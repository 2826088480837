import React, { useEffect, useState } from "react"

import { Row, Col, Button, PageHeader, LabelForm, Label as FormLabel, Type as UIType, Loading, Select } from "p-ui"
import { DataPickerInput, DatePickerSpan, FlexColDiv, FlexDiv } from "@styles"

import STATIONS from "src/apis/station"
import LAB from "src/apis/lab"

import { Station, BikeLatLng } from "@type/responseType"
import CustomDatePicker from "@components/widgets/customDatePicker"
import ReactDatePicker from "react-datepicker"
import { ko } from "date-fns/locale"
import useAlert from "@context/alert/useAlert"
import StationMap from "@pages/Station/Components/StationMap"

const PolyLinePage = () => {
    const currentDate = new Date()

    const [stationData, setStationData] = useState<Station[]>([])
    const [bikeSerial, setBikeSerial] = useState<string>("")
    const [polylineData, setPolylineData] = useState<BikeLatLng[]>([])
    const [totalMile, setTotlaMile] = useState<any>()
    const [savePolylineData, setSavePolylineData] = useState<any[]>([])

    const [bikeList, setBikeList] = useState([])

    const [startDate, setStartDate] = useState(currentDate)
    const [endDate, setEndDate] = useState<Date>(currentDate)
    const [intervalTime, setIntervalTime] = useState<number>(30000)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { openAlert } = useAlert()

    const handleStartDateChange = (date: any) => {
        setStartDate(date)
        setEndDate(date)
    }

    const handleEndDateChange = (date: any) => {
        setEndDate(date)
    }

    const getMapData = async () => {
        await STATIONS.getStationData({ isAdmin: "admin" }).then(res => {
            if (res.status === "SUCCESS") {
                const stationList = res.resultData.data
                    .filter((item: Station) => Number(item.status) === 1)
                    .map((item: Station) => {
                        if (item.tags) {
                            item.tags = JSON.parse(item.tags)
                        }

                        return {
                            ...item,
                            latlng: {
                                lat: item.latitude,
                                lng: item.longitude,
                            },
                            popup: false,
                        }
                    })
                setStationData(stationList)
            }
        })
    }

    const getBikeTotal = async () => {
        await LAB.getBikeTotal({
            serial: bikeSerial,
            interval: intervalTime,
            startTime: startDate.getTime(),
            endTime: endDate.getTime(),
        }).then(response => {
            if (response.status === "SUCCESS") {
                if (response.resultData?.length === 0) {
                    return openAlert({ body: "검색기간내에 데이터가 없습니다.", type: "warning" })
                }
                setTotlaMile({ totalMile: response.resultData?.totalMile, serial: bikeSerial })
            } else {
                openAlert({ body: response.resultMessage, type: "warning" })
            }
        })
    }

    const getBikeLine = async () => {
        try {
            const [totalRes, gpsRes] = await Promise.all([
                LAB.getBikeTotal({
                    serial: bikeSerial,
                    interval: intervalTime,
                    startTime: startDate.getTime(),
                    endTime: endDate.getTime(),
                }),
                LAB.getBikeGps({
                    serial: bikeSerial,
                    interval: intervalTime,
                    startTime: startDate.getTime(),
                    endTime: endDate.getTime(),
                }),
            ])
            setIsLoading(false)

            if (totalRes.status !== "SUCCESS") {
                return openAlert({ body: totalRes.resultMessage, type: "warning" })
            }

            if (totalRes.resultData?.length === 0) {
                return openAlert({ body: "검색기간내에 데이터가 없습니다.", type: "warning" })
            }

            if (gpsRes.status !== "SUCCESS") {
                return openAlert({ body: gpsRes.resultMessage, type: "warning" })
            }

            if (gpsRes.resultData?.length === 0) {
                return openAlert({ body: "검색기간내에 데이터가 없습니다.", type: "warning" })
            }

            const latLngList = gpsRes.resultData
                .filter((item: BikeLatLng) => item.gpsLat !== "0.0" || item.gpsLng !== "0.0")
                .map((listItem: any) => ({
                    ...listItem,
                    serial: bikeSerial,
                    totalMile: totalRes.resultData?.totalMile,
                }))

            setPolylineData(latLngList)
            setTotlaMile({ totalMile: totalRes.resultData?.totalMile, serial: bikeSerial })
        } catch (error) {
            openAlert({ body: "오류가 발생했습니다.", type: "error" })
            console.error("Error in getBikeLine:", error)
        }
    }

    const getBikeList = async () => {
        await LAB.getBikeList().then(res => {
            if (res.status === "SUCCESS") {
                setBikeList(res.resultData)
            }
        })
    }

    const saveLine = () => {
        const saveArr: any[] = [...savePolylineData]

        if (polylineData?.length == 0) {
            return openAlert({ title: "저장 실패", body: "검색결과가 없습니다.", type: "warning" })
        }
        if (saveArr.length >= 10) {
            return openAlert({ title: "저장 실패", body: "10개이상은 저장할 수 없습니다.", type: "warning" })
        }
        saveArr.push(polylineData)
        setSavePolylineData(saveArr as unknown as [])
        setPolylineData([])
        setTotlaMile(null)
        openAlert({ title: "저장 성공", body: "저장되었습니다.", type: "primary" })
    }

    useEffect(() => {
        getMapData()
        getBikeList()
    }, [])

    return (
        <>
            <Loading open={isLoading} />
            <FlexColDiv height="100%">
                <PageHeader
                    title="실험실"
                    subTitle="Polyline"
                />
                <Row gap={"20px"}>
                    <Col md={4}>
                        <LabelForm label="이륜차">
                            <Select
                                options={bikeList}
                                searchable
                                onChange={(label: any, value: any) => setBikeSerial(value)}
                            />
                        </LabelForm>
                    </Col>
                    <Col md={8}>
                        <LabelForm
                            labelCol={2}
                            label="검색기간"
                        >
                            <FlexDiv
                                height="100%"
                                gap="5px"
                            >
                                <CustomDatePicker height={"100%"}>
                                    <ReactDatePicker
                                        selected={startDate}
                                        showTimeSelect
                                        onChange={(date: any) => handleStartDateChange(date)}
                                        locale={ko} // 한글로 변경
                                        selectsStart
                                        maxDate={currentDate}
                                        dateFormat="yyyy.MM.dd (eee) HH:mm" // 시간 포맷 변경
                                        showPopperArrow={false} // 화살표 변경
                                        customInput={<DataPickerInput height={"50px"} />}
                                    />
                                </CustomDatePicker>
                                <DatePickerSpan> ~ </DatePickerSpan>
                                <CustomDatePicker height={"100%"}>
                                    <ReactDatePicker
                                        selected={endDate}
                                        showTimeSelect
                                        onChange={(date: any) => handleEndDateChange(date)}
                                        locale={ko}
                                        dateFormat="yyyy.MM.dd (eee) HH:mm"
                                        showPopperArrow={false}
                                        selectsEnd
                                        startDate={startDate}
                                        maxDate={new Date(startDate.getTime() + 24 * 60 * 60 * 1000 - 60000)}
                                        minTime={new Date(new Date().setHours(0, 0, 0, 0))}
                                        maxTime={
                                            startDate.getDate() !== endDate.getDate()
                                                ? new Date(startDate.getTime())
                                                : new Date(new Date().setHours(23, 30, 0, 0))
                                        }
                                        endDate={endDate}
                                        minDate={startDate}
                                        customInput={<DataPickerInput height={"50px"} />}
                                    />
                                </CustomDatePicker>
                                <Select
                                    width={"100px"}
                                    options={interval}
                                    defaultValue={intervalTime}
                                    onChange={(label: any, value: any) => setIntervalTime(value)}
                                />
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setIsLoading(true)
                                        getBikeLine()
                                        getBikeTotal()
                                    }}
                                >
                                    검색
                                </Button>
                                <Button
                                    size="small"
                                    type="black"
                                    onClick={() => {
                                        saveLine()
                                    }}
                                >
                                    저장
                                </Button>
                            </FlexDiv>
                        </LabelForm>
                    </Col>
                </Row>
                <StationMap
                    savePolylineData={savePolylineData}
                    stationData={stationData}
                    polylineData={polylineData}
                    totalMile={totalMile}
                    isSwitch={true}
                />
            </FlexColDiv>
        </>
    )
}

export default PolyLinePage

const interval = [
    { label: "10초", value: 10000 },
    { label: "30초", value: 30000 },
    { label: "1분", value: 60000 },
    { label: "10분", value: 600000 },
]
