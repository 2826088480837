// Default axios config File
import axios from "./config"
import { ISSUE } from "./urls"
import { Result } from "@type/responseType"
import { Issue, IssueResult, WriteApiData } from "@type/issue"

const Iss = {
    /**
     * 이슈 리스트 조회
     * @param params : any
     * @returns httpResponse
     */
    async getDataList(params?: any): Promise<Result<Issue>> {
        try {
            return await axios.host.get(ISSUE.GET_LIST, { params })
        } catch (e: any) {
            console.log(e)
            return e.message
        }
    },
    /**
     * Request Issue Data
     * @param {any} params : Search conditions
     * @returns {Array}
     */
    async getData(issueId?: number): Promise<Result<IssueResult>> {
        try {
            return await axios.host.get(ISSUE.GET_ONE + issueId)
        } catch (e) {
            return Promise.reject({})
        }
    },

    /**
     * 이슈 삭제
     * @param issueId number
     * @returns httpResponse
     */
    async deleteIssue(issueId?: number): Promise<Result<IssueResult>> {
        try {
            return await axios.host.delete(ISSUE.DELETE + issueId)
        } catch (e) {
            return Promise.reject({})
        }
    },

    /**
     * 이슈 등록
     * @param issue any
     * @returns httpResponse
     */
    async insert(issue: any): Promise<Result<Issue>> {
        try {
            return await axios.host.post(ISSUE.INSERT, issue)
        } catch (e) {
            return Promise.reject([])
        }
    },

    /**
     * 이슈 업데이트
     * @param issue Issue
     * @returns httpResponse
     */
    async update(issue: Issue) {
        try {
            return await axios.host.put(ISSUE.UPDATE + issue.issueId, issue)
        } catch (e) {
            return Promise.reject([])
        }
    },

    /**
     * 이슈 등록 시 필요한 data 조회
     * @param params any
     * @returns http Response
     */
    async getWriteData(params?: any): Promise<Result<WriteApiData>> {
        try {
            return await axios.host.get(ISSUE.GET_WRITE, { params })
        } catch (e: any) {
            console.log(e)
            return e.message
        }
    },
}

export default Iss
