import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

import { useRecoilState, useResetRecoilState } from "recoil"
import AppState from "@store"

import { Col, Row } from "p-ui"
import { CommonDiv } from "@styles"
import ChartTitle from "@pages/Issue/Components/ChartTitle"
import IssueDetailForm from "@pages/Issue/Components/IssueDetailForm"
import Loader from "@components/widgets/Loader"

import apis from "@apis"

import { WriteApiData, WriteData } from "@type/issue"

const IssueDetail = () => {
    const location = useLocation()
    const id = new URLSearchParams(location.search).get("id")
    const resetIssueData = useResetRecoilState(AppState.reg.paramIssueState)
    const resetFormData = useResetRecoilState(AppState.reg.paramIssueStateSelector)
    const resetIssueDesc = useResetRecoilState(AppState.reg.paramIssueDesState)
    const resetWriteData = useResetRecoilState(AppState.reg.writeDataState)

    const [issueData, setIssueData] = useRecoilState(AppState.reg.paramIssueStateSelector)
    const [issueDesData, setIssueDesData] = useRecoilState(AppState.reg.paramIssueDesState)
    const [writeData, setWriteData] = useRecoilState(AppState.reg.writeDataState)

    const getIssueData = async () => {
        const axiosResult = await apis.Iss.getData(Number(id))
        setIssueData(axiosResult.resultData.issue)
        setIssueDesData(axiosResult.resultData.issueDesc)
    }
    const getWriteData = async () => {
        const writeData = await apis.Iss.getWriteData()
        if (writeData.status == "SUCCESS") {
            setWriteDataAction(writeData.resultData)
        }
    }
    const setWriteDataAction = (writeData: WriteApiData) => {
        let result: WriteData = {
            category: [],
            station: [],
            bike: [],
            battery: [],
        }
        result.category = writeData.category
        result.station = writeData.goods.filter((item: any) => {
            return item.value.charAt(0) == "S"
        })
        result.bike = writeData.goods.filter((item: any) => {
            return item.value.charAt(0) == "M"
        })
        result.battery = writeData.goods.filter((item: any) => {
            if (item.value.charAt(0) == "B" && item.value.charAt(9) == "M") {
                item.value = item.value + "/S"
                return true
            }
            return false
        })
        setWriteData(result)
    }
    useEffect(() => {
        getWriteData()
        resetFormData()
        if (id) {
            getIssueData()
        }
    }, [id])
    useEffect(() => {
        return () => {
            resetIssueData()
            resetIssueDesc()
            resetWriteData()
        }
    }, [])

    return issueData ? (
        <>
            <CommonDiv margin="0 0 2rem 0">
                <ChartTitle
                    title={issueData?.title}
                    id={issueData?.issueId}
                    detail={"detail"}
                />
            </CommonDiv>
            <Row marginBottom={"2rem"}>
                <Col sm={12}>
                    <IssueDetailForm />
                </Col>
            </Row>
        </>
    ) : (
        <Loader />
    )
}

export default IssueDetail
