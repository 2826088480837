import { Popover } from "@components/widgets/PopOver"
import { Popper } from "@pages/Lab/bike/style"
import { FlexDiv } from "@styles"
import { Button, Icon } from "p-ui"
import { useState } from "react"

const PopOverComponent = ({ item }: any) => {
    const [isPopperShown, setIsPopperShown] = useState(false)

    const onOpenerClick = (e: any) => {
        e.stopPropagation()
        setIsPopperShown(!isPopperShown)
    }

    const onClose = () => {
        setIsPopperShown(false)
    }

    return (
        <FlexDiv
            gap="5px"
            alignItems="center"
            position="relative"
        >
            <span>{item.updateDesc.substr(0, 8) + "..."}</span>
            <Button
                compact
                round
                size="small"
                onClick={onOpenerClick}
            >
                <Icon.Plus
                    width={8}
                    height={8}
                ></Icon.Plus>
            </Button>
            {isPopperShown && (
                <Popover onClose={onClose}>
                    <Popper>{item.updateDesc}</Popper>
                </Popover>
            )}
        </FlexDiv>
    )
}

export default PopOverComponent
