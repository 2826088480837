import axios from "./config"
import { REQUEST_TASK } from "./urls"
import { ReqTask, Result } from "@type/responseType"

export default {
    async getReqTask(): Promise<Result<ReqTask[]>> {
        try {
            return await axios.host.get(REQUEST_TASK.GET_REQTASK)
        } catch (e: any) {
            return e.message
        }
    },
    async getOneReqTask(id: string | number): Promise<Result<ReqTask>> {
        try {
            return await axios.host.get(REQUEST_TASK.GET_ONE_REQTASK + id)
        } catch (e: any) {
            return e.message
        }
    },
    async insertReqTask(params: any): Promise<Result<string>> {
        try {
            return await axios.host.post(REQUEST_TASK.INSERT_REQTASK, params)
        } catch (e: any) {
            return e.message
        }
    },
    async updateReqTask(params: any): Promise<Result<string>> {
        try {
            return await axios.host.put(REQUEST_TASK.UPDATE_REQTASK, params)
        } catch (e: any) {
            return e.message
        }
    },
    async deleteReqTask(id: string | number): Promise<Result<string>> {
        try {
            return await axios.host.delete(REQUEST_TASK.DELETE_REQTASK + id)
        } catch (e: any) {
            return e.message
        }
    },
    async getManagerIdList(): Promise<Result<[]>> {
        try {
            return await axios.host.get(REQUEST_TASK.GET_MANAGER)
        } catch (e: any) {
            return e.message
        }
    },
}
