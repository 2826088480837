import React, { useEffect, useState } from "react"

import { FlexColDiv, Text } from "@styles"
import { LabelForm, Input } from "p-ui"

interface OrderPaymentprops {}

const OrderPaymentModal = (props: OrderPaymentprops) => {
    /**
     * TODO 이거 하드코딩 해놔도 되는건지
     **/
    return (
        <FlexColDiv gap="10px">
            <LabelForm
                label="모터사이클 잔금"
                fullHeight
            >
                <Input
                    readonly
                    name="comment"
                    placeholder={"3,100,000 원"}
                />
            </LabelForm>
            <LabelForm
                label="결제금액"
                fullHeight
            >
                <Input
                    readonly
                    name="comment"
                    placeholder={"3,100,000 원"}
                />
            </LabelForm>
            <Text
                bold
                color="red"
                fontSize="14px"
                margin="20px 0 0 0"
            >
                현장 결제 처리 이력은 수정/삭제되지 않습니다.
                <br />
                결제 처리 후 [결제완료] 상태로 변경됩니다.
                <br />
                <br />
                {` [ 3,100,000 ]원 을 결제 처리하시겠습니까? `}
            </Text>
        </FlexColDiv>
    )
}

export default OrderPaymentModal
