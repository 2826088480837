import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"

import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FlexColDiv, Text } from "@styles"
import styled from "styled-components"
import { Button, Icon } from "p-ui"
import useAlert from "@context/alert/useAlert"

const ImageUpload = (props: ImageUploadProps) => {
    const { id, title, subTitle, maxSize, required, setImgFiles } = props

    const [files, setFiles] = useState<any>([])
    const [previewImg, setPreviewImg] = useState<any>()

    const [isDrag, setIsDrag] = useState(false)

    const imgInputRef = useRef<HTMLInputElement>(null)
    const dragRef = useRef<HTMLDivElement | null>(null)

    const { openAlert } = useAlert()

    const byteToKBorMB = (bytes: number) => {
        const kilobytes = bytes / 1024

        if (kilobytes >= 1000) {
            const megabytes = (kilobytes / 1024).toFixed(2)
            return `${megabytes}MB`
        }

        return `${kilobytes.toFixed(2)}KB`
    }

    const onImgInputBtnClick = (e: any) => {
        e.preventDefault()
        if (e.target.className !== "deleteBtn") {
            imgInputRef.current?.click()
        }
    }

    const encodeFileToBase64 = (fileBlob: any) => {
        const reader = new FileReader()
        reader.readAsDataURL(fileBlob)
        return new Promise((resolve: any) => {
            reader.onload = () => {
                setPreviewImg(reader.result)
                resolve()
            }
        })
    }

    const onChangeFiles = useCallback(
        (e: ChangeEvent<HTMLInputElement> | any): void => {
            let selectFiles: File[] = []
            let tempFiles: IFileTypes[] = []

            if (e.type === "drop") {
                selectFiles = e.dataTransfer.files
            } else {
                selectFiles = e.target.files
            }

            for (let i = 0; i < selectFiles.length; i++) {
                if (selectFiles[i].type !== "image/png" && selectFiles[i].type !== "image/jpeg") {
                    return openAlert({
                        title: "실패",
                        body: "png/jpg 이미지만 업로드해주세요",
                        type: "warning",
                    })
                }
            }

            if (selectFiles.length > 1) {
                return openAlert({
                    title: "실패",
                    body: "이미지 하나만 선택해주세요.",
                    type: "warning",
                })
            }

            const areAnyFilesGreaterThan10MB = (files: File[]) => {
                for (let i = 0; i < files.length; i++) {
                    if (isGreaterThan10MB(files[i].size)) {
                        return true
                    }
                }
                return false
            }

            if (areAnyFilesGreaterThan10MB(selectFiles)) {
                return openAlert({
                    title: "실패",
                    body: "10MB 이하 첨부파일을 업로드해주세요",
                    type: "warning",
                })
            }

            for (const file of selectFiles) {
                tempFiles.push({
                    id: id,
                    object: file,
                })
            }

            setFiles((prevFiles: any) => (prevFiles.length > 0 ? [...prevFiles, ...tempFiles] : tempFiles))
            setImgFiles((prevFiles: any) => (prevFiles.length > 0 ? [...prevFiles, ...tempFiles] : tempFiles))
            encodeFileToBase64(selectFiles[0])
        },
        [setFiles]
    )

    const isGreaterThan10MB = (bytes: number) => {
        const megabytes = bytes / (1024 * 1024)
        return megabytes >= 10
    }

    const handleDragIn = useCallback((e: DragEvent): void => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const handleDragOut = useCallback((e: DragEvent): void => {
        e.preventDefault()
        e.stopPropagation()

        setIsDrag(false)
    }, [])

    const handleDragOver = useCallback((e: DragEvent): void => {
        e.preventDefault()
        e.stopPropagation()

        if (e.dataTransfer!.files) {
            setIsDrag(true)
        }
    }, [])

    const handleDrop = useCallback(
        (e: DragEvent): void => {
            e.preventDefault()
            e.stopPropagation()

            onChangeFiles(e)
            setIsDrag(false)
        },
        [onChangeFiles]
    )

    const initDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.addEventListener("dragenter", handleDragIn)
            dragRef.current.addEventListener("dragleave", handleDragOut)
            dragRef.current.addEventListener("dragover", handleDragOver)
            dragRef.current.addEventListener("drop", handleDrop)
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

    const resetDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.removeEventListener("dragenter", handleDragIn)
            dragRef.current.removeEventListener("dragleave", handleDragOut)
            dragRef.current.removeEventListener("dragover", handleDragOver)
            dragRef.current.removeEventListener("drop", handleDrop)
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

    const deleteFiles = useCallback(() => {
        setFiles([])
        setImgFiles([])
    }, [files, setFiles])

    useEffect(() => {
        initDragEvents()
        return () => resetDragEvents()
    }, [initDragEvents, resetDragEvents])

    return (
        <FlexColDiv
            gap="8px"
            maxWidth="120px"
        >
            <FlexColDiv
                gap="8px"
                alignItems="center"
            >
                <Text
                    as={"p"}
                    fontSize="16px"
                    margin="0"
                >
                    {title} {required && <Required>*</Required>}
                </Text>
                <Text
                    as={"p"}
                    fontSize="12px"
                    color={`var(--text_secondary)`}
                    margin="0"
                >
                    {subTitle}
                </Text>
            </FlexColDiv>
            {files?.length > 0 ? (
                files?.map((file: IFileTypes) => {
                    const {
                        id,
                        object: { name, size },
                    } = file
                    return (
                        <>
                            <PreviewWrap>
                                <PreviewImg
                                    key={id}
                                    src={previewImg}
                                    alt={name}
                                />
                                <Button
                                    compact
                                    type="danger"
                                    size="small"
                                    onClick={deleteFiles}
                                >
                                    <Icon.Delete />
                                </Button>
                            </PreviewWrap>
                            <Text
                                fontSize="12px"
                                fontWeight={700}
                            >
                                {name}
                            </Text>
                            <Text
                                fontSize="12px"
                                color={`var(--text_secondary)`}
                                margin="0"
                            >
                                {byteToKBorMB(size)}
                            </Text>
                        </>
                    )
                })
            ) : (
                <>
                    <ImageDropZone
                        ref={dragRef}
                        isDrag={isDrag}
                        onClick={onImgInputBtnClick}
                    >
                        <span>
                            <FontAwesomeIcon icon={faArrowUpFromBracket} />
                        </span>
                        <p>Upload image</p>
                    </ImageDropZone>
                    <input
                        ref={imgInputRef}
                        placeholder="입력"
                        type="file"
                        accept="image/*"
                        onChange={onChangeFiles}
                        multiple={true}
                        style={{ display: "none" }}
                    />

                    <Text
                        as={"p"}
                        fontSize="12px"
                        color={`var(--text_secondary)`}
                        margin="0"
                    >
                        Max size-{maxSize}Mb.
                    </Text>
                    <Text
                        fontSize="12px"
                        color={`var(--text_secondary)`}
                        margin="0"
                    >
                        Jpg,Png
                    </Text>
                </>
            )}
        </FlexColDiv>
    )
}

export default ImageUpload
interface IFileTypes {
    id: number // 파일들의 고유값 id
    object: File
}

interface ImageUploadProps {
    id: number
    title: string
    subTitle: string
    maxSize: number
    imgFiles: File[]
    setImgFiles: any
    required?: boolean
}

const ImageDropZone = styled(FlexColDiv)<{ isDrag: boolean }>`
    align-items: center;
    gap: 4px;

    padding: 37px 14px;
    height: 120px;

    border-radius: 8px;
    border: 2px solid var(--light-assents-accent-1-primary, #2d5bff);
    filter: blur(${props => props.isDrag && 2}px);

    &:hover {
        filter: blur(2px);
    }

    span {
        color: var(--light-assents-accent-1-primary, #2d5bff);
    }

    p {
        font-size: 14px;
    }
`
const PreviewWrap = styled.div`
    position: relative;

    &:hover {
        .pui-button {
            display: block;
        }
    }

    .pui-button {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
`

const PreviewImg = styled.img`
    width: 100%;
    border-radius: 8px;

    ${PreviewWrap}:hover & {
        filter: blur(2px);
    }
`

const Required = styled.span`
    color: red;
`
