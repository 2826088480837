import React, { useEffect, useMemo, useState } from "react"
import ECharts from "echarts-for-react"
import Divider from "@components/widgets/Divider"
import { Icon } from "p-ui"
import { FlexColDiv, FlexDiv, Text } from "@styles"
import { ChartsDiv } from "../../Lab/bike/style"

interface BatteryProps {
    serialNo: string
    soc: number
    batteryNo: number
}
const BatteryGraph = (props: BatteryProps) => {
    const [isResize, setIsResize] = useState(false)

    const graphColor = useMemo(() => {
        if (props.soc > 50) {
            return "#3AC922"
        } else if (20 < props.soc && 51 > props.soc) {
            return "#f6d523"
        } else {
            return "#FF3030"
        }
    }, [props.soc])

    const options = {
        legend: {
            data: [`Battery ${props.batteryNo}`],
            bottom: 10,
            left: "left",
        },
        series: [
            {
                type: "pie",
                labelLine: {
                    show: false,
                },

                label: {
                    show: false,
                    position: "center",
                },
                data: [
                    { value: props.soc, name: `Battery ${props.batteryNo}`, itemStyle: { color: graphColor } },
                    { value: 100 - props.soc, itemStyle: { color: "#efefef" } },
                ],
                radius: ["40%", "70%"],
            },
        ],
    }

    useEffect(() => {
        setIsResize(true)
    }, [])

    return (
        <ChartsDiv>
            <FlexDiv justifyContent="space-between">
                <FlexColDiv>
                    <Text
                        bold
                        fontSize="20px"
                    >
                        {props.serialNo}
                    </Text>
                    <Text
                        bold
                        fontSize="24px"
                    >
                        {props.soc}%
                    </Text>
                    <Text
                        fontSize="12px"
                        color="#4F4F4F"
                    >
                        배터리{props.batteryNo}
                    </Text>
                </FlexColDiv>
                <Icon icon={"Info"} />
            </FlexDiv>
            <Divider />
            {isResize && (
                <ECharts
                    option={options}
                    notMerge={true}
                    opts={{ renderer: "canvas", height: "auto", width: "auto" }}
                ></ECharts>
            )}
        </ChartsDiv>
    )
}

export default BatteryGraph
