/**
 * @props
 * clickedFilter-클릭된 값 arr
 * checklist-checkbox 에서 Value 로 넘어오는 []:number
 * defaultFilter- 기본으로 체크되어있어야하는 filter(전체 제외)
 * setClickedFilter -  clikedFilter 셋팅함수
 * allValue - label이 "전체" 인 value 값
 */

interface onChangeFilterProps {
    clickedFilter: (string | number)[]
    checkList: (string | number)[]
    defaultFilter: (string | number)[]
    setClickedFilter: ([]) => void
    allValue: string | number
}

export const onChangeFilter = (props: onChangeFilterProps) => {
    const { clickedFilter, checkList, setClickedFilter, defaultFilter, allValue } = props
    if (clickedFilter.includes(allValue) && !checkList.includes(allValue)) {
        setClickedFilter([])
        return
    }

    const isAll = defaultFilter.every(item => checkList.includes(item))

    switch (true) {
        case isAll:
        case !clickedFilter.includes(allValue) && checkList.includes(allValue):
            setClickedFilter(defaultFilter.concat(allValue))
            break
        default:
            setClickedFilter(checkList.filter(item => item !== allValue))
    }
}
