import React, { useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router"

import { Type as UIType, Button, Col, DataTable, Input, PageHeader, Row, Select, Tag, CheckBox } from "p-ui"
import { FlexDiv, Label } from "@styles"

import REQUEST_TASK from "src/apis/reqTask"

import { onChangeFilter } from "src/utils/filter"

import { req_type, requestColum, search_type, service_code, task_status } from "../constants"

import { Type } from "p-ui"
import { onChangeParam } from "@type/common"
import { ReqTask } from "@type/responseType"
import { updateActivePage, observPage } from "@common/util"
import { useSearchParams } from "react-router-dom"
import { PageRow } from "p-ui/dist/esm/types"
import { axiosClearAuthHeader } from "@apis"
import AppState from "@store"
import { useSetRecoilState } from "recoil"
import useAlert from "@context/alert/useAlert"

const RequestMainPage = () => {
    const { openAlert } = useAlert()
    const tableRef = useRef<HTMLTableElement | null>(null)

    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchTextParam = searchParams?.get("searchText")
    const searchTypeParam = searchParams?.get("searchType")
    const reqTypeParam = searchParams?.get("reqType")
    const pageParam = searchParams.get("page")
    const limitParam = searchParams.get("limit")

    const [reqListData, setReqListData] = useState<ReqTask[]>([])
    const [filterList, setFilterList] = useState<ReqTask[]>([])
    const [pageRows, setPageRows] = useState<PageRow>(15)

    const [searchType, setSearchType] = useState<string>(searchTypeParam ? searchTypeParam : "originReqTitle")

    const [clickedFilter, setClickedFilter] = useState<number[]>(
        reqTypeParam ? reqTypeParam.split(",").map(Number) : defaultFilter.concat(0)
    )
    const [searchText, setSearchText] = useState<string>("")

    const [nowPage, setNowPage] = useState<any>(pageParam !== null ? pageParam : 1)

    const setLoginState = useSetRecoilState<boolean>(AppState.auth.loginState)
    const setToken = useSetRecoilState<string>(AppState.auth.tokenState)
    const setUserState = useSetRecoilState<string>(AppState.auth.userState)

    const navigate = useNavigate()
    let tagType = "primary"
    let tagTitle = ""

    const setTag = (_priority: number) => {
        switch (_priority) {
            case 1:
                tagType = "success"
                tagTitle = "낮음"
                break
            case 2:
                tagType = "primary"
                tagTitle = "보통"
                break
            case 3:
                tagType = "warning"
                tagTitle = "높음"
                break
            case 4:
                tagType = "danger"
                tagTitle = "긴급"
                break
            default:
                tagType = "default"
                break
        }
    }

    const getReqTask = async () => {
        await REQUEST_TASK.getReqTask().then(res => {
            if (res.status === "SUCCESS") {
                const reqList = res.resultData.map((item: any) => {
                    setTag(Number(item.priority))
                    return {
                        ...item,
                        detail: (
                            <Button
                                onClick={() => {
                                    navigate({ pathname: "/request/detail", search: "?id=" + item.reqId })
                                }}
                                size="small"
                                type="primary"
                            >
                                상세보기
                            </Button>
                        ),
                        reqTitle: <div>{item.reqTitle}</div>,
                        originReqTitle: item.reqTitle,
                        reqType: <div>{req_type[Number(item.reqType)]?.label}</div>,
                        priority: (
                            <Tag
                                type={tagType as Type.TagType}
                                value={tagTitle}
                            />
                        ),
                        originTaksStatus: item.taskStatus,
                        originPriority: item.priority,
                        originReqType: item.reqType,
                        originServiceCode: item.serviceCode,
                        serviceCode: <div>{service_code[Number(item.serviceCode)]?.label}</div>,
                        taskStatus: <div>{task_status[Number(item.taskStatus - 1)]?.label}</div>,
                        completionDt: item.completionDt ? item.completionDt.substr(0, 10) : "-",
                        createdAt: item.createdAt ? item.createdAt.substr(0, 10) : "-",
                    }
                })
                setReqListData(reqList)
            }
        })
    }

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)

            if (newActivePage === 0) {
                return setNowPage(Number(pageParam))
            }

            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    const filterData = useMemo(() => {
        if (searchText === "") {
            return reqListData.filter(item => {
                return clickedFilter.includes(Number(item.originTaksStatus))
            })
        }
        if (clickedFilter.length === 0) {
            return []
        }

        const data = reqListData.filter(
            item => clickedFilter.includes(Number(item.originTaksStatus)) && item[searchType]?.includes(searchText)
        )
        return data
    }, [clickedFilter, reqListData, searchText])

    const filter = () => {
        if (location.search.length === 0) {
            setSearchParams({
                searchText: searchText,
                searchType: searchType,
                page: nowPage,
                limit: pageRows.toString(),
            })
        } else {
            setSearchParams({
                searchText: searchText,
                searchType: searchType,
                reqType: clickedFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        }
        setFilterList(filterData)
    }
    const logout = () => {
        setToken("")
        setLoginState(false)
        setUserState("")
        axiosClearAuthHeader()
        sessionStorage.clear()

        // TODO CHANGE : 권한 별 인덱스 설정
        navigate("/")
    }

    const reject = () => {
        openAlert({
            title: "사용 불가",
            body: "사용이 불가한 페이지 입니다. 잠시 후 로그아웃 됩니다.",
            type: "warning",
        })
        setTimeout(() => {
            logout()
        }, 1000)
    }
    useEffect(() => {
        reject()
        // getReqTask()
        // if (searchTextParam) {
        //     setSearchText(searchTextParam)
        // }
        // if (limitParam) {
        //     setPageRows(Number(limitParam) as unknown as PageRow)
        // }
    }, [])

    useEffect(() => {
        filter()
    }, [reqListData, clickedFilter, nowPage, pageRows])

    const onResetButtonClick = () => {
        setClickedFilter(defaultFilter.concat(0))
        setSearchText("")
        setSearchType("originReqTitle")
        setNowPage(1)
        filter()
    }

    return (
        <>
            <PageHeader
                title="업무요청"
                subTitle="업무요청 관리"
            />

            <Row gap={10}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv
                        alignItems="center"
                        gap="5px"
                    >
                        <Select
                            width={"100px"}
                            size="small"
                            options={search_type}
                            defaultValue={searchType}
                            onChange={(label: string, value: any) => {
                                setSearchType(value)
                            }}
                        />
                        <Input
                            value={searchText}
                            width="300px"
                            size="small"
                            onChange={(e: onChangeParam) => {
                                setSearchText(e.value as string)
                            }}
                            onSearch={() => {
                                setSearchText(searchText)
                                setNowPage(1)
                                filter()
                            }}
                        />
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>필터</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv
                        alignItems="center"
                        gap="15px"
                    >
                        <CheckBox.Group
                            value={clickedFilter}
                            options={filterOptions}
                            onChange={(value: any) => {
                                const param = {
                                    clickedFilter: clickedFilter,
                                    checkList: value as [],
                                    defaultFilter: defaultFilter,
                                    setClickedFilter: setClickedFilter,
                                    allValue: 0,
                                }
                                onChangeFilter(param)
                                setNowPage(1)
                                filter()
                            }}
                        />
                        <Button
                            size="small"
                            type="default"
                            onClick={() => {
                                onResetButtonClick()
                            }}
                        >
                            초기화
                        </Button>
                    </FlexDiv>
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    pagination
                    headerRight={
                        <Button
                            onClick={() => {
                                reject()
                                // navigate("/request/insert")
                            }}
                            size="small"
                            type="primary"
                        >
                            업무 요청 등록
                        </Button>
                    }
                    nowPage={nowPage}
                    columns={requestColum}
                    dataList={filterList}
                    pageRow={pageRows}
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(limitParam) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                />
            </div>
        </>
    )
}

export default RequestMainPage
const defaultFilter = [1, 2, 3, 4, 5, 6]

const filterOptions: UIType.OptionType[] = [
    { label: "전체", value: 0 },
    { label: "요청", value: 1 },
    { label: "검토", value: 2 },
    { label: "반려", value: 3 },
    { label: "보류", value: 4 },
    { label: "작업", value: 5 },
    { label: "완료", value: 6 },
]
