import { Notice, NoticeInfo, NoticeOneData } from "@type/bbsType"
import { Code, Result } from "@type/responseType"
import axios from "./config"
import { NOTICE } from "./urls"

export default {
    /**
     * 공지사항 목록 조회
     * @returns {Array}
     */
    async getNoticeList(): Promise<Result<NoticeInfo>> {
        try {
            return await axios.host.get(NOTICE.GET_LIST)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * 공지사항 상세 조회
     * @param id noticeId
     * @returns {NoticeOneData}
     */
    async getNotice(id: number | string): Promise<Result<NoticeOneData>> {
        try {
            return await axios.host.get(NOTICE.GET_DETAIL + id)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * 공지사항 카테고리(구분) 조회
     * @returns {Array}
     */
    async getType(): Promise<Result<Code[]>> {
        try {
            return await axios.host.get(NOTICE.GET_TYPE)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * 공지사항 등록
     * @returns {NoticeInfo}
     */
    async insertNotice(body: Notice): Promise<Result<NoticeInfo>> {
        try {
            return await axios.host.post(NOTICE.POST, body)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * 공지사항 수정
     * @returns {NoticeInfo}
     */
    async updateNotice(id: number, body: NoticeInfo): Promise<Result<NoticeInfo>> {
        try {
            return await axios.host.put(NOTICE.PUT + id, body)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * 공지사항 삭제 (use_fg = 0 처리)
     * @param id noticeId
     * @returns {NoticeInfo}
     */
    async deleteNotice(id: number): Promise<Result<NoticeInfo>> {
        try {
            return await axios.host.delete(NOTICE.DELETE + id)
        } catch (err: any) {
            return err.message
        }
    },
}
