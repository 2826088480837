import CustomDatePicker from "@components/widgets/customDatePicker"
import { DataPickerInput } from "@styles"
import { HardwareListALL, TaskManager } from "@type/responseType"
import { ko } from "date-fns/locale"
import { Col, Label, Row, Select, TextArea } from "p-ui"
import React, { useEffect, useMemo, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useGetAllHardList } from "src/queries/firmhard"
import { ASSET_BIKE } from "../constants"
import { useGetManagerList } from "src/queries/manager"
import { useQueryClient } from "@tanstack/react-query"
import { formatYYYYMMDDhhmmss2 } from "@common/util"
import { useLocation } from "react-router"
import { useGetHardWareVersion } from "src/queries/assets"

const HardUpdateModal = (props: any) => {
    const { hardwareInfo, setHardwareInfo, serialNum, setPrevHardwareInfo, prevHardwareInfo } = props
    const currentDate = new Date()
    const location = useLocation()
    const serialNo = new URLSearchParams(location.search).get("serialNo")
        ? new URLSearchParams(location.search).get("serialNo")
        : serialNum

    const [startDate, setStartDate] = useState(currentDate)
    const [type, setType] = useState<string>("")

    const { data: hwVersion } = useGetHardWareVersion(serialNo, true)
    const { data: hardwareListData } = useGetAllHardList()
    const { data: managerListData } = useGetManagerList()

    const hardList = useMemo(() => {
        return hardwareListData
            ? hardwareListData
                  .filter((item: any) => type.includes(item.hardwareType.toLowerCase()))
                  .map((item: HardwareListALL) => {
                      return { label: item.version, value: item.hardwareId }
                  })
            : []
    }, [type, hardwareListData])

    const managerList = useMemo(() => {
        return managerListData
            ? managerListData.map((item: TaskManager) => {
                  return { label: item.managerNm, value: item.managerId }
              })
            : []
    }, [managerListData])

    useEffect(() => {
        setHardwareInfo({
            ...hardwareInfo,
            applyDt: formatYYYYMMDDhhmmss2(startDate as unknown as string),
            bikeSerial: serialNo as string,
            edcuVersion: hwVersion?.edcuVersionId,
        })
        setPrevHardwareInfo({
            ...prevHardwareInfo,
        })
    }, [hwVersion])
    return (
        <Row gap={10}>
            <Col sm={3}>
                <Label value={"* 하드웨어선택"} />
            </Col>
            <Col sm={8}>
                <Select
                    options={ASSET_BIKE.hardType}
                    onChange={(label, value) => {
                        setType(value.toString())
                        setHardwareInfo((prev: any) => ({
                            ...prev,
                            edcuVersion: hwVersion?.edcuVersionId,
                        }))
                    }}
                />
            </Col>
            <Col sm={3}>
                <Label value={"* 버전"} />
            </Col>
            <Col sm={8}>
                <Select
                    options={hardList}
                    onChange={(label, value) => {
                        setHardwareInfo((prev: any) => ({ ...prev, [type]: value as number }))
                        setPrevHardwareInfo((prev: any) => ({ ...prev, [type]: value as number }))
                    }}
                />
            </Col>
            <Col sm={3}>
                <Label value={"* 담당자"} />
            </Col>
            <Col sm={8}>
                <Select
                    searchable
                    options={managerList}
                    onChange={(label, value) => {
                        setHardwareInfo((prev: any) => ({ ...prev, managerId: value as number }))
                        setPrevHardwareInfo((prev: any) => ({ ...prev, managerId: value as number }))
                    }}
                />
            </Col>
            <Col sm={3}>
                <Label value={"* 업데이트 일"} />
            </Col>
            <Col sm={8}>
                <CustomDatePicker
                    width="100%"
                    height="100%"
                >
                    <ReactDatePicker
                        selected={startDate}
                        onChange={(date: any) => {
                            setStartDate(date)
                            setHardwareInfo((prevInfo: any) => ({
                                ...prevInfo,
                                applyDt: formatYYYYMMDDhhmmss2(date as unknown as string),
                            }))
                        }}
                        locale={ko} // 한글로 변경
                        selectsStart
                        dateFormat="yyyy.MM.dd (eee)" // 시간 포맷 변경
                        showPopperArrow={false} // 화살표 변경
                        customInput={<DataPickerInput />}
                    />
                </CustomDatePicker>
            </Col>
            <Col sm={3}>
                <Label value={"사유"} />
            </Col>
            <Col sm={8}>
                <TextArea
                    placeholder="업데이트 하는 사유를 적어 주세요."
                    maxLength={200}
                    onChange={e => {
                        setHardwareInfo((prev: any) => ({ ...prev, updateDesc: e.target.value }))
                    }}
                />
            </Col>
        </Row>
    )
}

export default HardUpdateModal
