import { Row, Col } from "react-grid-system"
import styled from "styled-components"

export const ContentWrap = styled.div`
    border: 1px solid #dddd;
    padding: 2rem;
    border-radius: 5px;
    white-space: nowrap;
`

export const InsertValueDiv = styled.div<{ overflowX?: string }>`
    width: 100%;
    height: fit-content;
    max-height: 150px;
    min-height: 50px;
    overflow-x: ${props => props.overflowX || "auto"};
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    outline: none;
    background: #f8f8f8;
    border: 1px solid #f8f8f8;
    border-radius: 8px;
    padding: 0 16px;
    line-height: 50px;
    text-align: left;
    span {
        background-color: #fffbe6;
        padding: 0.4rem;
        box-sizing: border-box;
        height: 80%;
        border: 1px solid #dddd;
        border-radius: 8px;
        cursor: pointer;
    }
`

export const DataPickerInput = styled.input`
    border: none;
    height: 40px;
    width: 100%;
    background-color: #f8f8f8;
    outline: none;
    padding-left: 0.5rem;
    color: #6e6e6e;
`
