import { HardwareInfo } from "@type/firmHardType"
import axios from "./config"
import { HardwareList, HardwareListALL, Result } from "@type/responseType"
import { HW } from "./urls"
export default {
    async getAllHardList(): Promise<Result<HardwareListALL[]>> {
        try {
            return await axios.host.get(HW.GET_HARD_LIST_ALL)
        } catch (e: any) {
            return e.message
        }
    },
    async getHardList(): Promise<Result<HardwareList[]>> {
        try {
            return await axios.host.get(HW.GET_HARD_LIST)
        } catch (e: any) {
            return e.message
        }
    },
    async getHardHistory(deviceType: string, hardwareType: string): Promise<Result<HardwareList[]>> {
        try {
            return await axios.host.get(
                HW.GET_HARD_HISTORY + "?deviceType=" + deviceType + "&hardwareType=" + hardwareType
            )
        } catch (e: any) {
            return e.message
        }
    },
    async upsertHard(hardwareInfo: HardwareInfo): Promise<Result<any[]>> {
        try {
            return await axios.host.post(HW.UPDATE_HARD, hardwareInfo)
        } catch (e: any) {
            return e.message
        }
    },
    async deleteHard(hardwareId: number): Promise<Result<any[]>> {
        try {
            return await axios.host.delete(HW.DELETE_HARD, { params: { hardwareId: hardwareId } })
        } catch (e: any) {
            return e.message
        }
    },
}
