import React from "react"
import { DatePickerWrap, Span } from "./styles"
import { Icon } from "p-ui"

function CustomDatePicker(props: any) {
    const { children, margin, width, height, spanWidth } = props

    return (
        <DatePickerWrap
            margin={margin}
            height={height}
            width={width}
        >
            <Span width={spanWidth}>
                <Icon.Calendar
                    width={20}
                    height={20}
                    fill="var(--secondary)"
                />
            </Span>
            {children}
        </DatePickerWrap>
    )
}

export default CustomDatePicker
