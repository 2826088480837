import { CommonDiv, FlexColDiv, FlexDiv, GridDiv, ImgDiv, Text } from "@styles"
import { Button, Icon, Input, LabelForm, Loading, Modal, PageHeader } from "p-ui"
import { useEffect, useState } from "react"
import useAlert from "@context/alert/useAlert"
import { useGetShippmentStatus, useUploadFile } from "src/queries/bbs/shippmentStatus"
import { useQueryClient } from "@tanstack/react-query"
import { QUERY_KEYS } from "@common/constatns"
import { shippmentStatusFolderName, shippmentStatusLinkInfo } from "../constants"
import ImageUpload from "@components/widgets/ImageUpload"
import { useDeleteFile } from "src/queries/common/file"
import Pagination from "@components/widgets/Pagination"

const ShippmentStatus = () => {
    const queryClient = useQueryClient()

    const [modalOpen, setModalOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState<deleteModal>(defaultDeleteStatus)
    const [uploadFiles, setUploadFiles] = useState([])
    const [title, setTitle] = useState("")
    const [list, setList] = useState<{ [key: string]: fileList[] }>()
    const [keys, setKeys] = useState<String[]>()
    const [page, setPage] = useState(1)
    const [pageList, setPageList] = useState<number[]>()
    const { openAlert } = useAlert()

    const viewCount = 6
    const LINK_INFO = "shippment_status"

    /**
     * 출고현황 리스트 조회
     */
    const { data } = useGetShippmentStatus(LINK_INFO)

    const { mutate } = useUploadFile()
    const deleteImg = useDeleteFile()

    const validationField = () => {
        if (title === "") {
            openAlert({ title: "실패", body: "제목을 입력해주세요.", type: "warning" })
        } else if (uploadFiles.length === 0) {
            openAlert({ title: "실패", body: "이미지 파일을 등록해주세요.", type: "warning" })
        } else {
            uploadFile()
        }
    }

    /**
     * 업로드 파일
     */
    const uploadFile = () => {
        let formData = new FormData()

        uploadFiles.map((item: any) => {
            formData.append("formData", item.object)
        })
        formData.append("linkInfo", shippmentStatusLinkInfo)
        formData.append("title", title)

        mutate(formData, {
            onSuccess(res) {
                if (res.status === "SUCCESS") {
                    openAlert({ title: "성공", body: "추가 완료", type: "primary" })
                    setModalOpen(false)
                    setUploadFiles([])
                    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SHIPPMENT_STATUS_GET_LIST] }) //성공시 조회 api 호출
                } else {
                    openAlert({ title: "실패", body: "이미지 추가 실패하였습니다.", type: "warning" })
                }
            },
            onError(error) {
                console.log("🚀 ~ onError ~ error:", error)
            },
        })
    }

    /**
     * 이미지(파일) 삭제
     * @param id fileId
     */
    const deleteImgFile = (id: number) => {
        deleteImg(
            { folderName: shippmentStatusFolderName, fileId: id },
            {
                onSuccess(res) {
                    if (res.status === "SUCCESS") {
                        openAlert({ title: "성공", body: "삭제 완료", type: "primary" })
                        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SHIPPMENT_STATUS_GET_LIST] }) //성공시 조회 api 호출
                    } else {
                        openAlert({ title: "실패", body: "이미지 삭제 실패하였습니다.", type: "warning" })
                    }
                },
                onError(error) {
                    console.log("🚀 ~ onError ~ error:", error)
                },
            }
        )
    }

    useEffect(() => {
        if (data) {
            //dataList, pageList 설정하기
            for (let i = 1; i <= Math.floor(data.length / viewCount) + 1; i++) {
                setList(prev => ({ ...prev, [i]: data.slice(i * viewCount - viewCount, i * viewCount) }))
            }
            const calcPageList = Array.from(
                { length: Math.floor(data.length / viewCount) + (data.length % viewCount === 0 ? 0 : 1) },
                (_, idx) => {
                    return idx + 1
                }
            )
            setPageList(calcPageList)

            // 삭제할 때 data 총갯수가 page-1*viewCount가 같다면 page -1 처리
            if (page !== 1 && (page - 1) * viewCount === data.length) {
                setPage(page - 1)
            }
        }
    }, [data])

    useEffect(() => {
        if (list) {
            setKeys(Object.keys(list))
        }
    }, [list])

    return (
        <>
            <FlexDiv alignItems="center">
                <PageHeader
                    title={"출고현황 관리"}
                    subTitle="출고현황 리스트"
                />
                {data && data.length !== 0 && (
                    <Button
                        type="primary"
                        size="small"
                        height={36}
                        onClick={() => setModalOpen(true)}
                    >
                        이미지 추가
                    </Button>
                )}
            </FlexDiv>
            {data && data.length === 0 ? (
                <CommonDiv
                    width="100%"
                    style={{ textAlign: "center" }}
                >
                    <Text
                        as={"p"}
                        fontSize="18px"
                    >
                        이미지를 등록해주세요.
                    </Text>
                    <Button
                        type="primary"
                        size="small"
                        height={36}
                        onClick={() => setModalOpen(true)}
                    >
                        이미지 추가
                    </Button>
                </CommonDiv>
            ) : (
                <>
                    <GridDiv
                        gridCol="1fr 1fr 1fr"
                        justifyItems="center"
                        gap="16px"
                    >
                        {keys &&
                            list &&
                            list[page].map((item: any) => {
                                return (
                                    <FlexColDiv
                                        alignItems="center"
                                        gap="16px"
                                    >
                                        <ImgDiv
                                            size="240px"
                                            backGroundImg={item.thumbUrl}
                                            backGroundSize="cover"
                                            cursor="pointer"
                                            onClick={() => window.open(item.s3Url)}
                                        ></ImgDiv>
                                        <FlexDiv
                                            gap="8px"
                                            alignItems="center"
                                        >
                                            <Text
                                                className="item"
                                                fontSize="18px"
                                            >
                                                {item.fileName}
                                            </Text>
                                            <Button
                                                type="danger"
                                                size="small"
                                                compact
                                                onClick={() => setDeleteOpen({ state: true, id: item.fileId })}
                                            >
                                                <Icon.Delete
                                                    width={"16px"}
                                                    height={"16px"}
                                                />
                                            </Button>
                                        </FlexDiv>
                                    </FlexColDiv>
                                )
                            })}
                    </GridDiv>
                    <br />
                    {pageList && (
                        <Pagination
                            pageList={pageList}
                            count={5}
                            page={page}
                            setPage={setPage}
                        />
                    )}
                </>
            )}
            <Modal
                type="confirm"
                title="출고 현황 이미지 추가"
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false)
                    setUploadFiles([])
                }}
                width={540}
                footer={
                    <>
                        <Button
                            type="secondary"
                            onClick={() => {
                                setModalOpen(false)
                                setUploadFiles([])
                            }}
                        >
                            취소
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                validationField()
                            }}
                        >
                            추가
                        </Button>
                    </>
                }
            >
                <FlexColDiv gap="16px">
                    <LabelForm
                        label={"제목"}
                        labelCol={3}
                        required
                    >
                        <Input
                            name="title"
                            placeholder="제목을 입력해주세요."
                            onChange={e => {
                                setTitle(e.value.toString())
                            }}
                            maxLength={60}
                        />
                    </LabelForm>
                    <LabelForm
                        label={"첨부파일"}
                        labelCol={3}
                        required
                    >
                        <ImageUpload
                            imgFiles={uploadFiles}
                            setImgFiles={setUploadFiles}
                            id={0}
                            title={""}
                            subTitle={""}
                            maxSize={10}
                        />
                    </LabelForm>
                </FlexColDiv>
            </Modal>
            <Modal
                type="confirm"
                title="출고현황 이미지 삭제"
                open={deleteOpen.state}
                onClose={() => setDeleteOpen(defaultDeleteStatus)}
                onConfirm={() => {
                    setDeleteOpen(defaultDeleteStatus)
                    deleteImgFile(deleteOpen.id)
                }}
            >
                정말로 삭제하시겠습니까?
            </Modal>
        </>
    )
}

interface deleteModal {
    state: boolean | undefined
    id: number
}

interface fileList {
    createdAt: string
    fileId: number
    fileName: string
    linkInfo: string
    linkKey: number | null
    realName: string
    s3Url: string
    thumbUrl: string | null
}

const defaultDeleteStatus = { state: false, id: -1 }
export default ShippmentStatus
