import React, { useEffect, useMemo, useState } from "react"
import { Order } from "../constants"
import { FlexColDiv, Text } from "@styles"
import { Input, LabelForm, Select } from "p-ui"
import { PaymentInfo } from "@type/orderManageType"

interface OrderEditStatusParam {
    setPreviousStatus: (e: any) => void
    orderStatus: number
    paymentInfo: PaymentInfo[]
}

const OrderEditStatusForm = (props: OrderEditStatusParam) => {
    const { setPreviousStatus, orderStatus, paymentInfo } = props

    const [changeOptions, setChangeOptions] = useState<any[]>([])
    const [selectedStaus, setSelectedStatus] = useState<string>()

    const selectStatus = (value: string) => {
        let result = changeOptions.filter(item => item.value === value)
        setSelectedStatus(result[0]?.label)
    }

    useEffect(() => {
        const newArr = [...Order.orderStep]
        const options: any[] = newArr.slice(2, orderStatus)

        const isVbankBalance = paymentInfo.some(item => item.name.includes("잔금") && item.payMethod === "가상계좌")

        const filteredData = options.filter(
            item => (isVbankBalance && item.value !== 7) || (!isVbankBalance && item.value !== 8)
        )

        setChangeOptions(filteredData)
    }, [orderStatus])

    return (
        <FlexColDiv gap="10px">
            <LabelForm label="현재 상태">
                <Input
                    readonly
                    defaultValue={Order.orderStep[orderStatus]?.label}
                />
            </LabelForm>
            <LabelForm
                label="변경 상태"
                required
            >
                <Select
                    disabled={changeOptions.length === 0}
                    onChange={(label, value) => {
                        setPreviousStatus(value)
                        selectStatus(value as string)
                    }}
                    options={changeOptions}
                />
            </LabelForm>

            <Text
                color="red"
                bold
                margin="20px 0 0 0"
            >
                선택하신 [{selectedStaus ? selectedStaus : "___"}] 상태로 변경하시겠습니까? <br />이 결정은 되돌릴 수
                없으며, 변경 전 신중하게 고려해 주시기 바랍니다.
            </Text>
        </FlexColDiv>
    )
}

export default OrderEditStatusForm
