import apis from "@apis"
import { useMutation } from "@tanstack/react-query"

export const useDeleteFile = () => {
    const fetcher = (param: deleteFile) => apis.File.deleteFile(param.folderName, param.fileId)
    const { mutate } = useMutation({ mutationFn: fetcher })
    return mutate
}

interface deleteFile {
    folderName: String
    fileId: number
}
