import useAlert from "@context/alert/useAlert"
import { useLocation, useNavigate, useParams } from "react-router"
import bikeErrorCode from "src/apis/bikeErrorCode"
import { useEffect, useMemo, useRef, useState } from "react"
import { BikeErrorCodeInfo } from "@type/bbsType"
import { Editor, Viewer } from "@toast-ui/react-editor"
import colorPlugin from "@toast-ui/editor-plugin-color-syntax"
import { BbsViewerContainer, CommonDiv, FlexColDiv, FlexDiv, Text } from "@styles"

import { Button, Input, LabelForm, Modal, PageHeader, Select, TableForm, TableFormLabel, TableFormValue } from "p-ui"
import { bikeErrorCodeValidate } from "@common/util"

const BikeErrorCodeDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const { openAlert, closeAlert } = useAlert()

    const [bikeErrorCodeData, setBikeErrorCodeData] = useState<BikeErrorCodeInfo>()
    const [codeType, setCodeType] = useState([] as any)
    const [editData, setEditData] = useState<BikeErrorCodeInfo>()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [contents, setContents] = useState<string | any>("")
    const [openModal, setOpenModal] = useState<boolean>(false)

    const editorRef = useRef<Editor>(null)

    const regex = /^[a-zA-Z0-9]+$/ //영어+숫자만

    /**
     * Bike Error Code 단건 조회
     * @param id codeId
     */
    const getBikeErrorCode = (id: number | string | undefined) => {
        bikeErrorCode
            .getBikeErrorCode(id)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setBikeErrorCodeData(res.resultData)
                    setEditData(res.resultData)
                    setContents(res.resultData.errorDesc)
                } else {
                    if (res.resultCode === "ERROR_010") {
                        openAlert({
                            title: "실패",
                            body: res.resultMessage.toString(),
                            type: "warning",
                            infinite: true,
                            onClose: () => navigate(-1),
                        })
                    }
                }
            })
            .catch(e => console.log(e))
    }

    /**
     * Bike Error Code 카테고리(구분) 조회
     */
    const getType = () => {
        bikeErrorCode.getBikeErrorCodeType().then(res => {
            if (res.status === "SUCCESS") {
                setCodeType(res.resultData)
            }
        })
    }

    const setEditForm = (name: any, value: any) => {
        setEditData((item: any) => ({ ...item, [name]: value }))
    }

    /**
     * BikeErrorCode 수정
     * @param id codeId
     * @param body
     */
    const updateBikeErrorCode = (body: BikeErrorCodeInfo | undefined) => {
        if (body !== undefined) {
            console.log(body)
            bikeErrorCode.updateBikeErrorCode(body).then(res => {
                if (res.status === "SUCCESS") {
                    setEditMode(false)
                    getBikeErrorCode(res.resultData.codeId)
                    openAlert({ title: "성공", body: "에러코드가 수정이 완료되었습니다.", type: "primary" })
                } else {
                    openAlert({ title: "실패", body: res.resultMessage, type: "warning" })
                }
            })
        }
    }

    /**
     * BikeErrorCode 삭제
     * @param id codeId
     */
    const deleteBikeErrorCode = (id: number | string | undefined) => {
        bikeErrorCode
            .deleteBikeErrorCode(id)
            .then(res => {
                if (res.status === "SUCCESS") {
                    openAlert({ title: "삭제", body: "삭제 완료되었습니다.", type: "primary" })
                    navigate("/bbs/errorcode")
                } else {
                    openAlert({ title: "실패", body: "삭제 실패", type: "warning" })
                }
            })
            .catch(e => console.log(e))
    }

    const checkValidate = () => {
        const { fieldList, notValidMessage } = bikeErrorCodeValidate()

        if (editData !== undefined) {
            const data = editData
            const isNotValidField = fieldList.find(field => !data[field as keyof BikeErrorCodeInfo])

            if (isNotValidField) {
                return openAlert({ title: "실패", body: notValidMessage[isNotValidField].toString(), type: "warning" })
            }
            if (data.errorDesc === "<p><br></p>") {
                return openAlert({ title: "실패", body: "에러코드 설명을 입력해주세요.", type: "warning" })
            }
            if (!regex.test(data.errorCode)) {
                return openAlert({ title: "실패", body: "에러코드명은 영어, 숫자만 입력해주세요", type: "warning" })
            }
            const containsImageTag = /<img\s[^>]*>/i.test(data.errorDesc)

            if (containsImageTag) {
                return openAlert({ title: "실패", body: "이미지는 삽입할 수 없습니다.", type: "warning" })
            }
            updateBikeErrorCode(data)
        }
    }

    useEffect(() => {
        if (id) {
            getBikeErrorCode(id)
        }
        getType()
    }, [])

    useEffect(() => {
        //editor에 이미지 삽입 못하게 처리
        if (editorRef.current) {
            editorRef.current.getInstance().removeHook("addImageBlobHook")
        }
    }, [editorRef.current])

    useEffect(() => {
        setEditForm("errorDesc", contents)
    }, [contents])
    return (
        <div>
            <FlexDiv alignItems="center">
                <PageHeader
                    title={"에러코드 관리"}
                    subTitle="에러코드 상세 내역"
                />
                <FlexDiv
                    gap="16px"
                    justifyContent="flex-end"
                    height="40px"
                >
                    {editMode ? (
                        <>
                            <Button
                                type="secondary"
                                onClick={() => {
                                    setEditMode(false)
                                    setEditData(bikeErrorCodeData)
                                    setContents(bikeErrorCodeData?.errorDesc)
                                }}
                            >
                                취소하기
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    checkValidate()
                                }}
                            >
                                저장하기
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                type="secondary"
                                onClick={() => setEditMode(true)}
                            >
                                수정하기
                            </Button>
                            <Button
                                type="danger"
                                onClick={() => {
                                    setOpenModal(true)
                                }}
                            >
                                삭제하기
                            </Button>
                        </>
                    )}
                </FlexDiv>
            </FlexDiv>

            {editMode ? (
                <FlexColDiv gap="36px">
                    <FlexColDiv
                        width="1200px"
                        gap="8px"
                    >
                        <LabelForm
                            label={"에러코드명"}
                            labelCol={2}
                            required
                        >
                            <Input
                                name="errorCode"
                                placeholder="에러코드명은 영어, 숫자만 입력해주세요."
                                defaultValue={bikeErrorCodeData?.errorCode}
                                onChange={e => {
                                    setEditForm(e.name, e.value)
                                }}
                            />
                        </LabelForm>

                        <LabelForm
                            label="구분"
                            labelCol={2}
                            required
                        >
                            <Select
                                name="codeType"
                                defaultValue={bikeErrorCodeData?.codeLabel}
                                options={codeType.map((item: any) => item.codeLabel)}
                                onChange={(label: string, value: any) => {
                                    setEditForm(label, codeType.find((item: any) => item.codeLabel === value).codeId)
                                }}
                            />
                        </LabelForm>
                    </FlexColDiv>
                    <Editor
                        ref={editorRef}
                        initialValue={bikeErrorCodeData?.errorDesc}
                        initialEditType="wysiwyg"
                        previewStyle="vertical"
                        height="600px"
                        placeholder="에러코드 설명을 입력해주세요."
                        useCommandShortcut={true}
                        plugins={[colorPlugin]}
                        toolbarItems={[["bold", "italic", "strike"]]}
                        onChange={() => setContents(editorRef?.current?.getInstance().getHTML())}
                    />
                </FlexColDiv>
            ) : (
                <FlexColDiv gap="36px">
                    <TableForm>
                        <TableFormLabel md={3}>에러코드명</TableFormLabel>
                        <TableFormValue md={9}>{bikeErrorCodeData?.errorCode}</TableFormValue>
                        <TableFormLabel md={3}>구분</TableFormLabel>
                        <TableFormValue md={9}>{bikeErrorCodeData?.codeLabel}</TableFormValue>
                    </TableForm>
                    <FlexDiv gap="8px">
                        {bikeErrorCodeData && (
                            <CommonDiv margin="-24px 0">
                                <Text
                                    margin="-24px 0"
                                    fontSize="12px"
                                    color="grey"
                                >
                                    pc 버전 미리보기 (1200px)
                                </Text>
                                <BbsViewerContainer $width="1200px">
                                    <div className="viewer-content">
                                        <Viewer initialValue={contents}></Viewer>
                                    </div>
                                </BbsViewerContainer>
                            </CommonDiv>
                        )}
                        {bikeErrorCodeData && (
                            <CommonDiv margin="-24px 0">
                                <Text
                                    fontSize="12px"
                                    color="grey"
                                >
                                    mobile 버전 미리보기 (393px - iphone 14 pro)
                                </Text>
                                <BbsViewerContainer $width="393px">
                                    <div className="viewer-content">
                                        <Viewer initialValue={contents}></Viewer>
                                    </div>
                                </BbsViewerContainer>
                            </CommonDiv>
                        )}
                    </FlexDiv>
                </FlexColDiv>
            )}
            <Modal
                type="confirm"
                title="에러코드 삭제"
                open={openModal}
                onConfirm={() => {
                    setOpenModal(false)
                    deleteBikeErrorCode(id)
                }}
            >
                정말로 삭제하시겠습니까?
            </Modal>
        </div>
    )
}

export default BikeErrorCodeDetail
