import React, { useEffect, useMemo, useState } from "react"

import StationMap from "../Components/StationMap"
import StationFootData from "../Components/StationFootData"
import StationInfo from "../Components/StationInfo"
import { Button, CheckBox, Col, Input, PageHeader, Row, Select, Type as UIType } from "p-ui"
import { FlexBetween, FlexColDiv, FlexDiv, Label } from "@styles"

import STATIONS from "src/apis/station"

import { onChangeFilter } from "src/utils/filter"

import { Station } from "@type/responseType"
import { onChangeParam } from "@type/common"
import { useLocation, useSearchParams } from "react-router-dom"

const StationPage = () => {
    const location = useLocation()

    const [searchParams, setSearchParams] = useSearchParams()

    const searchTypeParam = searchParams?.get("searchType")
    const searchTextParam = searchParams?.get("searchText")
    const statusParam = searchParams.get("status")
    const typeParam = searchParams.get("type")

    const [stationData, setStationData] = useState<Station[]>([])
    const [originList, setOriginList] = useState<Station[]>([])
    const [searchType, setSearchType] = useState<string>("address")

    const [clickedStatusFilter, setClickedStatusFilter] = useState<number[]>(
        statusParam ? statusParam.split(",").map(Number) : []
    )
    const [clickedCenterFilter, setClickedCenterFilter] = useState<number[]>(
        typeParam ? typeParam.split(",").map(Number) : []
    )

    const [searchText, setSearchText] = useState<string>("")
    const [filterText, setFilterText] = useState<string>("")

    // TODO CHANGE :: pop 생성 이후에 검색 필터 정상으로 작동할 수 있게 수정필요
    const filterData = useMemo(() => {
        let filteredResults = [...stationData]

        // 검색 필터
        if (filterText !== "") {
            filteredResults = filteredResults.filter(item => {
                return searchType ? item[searchType]?.includes(filterText) : true
            })
        }

        // 다른 필터
        if (!clickedStatusFilter.includes(3) || !clickedCenterFilter.includes(4)) {
            filteredResults = filteredResults.filter(
                item =>
                    clickedCenterFilter.includes(Number(item.centerType)) &&
                    clickedStatusFilter.includes(Number(item.status))
            )
        }

        if (clickedStatusFilter.length === 0 || clickedCenterFilter.length === 0) {
            filteredResults = []
        }

        return filteredResults
    }, [clickedStatusFilter, filterText, clickedCenterFilter, searchType, stationData])

    const filter = () => {
        if (location.search.length === 0) {
            setClickedStatusFilter([-1, 0, 1, 2, 3, 4])
            setClickedCenterFilter([-1, 0, 1, 2, 3])
            setSearchParams({
                searchText: searchText,
                searchType: searchType,
                type: clickedCenterFilter.toString(),
                status: clickedStatusFilter.toString(),
            })
        } else {
            setSearchParams({
                searchText: searchText,
                searchType: searchType,
                type: clickedCenterFilter.toString(),
                status: clickedStatusFilter.toString(),
            })
        }
    }
    const getMapData = async () => {
        await STATIONS.getStationData({ isAdmin: "admin" }).then(res => {
            if (res.status === "SUCCESS") {
                const stationList = res.resultData.data.map((item: any) => {
                    if (item.tags) {
                        item.tags = JSON.parse(item.tags)
                    }
                    return {
                        ...item,
                        latlng: {
                            lat: item.latitude,
                            lng: item.longitude,
                        },
                        popup: false,
                    }
                })

                setStationData(stationList)
                setOriginList(stationList)
            }
        })
    }

    useEffect(() => {
        getMapData()
        if (searchTextParam) {
            setFilterText(searchTextParam)
            setSearchText(searchTextParam)
        }
        if (searchTypeParam) {
            setSearchType(searchTypeParam)
        } else {
            setSearchType("stationName")
        }
    }, [])
    useEffect(() => {
        filter()
    }, [stationData, clickedCenterFilter, clickedStatusFilter, filterText, searchType])

    return (
        <FlexColDiv height="100%">
            <PageHeader
                title="스테이션"
                subTitle="스테이션 현황"
            />
            <Row gap={"20px"}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv gap="10px">
                        <Select
                            onChange={(name: string, value: any) => {
                                setSearchType(value)
                            }}
                            options={searchOption}
                            defaultValue={searchType}
                            size="small"
                            width="100px"
                        />
                        <Input
                            size="small"
                            width="300px"
                            value={searchTextParam ? searchTextParam : ""}
                            onChange={(e: onChangeParam) => {
                                setSearchText(e.value as string)
                            }}
                            onSearch={() => {
                                setFilterText(searchText)
                            }}
                        />
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label> 상태</Label>
                </Col>
                <Col md={11}>
                    <FlexDiv
                        alignItems="center"
                        gap="15px"
                    >
                        <CheckBox.Group
                            boxWidth={"16px"}
                            boxHeight={"16px"}
                            value={clickedStatusFilter}
                            options={statusFilterOptions}
                            onChange={(value: any) => {
                                const param = {
                                    clickedFilter: clickedStatusFilter,
                                    checkList: value as [],
                                    defaultFilter: defaultStatusFilter,
                                    setClickedFilter: setClickedStatusFilter,
                                    allValue: -1,
                                }
                                onChangeFilter(param)
                            }}
                        />
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>구분</Label>
                </Col>
                <Col md={11}>
                    <FlexBetween
                        alignItems="center"
                        gap="15px"
                    >
                        <FlexDiv
                            alignItems="center"
                            gap="15px"
                        >
                            <CheckBox.Group
                                boxWidth={"16px"}
                                boxHeight={"16px"}
                                value={clickedCenterFilter}
                                options={centerFilterOptions}
                                onChange={(value: any) => {
                                    const param = {
                                        clickedFilter: clickedCenterFilter,
                                        checkList: value as [],
                                        defaultFilter: defaultCenterFilter,
                                        setClickedFilter: setClickedCenterFilter,
                                        allValue: -1,
                                    }
                                    onChangeFilter(param)
                                }}
                            />
                            <Button
                                size="small"
                                type="default"
                                onClick={() => {
                                    setClickedCenterFilter(defaultCenterFilter.concat(-1))
                                    setClickedStatusFilter(defaultStatusFilter.concat(-1))
                                    setSearchText("")
                                    setFilterText("")
                                    setSearchType("stationName")
                                    setStationData(originList)
                                }}
                            >
                                초기화
                            </Button>
                        </FlexDiv>
                        <StationInfo />
                    </FlexBetween>
                </Col>
            </Row>
            <StationMap stationData={filterData} />
            <StationFootData dataList={stationData} />
        </FlexColDiv>
    )
}

export default StationPage

const searchOption = [
    { label: "이름검색", value: "stationName" },
    { label: "도로명검색", value: "addressRoad" },
    { label: "구주소검색", value: "addressRegion" },
]

const defaultStatusFilter = [0, 1, 2, 3, 4]
const defaultCenterFilter = [0, 1, 2, 3]

const statusFilterOptions: UIType.OptionType[] = [
    { label: "전체", value: -1 },
    { label: "설치완료", value: 1 },
    { label: "전기시공", value: 2 },
    { label: "설치예정", value: 0 },
    { label: "설치 문의", value: 3 },
    { label: "협력사 문의", value: 4 },
]

const centerFilterOptions: UIType.OptionType[] = [
    { label: "전체", value: -1 },
    { label: "스테이션", value: 0 },
    { label: "대리점", value: 1 },
    { label: "협력점", value: 2 },
    { label: "직영점", value: 3 },
]
