import { ChangeZentalType } from "@pages/Rental/Components/ContractForm"
import axios from "./config"
import { ZENTAL } from "./urls"
import { Result, ZtBikeData, ZtUser } from "@type/responseType"
export default {
    /**
     * 젠탈 유저 조회
     * @returns {Array}
     */
    /**젠탈 유저 */
    async getZentalUser(): Promise<Result<ZtUser[]>> {
        try {
            return await axios.host.get(ZENTAL.GET_USER)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * 젠탈 유저 등록
     * @param {Object} userData
     * @returns {Result} resultData
     */
    async insertZentalUser(userData: any) {
        try {
            return await axios.host.post(ZENTAL.INSERT_USER, userData)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * 젠탈 유저 업데이트
     * @param {Object} userData
     * @returns {Result} resultData
     */
    async updateZentalUser(userData: any) {
        try {
            return await axios.host.post(ZENTAL.UPDATE_USER, userData)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * 젠탈 유저 단건 조회
     * @param {number} userId 유저아이디
     * @returns {Result} userData
     */
    async getZentalUserOne(userId: number) {
        try {
            return await axios.host.get(ZENTAL.GET_ONE_USER + userId)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * 젠탈 유저삭제
     * @param {number} userId 유저아이디
     * @returns {Result} resultData
     */
    async deleteZentalUser(userId: number) {
        try {
            return await axios.host.delete(ZENTAL.DELETE_USER + userId)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * 젠탈 유저 다중 파일 업로드
     * @param {string} folderName s3폴더 이름 (zental)
     * @param {FormData} formData formdata
     * @returns
     */
    async uploadFileList(folderName: string, formData: any) {
        try {
            return await axios.host.post(ZENTAL.UPLOAD_FILE_LIST + folderName, formData)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * s3 file 단건 삭제
     * @param {string} folderName s3폴더 이름 (zental)
     * @param {number} fileId  filId
     * @returns
     */
    async deleteFile(folderName: String, fileId: number) {
        try {
            return await axios.host.delete(ZENTAL.DELETE_FILE + folderName, { params: { fileId } })
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * 계약 되지 않은 zental user 조회
     * @returns
     */
    async getUnContarctUser() {
        try {
            return await axios.host.get(ZENTAL.UNCONTRACT_USER)
        } catch (e: any) {
            return e.message
        }
    },

    /**젠탈 라이더 */
    async getZentalRider() {
        try {
            return await axios.host.get(ZENTAL.GET_ZENTAL_RIDER)
        } catch (e: any) {
            return e.message
        }
    },

    /** 젠탈 비밀번호 초기화 */
    async resetPassword(riderSn: string) {
        try {
            return await axios.host.put(ZENTAL.RESET_PASS_RIDER + riderSn)
        } catch (e: any) {
            return e.message
        }
    },

    /** 젠탈 라이더 계정 생성 */
    async insertZentalRider() {
        try {
            return await axios.host.post(ZENTAL.INSERT_ZENTAL_RIDER)
        } catch (e: any) {
            return e.message
        }
    },
    /**젠탈 바이크 */
    async getZentalBikeList() {
        try {
            return await axios.host.get(ZENTAL.GET_BIKE_LIST)
        } catch (e: any) {
            return e.message
        }
    },
    async getZentalBikeOne(bikeId: string | number): Promise<Result<ZtBikeData>> {
        try {
            return await axios.host.get(ZENTAL.GET_BIKE + bikeId)
        } catch (e: any) {
            return e.message
        }
    },
    async upDateBikeStatus(bikeInfo: string | number) {
        try {
            return await axios.host.put(ZENTAL.UPDATE_BIKE_STATUS + bikeInfo)
        } catch (e: any) {
            return e.message
        }
    },
    async syncBike() {
        try {
            return await axios.host.post(ZENTAL.SYNC_BIKE)
        } catch (e: any) {
            return e.message
        }
    },
    async getUnContractBike() {
        try {
            return await axios.host.get(ZENTAL.UNCONTRACT_BIKE)
        } catch (e: any) {
            return e.message
        }
    },

    /**젠탈 서비스 */
    async connectService(zentalInfo: any) {
        try {
            return await axios.host.post(ZENTAL.CONNECT_SERVICE, zentalInfo)
        } catch (e: any) {
            return e.message
        }
    },
    async upsertService(serviceInfo: any) {
        try {
            return await axios.host.post(ZENTAL.UPSERT_SERVICE, serviceInfo)
        } catch (e: any) {
            return e.message
        }
    },
    async changeService(serviceInfo: any) {
        try {
            return await axios.host.put(ZENTAL.CHANGE_SERVICE, serviceInfo)
        } catch (e: any) {
            return e.message
        }
    },
    async changeBike(serviceuserid: any) {
        try {
            return await axios.host.put(ZENTAL.CHANGE_BIKE, serviceuserid)
        } catch (e: any) {
            return e.message
        }
    },
    async changeServiceBike(changeBikeDTO: any) {
        try {
            return await axios.host.put(ZENTAL.CHANGE_SERVICE_BIKE, changeBikeDTO)
        } catch (e: any) {
            return e.message
        }
    },
    async getServiceModel(startDt?: string | null) {
        try {
            return await axios.host.get(ZENTAL.GET_SERVICE_MODEL, { params: { startDt } })
        } catch (e: any) {
            return e.message
        }
    },

    async deleteService(membershipId: number, type: string) {
        try {
            return await axios.host.delete(ZENTAL.DELETE_SERVICE + membershipId, { params: { type } })
        } catch (e: any) {
            return e.message
        }
    },

    async updateZentalStatus(updateData: any) {
        try {
            return await axios.host.post(ZENTAL.UPSERT_SERVICE_STATUS, updateData)
        } catch (e: any) {
            return e.message
        }
    },

    /**젠탈 보험 */
    async getInsuranceList() {
        try {
            return await axios.host.get(ZENTAL.GET_INSURANCE)
        } catch (e: any) {
            return e.message
        }
    },
    async insertInsurance(insuranceInfo: any) {
        try {
            return await axios.host.post(ZENTAL.INSERT_INSURANCE, insuranceInfo)
        } catch (e: any) {
            return e.message
        }
    },
    async connectInsurance(insuranceInfo: any) {
        try {
            return await axios.host.put(ZENTAL.CONNECT_INSURANCE, insuranceInfo)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * 핸드폰 번호 중복 체크
     * @param {string} phone 핸드폰 번호
     * @returns {Result}
     */
    async duplicateCheckByPhone(phone: string) {
        try {
            return await axios.host.get(ZENTAL.DUPLICATE_CHECK + phone)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 젠탈 fail cnt 차감
     * @param zentalId
     * @returns
     */
    async cutFailCnt(zentalId: number, count: number) {
        try {
            return await axios.host.put(ZENTAL.CUT_FAIL_CNT + zentalId, { count })
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 젠탈 재결제
     * @param zentalId
     * @returns
     */
    async rePayZental(zentalId: number) {
        try {
            return await axios.host.put(ZENTAL.REPAY + zentalId)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 젠탈 유저의 카드 list 조회
     * @param {number} userId
     * @returns {Result}
     */
    async getUserZtCardList(userId: number) {
        try {
            return await axios.host.get(ZENTAL.GET_ZT_CARD + userId)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 젠탈 카드 업데이트
     * @param {Object} cardData
     * @returns {Result} resultData
     */
    async updateZentalCard(cardData: any) {
        try {
            return await axios.host.post(ZENTAL.UPDATE_ZT_CARD, cardData)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 젠탈 유저의 서비스 이용 이력 조회
     * @param {number} userId
     * @returns {Result}
     */
    async getUserHistory(userId: number) {
        try {
            return await axios.host.get(ZENTAL.GET_USER_HISTORY + userId)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 젠탈 유저의 서비스 별 결제 이력 조회
     * @param {number} zentalId
     * @returns {Result}
     */
    async getZentalPaymentHistory(zentalId: number) {
        try {
            return await axios.host.get(ZENTAL.GET_PAYMENT_HISTORY + zentalId)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 젠탈 카드 업데이트
     * @param {Object} extendData
     * @returns {Result} resultData
     */
    async extendZental(extendData: any) {
        try {
            return await axios.host.post(ZENTAL.EXTEND, extendData)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 젠탈 결제 이력 생성 - 테스트
     * @param {Object} zentalId
     * @returns {Result} resultData
     */
    async createPayment(zentalId: number) {
        try {
            return await axios.host.post(ZENTAL.GET_PAYMENT_HISTORY + zentalId)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * 젠탈 계약 수정
     * @param ChangeZentalType
     * @returns
     */
    async updateZental(zentalId: number, changeZentalType: ChangeZentalType) {
        try {
            return await axios.host.put(ZENTAL.UPSERT_SERVICE + zentalId, changeZentalType)
        } catch (e: any) {
            return e.message
        }
    },
}
