import React, { useEffect, useRef, useState } from "react"
import { Sidebar } from "p-ui"
import { Outlet, useLocation } from "react-router"
import { LayoutDefaultProps } from "@type/index"

import MenuList from "@route/menuList"
import HeaderLayout from "./HeaderLayout"
import { Type } from "p-ui"
import { ServiceMenu } from "@type/common"

const DefaultLayout = ({ children }: LayoutDefaultProps) => {
    const [showButton, setShowButton] = useState(false)
    const [target, setTarget] = useState<any>(null)
    const [sessionMenuList, setSessionMenuList] = useState<Type.Menu[]>([])

    // const handleTop = () => {
    //     target.scrollTo({ top: 0, behavior: "smooth" })
    //     setShowButton(false)
    // }

    const handleScroll = (event: React.UIEvent<HTMLElement>) => {
        if (!target) {
            setTarget(event.currentTarget)
        }
        event.currentTarget.scrollTop > 10 ? setShowButton(true) : setShowButton(false)
    }

    /**
     * 권한 있는 link를 가 있는 객체인지 확인하는 로직
     * @param userMenu
     * @param menu
     * @returns boolean
     */
    const checkPermision = (userMenu: ServiceMenu, menu: Type.Menu): boolean => {
        const isSelf = userMenu.menuUrl == menu.link
        let hasChild = menu.child.some(childMenu => checkPermision(userMenu, childMenu))
        return isSelf || hasChild
    }

    /**
     * 객체 중 사용 가능한 목록만 Check하는 로직
     * @param isExist
     * @param userMenuList
     * @param menu
     * @returns boolean
     */
    const checkUsableMenu = (isExist: boolean, userMenuList: ServiceMenu[], menu: Type.Menu): boolean => {
        userMenuList.forEach(menuInfo => {
            if (menuInfo.used != 1 && menuInfo.menuUrl == menu.link) {
                menu.title = menuInfo.menuNm
                isExist = true
                menuInfo.used = 1
                return true
            }
            // 3 Depth 메뉴 처리 child에 권한 있는 메뉴만 추가
            menu.child.forEach(subMenuInfo => {
                isExist = checkUsableMenu(isExist, userMenuList, subMenuInfo)
            })
        })
        return isExist
    }

    useEffect(() => {
        // Menu List 가져오기
        const filterLink: Type.Menu[] = [...MenuList]
        const userMenuList: ServiceMenu[] = JSON.parse(sessionStorage.getItem("MENU_LIST")!!)

        // 권한이 있는 Menu List 저장하는 장소
        let filterMenuList: Type.Menu[] = []

        //
        userMenuList.forEach(userMenu => {
            // 이미 찾아서 처리된 Menu 리턴
            if (userMenu.used == 1) {
                return
            }
            // 권한이 있는 Menu 객체 찾기
            const useableMenu = filterLink.find(menu => checkPermision(userMenu, menu))

            if (useableMenu) {
                // userMenu.used = 1 처리된 메뉴 Check 로직

                // 1 Depth 메뉴 처리
                // 일찍 리턴
                if (useableMenu.link == userMenu.menuUrl) {
                    userMenu.used = 1
                    filterMenuList.push(useableMenu)
                    return
                }

                // 메뉴 처리
                useableMenu.child = useableMenu.child.filter(usebleSubMenu => {
                    // 권한 있는 메뉴 Check 변수
                    let isExist = false
                    // 2 Depth 메뉴 처리
                    isExist = checkUsableMenu(isExist, userMenuList, usebleSubMenu)
                    return isExist
                })
                // 메뉴 리스트 저장
                filterMenuList.push(useableMenu)
            }
        })
        setSessionMenuList(filterMenuList)
    }, [sessionStorage.getItem("MENU_LIST")])

    return (
        <>
            <div className="wrapper">
                <div className="sidebar-wrapper">
                    {sessionMenuList.length != 0 && <Sidebar menuList={sessionMenuList} />}
                </div>

                <div
                    className="main-wrapper"
                    // onClick={() => closeDropSidebar()}
                >
                    <HeaderLayout menuList={sessionMenuList} />
                    <div
                        className="content-wrapper"
                        onScroll={handleScroll}
                    >
                        {/* children이 있을경우는 children을 없을 경우에는 Outlet을 나타내준다 */}
                        {children || <Outlet />}
                        {/* <button
                            onClick={handleTop}
                            className={classnames(showButton ? "show-btn" : "hidden-btn", "floating-btn")}
                        >
                            ▲
                        </button> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DefaultLayout
