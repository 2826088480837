import { Col as _col, Row as _row } from "react-grid-system"
import styled from "styled-components"

/**
 * TODO DELETE 이거 안쓰는페이지에서 사용하고있어서 살려둠 추후 삭제예정
 */

const $Label = styled(_col)`
    display: inline-block;
    background-color: #f8f8f8;
    height: 50px;
    font-weight: bold;
    line-height: 50px;
    border-radius: 4px;
    color: #5c5c5c;
`

const Col = styled(_col)``

const Row = styled(_row)<{ gap?: string; marginBottom?: number }>`
    row-gap: ${props => props.gap};
    margin-bottom: ${props => props.marginBottom}px;
`
export const Label = styled.div`
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    &.two-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
`
export { $Label, Col, Row }
