import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router"

import { useRecoilState, useRecoilValue } from "recoil"
import { centerParamState, stationImgState, stationParamState } from "src/store/staiton"

import StationForm from "../Components/StationForm"
import CenterForm from "../Components/CenterForm"
import { Button, Col, Modal, PageHeader, Row } from "p-ui"
import { FlexDiv } from "@styles"

import apis from "@apis"

import useAlert from "@context/alert/useAlert"

import { CenterTypes, StationOneType, StationParamType, StationTypes } from "@type/stationType"
import { nowPageState } from "src/store/pageNation"

const StationUpsertPage = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const id = new URLSearchParams(location.search).get("id") as string

    const centerParam = useRecoilValue(centerParamState)
    const stationParam = useRecoilValue(stationParamState)
    const imgData = useRecoilValue(stationImgState)

    const [edit, setEdit] = useState<boolean>(false)
    const [isCenter, setIsCenter] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [stationData, setStationData] = useState<StationOneType>()

    const { openAlert } = useAlert()

    const getStationData = async (id: string) => {
        await apis.Station.getOneStation(id).then(res => {
            if (res.status === "SUCCESS") {
                setStationData(res.resultData as unknown as StationOneType)
            }
        })
    }

    const saveStation = async () => {
        let saveParam = {
            station: stationParam,
            center: centerParam,
        }
        await apis.Station.updateStationData(saveParam as StationParamType).then(async res => {
            if (imgData !== null) {
                const formData = new FormData()
                formData.append("formData", imgData)
                formData.append("linkInfo", "tb_station")
                formData.append("linkKey", res.resultData.stationId)
                formData.append("status", "1")
                await apis.File.uploadeImg("image", formData)
            }
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "저장 완료",
                    body: "스테이션이 등록되었습니다. 스테이션 리스트로 이동합니다.",
                    type: "primary",
                })

                setTimeout(() => {
                    navigate("/station/list")
                }, 2000)
            } else {
                openAlert({
                    title: "실패",
                    body: "저장 실패",
                    type: "warning",
                })
            }
        })
    }

    const deleteCenter = async () => {
        id &&
            (await apis.Station.deleteStationData(Number(id)).then(res => {
                if (res.status == "SUCCESS") {
                    openAlert({
                        title: "삭제",
                        body: "스테이션이 삭제되었습니다. 스테이션 리스트로 이동합니다.",
                        type: "warning",
                    })
                    setTimeout(() => {
                        navigate("/station/list")
                    }, 2000)
                } else {
                    openAlert({
                        title: "실패",
                        body: "저장 실패",
                        type: "warning",
                    })
                }
            }))
    }

    /**
     * 센터 유효성 검사
     */
    const validateCenter = () => {
        const form: CenterTypes = centerParam
        const formFields: string[] = ["centerNm", "telNo", "zipCode", "bisNo"]
        const message: { [key: string]: string } = {
            centerNm: "지점명을 입력해주세요",
            telNo: "전화번호를 입력해주세요",
            zipCode: "주소 검색을 다시 해주세요",
            bisNo: "사업자 번호를 입력해주세요",
        }
        validateAction(form, formFields, message)
    }

    /**
     * 스테이션 유효성 검사
     */
    const validateStation = () => {
        const form: StationTypes = stationParam
        const formFields: string[] = ["stationName", "addressRoad", "status"]
        const message: { [key: string]: string } = {
            stationName: "스테이션 이름을 입력해주세요",
            addressRoad: "주소를 입력해주세요",
            status: "설치 상태를 선택해주세요",
        }
        if (isCenter) {
            validateCenter()
        } else {
            validateAction(form, formFields, message)
        }
    }

    /**
     * validation 및 유효성 검사 통과 시 save action 호출
     * @param form
     * @param formFields
     * @param message
     */
    const validateAction = (form: any, formFields: string[], message: { [key: string]: string }) => {
        const field = formFields.find(field => !form[field])
        if (field) {
            alert(message[field])
        } else {
            if (isCenter && !isValidNumber(form.telNo)) {
                alert("전화번호는 숫자와 하이픈(-)으로만 입력해주세요.")
            } else if (isCenter && !isValidNumber(form.bisNo)) {
                alert("사업자 번호는 숫자와 하이픈(-)으로만 입력해주세요.")
            } else {
                saveStation()
            }
        }
    }

    /**
     * 숫자 + 하이픈 검증 (복합 사용)
     * @param value
     * @returns
     */
    const isValidNumber = (value: string) => {
        return /^(\d+-)+\d+$/g.test(value)
    }

    useEffect(() => {
        if (id) {
            getStationData(id)
        }
    }, [])

    return (
        <div>
            <PageHeader
                title="스테이션"
                subTitle="스테이션 등록/수정"
            />
            <FlexDiv
                gap="5px"
                justifyContent="flex-end"
            >
                {id ? (
                    <Button
                        onClick={() => (edit ? validateStation() : setEdit(!edit))}
                        size="small"
                        type="primary"
                    >
                        {edit ? "저장" : "수정"}
                    </Button>
                ) : (
                    <Button
                        onClick={validateStation}
                        size="small"
                        type="primary"
                    >
                        등록
                    </Button>
                )}
                <Button
                    disabled={!id}
                    size="small"
                    type="danger"
                    onClick={() => {
                        setDeleteModal(true)
                    }}
                >
                    삭제
                </Button>
            </FlexDiv>
            <Row>
                <Col sm={6}>
                    <StationForm
                        edit={edit}
                        id={id}
                        stationData={stationData}
                    />
                </Col>
                <Col sm={6}>
                    <CenterForm
                        isCenter={isCenter}
                        setIsCenter={setIsCenter}
                        edit={edit}
                        id={id}
                        getStationData={getStationData}
                        stationData={stationData}
                    />
                </Col>
            </Row>
            <Modal
                open={deleteModal}
                type="confirm"
                onConfirm={deleteCenter}
                onClose={() => {
                    setDeleteModal(false)
                }}
                onCancel={() => {
                    setDeleteModal(false)
                }}
            >
                스테이션을 삭제 하시겠습니까?
            </Modal>
        </div>
    )
}

export default StationUpsertPage
