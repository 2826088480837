import React, { useMemo, useState } from "react"
import { AlertStateContext, AlertDispatchContext } from "./AlertContext"
import Alerts from "./Alerts"

const AlertProvider = ({ children }: any) => {
    const [openedAlert, setOpenedAlert] = useState([])

    const open = (Component: any, props: any) => {
        setOpenedAlert((alerts): any => {
            return [...alerts, { Component, props }]
        })
    }

    const close = (Component: any) => {
        setOpenedAlert((alerts: any) => {
            return alerts.filter((alert: any) => {
                return alert.Component !== Component
            })
        })
    }

    const dispatch = useMemo(() => ({ open, close }), [])

    return (
        <AlertStateContext.Provider value={openedAlert}>
            <AlertDispatchContext.Provider value={dispatch}>
                {children}
                <Alerts />
            </AlertDispatchContext.Provider>
        </AlertStateContext.Provider>
    )
}

export default AlertProvider
