import { Button, TableForm, TableFormLabel, TableFormValue } from "p-ui"

import { useSetRecoilState } from "recoil"
import { zentalModalState } from "src/store/zental"

import { FlexBetween } from "@styles"

function RiderInfoForm(props: any) {
    const { setModalOpen, bikeData, resetPassAction } = props
    return (
        <div>
            <FlexBetween
                alignItems="center"
                margin="0 0 1rem 0"
            >
                <h4>라이더 정보</h4>
            </FlexBetween>
            <TableForm>
                <TableFormLabel sm={3}>라이더 계정</TableFormLabel>
                <TableFormValue sm={9}>{bikeData?.riderEmail || "-"}</TableFormValue>
                <TableFormLabel sm={3}>비밀번호</TableFormLabel>
                <TableFormValue sm={9}>
                    <Button
                        type="danger"
                        width="100%"
                        onClick={() => {
                            setModalOpen(() => ({
                                confirmModalState: {
                                    isOpen: true,
                                    title: "패스워드 초기화",
                                    contents: "라이더 계정의 비밀번호를 초기화 합니다",
                                    cb: () => resetPassAction(),
                                },
                            }))
                        }}
                        disabled={!bikeData.riderNm}
                    >
                        비밀번호 초기화
                    </Button>
                </TableFormValue>
            </TableForm>
        </div>
    )
}

export default RiderInfoForm
