import React, { useState, useEffect } from "react"

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { zentalDataState, connectParamState, serviceUserConnectParamState } from "src/store/zental"

import { CommonDiv, DataPickerInput, FlexDiv } from "@styles"
import { Col, Input, LabelForm, Row, Select } from "p-ui"

import moment from "moment"

import { depositOptions, discountOptions } from "./ZentalConstant"
import CustomDatePicker from "@components/widgets/customDatePicker"
import ReactDatePicker from "react-datepicker"
import { ko } from "date-fns/locale"
import { format } from "date-fns"

const ServiceConnectModal = (props: any) => {
    const { bikeData, options, setRiderSn } = props

    const zentalData = useRecoilValue<any>(zentalDataState)
    const [modelOption, setModelOption] = useState<{ label: any; value: any }[]>([
        { label: "모델을 선택해주세요", value: -1 },
    ])
    const [userOption, setUserOption] = useState<{ label: any; value: any }[]>([])

    const [connectParam, setConnectParam] = useRecoilState(connectParamState)
    const setServiceUserConnectParam = useSetRecoilState(serviceUserConnectParamState)

    let today = new Date()

    let year = today.getFullYear()
    let month = ("0" + (today.getMonth() + 1)).slice(-2)
    let day = ("0" + today.getDate()).slice(-2)

    let dateString = year + "-" + month + "-" + day

    const setData = (name: string | any, value: string | any) => {
        switch (name) {
            case "modelId":
            case "discount":
                setConnectParam(prev => ({ ...prev, [name]: value }))
                break
            case "startDt":
                setConnectParam(prev => ({ ...prev, ["startDt"]: value }))
                setServiceUserConnectParam(prev => ({ ...prev, ["startDt"]: value }))
                break
            case "rider":
                setRiderSn(value)
                break
            default:
                setServiceUserConnectParam(prev => ({ ...prev, [name]: value }))
                break
        }
    }

    useEffect(() => {
        setServiceUserConnectParam(prev => ({
            ...prev,
            startDt: dateString,
            status: "active",
            bikeId: Number(bikeData.bikeId),
        }))
        setConnectParam(prev => ({ ...prev, startDt: dateString, status: 1 }))
    }, [])

    useEffect(() => {
        let newArr: { label: any; value: any }[] = [{ label: "모델을 선택해주세요", value: -1 }]

        zentalData.serviceModelState.map((item: any) => {
            newArr.push({ label: `${item.modelName} (${item.price.toLocaleString()})`, value: item.modelId })
        })

        setModelOption(newArr)
    }, [])

    useEffect(() => {
        let newUserArr: { label: any; value: any }[] = []
        zentalData.userState.map((item: any) => {
            newUserArr.push({
                label: `${item.userName} / ${
                    item.userType == 0 ? moment(item.birthDt).format("YYYY.MM.DD") : item.licenseNo
                }`,
                value: item.userId,
            })
        })
        setUserOption(newUserArr)
    }, [])

    return (
        <FlexDiv justifyContent="center">
            <CommonDiv width="500px">
                <Row gap="10px">
                    <Col sm={12}>
                        <LabelForm label="모델">
                            <Select
                                searchable
                                defaultValue={-1}
                                onChange={(label, value) => {
                                    setData(label, value)
                                }}
                                name="modelId"
                                size="small"
                                options={modelOption}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <LabelForm label="할인액">
                            <Select
                                searchable
                                defaultValue={-1}
                                onChange={(label, value) => {
                                    setData(label, value)
                                }}
                                name="discount"
                                size="small"
                                options={discountOptions}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <LabelForm label="라이더 연결">
                            {
                                bikeData.riderNm ? (
                                    <Input
                                        readonly
                                        defaultValue={bikeData.riderNm}
                                    />
                                ) : (
                                    <Select
                                        name="rider"
                                        size="small"
                                        options={options}
                                        onChange={(label, value) => {
                                            setData(label, value)
                                        }}
                                    />
                                ) /**라이더 연결할 옵션 들어가야함 */
                            }
                        </LabelForm>
                    </Col>

                    <Col sm={12}>
                        <LabelForm label="사용자">
                            <Select
                                searchable
                                onChange={(label, value) => {
                                    setData(label, value)
                                }}
                                name="userId"
                                size="small"
                                options={userOption}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <LabelForm label="보증금">
                            <Select
                                onChange={(label, value) => {
                                    setData(label, value)
                                }}
                                name="deposit"
                                size="small"
                                options={depositOptions}
                                defaultValue={depositOptions[0].value}
                            />
                        </LabelForm>
                    </Col>

                    <Col sm={12}>
                        <LabelForm label="계약 시작일">
                            <CustomDatePicker width={"100%"}>
                                <ReactDatePicker
                                    selected={
                                        connectParam["startDt"] != "" ? new Date(connectParam["startDt"]) : new Date()
                                    }
                                    onChange={(date: any) => setData("startDt", format(date, "yyyy-MM-dd"))}
                                    locale={ko} // 한글로 변경
                                    selectsStart
                                    dateFormat="yyyy-MM-dd"
                                    showPopperArrow={false} // 화살표 변경
                                    customInput={<DataPickerInput />}
                                />
                            </CustomDatePicker>
                            {/* <Input
                                name="startDt"
                                placeholder="2001-01-01"
                                defaultValue={dateString}
                                onChange={(e: any) => setData(e.name, e.value)}
                            ></Input> */}
                        </LabelForm>
                    </Col>
                </Row>
            </CommonDiv>
        </FlexDiv>
    )
}

export default ServiceConnectModal
