import React, { useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router"

import { useResetRecoilState } from "recoil"
import { centerParamState, stationParamState } from "src/store/staiton"

import { Button, CheckBox, Col, DataTable, Input, PageHeader, Row, Select, Tag, Type as UIType } from "p-ui"
import { FlexDiv, Label, Text } from "@styles"

import STATIONS from "src/apis/station"

import { downloadExcel, formatPhone, observPage, updateActivePage } from "@common/util"
import { onChangeFilter } from "src/utils/filter"

import { StationManage } from "@common/constatns"

import { NumberObjectType, onChangeParam } from "@type/common"
import { Station } from "@type/responseType"
import { useSearchParams } from "react-router-dom"
import { PageRow } from "p-ui/dist/esm/types"
const StationListPage = () => {
    const tableRef = useRef<HTMLTableElement | null>(null)

    const [searchParams, setSearchParams] = useSearchParams()
    const pageParam = searchParams.get("page")
    const searchTypeParam = searchParams?.get("searchType")
    const searchTextParam = searchParams?.get("searchText")
    const statusParam = searchParams.get("status")
    const typeParam = searchParams.get("type")
    const limitParam = searchParams.get("limit")

    const [nowPage, setNowPage] = useState<any>(pageParam !== null ? pageParam : 1)

    const [stationData, setStationData] = useState<Station[]>([])
    const [searchType, setSearchType] = useState("")

    const [clickedStatusFilter, setClickedStatusFilter] = useState<number[]>(
        statusParam ? statusParam.split(",").map(Number) : []
    )
    const [clickedCenterFilter, setClickedCenterFilter] = useState<number[]>(
        typeParam ? typeParam.split(",").map(Number) : []
    )
    const [pageRows, setPageRows] = useState<PageRow>(15)
    const [searchText, setSearchText] = useState<string>("")
    const [filterText, setFilterText] = useState<string>("")
    const navigate = useNavigate()
    const location = useLocation()

    const resetStation = useResetRecoilState(stationParamState)
    const resetCenter = useResetRecoilState(centerParamState)

    const centerTypeTag = (type: number) => {
        switch (type) {
            case 0:
                return "warning"
            case 1:
                return "success"
            case 2:
                return "primary"
            case 3:
                return "danger"
            default:
                return "default"
        }
    }

    const getMapData = async () => {
        await STATIONS.getStationData({ isAdmin: "admin" }).then(res => {
            if (res.status === "SUCCESS") {
                const stationList = res.resultData.data.map((item: any) => {
                    if (item.tags) {
                        item.tags = JSON.parse(item.tags)
                    }
                    return {
                        ...item,
                        stationTag:
                            item.centerType != null ? (
                                <Tag
                                    type={centerTypeTag(item.centerType)}
                                    value={STATUS.CENTER[item.centerType]}
                                />
                            ) : (
                                <>-</>
                            ),
                        stationAddress: `${item.addressRoad} ${item.addressDetail}`,
                        statusText: item.codeLabel,
                        telNo: formatPhone(item.telNo),
                        stationStatus: (
                            <Text
                                fontWeight={900}
                                color={STATUS.COLOR[item.status]}
                            >
                                {item.codeLabel}
                            </Text>
                        ),
                        etc: (
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    navigate({ pathname: "/station/upsert", search: "?id=" + item.stationId })
                                }}
                            >
                                상세보기 →
                            </Button>
                        ),
                    }
                })

                setStationData([...stationList])
            }
        })
    }

    const filterData = useMemo(() => {
        let filteredResults = stationData

        // 검색 필터
        if (filterText !== "") {
            filteredResults = filteredResults.filter(item => {
                return searchType ? item[searchType]?.includes(filterText) : true
            })
        }

        // 다른 필터
        if (!clickedStatusFilter.includes(3) || !clickedCenterFilter.includes(4)) {
            filteredResults = filteredResults.filter(
                item =>
                    clickedCenterFilter.includes(Number(item.centerType)) &&
                    clickedStatusFilter.includes(Number(item.status))
            )
        }

        if (clickedStatusFilter.length === 0 || clickedCenterFilter.length === 0) {
            filteredResults = []
        }

        return filteredResults
    }, [clickedStatusFilter, stationData, filterText, clickedCenterFilter, searchType])

    const filter = () => {
        if (location.search.length === 0) {
            setClickedStatusFilter([-1, 0, 1, 2, 3, 4])
            setClickedCenterFilter([-1, 0, 1, 2, 3])
            setSearchParams({
                searchText: searchText,
                searchType: searchType,
                type: clickedCenterFilter.toString(),
                status: clickedStatusFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        } else {
            setSearchParams({
                searchText: searchText,
                searchType: searchType,
                type: clickedCenterFilter.toString(),
                status: clickedStatusFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        }
    }

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)
            if (newActivePage === 0) {
                setNowPage(Number(pageParam))
                return
            }
            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    useEffect(() => {
        getMapData()
        resetStation()
        resetCenter()
        if (searchTextParam) {
            setFilterText(searchTextParam)
            setSearchText(searchTextParam)
        }
        if (limitParam) {
            setPageRows(Number(limitParam) as unknown as PageRow)
        }
        if (searchTypeParam) {
            setSearchType(searchTypeParam)
        } else {
            setSearchType("stationName")
        }
    }, [])

    useEffect(() => {
        filter()
    }, [stationData, clickedCenterFilter, clickedStatusFilter, nowPage, pageRows])

    return (
        <>
            <PageHeader
                title="스테이션"
                subTitle="스테이션 관리"
            />
            <Row gap={"20px"}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv gap="10px">
                        <Select
                            onChange={(name: string, value: any) => {
                                setSearchType(value)
                            }}
                            options={searchOption}
                            defaultValue={searchType}
                            size="small"
                            width="100px"
                        />
                        <Input
                            size="small"
                            width="300px"
                            value={searchTextParam ? searchTextParam : ""}
                            onChange={(e: onChangeParam) => {
                                setSearchText(e.value as string)
                            }}
                            onSearch={() => {
                                setNowPage(1)
                                setFilterText(searchText)
                                filter()
                            }}
                        />
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label> 상태</Label>
                </Col>
                <Col md={11}>
                    <FlexDiv
                        alignItems="center"
                        gap="15px"
                    >
                        <CheckBox.Group
                            boxWidth={"16px"}
                            boxHeight={"16px"}
                            value={clickedStatusFilter}
                            options={statusFilterOptions}
                            onChange={(value: any) => {
                                const param = {
                                    clickedFilter: clickedStatusFilter,
                                    checkList: value as [],
                                    defaultFilter: defaultStatusFilter,
                                    setClickedFilter: setClickedStatusFilter,
                                    allValue: -1,
                                }
                                setNowPage(1)
                                onChangeFilter(param)
                            }}
                        />
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>구분</Label>
                </Col>
                <Col md={11}>
                    <FlexDiv
                        alignItems="center"
                        gap="15px"
                    >
                        <CheckBox.Group
                            boxWidth={"16px"}
                            boxHeight={"16px"}
                            value={clickedCenterFilter}
                            options={centerFilterOptions}
                            onChange={(value: any) => {
                                const param = {
                                    clickedFilter: clickedCenterFilter,
                                    checkList: value as [],
                                    defaultFilter: defaultCenterFilter,
                                    setClickedFilter: setClickedCenterFilter,
                                    allValue: -1,
                                }
                                setNowPage(1)
                                onChangeFilter(param)
                            }}
                        />
                        <Button
                            size="small"
                            type="default"
                            onClick={() => {
                                setClickedCenterFilter(defaultCenterFilter.concat(-1))
                                setClickedStatusFilter(defaultStatusFilter.concat(-1))
                                setSearchText("")
                                setFilterText("")
                                setSearchType("stationName")
                                setNowPage(1)
                            }}
                        >
                            초기화
                        </Button>
                    </FlexDiv>
                </Col>
            </Row>

            <div ref={tableRef}>
                <DataTable
                    pagination
                    header={
                        <FlexDiv
                            alignItems="center"
                            gap="5px"
                        >
                            <Button
                                size="small"
                                onClick={() => downloadExcel(stationData, excelHeader, "스테이션 리스트_")}
                            >
                                엑셀
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate("/station/upsert")
                                }}
                                type="primary"
                                size="small"
                            >
                                신규 등록
                            </Button>
                        </FlexDiv>
                    }
                    nowPage={Number(pageParam)}
                    columns={StationManage.tableColumn}
                    dataList={filterData}
                    pageRow={pageRows}
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(limitParam) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                    numbering
                />
            </div>
        </>
    )
}

export default StationListPage
interface Status {
    TEXT: NumberObjectType
    COLOR: NumberObjectType
    CENTER: NumberObjectType
}

export const STATUS: Status = {
    TEXT: {
        0: "• 설치예정",
        1: "• 운영중",
        2: "• 전기시공",
        3: "• 설치 문의",
        4: "• 협력사 문의",
    },
    CENTER: {
        0: "본사",
        1: "대리점",
        2: "협력점",
        3: "직영점",
    },
    COLOR: {
        0: "#ff653e",
        1: "#00cb45",
        2: "#ff7f00",
        3: "#6284fd",
        4: "#6284fd",
    },
}
const defaultStatusFilter = [0, 1, 2, 3, 4]
const defaultCenterFilter = [0, 1, 2, 3]

const excelHeader = [
    { label: "도로명주소", value: "addressRoad" },
    { label: "구주소", value: "addressRegion" },
    { label: "상세주소", value: "addressDetail" },
    { label: "우편번호", value: "zipCode" },
    { label: "스테이션명", value: "stationName" },
    { label: "상태", value: "statusText" },
    { label: "센터명", value: "centerNm" },
    { label: "전화번호", value: "telNo" },
]
const searchOption = [
    { label: "이름검색", value: "stationName" },
    { label: "도로명검색", value: "addressRoad" },
    { label: "구주소검색", value: "addressRegion" },
]

const statusFilterOptions: UIType.OptionType[] = [
    { label: "전체", value: -1 },
    { label: "설치완료", value: 1 },
    { label: "전기시공", value: 2 },
    { label: "설치예정", value: 0 },
    { label: "설치 문의", value: 3 },
    { label: "협력사 문의", value: 4 },
]

const centerFilterOptions: UIType.OptionType[] = [
    { label: "전체", value: -1 },
    { label: "스테이션", value: 0 },
    { label: "대리점", value: 1 },
    { label: "협력점", value: 2 },
    { label: "직영점", value: 3 },
]
