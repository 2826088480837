import React, { useEffect, useState, useMemo, useRef } from "react"
import { useLocation, useNavigate } from "react-router"

import { Row, Col, PageHeader, Button, DataTable, CheckBox, Input, Type as UIType } from "p-ui"
import { MembershipCountWrap, MembershipHeaderWrap } from "../styles"
import { FlexDiv, Label } from "@styles"

import apis from "@apis"

import Utils from "@utils"
import { downloadExcel, observPage, updateActivePage } from "@common/util"
import { onChangeFilter } from "src/utils/filter"

import { membershipColumn } from "../constants"

import { MembershipTable } from "@type/membershipType"
import { onChangeParam } from "@type/common"
import { useSearchParams } from "react-router-dom"
import { PageRow } from "p-ui/dist/esm/types"

const MembershipMainPage = () => {
    const currentDate = new Date()
    const navigate = useNavigate()
    const location = useLocation()

    const tableRef = useRef<HTMLTableElement | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const searchTextParam = searchParams?.get("searchText")
    const pageParam = searchParams.get("page")
    const statusParam = searchParams.get("status")
    const limitParam = searchParams.get("limit")

    const [nowPage, setNowPage] = useState<any>(pageParam !== null ? pageParam : 1)

    const [loading, setLoading] = useState<boolean>(true)
    const [membershipList, setMembershipList] = useState<MembershipTable[]>([])
    const [clickedFilter, setClickedFilter] = useState<number[]>(statusParam ? statusParam.split(",").map(Number) : [])

    const [searchText, setSearchText] = useState<string>("")
    const [filterText, setFilterText] = useState<string>("")

    const [pageRows, setPageRows] = useState<PageRow>(15)

    const statusCnt = useMemo(() => {
        let cnt = {
            all: membershipList.length || 0,
            subscribe: 0,
            subscribeEnd: 0,
            reserveUnSub: 0,
            unsubscribe: 0,
            pause: 0,
        }

        membershipList.forEach((item: any) => {
            switch (item.statusCode) {
                case 1: // 일시정지
                    cnt.pause++
                    break
                case 5: // 종료
                    cnt.subscribeEnd++
                    break
                case 4: // 해지 예정
                    cnt.reserveUnSub++
                    break
                case 6: // 미구독
                    cnt.unsubscribe++
                    break
                default:
                    // 0: 결제 실패, 2: 구독중
                    cnt.subscribe++
                    break
            }
        })

        return cnt
    }, [membershipList])

    const filterData = useMemo(() => {
        let filteredResults = membershipList

        // 검색 필터
        if (filterText !== "") {
            filteredResults = filteredResults.filter(item => JSON.stringify(item).includes(filterText))
        }

        // 다른 필터
        if (!clickedFilter.includes(0)) {
            filteredResults = filteredResults.filter(item => clickedFilter.includes(item.statusCode))
        }

        if (clickedFilter.length === 0) {
            filteredResults = []
        }

        return filteredResults
    }, [clickedFilter, membershipList, filterText])

    const getMembership = async (endDt?: string | null, startDt?: string | null) => {
        await apis.Membership.getMembership(endDt, startDt).then(res => {
            setLoading(false)

            if (res.status === "SUCCESS") {
                const newArr = res.resultData.map((item: MembershipTable) => {
                    let textColor = ""
                    let statusCode = 0

                    switch (item.status) {
                        case "일시정지":
                            textColor = "warning"
                            statusCode = 1
                            break
                        case "해지 예정":
                            textColor = "zen-orange"
                            statusCode = 4
                            break
                        case "종료":
                            textColor = "danger"
                            statusCode = 5
                            break
                        case "미구독":
                            textColor = "secondary"
                            statusCode = 6
                            break
                        default:
                            if (item.status.includes("실패")) {
                                textColor = "danger"
                                statusCode = 2
                            } else {
                                textColor = "success"
                                statusCode = 3
                            }
                    }

                    const paymentDate = item.endDate
                        ? Utils.stringToDate(item.endDate).plus(1, "d").format(Utils.FORMAT_DATE)
                        : "-"

                    return {
                        ...item,
                        statusCode: statusCode,
                        originStatus: item.status,
                        status: <span style={{ color: `var(--${textColor})` }}>{item.status}</span>,
                        purchaserPhone: Utils.formatPhone(item.purchaserPhone),
                        paymentDate,
                        detail: (
                            <Button
                                size="small"
                                type="primary"
                                onClick={() => {
                                    navigate({ pathname: "/membership/detail/" + item.bikeSerial })
                                }}
                            >
                                상세보기
                            </Button>
                        ),
                    }
                })

                setMembershipList(newArr)
            }
        })
    }

    const filter = () => {
        if (location.search.length === 0) {
            setClickedFilter([0, 1, 3, 4, 5, 6])
            setSearchParams({
                searchText: searchText,
                status: clickedFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        } else {
            setSearchParams({
                searchText: searchText,
                status: clickedFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        }
    }

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)
            if (newActivePage === 0) {
                setNowPage(Number(pageParam))
                return
            }
            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    useEffect(() => {
        setLoading(true)

        getMembership(currentDate.format(Utils.FORMAT_DATE), currentDate.format(Utils.FORMAT_DATE))
        if (limitParam) {
            setPageRows(Number(limitParam) as unknown as PageRow)
        }
        if (searchTextParam) {
            setFilterText(searchTextParam)
            setSearchText(searchTextParam)
        }
    }, [])

    useEffect(() => {
        filter()
    }, [membershipList, clickedFilter, nowPage, pageRows])

    return (
        <div>
            <PageHeader
                title="고객 관리"
                subTitle="멤버십 현황"
            />
            <Row gap={"20px"}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <MembershipHeaderWrap>
                        <Input
                            value={searchText}
                            width="300px"
                            onChange={(e: onChangeParam) => {
                                setSearchText(e.value as string)
                            }}
                            onSearch={() => {
                                setNowPage(1)
                                setFilterText(searchText)
                                filter()
                            }}
                            size="small"
                        />
                    </MembershipHeaderWrap>
                </Col>
                <Col sm={1}>
                    <Label>구독 상태</Label>
                </Col>
                <Col md={11}>
                    <FlexDiv
                        alignItems="center"
                        gap="15px"
                    >
                        <CheckBox.Group
                            value={clickedFilter}
                            options={filterOptions}
                            onChange={(value: any) => {
                                const param = {
                                    clickedFilter: clickedFilter,
                                    checkList: value as [],
                                    defaultFilter: defaultFilter,
                                    setClickedFilter: setClickedFilter,
                                    allValue: 0,
                                }
                                setNowPage(1)
                                onChangeFilter(param)
                            }}
                        />
                        <Button
                            size="small"
                            type="default"
                            onClick={() => {
                                setClickedFilter(defaultFilter.concat(0))
                                setSearchText("")
                                setFilterText("")
                                setNowPage(1)
                            }}
                        >
                            초기화
                        </Button>
                    </FlexDiv>
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    headerLeft={
                        <Button
                            size="small"
                            onClick={() => {
                                downloadExcel([...filterData], excelHeader, "멤버십현황")
                            }}
                        >
                            엑셀
                        </Button>
                    }
                    headerRight={
                        <MembershipCountWrap>
                            <span className="membership-total">전체 {statusCnt.all} 건</span>
                            <span className="membership-subscribing">구독중 {statusCnt.subscribe} 건</span>
                            <span className="membership-pause">일시정지 {statusCnt.pause} 건</span>
                            <span className="membership-end">종료 {statusCnt.subscribeEnd} 건</span>
                            <span className="membership-cancellation">해지 예정 {statusCnt.reserveUnSub} 건</span>
                            <span className="membership-disSub">미구독 {statusCnt.unsubscribe} 건</span>
                        </MembershipCountWrap>
                    }
                    nowPage={nowPage}
                    pagination
                    columns={membershipColumn}
                    dataList={filterData}
                    loading={loading}
                    pageRow={pageRows}
                    numbering
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(limitParam) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default MembershipMainPage

const defaultFilter = [1, 3, 4, 5, 6]

const filterOptions: UIType.OptionType[] = [
    { label: "전체", value: 0 },
    { label: "구독중", value: 3 },
    { label: "일시정지", value: 1 },
    { label: "해지 예정", value: 4 },
    { label: "종료", value: 5 },
    { label: "미구독", value: 6 },
]
const excelHeader = [
    { label: "시리얼 번호", value: "bikeSerial" },
    { label: "구매자", value: "purchaserNm" },
    { label: "휴대폰번호", value: "purchaserPhone" },
    { label: "구독중인 멤버십", value: "membershipName" },
    { label: "상태", value: "originStatus" },
    { label: "시작일", value: "startDate" },
    { label: "종료일", value: "endDate" },
    { label: "다음 결제일", value: "paymentDate" },
]
