import { Type } from "p-ui"
export const requestColum = [
    {
        title: "요청 서비스",
        dataIndex: "serviceCode",
    },

    {
        title: "유형",
        dataIndex: "reqType",
    },
    {
        title: "중요도",
        dataIndex: "priority",
    },
    {
        title: "제목",
        dataIndex: "reqTitle",
    },
    {
        title: "요청자",
        dataIndex: "reqUserNm",
    },
    {
        title: "진행 상태",
        dataIndex: "taskStatus",
    },
    {
        title: "등록 일자",
        dataIndex: "createdAt",
    },
    {
        title: "예상 완료일",
        dataIndex: "completionDt",
    },
    {
        title: "상세보기",
        dataIndex: "detail",
    },
]

export const req_type: Type.OptionType[] = [
    { label: "선택", value: "select", disabled: true },
    { label: "이슈", value: 1 },
    { label: "수정", value: 2 },
    { label: "신규개발", value: 3 },
]
export const service_code: Type.OptionType[] = [
    { label: "선택", value: "select", disabled: true },
    { label: "ZEUS", value: 1 },
    { label: "NZEUS", value: 2 },
    { label: "모바일", value: 3 },
    { label: "홈페이지", value: 4 },
    { label: "콜렉터(수집서버)", value: 5 },
    { label: "관제알림", value: 6 },
    { label: "멤버십", value: 7 },
    { label: "젠탈", value: 8 },
    { label: "발주서비스", value: 9 },
]

export const req_orgNm: Type.OptionType[] = [
    { label: "선택", value: "select", disabled: true },
    { label: "서비스운영", value: "서비스운영" },
    { label: "사업개발", value: "사업개발" },
    { label: "배터리", value: "배터리" },
    { label: "이륜차", value: "이륜차" },
    { label: "FW/HW", value: "FW/HW" },
    { label: "제로팩토리", value: "제로팩토리" },
]
export const priority: Type.OptionType[] = [
    {
        label: "낮음",
        value: 1,
    },
    {
        label: "보통",
        value: 2,
    },
    {
        label: "높음",
        value: 3,
    },
    {
        label: "긴급",
        value: 4,
    },
]

export const task_status: Type.OptionType[] = [
    {
        label: "요청",
        value: 1,
    },
    {
        label: "검토",
        value: 2,
    },
    {
        label: "반려",
        value: 3,
    },
    {
        label: "보류",
        value: 4,
    },
    {
        label: "작업",
        value: 5,
    },
    {
        label: "완료",
        value: 6,
    },
]

export const search_type: Type.OptionType[] = [
    {
        label: "제목",
        value: "originReqTitle",
    },
    {
        label: "요청자",
        value: "reqUserNm",
    },
]

export const filter_type: Type.OptionType[] = [
    {
        label: "전체",
        value: "all",
    },
    {
        label: "요청 서비스",
        value: "originServiceCode",
    },
    {
        label: "유형",
        value: "originReqType",
    },
    {
        label: "중요도",
        value: "originPriority",
    },
    {
        label: "진행 상태",
        value: "originTaksStatus",
    },
]

export const editorPlaceHolder = `
업무요청 작성시 유의사항
-----------------------------------------------------------------------------------------------------------------------

    1. 제목은 반드시 주요 작업 내용이 포함 되어야합니다.
        예시:  ”멤버십 문의건” ( X )
        ”멤버십 해지 상태 문의건” (O)

        “홈페이지 수정 요청” ( X )
        ”홈페이지 구매 페이지 모터사이클 이미지 수정 요청” ( O )

    2. 요청사항은 상세한 내용 적어주시면 원할한 검토가 가능합니다.
        * 요청하는 페이지의 url 을 기재하면 담당자가 바로 확인 할 수 있습니다.
        * 제품, 주문, 고객상태 문의일 경우 제품의 시리얼번호, 고객 이름과 휴대폰번호, 주문 번호 등을 기재해주세요.
        
    3. 요청 업무의 중요도는 시스템 개발 팀 검토 후 중요도가 조절 될 수도 있습니다.

    4. * 표시된 항목은 필수 기재 항목입니다.
`

export const issuePlaceHolder = `
문제사항 작성시 유의사항
----------------------------------------------------------------------------------------------------------------------

    1. 문제사항 설명 이미지나 링크를 포함해주시면 원할한 검토가 가능합니다.
        * 문제사항이 일어난 페이지 화면 캡쳐나 url을 기재하면 담당자가 바로 확인 할 수 있습니다.
        * 이슈 발생 날짜와 시간을 같이 기재해주세요.
        * 이미지파일 (png,jpg...)만 첨부 가능

    2. 문제사항이 없을 경우 해당 항목은 작성하지 않으셔도 됩니다.
`
