import { useState, useEffect } from "react"

import CustomDatePicker from "@components/widgets/customDatePicker"
import ReactDatePicker from "react-datepicker"
import { Input, LabelForm } from "p-ui"
import { DataPickerInput, FlexColDiv } from "@styles"

import { ko } from "date-fns/locale"
import utils from "@utils"
import { setHours, setMinutes } from "date-fns"
import { areDatesEqual, extractTimeFromDateString } from "src/utils/date"

const UsabledModal = (props: any) => {
    const { setEditForm, rentalFleetData } = props
    const { startDt } = rentalFleetData
    const currentDate = new Date()
    const [selectedDt, setSelectedDt] = useState(currentDate)
    const [minDate, setMinDate] = useState(currentDate)
    const [isSetMinTime, setIsSetMinTime] = useState(true)

    useEffect(() => {
        setSelectedDt(new Date(startDt))
        setEditForm("endDt", startDt)
        setMinDate(new Date(rentalFleetData.startDt))
    }, [])

    return (
        <FlexColDiv gap="16px">
            <LabelForm label="종료담당자">
                <Input
                    name="endMgrNm"
                    placeholder={"종료담당자 이름을 입력하세요."}
                    onChange={(e: any) => {
                        setEditForm(e.name, e.value)
                    }}
                />
            </LabelForm>
            <LabelForm label="종료일">
                <CustomDatePicker
                    width={"100%"}
                    height={"50px"}
                >
                    <ReactDatePicker
                        selected={selectedDt}
                        onChange={(date: Date) => {
                            setSelectedDt(date)
                            setEditForm("endDt", date.format(utils.FORMAT_SHORT_DATETIME))
                            setIsSetMinTime(areDatesEqual(date, new Date(startDt)))
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd HH:mm"
                        minDate={minDate}
                        showTimeSelect
                        timeIntervals={5}
                        minTime={setHours(
                            setMinutes(new Date(), isSetMinTime ? extractTimeFromDateString(startDt).minutes : 0),
                            isSetMinTime ? extractTimeFromDateString(startDt).hours : 0
                        )}
                        maxTime={setHours(setMinutes(new Date(), 30), 23)}
                        customInput={<DataPickerInput height={"50px"} />}
                        onKeyDown={e => {
                            e.preventDefault()
                        }}
                    />
                </CustomDatePicker>
            </LabelForm>
        </FlexColDiv>
    )
}

export default UsabledModal
