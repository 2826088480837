import { ImgProps, SettingDivProp, TextAlignProps, TextProps } from "@styles"
import styled from "styled-components"

/** text 정렬할때
 * @props
 * @align - string
 */
export const TextAlignDiv = styled.div<TextAlignProps>`
    text-align: ${props => props.align || "center"};
`

/**  span 으로 사용하는 text들
 * @props TextProps
 *  color?: string
    bold?: boolean
    fontSize?: string
    fontWeight?: number | string
    margin?: string
    padding?: string
    textAlign?: string
    display?: string - string
    decoration?: string
    cursor?: string

 */

export const Text = styled.span<TextProps>`
    color: ${props => props.color};
    font-weight: ${props => props.fontWeight || (props.bold && "bold") || "normal"};
    font-size: ${props => props.fontSize};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    text-align: ${props => props.textAlign};
    line-height: ${props => props.lineHeigt};
    display: ${props => props.display};
    width: ${props => props.width};
    text-decoration: ${props => props.decoration};
    cursor: ${props => props.cursor};
`

/** 이미지 박스
 * @props
 *  size?: string
    border?: string
    borderRadius: string
 */

export const ImgDiv = styled.div<ImgProps>`
    min-width: 150px;
    min-height: 150px;
    width: ${props => props.size};
    height: ${props => props.size};
    border: ${props => props.border};
    border-radius: ${props => props.borderRadius};
    background-image: ${props => `url(${props.backGroundImg})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${props => props.backGroundSize || "contain"};
    background-color: ${props => props.backGroundColor};
    cursor: ${props => props.cursor};
`

/**  보험연결, 대리점 연결등.. 연결할때 쓰는 큰 버튼 박스
 * @props
 * height?: string
 */

export const SettingDiv = styled.div<SettingDivProp>`
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 16px;
    height: ${props => props.height || "100%"};
`

/**  투명 버튼
 */
export const TransparentBtn = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
`
