import styled from "styled-components"
import { Col, Row } from "react-grid-system"
import DatePicker from "react-datepicker"

export const ChartTitle = styled.div<{ marginBottom?: number }>`
    margin-bottom: ${props => props.marginBottom + "rem" ?? "1rem"};
    font-weight: bold;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
`
export const FlexCol = styled(Col)<{ flexWrap?: string }>`
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    flex-wrap: ${props => props.flexWrap};
`

export const MarginRow = styled(Row)`
    margin-bottom: 10px;
`
export const ButtonWrap = styled(Col)`
    padding-left: 0px !important;
    display: flex;
    // justify-content: space-between;
`

export const RegFormDatePicker = styled(DatePicker)`
    height: 40px;
    line-height: inherit;
    display: flex;
    background: #f8f8f8;
    border: 1px solid #f8f8f8;
    border-radius: 8px;
    position: relative;
    padding: 0 16px;
    cursor: pointer;
    :disabled {
        background-color: #efefef;
        border-color: #8a8a8a;
    }
`

export const MainDashBoardBox = styled.div<{ height?: number; padding?: number }>`
    height: ${props => props.height ?? props.height + "px"};
    width: 100%;
    background-color: white !important;
    border-radius: 10px;
    padding: 30px ${props => (props.padding != undefined ? props.padding : 20)}px;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
`

export const MainDashBoardChartBox = styled.div<{ height?: number }>`
    height: ${props => (props.height != undefined ? props.height + "px" : "")};
    width: 100%;
    background-color: white !important;
    border-radius: 10px;
    padding: 20px 20px;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
`

export const MainDashBoardTextBox = styled(MainDashBoardChartBox)`
    height: 100%;
    padding-bottom: 4rem;
`
