import { useEffect, useState } from "react"

import { DataTable } from "p-ui"
import { WhiteSpaceWrap, Title } from "../RentalFleetList/style"

import rentalFleet from "src/apis/rentalFleet"

import { formatYYYYMMDDhhmm } from "@common/util"

import { RentalFleet } from "@common/constatns"

const BikeHistory = (props: any) => {
    const { rentalFleetBikeData, historyData, setHistoryData, setLastEndDate } = props
    const [filterData, setFilterData] = useState([] as any)

    /**
     * 대차 이력 조회
     * @param bikeSerial 바이크시리얼
     */
    const getRentalFleetHistory = (bikeSerial: string) => {
        rentalFleet
            .getRentalFleetHistory(bikeSerial)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setHistoryData(res.resultData)
                    setLastEndDate(res.resultData[0].endDt)
                }
            })
            .catch(err => console.log(err))
    }

    const filterDataList = () => {
        return historyData.map((item: any) => {
            const depositValue =
                item.deposit !== null && item.deposit !== undefined ? "(" + item.deposit.toLocaleString() + ")" : ""
            return {
                ...item,
                leaseUserNm: item.leaseUserNm || "-",
                leaseReason: <WhiteSpaceWrap>{item.leaseReason || "-"}</WhiteSpaceWrap>,
                startMgrNm: item.startMgrNm || "-",
                startDt: <WhiteSpaceWrap>{formatYYYYMMDDhhmm(item.startDt)}</WhiteSpaceWrap>,
                endMgrNm: item.endMgrNm || "-",
                endDt: <WhiteSpaceWrap>{formatYYYYMMDDhhmm(item.endDt)}</WhiteSpaceWrap>,
                depositStatus: item.depositStatus + depositValue,
                comment: <WhiteSpaceWrap>{item.comment}</WhiteSpaceWrap>,
            }
        })
    }

    useEffect(() => {
        const { bikeSerial } = rentalFleetBikeData || {}
        if (bikeSerial) {
            getRentalFleetHistory(bikeSerial)
        }
    }, [rentalFleetBikeData])

    useEffect(() => {
        setFilterData(filterDataList())
    }, [historyData])

    return (
        <>
            {historyData && (
                <>
                    <Title>대차 이력</Title>
                    <DataTable
                        columns={RentalFleet.history}
                        dataList={filterData}
                        pagination
                        numbering
                    />
                </>
            )}
        </>
    )
}

export default BikeHistory
