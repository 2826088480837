import React, { useEffect, useRef } from "react"

import { CommonDiv } from "@styles"

import { Editor, Viewer } from "@toast-ui/react-editor"
import colorSyntax from "@toast-ui/editor-plugin-color-syntax"
import "@toast-ui/editor/dist/i18n/ko-kr"
import "@toast-ui/editor/dist/toastui-editor.css"
import "tui-color-picker/dist/tui-color-picker.css"
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css"
import "@toast-ui/editor/dist/toastui-editor-viewer.css"

const ToastEditor = ({ htmlStr, setHtmlStr, isViewer, isInsert }: any) => {
    const editorRef = useRef<Editor>(null)

    useEffect(() => {
        if (isInsert) {
            setHtmlStr(`<h2><strong>인입 내용</strong></h2>\n\n<p><br class='ProseMirror-trailingBreak'></p>
            <h2><strong>현장 확인</strong></h2>\n\n<p><br class='ProseMirror-trailingBreak'></p>
            <h2><strong>조치 내용</strong></h2>\n\n<p><br class='ProseMirror-trailingBreak'></p>`)
        }
    }, [])

    return (
        htmlStr &&
        (isViewer ? (
            <CommonDiv padding="0 24px">
                <Viewer initialValue={htmlStr} />
            </CommonDiv>
        ) : (
            <Editor
                initialValue={htmlStr}
                plugins={[colorSyntax]}
                previewStyle="vertical"
                height="600px"
                initialEditType="wysiwyg"
                useCommandShortcut={true}
                language="ko-KR"
                onChange={() => setHtmlStr(editorRef.current?.getInstance().getHTML())}
                ref={editorRef}
            />
        ))
    )
}

export default ToastEditor
