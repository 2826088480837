import React, { useState } from "react"

import { Input, LabelForm, Select } from "p-ui"
import { FlexColDiv } from "@styles"

import { Codes } from "@type/codeType"

const CodeForm = (props: any) => {
    const { code, parentOption, handleSubmit, submitButton, type } = props
    const [selectedCategory, setSelectedCategory] = useState<string>(code.parentCode.split("_")[1])
    const [newCode, setNewCode] = useState<Codes>(code)

    /**
     * 상위 컴포넌트에서 데이터 관리 하기 위해 함수 반환하는 submit 함수
     * @param event any
     * @returns handleSubmit function
     */
    const submit = (event: any) => {
        event.preventDefault()
        const formData = new FormData(event.target)
        const newC = formData.get("code")?.toString() ?? ""
        const newCL = formData.get("codeLabel")?.toString() ?? ""
        newCode.code = newC
        newCode.codeLabel = newCL
        return handleSubmit(newCode, type)
    }

    return (
        <form onSubmit={submit}>
            <FlexColDiv gap="10px">
                <LabelForm label="상위코드">
                    <Select
                        defaultValue={selectedCategory}
                        options={parentOption}
                        name="parentCode"
                        onChange={(label: string, value: any) => setNewCode({ ...newCode, parentCode: `AS_${value}` })}
                    />
                </LabelForm>
                <LabelForm label="Code">
                    <Input
                        defaultValue={code.code}
                        name="code"
                    />
                </LabelForm>
                <LabelForm label="코드 라벨">
                    <Input
                        defaultValue={code.codeLabel}
                        name="codeLabel"
                    />
                </LabelForm>
            </FlexColDiv>
            <button
                ref={submitButton}
                style={{ display: "none" }}
            />
        </form>
    )
}

export default CodeForm
