import { useEffect, useRef, useState, useMemo } from "react"

import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { Button, CheckBox, Col, DataTable, Icon, Input, Loading, PageHeader, Row, Select, Tag } from "p-ui"
import { WhiteSpaceWrap } from "./style"

import rentalFleet from "src/apis/rentalFleet"

import { RentalFleet, RentalFleetStatus } from "@common/constatns"

import { Type } from "p-ui"
import { updateActivePage, observPage } from "@common/util"
import { FlexDiv, Label } from "@styles"
import { onChangeFilter } from "src/utils/filter"
import { PageRow } from "p-ui/dist/esm/types"

const RentalFleetList = () => {
    const tableRef = useRef<HTMLTableElement | null>(null)

    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchTextParam = searchParams?.get("searchText")
    const typeParam = searchParams.get("type")
    const pageParam = searchParams.get("page")
    const limitParam = searchParams.get("limit")

    const column: dataInfo[] = RentalFleet.bike

    const [rentalFleetList, setRentalFleetList] = useState([] as any)
    const [filterList, setFilterList] = useState([] as any)
    const [clickedTypeFilter, setClickedTypeFilter] = useState<number[]>(
        typeParam ? typeParam.split(",").map(Number) : []
    )
    const [searchQuery, setSearchQuery] = useState("")
    const [pageRows, setPageRows] = useState<PageRow>(15)
    const [isLoading, setIsLoading] = useState(false)

    const [nowPage, setNowPage] = useState<any>(pageParam !== null ? pageParam : 1)

    /**
     * 전체 대차 리스트 조회
     */
    const getRentalFleetList = () => {
        rentalFleet
            .getRentalFleetList()
            .then(res => {
                if (res.status === "SUCCESS") {
                    setRentalFleetList(res.resultData)
                    const result: any = res.resultData
                    const closestRow: Type.PageRow = rows.find(row => result.length <= row) || 100
                    !limitParam && setPageRows(closestRow)
                }
            })
            .catch(error => console.log(error))
    }

    /**
     * 테이블 컬럼 커스텀하기
     * @param item
     * @returns
     */
    const commonMapping = (item: any) => ({
        ...item,
        leaseType: (
            <Tag
                value={item.leaseType}
                type={
                    (item.leaseType &&
                        RentalFleetStatus.find((item2: any) => item2.label === item.leaseType)?.tagType) ||
                    "primary"
                }
            />
        ),
        leaseUserNm: item.leaseUserNm || "-",
        leaseReason: <WhiteSpaceWrap>{item.leaseReason || "-"}</WhiteSpaceWrap>,
        comment: <WhiteSpaceWrap>{item.comment || "-"}</WhiteSpaceWrap>,
        detail: (
            <Button
                size="small"
                type="primary"
                onClick={() => {
                    navigate("/rental_fleet/detail", { state: { bikeSerial: item.bikeSerial } })
                }}
            >
                상세보기
            </Button>
        ),
    })

    /**
     * 검색필터링
     */
    const searchList = useMemo(() => {
        const filteredCheckValue = RentalFleetStatus.filter(item => clickedTypeFilter.includes(item.value)).map(
            item => item.label
        )
        const filteredData = rentalFleetList
            .filter((item: any) => filteredCheckValue.includes(item.leaseType))
            .filter((item: any) => searchQuery === "" || item.bikeNo.includes(searchQuery))

        return filteredData
    }, [searchQuery, clickedTypeFilter, rentalFleetList])

    const filter = () => {
        if (location.search.length === 0) {
            setClickedTypeFilter([0, 1, 2, 3, 4, 99])
            setSearchParams({
                searchText: searchQuery,
                type: RentalFleetStatus.map((item: any) => item.value).toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        } else {
            setSearchParams({
                searchText: searchQuery,
                type: clickedTypeFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        }

        const searchedList = [...searchList]
        const filteredList = searchedList.map(commonMapping)
        setFilterList(filteredList)
    }

    const handleEnterPress = (event: any) => {
        if (event.key === "Enter") {
            setNowPage(1)
            filter()
        }
    }

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)

            if (newActivePage === 0) {
                return setNowPage(Number(pageParam))
            }

            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    useEffect(() => {
        getRentalFleetList()
        if (searchTextParam) {
            setSearchQuery(searchTextParam)
        }
        if (limitParam) {
            setPageRows(Number(limitParam) as unknown as PageRow)
        }
    }, [])

    useEffect(() => {
        filter()
    }, [rentalFleetList, clickedTypeFilter, nowPage, pageRows])

    return (
        <>
            <PageHeader
                title={"대차"}
                subTitle={"전체 대차 관리"}
            />
            <Loading open={isLoading} />
            <Row gap={"20px"}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv gap="16px">
                        <Select
                            size="small"
                            width="100px"
                            defaultValue={"번호판"}
                            options={["번호판"]}
                        />
                        <Input
                            width={"300px"}
                            height={42}
                            value={searchQuery}
                            onChange={(e: any) => setSearchQuery(e.value)}
                            onSearch={() => {
                                setNowPage(1)
                                filter()
                            }}
                            onKeyUp={handleEnterPress}
                        />
                        <Button
                            size="small"
                            type="secondary"
                            compact
                            onClick={() => {
                                setIsLoading(true)
                                window.location.reload()
                            }}
                        >
                            <Icon.Refresh />
                        </Button>
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>대차상태</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv gap="16px">
                        <CheckBox.Group
                            value={clickedTypeFilter}
                            options={RentalFleetStatus}
                            onChange={(value: any) => {
                                const param = {
                                    clickedFilter: clickedTypeFilter,
                                    checkList: value as [],
                                    defaultFilter: [0, 1, 2, 3, 4],
                                    setClickedFilter: setClickedTypeFilter,
                                    allValue: 99,
                                }
                                onChangeFilter(param)
                                setNowPage(1)
                                filter()
                            }}
                        />
                    </FlexDiv>
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    columns={column}
                    dataList={filterList}
                    pagination={true}
                    pageRow={pageRows}
                    nowPage={nowPage}
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(limitParam) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                    headerRight={
                        <Link
                            to={"https://zentropyproject.notion.site/6b05390208894a888225d3984b9b2c58?pvs=4"}
                            target="_blank"
                        >
                            <Tag
                                value={"📗매 뉴 얼"}
                                type={"default"}
                            />
                        </Link>
                    }
                />
            </div>
        </>
    )
}

export default RentalFleetList

export interface dataInfo {
    title: string
    dataIndex: string
    render?: (params: any) => void
}

const rows: Type.PageRow[] = [5, 10, 15, 20, 30, 50, 100]
