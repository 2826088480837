import React, { useState } from "react"
import { useNavigate } from "react-router"
import Pagination from "./Pagination"
import { Select } from "p-ui"
import { TableHeader, $TableOptionPanel, Label, Table, Th, Td, PaginationDiv } from "./styles"

/**
 * Data Table 생성
 * @param columns : {title: string, dataIndex: string, render: function }
 * @returns
 */
export const DataTables = ({ columns, dataList, router, pagination, isIssue }: any) => {
    const [perPage, setPerpage] = useState(10)
    const [page, setPage] = useState(1)
    const offset = (page - 1) * perPage

    const handlePerPageChange = (_perPage: number) => {
        setPerpage(_perPage)
    }

    const navigate = useNavigate()

    const perPageList = [
        { label: "10", value: 10 },
        { label: "15", value: 15 },
        { label: "20", value: 20 },
        { label: "30", value: 30 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
    ]
    return (
        <>
            {pagination && (
                <TableHeader>
                    <$TableOptionPanel>
                        <div>
                            <Label htmlFor="perpage">보기</Label>
                            <Select
                                options={perPageList}
                                defaultValue={perPageList[0].value}
                                size="small"
                                width="60px"
                                onChange={(name, value) => {
                                    handlePerPageChange(Number(value))
                                }}
                            />
                        </div>
                        {isIssue && <div>검색 된 이슈 : {dataList?.length} 건</div>}
                    </$TableOptionPanel>
                </TableHeader>
            )}
            <Table>
                <thead>
                    <tr>
                        {columns?.map((column: any, i: number) => {
                            return <Th key={i}>{column.title}</Th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {dataList?.length > 0 ? (
                        (dataList?.slice(offset, offset + perPage) || []).map((data: any, index: number) => {
                            return (
                                <tr key={index.toString()}>
                                    {columns.map((column: any, i: number) => {
                                        return (
                                            <Td
                                                onClick={() =>
                                                    column.title == "제목" &&
                                                    navigate({
                                                        pathname: router.pathname,
                                                        search: `${router.search}=${data.issueId}`,
                                                    })
                                                }
                                                className={column.title == "제목" ? "title-td-panel" : ""}
                                                key={i}
                                            >
                                                {column.render
                                                    ? column.render(data[column.dataIndex])
                                                    : data[column.dataIndex]}
                                            </Td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                    ) : (
                        <tr>
                            <td colSpan={columns.length}>{"데이터가 없습니다"}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {pagination && (
                <PaginationDiv className="patient-pagination">
                    <Pagination
                        postsPerPage={perPage} //보여줄 limit 값
                        totalPosts={dataList?.length} // total 값
                        paginate={setPage} // 페이지 셋팅함수
                        page={page} // 기본 페이지
                    />
                </PaginationDiv>
            )}
        </>
    )
}
