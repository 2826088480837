import { DataPickerInput, DatePickerSpan, FlexColDiv, FlexDiv, ImgDiv } from "@styles"
import { Button, CheckBox, Input, LabelForm, PageHeader, Select } from "p-ui"
import React, { useEffect, useState } from "react"
import { ko } from "date-fns/locale"
import CustomDatePicker from "@components/widgets/customDatePicker"
import ReactDatePicker from "react-datepicker"
import ImageUpload from "@components/widgets/ImageUpload"
import { useNavigate, useParams } from "react-router"
import { formatYYYYMMDDhhmm } from "@common/util"
import events from "src/apis/event"
import { PopupParamType } from "@type/bbsType"
import apis from "@apis"
import useAlert from "@context/alert/useAlert"

const EventUpsertPage = () => {
    const { id } = useParams()
    const { openAlert } = useAlert()
    const navigate = useNavigate()

    const currentDate = new Date()

    const [popupSeq, setPopupSeq] = useState<number>(1)
    const [startDate, setStartDate] = useState(currentDate)
    const [endDate, setEndDate] = useState<Date>(new Date("2099-12-31"))
    const [uploadFiles, setUploadFiles] = useState([])

    const [isEdit, setIsEdit] = useState(false)
    const [popupParam, setPopupParam] = useState({
        endDate: "",
        linkType: 0,
        popupLink: "",
        popupTitle: "",
        popupWidth: 0,
        startDate: "",
        imageUrl: "",
        popupSeq: 1,
    })
    const changeInput = (e: any) => {
        setPopupParam({ ...popupParam, [e.name]: e.value })
    }
    const upsertParam = async () => {
        if (uploadFiles.length === 0 && popupParam.imageUrl === "") {
            openAlert({
                title: "알림",
                body: "이미지를 업로드해야 합니다.",
                type: "warning",
            })
            return
        } else {
            await events.updateEvent(popupParam as PopupParamType).then(async res => {
                if (uploadFiles.length > 0) {
                    const formData = new FormData()
                    uploadFiles.map((item: any) => {
                        formData.append("formData", item.object)
                    })
                    formData.append("linkInfo", "tb_popup")
                    formData.append("linkKey", res.resultData.popupId)
                    formData.append("status", "1")
                    await apis.File.uploadeImg("image", formData)
                }

                if (res.status === "SUCCESS") {
                    openAlert({
                        title: "저장 완료",
                        body: "팝업이 등록 되었습니다. 팝업 리스트 페이지로 이동합니다.",
                        type: "primary",
                    })

                    setTimeout(() => {
                        navigate("/bbs/event")
                    }, 2000)
                } else {
                    openAlert({
                        title: "실패",
                        body: "저장 실패",
                        type: "warning",
                    })
                }
            })
        }
    }
    const deletePopup = () => {
        events.deleteEvent(id as unknown as number).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "삭제 완료",
                    body: "팝업이 삭제 되었습니다. 팝업 리스트 페이지로 이동합니다.",
                    type: "primary",
                })

                setTimeout(() => {
                    navigate("/bbs/event")
                }, 2000)
            } else {
                openAlert({
                    title: "실패",
                    body: "삭제 실패",
                    type: "warning",
                })
            }
        })
    }
    const getPopupOne = () => {
        events.getEventOne(id as unknown as number).then(res => {
            if (res.status === "SUCCESS") {
                setPopupParam(res.resultData)
                setStartDate(new Date(res.resultData.startDate))
                setEndDate(new Date(res.resultData.endDate))
            }
        })
    }
    useEffect(() => {
        if (id === "insert") {
            setIsEdit(true)
            setPopupParam({
                ...popupParam,
                startDate: formatYYYYMMDDhhmm(startDate as unknown as string),
                endDate: formatYYYYMMDDhhmm(endDate as unknown as string),
            })
        } else {
            getPopupOne()
        }
    }, [id])
    return (
        <div>
            <PageHeader title={id == "insert" ? "팝업 등록" : "팝업 수정"} />
            <FlexDiv
                justifyContent="flex-end"
                gap="5px"
                margin="0 0 10px 0"
            >
                {id !== "insert" ? (
                    <Button
                        onClick={() => (isEdit ? upsertParam() : setIsEdit(true))}
                        size="small"
                        type="primary"
                    >
                        {isEdit ? "저장" : "수정"}
                    </Button>
                ) : (
                    <Button
                        onClick={() => {
                            upsertParam()
                        }}
                        size="small"
                        type="primary"
                    >
                        등록
                    </Button>
                )}

                <Button
                    size="small"
                    type="danger"
                    onClick={() => {
                        deletePopup()
                    }}
                >
                    삭제
                </Button>
            </FlexDiv>
            <FlexDiv gap="30px">
                <FlexColDiv gap="5px">
                    <LabelForm
                        required
                        label="팝업 제목"
                    >
                        <Input
                            name="popupTitle"
                            disabled={!isEdit}
                            onChange={e => {
                                changeInput(e)
                            }}
                            value={popupParam?.popupTitle}
                        />
                    </LabelForm>

                    <LabelForm
                        required
                        label="순서"
                    >
                        <Select
                            disabled={!isEdit}
                            defaultValue={popupParam?.popupSeq !== 0 ? popupParam?.popupSeq : popupSeq}
                            options={[
                                { label: "1", value: 1 },
                                { label: "2", value: 2 },
                                { label: "3", value: 3 },
                                { label: "4", value: 4 },
                            ]}
                            onChange={(label, value) => {
                                setPopupSeq(value as number)
                                setPopupParam({ ...popupParam, popupSeq: value as number })
                            }}
                        />
                    </LabelForm>
                    <LabelForm
                        required
                        label="게시기간"
                    >
                        <FlexDiv gap="5px">
                            <CustomDatePicker>
                                <ReactDatePicker
                                    selected={startDate}
                                    disabled={!isEdit}
                                    onChange={(date: any) => {
                                        setStartDate(date)
                                        setPopupParam({ ...popupParam, startDate: formatYYYYMMDDhhmm(date) })
                                    }}
                                    locale={ko} // 한글로 변경
                                    selectsStart
                                    showTimeSelect
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="yyyy.MM.dd (eee) HH:mm"
                                    showPopperArrow={false} // 화살표 변경
                                    customInput={<DataPickerInput />}
                                />
                            </CustomDatePicker>
                            <DatePickerSpan> ~ </DatePickerSpan>
                            <CustomDatePicker>
                                <ReactDatePicker
                                    disabled={!isEdit}
                                    selected={endDate}
                                    onChange={(date: any) => {
                                        setEndDate(date)
                                        setPopupParam({ ...popupParam, endDate: formatYYYYMMDDhhmm(date) })
                                    }}
                                    locale={ko}
                                    showTimeSelect
                                    dateFormat="yyyy.MM.dd (eee) HH:mm"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    showPopperArrow={false}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    customInput={<DataPickerInput />}
                                />
                            </CustomDatePicker>
                        </FlexDiv>
                    </LabelForm>
                    <LabelForm
                        required
                        label="이미지 등록"
                    >
                        {isEdit ? (
                            <ImageUpload
                                id={0}
                                title={""}
                                subTitle={""}
                                maxSize={10}
                                imgFiles={uploadFiles}
                                setImgFiles={setUploadFiles}
                            />
                        ) : (
                            <ImgDiv
                                border="1px solid #dddd"
                                borderRadius="4px"
                                backGroundImg={popupParam?.imageUrl}
                                size={"150px"}
                            />
                        )}
                    </LabelForm>
                    <LabelForm
                        required
                        label="이미지 링크"
                    >
                        <FlexDiv gap="5px">
                            <Input
                                name="popupLink"
                                disabled={!isEdit}
                                value={popupParam?.popupLink}
                                onChange={e => {
                                    changeInput(e)
                                }}
                                placeholder="https://"
                            />
                            <CheckBox
                                checked={popupParam?.linkType === 1}
                                onChange={e => {
                                    setPopupParam({ ...popupParam, linkType: e ? 1 : 0 })
                                }}
                                disabled={!isEdit}
                                label="새 창에서 열기"
                            />
                        </FlexDiv>
                    </LabelForm>
                    <LabelForm
                        required
                        label="너비"
                    >
                        <FlexDiv
                            alignItems="center"
                            gap="5px"
                        >
                            <Input
                                maxLength={4}
                                value={
                                    popupParam?.popupWidth.toString() == "0" ? "440" : popupParam?.popupWidth.toString()
                                }
                                type="number"
                                name="popupWidth"
                                disabled={!isEdit}
                                onChange={e => {
                                    changeInput(e)
                                }}
                            />
                            <span>px</span>
                        </FlexDiv>
                    </LabelForm>
                    <p>* 이미지가 잘릴경우 너비를 줄여주세요</p>
                </FlexColDiv>
                <div>
                    <img
                        style={{ width: "500px" }}
                        src={`${process.env.PUBLIC_URL}/images/event${popupSeq}.png`}
                    />
                </div>
            </FlexDiv>
        </div>
    )
}

export default EventUpsertPage
