import { Button, Col, Input, LabelForm, Modal, Row, TableForm, TableFormLabel, TableFormValue, Tooltip } from "p-ui"

import { FlexBetween, FlexColDiv, Text } from "@styles"
import { useState } from "react"
import useAlert from "@context/alert/useAlert"

function BikeInfoForm(props: any) {
    const { setModalOpen, bikeData, changePermit, cutFailCnt } = props

    const { openAlert } = useAlert()

    const [failCntModal, setFailCntModal] = useState<boolean>(false)
    const [cutCnt, setCutCnt] = useState<number>(0)

    /**
     * 유효성 검사 - 횟수 차감
     */
    const validation = () => {
        const failCnt = Number(bikeData.failCnt)

        if (failCnt == 0) {
            openAlert({ title: "ERROR", body: "차감할 실패 횟수가 존재하지 않습니다", type: "warning" })
            return true
        }

        if (cutCnt == 0) {
            openAlert({ title: "ERROR", body: "정확한 값을 입력해주세요.", type: "warning" })
            return true
        }

        if (cutCnt > failCnt) {
            openAlert({ title: "ERROR", body: "실패 이력보다 차감할 이력이 많습니다.", type: "warning" })
            return true
        }
        cutFailCnt(cutCnt)
        resetModal()
    }

    const resetModal = () => {
        setCutCnt(0)
        setFailCntModal(false)
    }
    return (
        <div>
            <FlexBetween
                alignItems="center"
                margin="0 0 1rem 0"
            >
                <h4>차량 정보</h4>
            </FlexBetween>
            {bikeData.vinNo && (
                <TableForm>
                    <TableFormLabel sm={2}>차대번호</TableFormLabel>
                    <TableFormValue sm={4}>{bikeData.vinNo}</TableFormValue>
                    <TableFormLabel sm={2}>시리얼 번호</TableFormLabel>
                    <TableFormValue sm={4}>{bikeData.serialNo}</TableFormValue>

                    <TableFormLabel sm={2}>누적실패이력</TableFormLabel>
                    <TableFormValue
                        sm={4}
                        isDefaultWrap={false}
                    >
                        <div className="flex-between">
                            <Text color="red">{`${bikeData.failCnt || 0}/3`}</Text>
                            <Button
                                size="small"
                                onClick={() => setFailCntModal(true)}
                            >
                                차감
                            </Button>
                        </div>
                    </TableFormValue>
                    <TableFormLabel sm={2}>교환 상태</TableFormLabel>
                    <TableFormValue sm={4}>
                        <div className="flex-between">
                            <Text color={bikeData.authPermitAt == "n" ? "red" : "black"}>
                                {bikeData.authPermitAt == "y" ? "가능" : "불가"}
                            </Text>
                            <Button
                                size="small"
                                onClick={() =>
                                    setModalOpen(() => ({
                                        confirmModalState: {
                                            isOpen: true,
                                            title: "교환 상태 변경",
                                            contents: `교환 상태를 '${
                                                bikeData.authPermitAt == "y" ? "불가" : "가능"
                                            }' 로 변경하시겠습니까?`,
                                            cb: () => changePermit(bikeData.authPermitAt == "y" ? "n" : "y"),
                                        },
                                    }))
                                }
                            >
                                변경
                            </Button>
                        </div>
                    </TableFormValue>
                </TableForm>
            )}

            <Modal
                title="횟수 차감"
                open={failCntModal}
                onCancel={() => resetModal()}
                onClose={() => resetModal()}
                type="confirm"
                onConfirm={() => validation()}
            >
                <Row gap="10px">
                    <Col sm={12}>
                        <LabelForm
                            label="차감 횟수"
                            labelCol={3}
                        >
                            <Input
                                name="cutCount"
                                type="number"
                                value={cutCnt.toString()}
                                onChange={e => setCutCnt(Number(e.value))}
                            />
                        </LabelForm>
                    </Col>

                    <Col sm={12}>
                        <p style={{ color: "red", fontSize: "1.15rem" }}>
                            * 현재 실패 횟수 {bikeData.failCnt}/3<br/>
                            * 현재 실패 횟수에서 입력한 차감 횟수 만큼 차감 됩니다.
                        </p>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default BikeInfoForm
