import React, { useEffect, useState } from "react"
import { BrowserRouter } from "react-router-dom"
import Routes from "@route/Routes"
import { RecoilRoot } from "recoil"
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import "./styles/global.css"
import "react-datepicker/dist/react-datepicker.css"
import "p-ui/dist/p-ui.min.css"
import AlertProvider from "./context/alert/AlertProvider"
import Title from "@components/widgets/Title"
import ScrollToTop from "@common/scrollToTop"

const queryClient = new QueryClient()

const App = () => {
    return (
        <AlertProvider>
            <RecoilRoot>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <Title />
                        <Routes />
                    </BrowserRouter>
                </QueryClientProvider>
            </RecoilRoot>
        </AlertProvider>
    )
}

export default App
