import { useEffect, useState } from "react"

import styled from "styled-components"
import { Title } from "../RentalFleetList/style"
import { Type } from "p-ui"
import { CommonDiv, FlexDiv, TransparentBtn } from "@styles"
import { Button, Icon, TableForm, TableFormLabel, TableFormValue } from "p-ui"

import { formatYYYYMMDDhhmm, getAccount } from "@common/util"

import { RentalDepositStatus, RentalFleetConsentFg, RentalFleetLocation, RentalFleetStatus } from "@common/constatns"

interface buttonType {
    type: Type.ButtonType
    modal: string
    children: string
    isShow: boolean
}
const BikeInfo = (props: any) => {
    const { rentalFleetBikeData, setModalOpen } = props
    const { leaseType, leaseUserNm, bikeLocation, startDt, leaseReason, consentFg, depositStatus, startMgrNm } =
        rentalFleetBikeData || {}

    const [buttonSetting, setButtonSetting] = useState<buttonType[]>([
        { type: "danger", modal: "useModal", children: "점검필요로 변경", isShow: false },
        { type: "primary", modal: "startModal", children: "등록", isShow: false },
        { type: "danger", modal: "endModal", children: "사용종료", isShow: false },
        { type: "primary", modal: "useModal", children: "사용가능으로 변경", isShow: false },
        { type: "primary", modal: "editModal", children: "수정", isShow: false },
    ])

    const account = getAccount()

    useEffect(() => {
        if (rentalFleetBikeData) {
            const updatedButtonSetting = [...buttonSetting]

            updatedButtonSetting.forEach(button => {
                button.isShow = false
            })

            //* 상태별로 버튼 활성화
            updatedButtonSetting[0].isShow = leaseType === 1
            updatedButtonSetting[1].isShow = leaseType === 1
            updatedButtonSetting[2].isShow = leaseType >= 2 && leaseType <= 4
            updatedButtonSetting[3].isShow = leaseType === 0

            //* 권한 있는 계정만 수정버튼 활성화
            if (authorizedAccount.find((id: string) => id === account)) {
                updatedButtonSetting[4].isShow = true
            }

            setButtonSetting(updatedButtonSetting)
        }
    }, [rentalFleetBikeData])

    return (
        <ExtendedCommonDiv width="50%">
            {rentalFleetBikeData && (
                <>
                    <FlexDiv
                        alignItems="center"
                        gap="16px"
                    >
                        <Title>대차 정보</Title>
                        {buttonSetting.map((button, index) => {
                            if (button.isShow) {
                                return (
                                    <Button
                                        key={index}
                                        size="small"
                                        type={button.type}
                                        onClick={() => {
                                            setModalOpen((prev: any) => ({
                                                ...prev,
                                                [button.modal]: true,
                                            }))
                                        }}
                                    >
                                        {button.children}
                                    </Button>
                                )
                            }
                        })}
                    </FlexDiv>
                    <TableForm>
                        <TableFormLabel md={2}>상태</TableFormLabel>
                        <TableFormValue md={4}>
                            {RentalFleetStatus.find((item: any) => item.value === leaseType)?.label}
                        </TableFormValue>
                        <TableFormLabel md={2}>위치</TableFormLabel>
                        <TableFormValue md={4}>
                            <FlexDiv
                                gap="16px"
                                alignItems="center"
                            >
                                {RentalFleetLocation.find(item => item.value === bikeLocation)?.label}
                                <TransparentBtn
                                    onClick={() =>
                                        setModalOpen((prev: any) => ({
                                            ...prev,
                                            locationEdit: true,
                                        }))
                                    }
                                >
                                    <Icon.Edit
                                        width={"16px"}
                                        height="16px"
                                    />
                                </TransparentBtn>
                            </FlexDiv>
                        </TableFormValue>
                        <TableFormLabel md={2}>사용자</TableFormLabel>
                        <TableFormValue md={4}>{leaseUserNm}</TableFormValue>
                        <TableFormLabel md={2}>시작일</TableFormLabel>
                        <TableFormValue md={4}>{formatYYYYMMDDhhmm(startDt)}</TableFormValue>
                        <TableFormLabel md={2}>사유</TableFormLabel>
                        <TableFormValue md={4}>{leaseReason}</TableFormValue>
                        <TableFormLabel md={2}>동의서</TableFormLabel>
                        <TableFormValue md={4}>
                            {RentalFleetConsentFg.find(item => consentFg === item.value)?.label}
                        </TableFormValue>
                        <TableFormLabel md={2}>보증</TableFormLabel>
                        <TableFormValue md={4}>
                            {RentalDepositStatus.find(item => depositStatus === item.value)?.label}
                        </TableFormValue>
                        <TableFormLabel md={2}>시작담당자</TableFormLabel>
                        <TableFormValue>{startMgrNm}</TableFormValue>
                    </TableForm>
                </>
            )}
        </ExtendedCommonDiv>
    )
}

const ExtendedCommonDiv = styled(CommonDiv)`
    flex: 1;
`

export default BikeInfo

//TODO :: 대차관리자 컨펌받기
const authorizedAccount = ["bylee", "chson", "jshong", "jypark", "jyyun"]
