import { atom, selector } from "recoil"
import { modalType } from "@type/common"

export const modalAtom = atom<modalType>({
    key: "modalState",
    default: {
        open: false,
        header: "Modal Header",
        type: "0",
        text: "main",
        cb: () => {
            console.log("callback")
        },
    },
})

export const modalSelector = selector({
    key: "modalAtom",
    get: ({ get }) => {
        return get(modalAtom)
    },
    set: ({ set }, newState) => {
        set(modalAtom, newState)
    },
})

export const modalOpenState = atom({
    key: "modalOpenState",
    default: false,
})
