// TODO CHANGE :: API URL
export const HOST_URL = process.env.REACT_APP_API_URL
export const AGENCY_URL = "http://nzeus.zentropy.co.kr/api"
export const OPEN_API = process.env.REACT_APP_OPEN_API_URL

export const OPEN_API_BIKE = {
    GET_DATA: "/bike/test",
}

export const ISSUE = {
    GET_LIST: "/issue",
    GET_ONE: "/issue/",
    INSERT: "/issue",
    UPDATE: "/issue/",
    DELETE: "/issue/",
    GET_WRITE: "/issue/write/",
}

export const AUTH = {
    SIGNIN: "/auth/signin",
    REFRESH: "/auth/refresh",
}

export const STATION = {
    GET: "/station",
    GET_ONE: "/station/one/",
    POST: "/station",
    DELETE: "/station/",
    FILE_UPLOAD: "/file",
}

export const CENTER = {
    DELETE: "/center/",
}

export const ASCODE = {
    GET: "/asCode",
    POST: "/asCode",
    DELETE: "/asCode/",
    PUT: "/asCode",
    GET_STATION_LIST_FOR_AS: "/asCode/station",
    GET_AS: "/asCode/as",
    GET_AS_PARENT: "/asCode/as-parent",
}

export const PUSH = {
    GET: "/push",
    POST: "/push",
    SEND_ALL: "/push/all",
    DELETE: "/push/",
    PUT: "/push",
    GET_TEMPLATE: "/pushTemplate",
    POST_TEMPLATE: "/pushTemplate",
    PUT_TEMPLATE: "/pushTemplate",
    DELETE_TEMPLATE: "/pushTemplate/",
}

export const AGENCY = {
    GET: "/agency",
    GET_ONE: "/agency/",
    INSERT: "/agency",
    UPDATE: "/agency",
    SYNC: "/agency/",
    RESET: "/agency/",
    DELETE: "/agency/",
}

export const PURCHASER = {
    GET: "/purchaser",
    INSERT: "/purchaser",
    UPDATE: "/purchaser",
    GETONE: "/purchaser/",
}

export const ZENTAL = {
    /**ZtUser */
    GET_USER: "/ztuser",
    INSERT_USER: "/ztuser",
    UPDATE_USER: "/ztuser",
    GET_ONE_USER: "/ztuser/",
    DELETE_USER: "/ztuser/",
    UPLOAD_FILE_LIST: "/file/list/",
    DELETE_FILE: "/file/",
    UNCONTRACT_USER: "/ztuser/uncontract",
    DUPLICATE_CHECK: "/ztuser/duplicate/",
    GET_ZT_CARD: "/card/user/",
    UPDATE_ZT_CARD: "/zental/card",

    /**ZtBike */
    GET_BIKE: "/ztbike/",
    DELETE_BIKE: "/ztbike/",
    SYNC_BIKE: "/ztbike",
    UPDATE_BIKE_STATUS: "/ztbike",
    GET_BIKE_LIST: "/ztbike/all",
    UPDATE_BIKE_RIDER: "/ztbike/rider",
    CHANGE_BIKE: "/ztbike",
    UNCONTRACT_BIKE: "/ztbike/uncontract",
    /**ztService */
    CONNECT_SERVICE: "/zental",
    UPSERT_SERVICE: "/zental/",
    UPSERT_SERVICE_STATUS: "/zental/status/",
    DELETE_SERVICE: "/zental/",
    CHANGE_SERVICE: "/zental/change",
    GET_SERVICE_MODEL: "/membership-model",
    CHANGE_SERVICE_BIKE: "/zental/change-bike",
    CUT_FAIL_CNT: "/zental/fail/",
    REPAY: "/zental/repay/",
    EXTEND: "/zental/extend",

    /**ztInsurance */
    GET_INSURANCE: "/insurance",
    INSERT_INSURANCE: "/insurance",
    CONNECT_INSURANCE: "/insurance",

    /**ztRider */
    GET_ZENTAL_RIDER: "/rider/zental",
    RESET_PASS_RIDER: "/rider/reset/",
    INSERT_ZENTAL_RIDER: "/rider/zental",

    GET_USER_HISTORY: "/zental/user/",
    GET_PAYMENT_HISTORY: "/zental/payment/",
}

export const ORDER_MANAGE = {
    GET_LIST: "/bike-order",
    GET_DETAIL: "/bike-order/",
    UPDATE_ORDER: "/bike-order/item/",
    UPDATE_COMMENT: "/bike-order/comment", // /order-comment --> /bike-order/comment
    DELETE_ORDER: "/bike-order/",
    UPDATE_ORDER_STATE: "/bike-order/update",
    REJECT_CANCEL: "/bike-order/reject",
    GET_PROMOTION: "/bike-order/promotions/",
    UPDATE_PROMOTION: "/bike-order/promotion/",
    GET_CUSTOMER_ONE: "/bike-order/customer/", // /orderCustomer/ --> /bike-order/customer/
    UPDATE_CUSTOMER: "/bike-order/customer", // /orderCustomer --> /bike-order/customer
    GET_ACCESSORY: "/bike-order/prouducts",
    GET_COLOR: "/bike-order/prouduct/options/",
    GET_TOTAL_AMOUNT: "/bike-order/amount/",
    OFFLINE_PAYMENT: "/bike-order/offline/",
    GET_REFUND: "/bike-order/refund/", // /refund/ --> /bike-order/refund/
    GET_SUBSIDY: "/bike-order/subsidy",

    CHECK_PAYMENT: "/easy-payment/check/",

    UPLOAD_FILE_LIST: "/file/list/",
    DELETE_FILE: "/file/",
}

export const REQUEST_TASK = {
    GET_REQTASK: "/reqTask",
    INSERT_REQTASK: "/reqTask",
    UPDATE_REQTASK: "/reqTask",
    GET_ONE_REQTASK: "/reqTask/",
    DELETE_REQTASK: "/reqTask/",
    GET_MANAGER: "/manager",
}

export const RENTAL_FLEET = {
    GET_LIST: "/bike-lease",
    GET_DETAIL: "/bike-lease/",
    GET_HISTORY: "/bike-lease/history/",
    POST: "/bike-lease",
    PUT: "/bike-lease",
    PUT_MEMO: "/bike-lease/",
}

export const MEMBERSHIP = {
    /**구매자 멤버십 사용 현황 조회 */
    GET_MEMBERSHIP: "/membership/purchaser",
    /**바이크 멤버십 단건 조회 */
    GET_MEMBERSHIP_ONE: "/membership/",
    /**바이크 멤버십 단건 조회 */
    GET_MEMBERSHIP_PROMOTION_LIST: "/membership/promotions/",
    DELETE_MEMBERSHIP_PROMOTION: "/membership/promotion/",
    /**멤버십 변경 */
    UPDATE_MEMBERSIP: "/membership/model",
    /**멤버십 교환 상태 변경 */
    UPDATE_BIKE_AUTH: "/membership/bike/auth",
    /**멤버십 교환 상태 변경 */
    INSERT_PROMOTION: "/membership/promotion",

    /**멤버십 상태 변경 */
    UPDATE_AUTH: "/membership/auth",

    /**멤버십 카드변경 */
    UPDATE_CARD: "/membership/card",
    GET_CARD_LIST: "/membership/cards/",
    /**주행거리 조회 */
    GET_DAILY_MILE: "/membership/daily/",
    /**결제이력 조회 */
    GET_MERCHANT: "/membership/merchant/",
    /**상품리스트 조회 */
    GET_GOODS_LIST: "/membership/goods/",
    /**멤버십 프로모션리스트 조회  */
    GET_PROMOTION_LIST: "/membership/promotion-models",

    /**결제 시도  */
    PAYMENT_MEMBERSHIP: "/membership/payment/",
    /**현장 결제  */
    OFFLINE_PAYMENT: "/membership/offline/",
    /**환불 시도  */
    REFUND_PAYMENT: "/membership/refund/",
    /**일시 정지  */
    SUSPEND_MEMBERSHIP: "/membership/suspend",
}

export const LAB = {
    GET_BIKE_LIST: "lab/bike",
    GET_BIKE_GPS: "lab/polyline",
    GET_BIKE_TOTAL: "lab/total",
}
export const NOTICE = {
    GET_LIST: "/notice",
    GET_DETAIL: "/notice/",
    GET_TYPE: "/notice/type",
    POST: "/notice",
    PUT: "/notice/",
    DELETE: "/notice/delete/",
}

export const FAQ = {
    GET_LIST: "/faq",
    GET_DETAIL: "/faq/",
    GET_TYPE: "/faq/type",
    POST: "/faq",
    PUT: "/faq",
    DELETE: "/faq/",
}
export const GOODS = {
    UPDATE_AUTH: "/goods/auth",
}

export const BIKEERRORCODE = {
    GET_LIST: "/errorCode",
    GET_DETAIL: "/errorCode/",
    GET_TYPE: "/errorCode/type",
    POST: "/errorCode",
    PUT: "/errorCode",
    DELETE: "/errorCode/",
}

export const FILE = {
    UPLOAD_FILE_LIST: "/file/list/",
    DELETE_FILE: "/file/",
    GET_FILE_LIST: "/file",
}

export const ASSETS = {
    GET_LIST: "/assets",
    GET_ONE: "/assets/",
    GET_STATUS: "/assets/status/",
    UPDATE_FIRMWARE: "/assets/firmware",
    GET_FIRMARE: "/assets/firmware/",
    GET_FIRMARE_LIST: "/assets/firmware/list/",
    GET_APPLY_FIRMARE_LIST: "/assets/firmware/list",
    GET_ASSETS_BIKESTATUS: "/assets/bike/status",
    GET_HARDWARE_LIST: "/assets/hardware/list/",
    GET_APPLY_HARDWARE_LIST: "/assets/hardware/list",
    GET_HARDWARE: "/assets/hardware/",
    UPDATE_HARDWARE: "/assets/hardware",
    GET_MEMO: "/assets/memo/",
    UPDATE_MEMO: "assets/memo",
    UPDATE_ASSETS: "/assets/",

    /**즐켜찾는 기체 */
    GET_FAVORITE_LIST: "/assets/favorite",
    GET_FAVORITE: "/favorite",
    POST_FAVORITE: "/favorite",
    DELETE_FAVORITE: "/favorite",
}

export const FW = {
    GET_FIRM_LIST: "/firmware/list",
    GET_FIRM_LIST_ALL: "/firmware/list/all",
    GET_FIRM_HISTORY: "/firmware/list/history",
    UPDATE_FIRM: "/firmware",
    DELETE_FIRM: "/firmware",
    POST_FIRM: "/firmware",
}
export const HW = {
    GET_HARD_LIST: "/hardware/list",
    GET_HARD_LIST_ALL: "/hardware/list/all",
    GET_HARD_HISTORY: "/hardware/list/history",
    UPDATE_HARD: "/hardware",
    DELETE_HARD: "/hardware",
    POST_HARD: "/hardware",
}

export const SHIPPMENTSTATUS = {
    UPLOAD_IMG: "/shippmentstatus",
}

export const EVENT = {
    GET_POPUP_LIST: "/popup",
    UPDATE_POPUP: "/popup",
    INSERT_POPUP: "/popup",
    GET_POPUP_ONE: "/popup/",
    DELETE_POPUP_ONE: "/popup/",
}
