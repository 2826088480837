import React, { useEffect, useState } from "react"

import { getManagerId } from "@common/util"
import useAlert from "@context/alert/useAlert"
import { MemoIconWrapper } from "@pages/RentalFleet/RentalFleetList/style"
import { FlexDiv, FlexColDiv, Text } from "@styles"
import { useQueryClient } from "@tanstack/react-query"
import { AssetBikeMemo } from "@type/responseType"
import { Button, TextArea } from "p-ui"
import { useLocation } from "react-router"
import { useGetBikeFirmWareVersion, useUpdateBikeMemo } from "src/queries/assets"
import { ASSETS_QUERY_KEY } from "@common/queryKey"

const Memo = () => {
    const location = useLocation()
    const queryClient = useQueryClient()
    const serialNo = new URLSearchParams(location.search).get("serialNo")

    const { openAlert } = useAlert()

    const { data: versionData } = useGetBikeFirmWareVersion(serialNo as string, true)
    const memoCacheData: any = queryClient.getQueryData([ASSETS_QUERY_KEY.GET_BIKE_MEMO, serialNo])

    const [memoData, setMemoData] = useState<AssetBikeMemo>()

    const [isEdit, setIsEdit] = useState(false)

    const { mutate } = useUpdateBikeMemo()

    const updateBikeMemo = () => {
        mutate(memoData as AssetBikeMemo, {
            onSuccess(data) {
                if (data.status === "SUCCESS") {
                    queryClient.invalidateQueries({ queryKey: [ASSETS_QUERY_KEY.GET_BIKE_ONE, serialNo] })
                    openAlert({ title: "성공", body: "메모 저장 완료", type: "primary" })
                } else {
                    openAlert({ title: "실패", body: data.resultMessage, type: "warning" })
                }
            },
        })
    }
    useEffect(() => {
        setMemoData((prevMemoData: any) => {
            return memoCacheData?.resultData
                ? {
                      ...memoCacheData.resultData,
                      edcuVersion: versionData?.edcuVersionId,
                      icsVersion: versionData?.icsVersionId,
                      mcuVersion: versionData?.mcuVersionId,
                      managerId: Number(getManagerId()),
                  }
                : {
                      edcuVersion: versionData?.edcuVersionId,
                      icsVersion: versionData?.icsVersionId,
                      mcuVersion: versionData?.mcuVersionId,
                      bikeSerial: versionData?.serialNo,
                      managerId: Number(getManagerId()),
                  }
        })
    }, [versionData])
    return (
        <FlexColDiv>
            <FlexDiv
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
            >
                <FlexDiv
                    alignItems="center"
                    margin="0 0 10px  0"
                >
                    <MemoIconWrapper
                        width="40px"
                        height="40px"
                        padding="12px"
                        justifyContent="center"
                        alignItems="center"
                        gap="10px"
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/images/ic_memo.svg`}
                            alt="memo_icon"
                        />
                    </MemoIconWrapper>
                    <Text bold>메모</Text>
                </FlexDiv>
                <FlexDiv gap="8px">
                    {isEdit && (
                        <Button
                            size="small"
                            type="danger"
                            onClick={() => {
                                setMemoData({
                                    ...memoData,
                                    memo: memoCacheData ? memoCacheData.resultData?.memo : "",
                                } as AssetBikeMemo)
                                setIsEdit(!isEdit)
                            }}
                        >
                            취소
                        </Button>
                    )}
                    <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                            setIsEdit(!isEdit)
                            isEdit && updateBikeMemo()
                        }}
                    >
                        {isEdit ? "메모 저장" : "메모 수정"}
                    </Button>
                </FlexDiv>
            </FlexDiv>

            <TextArea
                value={memoData?.memo ? memoData.memo : ""}
                onChange={e => {
                    setMemoData({ ...memoData, memo: e.target.value } as AssetBikeMemo)
                }}
                disabled={!isEdit}
                width={"100%"}
                height={"100%"}
                name="comment"
                rows={5}
                maxLength={200}
            />
        </FlexColDiv>
    )
}

export default Memo
