import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"

import { FlexColDiv, FlexDiv } from "@styles"
import { DropZone, ScrollDiv, FileDiv } from "@components/widgets/dragAndDrop/styles"
import { Button, Icon, Modal } from "p-ui"
import Divider from "@components/widgets/Divider"

// TODO :: Intergrated Drag And Drop
function OrderDragAndDropFile(props: DragAndDropFileProps) {
    const { files, setFiles, defaultData, deleteAttachFiles } = props
    const imgInputRef = useRef<HTMLInputElement>(null)
    const [isDragging, setIsDragging] = useState<boolean>(false)
    const [modalData, setModalData] = useState({ isOpen: false, fileId: 0, fileName: "" })

    const fileId = useRef<number>(0)
    const dragRef = useRef<HTMLDivElement | null>(null)

    const onImgInputBtnClick = (e: any) => {
        e.preventDefault()
        if (e.target.className !== "deleteBtn") {
            imgInputRef.current?.click()
        }
    }

    const onChangeFiles = useCallback(
        (e: ChangeEvent<HTMLInputElement> | any): void => {
            let selectFiles: File[] = []
            let tempFiles: IFileTypes[] = []

            if (e.type === "drop") {
                selectFiles = e.dataTransfer.files
            } else {
                selectFiles = e.target.files
            }

            for (const file of selectFiles) {
                tempFiles.push({
                    id: fileId.current++,
                    object: file,
                })
            }

            setFiles((prevFiles: any) => (prevFiles.length > 0 ? [...prevFiles, ...tempFiles] : tempFiles))
        },
        [setFiles]
    )
    const handleDragIn = useCallback((e: DragEvent): void => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const handleDragOut = useCallback((e: DragEvent): void => {
        e.preventDefault()
        e.stopPropagation()

        setIsDragging(false)
    }, [])

    const handleDragOver = useCallback((e: DragEvent): void => {
        e.preventDefault()
        e.stopPropagation()

        if (e.dataTransfer!.files) {
            setIsDragging(true)
        }
    }, [])

    const handleDrop = useCallback(
        (e: DragEvent): void => {
            e.preventDefault()
            e.stopPropagation()

            onChangeFiles(e)
            setIsDragging(false)
        },
        [onChangeFiles]
    )

    const initDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.addEventListener("dragenter", handleDragIn)
            dragRef.current.addEventListener("dragleave", handleDragOut)
            dragRef.current.addEventListener("dragover", handleDragOver)
            dragRef.current.addEventListener("drop", handleDrop)
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

    const resetDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.removeEventListener("dragenter", handleDragIn)
            dragRef.current.removeEventListener("dragleave", handleDragOut)
            dragRef.current.removeEventListener("dragover", handleDragOver)
            dragRef.current.removeEventListener("drop", handleDrop)
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

    const deleteFiles = useCallback(
        (id: number): void => {
            setFiles(files.filter((file: any) => file.id !== id))
        },
        [files, setFiles]
    )

    useEffect(() => {
        initDragEvents()
        return () => resetDragEvents()
    }, [initDragEvents, resetDragEvents])

    useEffect(() => {
        return () => {
            setFiles((prev: any) => ({
                ...prev,
                userFileState: [],
            }))
        }
    }, [])

    return (
        <FlexColDiv gap="30px">
            <div>
                <DropZone
                    isDragging={isDragging}
                    ref={dragRef}
                    onClick={onImgInputBtnClick}
                >
                    <span>
                        <Icon.Upload fill="var(--primary)" />
                    </span>
                    <p>Drag file to zone on click upload</p>
                </DropZone>
                <input
                    ref={imgInputRef}
                    placeholder="입력"
                    type="file"
                    onChange={onChangeFiles}
                    multiple={true}
                    style={{ display: "none" }}
                />
            </div>
            {(files?.length > 0 || defaultData?.length > 0) && (
                <ScrollDiv>
                    <div>
                        <>
                            {defaultData?.length > 0 &&
                                defaultData.map((item: any) => {
                                    const { fileId, fileName } = item
                                    return (
                                        <>
                                            <FlexDiv
                                                alignItems="center"
                                                gap="10px"
                                                margin="0 0 10px 0"
                                            >
                                                <FileDiv key={fileId}>
                                                    <span>
                                                        <Icon.Attach
                                                            width={16}
                                                            height={16}
                                                            fill="#ff7433"
                                                        />
                                                    </span>
                                                    <p>{fileName}</p>
                                                </FileDiv>
                                                <Button
                                                    type={"danger"}
                                                    // size="small"
                                                    onClick={() =>
                                                        setModalData({
                                                            ...modalData,
                                                            isOpen: true,
                                                            fileId: fileId,
                                                            fileName: fileName,
                                                        })
                                                    }
                                                >
                                                    <Icon.Delete
                                                        fill="white"
                                                        width={16}
                                                        height={16}
                                                    />
                                                </Button>
                                            </FlexDiv>
                                        </>
                                    )
                                })}
                            <Divider />
                        </>
                        {files?.length > 0 &&
                            files?.map((file: IFileTypes) => {
                                const {
                                    id,
                                    object: { name },
                                } = file
                                return (
                                    <FlexDiv
                                        alignItems="center"
                                        margin="0 0 10px 0"
                                        gap="10px"
                                    >
                                        <FileDiv key={id}>
                                            <span>
                                                <Icon.Attach
                                                    width={16}
                                                    height={16}
                                                    fill="#ff7433"
                                                />
                                            </span>
                                            <p>{name}</p>
                                        </FileDiv>
                                        <Button
                                            type={"danger"}
                                            // size="small"
                                            onClick={() => deleteFiles(id)}
                                        >
                                            <Icon.Delete
                                                fill="white"
                                                width={16}
                                                height={16}
                                            />
                                        </Button>
                                    </FlexDiv>
                                )
                            })}
                    </div>
                </ScrollDiv>
            )}

            <Modal
                type="confirm"
                open={modalData.isOpen}
                confirmText="삭제"
                onClose={() => {
                    setModalData({ ...modalData, isOpen: false, fileId: -1, fileName: "" })
                }}
                onConfirm={() => deleteAttachFiles(modalData.fileId)}
            >
                <FlexColDiv
                    alignItems="center"
                    minHeihgt="140px"
                    justifyContent="center"
                >
                    <h3>{modalData.fileName}</h3>
                    파일을 삭제 하시겠습니까?
                </FlexColDiv>
            </Modal>
        </FlexColDiv>
    )
}

export default OrderDragAndDropFile

interface IFileTypes {
    id: number // 파일들의 고유값 id
    object: File
}

interface DragAndDropFileProps {
    files: any
    setFiles: React.Dispatch<React.SetStateAction<any>>
    defaultData: any
    setDeleteAlert?: any
    deleteAttachFiles?: any
}
