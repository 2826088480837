import MenuList from "@route/menuList"
import React, { useEffect } from "react"
import { useLocation } from "react-router"
import { Type } from "p-ui"

const initMenu: Type.Menu = {
    title: "",
    icon: "",
    link: "",
    child: [],
    linkedPage: [],
}

const Title = (props: any) => {
    const { pathname } = useLocation()
    const title = process.env.REACT_APP_TITLE

    const setTitle = (pathname: string) => {
        const { title: subTitle } = MenuList.reduce((result: Type.Menu, menu) => {
            if (menu.link === pathname || menu.linkedPage?.find(el => pathname.includes(el))) {
                return menu
            }
            const childMatch = menu.child?.find(item => {
                return item.link === pathname
            })
            // const childMatch = menu.child?.find(item => item.link === pathname || pathname.includes(item.link || ""))
            if (childMatch) {
                return childMatch
            }

            const linkedPage = menu.child?.find(item => item.linkedPage?.find(el => pathname.includes(el)))
            if (linkedPage) {
                return linkedPage
            }

            return result
        }, initMenu)

        document.title = subTitle ? `${title} | ${subTitle || ""}` : title!
    }

    useEffect(() => {
        if (pathname) {
            setTitle(pathname)
        }
    }, [pathname])
    return null
}

export default Title
