import { Issue, IssueDesc, WriteData } from "@type/issue"
import { atom, selector } from "recoil"
import moment from "moment/moment"

export const paramIssueState = atom<Issue>({
    key: "paramState",
    default: {
        issueId: 0,
        issueType: "",
        title: "",
        reportType: "IB",
        status: 9,
        contents: "",
        goodsSerialNo: "",
        btSerialNo1: "",
        btSerialNo2: "",
        product: "",
        remark: "",
        writerId: "ACCOUNT",
        reporterId: "김나현",
        handlerId: "",
        useFg: 1,
        createdAt: null,
        updatedAt: null,
        parentId: 0,
        categoryNm: "",
        parentCategoryNm: "",
        issueDt: moment().format("YYYY-MM-DD HH:mm"),
    },
})

export const paramIssueDesState = atom<IssueDesc>({
    key: "paramIssueDesState",
    default: {
        issueDescId: 0,
        issueId: 0,
        descTy: 0,
        contents: "",
        createdAt: null,
        updatedAt: null,
        updatedBy: "",
    },
})

export const writeDataState = atom<WriteData>({
    key: "writeDataState",
    default: {
        category: [],
        station: [],
        bike: [],
        battery: []
    }
})

export const paramIssueStateSelector = selector({
    key: "paramIssueState",
    get: ({ get }) => {
        return get(paramIssueState)
    },
    set: ({ set }, newState) => {
        set(paramIssueState, newState)
    },
})
