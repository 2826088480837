import { useState, useEffect, useMemo } from "react"
import { Input, LabelForm, Radio, Select } from "p-ui"
import { FlexColDiv, FlexDiv, Text } from "@styles"
import { OrderCancelParams, PaymentInfo, RefundInfo } from "@type/orderManageType"
import { Order } from "../constants"
import Divider from "@components/widgets/Divider"
import orderManage from "src/apis/orderManage"

interface OrderCancelModalProps {
    cancelParam: OrderCancelParams
    paymentInfo: PaymentInfo[]
    setCancelParam: (cancelParm: OrderCancelParams) => void
    isVbank: boolean
    isDeposit?: boolean
    orderId: string
    setIsVbank: (e: boolean) => void
}

const OrderCancelModal = (props: OrderCancelModalProps) => {
    const { isDeposit, cancelParam, paymentInfo, setCancelParam, isVbank, orderId, setIsVbank } = props

    let totalAmount = 0

    const [refundInfo, setRefundInfo] = useState<RefundInfo>()

    for (let i = 0; i < paymentInfo.length; i++) {
        let item = paymentInfo[i]
        totalAmount += item.amount
    }

    const onChangeCancel = (e: any) => {
        if (e.name) {
            setCancelParam({ ...cancelParam, [e.name]: e.value })
        } else {
            setCancelParam({ ...cancelParam, type: e })
        }
    }

    const refundCost = useMemo(() => {
        if (cancelParam.type === "ALL") {
            return totalAmount.toLocaleString() + "원"
        }
        let cost = paymentInfo.filter((item: PaymentInfo) => item.name.includes("잔금"))[0]?.amount
        return cost ? `${cost.toLocaleString()}원` : 0
    }, [cancelParam.type])

    const getRefund = () => {
        orderManage
            .getRefund(orderId)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setRefundInfo(res.resultData)
                    /**사용자가 입력한 환불정보가있을경우 취소에 필요한 param 설정 */
                    res.resultData &&
                        setCancelParam({
                            ...cancelParam,
                            refundId: res.resultData?.refundId,
                            refundAccount: res.resultData.refundAccount,
                            refundBank: res.resultData.refundBank,
                            refundHolder: res.resultData.refundHolder,
                            refundTel: res.resultData.refundTel,
                        })
                } else {
                    console.log(res.resultMessage)
                }
            })
            .catch(error => console.log(error))
    }

    const setPayMethodType = (e: any) => {
        if (e === "ALL" || e === "ZR") {
            setIsVbank(paymentInfo.some((item: any) => item.payMethod === "가상계좌" && item.orderStatus === 2))
        } else {
            setIsVbank(
                paymentInfo.some(
                    (item: any) => item.name.includes("잔금") && item.payMethod === "가상계좌" && item.orderStatus === 2
                )
            )
        }
    }

    useEffect(() => {
        getRefund()
        isDeposit ? setPayMethodType("ZR") : setPayMethodType("ALL")
        isDeposit && setCancelParam({ ...cancelParam, type: "ZR" })
    }, [])

    return (
        <FlexColDiv gap="10px">
            {!isDeposit && (
                <LabelForm
                    label="구분"
                    fullHeight
                    required
                >
                    <FlexDiv height="100%">
                        <Radio.Group
                            defaultValue={"ALL"}
                            disabled={!paymentInfo.some((item: any) => item.name.includes("잔금"))}
                            onChange={e => {
                                onChangeCancel(e)
                                setPayMethodType(e)
                            }}
                            options={[
                                { label: "전액 환불", value: "ALL" },
                                { label: "잔금 환불", value: "ZB" },
                            ]}
                        />
                    </FlexDiv>
                </LabelForm>
            )}
            <LabelForm
                label="사유"
                fullHeight
                required
            >
                <Input
                    onChange={e => {
                        onChangeCancel(e)
                    }}
                    name="comment"
                    placeholder="주문 취소 사유를 입력해 주세요"
                />
            </LabelForm>
            {isVbank && (
                <>
                    <FlexDiv alignItems="center">
                        <FlexColDiv width="210px">
                            <Text bold>환불계좌(가상계좌 환불용)</Text>
                        </FlexColDiv>
                        <Divider />
                    </FlexDiv>
                    <LabelForm
                        label="예금주명"
                        fullHeight
                        required
                    >
                        <Input
                            name="refundHolder"
                            value={refundInfo && refundInfo?.refundHolder}
                            placeholder="예금주명을 입력해 주세요"
                            onChange={e => {
                                onChangeCancel(e)
                            }}
                        />
                    </LabelForm>
                    <LabelForm
                        label="전화번호"
                        fullHeight
                        required
                    >
                        <Input
                            type="number"
                            name="refundTel"
                            value={refundInfo && refundInfo?.refundTel}
                            placeholder="전화번호를 입력해 주세요('-' 제외)"
                            onChange={e => {
                                onChangeCancel(e)
                            }}
                        />
                    </LabelForm>
                    <LabelForm
                        label="은행명"
                        fullHeight
                        required
                    >
                        <Select
                            defaultValue={refundInfo && refundInfo?.refundBank}
                            onChange={(label: string, value) => {
                                value && onChangeCancel({ name: "refundBank", value: value })
                            }}
                            options={Order.BANK_CODE}
                        />
                    </LabelForm>
                    <LabelForm
                        label="계좌번호"
                        fullHeight
                        required
                    >
                        <Input
                            type="number"
                            value={refundInfo && refundInfo?.refundAccount}
                            name="refundAccount"
                            placeholder="계좌번호 입력('-' 제외)"
                            onChange={e => {
                                onChangeCancel(e)
                            }}
                        />
                    </LabelForm>
                </>
            )}
            {isDeposit ? (
                <Text
                    bold
                    color="red"
                    fontSize="14px"
                    margin="20px 0 0 0"
                >
                    관리자가 예약금 제외하고 환불하였으나 <br />
                    예약금까지 환불되어야 되는 경우 사용합니다. <br /> <br /> 예약금을 환불 하시겠습니까? <br />
                    환불할 경우 다시 복원되지 않습니다.
                </Text>
            ) : (
                <Text
                    bold
                    color="red"
                    fontSize="14px"
                    margin="20px 0 0 0"
                >
                    선택한 주문 1 건을 주문 취소하시겠습니까? <br />
                    주문 취소할 경우 다시 복원되지 않습니다.
                    <br />
                    <br />총 환불 예정금액 : &nbsp;&nbsp;
                    {refundCost}
                </Text>
            )}
        </FlexColDiv>
    )
}

export default OrderCancelModal
