import { FlexDiv, ImgDiv, Text, FlexColDiv } from "@styles"
import { PopupButton, PopupName, PopupWrap, TagUI } from "../styles"

import { STATUS } from "@common/constatns"

function StationPopup({ data, onClick }) {
    const handleImageError = e => {
        e.target.src = `${process.env.PUBLIC_URL}/images/zentropyLogo.png`
    }

    return (
        <PopupWrap>
            <PopupButton
                onClick={() => {
                    onClick()
                }}
            >
                X
            </PopupButton>
            <ImgDiv
                size="140px"
                borderRadius="8px"
                as="img"
                src={data.status == 0 ? `${process.env.PUBLIC_URL}/images/plan.png` : `${data.s3Url}`}
                alt=""
                onError={handleImageError}
            />
            <FlexColDiv
                alignItems="center"
                width="500px"
            >
                <PopupName>{data.centerNm ? `${data.stationName} (${data.centerNm})` : data.stationName}</PopupName>
                <Text
                    margin="0 0 10px 0"
                    fontWeight={900}
                    color={STATUS.COLOR[data.status]}
                >
                    {data.codeLabel}
                </Text>
                <FlexDiv
                    gap="5px"
                    margin="0 0 10px 0"
                >
                    {data.tags && (
                        <>
                            {data.tags.map((tag, index) => (
                                <TagUI key={index}>{tag.label}</TagUI>
                            ))}
                        </>
                    )}
                </FlexDiv>

                <Text
                    fontSize="14px"
                    display="block"
                    margin="5px 0 0 0"
                    textAlign="text"
                >
                    <Text
                        fontWeight={700}
                        margin="0 10px 0 0"
                    >
                        도로명 주소:
                    </Text>
                    {data.addressRoad}
                </Text>
            </FlexColDiv>
        </PopupWrap>
    )
}

export default StationPopup
