import { formatYYYYMMDDhhmmss2 } from "@common/util"
import { FirmwareListALL, HardwareListALL, TaskManager } from "@type/responseType"
import { Col, Label, Radio, Row, Select, TextArea } from "p-ui"
import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router"
import { useGetBikeFirmWareVersion, useGetHardWareVersion } from "src/queries/assets"
import { useGetAllFirmList, useGetAllHardList } from "src/queries/firmhard"
import { useGetManagerList } from "src/queries/manager"
import { ASSET_BIKE } from "../constants"
import { FlexDiv } from "@styles"
import ReactDatePicker from "react-datepicker"
import CustomDatePicker from "@components/widgets/customDatePicker"
import { DataPickerInput } from "@styles"
import { ko } from "date-fns/locale"

const FirmHardUpdateModal = (props: any) => {
    const { serialNum, firmwareInfo, setFirmwareInfo, prevFirmwareInfo, setPrevFirmwareInfo, setUpdateType } = props
    const { hardwareInfo, setHardwareInfo, setPrevHardwareInfo, prevHardwareInfo } = props

    const currentDate = new Date()
    const location = useLocation()
    const serialNo = new URLSearchParams(location.search).get("serialNo")
        ? new URLSearchParams(location.search).get("serialNo")
        : serialNum
    const [startDate, setStartDate] = useState(currentDate)
    const [type, setType] = useState<string>("")
    const [updateType, setTempUpdateType] = useState("firm")
    const [formData, setFormData] = useState({ type: "", version: "", manager: -100, desc: "" })
    const { data: managerListData } = useGetManagerList()
    const { data: hwVersion } = useGetHardWareVersion(serialNo, true)
    const { data: hardwareListData } = useGetAllHardList()
    const { data: fwVersion } = useGetBikeFirmWareVersion(serialNo, true)
    const { data: firmwareListData } = useGetAllFirmList()

    const managerList = useMemo(() => {
        return managerListData
            ? managerListData
                  .filter((item: any) => item.orgNm === "서비스운영")
                  .map((item: TaskManager) => {
                      return { label: item.managerNm, value: item.managerId }
                  })
            : []
    }, [managerListData])

    const firmList = useMemo(() => {
        return firmwareListData
            ? firmwareListData
                  .filter((item: any) => type.includes(item.firmwareType.toLowerCase()))
                  .map((item: FirmwareListALL) => {
                      return { label: item.version, value: item.firmwareId }
                  })
            : []
    }, [type, firmwareListData])

    const hardList = useMemo(() => {
        return hardwareListData
            ? hardwareListData
                  .filter((item: any) => type.includes(item.hardwareType.toLowerCase()))
                  .map((item: HardwareListALL) => {
                      return { label: item.version, value: item.hardwareId }
                  })
            : []
    }, [type, hardwareListData])

    const reset = () => {
        setFormData({ type: "", version: "", manager: -100, desc: "" })
        setStartDate(currentDate)
    }
    useEffect(() => {
        setHardwareInfo({
            ...hardwareInfo,
            applyDt: formatYYYYMMDDhhmmss2(startDate as unknown as string),
            bikeSerial: serialNo as string,
            edcuVersion: hwVersion?.edcuVersionId,
        })
        setPrevHardwareInfo({
            ...prevHardwareInfo,
        })
    }, [hwVersion, updateType])

    useEffect(() => {
        setFirmwareInfo({
            ...firmwareInfo,
            applyDt: formatYYYYMMDDhhmmss2(startDate as unknown as string),
            bikeSerial: serialNo as string,
            edcuVersion: fwVersion?.edcuVersionId,
            icsVersion: fwVersion?.icsVersionId,
            mcuVersion: fwVersion?.mcuVersionId,
        })
        setPrevFirmwareInfo({
            ...prevFirmwareInfo,
        })
    }, [fwVersion, updateType])

    return (
        <Row gap={10}>
            <Col sm={3}>
                <Label value={"* 펌/하드 웨어 선택"} />
            </Col>
            <Col sm={8}>
                <FlexDiv height="50px">
                    <Radio.Group
                        options={ASSET_BIKE.updateType}
                        defaultValue={updateType}
                        onChange={e => {
                            setUpdateType(e.toString())
                            setTempUpdateType(e.toString())
                            reset()
                        }}
                    />
                </FlexDiv>
            </Col>
            <Col sm={3}>
                <Label value={updateType === "firm" ? "* 펌웨어 선택" : "* 하드웨어선택"} />
            </Col>
            <Col sm={8}>
                <Select
                    options={updateType === "firm" ? ASSET_BIKE.firmType : ASSET_BIKE.hardType}
                    defaultValue={formData.type}
                    onChange={(label, value) => {
                        setType(value.toString())
                        setFormData({ ...formData, type: value as string })
                        if (updateType === "firm") {
                            setFirmwareInfo((prev: any) => ({
                                ...prev,
                                edcuVersion: fwVersion?.edcuVersionId,
                                icsVersion: fwVersion?.icsVersionId,
                                mcuVersion: fwVersion?.mcuVersionId,
                            }))
                        } else {
                            setHardwareInfo((prev: any) => ({
                                ...prev,
                                edcuVersion: hwVersion?.edcuVersionId,
                            }))
                        }
                    }}
                />
            </Col>
            <Col sm={3}>
                <Label value={"* 버전"} />
            </Col>
            <Col sm={8}>
                <Select
                    options={updateType === "firm" ? firmList : hardList}
                    defaultValue={formData.version}
                    onChange={(label, value) => {
                        setFormData({ ...formData, version: value as string })
                        if (updateType === "firm") {
                            setFirmwareInfo((prev: any) => ({ ...prev, [type]: value as number }))
                            setPrevFirmwareInfo((prev: any) => ({ ...prev, [type]: value as number }))
                        } else {
                            setHardwareInfo((prev: any) => ({ ...prev, [type]: value as number }))
                            setPrevHardwareInfo((prev: any) => ({ ...prev, [type]: value as number }))
                        }
                    }}
                />
            </Col>
            <Col sm={3}>
                <Label value={"* 담당자"} />
            </Col>
            <Col sm={8}>
                <Select
                    searchable
                    defaultValue={formData.manager}
                    options={managerList}
                    onChange={(label, value) => {
                        setFormData({ ...formData, manager: value as number })
                        if (updateType === "firm") {
                            setFirmwareInfo((prev: any) => ({ ...prev, managerId: value as number }))
                            setPrevFirmwareInfo((prev: any) => ({ ...prev, managerId: value as number }))
                        } else {
                            setHardwareInfo((prev: any) => ({ ...prev, managerId: value as number }))
                            setPrevHardwareInfo((prev: any) => ({ ...prev, managerId: value as number }))
                        }
                    }}
                />
            </Col>
            <Col sm={3}>
                <Label value={"* 업데이트 일"} />
            </Col>
            <Col sm={8}>
                <CustomDatePicker
                    width="100%"
                    height="100%"
                >
                    <ReactDatePicker
                        selected={startDate}
                        onChange={(date: any) => {
                            setStartDate(date)
                            if (updateType === "firm") {
                                setFirmwareInfo((prevInfo: any) => ({
                                    ...prevInfo,
                                    applyDt: formatYYYYMMDDhhmmss2(date as unknown as string),
                                }))
                                setPrevFirmwareInfo((prevInfo: any) => ({
                                    ...prevInfo,
                                    applyDt: formatYYYYMMDDhhmmss2(date as unknown as string),
                                }))
                            } else {
                                setHardwareInfo((prevInfo: any) => ({
                                    ...prevInfo,
                                    applyDt: formatYYYYMMDDhhmmss2(date as unknown as string),
                                }))
                            }
                        }}
                        locale={ko} // 한글로 변경
                        selectsStart
                        dateFormat="yyyy.MM.dd (eee)" // 시간 포맷 변경
                        showPopperArrow={false} // 화살표 변경
                        customInput={<DataPickerInput />}
                    />
                </CustomDatePicker>
            </Col>
            <Col sm={3}>
                <Label value={"사유"} />
            </Col>
            <Col sm={8}>
                <TextArea
                    value={formData.desc}
                    placeholder="업데이트 하는 사유를 적어 주세요."
                    maxLength={200}
                    onChange={e => {
                        setFormData({ ...formData, desc: e.target.value })
                        updateType === "firm"
                            ? setFirmwareInfo((prev: any) => ({ ...prev, updateDesc: e.target.value }))
                            : setHardwareInfo((prev: any) => ({ ...prev, updateDesc: e.target.value }))
                    }}
                />
            </Col>
        </Row>
    )
}

export default FirmHardUpdateModal
