import { createContext } from "react"
interface AlertContextType {
    open: (Component: any, props: any) => void
    close: (Component: any) => void
}

export const AlertDispatchContext = createContext<AlertContextType>({
    open: () => {},
    close: () => {},
})

export const AlertStateContext = createContext([])
