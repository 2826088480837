import { Type } from "p-ui"

export const Rental = {
    bike: [
        {
            title: "계약자",
            dataIndex: "riderNm",
        },
        {
            title: "차량번호",
            dataIndex: "bikeNo",
        },
        {
            title: "상태",
            dataIndex: "status",
        },
        {
            title: "시리얼번호",
            dataIndex: "serialNo",
        },
        {
            title: "차대번호",
            dataIndex: "vinNo",
        },
        {
            title: "생년월일",
            dataIndex: "birthDt",
        },
        {
            title: "연락처",
            dataIndex: "phone",
        },
        {
            title: "계약상품",
            dataIndex: "modelName",
        },
        {
            title: "계약시작",
            dataIndex: "serviceStartDt",
            sortable: true,
        },
        {
            title: "계약종료",
            dataIndex: "serviceEndDt",
            sortable: true,
        },
        {
            title: "해지일자",
            dataIndex: "cancelDt",
        },
        // {
        //     title: "보험연령",
        //     dataIndex: "ageOver",
        // },
        // {
        //     title: "보험사",
        //     dataIndex: "insuranceCompany",
        // },
        // {
        //     title: "피보험자",
        //     dataIndex: "insuranceName",
        // },
        // {
        //     title: "보험시작",
        //     dataIndex: "insuranceStartDt",
        // },
        // {
        //     title: "보험종료",
        //     dataIndex: "insuranceEndDt",
        // },
        {
            title: "상세보기",
            dataIndex: "bikeId",
            isFooterFixed: true,
        },
    ],
    rentalHistory: [
        {
            title: "계약자",
            dataIndex: "userName",
        },
        {
            title: "상태",
            dataIndex: "status",
        },

        {
            title: "생년월일",
            dataIndex: "birthDt",
        },
        {
            title: "연락처",
            dataIndex: "userPhone",
        },
        {
            title: "계약상품",
            dataIndex: "modelName",
        },
        {
            title: "사용시작",
            dataIndex: "startDt",
        },
        {
            title: "사용종료",
            dataIndex: "endDt",
        },
        {
            title: "해지일자",
            dataIndex: "cancelDt",
        },
        {
            title: "양도/교체 일자",
            dataIndex: "userEndDt",
        },
        {
            title: "보증금/위약금",
            dataIndex: "depositStatus",
        },
        // {
        //     title: "상세보기",
        //     dataIndex: "detail",
        // },
    ],
    rentalUser: [
        {
            title: "성명",
            dataIndex: "userName",
        },
        {
            title: "전화번호",
            dataIndex: "userPhone",
        },
        {
            title: "면허번호/사업자번호",
            dataIndex: "licenseNo",
        },
        {
            title: "기타",
            dataIndex: "detail",
        },
    ],
    paymentHistory: [
        {
            title: "성명",
            dataIndex: "userName",
        },
        {
            title: "서비스이름",
            dataIndex: "serviceName",
        },
        {
            title: "결제일자",
            dataIndex: "paidAt",
        },
        {
            title: "결제금액",
            dataIndex: "paymentAmount",
        },
        {
            title: "결제상태",
            dataIndex: "paymentStatus",
        },
        {
            title: "결제 회차",
            dataIndex: "cnt",
        },
    ],
    userHistory: [
        {
            title: "이름",
            dataIndex: "userName",
        },
        {
            title: "서비스이름",
            dataIndex: "modelName",
        },
        {
            title: "바이크번호",
            dataIndex: "bikeNo",
        },
        {
            title: "상태",
            dataIndex: "status",
        },
        {
            title: "이용기간",
            dataIndex: "period",
        },
        {
            title: "해지일",
            dataIndex: "cancelDt",
        },
        {
            title: "연장일",
            dataIndex: "extendDt",
        },
    ],
    userHistory2: [
        {
            title: "이름",
            dataIndex: "userName",
        },
        {
            title: "서비스이름",
            dataIndex: "modelName",
        },
        {
            title: "바이크번호",
            dataIndex: "bikeNo",
            sortable: true,
        },
        {
            title: "상태",
            dataIndex: "status",
        },
        {
            title: "이용기간",
            dataIndex: "period",
        },
        {
            title: "해지일",
            dataIndex: "cancelDt",
        },
        {
            title: "연장일",
            dataIndex: "extendDt",
        },
        {
            title: "결제이력",
            dataIndex: "paymentHistory",
        },
    ],
}

export const zentalStatus: { [key: string | number]: string | undefined } = {
    finish: "종료",
    cancel: "해지",
    active: "정상",
    change: "바이크 교체",
    transfer: "양도",
    extend: "연장",
    wait: "미계약",
    inspection: "점검",
    fail: "결제실패",
    0: "종료",
    1: "정상",
    2: "해지",
    3: "해지 예정",
    11: "이륜차 반납",
    21: "점검 요청",
}

export const ZentalSearchOption: Type.OptionType[] = [
    {
        label: "전체",
        value: "ALL",
    },
    {
        label: "계약자",
        value: "riderNm",
    },
    {
        label: "차량번호",
        value: "bikeNo",
    },
    {
        label: "시리얼",
        value: "serialNo",
    },
    {
        label: "연락처",
        value: "phone",
    },
    {
        label: "계약상품",
        value: "modelName",
    },
]

export const ZentalAlert: { [key: string]: string } = {
    successChangeCard: "카드 변경이 완료되었습니다.",
    failChangeCard: "카드 변경이 실패되었습니다. 다시 시도 해주세요.",
    checkCard: "현재 카드와 같은 카드를 선택했습니다. 다시 시도 해 주세요",
    successChangeAuthBike: "교환상태 변경이 완료되었습니다.",
    failChangeAuthBike: "교환상태 변경이 실패되었습니다. 다시 시도 해주세요.",
    successPayment: "결제요청이 완료되었습니다.",
    failPayment: "결제요청이 실패되었습니다. 다시 시도 해 주세요.",
    successTransfer: "양도 완료",
    failTransfer: "양도 실패",
    successContract: "젠탈 계약 설정 완료",
    successChnageBike: "바이크 변경 완료",
    failCardInfo: "변경될 카드가 선택되지 않았습니다.",
    success: "저장 완료",
    error: "문제가 발생하였습니다. 관리자에게 문의하세요.",
}
