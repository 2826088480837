import { BikeErrorCode, BikeErrorCodeInfo } from "@type/bbsType"
import { Code, Result } from "@type/responseType"
import axios from "./config"
import { BIKEERRORCODE } from "./urls"

export default {
    /**
     * Bike Error Code 목록 조회
     * @returns {Array}
     */
    async getBikeErrorCodeList(
        codeTypeParam: number[] | null | undefined,
        searchTextParam: string | null
    ): Promise<Result<BikeErrorCodeInfo>> {
        try {
            const params: { codeType?: string; searchText?: string } = {}
            if (codeTypeParam != null && codeTypeParam.length > 0) {
                if (Array.isArray(codeTypeParam)) {
                    params.codeType = codeTypeParam.join(",")
                } else {
                    params.codeType = codeTypeParam
                }
            }

            if (searchTextParam != null) {
                const encodedSearchText = encodeURIComponent(searchTextParam)
                params.searchText = encodedSearchText
            }
            return await axios.host.get(BIKEERRORCODE.GET_LIST, { params })
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * Bike Error Code 구분 조회
     * @returns {Array}
     */
    async getBikeErrorCodeType(): Promise<Result<Code[]>> {
        try {
            return await axios.host.get(BIKEERRORCODE.GET_TYPE)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * Bike Error Code 단건 조회
     * @param id codeId
     * @returns {Array}
     */
    async getBikeErrorCode(id: number | string | undefined): Promise<Result<BikeErrorCodeInfo>> {
        try {
            return await axios.host.get(BIKEERRORCODE.GET_DETAIL + id)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * Bike Error Code 등록
     * @param body faq info
     * @returns {Array}
     */
    async insertBikeErrorCode(body: BikeErrorCode): Promise<Result<BikeErrorCodeInfo>> {
        try {
            return await axios.host.post(BIKEERRORCODE.POST, body)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * Bike Error Code 수정
     * @param body bikeErrorCodeInfo
     * @returns {Array}
     */
    async updateBikeErrorCode(body: BikeErrorCodeInfo): Promise<Result<BikeErrorCodeInfo>> {
        try {
            return await axios.host.put(BIKEERRORCODE.PUT, body)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * Bike Error Code 삭제 (use_fg = 0처리)
     * @param id codeId
     * @returns {Array}
     */
    async deleteBikeErrorCode(id: number | string | undefined): Promise<Result<BikeErrorCodeInfo>> {
        try {
            return await axios.host.delete(BIKEERRORCODE.DELETE + id)
        } catch (err: any) {
            return err.message
        }
    },
}

interface paramType {
    codeType: number
    searchText: string
}
