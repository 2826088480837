import { Viewer } from "@toast-ui/react-editor"

const BbsViewer = (props: any) => {
    return (
        <div className="viewer-content">
            <Viewer
                initialValue={props.contents}
                customHTMLRenderer={{
                    htmlBlock: {
                        iframe(node: any) {
                            return [
                                {
                                    type: "openTag",
                                    tagName: "iframe",
                                    outerNewLine: true,
                                    attributes: node.attrs,
                                    classNames: ["youtube-box"],
                                },
                                { type: "html", content: node.childrenHTML ?? "" },
                                { type: "closeTag", tagName: "iframe", outerNewLine: true },
                            ]
                        },
                    },
                    htmlInline: {
                        big(node, { entering }) {
                            return entering
                                ? {
                                      type: "openTag",
                                      tagName: "big",
                                      attributes: node.attrs,
                                      classNames: ["youtube-box"],
                                  }
                                : { type: "closeTag", tagName: "big" }
                        },
                    },
                }}
            ></Viewer>
        </div>
    )
}

export default BbsViewer
