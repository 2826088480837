import { Col, Row } from "p-ui"
import styled from "styled-components"

export const MembershipCountWrap = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
    span {
        position: relative;
        font-weight: bold;
    }
    .membership-total {
        &::before {
            content: "●";
            position: absolute;
            color: var(--primary);

            left: -20px;
        }
    }
    .membership-end {
        &::before {
            content: "●";
            position: absolute;
            color: var(--danger);
            left: -20px;
        }
    }
    .membership-subscribing {
        &::before {
            content: "●";
            position: absolute;
            color: var(--success);
            left: -20px;
        }
    }
    .membership-cancellation {
        &::before {
            content: "●";
            position: absolute;
            color: var(--zen-orange);
            left: -20px;
        }
    }
    .membership-pause {
        &::before {
            content: "●";
            position: absolute;
            color: var(--warning);
            left: -20px;
        }
    }
    .membership-disSub {
        &::before {
            content: "●";
            position: absolute;
            color: var(--secondary);
            left: -20px;
        }
    }
`

export const MembershipHeaderWrap = styled.div`
    display: flex;
    gap: 10px;
`

export const MembershipDetailPageWrap = styled.div`
    h3 {
        margin-left: 0.5rem;
    }
    .flex-div {
        margin-top: 20px;
        display: flex;
        gap: 30px;
        margin-bottom: 20px;
        justify-content: flex-start;

        .info-div {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    .flex-end {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
    .flex-between {
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }
    .button-wrap {
        display: flex;
        align-items: center;
        gap: 5px;
    }
`

export const ValudeDiv = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 0;
    border-bottom: 1px;
    border-right: 0;
    border-left: 0;
    border-color: #dddd;
    border-style: solid;
`
export const FormWrap = styled.div`
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 4px;
    margin: 10px 0px 20px 0px;
`

export const FlexBtnWrap = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
    gap: 10px;
`

export const DataPickerInput = styled.input`
    border: none;
    height: 40px;
    width: 100%;
    background-color: #f8f8f8;
    outline: none;
    padding-left: 0.5rem;
    color: #6e6e6e;
`

export const DatePickerSpan = styled.span`
    height: 40px;
    width: 20px;
    text-align: center;
    line-height: 1.5;
    font-size: 22px;
`
export const ButtonWrap = styled.div`
    display: flex;
    justify-content: end;
`
