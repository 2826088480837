import { useEffect, useState } from "react"

import { Alert, Button, Col, TextArea } from "p-ui"
import { MemoIconWrapper, Title } from "../RentalFleetList/style"
import { CommonDiv, FlexColDiv, FlexDiv } from "@styles"

import rentalFleet from "src/apis/rentalFleet"

import { AlertProps } from "@type/common"
import { Type } from "p-ui"

const MemoComponents = (props: any) => {
    const { rentalFleetBikeData, setEditData, memo, setMemo } = props
    const { comment, bikeSerial } = rentalFleetBikeData || {}

    const [alertType, setAlertType] = useState<AlertProps>({
        title: "",
        isOpen: false,
        message: "",
        alertType: "primary",
    })

    const showAlert = (title: string, message: string, alertType: Type.AlertType) => {
        setAlertType({ title, isOpen: true, message, alertType })
    }

    const closeAlert = () => {
        setTimeout(() => {
            setAlertType({ ...alertType, isOpen: false })
        }, 2000)
    }

    const updateMemo = (bikeSerial: string) => {
        if (memo === "" && comment === null) {
            return showAlert("실패", "비고를 입력해주세요. / " + memo + "/ 메몽", "warning")
        }
        if (memo !== null && memo.length > 255) {
            return showAlert("실패", "255자 이내로 비고를 작성해주세요.", "warning")
        }
        rentalFleet
            .updateRentalFleetMemo(bikeSerial, memo)
            .then(res => {
                if (res.status === "SUCCESS") {
                    showAlert("성공", "비고가 수정되었습니다.", "primary")
                    setEditData((prev: any) => ({ ...prev, comment: memo }))
                } else {
                    showAlert("실패", "문제가 발생하였습니다. 관리자에게 문의하세요", "warning")
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (alertType.isOpen) {
            closeAlert()
        }
    }, [alertType])

    useEffect(() => {
        setMemo(comment)
    }, [rentalFleetBikeData])

    return (
        <Col>
            {rentalFleetBikeData && (
                <>
                    <FlexDiv
                        alignItems="center"
                        gap="10px"
                    >
                        <MemoIconWrapper
                            width="48px"
                            height="48px"
                            padding="12px"
                            justifyContent="center"
                            alignItems="center"
                            gap="10px"
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/images/ic_memo.svg`}
                                alt="memo_icon"
                            />
                        </MemoIconWrapper>
                        <Title>비고</Title>
                    </FlexDiv>
                    <CommonDiv width="628px">
                        <FlexColDiv alignItems="flex-end">
                            <TextArea
                                width={"100%"}
                                name="comment"
                                onChange={e => {
                                    setMemo(e.target.value)
                                }}
                                key={comment}
                                value={memo}
                                rows={5}
                            />
                        </FlexColDiv>
                    </CommonDiv>
                    <FlexDiv
                        width="628px"
                        justifyContent="flex-end"
                        margin="16px 0 0"
                    >
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                updateMemo(bikeSerial)
                            }}
                        >
                            비고 저장
                        </Button>
                        <Alert
                            title={alertType.title}
                            type={alertType.alertType}
                            open={alertType.isOpen}
                            body={alertType.message}
                            onClose={() => setAlertType({ ...alertType, isOpen: false })}
                        />
                    </FlexDiv>
                </>
            )}
        </Col>
    )
}

export default MemoComponents
