import styled from "styled-components"

export const PushUi = styled.div<{ top: number }>`
    padding: 10px;
    border-radius: 12px;
    position: absolute;
    top: ${props => props.top + "px"};
    background: #ededed;
    color: black;
    width: 315px;
`

export const PushContent = styled.div<{ isNowrap: Boolean }>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: ${props => (props.isNowrap ? "nowrap" : "break-spaces")};
    ${props => (props.isNowrap ? "" : "word-break: break-all")};
`

export const PushModalContent = styled.div`
    font-size: 1.5rem;
    white-space: pre-line;
`

export const FloatRightDiv = styled.div`
    float: right;
`
