import { getManagerId, getManagerName, getOrgName } from "@common/util"
import { RequestParamType, ReviewParamType } from "@type/formType"
import { atom, selector } from "recoil"

export const requestParamState = atom<RequestParamType>({
    key: "requestParamState",
    default: {
        reqTitle: "",
        reqOrgNm: getOrgName() as string,
        reqUserNm: getManagerName() as string,
        taskOrgNm: "시스템개발",
        serviceCode: 0,
        priority: 1,
        reqType: 0,
        reqDesc: "",
        reqIssue: "",
        reqManager: Number(getManagerId()),
    },
})

export const reviewParamState = atom<ReviewParamType>({
    key: "reviewParamState",
    default: {
        taskOrgNm: "",
        taskManagerId: 0,
        taskStatus: 1,
        taskDesc: "",
        completionDt: "",
    },
})
