import React, { useEffect, useMemo, useState } from "react"

import { useRecoilValue } from "recoil"
import { statuState, mapLevelState, mapCenterState } from "src/store/staiton"

import { MapMarker, Map, Polyline, CustomOverlayMap, useMap } from "react-kakao-maps-sdk"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import {
    CustomOverlay,
    FullScreenBtn,
    MapWrapper,
    OpacityBtnWrap,
    OpacitySwitchWrap,
    TotalMileColorBar,
} from "../styles"
import { Button, Icon, Switch, Tooltip } from "p-ui"
import StationPopup from "./StationPopup"

import { Station, BikeLatLng } from "@type/responseType"
import { formatYYYYMMDDhhmm } from "@common/util"
import { FlexDiv, Text } from "@styles"

function StationMap(props: StationHeaderProps) {
    const { stationData, polylineData, savePolylineData, isSwitch, totalMile } = props
    const handle = useFullScreenHandle()
    const status = useRecoilValue(statuState)
    const level = useRecoilValue(mapLevelState)
    const center = useRecoilValue(mapCenterState)
    const [clickedId, setClickedId] = useState<number>(0)
    const [startEndData, setStartEndData] = useState([])
    const [saveStartEndData, setSaveStartEndData] = useState([])

    const [markerVisible, setMarkerVisible] = useState(true)
    const [opacityCount, setOpacityCount] = useState<number>(4)
    const [selectedMarker, setSeleteMarker] = useState<any>()

    const totalMileArr = useMemo(() => {
        if (saveStartEndData.length > 0) {
            const newArr: { totalMile: any; serial: any }[] = []

            saveStartEndData.forEach((item: any) => {
                item.forEach((innerItem: any) => {
                    if (innerItem?.title === "S") {
                        newArr.push({ totalMile: innerItem.totalMile, serial: innerItem.serial })
                    }
                })
            })

            if (totalMile?.totalMile !== undefined) {
                newArr.unshift({ ...totalMile, isNotSave: true })
            }

            return newArr
        } else {
            return totalMile?.totalMile !== undefined ? [{ ...totalMile, isNotSave: true }] : []
        }
    }, [totalMile, saveStartEndData])

    const polylinePath = useMemo(() => {
        if (!polylineData) {
            return []
        }
        const newArray = [polylineData[0], polylineData[polylineData.length - 1]].map((item, i) => {
            return {
                ...item,
                title: i === 0 ? "S" : "E",
                serial: item?.serial,
            }
        })

        setStartEndData(newArray as [])
        return polylineData?.map((item: BikeLatLng) => {
            return {
                lat: Number(item.gpsLat),
                lng: Number(item.gpsLng),
            }
        })
    }, [polylineData, totalMile])

    const savePolylinePath = useMemo(() => {
        if (!savePolylineData) {
            return []
        }

        const newSaveArr: any[][] = []
        return savePolylineData.map(innerArr => {
            const newArray = [innerArr[0], innerArr[innerArr.length - 1]].map((item, i) => {
                return {
                    ...item,
                    title: i === 0 ? "S" : "E",
                    totalMile: item.totalMile,
                    serial: item.serial,
                }
            })
            newSaveArr.push(newArray)
            setSaveStartEndData(newSaveArr as [])
            return innerArr.map((item: any) => {
                return {
                    lat: Number(item.gpsLat),
                    lng: Number(item.gpsLng),
                }
            })
        })
    }, [savePolylineData])

    function getImageOption(status: number, stationName: string, isCenter: boolean) {
        let imageUri = ""
        if (stationName.includes("청담본사")) {
            imageUri = `${process.env.PUBLIC_URL}/images/zentropyMainIcon.png`
        } else if (isCenter && status == 1) {
            imageUri = `${process.env.PUBLIC_URL}/images/center.svg`
        } else if (isCenter && NOT_INSTALLED.includes(status.toString())) {
            imageUri = `${process.env.PUBLIC_URL}/images/center_un.svg`
        } else if (status == 1) {
            imageUri = `${process.env.PUBLIC_URL}/images/stationIcon.png`
        } else if (status == 2) {
            imageUri = `${process.env.PUBLIC_URL}/images/evIcon.png`
        } else {
            imageUri = `${process.env.PUBLIC_URL}/images/stationIcon2.png`
        }

        return {
            src: imageUri,
            size: { width: 20, height: 27 },
        }
    }

    const EventMarker = ({ markerInfo, index, onClick }: any) => {
        const [isVisible, setIsVisible] = useState(false)

        useEffect(() => {
            if (index === selectedMarker) {
                setIsVisible(true)
            } else {
                setIsVisible(false)
            }
        }, [selectedMarker])

        return (
            <div style={{ position: "relative" }}>
                <MapMarker
                    position={markerInfo.latlng}
                    image={getImageOption(markerInfo.status, markerInfo.stationName, markerInfo.centerNm != null)}
                    onClick={onClick}
                ></MapMarker>
                {isVisible && (
                    <StationPopup
                        data={markerInfo}
                        onClick={() => {
                            setIsVisible(false)
                            setSeleteMarker(null)
                        }}
                    />
                )}
            </div>
        )
    }

    const EventCustomOverlay = ({ data }: any) => {
        const [isVisible, setIsVisible] = useState(false)

        return (
            <CustomOverlayMap
                position={{
                    lat: data.gpsLat as unknown as number,
                    lng: data.gpsLng as unknown as number,
                }}
            >
                <CustomOverlay>
                    {isVisible && (
                        <div className="info-window">
                            {formatYYYYMMDDhhmm(data.time)} / {data.serial}
                        </div>
                    )}
                    <h2
                        onClick={() => {
                            setIsVisible(!isVisible)
                        }}
                        className={data.title === "S" ? "start" : "end"}
                    >
                        {data.title}
                    </h2>
                </CustomOverlay>
            </CustomOverlayMap>
        )
    }

    const ChangeOpacity = () => {
        const decreaseCount = () => {
            if (opacityCount <= 1) {
                return
            } else {
                setOpacityCount(opacityCount - 1)
            }
        }
        const increaseCount = () => {
            if (opacityCount >= 5) {
                return
            } else {
                setOpacityCount(opacityCount + 1)
            }
        }
        const checkColorArr = (i: number, item: any) => {
            if (i === 0) {
                return item.isNotSave ? "black" : color[i]
            } else {
                return totalMileArr.some(element => element.isNotSave) ? color[i - 1] : color[i]
            }
        }

        return (
            <OpacityBtnWrap>
                <div>
                    {totalMileArr.map((item: any, i: any) => {
                        return (
                            <span>
                                {item ? (
                                    <FlexDiv
                                        alignItems="center"
                                        gap="2px"
                                    >
                                        <TotalMileColorBar color={checkColorArr(i, item)} />
                                        <div>
                                            {item?.serial} / {item?.totalMile}km
                                        </div>
                                    </FlexDiv>
                                ) : (
                                    ""
                                )}
                            </span>
                        )
                    })}
                </div>
                <FlexDiv gap="5px">
                    <Button
                        size="small"
                        onClick={() => {
                            decreaseCount()
                        }}
                    >
                        -
                    </Button>
                    <Button
                        size="small"
                        disabled
                        width={"60px"}
                    >
                        {opacityCount}
                    </Button>
                    <Button
                        onClick={() => {
                            increaseCount()
                        }}
                        size="small"
                    >
                        +
                    </Button>
                </FlexDiv>
            </OpacityBtnWrap>
        )
    }

    const SavePolyLine = (props: any) => {
        const [isMouseOver, setIsMouseOver] = useState(false)

        return (
            <Polyline
                path={props.path}
                strokeWeight={4} // 선의 두께 입니다
                strokeColor={isMouseOver ? "#000000" : color[props.i]} // 선의 색깔입니다
                strokeOpacity={0.5} // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                strokeStyle={"solid"} // 선의 스타일입니다
                onMouseover={() => setIsMouseOver(true)}
                onMouseout={() => setIsMouseOver(false)}
            />
        )
    }
    const onCreatedMap = () => {
        const mapContainer = document.querySelector("#__react-kakao-maps-sdk___Map svg") as HTMLElement
        if (mapContainer !== null) {
            mapContainer.style.backgroundColor = `rgba(0,0,0, .${opacityCount})`
        }
    }

    const mapComponent = (
        <Map
            center={center}
            style={{ width: "100%", height: "100%" }}
            level={level}
            onCreate={onCreatedMap}
        >
            {markerVisible &&
                stationData?.map((station: Station) => {
                    if (status == STATUS.ALL) {
                        return (
                            <EventMarker
                                key={station.stationId}
                                markerInfo={station}
                                index={station.stationId}
                                onClick={() => setSeleteMarker(station.stationId)}
                                isClicked={selectedMarker === station.stationId}
                            />
                        )
                    }
                    if (status == STATUS.CENTER && station.centerNm != null) {
                        return (
                            <EventMarker
                                key={station.stationId}
                                markerInfo={station}
                                index={station.stationId}
                                onClick={() => setSeleteMarker(station.stationId)}
                                isClicked={selectedMarker === station.stationId}
                            />
                        )
                    }
                    if (status == STATUS.INSTALLED && station.status == "1") {
                        // 설치완료
                        return (
                            <EventMarker
                                key={station.stationId}
                                markerInfo={station}
                                index={station.stationId}
                                onClick={() => setSeleteMarker(station.stationId)}
                                isClicked={selectedMarker === station.stationId}
                            />
                        )
                    }
                    if (status == STATUS.PALNNING && NOT_INSTALLED.includes(station.status)) {
                        // 설치예정
                        return (
                            <EventMarker
                                key={station.stationId}
                                markerInfo={station}
                                index={station.stationId}
                                onClick={() => setSeleteMarker(station.stationId)}
                                isClicked={selectedMarker === station.stationId}
                            />
                        )
                    }
                    if (status == STATUS.ELECTRIC && station.status == "2") {
                        // 전기시공
                        return (
                            <EventMarker
                                key={station.stationId}
                                markerInfo={station}
                                index={station.stationId}
                                onClick={() => setSeleteMarker(station.stationId)}
                                isClicked={selectedMarker === station.stationId}
                            />
                        )
                    }
                })}
            {polylineData && polylineData.length > 0 && (
                <>
                    <Polyline
                        path={[...polylinePath]}
                        strokeWeight={4} // 선의 두께 입니다
                        strokeColor={"#222222"} // 선의 색깔입니다
                        strokeOpacity={0.7} // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                        strokeStyle={"solid"} // 선의 스타일입니다
                    />
                    {startEndData.map(item => {
                        return <EventCustomOverlay data={item} />
                    })}
                </>
            )}
            {savePolylineData && savePolylineData.length > 0 && (
                <>
                    {savePolylinePath.map((item, i) => {
                        return (
                            <SavePolyLine
                                path={savePolylinePath[i]}
                                i={i}
                            />
                        )
                    })}
                    {saveStartEndData.map((item: any) => {
                        return item.map((data: any) => {
                            return <EventCustomOverlay data={data} />
                        })
                    })}
                </>
            )}
        </Map>
    )

    useEffect(() => {
        setSeleteMarker(null)
    }, [stationData])
    return (
        <MapWrapper>
            <FullScreen
                className="full-screen"
                handle={handle}
            >
                <FullScreenBtn
                    onClick={() => {
                        if (handle.active) {
                            handle.exit()
                        } else {
                            handle.enter()
                        }
                    }}
                >
                    {handle.active ? (
                        <Icon.ExitFullScreen
                            width={40}
                            height={40}
                        />
                    ) : (
                        <Icon.FullScreen
                            width={40}
                            height={40}
                        />
                    )}
                </FullScreenBtn>
                {handle.active && mapComponent}
            </FullScreen>
            {mapComponent}
            {savePolylineData && <ChangeOpacity />}
            {isSwitch && (
                <OpacitySwitchWrap>
                    <Text>마커 ON/OFF</Text>
                    <Switch
                        size="small"
                        checked={markerVisible}
                        onChange={e => {
                            setMarkerVisible(e)
                        }}
                    />
                </OpacitySwitchWrap>
            )}
        </MapWrapper>
    )
}

export default StationMap

interface StationHeaderProps {
    stationData: Station[]
    polylineData?: BikeLatLng[]
    savePolylineData?: any[]
    isSwitch?: boolean
    totalMile?: any
}

const STATUS = {
    ALL: "all",
    CENTER: "center",
    INSTALLED: "installed",
    PALNNING: "planning",
    ELECTRIC: "electric",
}
const color = [
    "#ff2e2e",
    "#ffaf37",
    "#f5ff38",
    "#80ff49",
    "#17ebdd",
    "#17aeff",
    "#0043fb",
    "#a023ff",
    "#e958ff",
    "#6c461c",
]

const NOT_INSTALLED = ["0", "3", "4"]
