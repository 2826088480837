import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import {
    connectParamState,
    extendState,
    insuranceFormState,
    serviceUserConnectParamState,
    zentalModalState,
} from "src/store/zental"

import { CommonDiv, FlexBetween, FlexColDiv, GridDiv, Text } from "@styles"
import { Button, DataTable, Icon, Modal, PageHeader, Select, Tooltip, Type } from "p-ui"
import Divider from "@components/widgets/Divider"
import ContractForm from "../../Components/ContractForm"
import DetailContentWrap from "../../Components/DetailContentWrap"
import BikeInfoForm from "@pages/Rental/Components/BikeInfoForm"
import ServiceConnectModal from "@pages/Rental/Components/ServiceConnectModal"
import BikeDetailHeader from "@pages/Rental/Components/BikeDetailHeader"
import AssignmentModal from "@pages/Rental/Components/AssignmentModal"
import BikeChangeModal from "@pages/Rental/Components/BikeChangeModal"

import moment from "moment"
import useAlert from "@context/alert/useAlert"

import { paymentStatus } from "@common/constatns"
import { Rental, ZentalAlert, zentalStatus } from "@pages/Rental/constants"

import { ObjectType } from "@type/common"
import {
    BikeHistoryType,
    BikeServiceInfoType,
    PaymentHistoryType,
    UserCardType,
    UserHistoryType,
} from "@type/responseType"
import apis from "@apis"
import RiderInfoForm from "@pages/Rental/Components/RiderInfoForm"
import CardForm from "@pages/Rental/Components/CardForm"
import ExtandForm from "@pages/Rental/Components/ExtandForm"
import { BikeParam } from "@type/formType"

function RentalBikeDetailPage() {
    const location = useLocation()
    const id = new URLSearchParams(location.search).get("id")
    const navigate = useNavigate()

    const { openAlert } = useAlert()

    const resetInsurance = useResetRecoilState(insuranceFormState)
    const resetConnectParam = useResetRecoilState(connectParamState)
    const resetServiceParma = useResetRecoilState(serviceUserConnectParamState)

    const connectParam = useRecoilValue(connectParamState)
    const serviceUserConnectParam = useRecoilValue(serviceUserConnectParamState)

    const [modalOpen, setModalOpen] = useRecoilState(zentalModalState)
    const [insuranceForm, setInsuranceForm] = useRecoilState(insuranceFormState)

    const [column, setColumn] = useState(Rental.rentalHistory)
    const [userHistoryColumn, setUserHistoryColumn] = useState(Rental.userHistory)
    const [paymentHistoryColumn, setPaymentHistoryColumn] = useState(Rental.paymentHistory)

    const [bikeData, setBikeData] = useState<BikeServiceInfoType>(BikeServiceInfoDefault)
    console.log("bikeData: ", bikeData)
    const [bikeHistoryData, setBikeHistoryData] = useState<BikeHistoryType[]>([])
    const [paymentHistoryData, setPaymentHistoryData] = useState<PaymentHistoryType[]>([])
    const [userHistoryData, setUserHistoryData] = useState<UserHistoryType[]>([])
    const [userCard, setUserCard] = useState<UserCardType>(userCardDefault)

    const [options, setOptions] = useState<{ label: any; value: any }[]>([])

    const [newBikeId, setNewBikeId] = useState()
    const [bikeStatus, setBikeStatus] = useState()
    const [transfereeId, setTransfereeId] = useState()
    const [transfereeDeposit, setTransfereeDeposit] = useState<string>("0")
    const [riderSn, setRiderSn] = useState()
    const [cardOption, setCardOption] = useState<Type.OptionType[]>([])
    const [selectedCard, setSelectedCard] = useState("")
    const [extendRecoilState, setExtendState] = useRecoilState(extendState)

    const setDefaultParam = () => {
        resetConnectParam()
        resetServiceParma()
    }
    const recentCard = cardOption?.find((item: any) => {
        return item.value === bikeData?.paymentCuid
    }) ?? { label: "-", value: "-" }

    /**연결 안된 젠탈 라이더 */
    const getZentalRider = async () => {
        await apis.Zental.getZentalRider().then(res => {
            if (res.status === "SUCCESS") {
                if (res.resultData.length === 0) {
                    setOptions([])
                } else {
                    let newUserArr: { label: any; value: any }[] = []
                    res.resultData.map((item: any) => {
                        newUserArr.push({ label: item.email, value: item.riderSn })
                        setOptions(newUserArr)
                    })
                }
            }
        })
    }
    /**연결안된 유저 */
    const getUncontractUserList = async () => {
        await apis.Zental.getUnContarctUser().then(res => {
            if (res.status === "SUCCESS") {
                if (res.resultData.length === 0) {
                    setOptions([])
                } else {
                    let newUserArr: { label: any; value: any }[] = []
                    res.resultData.map((item: any) => {
                        newUserArr.push({
                            label: `${item.userName} (${
                                item.userType == 0 ? moment(item.birthDt).format("YYYY.MM.DD") : item.licenseNo
                            })`,
                            value: item.userId,
                        })
                        setOptions(newUserArr)
                    })
                }
            }
        })
    }

    /**연결안된 바이크 */
    const getUncontractBikeList = async () => {
        await apis.Zental.getUnContractBike().then(res => {
            if (res.status === "SUCCESS") {
                let newUserArr: { label: any; value: any }[] = []
                res.resultData.map((item: any) => {
                    newUserArr.push({ label: `${item.bikeNo} / ${item.serialNo}`, value: item.bikeId })
                    setOptions(newUserArr)
                })
            }
        })
    }

    /**
     * 젠탈 유저의 사용 가능한 카드 목록 조회
     */
    const getUserZtCardList = async () => {
        await apis.Zental.getUserZtCardList(bikeData?.userId).then(res => {
            if (res.status === "SUCCESS") {
                let cardList: Type.OptionType[] = [{ label: "선택", value: "select", disabled: true }]
                res.resultData.map((item: any) => {
                    cardList.push({
                        label: `${item.cardName}(${item.cardNumber?.substring(item.cardNumber.length - 4)})`,
                        value: item.customerUid,
                    })
                })
                setCardOption(cardList)
            }
        })
    }

    /**바이크 데이터 가지고오고 설정 */
    const getBikeData = async () => {
        if (id) {
            await apis.Zental.getZentalBikeOne(id).then(res => {
                if (res.status === "SUCCESS") {
                    const resultData = res.resultData
                    const bikeServiceInfo: BikeServiceInfoType = resultData.bikeServiceInfo
                    setBikeData(bikeServiceInfo)
                    setBikeHistoryData(resultData.bikeHistory)
                    setPaymentHistoryData(resultData.paymentHistory)
                    setUserHistoryData(resultData.userHistory)
                    setUserCard(resultData.userCard)
                }
            })
        }
    }

    /** 계약 양도 */
    const assignmentBike = async () => {
        await apis.Zental.changeService({
            bikeId: Number(bikeData.bikeId),
            transferorId: bikeData.userId,
            transfereeId: transfereeId,
            deposit: transfereeDeposit,
        }).then(res => {
            if (res.status === "SUCCESS") {
                setModalOpen(prev => ({
                    ...prev,
                    assignmentModalState: false,
                }))
                openAlert({ title: "성공", body: ZentalAlert.successTransfer, type: "primary" })

                getBikeData()
            } else {
                openAlert({ title: "실패", body: ZentalAlert.failTransfer, type: "warning" })
            }
        })
    }

    /** 계약 양도 Validation */
    const assignmentBikeValidation = () => {
        if (!transfereeId) {
            openAlert({ title: "입력 실패", body: "양수자를 입력해주세요", type: "warning" })

            return
        }

        if (transfereeDeposit == "-1") {
            openAlert({ title: "입력 실패", body: "양수자의 보증금을 입력해주세요", type: "warning" })

            return
        }
        assignmentBike()
    }

    /** 보험 연결 */
    const connectInsurance = async () => {
        await apis.Zental.insertInsurance(insuranceForm).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "저장", body: ZentalAlert.successContract, type: "primary" })
                resetInsurance()
                setModalOpen(prev => ({
                    ...prev,
                    insuranceModalState: false,
                }))
                setBikeData(BikeServiceInfoDefault)
                getBikeData()
            } else {
                openAlert({
                    title: "실패",
                    body: ZentalAlert.error,
                    type: "warning",
                })
            }
        })
    }

    /** 바이크 정보 변경 */
    const changeBikeInfo = async () => {
        if (bikeData.bikeNo.length > 10) {
            openAlert({
                title: "실패",
                body: "차량 번호를 확인해주세요.",
                type: "warning",
            })

            getBikeData()
            return
        }
        await apis.Zental.changeBike({
            bikeId: bikeData.bikeId,
            bikeNo: bikeData.bikeNo,
            status: bikeData.status,
            serialNo: bikeData.serialNo,
        }).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "저장", body: ZentalAlert.successChnageBike, type: "primary" })

                getBikeData()
            } else {
                openAlert({
                    title: "실패",
                    body: ZentalAlert.error,
                    type: "warning",
                })
            }
        })
    }

    const connectServiceValidation = () => {
        const { startDt } = connectParam
        const { userId, deposit } = serviceUserConnectParam

        const fieldList: string[] = ["modelId", "startDt", "discount"]
        const notValidMessage: ObjectType = {
            modelId: "상품 모델을 선택해주세요",
            startDt: "시작 일자를 입력해주세요",
            discount: "할인액을 선택해주세요",
        }

        const isNotValidField = fieldList.find(field => !connectParam[field] || connectParam[field] === -1)

        if (isNotValidField) {
            openAlert({
                title: "실패",
                body: notValidMessage[isNotValidField].toString(),
                type: "warning",
            })

            return
        }
        if (riderSn === "") {
            openAlert({
                title: "실패",
                body: "라이더를 선택 해주세요",
                type: "warning",
            })
            return
        }
        if (userId.toString() === "") {
            openAlert({
                title: "실패",
                body: "사용자를 선택 해주세요",
                type: "warning",
            })

            return
        }
        if (deposit.toString() === "-1") {
            openAlert({
                title: "실패",
                body: "보증금은 비워둘 수 없습니다",
                type: "warning",
            })

            return
        }
        if (startDt.toString().length !== 10) {
            openAlert({
                title: "실패",
                body: "시작일 양식을 맞춰 주세요",
                type: "warning",
            })
        }
        connectService()
    }

    /** 서비스 연결*/
    // TODO Refactoring Params 정리
    const connectService = async () => {
        const connectParams = {
            zental: connectParam,
            ztMembershipUser: serviceUserConnectParam,
            purchaserSn: bikeData.purchaserSn,
            serialNo: bikeData.serialNo,
            bikeId: bikeData.bikeId,
            riderSn: riderSn,
            discount: connectParam.discount,
        }

        if (bikeData.riderNm) {
            await apis.Zental.connectService(connectParams).then(res => {
                if (res.status === "SUCCESS") {
                    openAlert({
                        title: "저장",
                        body: ZentalAlert.success,
                        type: "primary",
                    })

                    getBikeData()
                } else {
                    openAlert({
                        title: "실패",
                        body: res.resultMessage,
                        type: "warning",
                    })
                }
            })
        } else {
            await apis.Zental.connectService(connectParams).then(res => {
                if (res.status === "SUCCESS") {
                    openAlert({
                        title: "저장",
                        body: ZentalAlert.success,
                        type: "primary",
                    })
                    getBikeData()
                    setModalOpen(prev => ({
                        ...prev,
                        connectModalState: false,
                    }))
                } else {
                    openAlert({
                        title: "실패",
                        body: res.resultMessage,
                        type: "warning",
                    })
                }
            })
        }
    }
    /**서비스 바이크 변경 */
    const changeSeriveBike = async () => {
        await apis.Zental.changeServiceBike({
            bikeId: bikeData.bikeId,
            newBikeId: newBikeId,
            userId: bikeData.userId,
            bikeStatus: bikeStatus,
        }).then(res => {
            if (res.status === "SUCCESS") {
                getBikeData()
                openAlert({
                    title: "저장",
                    body: ZentalAlert.success,
                    type: "primary",
                })
                setModalOpen(prev => ({
                    ...prev,
                    bikeChangeModalState: false,
                }))
                setTimeout(() => {
                    navigate(`/rental/bike`)
                }, 1000)
            } else {
                openAlert({
                    title: "실패",
                    body: ZentalAlert.error,
                    type: "warning",
                })
            }
        })
    }

    const changeSeriveBikeValidation = () => {
        let isOpen = false
        let message = ""

        switch (true) {
            case bikeStatus === "":
                isOpen = true
                message = "바이크 상태를 입력해주세요."
                break
            case newBikeId == "":
                isOpen = true
                message = "변경할 바이크를 입력해주세요."
                break
        }
        if (!isOpen) {
            changeSeriveBike()
            return
        }
        openAlert({
            title: "입력 실패",
            body: message,
            type: "warning",
        })
    }

    /**
     * column 세팅하는 함수 (userHistory, detailColumn)
     * @param column
     * @param type history, detail
     */
    const convertColumn = (
        column: {
            title: string
            dataIndex: string
        }[],
        type: String = "history"
    ) => {
        const newColumn = column.map(item => {
            switch (item.dataIndex) {
                case "status":
                    return {
                        ...item,
                        render: (item: any) => {
                            return <span>{zentalStatus[item]}</span>
                        },
                    }
                case "cancelDt":
                    return {
                        ...item,
                        render: (item: any) => {
                            return <span>{item === null ? "-" : item}</span>
                        },
                    }
                case "extendDt":
                    return {
                        ...item,
                        render: (item: any) => {
                            console.log(item)
                            return <span>{item === null ? "-" : item}</span>
                        },
                    }
                case "depositStatus":
                    return {
                        ...item,
                        render: (item: string) => {
                            return (
                                <Tooltip
                                    content="상태를 변경합니다(대기 -> 완료)"
                                    placement="left"
                                >
                                    <Button
                                        size="small"
                                        onClick={() => openAlert({ body: "준비 중인 기능 입니다.", type: "warning" })}
                                        disabled={item === "SUCCESS"}
                                    >
                                        {item === "SUCCESS" ? "완료" : "대기"}
                                    </Button>
                                </Tooltip>
                            )
                        },
                    }
                default:
                    return {
                        ...item,
                    }
            }
        })
        type == "history" ? setUserHistoryColumn(newColumn) : setColumn(newColumn)
    }

    /**
     * paymentHistory Column 세팅하는 함수
     */
    const convertPaymentHistoryColumn = () => {
        const newColumn = paymentHistoryColumn.map(item => {
            switch (item.dataIndex) {
                case "paymentStatus":
                    return {
                        ...item,
                        render: (item: any) => {
                            return <span>{paymentStatus[item]}</span>
                        },
                    }
                case "paymentAmount":
                    return {
                        ...item,
                        render: (item: any) => {
                            return <span>{item.toLocaleString("ko-KR")}</span>
                        },
                    }
                default:
                    return { ...item }
            }
        })
        setPaymentHistoryColumn(newColumn)
    }

    const convertBikeHistory = useMemo(() => {
        return bikeHistoryData.map(item => {
            return {
                ...item,
                userName: (
                    <Tooltip
                        placement="right"
                        content="클릭 시 고객 관리 페이지로 이동 됩니다"
                    >
                        <Text
                            color="blue"
                            decoration="underline"
                            onClick={() => navigate({ pathname: "/rental/userdetail", search: `?id=${item.userId}` })}
                        >
                            {item.userName}
                        </Text>
                    </Tooltip>
                ),
            }
        })
    }, [bikeHistoryData])
    /**
     * 계약 종료 Action
     */
    const cancelContract = async (type: string) => {
        await apis.Zental.deleteService(bikeData.zentalId, type).then(res => {
            const result = type == "finish" ? "종료" : "해지"
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "성공",
                    body: `계약 ${result} 되었습니다.`,
                    type: "primary",
                })
                getBikeData()
            } else {
                openAlert({
                    title: "실패",
                    body: `계약 ${result} 실패하였습니다.`,
                    type: "warning",
                })
            }
        })
    }

    const resetPassAction = async () => {
        await apis.Zental.resetPassword(bikeData.riderSn).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "변경",
                    body: "Password가 변경 되었습니다.",
                    type: "primary",
                })

                getBikeData()
            } else {
                openAlert({
                    title: "실패",
                    body: "Password 변경 실패 하였습니다.",
                    type: "warning",
                })
            }
        })
    }

    /** 라이더 계정 추가 */
    const insertRider = async () => {
        await apis.Zental.insertZentalRider().then(res => {
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "계정 생성 성공",
                    body: res.resultData + "계정 생성 성공",
                    type: "primary",
                })

                getZentalRider()
            } else {
                openAlert({
                    title: "계정 생성 실패",
                    body: "계정 생성 실패하였습니다. 관리자에게 문의하세요",
                    type: "warning",
                })
            }
        })
    }

    /**
     * 젠탈 상태 업데이트
     * @param status
     */
    const updateZentalStatus = async (status: number) => {
        const param: BikeParam = {
            zentalId: bikeData.zentalId,
            newStatus: status,
        }
        if (status === 22) {
            param.membershipUserId = bikeData.membershipUserId // membershipId를 적절한 값으로 설정해야 합니다.
        }
        await apis.Zental.updateZentalStatus(param).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "저장", body: "변경 되었습니다.", type: "primary" })
                getBikeData()
            } else {
                openAlert({
                    title: "실패",
                    body: "변경 실패 되었습니다.. 관리자에게 문의하세요.",
                    type: "warning",
                })
            }
        })
    }

    /**
     * 교환 불가 여부 변경
     */
    const changePermit = async (state: string) => {
        const param = { authPermitAt: state, serialNo: bikeData.serialNo }
        await apis.Goods.udateBikeAuth(param).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "성공",
                    body: "교환 상태 변경 성공",
                    type: "primary",
                })
                getBikeData()
            } else {
                openAlert({
                    title: "실패",
                    body: "변경 실패",
                    type: "warning",
                })
            }
        })
    }

    /**
     * fail 카운트 차감
     */
    const cutFailCnt = async (cutCount: number) => {
        await apis.Zental.cutFailCnt(bikeData.zentalId, cutCount).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "성공",
                    body: `실패 횟수 ${cutCount}회 차감`,
                    type: "primary",
                })
                getBikeData()
            } else {
                openAlert({
                    title: "실패",
                    body: "실패 횟수 차감 실패",
                    type: "warning",
                })
            }
        })
    }

    /**
     * 젠탈 재결제
     */
    const rePayZental = () => {
        apis.Zental.rePayZental(bikeData.zentalId).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "성공",
                    body: "재결제 요청 완료",
                    type: "primary",
                })
                getBikeData()
            } else {
                openAlert({
                    title: "실패",
                    body: res.resultMessage,
                    type: "warning",
                })
            }
        })
    }

    /**
     * 검증
     * @returns
     */
    const checkCard = () => {
        if (selectedCard === recentCard.value) {
            openAlert({ title: "실패", body: ZentalAlert.checkCard, type: "warning" })

            return
        } else {
            updateCard()
        }
    }

    /**
     * 카드 변경 api
     */
    const updateCard = () => {
        const param = { zentalId: bikeData.zentalId, paymentCuid: selectedCard }
        if (selectedCard == "select") {
            openAlert({ title: "실패", body: ZentalAlert.failCardInfo, type: "warning" })
            return
        }
        apis.Zental.updateZentalCard(param)
            .then(res => {
                if (res.status === "SUCCESS") {
                    openAlert({
                        title: "성공",
                        body: ZentalAlert.successChangeCard,
                        type: "primary",
                    })
                    getBikeData()
                } else {
                    openAlert({
                        title: "실패",
                        body: ZentalAlert.failChangeCard,
                        type: "warning",
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const extendFormValidation = () => {
        const fieldList = Object.keys(extendRecoilState)

        const notValidMessage: ObjectType = {
            modelId: "상품 모델을 선택해주세요",
            startDt: "시작 일자를 입력해주세요",
            discount: "할인액을 선택해주세요",
            deposit: "보증금을 선택해주세요",
        }

        const isNotValidField = fieldList.find(field => extendRecoilState[field] === -1)

        if (isNotValidField) {
            openAlert({
                title: "실패",
                body: notValidMessage[isNotValidField].toString(),
                type: "warning",
            })
            return
        }
        extendZental()
    }

    // * 연장 API 연결
    const extendZental = () => {
        apis.Zental.extendZental(extendRecoilState)
            .then(res => {
                if (res.status == "SUCCESS") {
                    openAlert({ title: "SUCCESS", body: "연장 완료", type: "primary" })
                    setModalOpen(prev => ({
                        ...prev,
                        extendMoalState: false,
                    }))
                    getBikeData()
                } else {
                    openAlert({ title: "ERROR", body: res.resultMessage, type: "warning" })
                }
            })
            .catch(err => {
                openAlert({ title: "ERROR", body: err.message, type: "warning" })
            })
    }

    useEffect(() => {
        getBikeData()
        convertColumn(column, "detail")
        convertColumn(userHistoryColumn)
        convertPaymentHistoryColumn()
    }, [])

    return (
        <FlexColDiv
            gap="15px"
            flex="auto"
            margin="30px 0 0 0"
        >
            <PageHeader
                title="렌탈"
                subTitle="바이크 상세"
            />
            <CommonDiv padding="0 1rem 0 1rem">
                <BikeDetailHeader
                    bikeData={bikeData}
                    setBikeData={setBikeData}
                    changeBikeInfo={changeBikeInfo}
                    getUncontractUserList={getUncontractUserList}
                    getUncontractBikeList={getUncontractBikeList}
                    updateZentalStatus={updateZentalStatus}
                    cancelContract={cancelContract}
                />
                <Divider />

                {/* 계약 div */}
                <GridDiv
                    gap="50px"
                    padding="0 1rem 0 1rem"
                    gridCol="1fr 1fr"
                >
                    {/* <FlexColDiv
                        gap="15px"
                        flex="auto"
                        margin="30px 0 0 0"
                    > */}
                    <ContractForm
                        bikeData={bikeData}
                        paymentHistoryData={paymentHistoryData}
                        getZentalRider={getZentalRider}
                        rePayZental={rePayZental}
                        getBikeData={getBikeData}
                    />
                    {/* </FlexColDiv> */}
                    {/* 바이크 정보 */}
                    {/* <GridDiv padding="0 1rem 0 1rem"> */}
                    <FlexColDiv
                        gap="15px"
                        flex="auto"
                        margin="30px 0 0 0"
                    >
                        <BikeInfoForm
                            setModalOpen={setModalOpen}
                            bikeData={bikeData}
                            changePermit={changePermit}
                            cutFailCnt={cutFailCnt}
                        />
                        <CardForm
                            userCard={userCard}
                            getUserZtCardList={getUserZtCardList}
                            setModalOpen={setModalOpen}
                        />
                        <RiderInfoForm
                            setModalOpen={setModalOpen}
                            bikeData={bikeData}
                            resetPassAction={resetPassAction}
                        />
                    </FlexColDiv>
                </GridDiv>
                {/* 바이크 history div */}
                <FlexColDiv
                    gap="15px"
                    flex="auto"
                    margin="30px 0 0 0"
                >
                    <FlexBetween alignItems="center">
                        <h4>바이크 렌탈 히스토리</h4>
                    </FlexBetween>
                    <DetailContentWrap>
                        <DataTable
                            columns={column}
                            dataList={convertBikeHistory}
                            pagination
                        />
                    </DetailContentWrap>
                </FlexColDiv>
            </CommonDiv>

            {/* MODAL 모음 */}
            <Modal
                type="confirm"
                title="계약 설정"
                open={modalOpen.connectModalState}
                onClose={() => {
                    setModalOpen(prev => ({
                        ...prev,
                        connectModalState: false,
                    }))
                    setDefaultParam()
                }}
                onConfirm={() => {
                    connectService()
                    setModalOpen(prev => ({
                        ...prev,
                        connectModalState: false,
                    }))
                    setDefaultParam()
                }}
                footer={
                    <CommonDiv display="contents">
                        <Button
                            onClick={() => {
                                insertRider()
                            }}
                            type="primary"
                        >
                            라이더 추가
                        </Button>
                        <Button onClick={() => connectServiceValidation()}>저장</Button>
                    </CommonDiv>
                }
            >
                <ServiceConnectModal
                    bikeData={bikeData}
                    options={options}
                    setRiderSn={setRiderSn}
                />
            </Modal>
            <Modal
                type="confirm"
                title="서비스 양도"
                width="500px"
                open={modalOpen.assignmentModalState}
                onClose={() => {
                    setModalOpen(prev => ({
                        ...prev,
                        assignmentModalState: false,
                    }))
                }}
                footer={
                    <CommonDiv display="contents">
                        <Button
                            onClick={() => {
                                setModalOpen(prev => ({
                                    ...prev,
                                    assignmentModalState: false,
                                }))
                            }}
                            type="danger"
                        >
                            취소
                        </Button>
                        <Button onClick={() => assignmentBikeValidation()}>저장</Button>
                    </CommonDiv>
                }
            >
                <AssignmentModal
                    options={options}
                    bikeData={bikeData}
                    setTransfereeId={setTransfereeId}
                    setTransfereeDeposit={setTransfereeDeposit}
                />
            </Modal>
            <Modal
                type="confirm"
                title="바이크 변경"
                width="500px"
                open={modalOpen.bikeChangeModalState}
                onClose={() => {
                    setModalOpen(prev => ({
                        ...prev,
                        bikeChangeModalState: false,
                    }))
                }}
                footer={
                    <CommonDiv display="contents">
                        <Button
                            onClick={() => {
                                setModalOpen(prev => ({
                                    ...prev,
                                    bikeChangeModalState: false,
                                }))
                            }}
                            type="danger"
                        >
                            취소
                        </Button>
                        <Button onClick={() => changeSeriveBikeValidation()}>저장</Button>
                    </CommonDiv>
                }
            >
                <BikeChangeModal
                    options={options}
                    bikeData={bikeData}
                    setNewBikeId={setNewBikeId}
                    setBikeStatus={setBikeStatus}
                />
            </Modal>
            <Modal
                type="alert"
                title="서비스 이력"
                open={modalOpen.serviceHistoryState}
                onClose={() => {
                    setModalOpen(prev => ({
                        ...prev,
                        serviceHistoryState: false,
                    }))
                }}
            >
                <DataTable
                    pagination
                    columns={userHistoryColumn}
                    dataList={userHistoryData as object[]}
                />
            </Modal>
            <Modal
                type="alert"
                title="결제 이력"
                open={modalOpen.paymentHistoryState}
                onClose={() => {
                    setModalOpen(prev => ({
                        ...prev,
                        paymentHistoryState: false,
                    }))
                }}
            >
                <DataTable
                    pagination
                    columns={paymentHistoryColumn}
                    dataList={paymentHistoryData as object[]}
                />
            </Modal>
            <Modal
                type="confirm"
                title={modalOpen.confirmModalState.title}
                open={modalOpen.confirmModalState.isOpen}
                onClose={() => {
                    setModalOpen(prev => ({
                        ...prev,
                        confirmModalState: {
                            ...prev.confirmModalState,
                            isOpen: false,
                            title: "confirm modal",
                            contents: "confirm modal contents",
                            cb: () => {},
                        },
                    }))
                }}
                onConfirm={() => {
                    modalOpen.confirmModalState.cb()
                }}
            >
                {/* FIXME :: HTML로 조회? */}
                {modalOpen.confirmModalState.contents}
            </Modal>
            <Modal
                onClose={() =>
                    setModalOpen(prev => ({
                        ...prev,
                        cardMoalState: false,
                    }))
                }
                title="카드 변경"
                open={modalOpen.cardMoalState}
                onConfirm={() => {
                    checkCard()
                }}
                type="confirm"
            >
                <div>
                    <span>현재</span>
                    <Select
                        options={cardOption}
                        defaultValue={recentCard?.value}
                        readonly
                    />
                </div>
                <h2 style={{ textAlign: "center" }}>
                    <Icon.BottomArrow />
                </h2>
                <div>
                    <span>카드 변경</span>
                    <Select
                        options={cardOption}
                        defaultValue={cardOption[0]?.value}
                        onChange={(label: string, value: any) => {
                            setSelectedCard(value as string)
                        }}
                    />
                </div>
                <p style={{ textAlign: "center" }}>선택하신 카드로 변경 하시겠습니까?</p>
            </Modal>
            <Modal
                width={"600px"}
                onClose={() =>
                    setModalOpen(prev => ({
                        ...prev,
                        extendMoalState: false,
                    }))
                }
                title="연장"
                open={modalOpen.extendMoalState}
                type="confirm"
                footer={
                    <CommonDiv display="contents">
                        <Button
                            onClick={() => {
                                setModalOpen(prev => ({
                                    ...prev,
                                    extendMoalState: false,
                                }))
                            }}
                            size="small"
                            type="secondary"
                        >
                            취소
                        </Button>
                        <Button
                            onClick={() => extendFormValidation()}
                            size="small"
                            type="primary"
                        >
                            저장
                        </Button>
                    </CommonDiv>
                }
            >
                <ExtandForm bikeData={bikeData} />
            </Modal>
        </FlexColDiv>
    )
}

export default RentalBikeDetailPage

const BikeServiceInfoDefault = {
    ageOver: "",
    bikeId: "",
    bikeNo: "",
    birthDt: "",
    cancelDt: null,
    zentalId: 0,
    failCnt: 0,
    membershipUserId: 0,
    modelName: "",
    phone: "",
    purchaserSn: 0,
    riderEmail: "",
    riderNm: "",
    riderSn: "",
    serialNo: "",
    serviceEndDt: "",
    serviceStartDt: "",
    status: "active",
    userId: 0,
    paymentCuid: "",
    vinNo: "",
    deposit: "0",
    authPermitAt: "y",
    ztDiscountId: 0,
}

const userCardDefault = {
    customerUid: "",
    codeId: 0,
    userId: 0,
    cardHash: "",
    cardNumber: "",
    cardName: "",
    cvc: "",
    customerName: "",
    expiredDate: "",
    personalNumber: "",
    useFg: "",
    createdAt: "",
    updatedAt: "",
}
