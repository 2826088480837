import { FlexDiv, Text } from "@styles"
import styled from "styled-components"

export const Title = styled(Text)`
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    margin: 40px 0;
    margin-right: 1rem;
`

export const MemoIconWrapper = styled(FlexDiv)`
    border-radius: 8px;
    background: var(--light-greyscale-bg-darken, #f8f8f8);
`
export const WhiteSpaceWrap = styled.p`
    white-space: pre-wrap;
`
