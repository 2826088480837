import { axiosClearAuthHeader } from "@apis"
import { atom, selector } from "recoil"

const sessionStorage = typeof window !== "undefined" ? window.sessionStorage : undefined

export const loginState = atom<boolean>({
    key: "loginState",
    default: sessionStorage?.getItem("TOKEN") ? true : false,
})

export const tokenState = atom<string>({
    key: "tokenState",
    default: "",
})

export const logoutSelector = selector({
    key: "logoutSelector",
    get: ({ get }) => get(loginState),
    set: ({ set }, auth) => {
        if (!auth) {
            set(loginState, false)
            set(tokenState, "")
            axiosClearAuthHeader()
        }
    },
})

export const userState = atom<string>({
    key: "userState",
    default: "",
})
