import { ObjectType } from "@type/common"
interface ValidationField {
    [key: string]: ObjectType
}

export const validationField: ValidationField = {
    cardCancel: {
        type: "구분을 선택해 주세요",
        comment: "주문취소 사유를 입력해 주세요",
    },
    vBankCancel: {
        type: "구분을 선택 해 주세요",
        comment: "주문취소 사유를 입력해 주세요",
        refundHolder: "예금주명을 입력해 주세요",
        refundTel: "휴대폰 번호를 입력해 주세요",
        refundBank: "환불받을 계좌의 은행을 선택해 주세요",
        refundAccount: "환불받을 계좌번호를 입력해 주세요",
    },
    purchaserEdit: {
        userName: "이름을 입력해 주세요",
        userPhone: "휴대폰 번호를 입력해 주세요",
        userEmail: "이메일을 입력해 주세요",
        personalNumber: "생년월일/사업자번호를 입력해 주세요",
        addressRoad: "주소를 입력해 주세요",
    },
}
export const Order = {
    orderStep: [
        {
            label: "주문취소",
            value: 0,
        },
        {
            label: "주문취소 요청",
            value: 1,
        },
        {
            label: "예약 대기",
            value: 2,
        },
        {
            label: "예약 완료",
            value: 3,
        },
        {
            label: "보조금 신청",
            value: 4,
        },
        {
            label: "보조금 자격 확인",
            value: 5,
        },
        {
            label: "보조금 지원 확인",
            value: 6,
        },
        {
            label: "결제 대기",
            value: 7,
        },
        {
            label: "입금 대기",
            value: 8,
        },
        {
            label: "결제 완료",
            value: 9,
        },
        {
            label: "출고 준비",
            value: 10,
        },
        {
            label: "인도 완료",
            value: 11,
        },
        {
            label: "보조금 지급 신청",
            value: 12,
        },
        {
            label: "최종 완료",
            value: 13,
        },
    ],
    tableColumn: [
        {
            title: "주문상태",
            dataIndex: "codeTag",
        },
        {
            title: "이름",
            dataIndex: "userName",
        },
        {
            title: "휴대폰 번호",
            dataIndex: "userPhone",
        },

        {
            title: "이메일",
            dataIndex: "userEmail",
        },
        {
            title: "주문명",
            dataIndex: "productName",
        },
        {
            title: "주문일",
            dataIndex: "createdAt",
        },
        {
            title: "상세보기",
            dataIndex: "detail",
            // isFooterFixed: true,
        },
    ],
    stateList: [
        { label: "예약 완료", value: "예약 완료" },
        { label: "보조금 신청", value: "보조금 신청" },
        { label: "보조금 자격확인", value: "보조금 자격확인" },
        { label: "보조금 지원확인", value: "보조금 지원확인" },
        { label: "결제 대기", value: "결제 대기" },
        { label: "결제 완료", value: "결제 완료" },
        { label: "출고 준비", value: "출고 준비" },
        { label: "인도 완료", value: "인도 완료" },
        { label: "보조금 지급 신청", value: "보조금 지급 신청" },
        { label: "최종 완료", value: "최종 완료" },
        { label: "취소", value: "취소" },
    ],
    orderState: [
        { label: "신청 완료  → 계약 대기", value: "1" },
        { label: "계약 대기  → 보조금 대기", value: "2" },
        { label: "보조금 대기  → 결제 대기", value: "3" },
        { label: "결제 대기  → 결제 완료", value: "4" },
        { label: "결제 완료  → 출고 대기", value: "5" },
        { label: "출고 대기  → 인도 완료", value: "6" },
        { label: "취소", value: "7" },
    ],
    printOption: [
        { label: "주문정보 한줄에 상품정보를 여러줄로 풀어서 출력", value: "1" },
        { label: "아닌거", value: "2" },
    ],
    detailTableColumn: [
        {
            title: "모델명",
            dataIndex: "productName",
        },
        {
            title: "컬러",
            dataIndex: "optionName",
        },
        {
            title: "수량",
            dataIndex: "itemQuantity",
        },
        {
            title: "옵션",
            dataIndex: "optionList",
        },
        {
            title: "보조금",
            dataIndex: "subsidyList",
        },
        {
            title: "판매단가",
            dataIndex: "itemAmount",
        },
        {
            title: "합계",
            dataIndex: "total",
        },
        {
            title: "수정",
            dataIndex: "edit",
        },
    ],
    purchaseInfo: [
        {
            title: "결제일",
            dataIndex: "paidAt",
        },
        {
            title: "결제명",
            dataIndex: "name",
        },
        {
            title: "결제수단",
            dataIndex: "payMethod",
        },
        {
            title: "결제금액",
            dataIndex: "totalAmount",
        },
        {
            title: "결제상태",
            dataIndex: "status",
        },
    ],
    signTableColumn: [
        {
            title: "문서명",
            dataIndex: "docNm",
        },
        {
            title: "서명요청자",
            dataIndex: "requesterNm",
        },
        {
            title: "서명자",
            dataIndex: "signerNm",
        },
        {
            title: "서명 유효기간 만료일",
            dataIndex: "expirationDate",
        },

        {
            title: "서명 상태",
            dataIndex: "signStatus",
        },
    ],
    insertTableColumn: [
        {
            title: "모델명",
            dataIndex: "modelNm",
        },
        {
            title: "컬러",
            dataIndex: "color",
        },
        {
            title: "수량",
            dataIndex: "count",
        },
        {
            title: "옵션",
            dataIndex: "option",
        },

        {
            title: "판매단가",
            dataIndex: "cost",
        },
        {
            title: "할인",
            dataIndex: "discount",
        },
        {
            title: "합계",
            dataIndex: "total",
        },
        {
            title: "",
            dataIndex: "delete",
        },
    ],
    BANK_CODE: [
        { label: "기업", value: "003" },
        { label: "국민", value: "004" },
        { label: "외환", value: "005" },
        { label: "수협", value: "007" },
        { label: "농협", value: "011" },
        { label: "우리", value: "020" },
        { label: "SC제일", value: "023" },
        { label: "한국씨티", value: "027" },
        { label: "대구", value: "031" },
        { label: "부산", value: "032" },
        { label: "광주", value: "034" },
        { label: "전북", value: "037" },
        { label: "경남", value: "039" },
        { label: "새마을금고", value: "045" },
        { label: "우체국", value: "071" },
        { label: "하나", value: "081" },
        { label: "신한", value: "088" },
        { label: "케이뱅크", value: "089" },
        { label: "카카오뱅크", value: "090" },
        { label: "토스뱅크", value: "092" },
    ],
}
