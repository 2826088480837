import styled from "styled-components"

export const DropZone = styled.div<{ isDragging: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: 148px;
    border-radius: 8px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D7D7D7FF' stroke-width='4' stroke-dasharray='11%2c 12' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
    background-color: ${props => props.isDragging && "aliceblue"};
    p {
        font-size: 16px;
    }
    span {
        font-size: 18px;
        color: var(--primary);
    }
    button {
        background-color: transparent;
        border: none;
        font-weight: bold;
        cursor: pointer;
        color: var(--danger);
        font-size: 16px;
    }
`

export const FileDiv = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #f9f9f9;
    height: 56px;
    border-radius: 8px;
    padding: 4px;
    span {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background-color: #fff0e7;
        margin: 8px 16px 8px 16px;
        border-radius: 8px;
        color: #ff7433;
    }
    p {
        padding-right: 16px;
    }
`
export const ScrollDiv = styled.div`
    height: 200px;

    overflow-y: auto;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
    ::-webkit-scrollbar {
        display: none;
    }
`
