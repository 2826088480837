import React, { useEffect, useState } from "react"
import { CommonDiv, FlexColDiv, FlexDiv, Text } from "@styles"
import { Button, DataTable, Icon, Tag, Tooltip } from "p-ui"
import BatteryGraph from "@pages/Assets/Components/BatteryGraph"
import { ChartsDiv, Contents, Tab } from "../style"
import { ASSET_BIKE } from "@pages/Assets/constants"
import { useQueryClient } from "@tanstack/react-query"
import { useLocation } from "react-router"
import { ConfirmModalType } from "@pages/Assets/types"
import { formatYYYYMMDDhhmmss } from "@common/util"
import BatteryInfoGraph from "@pages/Assets/Components/BatteryInfoGraph"
import {
    useGetBikeFirmWareHistory,
    useGetBikeFirmWareVersion,
    useGetBikeStatus,
    useGetHardWareVersion,
    useGetHardwareVersionHistory,
    useUpdateFirmWare,
    useUpdateHardWare,
} from "src/queries/assets"
import FirmUpdateModal from "@pages/Assets/Components/FirmUpdateModal"
import { FirmwareParam, HardwareParam } from "@type/assetsTypes"
import useAlert from "@context/alert/useAlert"
import HardUpdateModal from "@pages/Assets/Components/HardUpdateModal"
import PopOverComponent from "@pages/Assets/Components/PopOver"
import { ASSETS_QUERY_KEY } from "@common/queryKey"
import CommonModal from "@pages/Assets/Components/CommonModal"

const TabMenu = () => {
    const [currentTab, clickTab] = useState(0)
    const [isFirmChecked, setIsFirmChecked] = useState(false)
    const [isHardChecked, setIsHardChecked] = useState(false)

    const [modalOpen, setModalOpen] = useState<ConfirmModalType>({
        isOpen: false,
        title: "confirm",
        contents: `<div>test</div>`,
        width: 800,
        onClose: () => {
            setModalOpen(prev => ({
                ...prev,
                isOpen: false,
                title: "confirm modal",
                contents: "confirm modal contents",
                cb: () => {},
            }))
            setIsFirmChecked(false)
            setIsHardChecked(false)
            clearParam()
        },
        cb: () => {},
    })

    const [firmwareInfo, setFirmwareInfo] = useState<FirmwareParam>({
        bikeSerial: "",
        edcuVersion: 0,
        icsVersion: 0,
        managerId: 0,
        mcuVersion: 0,
        updateDesc: "",
    })
    const [prevFirmwareInfo, setPrevFirmwareInfo] = useState<FirmwareParam>({
        bikeSerial: "",
        edcuVersion: 0,
        icsVersion: 0,
        managerId: -100,
        mcuVersion: 0,
        updateDesc: "",
    })

    const [hardwareInfo, setHardwareInfo] = useState<HardwareParam>({
        bikeSerial: "",
        edcuVersion: 0,
        managerId: 0,
        updateDesc: "",
    })
    const [prevHardwareInfo, setPrevHardwareInfo] = useState<HardwareParam>({
        bikeSerial: "",
        edcuVersion: 0,
        managerId: -100,
        updateDesc: "",
    })

    const location = useLocation()
    const serialNo = new URLSearchParams(location.search).get("serialNo")
    const { openAlert } = useAlert()
    const queryClient = useQueryClient()
    /**펌웨어 이력 */
    const { refetch: fwRefetch } = useGetBikeFirmWareHistory(serialNo as string)
    /**하드웨어 이력 */
    const { refetch: hwRefetch } = useGetHardwareVersionHistory(serialNo as string)
    const { data: bikeStatusData } = useGetBikeStatus(
        serialNo as string,
        currentTab == 0 || currentTab == 1 ? true : false
    )
    const { data: fwVersion } = useGetBikeFirmWareVersion(serialNo as string, currentTab == 2 ? true : false)
    const { data: hwVersion } = useGetHardWareVersion(serialNo as string, currentTab == 3 ? true : false)
    const { refetch: updateFirmVersion } = useUpdateFirmWare(firmwareInfo)
    const { refetch: updateHardVersion } = useUpdateHardWare(hardwareInfo)

    const checkedFirmValidation = () => {
        setIsFirmChecked(true)
    }
    const checkedHardValidation = () => {
        setIsHardChecked(true)
    }
    const selectMenuHandler = (value: number) => {
        clickTab(value)
    }

    const clearParam = () => {
        setHardwareInfo({
            bikeSerial: "",
            edcuVersion: 0,
            managerId: 0,
            updateDesc: "",
        })
        setFirmwareInfo({
            bikeSerial: "",
            edcuVersion: 0,
            icsVersion: 0,
            managerId: 0,
            mcuVersion: 0,
            updateDesc: "",
        })
        setPrevFirmwareInfo({
            bikeSerial: "",
            edcuVersion: 0,
            icsVersion: 0,
            managerId: -100,
            mcuVersion: 0,
            updateDesc: "",
        })
        setPrevHardwareInfo({
            bikeSerial: "",
            edcuVersion: 0,
            managerId: -100,
            updateDesc: "",
        })
    }
    const createVersionData = (data: any, title: string) => {
        return data
            ? [data].map(item => {
                  return {
                      ...item,
                      applyDt: item?.applyDt ? formatYYYYMMDDhhmmss(item?.applyDt) : "-",
                      updateDesc:
                          item.updateDesc?.length > 15 ? (
                              <Tooltip
                                  placement={"top"}
                                  content={item.updateDesc}
                              >
                                  <div style={{ cursor: "pointer" }}> {item.updateDesc.substr(0, 15) + "..."}</div>
                              </Tooltip>
                          ) : (
                              item.updateDesc
                          ),
                      update: item.serialNo && (
                          <FlexDiv justifyContent="center">
                              <Button
                                  compact
                                  type="primary"
                                  size="small"
                                  onClick={() => {
                                      setModalOpen(prev => ({
                                          ...prev,
                                          isOpen: true,
                                          width: 600,
                                          title: title,
                                          contents: title.includes("펌") ? (
                                              <FirmUpdateModal
                                                  firmwareInfo={firmwareInfo}
                                                  setFirmwareInfo={setFirmwareInfo}
                                                  setPrevFirmwareInfo={setPrevFirmwareInfo}
                                                  prevFirmwareInfo={prevFirmwareInfo}
                                              />
                                          ) : (
                                              <HardUpdateModal
                                                  hardwareInfo={hardwareInfo}
                                                  setHardwareInfo={setHardwareInfo}
                                                  setPrevHardwareInfo={setPrevHardwareInfo}
                                                  prevHardwareInfo={prevHardwareInfo}
                                              />
                                          ),
                                          cb: title.includes("펌") ? checkedFirmValidation : checkedHardValidation,
                                      }))
                                  }}
                              >
                                  <Icon.Edit
                                      width={14}
                                      height={14}
                                  ></Icon.Edit>
                              </Button>
                          </FlexDiv>
                      ),
                  }
              })
            : []
    }

    const fwVersionData = createVersionData(fwVersion, "펌웨어 업데이트")

    const hwVersionData = createVersionData(hwVersion, "하드웨어 업데이트")

    const updateFirmVirson = () => {
        updateFirmVersion().then(res => {
            if (res.status == "success") {
                openAlert({
                    title: "성공",
                    body: "펌웨어 업데이트 성공.",
                    type: "primary",
                })
                queryClient.invalidateQueries({ queryKey: [ASSETS_QUERY_KEY.GET_BIKE_FIRMWARE_VERSION, serialNo] })
                setModalOpen(prev => ({
                    ...prev,
                    isOpen: false,
                }))
                setIsFirmChecked(false)
                clearParam()
            } else {
                openAlert({
                    title: "실패",
                    body: "업데이트 실패.",
                    type: "warning",
                })
            }
        })
    }
    const updateHardVirson = () => {
        updateHardVersion().then(res => {
            if (res.status == "success") {
                openAlert({
                    title: "성공",
                    body: "하드웨어 업데이트 성공.",
                    type: "primary",
                })
                queryClient.invalidateQueries({ queryKey: [ASSETS_QUERY_KEY.GET_BIKE_HARDWARE_VERSION, serialNo] })

                setModalOpen(prev => ({
                    ...prev,
                    isOpen: false,
                }))
                setIsHardChecked(false)
                clearParam()
            } else {
                openAlert({
                    title: "실패",
                    body: "업데이트 실패.",
                    type: "warning",
                })
            }
        })
    }
    const TabMenuContnet = () => {
        switch (menuArr[currentTab].value) {
            case 0:
                return (
                    <FlexColDiv>
                        <FlexDiv
                            justifyContent="space-between"
                            alignItems="flex-end"
                        >
                            <FlexDiv
                                gap="20px"
                                height="400px"
                            >
                                <BatteryGraph
                                    batteryNo={1}
                                    serialNo={bikeStatusData?.bt1SerialNo}
                                    soc={bikeStatusData?.bt1Soc}
                                />
                                <BatteryGraph
                                    batteryNo={2}
                                    serialNo={bikeStatusData?.bt2SerialNo}
                                    soc={bikeStatusData?.bt2Soc}
                                />
                            </FlexDiv>
                            <ul>
                                <li>배터리 임계치</li>
                                <li>
                                    <Text color="#3AC922">■</Text> 51%~100%
                                </li>
                                <li>
                                    <Text color="#f6d523">■</Text> 21%~50%
                                </li>
                                <li>
                                    <Text color="#FF3030">■</Text> 0%~20%
                                </li>
                            </ul>
                        </FlexDiv>
                        <BatteryInfoGraph />
                    </FlexColDiv>
                )

            case 1:
                return (
                    <ChartsDiv>
                        <Text
                            margin="0 0 12px 0"
                            color="#5C5C5C"
                        >
                            누적 주행 거리
                        </Text>
                        <Text
                            bold
                            fontSize="18px"
                        >
                            {bikeStatusData?.totalMile?.toLocaleString()} Km
                        </Text>
                    </ChartsDiv>
                )
            case 2:
                return (
                    <>
                        <DataTable
                            columns={ASSET_BIKE.firmTabTableColumn}
                            dataList={fwVersionData ? fwVersionData : []}
                        />
                    </>
                )
            case 3:
                return (
                    <>
                        <DataTable
                            columns={ASSET_BIKE.hardTabTableColumn}
                            dataList={hwVersionData ? hwVersionData : []}
                        />
                    </>
                )
        }
    }

    const handleModalBtnClick = (currentTab: number) => {
        const fetchData = currentTab === 2 ? fwRefetch() : hwRefetch()
        fetchData.then((res: any) => {
            let prevState = ""
            let data = res.data
                .slice()
                .reverse()
                .map((item: any, i: number) => {
                    const newItem = {
                        ...item,
                        updateDesc: item.updateDesc?.length > 10 ? <PopOverComponent item={item} /> : item.updateDesc,
                    }
                    if (prevState) {
                        Object.keys(item).forEach((key: any) => {
                            if (
                                key !== "managerNm" &&
                                key !== "applyDt" &&
                                key !== "updateDesc" &&
                                prevState[key] !== item[key]
                            ) {
                                newItem[key] = (
                                    <FlexDiv
                                        gap="5px"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Tag
                                            type="primary"
                                            size="small"
                                            value={"NEW"}
                                        />
                                        <span>{item[key]}</span>
                                    </FlexDiv>
                                )
                            }
                        })
                    }
                    prevState = {
                        ...item,
                    }
                    return newItem
                })
            const reversedData = data.slice().reverse()
            setModalOpen(prev => ({
                ...prev,
                isOpen: true,
                width: 800,
                title: currentTab == 2 ? "바이크 펌웨어 변경 이력" : "바이크 하드웨어 변경 이력",
                contents: (
                    <div>
                        <h3>{currentTab == 2 ? "바이크 펌웨어 변경 이력" : "바이크 하드웨어 변경 이력"}</h3>
                        <DataTable
                            pageRow={5}
                            pagination
                            columns={
                                currentTab == 2 ? ASSET_BIKE.firmhistoryListColumn : ASSET_BIKE.hardHistoryListColumn
                            }
                            dataList={reversedData}
                        />
                    </div>
                ),
                cb: () =>
                    setModalOpen(prev => ({
                        ...prev,
                        isOpen: false,
                    })),
            }))
        })
    }
    function isContentAvailable() {
        if (currentTab <= 1 && bikeStatusData) return true
        if (currentTab === 2 && fwVersionData.length > 0) return true
        if (currentTab === 3 && hwVersionData.length > 0) return true
        return false
    }
    useEffect(() => {
        if (isFirmChecked) {
            if (
                (prevFirmwareInfo.edcuVersion === 0 &&
                    prevFirmwareInfo.icsVersion === 0 &&
                    prevFirmwareInfo.mcuVersion === 0) ||
                prevFirmwareInfo.managerId === -100
            ) {
                openAlert({
                    title: "확인",
                    body: "필수 입력값을 작성해 주세요.",
                    type: "warning",
                })
                setIsFirmChecked(false)
            } else {
                updateFirmVirson()
            }
        }
    }, [isFirmChecked])

    useEffect(() => {
        if (isHardChecked) {
            if (prevHardwareInfo.edcuVersion === 0 || prevHardwareInfo.managerId === -100) {
                openAlert({
                    title: "확인",
                    body: "필수 입력값을 작성해 주세요.",
                    type: "warning",
                })
                setIsHardChecked(false)
            } else {
                updateHardVirson()
            }
        }
    }, [isHardChecked])
    return (
        <>
            <Tab>
                {menuArr.map((el, index) => (
                    <li
                        key={index}
                        className={index === currentTab ? "submenu focused" : "submenu"}
                        onClick={() => selectMenuHandler(index)}
                    >
                        {el.name}
                    </li>
                ))}
            </Tab>

            {isContentAvailable() ? (
                <Contents>
                    <FlexColDiv
                        gap="20px"
                        margin="0 0 30px 0"
                    >
                        <FlexDiv
                            alignItems="center"
                            gap="8px"
                        ></FlexDiv>
                        <div>
                            <FlexDiv
                                alignItems="center"
                                gap="8px"
                            >
                                <Text
                                    display="block"
                                    margin="0 0 20px 0"
                                    bold
                                    fontSize="18px"
                                >
                                    {menuArr[currentTab].subName}
                                </Text>
                                {currentTab > 1 && (
                                    <CommonDiv margin="0 0 20px 0">
                                        <Button
                                            size="small"
                                            type="primary"
                                            onClick={() => handleModalBtnClick(currentTab)}
                                        >
                                            {currentTab == 2 ? "펌웨어 이력" : "하드웨어 이력"}
                                        </Button>
                                    </CommonDiv>
                                )}
                            </FlexDiv>
                            <Text fontSize="12px">
                                {currentTab <= 1
                                    ? bikeStatusData?.baseDt
                                        ? `${formatYYYYMMDDhhmmss(bikeStatusData?.baseDt)}기준`
                                        : "-"
                                    : ""}
                            </Text>
                        </div>
                    </FlexColDiv>
                    {TabMenuContnet()}
                </Contents>
            ) : (
                <Contents minHeigth="300px">
                    <FlexColDiv
                        alignItems="center"
                        justifyContent="center"
                        height="200px"
                    >
                        <Text
                            fontSize="30px"
                            textAlign="center"
                            color="#7c7b7b"
                        >
                            <Icon
                                icon={"EclipsExclam"}
                                width={50}
                                height={50}
                                fill="#7c7b7b"
                            />
                        </Text>
                        <Text
                            fontSize="18px"
                            textAlign="center"
                        >
                            {menuArr[currentTab].name} 가 없습니다. <br />
                            관리자에게 문의해주시길 바랍니다.
                        </Text>
                        {currentTab > 1 && (
                            <div style={{ margin: "10px 0 0 0" }}>
                                <Button
                                    onClick={() => {
                                        setModalOpen(prev => ({
                                            ...prev,
                                            isOpen: true,
                                            width: 600,
                                            title: menuArr[currentTab].name,
                                            contents: menuArr[currentTab].name.includes("펌") ? (
                                                <FirmUpdateModal
                                                    firmwareInfo={firmwareInfo}
                                                    setFirmwareInfo={setFirmwareInfo}
                                                    setPrevFirmwareInfo={setPrevFirmwareInfo}
                                                    prevFirmwareInfo={prevFirmwareInfo}
                                                />
                                            ) : (
                                                <HardUpdateModal
                                                    hardwareInfo={hardwareInfo}
                                                    setHardwareInfo={setHardwareInfo}
                                                    setPrevHardwareInfo={setPrevHardwareInfo}
                                                    prevHardwareInfo={prevHardwareInfo}
                                                />
                                            ),
                                            cb: menuArr[currentTab].name.includes("펌")
                                                ? checkedFirmValidation
                                                : checkedHardValidation,
                                        }))
                                    }}
                                    size="small"
                                    type="primary"
                                >
                                    업데이트하기
                                </Button>
                            </div>
                        )}
                    </FlexColDiv>
                </Contents>
            )}
            <CommonModal modalOpen={modalOpen} />
        </>
    )
}

export default TabMenu
const menuArr = [
    { name: "배터리 정보", value: 0, subName: "배터리 현황" },
    { name: "주행 정보", value: 1, subName: "주행 정보" },
    { name: "펌웨어 정보", value: 2, subName: "펌웨어 정보" },
    { name: "하드웨어 정보", value: 3, subName: "하드웨어 정보" },
]
