import React, { useRef, useState, useEffect } from "react"

import { useRecoilState } from "recoil"

import ReactDatePicker from "react-datepicker"
import { Editor, Viewer } from "@toast-ui/react-editor"
import { Button, Col, LabelForm, Row, Select } from "p-ui"
import { DataPickerInput } from "../style"
import { SettingDiv, TextAlignDiv, ViewerContainer } from "@styles"
import "@toast-ui/editor/dist/toastui-editor-viewer.css"
import "@toast-ui/editor/dist/i18n/ko-kr"
import "@toast-ui/editor/dist/toastui-editor.css"
import "tui-color-picker/dist/tui-color-picker.css"
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css"
import colorSyntax from "@toast-ui/editor-plugin-color-syntax"

import { ko } from "date-fns/esm/locale"
import { getOrgName, getManagerId, getManagerName } from "@common/util"
import useAlert from "@context/alert/useAlert"

import REQUEST_TASK from "src/apis/reqTask"
import { reviewParamState } from "src/store/workRequest"

import { task_status } from "../constants"

const DetailReviewForm = (props: any) => {
    const { manager, setManager, requestData, mode, setMode, getOneReqTask, managerIdList } = props
    const [startDate, setStartDate] = useState(new Date())

    const editorRef = useRef<Editor>(null)
    const orgName = getOrgName()
    const managerId = getManagerId()
    const managerNm = getManagerName()
    const { openAlert } = useAlert()

    const [reviewParam, setReviewParam] = useRecoilState(reviewParamState)
    const [htmlStr, setHtmlStr] = useState<string | any>("")

    const settingManager = async () => {
        let param = {
            ...requestData,
            reqId: requestData?.reqId,
            taskStatus: 2,
            taskManagerId: Number(managerId),
            taskOrgNm: orgName,
        }

        if (orgName !== "시스템개발") {
            return openAlert({ title: "실패", body: "권한이 없습니다", type: "warning" })
        } else {
            await REQUEST_TASK.updateReqTask(param).then(res => {
                if (res.status === "SUCCESS") {
                    setManager(true)
                    setMode("edit")
                    getOneReqTask()
                }
            })
        }
    }

    const setDate = (date: string | any) => {
        const year = new Date(date).getFullYear()
        const month = ("0" + (new Date(date).getMonth() + 1)).slice(-2)
        const day = ("0" + new Date(date).getDate()).slice(-2)
        const resultDate = `${year}-${month}-${day}`

        setStartDate(new Date(date))

        return resultDate
    }

    useEffect(() => {
        setReviewParam(prev => ({ ...prev, taskDesc: htmlStr }))
    }, [htmlStr])

    useEffect(() => {
        requestData?.taskDesc && setHtmlStr(requestData?.taskDesc)
        setReviewParam({
            ...reviewParam,
            taskStatus: 2,
            taskManagerId: Number(managerId),
            taskOrgNm: orgName as string,
            completionDt: requestData?.completionDt ? setDate(new Date(requestData.completionDt)) : setDate(startDate),
        })
    }, [requestData])

    useEffect(() => {
        const newDate = new Date().setDate(startDate.getDate() + 7)
        setStartDate(new Date(newDate))
    }, [])
    return (
        <Row
            gap={10}
            marginTop={30}
        >
            {manager ? (
                <>
                    <Col sm={6}>
                        <LabelForm
                            required
                            label="담당부서"
                        >
                            <Select
                                readonly
                                defaultValue={"시스템개발"}
                                options={[{ label: "시스템개발", value: "시스템개발" }]}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={6}>
                        <LabelForm
                            required
                            label="업무 담당자"
                        >
                            <Select
                                readonly={mode === "detail"}
                                name="taskManagerId"
                                options={managerIdList}
                                defaultValue={
                                    requestData.taskManager?.managerId ? requestData.taskManager?.managerId : managerId
                                }
                                onChange={(label: string, value: any) => {
                                    setReviewParam({ ...reviewParam, taskManagerId: value })
                                }}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={6}>
                        <LabelForm
                            required
                            label="업무 상태"
                        >
                            <Select
                                readonly={mode === "detail"}
                                name="taskStatus"
                                defaultValue={manager && requestData.taskStatus}
                                options={task_status}
                                onChange={(label: string, value: any) => {
                                    setReviewParam({ ...reviewParam, taskStatus: value })
                                }}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={6}>
                        <LabelForm
                            required
                            label="예상 완료 일자"
                        >
                            <ReactDatePicker
                                disabled={mode === "detail"}
                                selected={startDate}
                                onChange={(date: any) => {
                                    setReviewParam({ ...reviewParam, completionDt: setDate(date) })
                                }}
                                locale={ko} // 한글로 변경
                                selectsStart
                                dateFormat="yyyy.MM.dd (eee)" // 시간 포맷 변경
                                showPopperArrow={false} // 화살표 변경
                                minDate={new Date()}
                                customInput={<DataPickerInput />}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <LabelForm
                            required
                            label="검토의견"
                            labelCol={1.5}
                            formCol={10.5}
                        >
                            <TextAlignDiv align="left">
                                {mode === "edit" ? (
                                    <Editor
                                        ref={editorRef}
                                        initialValue={htmlStr}
                                        plugins={[colorSyntax]}
                                        previewStyle="vertical"
                                        height="300px"
                                        initialEditType="wysiwyg"
                                        useCommandShortcut={true}
                                        language="ko-KR"
                                        onChange={() => {
                                            setHtmlStr(editorRef.current?.getInstance().getHTML())
                                        }}
                                    />
                                ) : (
                                    <ViewerContainer>
                                        <div className="viewer-content">
                                            {htmlStr && <Viewer initialValue={htmlStr}></Viewer>}
                                        </div>
                                    </ViewerContainer>
                                )}
                            </TextAlignDiv>
                        </LabelForm>
                    </Col>
                </>
            ) : (
                <Col sm={12}>
                    <SettingDiv height="200px">
                        <Button
                            onClick={settingManager}
                            type="tertiary"
                        >
                            담당자 설정
                        </Button>
                    </SettingDiv>
                </Col>
            )}
        </Row>
    )
}

export default DetailReviewForm
