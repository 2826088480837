import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"

import { useRecoilState, useResetRecoilState } from "recoil"
import AppState from "@store"

import Divider from "@components/widgets/Divider"
import PageTitle from "@components/widgets/PageTitle"
import { Button, Col, Row } from "p-ui/dist/esm/components/widget"
import RegForm from "@pages/Issue/Components/RegForm"
import ToastEditor from "@pages/Issue/Components/ToastEditor"
import { WriteApiData, WriteData } from "@type/issue"
import { MainDashBoardBox, MainDashBoardTextBox } from "../styles"
import { FlexDiv } from "@styles"

import apis from "@apis"

const IssueUpsert = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const id = new URLSearchParams(location.search).get("id")
    const isInsert = id ? false : true
    const userID = "ACCOUNT"
    const [issueData, setIssueData] = useRecoilState(AppState.reg.paramIssueStateSelector)
    const [issueDesData, setIssueDesData] = useRecoilState(AppState.reg.paramIssueDesState)
    const [htmlStr, setHtmlStr] = useState<string>("")
    const [writeData, setWriteData] = useRecoilState(AppState.reg.writeDataState)
    const resetFormData = useResetRecoilState(AppState.reg.paramIssueStateSelector)
    const resetIssueData = useResetRecoilState(AppState.reg.paramIssueState)
    const resetIssueDesc = useResetRecoilState(AppState.reg.paramIssueDesState)
    const resetWriteData = useResetRecoilState(AppState.reg.writeDataState)

    function handleSubmit(event: any) {
        event.preventDefault()
    }

    async function onClick() {
        if (!issueData.title) return alert("제목을 작성해 주세요.")
        if (!issueData.issueType) return alert("상세 유형을 선택해주세요.")
        let insertParam = {
            issue: { ...issueData },
            issueDesc: { ...issueDesData },
        }
        const insertData = await apis.Iss.insert(insertParam)
        if (insertData.status == "SUCCESS") {
            isInsert ? alert("등록 되었습니다.") : alert("수정 되었습니다.")
            navigate("/iss/list")
        } else {
            isInsert ? alert("등록 실패") : alert("수정 실패")
        }
    }

    const getIssueData = async () => {
        const result = await apis.Iss.getData(Number(id))
        if (result) {
            setHtmlStr(result.resultData.issueDesc.contents)
            setIssueData(result.resultData.issue)
            setIssueDesData(result.resultData.issueDesc)
        }
    }

    const getWriteData = async () => {
        const writeData = await apis.Iss.getWriteData()
        if (writeData.status == "SUCCESS") {
            setWriteDataAction(writeData.resultData)
        }
    }
    const setWriteDataAction = (writeData: WriteApiData) => {
        let result: WriteData = {
            category: [],
            station: [],
            bike: [],
            battery: [],
        }
        result.category = writeData.category
        result.station = writeData.goods.filter((item: any) => {
            return item.value.charAt(0) == "S"
        })
        result.bike = writeData.goods.filter((item: any) => {
            return item.value.charAt(0) == "M"
        })
        result.battery = writeData.goods.filter((item: any) => {
            if (item.value.charAt(0) == "B" && item.value.charAt(9) == "M") {
                item.label = item.label + "/S"
                return true
            }
            return false
        })

        setWriteData(result)
    }

    useEffect(() => {
        setIssueDesData({ ...issueDesData, contents: htmlStr, updatedBy: userID })
    }, [htmlStr])

    useEffect(() => {
        if (id) {
            getIssueData()
        }
    }, [id])
    useEffect(() => {
        getWriteData()
        resetFormData()
        return () => {
            resetIssueData()
            resetIssueDesc()
            resetWriteData()
            setHtmlStr("")
        }
    }, [])
    return (
        <>
            <div>
                <PageTitle
                    topMenu="이슈"
                    subMenu={id ? "수정" : "등록"}
                />
            </div>
            <Row marginBottom={"2rem"}>
                <Col sm={12}>
                    <form onSubmit={handleSubmit}>
                        <MainDashBoardBox>
                            <RegForm isInsert={isInsert} />
                        </MainDashBoardBox>
                    </form>
                </Col>
                <Col sm={12}>
                    <Divider />
                </Col>
                <Col sm={12}>
                    <MainDashBoardTextBox>
                        <ToastEditor
                            htmlStr={htmlStr}
                            setHtmlStr={setHtmlStr}
                            isViewer={false}
                            isInsert={isInsert}
                        />
                    </MainDashBoardTextBox>
                </Col>
                <FlexDiv
                    width="100%"
                    justifyContent="flex-end"
                    gap="10px"
                    margin="1rem 0 0 0"
                >
                    <Button
                        onClick={() =>
                            id ? navigate({ pathname: "/iss/detail", search: "?id=" + id }) : navigate("/iss/list")
                        }
                        type="danger"
                    >
                        취소
                    </Button>
                    {id ? (
                        <Button
                            onClick={onClick}
                            type="primary"
                        >
                            수정
                        </Button>
                    ) : (
                        <Button
                            onClick={onClick}
                            type="primary"
                        >
                            등록
                        </Button>
                    )}
                </FlexDiv>
            </Row>
        </>
    )
}

export default IssueUpsert
