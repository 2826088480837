import { ASSETS_HARD, ASSET_BIKE } from "@pages/Assets/constants"
import { FlexDiv, Label } from "@styles"
import { Button, Col, DataTable, Icon, Input, PageHeader, Row, Select } from "p-ui"
import { useNavigate } from "react-router"

import React, { useEffect, useMemo, useState } from "react"
import { useGetHardList } from "src/queries/firmhard"
import { onChangeParam } from "p-ui/dist/esm/types/input"

const HardwareList = () => {
    const navigate = useNavigate()
    const { data: hardwareListData, isFetching, isError, error } = useGetHardList()
    const [isHardwareSearch, setIsHardwareSearch] = useState<boolean>(false)
    const [bikeList, setBikeList] = useState<any[]>([])

    const [searchText, setSearchText] = useState<string>("")
    const [filterText, setFilterText] = useState<string>("")
    const [hardwareType, setHardwareType] = useState<string>("")

    const changeTypeName = (type: string) => {
        switch (type) {
            case "BIKE_HW":
                return "바이크"
            case "STATION_HW":
                return "스테이션"
            case "BATTERY_HW":
                return "배터리"
        }
    }
    const convertList = (data: any[]) => {
        return data?.map((item: any) => {
            return {
                ...item,
                releaseDt: item.releaseDt.replace(/\.\d+$/, ""),
                deviceType: changeTypeName(item.deviceType),
                detail: (
                    <Button
                        size="small"
                        type="tertiary"
                        onClick={() => {
                            navigate({
                                pathname: "/lab/harddetail",
                                search: "?type=" + item.hardwareType + "&device=" + item.deviceType,
                            })
                        }}
                    >
                        상세보기
                    </Button>
                ),
            }
        })
    }
    const filterData = useMemo(() => {
        let filteredResults = bikeList

        if (hardwareType !== "") {
            filteredResults = filteredResults?.filter((item: any) => {
                return hardwareType ? hardwareType?.toLowerCase()?.includes(item.hardwareType.toLowerCase()) : true
            })
        }

        // 검색 필터
        if (filterText !== "") {
            filteredResults = filteredResults?.filter((item: any) =>
                JSON.stringify(item)?.toLowerCase().includes(filterText?.toLowerCase().split(" ").join(""))
            )
        }

        // 다른 필터

        return filteredResults
    }, [bikeList, filterText, hardwareType, isHardwareSearch])

    const handleFetchResult = (
        fetching: boolean,
        isError: boolean,
        error: any,
        dataList: any[],
        setter: (data: any[]) => void
    ) => {
        /**실패시 */
        if (!fetching && isError) {
            console.log(error)
        }
        /**성공시 */
        if (!fetching && !isError) {
            setter(convertList(dataList as any[]))
        }
    }
    const filterClear = () => {
        setSearchText("")
        setFilterText("")
        setHardwareType("")
    }
    useEffect(() => {
        handleFetchResult(isFetching, isError, error, hardwareListData, setBikeList)
    }, [isFetching, isError, error, hardwareListData])
    return (
        <div>
            <PageHeader
                title="자산관리"
                subTitle="하드웨어 목록"
            />

            <Row gap={20}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv
                        gap="10px"
                        alignItems="center"
                    >
                        <Input
                            size="small"
                            width={300}
                            value={searchText}
                            onChange={(e: onChangeParam) => {
                                setSearchText(e.value.toString())
                            }}
                            onSearch={() => {
                                setFilterText(searchText)
                            }}
                        />
                        <Button onClick={() => filterClear()}>초기화</Button>
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>하드웨어</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv
                        gap="10px"
                        alignItems="center"
                    >
                        <Select
                            width={200}
                            size="small"
                            onChange={(label, value) => setHardwareType(value.toString())}
                            defaultValue={hardwareType}
                            options={ASSET_BIKE.hardType}
                        />
                        <Button
                            compact
                            size="small"
                            type="primary"
                        >
                            <Icon.Search />
                        </Button>
                    </FlexDiv>
                </Col>
            </Row>
            <DataTable
                pagination
                columns={ASSETS_HARD.hardListTable}
                dataList={filterData}
            />
        </div>
    )
}

export default HardwareList
