import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"

import { useSetRecoilState } from "recoil"
import AppState from "@store"

import { Button, Input } from "p-ui"
import { CommonDiv, FlexColDiv } from "@styles"

import apis, { axiosApplyConfig } from "@apis"

import useAlert from "@context/alert/useAlert"

import { onChangeParam } from "@type/common"
import { LoginFormData } from "@type/formType"

const enterKeys = ["NumpadEnter", "Enter"]

const Login = () => {
    const [loginForm, setLoginFormData] = useState<LoginFormData>({ account: "", password: "" })
    const setLoginState = useSetRecoilState<boolean>(AppState.auth.loginState)
    const setToken = useSetRecoilState<string>(AppState.auth.tokenState)
    const setUserState = useSetRecoilState<string>(AppState.auth.userState)
    const navigate = useNavigate()
    const { state } = useLocation()
    const { openAlert } = useAlert()

    const validation = () => {
        switch ("") {
            case loginForm.account:
                openAlert({ body: "아이디는 비워 둘 수 없습니다.", type: "warning" })
                break
            case loginForm.password:
                openAlert({ body: "비밀번호는 비워 둘 수 없습니다.", type: "warning" })
                break
            default:
                signIn()
                break
        }
    }
    const signIn = async () => {
        await apis.Auth.login(loginForm).then(res => {
            if (res.status == "SUCCESS") {
                const { accessToken, refreshToken, manager, menuList } = res.resultData
                setToken(accessToken)
                setLoginState(true)
                setUserState(manager.account)
                axiosApplyConfig(accessToken)
                sessionStorage.setItem("TOKEN", accessToken)
                sessionStorage.setItem("REFRESHTOKEN", refreshToken)
                sessionStorage.setItem("ACCOUNT", manager.account)
                sessionStorage.setItem("CONFIRMMANAGER", manager.confirmManger)
                sessionStorage.setItem("MANAGERID", manager.managerId)
                sessionStorage.setItem("MANAGERNAME", manager.managerNm)
                sessionStorage.setItem("ORGNAME", manager.orgNm)
                sessionStorage.setItem("POSITIONNAME", manager.positionNm)
                sessionStorage.setItem("MENU_LIST", JSON.stringify(menuList))

                // TODO CHANGE : 권한 별 인덱스 설정
                if (state?.pathname !== "/") {
                    if (state?.search) {
                        navigate(`${state.pathname}${state.search}`)
                    } else {
                        navigate(state?.pathname)
                    }
                } else {
                    navigate("/station")
                }
            } else {
                openAlert({
                    body: "계정 정보가 틀렸습니다. 다시 한번 확인 부탁드립니다.",
                    type: "warning",
                })
            }
        })
    }
    const onChange = (e: any) => {
        const { name, value } = e
        setLoginFormData({ ...loginForm, [name]: value })
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        let session = params.get("session")
        if (session == "expire") {
            openAlert({ body: "세션이 만료되었습니다. 다시 로그인 부탁드립니다.", type: "warning" })
        }
        if (sessionStorage.getItem("TOKEN")) {
            navigate("/station")
        }
    }, [])

    return (
        <CommonDiv height="100%">
            <FlexColDiv
                gap="15px"
                justifyContent="center"
                alignItems="center"
                height="100%"
            >
                <FlexColDiv gap="10px">
                    <Input
                        onChange={(e: onChangeParam) => onChange(e)}
                        placeholder="ID"
                        name="account"
                        width="250px"
                        height="35px"
                        onKeyUp={(e: any) => {
                            if (enterKeys.includes(e.code)) {
                                validation()
                            }
                        }}
                    />
                    <Input
                        onChange={(e: onChangeParam) => onChange(e)}
                        onKeyUp={(e: any) => {
                            if (enterKeys.includes(e.code)) {
                                validation()
                            }
                        }}
                        type="password"
                        placeholder="password"
                        name="password"
                        width="250px"
                        height="35px"
                    />
                </FlexColDiv>
                <Button
                    type="primary"
                    onClick={validation}
                >
                    Sign In
                </Button>
            </FlexColDiv>
        </CommonDiv>
    )
}

export default Login
