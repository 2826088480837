export const ASSETS_QUERY_KEY = {
    GET_BIKE_LIST: "get-assets-bike-list",
    GET_BIKE_ONE: "get-assets-bike-one",
    GET_BIKE_STATUS: "get-assets-bike-status",
    GET_BIKE_FIRMWARE_VERSION: "get-assets-bike-firmware-version",
    GET_BIKE_FIRMWARE_HISTORY: "get-assets-bike-firmware-history",
    GET_BIKE_HARDWARE_VERSION: "get-assets-bike-hardware-version",
    GET_BIKE_HARDWARE_HISTORY: "get-assets-bike-hardware-history",
    GET_BIKE_MEMO: "get-assets-bike-memo",
    GET_FAVORITE_LIST: "get-assets-favorite-list",
    DELETE_FAVORITE_LIST: "delete-assets-favorite-list",
    POST_FAVORITE_LIST: "post-assets-favorite-list",
    UPDATE_FIRMWARE: "update-assets-firmware",
    UPDATE_HARDMWARE: "update-assets-hardware",
    UPDATE_BIKE_MEMO: "update-assets-bike-memo",
    UPDATE_BIKE_INFO: "update-assets-bike-info",
    GET_OPENAPI_INFO: "get-assets-openapi-info",

    GET_APPLY_FIRM_BIKE: "get-assets-apply-firm-bike",
    GET_APPLY_HARD_BIKE: "get-assets-apply-hard-bike",
}
export const FW_QUERY_KEY = {
    GET_ALL_LIST: "get-firm-all-list",
    GET_LIST: "get-firm-list",
    GET_HISTORY: "get-firm-history",
    UPSERT_VERSION: "upsert-firm-version",
    DELETE_VERSION: "delete-firm-version",
}
export const HW_QUERY_KEY = {
    GET_ALL_LIST: "get-hard-all-list",
    GET_LIST: "get-hard-list",
    GET_HISTORY: "get-hard-history",
    UPSERT_VERSION: "upsert-hard-version",
    DELETE_VERSION: "delete-hard-version",
}
export const MANAGER_QUERY_KEY = {
    GET_LIST: "get-manager-list",
}
