import React, { useEffect, useMemo, useRef, useState } from "react"

import CodeGenerator from "../Components/CodeGenerator"
import CodeForm from "../Components/CodeForm"
import Loader from "@components/widgets/Loader"
import { Select, Input, Button, Modal, DataTable, PageHeader, Col, Row, CheckBox } from "p-ui"
import { FlexBetween, FlexColDiv, FlexDiv, Label, Text } from "@styles"

import apis from "@apis"

import { utils, writeFile } from "xlsx"
import useAlert from "@context/alert/useAlert"

import { ASCodes } from "@common/constatns"
import { Codes, ParentCodes } from "@type/codeType"
import { Type } from "p-ui"
import { useSearchParams } from "react-router-dom"
import { useLocation, useNavigate } from "react-router"
import { observPage, updateActivePage } from "@common/util"
import { PageRow } from "p-ui/dist/esm/types"
import { onChangeFilter } from "src/utils/filter"
interface updateModal {
    code: Codes
    isOpen: boolean
}

const initCodeValue = { codeId: 0, parentCode: "", parentLabel: "", code: "", codeLabel: "", label: "" }

const ASCode = () => {
    const tableRef = useRef<HTMLTableElement | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()

    const pageParam = searchParams.get("page")
    const searchTextParam = searchParams?.get("searchText")
    const typeParam = searchParams.get("type")
    const limitParam = searchParams.get("limit")

    const [nowPage, setNowPage] = useState<any>(pageParam !== null ? pageParam : 1)
    const [parentOption, setParentOption] = useState<Type.OptionGroupType[]>([{ label: "전체", value: "ALL" }])
    const [parentOriginOption, setParentOriginOption] = useState<Type.OptionGroupType[]>([])

    const [keyword, setKeyword] = useState<string>("")
    const [searchText, setSearchText] = useState<string>("")

    const [originList, setOriginList] = useState([] as any)

    const [pageRows, setPageRows] = useState<PageRow>(15)

    const [clickedTypeFilter, setClickedTypeFilter] = useState(typeParam ? typeParam.split(",") : [])

    const [openGenerateModal, setOpenGenerateModal] = useState<boolean>(false)
    const [openAddModal, setOpenAddModal] = useState<updateModal>({ code: initCodeValue, isOpen: false })
    const [openUpdateModal, setOpenUpdateModal] = useState<updateModal>({ code: initCodeValue, isOpen: false })
    const [openDeleteModal, setOpenDeleteModal] = useState<updateModal>({ code: initCodeValue, isOpen: false })

    const submitButton = useRef<HTMLButtonElement>(null)
    const location = useLocation()

    const { openAlert } = useAlert()

    /**
     * Excel Download
     * @returns Excel File
     */
    const downloadExcel = () => {
        const wb = utils.book_new()
        // 열 순서를 sheet화
        const ws = utils.json_to_sheet([])
        // ws에 excelTableHead를 추가
        // any가 아니면 키 삭제가 안됨
        const excelDataList = originList.map((item: any) => {
            delete item["codeId"]
            delete item["parentCode"]
            delete item["label"]
            delete item["action"]
            return item
        })
        utils.sheet_add_json(ws, excelDataList)
        ws["!cols"] = [{ wch: 10 }, { wch: 5 }, { wch: 100 }]
        ws["A1"] = { t: "", v: "분류" }
        ws["B1"] = { t: "", v: "코드" }
        ws["C1"] = { t: "", v: "내용" }

        utils.book_append_sheet(wb, ws, "Report")

        // Set Time
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()

        const currentDate = `${year}-${month}-${day}`

        // Set File Name
        writeFile(wb, `ASCode${currentDate}.xlsx`)
        return "TEST"
    }

    const getCodeList = async () => {
        await apis.Code.getASCodeList().then(res => {
            if (res?.status == "SUCCESS") {
                const asCodeList = res.resultData.map((item: any) => {
                    return {
                        ...item,
                        action: (
                            <FlexDiv
                                gap="5px"
                                justifyContent="center"
                            >
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => setOpenUpdateModal({ ...openUpdateModal, code: item, isOpen: true })}
                                >
                                    수정
                                </Button>
                                <Button
                                    type="danger"
                                    size="small"
                                    onClick={() => setOpenDeleteModal({ ...openDeleteModal, code: item, isOpen: true })}
                                >
                                    숨김
                                </Button>
                            </FlexDiv>
                        ),
                    }
                })
                setOriginList([...asCodeList])
            }
        })
        await apis.Code.getParentCodeList().then(res => {
            if (res.status == "SUCCESS") {
                let options: Type.OptionGroupType[] = []
                res.resultData.map((item: ParentCodes, i: any) => {
                    options.push({ label: item.label, value: item.parentCode.split("_")[1] })
                })

                const newOption = [...options]
                newOption.unshift({ label: "전체", value: "ALL" })

                setParentOption(newOption)
                setParentOriginOption(options)
            }
        })
    }

    const filterData = useMemo(() => {
        let filteredResults = originList
        if (keyword !== "") {
            filteredResults = filteredResults.filter((item: any) => JSON.stringify(item).includes(keyword))
        }
        if (!clickedTypeFilter.includes("ALL")) {
            filteredResults = filteredResults.filter((item: any) =>
                clickedTypeFilter.includes(item.parentCode.split("_")[1])
            )
        }

        if (clickedTypeFilter.length === 0) {
            filteredResults = []
        }

        return filteredResults
    }, [originList, clickedTypeFilter, searchText])

    const filter = () => {
        if (location.search.length === 0) {
            setClickedTypeFilter(["ALL", "FUNNEL", "SYMPTOM", "CHECK", "CAUSE", "PROCESS", "OPTION"])
            setSearchParams({
                searchText: searchText,
                type: clickedTypeFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        } else {
            setSearchParams({
                searchText: searchText,
                type: clickedTypeFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        }
    }

    /**
     * trigger Click Button for Submit
     */
    const clickTrigger = () => {
        if (submitButton.current) {
            submitButton.current.click()
        }
    }

    /**
     * submit 후 api 전송
     * @param newCode
     */
    const handleSubmit = (newCode: Codes, type: string) => {
        type == "update" ? updateApi(newCode) : insertApi(newCode)
    }

    /**
     *  코드 수정
     * @param newCode Codes
     */
    const updateApi = async (newCode: Codes) => {
        await apis.Code.updateASCode(newCode).then(res => {
            res.status == "SUCCESS"
                ? afterResponseAction("수정 완료!", "primary")
                : afterResponseAction("실패! 이미 등록된 코드 입니다. 관리자에게 문의해주세요", "warning")
        })
    }

    /**
     * 코드 추가
     * @param newCode Codes
     */
    const insertApi = async (newCode: Codes) => {
        await apis.Code.insertASCode(newCode).then(res => {
            res.status == "SUCCESS"
                ? afterResponseAction("저장 완료!", "primary")
                : afterResponseAction("실패! 이미 등록된 코드 입니다. 관리자에게 문의해주세요", "warning")
        })
    }

    /**
     * 코드 삭제
     * @param codeId number
     */
    const deleteCode = async (codeId: number) => {
        setOpenDeleteModal({ ...openDeleteModal, isOpen: false })
        await apis.Code.deleteASCode(codeId).then(res => {
            if (res.status == "SUCCESS") afterResponseAction("숨김 완료", "warning")
        })
    }

    /**
     * apia 성공 후 액션
     * @param msg stirng
     * @param type AlertType
     */
    const afterResponseAction = (msg: string, type: Type.AlertType) => {
        openAlert({ body: msg as string, type: type })
        getCodeList()
    }

    const handleClick = () => {
        setSearchText("")
        setKeyword("")
        setNowPage(1)
        setClickedTypeFilter(defaultStatusFilter.concat("ALL"))
    }

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)
            if (newActivePage === 0) {
                setNowPage(Number(pageParam))
                return
            }
            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])
    /**
     * 초기 실행
     */

    useEffect(() => {
        getCodeList()
        if (limitParam) {
            setPageRows(Number(limitParam) as unknown as PageRow)
        }
        if (searchTextParam) {
            setKeyword(searchTextParam)
            setSearchText(searchTextParam)
        }
    }, [])

    useEffect(() => {
        filter()
    }, [originList, nowPage, pageRows, clickedTypeFilter])

    return originList ? (
        <>
            {/* PAGE TITLE */}
            <PageHeader
                title="AS 코드"
                subTitle="목록"
            />
            <Row gap={"20px"}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <FlexBetween>
                        <FlexDiv
                            gap="5px"
                            justifyContent="flex-start"
                        >
                            <Input
                                size="small"
                                width="300px"
                                placeholder="Search..."
                                value={keyword}
                                onChange={(e: any) => setKeyword(e.value)}
                                onSearch={() => {
                                    setNowPage(1)
                                    setSearchText(keyword)
                                    filter()
                                }}
                                onKeyUp={(e: any) => {
                                    if (e.key == "Enter") {
                                        setNowPage(1)
                                        setSearchText(keyword)
                                        filter()
                                    }
                                }}
                            />
                            <Button
                                size="small"
                                type="default"
                                onClick={handleClick}
                            >
                                초기화
                            </Button>
                        </FlexDiv>
                        <FlexDiv
                            gap="5px"
                            justifyContent="flex-end"
                        >
                            <Button
                                type="default"
                                size="small"
                                onClick={() => downloadExcel()}
                            >
                                엑셀
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => setOpenGenerateModal(true)}
                            >
                                코드 생성
                            </Button>
                            <Button
                                type="secondary"
                                size="small"
                                onClick={() => setOpenAddModal({ ...openAddModal, isOpen: true })}
                            >
                                코드 추가
                            </Button>
                        </FlexDiv>
                    </FlexBetween>
                </Col>
                <Col sm={1}>
                    <Label>분류</Label>
                </Col>
                <Col md={11}>
                    <FlexDiv
                        alignItems="center"
                        gap="15px"
                    >
                        <CheckBox.Group
                            value={clickedTypeFilter}
                            options={parentOption}
                            onChange={(value: any) => {
                                const param = {
                                    clickedFilter: clickedTypeFilter,
                                    checkList: value as [],
                                    defaultFilter: defaultStatusFilter,
                                    setClickedFilter: setClickedTypeFilter,
                                    allValue: "ALL",
                                }
                                setNowPage(1)
                                onChangeFilter(param)
                            }}
                        />
                    </FlexDiv>
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    columns={ASCodes.tableColumn}
                    dataList={filterData}
                    nowPage={Number(pageParam)}
                    numbering
                    pagination={true}
                    pageRow={pageRows}
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(limitParam) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                />
            </div>
            {/* Modal */}
            {/* 코드 생성 */}
            <Modal
                title="코드 생성"
                open={openGenerateModal}
                onClose={() => setOpenGenerateModal(false)}
                type={"alert"}
                width={700}
                scrollable
            >
                <CodeGenerator
                    category={parentOption}
                    codeList={originList}
                />
            </Modal>
            {/* 코드 등록 */}
            <Modal
                title="코드 등록"
                open={openAddModal.isOpen}
                onClose={() => setOpenAddModal({ ...openAddModal, isOpen: false })}
                type={"confirm"}
                confirmText={"저장"}
                onConfirm={() => clickTrigger()}
                width={500}
            >
                <CodeForm
                    code={openAddModal.code}
                    handleSubmit={handleSubmit}
                    parentOption={parentOriginOption}
                    submitButton={submitButton}
                    type="insert"
                />
            </Modal>
            {/* 코드 수정 */}
            <Modal
                title="코드 수정"
                open={openUpdateModal.isOpen}
                onClose={() => setOpenUpdateModal({ ...openUpdateModal, isOpen: false })}
                type={"confirm"}
                confirmText={"수정"}
                onConfirm={() => clickTrigger()}
                width={500}
            >
                <CodeForm
                    code={openUpdateModal.code}
                    handleSubmit={handleSubmit}
                    parentOption={parentOriginOption}
                    submitButton={submitButton}
                    type="update"
                />
            </Modal>
            {/* 코드 삭제 */}
            <Modal
                title="Confirm"
                open={openDeleteModal.isOpen}
                onClose={() => setOpenDeleteModal({ ...openDeleteModal, isOpen: false })}
                onConfirm={() => deleteCode(openDeleteModal.code.codeId)}
                type={"confirm"}
                confirmText={"삭제"}
            >
                <FlexColDiv>
                    <Text
                        fontSize="1.3rem"
                        margin="0 0 30px 0"
                        textAlign="center"
                        color="red"
                    >
                        코드를 삭제하시겠습니까?
                    </Text>
                    <Text
                        fontSize="1.1rem"
                        margin="0 0 10px 0"
                        textAlign="center"
                    >
                        <b> 상위코드 </b> {openDeleteModal.code.parentLabel}
                    </Text>
                    <Text
                        fontSize="1.1rem"
                        margin="0 0 10px 0"
                        textAlign="center"
                    >
                        <b>코드</b> {openDeleteModal.code.code}
                    </Text>
                    <Text
                        fontSize="1.1rem"
                        margin="0 0 10px 0"
                        textAlign="center"
                    >
                        <b>라벨 </b>: {openDeleteModal.code.codeLabel}
                    </Text>
                </FlexColDiv>
            </Modal>
        </>
    ) : (
        <Loader />
    )
}

export default ASCode

const defaultStatusFilter = ["FUNNEL", "SYMPTOM", "CHECK", "CAUSE", "PROCESS", "OPTION"]
