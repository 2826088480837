import { Agency, AgencyOne, Result } from "@type/responseType"
import axios from "./config"
import { AGENCY, PURCHASER } from "./urls"

export default {
    /**
     * Request album list
     * @param {Object} params : Search conditions
     * @returns {Array}
     */
    async getAgencyList(): Promise<Result<Agency>> {
        try {
            return await axios.agency.get(AGENCY.GET)
        } catch (e: any) {
            return e.message
        }
    },
    async getAgencyOne(agencyId: number): Promise<Result<AgencyOne>> {
        try {
            return await axios.agency.get(AGENCY.GET_ONE + agencyId)
        } catch (e: any) {
            return e.message
        }
    },
    async insertAgency(agencyData: any) {
        try {
            return await axios.agency.post(AGENCY.INSERT, agencyData)
        } catch (e: any) {
            return e.message
        }
    },
    async updateAgency(agencyData: any) {
        try {
            return await axios.agency.put(AGENCY.UPDATE, agencyData)
        } catch (e: any) {
            return e.message
        }
    },
    async deleteAgency(agencyData: any) {
        try {
            return await axios.agency.delete(AGENCY.DELETE + agencyData)
        } catch (e: any) {
            return e.message
        }
    },
    async syncBike(agencyId: number) {
        try {
            return await axios.agency.post(AGENCY.SYNC + agencyId)
        } catch (e: any) {
            return e.message
        }
    },
    async resetPassword(agencyId: number) {
        try {
            return await axios.agency.put(AGENCY.RESET + agencyId)
        } catch (e: any) {
            return e.message
        }
    },

    async getPurchaser() {
        try {
            return await axios.agency.get(PURCHASER.GET)
        } catch (e: any) {
            return e.message
        }
    },
}
