import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ASSET_BIKE } from "@pages/Assets/constants"
import { ConfirmModalType, QueryParams, SearchParamState } from "@pages/Assets/types"
import { FlexDiv, Label, Text } from "@styles"
import { Button, Col, DataTable, Icon, Input, PageHeader, Row, Select, Tag, Tooltip } from "p-ui"
import { useSearchParams } from "react-router-dom"
import {
    useGetAllBikeList,
    useGetBikeFirmWareHistory,
    useGetHardwareVersionHistory,
    useUpdateFirmWare,
    useUpdateHardWare,
} from "src/queries/assets"
import { useGetAllFirmList, useGetAllHardList } from "src/queries/firmhard"
import { AssetAllBike, FirmwareListALL } from "@type/responseType"
import { formatYYYYMMDDhhmm, getManagerId, observPage, updateActivePage } from "@common/util"
import { FirmwareParam, HardwareParam } from "@type/assetsTypes"
import useAlert from "@context/alert/useAlert"
import { useQueryClient } from "@tanstack/react-query"
import { PageRow } from "p-ui/dist/esm/types"
import { onChangeParam } from "p-ui/dist/esm/types/input"
import PopOverComponent from "@pages/Assets/Components/PopOver"
import { ASSETS_QUERY_KEY } from "@common/queryKey"
import CommonModal from "@pages/Assets/Components/CommonModal"
import FirmHardUpdateModal from "@pages/Assets/Components/FirmHardUpdateModal"

const BikeList = () => {
    const managerId = getManagerId()
    const { openAlert } = useAlert()
    const queryClient = useQueryClient()
    const tableRef = useRef<HTMLTableElement | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const queryParams: QueryParams = {
        page: searchParams?.get("page"),
        limit: searchParams?.get("limit"),
        searchText: searchParams?.get("searchText"),
        searchType: searchParams?.get("searchType"),
        firmware: {
            searchText: searchParams?.get("firmwareSearchText"),
            type: searchParams?.get("firmwareType"),
        },
        hardware: {
            searchText: searchParams?.get("hardwareSearchText"),
            type: searchParams?.get("hardwareType"),
        },
    }
    const [isCbChecked, setIsCbChecked] = useState(false)

    const [serialNo, setSerialNo] = useState<string>("")
    const [isHwDetailOpen, setIsHwDetailOpen] = useState(false)
    const [isFwDetailOpen, setIsFwDetailOpen] = useState(false)
    const [updateType, setUpdateType] = useState<string>("firm")

    const [bikeList, setBikeList] = useState<AssetAllBike[]>([])
    const [pageRows, setPageRows] = useState<PageRow>(15)
    const [nowPage, setNowPage] = useState<any>(queryParams.page !== null ? queryParams.page : 1)
    const [isFirmwareSearch, setIsFirmwareSearch] = useState<boolean>(false)
    const [isHardwareSearch, setIsHardwareSearch] = useState<boolean>(false)
    const [searchParamsState, setSearchParamsState] = useState<SearchParamState>({
        searchText: "",
        filterText: "",
        firmware: {
            type: "",
            searchText: "",
            filterText: "",
        },
        hardware: {
            type: "",
            searchText: "",
            filterText: "",
        },
    })

    const [firmwareInfo, setFirmwareInfo] = useState<FirmwareParam>({
        bikeSerial: "",
        edcuVersion: 0,
        icsVersion: 0,
        managerId: 0,
        mcuVersion: 0,
        updateDesc: "",
    })

    const [prevFirmwareInfo, setPrevFirmwareInfo] = useState<FirmwareParam>({
        bikeSerial: "",
        edcuVersion: 0,
        icsVersion: 0,
        managerId: -100,
        mcuVersion: 0,
        updateDesc: "",
    })

    const [hardwareInfo, setHardwareInfo] = useState<HardwareParam>({
        bikeSerial: "",
        edcuVersion: 0,
        managerId: 0,
        updateDesc: "",
    })
    const [prevHardwareInfo, setPrevHardwareInfo] = useState<HardwareParam>({
        bikeSerial: "",
        edcuVersion: 0,
        managerId: -100,
        updateDesc: "",
    })
    const [modalOpen, setModalOpen] = useState<ConfirmModalType>({
        isOpen: false,
        title: "confirm",
        contents: `<div>test</div>`,
        width: 800,
        onClose: () => {
            setModalOpen(prev => ({
                ...prev,
                isOpen: false,
                title: "confirm modal",
                contents: "confirm modal contents",
                cb: () => {},
            }))
            setIsFwDetailOpen(false)
            setIsHwDetailOpen(false)
            setUpdateType("firm")
            setIsCbChecked(false)
            clearParam()
        },
        cb: () => {},
    })
    /**전체 바이크 리스트 조회 */
    const { data: bikeListData, isFetching, isError, error } = useGetAllBikeList(managerId as unknown as number)
    /**펌웨어 변경 이력 조회 */
    const { refetch: refetchFwHistory } = useGetBikeFirmWareHistory(serialNo)
    /**하드웨어 변경 이력 조회 */
    const { refetch: refetchHwHistory } = useGetHardwareVersionHistory(serialNo)
    /**전체 펌웨어 리스트  조회 */
    const { data: firmwareListData } = useGetAllFirmList()
    /**전체 하드웨어 리스트  조회 */
    const { data: hardwareListData } = useGetAllHardList()
    /** 펌웨어 버전 업데이트 */
    const { refetch: updateFwVersion } = useUpdateFirmWare(firmwareInfo)

    /** 하드웨어 버전 업데이트 */
    const { refetch: updateHwVersion } = useUpdateHardWare(hardwareInfo)

    const firmSearchOptionList = useMemo(() => {
        return firmwareListData
            ? firmwareListData
                  ?.filter((item: any) => searchParamsState.firmware.type.includes(item.firmwareType?.toLowerCase()))
                  .map((item: FirmwareListALL) => {
                      return { label: item.version, value: item.version }
                  })
            : []
    }, [searchParamsState, firmwareListData])

    const hardSearchOptionList = useMemo(() => {
        return hardwareListData
            ? hardwareListData
                  ?.filter((item: any) => searchParamsState.hardware.type.includes(item.hardwareType?.toLowerCase()))
                  .map((item: FirmwareListALL) => {
                      return { label: item.version, value: item.version }
                  })
            : []
    }, [searchParamsState, hardwareListData])

    /**fw/hw 이력 상세보기 모달 open  */
    const openHistoryModal = () => {
        const fetchData = isFwDetailOpen ? refetchFwHistory() : refetchHwHistory()
        fetchData.then((res: any) => {
            let prevState = ""
            let data = res?.data
                ?.slice()
                ?.reverse()
                ?.map((item: any, i: number) => {
                    const updateItem = {
                        ...item,
                        applyDt: formatYYYYMMDDhhmm(item.applyDt),
                        updateDesc: item.updateDesc?.length > 10 ? <PopOverComponent item={item} /> : item.updateDesc,
                    }
                    if (prevState) {
                        Object.keys(item).forEach((key: any) => {
                            if (
                                key !== "managerNm" &&
                                key !== "applyDt" &&
                                key !== "updateDesc" &&
                                prevState[key] !== item[key]
                            ) {
                                updateItem[key] = (
                                    <FlexDiv
                                        gap="5px"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Tag
                                            type="primary"
                                            size="small"
                                            value={"NEW"}
                                        />
                                        <span>{item[key]}</span>
                                    </FlexDiv>
                                )
                            }
                        })
                    }
                    prevState = {
                        ...item,
                    }
                    return updateItem
                })
            const reversedData = data?.slice()?.reverse()
            setModalOpen(prev => ({
                ...prev,
                isOpen: true,
                width: 800,
                type: "alert",
                title: isFwDetailOpen ? "바이크 펌웨어 변경 이력" : "바이크 하드웨어 변경 이력",
                contents: (
                    <div>
                        <h3>{isFwDetailOpen ? "바이크 펌웨어 변경 이력" : "바이크 하드웨어 변경 이력"}</h3>
                        <DataTable
                            pageRow={5}
                            pagination
                            columns={
                                isFwDetailOpen ? ASSET_BIKE.firmhistoryListColumn : ASSET_BIKE.hardHistoryListColumn
                            }
                            dataList={reversedData}
                        />
                    </div>
                ),
            }))
        })
    }

    const convertList = (data: AssetAllBike[]) => {
        return data?.map(item => {
            return {
                ...item,
                updateDesc: item.updateDesc?.length > 10 ? <PopOverComponent item={item} /> : item.updateDesc,
                hwVersion: item.hwEdcuVersion,
                fwupdate: (
                    <FlexDiv
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Button
                            type="primary"
                            size="small"
                            compact
                            onClick={() => {
                                setModalOpen(prev => ({
                                    ...prev,
                                    isOpen: true,
                                    width: 600,
                                    type: "confirm",

                                    title: "펌웨어 업데이트",
                                    contents: (
                                        <FirmHardUpdateModal
                                            serialNum={item.serialNo}
                                            firmwareInfo={firmwareInfo}
                                            setFirmwareInfo={setFirmwareInfo}
                                            setPrevFirmwareInfo={setPrevFirmwareInfo}
                                            prevFirmwareInfo={prevFirmwareInfo}
                                            hardwareInfo={hardwareInfo}
                                            setHardwareInfo={setHardwareInfo}
                                            setPrevHardwareInfo={setPrevHardwareInfo}
                                            prevHardwareInfo={prevHardwareInfo}
                                            setUpdateType={(type: any) => setUpdateType(type)} // 이 부분 수정
                                        />
                                    ),
                                    cb: () => setIsCbChecked(true),
                                }))
                            }}
                        >
                            <Icon.Edit
                                width={14}
                                height={14}
                            />
                        </Button>
                    </FlexDiv>
                ),

                fwdetail: (
                    <Button
                        size="small"
                        type="tertiary"
                        onClick={() => {
                            setIsFwDetailOpen(true)
                            setSerialNo(item.serialNo)
                        }}
                    >
                        상세보기
                    </Button>
                ),
                hwdetail: (
                    <Button
                        size="small"
                        type="tertiary"
                        onClick={() => {
                            setIsHwDetailOpen(true)
                            setSerialNo(item.serialNo)
                        }}
                    >
                        상세보기
                    </Button>
                ),
            }
        })
    }
    const filterData = useMemo(() => {
        let filteredResults = bikeList
        if (searchParamsState.firmware.filterText !== "") {
            filteredResults = filteredResults?.filter(item => {
                return searchParamsState.firmware.type
                    ? item[searchParamsState.firmware.type]
                          ?.toLowerCase()
                          ?.includes(searchParamsState.firmware.filterText.toLowerCase().split(" ").join(""))
                    : true
            })
        }
        if (searchParamsState.hardware.filterText !== "") {
            filteredResults = filteredResults?.filter(item => {
                return searchParamsState.hardware.type
                    ? item["hwVersion"]
                          ?.toLowerCase()
                          ?.includes(searchParamsState.hardware.filterText.toLowerCase().split(" ").join(""))
                    : true
            })
        }
        // 검색 필터
        if (searchParamsState.filterText !== "") {
            filteredResults = filteredResults?.filter(item =>
                JSON.stringify(item)
                    .toLowerCase()
                    .includes(searchParamsState.filterText.toLowerCase().split(" ").join(""))
            )
        }

        return filteredResults
    }, [bikeList, searchParamsState, isFirmwareSearch, isHardwareSearch])

    const filterClear = () => {
        setSearchParamsState({
            searchText: "",
            filterText: "",
            firmware: {
                type: "edcuVersion",
                searchText: "",
                filterText: "",
            },
            hardware: {
                type: "edcuVersion",
                searchText: "",
                filterText: "",
            },
        })
        setNowPage(1)
    }

    const filterByQueryString = () => {
        setSearchParams({
            filterText: searchParamsState.filterText,
            firmwareFilterText: searchParamsState.firmware.filterText,
            firmwareSearchText: searchParamsState.firmware.searchText,
            firmwareType: searchParamsState.firmware.type,
            hardwareFilterText: searchParamsState.hardware.filterText,
            hardwareSearchText: searchParamsState.hardware.searchText,
            hardwareType: searchParamsState.hardware.type,
            searchText: searchParamsState.searchText,
            page: nowPage,
            limit: pageRows.toString(),
        })
    }

    const updateFirmVirson = () => {
        updateFwVersion().then(res => {
            if (res.status == "success") {
                openAlert({
                    title: "성공",
                    body: "펌웨어 업데이트 성공.",
                    type: "primary",
                })
                queryClient.invalidateQueries({ queryKey: [ASSETS_QUERY_KEY.GET_BIKE_LIST, managerId] })

                setModalOpen(prev => ({
                    ...prev,
                    isOpen: false,
                }))
                setIsCbChecked(false)
                clearParam()
            } else {
                openAlert({
                    title: "실패",
                    body: "업데이트 실패.",
                    type: "warning",
                })
            }
        })
    }
    const updateHardVirson = () => {
        updateHwVersion().then(res => {
            if (res.status == "success") {
                openAlert({
                    title: "성공",
                    body: "하드웨어 업데이트 성공.",
                    type: "primary",
                })
                queryClient.invalidateQueries({ queryKey: [ASSETS_QUERY_KEY.GET_BIKE_LIST, managerId] })

                setModalOpen(prev => ({
                    ...prev,
                    isOpen: false,
                }))
                setIsCbChecked(false)
                clearParam()
            } else {
                openAlert({
                    title: "실패",
                    body: "업데이트 실패.",
                    type: "warning",
                })
            }
        })
    }
    const clearParam = () => {
        setHardwareInfo({
            bikeSerial: "",
            edcuVersion: 0,
            managerId: 0,
            updateDesc: "",
        })
        setFirmwareInfo({
            bikeSerial: "",
            edcuVersion: 0,
            icsVersion: 0,
            managerId: 0,
            mcuVersion: 0,
            updateDesc: "",
        })
        setPrevFirmwareInfo({
            bikeSerial: "",
            edcuVersion: 0,
            icsVersion: 0,
            managerId: -100,
            mcuVersion: 0,
            updateDesc: "",
        })
        setPrevHardwareInfo({
            bikeSerial: "",
            edcuVersion: 0,
            managerId: -100,
            updateDesc: "",
        })
    }

    const handleFetchResult = (
        fetching: boolean,
        isError: boolean,
        error: any,
        dataList: any[],
        setter: (data: any[]) => void
    ) => {
        /**실패시 */
        if (!fetching && isError) {
        }
        /**성공시 */
        if (!fetching && !isError) {
            setter(convertList(dataList as AssetAllBike[]))
        }
    }

    useEffect(() => {
        handleFetchResult(isFetching, isError, error, bikeListData, setBikeList)
    }, [isFetching, isError, error, bikeListData])

    useEffect(() => {
        isFwDetailOpen && openHistoryModal()
    }, [isFwDetailOpen])

    useEffect(() => {
        isHwDetailOpen && openHistoryModal()
    }, [isHwDetailOpen])

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)

            if (newActivePage === 0) {
                return setNowPage(Number(queryParams.page))
            }

            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    useEffect(() => {
        filterByQueryString()
    }, [bikeList, nowPage, pageRows, searchParamsState])

    useEffect(() => {
        if (queryParams.limit) {
            setPageRows(Number(queryParams.limit) as unknown as PageRow)
        }
        if (queryParams.searchText) {
            setSearchParamsState(prevState => ({
                ...prevState,
                searchText: queryParams.searchText as string,
                filterText: queryParams.searchText as string,
            }))
        }
        if (queryParams.firmware.searchText) {
            setSearchParamsState(prevState => ({
                ...prevState,
                firmware: {
                    ...prevState.firmware,
                    searchText: queryParams.firmware.searchText as string,
                    filterText: queryParams.firmware.searchText as string,
                },
            }))
        }
        if (queryParams.firmware.type) {
            setSearchParamsState(prevState => ({
                ...prevState,
                firmware: {
                    ...prevState.firmware,
                    type: queryParams.firmware.type as string,
                },
            }))
        } else {
            setSearchParamsState(prevState => ({
                ...prevState,
                firmware: {
                    ...prevState.firmware,
                    type: "edcuVersion",
                },
            }))
        }

        if (queryParams.hardware.searchText) {
            setSearchParamsState(prevState => ({
                ...prevState,
                hardware: {
                    ...prevState.hardware,
                    searchText: queryParams.hardware.searchText as string,
                    filterText: queryParams.hardware.searchText as string,
                },
            }))
        }
        if (queryParams.hardware.type) {
            setSearchParamsState(prevState => ({
                ...prevState,
                hardware: {
                    ...prevState.hardware,
                    type: queryParams.hardware.type as string,
                },
            }))
        } else {
            setSearchParamsState(prevState => ({
                ...prevState,
                hardware: {
                    ...prevState.hardware,
                    type: "edcuVersion",
                },
            }))
        }
    }, [])
    useEffect(() => {
        if (isCbChecked) {
            if (updateType == "firm") {
                if (
                    (prevFirmwareInfo.edcuVersion === 0 &&
                        prevFirmwareInfo.icsVersion === 0 &&
                        prevFirmwareInfo.mcuVersion === 0) ||
                    prevFirmwareInfo.managerId === -100
                ) {
                    openAlert({
                        title: "확인",
                        body: "펌웨어 필수 입력값을 작성해 주세요.",
                        type: "warning",
                    })
                    setIsCbChecked(false)
                } else {
                    updateFirmVirson()
                }
            } else if (updateType == "hard") {
                if (prevHardwareInfo.edcuVersion === 0 || prevHardwareInfo.managerId === -100) {
                    openAlert({
                        title: "확인",
                        body: "하드웨어 필수 입력값을 작성해 주세요.",
                        type: "warning",
                    })
                    setIsCbChecked(false)
                } else {
                    updateHardVirson()
                }
            }
        }
    }, [isCbChecked])

    return (
        <div>
            <PageHeader
                title="자산관리"
                subTitle="바이크 펌웨어 & 하드웨어 업데이트"
            />
            <Row gap={20}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv
                        gap="10px"
                        alignItems="center"
                    >
                        <Input
                            size="small"
                            width={300}
                            value={searchParamsState.searchText}
                            onChange={(e: onChangeParam) => {
                                setSearchParamsState(prevState => ({
                                    ...prevState,
                                    searchText: e.value.toString(),
                                }))
                            }}
                            onSearch={() => {
                                setNowPage(1)
                                setSearchParamsState(prevState => ({
                                    ...prevState,
                                    filterText: searchParamsState.searchText,
                                }))
                            }}
                        />
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>펌웨어</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv
                        gap="10px"
                        alignItems="center"
                    >
                        <Select
                            width={90}
                            size="small"
                            onChange={(label, value) =>
                                setSearchParamsState(prevState => ({
                                    ...prevState,
                                    firmware: {
                                        ...prevState.firmware,
                                        type: value.toString(),
                                        filterText: "",
                                    },
                                }))
                            }
                            defaultValue={searchParamsState.firmware.type}
                            options={ASSET_BIKE.firmType}
                        />
                        <Select
                            size="small"
                            options={firmSearchOptionList}
                            defaultValue={searchParamsState.firmware.filterText}
                            onChange={(label, value) => {
                                setNowPage(1)
                                setSearchParamsState(prevState => ({
                                    ...prevState,
                                    firmware: {
                                        ...prevState.firmware,
                                        searchText: value.toString(),
                                        filterText: value.toString(),
                                    },
                                }))
                                setIsFirmwareSearch(!isFirmwareSearch)
                            }}
                        />
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>하드웨어</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv
                        gap="10px"
                        alignItems="center"
                    >
                        <Select
                            width={90}
                            size="small"
                            onChange={(label, value) =>
                                setSearchParamsState(prevState => ({
                                    ...prevState,
                                    hardware: {
                                        ...prevState.hardware,
                                        type: value.toString(),
                                        filterText: "",
                                    },
                                }))
                            }
                            defaultValue={searchParamsState.hardware.type}
                            options={ASSET_BIKE.hardType}
                        />
                        <Select
                            size="small"
                            options={hardSearchOptionList}
                            defaultValue={searchParamsState.hardware.filterText}
                            onChange={(label, value) => {
                                setNowPage(1)
                                setIsHardwareSearch(!isHardwareSearch)
                                setSearchParamsState(prevState => ({
                                    ...prevState,
                                    hardware: {
                                        ...prevState.hardware,
                                        searchText: value.toString(),
                                        filterText: value.toString(),
                                    },
                                }))
                            }}
                        />
                        <Button
                            size="small"
                            type="default"
                            onClick={() => filterClear()}
                        >
                            초기화
                        </Button>
                    </FlexDiv>
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    pagination
                    numbering
                    headerLeft={
                        <FlexDiv gap="5px">
                            <Tooltip
                                content="검색 결과 수 / 전체 바이크 수"
                                placement="top"
                            >
                                <Text
                                    lineHeigt="2"
                                    color="var(--success)"
                                    fontWeight={"bold"}
                                >
                                    {filterData?.length}
                                </Text>

                                <Text
                                    lineHeigt="2"
                                    color="var(--primary)"
                                    fontWeight={"bold"}
                                >
                                    / {bikeList?.length}
                                </Text>
                            </Tooltip>
                        </FlexDiv>
                    }
                    columns={ASSET_BIKE.updateBikeTableColumn}
                    dataList={filterData}
                    pageRow={pageRows}
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(queryParams.limit) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                    nowPage={nowPage}
                />
            </div>
            <CommonModal modalOpen={modalOpen} />
        </div>
    )
}

export default BikeList
