import { Button, DataTable, PageHeader, Tag } from "p-ui"
import React, { useEffect, useState } from "react"
import { eventTableColumn } from "../constants"
import { useNavigate } from "react-router"
import events from "src/apis/event"
import { EventItem } from "@type/responseType"

const EventPage = () => {
    const [popupList, setPopupList] = useState<EventItem[]>([])
    const navigate = useNavigate()
    const getPopupList = () => {
        events
            .getEventList()
            .then(res => {
                if (res.status === "SUCCESS") {
                    setPopupList(
                        res.resultData.map((item: EventItem) => {
                            return {
                                ...item,
                                currentStatus:
                                    item.currentStatus === 1 ? (
                                        <Tag
                                            type="primary"
                                            value={"게시중"}
                                        />
                                    ) : item.currentStatus === 2 ? (
                                        <Tag
                                            type="success"
                                            value={"게시 예정"}
                                        />
                                    ) : (
                                        <Tag
                                            type="danger"
                                            value={"종료"}
                                        />
                                    ),
                                detail: (
                                    <Button
                                        onClick={() => {
                                            navigate("" + item.popupId, { state: { id: item.popupId } })
                                        }}
                                        size="small"
                                        type="primary"
                                    >
                                        상세보기
                                    </Button>
                                ),
                            }
                        })
                    )
                }
            })
            .catch(error => console.log(error))
    }
    useEffect(() => {
        getPopupList()
    }, [])
    return (
        <div>
            <PageHeader
                title={"팝업 관리"}
                subTitle="이벤트 리스트"
            />
            <DataTable
                pagination
                headerRight={
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            navigate("" + "insert", { state: { id: "insert" } })
                        }}
                    >
                        등록
                    </Button>
                }
                columns={eventTableColumn}
                dataList={popupList}
            />
        </div>
    )
}

export default EventPage
