import apis from "@apis"
import { ASSETS_QUERY_KEY, FW_QUERY_KEY, HW_QUERY_KEY } from "@common/queryKey"
import { useQuery, useMutation } from "@tanstack/react-query"
import { FirmwareInfo, HardwareInfo } from "@type/firmHardType"

export const useGetAllFirmList = () => {
    return useQuery({
        queryKey: [FW_QUERY_KEY.GET_ALL_LIST],
        queryFn: apis.Firmware.getAllFirmList,
        select: (data: any) => data.resultData,
        retry: false,
    })
}

export const useGetFirmList = () => {
    return useQuery({
        queryKey: [FW_QUERY_KEY.GET_LIST],
        queryFn: apis.Firmware.getFirmList,
        select: (data: any) => data.resultData,
        retry: false,
    })
}
export const useGetFirmHistory = (deviceType: string, firmwareType: string) => {
    return useQuery({
        queryKey: [FW_QUERY_KEY.GET_HISTORY, deviceType, firmwareType],
        queryFn: () => apis.Firmware.getFirmHistory(deviceType, firmwareType),
        select: (data: any) => data.resultData,
        retry: false,
    })
}
export const useUpsertFirm = (firmwareInfo: FirmwareInfo) => {
    return useQuery({
        queryKey: [FW_QUERY_KEY.UPSERT_VERSION, firmwareInfo],
        queryFn: () => apis.Firmware.upsertFirm(firmwareInfo),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
    })
}
export const useDeleteFirm = (firmwareId: number) => {
    return useQuery({
        queryKey: [FW_QUERY_KEY.DELETE_VERSION, firmwareId],
        queryFn: () => apis.Firmware.deleteFirm(firmwareId),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
    })
}

export const useGetAllHardList = () => {
    return useQuery({
        queryKey: [HW_QUERY_KEY.GET_ALL_LIST],
        queryFn: apis.Hardware.getAllHardList,
        select: (data: any) => data.resultData,
        retry: false,
    })
}

export const useGetHardList = () => {
    return useQuery({
        queryKey: [HW_QUERY_KEY.GET_LIST],
        queryFn: apis.Hardware.getHardList,
        select: (data: any) => data.resultData,
        retry: false,
    })
}
export const useGetHardHistory = (deviceType: string, hardmwareType: string) => {
    return useQuery({
        queryKey: [HW_QUERY_KEY.GET_HISTORY, deviceType, hardmwareType],
        queryFn: () => apis.Hardware.getHardHistory(deviceType, hardmwareType),
        select: (data: any) => data.resultData,
        retry: false,
    })
}
export const useUpsertHard = (hardmwareInfo: HardwareInfo) => {
    return useQuery({
        queryKey: [HW_QUERY_KEY.UPSERT_VERSION, hardmwareInfo],
        queryFn: () => apis.Hardware.upsertHard(hardmwareInfo),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
    })
}
export const useDeleteHard = (hardwareId: number) => {
    return useQuery({
        queryKey: [HW_QUERY_KEY.DELETE_VERSION, hardwareId],
        queryFn: () => apis.Hardware.deleteHard(hardwareId),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
    })
}

export const useGetApplyFirmBike = (firmwareId: number) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_APPLY_FIRM_BIKE, firmwareId],
        queryFn: () => apis.Assets.getBikeFirmWareApplyVersionList(firmwareId),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
    })
}
export const useGetApplyHardBike = (hardwareId: number) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_APPLY_HARD_BIKE, hardwareId],
        queryFn: () => apis.Assets.getBikeHardWareApplyVersionList(hardwareId),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
    })
}
