import { Button, TableForm, TableFormLabel, TableFormValue } from "p-ui"
import { FlexBetween } from "@styles"
import { UserCardType } from "@type/responseType"

interface CardFormProps {
    setModalOpen: any
    userCard: UserCardType
    getUserZtCardList: () => void
}

function CardForm(props: CardFormProps) {
    const { setModalOpen, userCard, getUserZtCardList } = props
    return (
        <div>
            <FlexBetween
                alignItems="center"
                margin="0 0 1rem 0"
            >
                <h4>카드 정보</h4>
            </FlexBetween>
            <TableForm>
                <TableFormLabel sm={3}>카드 번호 (끝 4자리)</TableFormLabel>
                <TableFormValue sm={9}>
                    <div className="flex-between">
                        <span>
                            {userCard?.cardNumber?.substring(
                                userCard?.cardNumber?.lastIndexOf("*") + 1,
                                userCard?.cardNumber?.lastIndexOf("*") + 1 + 4
                            ) || "-"}
                        </span>
                        <Button
                            disabled={!userCard?.cardName}
                            onClick={() => {
                                setModalOpen((prev: any) => ({
                                    ...prev,
                                    cardMoalState: true,
                                }))
                                getUserZtCardList()
                            }}
                            size="small"
                            type="default"
                        >
                            카드 변경
                        </Button>
                    </div>
                </TableFormValue>
                <TableFormLabel sm={3}>카드사</TableFormLabel>
                <TableFormValue sm={3}>{userCard?.cardName || "-"}</TableFormValue>
                <TableFormLabel sm={3}>이름</TableFormLabel>
                <TableFormValue sm={3}>{userCard?.customerName || "-"}</TableFormValue>
            </TableForm>
        </div>
    )
}

export default CardForm
