import React from "react"
import { Container, Row, Col } from "react-grid-system"

const PageTitle = (props: any) => {
    return (
        // <Con tainer>
        <Row>
            <Col sm={4}>
                <div>
                    <h2 style={{ display: "inline-block" }}>{props.topMenu} &gt; </h2>
                    <h3 style={{ display: "inline-block" }}>
                        <span>&nbsp;{props.subMenu}</span>
                    </h3>
                </div>
            </Col>
        </Row>
        // </Con>
    )
}

export default PageTitle
