import { faViruses, faBicycle, faPlugCircleBolt, faBatteryFull } from "@fortawesome/free-solid-svg-icons"
import { ObjectType } from "@type/common"

/**리액트 쿼리에서 사용하는 쿼리 키 모음 */
export const QUERY_KEYS = {
    ASSETS_GET_BIKE_LIST: "get-assets-bike-list",
    ASSETS_GET_BIKE_ONE: "get-assets-bike-one",
    ASSETS_GET_BIKE_STATUS: "get-assets-bike-status",
    ASSETS_GET_BIKE_VERSION: "get-assets-bike-version",
    ASSETS_GET_BIKE_MEMO: "get-assets-bike-memo",
    ASSETS_UPDATE_BIKE_MEMO: "update-assets-bike-memo",
    ASSETS_UPDATE_BIKE_INFO: "update-assets-bike-info",
    SHIPPMENT_STATUS_GET_LIST: "get-shippment-status-list",
    FILE_DELETE: "delete-file",
}
export const Issue = {
    MainBoardBox: [
        { title: "Station", color: "var(--zen-orange)", count: 5, icon: faPlugCircleBolt },
        { title: "Bike", color: "var(--primary)", count: 10, icon: faBicycle },
        { title: "Battery", color: "var(--warning)", count: 2, icon: faBatteryFull },
    ],
    status: [
        { status: "전체", color: "var(--zen-orange)", count: 5, value: 99 },
        { status: "발생", color: "var(--zen-orange)", count: 5, value: 0 },
        { status: "확인예정", color: "var(--primary)", count: 10, value: 1 },
        { status: "처리중", color: "var(--warning)", count: 2, value: 2 },
        { status: "완료", color: "var(--success)", count: 20, value: 3 },
    ],
    // 0: 취소, 1: 발생, 2: 확인 예정, 3: 처리 중, 9: 완료
    selectedStatus: [
        { label: "발생", value: "1" },
        { label: "확인 예정", value: "2" },
        { label: "처리 중", value: "3" },
        { label: "완료", value: "9" },
    ],
    selectedReportType: [
        { label: "IB", value: "IB" },
        { label: "관제", value: "관제" },
        { label: "내방", value: "내방" },
        { label: "점검", value: "점검" },
    ],
    issueStatus: [
        { label: "발생", value: "1" },
        { label: "확인예정", value: "2" },
        { label: "처리중", value: "3" },
        { label: "완료", value: "9" },
    ],
    columns: [
        { value: "all", label: "전체" },
        { value: "title", label: "제목" },
        { value: "contents", label: "내용" },
        { value: "categoryNm", label: "상세유형" },
        { value: "parentCategoryNm", label: "유형" },
        { value: "requestType", label: "접수" },
        { value: "issueDt", label: "발생 일시" },
        { value: "goodsSerialNo", label: "S/N" },
        { value: "btSerial", label: "배터리S/N" },
        { value: "product", label: "발생 위치" },
        { value: "reporterId", label: "이슈 보고자" },
        { value: "writerId", label: "작성자" },
    ],
    // tableColumn: ["유형", "교환소 / 이륜차", "배터리 S/N", "제목", "상태", "발생 일시", "작성자"],
    tableColumn: [
        {
            title: "No",
            dataIndex: "issueId",
        },
        {
            title: "유형",
            dataIndex: "parentCategoryNm",
        },
        {
            title: "상세유형",
            dataIndex: "categoryNm",
        },
        {
            title: "S/N",
            dataIndex: "goodsSerialNo",
        },
        {
            title: "배터리S/N",
            dataIndex: "btSerialNo1",
            render: (btSerialNo1: string) => {
                return btSerialNo1 ? `${btSerialNo1} / ${btSerialNo1.slice(0, -1) + "S"}` : "-"
            },
        },
        {
            title: "제목",
            dataIndex: "title",
        },
        {
            title: "상태",
            dataIndex: "status",
            render: (status: any) => {
                let statusText = ""
                switch (status) {
                    case 1:
                        statusText = "발생"
                        break
                    case 2:
                        statusText = "확인예정"
                        break
                    case 3:
                        statusText = "처리중"
                        break
                    case 9:
                        statusText = "완료"
                        break
                    default:
                        statusText = "취소"
                }
                return statusText
            },
        },
        {
            title: "발생 일시",
            dataIndex: "issueDt",
        },
        {
            title: "보고자",
            dataIndex: "reporterId",
        },
    ],
}

export const Driver = {
    tableColumn: [
        {
            title: "",
            dataIndex: "checkBox",
        },
        {
            title: "No",
            dataIndex: "No",
        },
        {
            title: "모델명",
            dataIndex: "model",
        },
        {
            title: "신청일",
            dataIndex: "reg_at",
        },
        {
            title: "예약 날짜",
            dataIndex: "reserved_at",
        },

        {
            title: "시간",
            dataIndex: "test_time",
        },
        {
            title: "타입",
            dataIndex: "type",
        },
        {
            title: "이름",
            dataIndex: "tester_name",
        },
        {
            title: "휴대폰 번호",
            dataIndex: "phoneNo",
        },
        {
            title: "상태",
            dataIndex: "reserve_status",
        },
        {
            title: "상세보기",
            dataIndex: "detail",
        },
    ],
    stateList: [
        { label: "전체", value: "all" },
        { label: "예약 완료", value: "reserved" },
        { label: "예약 취소", value: "cancel" },
        { label: "시승 완료", value: "finish" },
    ],
    dataList: [
        {
            No: "1",
            model: "Zentropy Z",
            reg_at: "2023-01-01",
            reserved_at: "2023-02-02",
            test_time: "10:00",
            type: "단시간",
            tester_name: "이배연",
            phoneNo: "01012345678",
            reserve_status: "예약 완료",
        },
        {
            No: "2",
            model: "Zentropy Z",
            reg_at: "2023-01-05",
            reserved_at: "2023-02-03",
            test_time: "12:00",
            type: "장시간",
            tester_name: "박지영",
            phoneNo: "01058589797",
            reserve_status: "예약 완료",
        },
        {
            No: "3",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "단시간",
            tester_name: "이용희",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
        {
            No: "4",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "장시간",
            tester_name: "윤지연",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
        {
            No: "5",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "단시간",
            tester_name: "손찬희",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
        {
            No: "6",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "단시간",
            tester_name: "홍지수",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
        {
            No: "7",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "단시간",
            tester_name: "김나현",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
        {
            No: "8",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "단시간",
            tester_name: "노상윤",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
        {
            No: "9",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "단시간",
            tester_name: "강효",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
        {
            No: "10",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "장시간",
            tester_name: "박규철",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
        {
            No: "11",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "단시간",
            tester_name: "강지훈",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
        {
            No: "12",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "장시간",
            tester_name: "임유택",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
        {
            No: "13",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "단시간",
            tester_name: "주승돈",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
        {
            No: "14",
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10",
            test_time: "14:00",
            type: "장시간",
            tester_name: "이재상",
            phoneNo: "01024243535",
            reserve_status: "예약 완료",
        },
    ],
    dummy: {
        testerInfo: {
            tester_name: "이재상",
            phoneNo: "01024243535",
            birth: "0000-00-00",
            license: "Y",
            usage: "출퇴근",
            bfModel: "혼다 PCX 125 2020년식",
            marketing: "Y",
        },
        reserveInfo: {
            no: 55,
            model: "Zentropy Z",
            reg_at: "2023-01-11",
            reserved_at: "2023-02-10 14:00",
            type: "장시간",
            reserve_status: "예약 완료",
            place: "청담점",
            memo: "메모장 메모장 메모장 메모장~~~~~~~~~",
        },
    },
}

export const ASCodes = {
    columns: [
        { value: "all", label: "전체" },
        { value: "funnel", label: "유입경로" },
        { value: "symptom", label: "증상" },
        { value: "check", label: "확인" },
        { value: "cause", label: "원인" },
        { value: "process", label: "처리" },
    ],
    tableColumn: [
        {
            title: "코드",
            dataIndex: "code",
        },
        {
            title: "분류",
            dataIndex: "parentLabel",
        },
        {
            title: "내용",
            dataIndex: "codeLabel",
        },
        {
            title: "Action",
            dataIndex: "action",
        },
    ],
    data: [
        {
            parent_code: "funnel",
            code: "C",
            code_label: "고객인입",
        },
        {
            parent_code: "symptom",
            code: 30,
            code_label: "쓰로틀 당겨도 안 나감",
        },
        {
            parent_code: "check",
            code: 20,
            code_label: "증상 확인안됨",
        },
        {
            parent_code: "cause",
            code: 40,
            code_label: "xptmxm",
        },
        {
            parent_code: "process",
            code: 10,
            code_label: "xptmxm1",
        },
    ],
}

export const RentalFleet = {
    bike: [
        {
            title: "상태",
            dataIndex: "leaseType",
        },
        {
            title: "번호판",
            dataIndex: "bikeNo",
        },
        {
            title: "시리얼번호",
            dataIndex: "bikeSerial",
        },
        {
            title: "보험",
            dataIndex: "insuranceAge",
        },
        {
            title: "사용자",
            dataIndex: "leaseUserNm",
        },
        {
            title: "사유",
            dataIndex: "leaseReason",
        },
        {
            title: "위치",
            dataIndex: "bikeLocation",
        },
        {
            title: "비고",
            dataIndex: "comment",
        },
        {
            title: "상세보기",
            dataIndex: "detail",
        },
    ],
    history: [
        {
            title: "상태",
            dataIndex: "leaseType",
        },
        {
            title: "번호판",
            dataIndex: "bikeNo",
        },
        {
            title: "사용자",
            dataIndex: "leaseUserNm",
        },
        {
            title: "사유",
            dataIndex: "leaseReason",
        },
        {
            title: "동의서",
            dataIndex: "consentFg",
        },
        {
            title: "시작담당자",
            dataIndex: "startMgrNm",
        },
        {
            title: "시작일",
            dataIndex: "startDt",
        },
        {
            title: "종료담당자",
            dataIndex: "endMgrNm",
        },
        {
            title: "종료일",
            dataIndex: "endDt",
        },
        {
            title: "보증",
            dataIndex: "depositStatus",
        },
        {
            title: "비고",
            dataIndex: "comment",
        },
    ],
}

export const RentalFleetStatus = [
    { label: "전체", value: 99, tagType: "default" },
    { label: "점검필요", value: 0, tagType: "danger" },
    { label: "사용가능", value: 1, tagType: "primary" },
    { label: "대차", value: 2, tagType: "success" },
    { label: "업무", value: 3, tagType: "success" },
    { label: "점검", value: 4, tagType: "warning" },
]

export const RentalFleetLocation = [
    // { label: "확인불가", value: 0 },
    { label: "고객", value: 1 },
    { label: "매장앞", value: 2 },
    { label: "DECK", value: 3 },
    { label: "주차장", value: 4 },
    { label: "실내", value: 5 },
    { label: "군포", value: 6 },
]

export const RentalDepositStatus = [
    { label: "해당없음", value: 0 },
    { label: "유상", value: 1 },
    { label: "무상", value: 2 },
]

export const RentalFleetConsentFg = [
    { label: "동의안함", value: 0 },
    { label: "동의함", value: 1 },
]
export const Payment = {
    paymentHistory: [
        {
            title: "결제자",
            dataIndex: "userName",
        },
        {
            title: "바이크",
            dataIndex: "bikeNo",
        },
        {
            title: "결제수단",
            dataIndex: "cardName",
        },
        {
            title: "카드번호",
            dataIndex: "cardNumber",
        },
        {
            title: "결제일시",
            dataIndex: "paidAt",
        },
        {
            title: "결제번호",
            dataIndex: "paymentId",
        },
        {
            title: "결제상품",
            dataIndex: "serviceName",
        },
        {
            title: "결제금액",
            dataIndex: "paymentAmount",
        },
        {
            title: "결제상태",
            dataIndex: "paymentStatus",
        },
    ],
}

export const paymentStatus: ObjectType = {
    0: "실패",
    1: "성공",
    2: "취소",
}

export const PushModalMessage = {
    noti: `[ 공지성 PUSH 알림 ]
                            
    젠트로피 앱이 설치된
    모든 고객에게 전송 됩니다.
    
    전송 하시겠습니까?`,
    ad: `[ 광고성 PUSH 알림 ]
                            
    "Push 알림 동의"한
    모든 고객에게 전송 됩니다.
    
    전송 하시겠습니까?`,
}
export const STATUS = {
    TEXT: {
        0: "• 설치예정",
        1: "• 운영중",
        2: "• 전기시공",
    },
    COLOR: {
        0: "#ff653e",
        1: "#00cb45",
        2: "#ff7f00",
        3: "#6284fd",
        4: "#6284fd",
    },
}

export const StationManage = {
    tableColumn: [
        {
            title: "상태",
            dataIndex: "stationStatus",
        },
        {
            title: "스테이션 명",
            dataIndex: "stationName",
        },
        {
            title: "구분",
            dataIndex: "stationTag",
        },
        {
            title: "주소",
            dataIndex: "stationAddress",
        },
        {
            title: "기타",
            dataIndex: "etc",
        },
    ],
    stationStatus: [
        {
            label: "설치 예정",
            value: "0",
        },
        {
            label: "설치 완료",
            value: "1",
        },
        {
            label: "전기 시공",
            value: "2",
        },
        {
            label: "설치 문의",
            value: "3",
        },
        {
            label: "협력사 문의",
            value: "4",
        },
    ],

    centerOption: [
        {
            label: "대리점",
            value: "1",
        },
        {
            label: "협력점",
            value: "2",
        },
    ],
}

export const Notice = [
    {
        title: "고유번호",
        dataIndex: "noticeId",
    },
    {
        title: "고정",
        dataIndex: "pinStatus",
    },
    {
        title: "게시 상태",
        dataIndex: "displayFg",
    },
    {
        title: "구분",
        dataIndex: "codeLabel",
    },
    {
        title: "제목",
        dataIndex: "title",
    },
    {
        title: "작성일",
        dataIndex: "postingDate",
    },
    {
        title: "상세보기",
        dataIndex: "detail",
    },
]

export const Faq = [
    {
        title: "고유번호",
        dataIndex: "faqId",
    },
    {
        title: "게시 상태",
        dataIndex: "displayFg",
    },
    {
        title: "구분",
        dataIndex: "codeLabel",
    },
    {
        title: "제목",
        dataIndex: "title",
    },
    {
        title: "상세보기",
        dataIndex: "detail",
    },
]

export const ErrorCode = [
    {
        title: "고유번호",
        dataIndex: "codeId",
    },
    {
        title: "구분",
        dataIndex: "codeLabel",
    },
    {
        title: "에러코드",
        dataIndex: "errorCode",
    },
    {
        title: "에러코드 설명",
        dataIndex: "errorDesc",
    },
    {
        title: "상세보기",
        dataIndex: "detail",
    },
]
