import apis from "@apis"
import { AssetBikeMemo, AssetGoodsBike, FavoriteBike, Result } from "@type/responseType"
import { useQuery, useMutation } from "@tanstack/react-query"
import openApi from "src/apis/openApi"
import { FirmwareParam, HardwareParam } from "@type/assetsTypes"
import { ASSETS_QUERY_KEY } from "@common/queryKey"

/**useQuery 예제
 * @queryKey
 * @queryFn
 * @select data.resultData에 접근하는걸 그냥 바로 resultData로 가공할수있다
 * @retry 재호출 기능 default false인데 자꾸 두번호출되서 false 넣음
 */
export const useGetAssetBikeStatus = (epochStartTime: string, epochEndTime: string, serialNo: string) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_OPENAPI_INFO, epochStartTime, epochEndTime, serialNo],
        queryFn: () =>
            apis.Assets.getAssetsBikeStatus({ serial: serialNo, startTime: epochStartTime, endTime: epochEndTime }),
        select: (data: any) => {
            const filteredData = data.resultData
                .map((item: any) => ({
                    biSerial: item.biSerial,
                    bt1Serial: item.bt1Serial,
                    bt1CellAvg: item.bt1CellAvg / 1000,
                    bt1Soc: item.bt1Soc / 10,
                    bt1TempAvg: item.bt1TempAvg / 10,
                    bt2Serial: item.bt2Serial,
                    bt2Soc: item.bt2Soc / 10,
                    bt1Current: item.bt1Current / 1000,
                    bt2Current: item.bt2Current / 1000,
                    bt2CellAvg: item.bt2CellAvg / 1000,
                    bt2TempAvg: item.bt2TempAvg / 10,
                    time: item.time,
                    dataTime: `${String(new Date(item.time).getDate()).padStart(2, "0")} 일 \n ${String(
                        new Date(item.time).getHours()
                    ).padStart(2, "0")} : ${String(new Date(item.time).getMinutes()).padStart(2, "0")}`,
                }))
                .sort((a: { time: number }, b: { time: number }) => a.time - b.time)
            return filteredData
        },
        retry: false,
        enabled: false,
    })
}
export const useGetAllBikeList = (managerId: number) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_BIKE_LIST, managerId],
        queryFn: () => apis.Assets.getAllBikeList(managerId),
        select: (data: any) => data.resultData,
        retry: false,
    })
}
export const useGetBikeOne = (serialNo: string, managerId: number) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_BIKE_ONE, serialNo, managerId],
        queryFn: () => apis.Assets.getBikeOne(serialNo, managerId),
        select: (data: any) => data.resultData,
        retry: false,
    })
}
export const useGetBikeStatus = (serialNo: string, enabled: boolean = false) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_BIKE_STATUS, serialNo],
        queryFn: () => apis.Assets.getBikeStatus(serialNo),
        select: (data: any) => data.resultData,
        retry: false,
        enabled: enabled,
    })
}
export const useGetBikeFirmWareVersion = (serialNo: string, enabled: boolean = false) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_BIKE_FIRMWARE_VERSION, serialNo],
        queryFn: () => apis.Assets.getBikeFirmWareVersion(serialNo),
        select: (data: any) => data.resultData,
        retry: false,
        enabled: enabled,
    })
}
export const useGetBikeFirmWareHistory = (serialNo: string) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_BIKE_FIRMWARE_HISTORY, serialNo],
        queryFn: () => apis.Assets.getBikeFirmWareVersionHistory(serialNo),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
    })
}
export const useUpdateFirmWare = (firmwareParam: FirmwareParam) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.UPDATE_FIRMWARE, firmwareParam],
        queryFn: () => apis.Assets.updateFirmWareVersion(firmwareParam),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
    })
}
export const useUpdateHardWare = (hardwareParam: HardwareParam) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.UPDATE_HARDMWARE, hardwareParam],
        queryFn: () => apis.Assets.updateHardWareVersion(hardwareParam),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
    })
}
export const useGetHardWareVersion = (serialNo: string, enabled: boolean = false) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_BIKE_HARDWARE_VERSION, serialNo],
        queryFn: () => apis.Assets.getBikeHardWareVersion(serialNo),
        select: (data: any) => data.resultData,
        retry: false,
        enabled: enabled,
    })
}
export const useGetHardwareVersionHistory = (serialNo: string) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_BIKE_HARDWARE_HISTORY, serialNo],
        queryFn: () => apis.Assets.getBikeHardWareVersionHistory(serialNo),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
    })
}
export const useGetBikeMemo = (serialNo: string) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_BIKE_MEMO, serialNo],
        queryFn: () => apis.Assets.getBikeMemo(serialNo),
        select: (data: any) => data.resultData,
        retry: false,
    })
}
export const useGetFavoriteBike = (managerId: number) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.GET_FAVORITE_LIST, managerId],
        queryFn: () => apis.Assets.getFavoriteBikeList(managerId),
        select: (data: any) => data.resultData,
        retry: false,
    })
}
export const useDeleteFavoriteBike = (favoriteBikeId: number) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.DELETE_FAVORITE_LIST, favoriteBikeId],
        queryFn: () => apis.Assets.deleteFavoriteBike(favoriteBikeId),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
    })
}
export const useUpdateFavoriteBike = (favoriteBike: FavoriteBike) => {
    return useQuery({
        queryKey: [ASSETS_QUERY_KEY.POST_FAVORITE_LIST, favoriteBike],
        queryFn: () => apis.Assets.updateFavoriteBike(favoriteBike),
        select: (data: any) => data.resultData,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
    })
}

/**useMutation 예제
 * @queryClient Success 했을때 get해올 데이터 위해서 client를 위에 선언해줌
 * @fetcher 사용할 Promise 함수
 * @onSuccess 성공했을때 실행할 함수/ 컴포넌트로 뺴놔서 지금 주석 처리 해둠
 */
export const useUpdateBikeInfo = () => {
    const fetcher = (chageData: AssetGoodsBike) => apis.Assets.updateBikeInfo(chageData.serialNo, chageData)
    return useMutation({
        mutationFn: fetcher,
        // onSuccess: () => {
        //     queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ASSETS_GET_BIKE_ONE] })
        // },
        onError: (res: any) => console.log(res),
    })
}
export const useUpdateBikeMemo = () => {
    const fetcher = (chageData: AssetBikeMemo) => apis.Assets.updateBikeMemo(chageData)
    return useMutation({
        mutationFn: fetcher,
        // onSuccess: () => {
        //     queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ASSETS_GET_BIKE_ONE] })
        // },
        onError: (res: any) => console.log(res),
    })
}
