import { HW_QUERY_KEY } from "@common/queryKey"
import { getFwHwCode } from "@common/util"
import useAlert from "@context/alert/useAlert"
import CommonModal from "@pages/Assets/Components/CommonModal"
import HardVersionUpdateModal from "@pages/Assets/Components/HardVersionUpsertModal"
import { ASSETS_HARD } from "@pages/Assets/constants"

import { ConfirmModalType } from "@pages/Assets/types"
import { FlexDiv, Text } from "@styles"
import { useQueryClient } from "@tanstack/react-query"
import { HardwareInfo } from "@type/firmHardType"
import { Button, DataTable, Icon, Loading, Modal, PageHeader, Tooltip } from "p-ui"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { useDeleteHard, useGetHardHistory, useUpsertHard } from "src/queries/firmhard"

const HardwareDetail = () => {
    const location = useLocation()
    const hardwareType = new URLSearchParams(location.search).get("type") as string
    const deviceType = new URLSearchParams(location.search).get("device") as string
    const { deviceTypeCode, firmwareTypeCode } = getFwHwCode(deviceType, hardwareType)

    const { openAlert } = useAlert()
    const queryClient = useQueryClient()
    const [ischecked, setIsChecked] = useState(false)

    const [modalOpen, setModalOpen] = useState<ConfirmModalType>({
        isOpen: false,
        title: "confirm",
        contents: `<div>test</div>`,
        width: 800,
        onClose: () => {
            setModalOpen(prev => ({
                ...prev,
                isOpen: false,
                title: "confirm modal",
                contents: "confirm modal contents",
                cb: () => {},
            }))
            setIsChecked(false)
            clearParam()
        },
        cb: () => {},
    })

    const [hardwareUpdateParam, setHardwareUpdateParam] = useState<HardwareInfo>({
        description: "",
        developer: "",
        deviceType: deviceTypeCode as number,
        hardwareId: 0,
        hardwareType: firmwareTypeCode as number,
        releaseDt: "",
        status: 1,
        version: "",
    })

    const [hardwareId, setHardwareId] = useState<number>()
    const { data: hardwareHistoryData, isFetching } = useGetHardHistory("bike", hardwareType?.toLocaleLowerCase())

    const { refetch: upsertHardware } = useUpsertHard(hardwareUpdateParam)
    const { refetch: deleteHardware } = useDeleteHard(hardwareId as number)

    const checkedValidation = () => {
        setIsChecked(true)
    }
    const clearParam = () => {
        setHardwareUpdateParam({
            description: "",
            developer: "",
            deviceType: deviceTypeCode as number,
            hardwareId: 0,
            hardwareType: firmwareTypeCode as number,
            releaseDt: "",
            status: 0,
            version: "",
        })
    }
    const changeTypeName = (type: string) => {
        switch (type) {
            case "BIKE_HW":
                return "바이크"
            case "STATION_HW":
                return "스테이션"
            case "BATTERY_HW":
                return "배터리"
        }
    }

    const deleteHard = () => {
        deleteHardware().then((res: any) => {
            if (res.status == "success") {
                openAlert({
                    title: "성공",
                    body: "하드웨어 삭제 성공.",
                    type: "primary",
                })
                queryClient.invalidateQueries({
                    queryKey: [HW_QUERY_KEY.GET_HISTORY, "bike", hardwareType?.toLocaleLowerCase()],
                })
                setModalOpen(prev => ({
                    ...prev,
                    isOpen: false,
                }))
            } else {
                openAlert({
                    title: "실패",
                    body: "삭제 실패.",
                    type: "warning",
                })
            }
        })
    }
    const upsertHard = () => {
        upsertHardware().then(res => {
            if (res.status == "success") {
                openAlert({
                    title: "성공",
                    body: "하드웨어 업데이트 성공.",
                    type: "primary",
                })
                queryClient.invalidateQueries({
                    queryKey: [HW_QUERY_KEY.GET_HISTORY, "bike", hardwareType?.toLocaleLowerCase()],
                })
                clearParam()
                setModalOpen(prev => ({
                    ...prev,
                    isOpen: false,
                }))
                setIsChecked(false)
            } else {
                openAlert({
                    title: "실패",
                    body: "업데이트 실패.",
                    type: "warning",
                })
            }
        })
    }
    let data = hardwareHistoryData?.map((item: any) => {
        return {
            ...item,
            hardwareType: item?.hardwareType,
            version: item.version,
            developer: item?.developer,
            description:
                item?.description?.length > 15 ? (
                    <Tooltip
                        placement={"top"}
                        content={
                            <ul style={{ padding: 0 }}>
                                {item?.description.length > 20
                                    ? item?.description.match(/.{1,20}/g).map((text: any) => {
                                          return <li>{text}</li>
                                      })
                                    : item?.description}
                            </ul>
                        }
                    >
                        <div style={{ cursor: "pointer" }}> {item?.description.substr(0, 15) + "..."}</div>
                    </Tooltip>
                ) : (
                    item?.description
                ),
            releaseDt: item?.releaseDt.replace(/\.\d+$/, ""),
            deviceType: changeTypeName(item?.deviceType),
            update: (
                <FlexDiv justifyContent="center">
                    <Button
                        size="small"
                        compact
                        type="primary"
                        onClick={() => {
                            setModalOpen(prev => ({
                                ...prev,
                                isOpen: true,
                                width: 600,
                                title: "하드웨어 버전 수정",
                                contents: (
                                    <HardVersionUpdateModal
                                        deviceType={changeTypeName(deviceType as string)}
                                        hardwareType={item?.hardwareType}
                                        hardwareInfo={item}
                                        setHardwareUpdateParam={setHardwareUpdateParam}
                                    />
                                ),
                                cb: checkedValidation,
                            }))
                        }}
                    >
                        <Icon.Edit
                            width={14}
                            height={14}
                        />
                    </Button>
                </FlexDiv>
            ),
            delete: (
                <FlexDiv justifyContent="center">
                    <Button
                        size="small"
                        compact
                        type="danger"
                        disabled={item.isBikeExits}
                        onClick={() => {
                            setHardwareId(item?.hardwareId)
                            setModalOpen(prev => ({
                                ...prev,
                                isOpen: true,
                                width: 600,
                                title: "하드웨어 버전 삭제",
                                contents: <>하드웨어 버전을 삭제하시겠습니까?</>,
                                cb: deleteHard,
                            }))
                        }}
                    >
                        <Icon.Delete
                            width={14}
                            height={14}
                        />
                    </Button>
                </FlexDiv>
            ),
        }
    })
    useEffect(() => {
        if (ischecked) {
            let allPropertiesFilled = true
            for (const key in hardwareUpdateParam) {
                if (key !== "description" && hardwareUpdateParam[key] === "") {
                    openAlert({
                        title: "확인",
                        body: "필수 입력값을 작성해 주세요.",
                        type: "warning",
                    })
                    setIsChecked(false)
                    allPropertiesFilled = false
                    break
                }
            }
            if (allPropertiesFilled) {
                upsertHard()
            }
        }
    }, [ischecked])
    return (
        <div>
            <PageHeader
                title="자산관리"
                subTitle="바이크 상세 > 하드웨어 패치 이력"
            />
            {/**이거 EDCU 에서 props 넘어오는걸로 바꿔야함 */}
            <Text
                as={"h2"}
                fontWeight={700}
            >
                EDCU
            </Text>
            {isFetching ? (
                <Loading open={isFetching} />
            ) : (
                <DataTable
                    headerRight={
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                setModalOpen(prev => ({
                                    ...prev,
                                    isOpen: true,
                                    width: 600,
                                    title: "하드웨어 버전 추가",
                                    contents: (
                                        <HardVersionUpdateModal
                                            deviceType={changeTypeName(deviceType as string)}
                                            hardwareType={hardwareType}
                                            setHardwareUpdateParam={setHardwareUpdateParam}
                                        />
                                    ),
                                    cb: checkedValidation,
                                }))
                            }}
                        >
                            버전 추가
                        </Button>
                    }
                    pagination
                    columns={ASSETS_HARD.hardHistoryColumn}
                    dataList={data}
                />
            )}
            <CommonModal modalOpen={modalOpen} />
        </div>
    )
}

export default HardwareDetail
