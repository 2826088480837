export const ASSET_BIKE = {
    updateBikeTableColumn: [
        {
            title: "차대번호",
            dataIndex: "biVin",
        },
        {
            title: "시리얼",
            dataIndex: "serialNo",
        },
        {
            title: "모델명",
            dataIndex: "goodsModelNm",
        },
        {
            title: "EDCU",
            dataIndex: "edcuVersion",
        },
        {
            title: "MCU",
            dataIndex: "mcuVersion",
        },
        {
            title: "ICS",
            dataIndex: "icsVersion",
        },
        {
            title: "H/W EDCU",
            dataIndex: "hwEdcuVersion",
        },
        {
            title: "fw/hw 업데이트",
            dataIndex: "fwupdate",
        },
        {
            title: "FW",
            dataIndex: "fwdetail",
        },
        {
            title: "HW",
            dataIndex: "hwdetail",
        },
    ],

    bikeTableCoulum: [
        {
            title: "차대번호",
            dataIndex: "biVin",
        },
        {
            title: "시리얼",
            dataIndex: "serialNo",
        },

        {
            title: "모델명",
            dataIndex: "goodsModelNm",
        },
        {
            title: "펌웨어",
            dataIndex: "firmWareVirson",
        },
        {
            title: "하드웨어",
            dataIndex: "hwEdcuVersion",
        },
        {
            title: "타입",
            dataIndex: "goodsType",
        },
        {
            title: "상세보기",
            dataIndex: "detail",
        },
    ],
    favoriteBikeCoulmn: [
        {
            title: "",
            dataIndex: "favorite",
        },
        {
            title: "차대번호",
            dataIndex: "biVin",
        },
        {
            title: "시리얼",
            dataIndex: "serialNo",
        },

        {
            title: "모델명",
            dataIndex: "goodsModelNm",
        },
        {
            title: "펌웨어",
            dataIndex: "firmWareVirson",
        },
        {
            title: "하드웨어",
            dataIndex: "hwEdcuVersion",
        },
        {
            title: "타입",
            dataIndex: "goodsType",
        },
        {
            title: "상세보기",
            dataIndex: "detail",
        },
    ],
    firmTabTableColumn: [
        {
            title: "EDCU",
            dataIndex: "edcuVersion",
        },
        {
            title: "ICS",
            dataIndex: "icsVersion",
        },
        {
            title: "MCU",
            dataIndex: "mcuVersion",
        },
        {
            title: "담당자",
            dataIndex: "managerNm",
        },
        {
            title: "업데이트 일자",
            dataIndex: "applyDt",
        },
        {
            title: "사유",
            dataIndex: "updateDesc",
        },
        {
            title: "업데이트",
            dataIndex: "update",
        },
    ],
    firmhistoryListColumn: [
        {
            title: "EDCU",
            dataIndex: "edcuVersion",
        },
        {
            title: "ICS",
            dataIndex: "icsVersion",
        },
        {
            title: "MCU",
            dataIndex: "mcuVersion",
        },
        {
            title: "담당자",
            dataIndex: "managerNm",
        },
        {
            title: "업데이트 일자",
            dataIndex: "applyDt",
        },
        {
            title: "사유",
            dataIndex: "updateDesc",
        },
    ],
    hardTabTableColumn: [
        {
            title: "EDCU",
            dataIndex: "edcuVersion",
        },
        {
            title: "담당자",
            dataIndex: "managerNm",
        },
        {
            title: "업데이트 일자",
            dataIndex: "applyDt",
        },
        {
            title: "사유",
            dataIndex: "updateDesc",
        },
        {
            title: "업데이트",
            dataIndex: "update",
        },
    ],
    hardHistoryListColumn: [
        {
            title: "EDCU",
            dataIndex: "edcuVersion",
        },
        {
            title: "담당자",
            dataIndex: "managerNm",
        },
        {
            title: "업데이트 일자",
            dataIndex: "applyDt",
        },
        {
            title: "사유",
            dataIndex: "updateDesc",
        },
    ],
    firmType: [
        { label: "EDCU", value: "edcuVersion" },
        { label: "MCU", value: "mcuVersion" },
        { label: "ICS", value: "icsVersion" },
    ],
    hardType: [{ label: "EDCU", value: "edcuVersion" }],
    updateType: [
        { label: "펌웨어", value: "firm" },
        { label: "하드웨어", value: "hard" },
    ],
    graphColor: [
        "rgba(255, 173, 177, 0.1)", // 빨강
        "rgba(255, 166, 0, 0.071)", // 주황
        "rgba(255, 255, 0, 0.052)", // 노랑
        "rgba(0, 128, 0, 0.028)", // 초록
        "rgba(0, 0, 255, 0.044)", // 파랑
        "rgba(128, 0, 128, 0.1)", // 보라
        "rgba(255, 173, 177, 0.1)", // 빨강
        "rgba(255, 166, 0, 0.071)", // 주황
        "rgba(255, 255, 0, 0.052)", // 노랑
        "rgba(0, 128, 0, 0.028)", // 초록
        "rgba(0, 0, 255, 0.044)", // 파랑
        "rgba(128, 0, 128, 0.1)", // 보라
    ],
}

export const ASSETS_FIRM = {
    firmListTable: [
        {
            title: "대상",
            dataIndex: "deviceType",
        },
        {
            title: "펌웨어",
            dataIndex: "firmwareType",
        },
        {
            title: "버전",
            dataIndex: "version",
        },
        {
            title: "배포일",
            dataIndex: "releaseDt",
        },
        {
            title: "설명",
            dataIndex: "description",
        },
        {
            title: "개발자",
            dataIndex: "developer",
        },
        {
            title: "상세보기",
            dataIndex: "detail",
        },
    ],
    firmHistoryColumn: [
        {
            title: "대상",
            dataIndex: "deviceType",
        },
        {
            title: "펌웨어",
            dataIndex: "firmwareType",
        },
        {
            title: "버전",
            dataIndex: "version",
        },
        {
            title: "배포일",
            dataIndex: "releaseDt",
        },
        {
            title: "설명",
            dataIndex: "description",
        },
        {
            title: "개발자",
            dataIndex: "developer",
        },
        {
            title: "바이크 조회",
            dataIndex: "search",
        },
        {
            title: "수정",
            dataIndex: "update",
        },
        {
            title: "삭제",
            dataIndex: "delete",
        },
    ],
    firmBikeSearchColumn: [
        {
            title: "시리얼",
            dataIndex: "serialNo",
        },
        {
            title: "차대번호",
            dataIndex: "vinNo",
        },
        {
            title: "버전명",
            dataIndex: "version",
        },
        {
            title: "상세보기",
            dataIndex: "detail",
        },
    ],
}
export const ASSETS_HARD = {
    hardListTable: [
        {
            title: "대상",
            dataIndex: "deviceType",
        },
        {
            title: "하드웨어",
            dataIndex: "hardwareType",
        },
        {
            title: "버전",
            dataIndex: "version",
        },
        {
            title: "배포일",
            dataIndex: "releaseDt",
        },
        {
            title: "설명",
            dataIndex: "description",
        },
        {
            title: "개발자",
            dataIndex: "developer",
        },
        {
            title: "상세보기",
            dataIndex: "detail",
        },
    ],
    hardHistoryColumn: [
        {
            title: "대상",
            dataIndex: "deviceType",
        },
        {
            title: "펌웨어",
            dataIndex: "hardwareType",
        },
        {
            title: "버전",
            dataIndex: "version",
        },
        {
            title: "배포일",
            dataIndex: "releaseDt",
        },
        {
            title: "설명",
            dataIndex: "description",
        },
        {
            title: "개발자",
            dataIndex: "developer",
        },
        {
            title: "수정",
            dataIndex: "update",
        },
        {
            title: "삭제",
            dataIndex: "delete",
        },
    ],
}

export const ASSETS_CODE = {
    BIKE_FW: 553,
    STATION_FW: 557,
    BATTERY_FW: 558,
    ICS_FW: 554,
    MCU_FW: 555,
    EDCU_FW: 556,
    BIKE_HW: 559,
    EDCU_HW: 560,
}
