import React, { useEffect, useState } from "react"

import { CommonDiv, FlexDiv, Text } from "@styles"
import { TextArea, Button, TableForm, TableFormLabel, TableFormValue, Icon } from "p-ui"

import orderManage from "src/apis/orderManage"

import useAlert from "@context/alert/useAlert"

import { DetailOrderInfo, OrderCancel, OrderCommentParams } from "@type/orderManageType"

type InfoComponents = {
    title: string
    data: string | null
}

interface OrderDetailOrderprops {
    detailData: DetailOrderInfo
    orderComment: OrderCommentParams
    getOrderDetail: () => void
    orderCancel: OrderCancel
    orderStatus: number
    modalOpen: any
    setModalOpen: any
}
function OrderDetailOrdererForm(props: OrderDetailOrderprops) {
    const { detailData, orderComment, getOrderDetail, orderCancel, orderStatus, modalOpen, setModalOpen } = props

    const { openAlert } = useAlert()

    const [memoEdit, setMemoEdit] = useState<boolean>(false)

    const [memoParam, setMemoParam] = useState<OrderCommentParams>({
        comment: orderComment?.comment ? orderComment.comment : "",
        commentId: orderComment?.commentId ? orderComment.commentId : null,
        managerId: 0, //추후변경
        orderId: detailData?.orderId,
    })
    const phoneRegex = /^(\d{2,3})(\d{3,4})(\d{4})$/
    const birthRegex = /(.{2})(?!$)/g

    const isCancle = orderStatus === 0 || orderStatus === 1

    const customerInfo: InfoComponents[] = [
        {
            title: "구분",
            data: detailData?.customerType || detailData?.orderType,
        },
        {
            title: "이름",
            data: detailData?.userName,
        },
        {
            title: "성별",
            data: detailData?.userGender == "M" ? "남성" : "여성",
        },
        {
            title: "휴대폰",
            data: detailData?.userPhone?.replace(phoneRegex, `$1-$2-$3`),
        },
        {
            title: "이메일",
            data: detailData?.userEmail,
        },
        {
            title: "주소",
            data: `${detailData?.addressRoad} ${detailData?.addressDetail}`,
        },
        {
            title: "생년월일",
            data: detailData?.personalNumber?.replace(birthRegex, "$1/"),
        },
        {
            title: "사업자 번호",
            data: detailData?.personalNumber,
        },
    ]

    const cancelInfo: InfoComponents[] = [
        {
            title: "취소일",
            data: orderCancel?.requestAt,
        },
        {
            title: "취소 금액",
            data: `${orderCancel?.orderAmount.toLocaleString()} 원`,
        },
        {
            title: "취소 사유",
            data: orderCancel?.cancelReason,
        },
    ]

    const CustomerInfoComponent = [
        {
            title: "주문자 정보",
            data: customerInfo,
        },

        {
            title: "주문 취소",
            data: cancelInfo,
        },
    ]

    const upsertMemo = () => {
        setMemoEdit(!memoEdit)
        if (memoEdit) {
            orderManage
                .upsertComment(memoParam)
                .then(res => {
                    if (res.status === "SUCCESS") {
                        openAlert("successAlert")
                        openAlert({
                            title: "저장 완료",
                            body: "메모 저장 완료 되었습니다.",
                            type: "primary",
                        })

                        getOrderDetail()
                    } else {
                        openAlert({
                            title: "저장 실패",
                            body: "메모 저장 실패 되었습니다.",
                            type: "warning",
                        })
                    }
                })
                .catch(error => console.log(error))
        }
    }

    useEffect(() => {
        if (detailData?.orderId) {
            setMemoParam({
                ...memoParam,
                orderId: detailData?.orderId,
                commentId: orderComment?.commentId ? orderComment.commentId : null,
            })
        }
    }, [detailData])

    return (
        <>
            <CommonDiv width="45%">
                <FlexDiv
                    alignItems="center"
                    gap="15px"
                    margin="0 0 5px 0"
                >
                    <Text
                        fontSize="16px"
                        bold
                    >
                        주문자 정보
                    </Text>
                    <Button
                        size="small"
                        type="primary"
                        compact
                        disabled={isCancle}
                        onClick={() => {
                            setModalOpen({ ...modalOpen, isEditOpen: true })
                        }}
                    >
                        <Icon.Edit
                            width={15}
                            height={15}
                        />
                    </Button>
                </FlexDiv>

                {CustomerInfoComponent.map(item => {
                    if (item.title === "주문 취소" && !orderCancel) {
                        return null
                    }

                    return (
                        <>
                            {item.title !== "주문자 정보" && (
                                <FlexDiv
                                    margin="30px 0"
                                    alignItems="center"
                                    gap="5px"
                                >
                                    <b>{item.title}</b>
                                </FlexDiv>
                            )}
                            <div>
                                <TableForm>
                                    {item.data.map(item => {
                                        if (detailData?.orderType === "개인" && item.title === "사업자 번호") {
                                            return null
                                        }
                                        if (detailData?.orderType !== "개인" && item.title === "생년월일") {
                                            return null
                                        }

                                        return (
                                            <>
                                                <TableFormLabel sm={3}>{item.title}</TableFormLabel>
                                                <TableFormValue sm={9}>{item.data}</TableFormValue>
                                            </>
                                        )
                                    })}
                                </TableForm>
                            </div>
                        </>
                    )
                })}
                <CommonDiv margin="30px 0">
                    <b>추천인</b>
                </CommonDiv>
                <TableForm>
                    <TableFormLabel sm={3}>추천인</TableFormLabel>
                    <TableFormValue sm={3}>
                        {detailData?.recommenderName ? detailData.recommenderName : "-"}
                    </TableFormValue>
                    <TableFormLabel sm={3}>추천인 연락처</TableFormLabel>
                    <TableFormValue sm={3}>
                        {detailData?.recommenderName
                            ? detailData.recommenderPhone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                            : "-"}
                    </TableFormValue>
                </TableForm>
                <CommonDiv margin="30px 0">
                    <b>메모</b>
                </CommonDiv>
                {!memoEdit ? (
                    <TableForm>
                        <TableFormLabel sm={3}>메모</TableFormLabel>
                        <TableFormValue sm={9}>
                            <div style={{ whiteSpace: "pre-line" }}>
                                {orderComment?.comment ? orderComment.comment : "-"}
                            </div>
                        </TableFormValue>
                    </TableForm>
                ) : (
                    <TextArea
                        name="comment"
                        maxLength={500}
                        onChange={e => {
                            setMemoParam({ ...memoParam, [e.target.name]: e.target.value })
                        }}
                        defaultValue={orderComment?.comment}
                        readonly={!memoEdit}
                        height="100px"
                    />
                )}
                <FlexDiv
                    justifyContent="flex-end"
                    gap="10px"
                    margin="10px 0 0 0"
                >
                    {!memoEdit ? (
                        <Button
                            onClick={upsertMemo}
                            size="small"
                        >
                            {orderComment?.comment ? "메모 수정" : "메모 등록"}
                        </Button>
                    ) : (
                        <>
                            <Button
                                onClick={() => setMemoEdit(false)}
                                size="small"
                            >
                                취소
                            </Button>
                            <Button
                                onClick={upsertMemo}
                                size="small"
                                type="primary"
                            >
                                저장
                            </Button>
                        </>
                    )}
                </FlexDiv>
            </CommonDiv>
        </>
    )
}

export default OrderDetailOrdererForm
