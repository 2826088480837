import React, { useEffect, useMemo, useState } from "react"

import { useSetRecoilState } from "recoil"
import { zentalModalState, zentalDataState } from "src/store/zental"

import {
    Button,
    Col,
    Input,
    LabelForm,
    Row,
    TableForm,
    TableFormLabel,
    TableFormValue,
    Select,
    Modal,
    Icon,
    Tooltip,
    Tag,
} from "p-ui"
import { DataPickerInput, FlexBetween, FlexColDiv, FlexDiv, GridDiv, SettingDiv, Text } from "@styles"

import ZENTAL from "src/apis/zental"

import { BikeServiceInfoType } from "@type/responseType"
import { OptionGroupType } from "p-ui/dist/esm/types"
import { addWeeks, format, parseISO } from "date-fns"
import { ObjectType } from "@type/common"
import useAlert from "@context/alert/useAlert"
import CustomDatePicker from "@components/widgets/customDatePicker"
import ReactDatePicker from "react-datepicker"
import { ko } from "date-fns/locale"

interface contractFromProps {
    bikeData: BikeServiceInfoType | any
    paymentHistoryData: any
    getZentalRider: () => void
    rePayZental: () => void
    getBikeData: () => void
}

interface ZentalModelType {
    modelId: number
    modelName: string
    modelPeriod: number
    priceId: number
    price: string
    startDt: string
    endDt: string
}

export interface ChangeZentalType {
    zentalId: number
    modelId: number
    startDt: string
    endDt: string
}

function ContractForm(props: contractFromProps) {
    const { bikeData, paymentHistoryData, getZentalRider, rePayZental, getBikeData } = props

    const { openAlert } = useAlert()

    const setZentalModal = useSetRecoilState(zentalModalState)
    const setZentaldata = useSetRecoilState(zentalDataState)

    const [modelModalOpen, setModelModalOpen] = useState(false)
    const [periodModalOpen, setPeriodModalOpen] = useState(false)

    const [changeZental, setChangeZental] = useState<ChangeZentalType>({
        zentalId: -1,
        modelId: -1,
        startDt: "0000-00-00",
        endDt: "0000-00-00",
    })

    const [zentalModel, setZentalModel] = useState<ZentalModelType[]>([])

    /**
     * 계약하지 않은 유저 목록
     */
    const getUnContarctUser = async () => {
        await ZENTAL.getUnContarctUser().then(res => {
            if (res.status === "SUCCESS") {
                setZentaldata(prev => ({ ...prev, userState: res.resultData }))
                setZentalModal(prev => ({
                    ...prev,
                    connectModalState: true,
                }))
            } else {
                setZentalModal(prev => ({
                    ...prev,
                    connectModalState: true,
                }))
            }
        })
    }

    /**
     * 젠탈 사용가능한 서비스 모델 조회
     */
    const getZentalServiceModel = async () => {
        await ZENTAL.getServiceModel().then(res => {
            if (res.status === "SUCCESS") {
                setZentaldata(prev => ({ ...prev, serviceModelState: res.resultData }))
                getUnContarctUser()
            } else {
            }
        })
    }

    /**
     * 계약 시작일 기준 변경 가능 모델 조회
     * @param startDate {string} 계약 시작일
     */
    const getNewZentalModel = async (startDate: string, btnType: string | number) => {
        await ZENTAL.getServiceModel(startDate).then(res => {
            if (res.status === "SUCCESS") {
                setZentalModel(res.resultData)

                btnTypes[btnType] == "model" ? setModelModalOpen(true) : setPeriodModalOpen(true)
            }
        })
    }

    /**
     * 업데이트 전 validation
     * @param type
     */
    const validation = (type: string | number) => {
        if (btnTypes[type] == "model") {
            if (changeZental.modelId == -1) {
                openAlert({ title: "실패", body: "변경할 계약을 선택해주세요.", type: "warning" })
                return true
            }
            if (changeZental.modelId == bikeData.modelId) {
                openAlert({ title: "실패", body: "동일한 계약입니다.", type: "warning" })
                return true
            }
        } else {
            // * 날짜 형식 확인
            if (regexAction()) {
                return true
            }

            // * 결제 이력 없는지 확인
            if (paymentHistoryData.length > 0) {
                openAlert({ title: "실패", body: "첫 결제 이전에만 기간을 수정할 수 있습니다.", type: "warning" })
                return true
            }

            // * 동일 기간 확인
            if (changeZental.startDt == bikeData.serviceStartDt && changeZental.endDt == bikeData.serviceEndDt) {
                openAlert({ title: "실패", body: "기간을 확인해주세요", type: "warning" })
                return true
            }
        }

        updateContract()
    }

    /**
     * 젠탈 계약 업데이트 api
     */
    const updateContract = async () => {
        await ZENTAL.updateZental(bikeData.zentalId, changeZental)
            .then(res => {
                if (res.status == "SUCCESS") {
                    openAlert({ title: "성공", body: "계약이 변경 되었습니다.", type: "primary" })
                    resetModals()
                    getBikeData()
                } else {
                    openAlert({ title: "실패", body: res.resultMessage, type: "warning" })
                    return true
                }
            })
            .catch(err => {
                console.log(err)
                // * 에러라면 return true
                return true
            })
    }
    /**
     * 모달 취소 / 종료 시 데이터 초기화
     * @param type string | number
     */
    const resetModals = () => {
        setChangeZental(prev => ({
            ...prev,
            startDt: bikeData.serviceStartDt,
            endDt: bikeData.serviceEndDt,
            zentalId: bikeData.zentalId,
            modelId: bikeData.modelId,
        }))
        setModelModalOpen(false)
        setPeriodModalOpen(false)
    }

    /**
     * startDt 정규식
     */
    const regexAction = () => {
        const regex = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
        if (!regex.test(changeZental.startDt)) {
            openAlert({ title: "실패", body: "유효하지 않은 날짜 형식입니다.", type: "warning" })
            return true
        }
    }
    /**
     * 종료일 계산
     */
    const calculatePeorid = () => {
        if (regexAction()) {
            return true
        }
        const model = zentalModel.find(item => item.modelId == bikeData?.modelId)

        if (!model) {
            openAlert({ title: "실패", body: "사용이 중지 된 모델은 계약일을 변경 할 수 없습니다.", type: "warning" })
            return true
        }

        const endDt = addWeeks(parseISO(changeZental.startDt), model?.modelPeriod || 0)

        setChangeZental(prev => ({
            ...prev,
            endDt: format(endDt, "yyyy-MM-dd"),
        }))
    }
    /**
     * model 선택 목록 생성
     */
    const optionList = useMemo(() => {
        let newArr: OptionGroupType[] = [{ label: "모델을 선택해주세요", value: -1 }]
        zentalModel.map((item: any) => {
            newArr.push({ label: `${item.modelName} (${item.price.toLocaleString()})`, value: item.modelId })
        })

        return newArr
    }, [zentalModel])

    useEffect(() => {
        if (bikeData) {
            setChangeZental(prev => ({
                ...prev,
                startDt: bikeData.serviceStartDt,
                endDt: bikeData.serviceEndDt,
                zentalId: bikeData.zentalId,
                modelId: bikeData.modelId,
            }))
        }
    }, [bikeData])

    return (
        <>
            <FlexColDiv
                gap="15px"
                flex="auto"
                margin="30px 0 0 0"
            >
                <FlexBetween
                    alignItems="center"
                    margin=" 0 0 1rem 0"
                >
                    <h4>계약자 정보</h4>
                    <FlexDiv gap="8px">
                        <Button
                            disabled={!bikeData?.riderNm}
                            onClick={() => {
                                setZentalModal(prev => ({
                                    ...prev,
                                    serviceHistoryState: true,
                                }))
                            }}
                            type="primary"
                            size="small"
                        >
                            서비스 이력
                        </Button>
                        {bikeData.status == 1 && (
                            <Button
                                type="secondary"
                                size="small"
                                onClick={() =>
                                    setZentalModal(prev => ({
                                        ...prev,
                                        extendMoalState: true,
                                    }))
                                }
                            >
                                연장
                            </Button>
                        )}
                    </FlexDiv>
                </FlexBetween>
                <TableForm>
                    <TableFormLabel sm={3}>계약자</TableFormLabel>
                    <TableFormValue sm={3}>{bikeData?.riderNm || "-"}</TableFormValue>
                    <TableFormLabel sm={3}>연장</TableFormLabel>
                    <TableFormValue sm={3}>{bikeData?.exCnt} 회</TableFormValue>
                    <TableFormLabel sm={3}>휴대폰</TableFormLabel>
                    <TableFormValue sm={3}>{bikeData?.riderNm ? bikeData?.phone : ""}</TableFormValue>
                    <TableFormLabel sm={3}>생년월일</TableFormLabel>
                    <TableFormValue sm={3}>{bikeData?.riderNm ? bikeData?.birthDt : ""}</TableFormValue>
                </TableForm>
                <FlexBetween
                    alignItems="center"
                    margin=" 0 0 1rem 0"
                >
                    <h4>계약 정보</h4>
                </FlexBetween>
                {!bikeData?.zentalId ? (
                    <SettingDiv>
                        <Button
                            onClick={() => {
                                getZentalRider()
                                getZentalServiceModel()
                            }}
                            type="tertiary"
                        >
                            계약 설정
                        </Button>
                    </SettingDiv>
                ) : (
                    <TableForm>
                        <TableFormLabel sm={3}>계약상품</TableFormLabel>
                        <TableFormValue sm={9}>
                            <div className="flex-between">
                                <div>
                                    <span style={{ marginRight: "10px" }}>{bikeData?.modelName}</span>
                                    {bikeData?.ztDiscountId ? (
                                        <Tag
                                            value="할인적용"
                                            size="small"
                                            color="var(--primary)"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <Button
                                    size="small"
                                    onClick={() => getNewZentalModel(bikeData.serviceStartDt, btnTypes.model)}
                                >
                                    상품 변경
                                </Button>
                            </div>
                        </TableFormValue>
                        <TableFormLabel sm={3}>보증금</TableFormLabel>
                        <TableFormValue sm={9}>
                            {`${Number(bikeData?.deposit).toLocaleString("ko-KR")}원`}
                        </TableFormValue>
                        <TableFormLabel sm={3}>계약시작</TableFormLabel>
                        <TableFormValue sm={3}>{bikeData?.serviceStartDt}</TableFormValue>
                        <TableFormLabel sm={3}>계약종료</TableFormLabel>
                        <TableFormValue sm={3}>
                            <div className="flex-between">
                                {bikeData?.serviceEndDt}
                                <Button
                                    size="small"
                                    onClick={() => getNewZentalModel(bikeData.serviceStartDt, btnTypes.period)}
                                >
                                    계약일 변경
                                </Button>
                            </div>
                        </TableFormValue>
                        <TableFormLabel sm={3}>해지일자</TableFormLabel>
                        <TableFormValue sm={9}>{bikeData?.cancelDt || "-"}</TableFormValue>
                        <TableFormLabel sm={3}>결제 이력</TableFormLabel>
                        <TableFormValue sm={9}>
                            <div className="flex-between">
                                {paymentHistoryData.length > 0
                                    ? `${paymentHistoryData[0].serviceName}-${paymentHistoryData[0].paidAt}`
                                    : "결제 이력 없음"}
                                <div>
                                    <Button
                                        onClick={() => {
                                            setZentalModal(prev => ({
                                                ...prev,
                                                confirmModalState: {
                                                    ...prev.confirmModalState,
                                                    isOpen: true,
                                                    title: "젠탈 재결제",
                                                    contents: (
                                                        <div style={{ textAlign: "center" }}>
                                                            <h2 style={{ margin: "0" }}>
                                                                {bikeData.riderNm} / {bikeData.modelName} <br />
                                                                <br /> 재결제가 진행 됩니다.
                                                            </h2>
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <p
                                                                style={{
                                                                    color: "red",
                                                                    margin: "0",
                                                                    fontSize: "1.25rem",
                                                                }}
                                                            >
                                                                * 결제를 진행하면 취소 할 수 없고, <br />
                                                                누적 실패 횟수는 수동으로 차감해야 합니다.
                                                            </p>
                                                        </div>
                                                    ),
                                                    cb: () => rePayZental(),
                                                },
                                            }))
                                        }}
                                        disabled={
                                            paymentHistoryData[0]?.paymentStatus == "1" ||
                                            paymentHistoryData.length == 0
                                        }
                                        size="small"
                                    >
                                        재결제
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setZentalModal(prev => ({
                                                ...prev,
                                                paymentHistoryState: true,
                                            }))
                                        }}
                                        disabled={paymentHistoryData.length < 1}
                                        size="small"
                                    >
                                        결제이력 더보기
                                    </Button>
                                </div>
                            </div>
                        </TableFormValue>
                    </TableForm>
                )}
            </FlexColDiv>

            {/* 계약 상품 변경 모달 */}
            <Modal
                title="계약 상품 변경"
                open={modelModalOpen}
                onCancel={() => resetModals()}
                onClose={() => resetModals()}
                type="confirm"
                onConfirm={() => validation(btnTypes.model)}
            >
                <Row gap="10px">
                    <Col sm={12}>
                        <LabelForm
                            label={"현재 계약 상품"}
                            labelCol={3}
                        >
                            <Input
                                name="currentProduct"
                                readonly
                                defaultValue={bikeData?.modelName}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <LabelForm
                            label={"변경할 계약 상품"}
                            labelCol={3}
                        >
                            <Select
                                searchable
                                options={optionList}
                                defaultValue={-1}
                                onChange={(label, value) => {
                                    setChangeZental(prev => ({
                                        ...prev,
                                        modelId: Number(value),
                                    }))
                                }}
                            />
                        </LabelForm>
                    </Col>
                </Row>
            </Modal>

            {/* 계약일 변경 모달 */}
            <Modal
                title="계약일 변경"
                open={periodModalOpen}
                onCancel={() => resetModals()}
                onClose={() => resetModals()}
                type="confirm"
                onConfirm={() => validation(btnTypes.period)}
            >
                <Row gap="10px">
                    <Col sm={12}>
                        <LabelForm
                            label={"시작일"}
                            labelCol={3}
                        >
                            <div className="flex-between">
                                <CustomDatePicker width={"90%"}>
                                    <ReactDatePicker
                                        selected={new Date(changeZental.startDt || bikeData?.serviceStartDte)}
                                        onChange={(date: any) =>
                                            setChangeZental(prev => ({
                                                ...prev,
                                                startDt: format(date, "yyyy-MM-dd"),
                                            }))
                                        }
                                        locale={ko} // 한글로 변경
                                        selectsStart
                                        dateFormat="yyyy-MM-dd"
                                        showPopperArrow={false} // 화살표 변경
                                        customInput={<DataPickerInput />}
                                    />
                                </CustomDatePicker>
                                <Tooltip
                                    content={
                                        <div>
                                            종료일 계산하기
                                            <br />* 계산하지 않아도 자동으로 변경 됩니다
                                        </div>
                                    }
                                    placement="top"
                                >
                                    <Button
                                        type="tertiary"
                                        circle
                                        onClick={() => calculatePeorid()}
                                    >
                                        <Icon.Check />
                                    </Button>
                                </Tooltip>
                            </div>
                        </LabelForm>
                    </Col>

                    <Col sm={12}>
                        <LabelForm
                            label={"종료일"}
                            labelCol={3}
                        >
                            <Tooltip
                                content="자동으로 계산 되는 필드 입니다 (임의 수정 불가)"
                                placement="bottom"
                            >
                                <Input
                                    name="endDt"
                                    readonly
                                    value={changeZental.endDt || bikeData?.serviceEndDt}
                                />
                            </Tooltip>
                        </LabelForm>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default ContractForm

const btnTypes: ObjectType = { model: "model", period: "period" }
