import { FW_QUERY_KEY } from "@common/queryKey"
import { getFwHwCode } from "@common/util"
import useAlert from "@context/alert/useAlert"
import CommonModal from "@pages/Assets/Components/CommonModal"
import FirmBikeSearchModal from "@pages/Assets/Components/FirmBikeSearchModal"
import FirmVersionUpdateModal from "@pages/Assets/Components/FirmVersionUpsertModal"

import { ASSETS_FIRM } from "@pages/Assets/constants"
import { ConfirmModalType } from "@pages/Assets/types"
import { FlexDiv, Text } from "@styles"
import { useQueryClient } from "@tanstack/react-query"
import { FirmwareInfo } from "@type/firmHardType"
import { FirmwareList } from "@type/responseType"
import { Button, DataTable, Icon, Loading, Modal, PageHeader, Tooltip } from "p-ui"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { useDeleteFirm, useGetFirmHistory, useUpsertFirm } from "src/queries/firmhard"

const FirmwareDetail = () => {
    const location = useLocation()
    const firmwareType = new URLSearchParams(location.search).get("type") as string
    const deviceType = new URLSearchParams(location.search).get("device") as string

    const { deviceTypeCode, firmwareTypeCode } = getFwHwCode(deviceType, firmwareType)

    const { openAlert } = useAlert()
    const queryClient = useQueryClient()
    const [ischecked, setIsChecked] = useState(false)
    const [modalOpen, setModalOpen] = useState<ConfirmModalType>({
        isOpen: false,
        title: "confirm",
        contents: `<div>test</div>`,
        width: 800,
        onClose: () => {
            setModalOpen(prev => ({
                ...prev,
                isOpen: false,
                title: "confirm modal",
                contents: "confirm modal contents",
                cb: () => {},
            }))
            setIsChecked(false)
            clearParam()
        },
        cb: () => {},
    })
    const [firmwareUpdateParam, setFirmwareUpdateParam] = useState<FirmwareInfo>({
        description: "",
        developer: "",
        deviceType: deviceTypeCode as number,
        firmwareId: 0,
        firmwareType: firmwareTypeCode as number,
        releaseDt: "",
        status: 1,
        version: "",
    })

    const [firmwareId, setFirmwareId] = useState<number>()
    const { data: firmwareHistoryData, isFetching } = useGetFirmHistory("bike", firmwareType?.toLocaleLowerCase())
    const { refetch: upsertFirmware } = useUpsertFirm(firmwareUpdateParam)
    const { refetch: deleteFirmware } = useDeleteFirm(firmwareId as number)

    const checkedValidation = () => {
        setIsChecked(true)
    }
    const clearParam = () => {
        setFirmwareUpdateParam({
            description: "",
            developer: "",
            deviceType: deviceTypeCode as number,
            firmwareId: 0,
            firmwareType: firmwareTypeCode as number,
            releaseDt: "",
            status: 0,
            version: "",
        })
    }
    const changeTypeName = (type: string) => {
        switch (type) {
            case "BIKE_FW":
                return "바이크"
            case "STATION_FW":
                return "스테이션"
            case "BATTERY_FW":
                return "배터리"
        }
    }

    const deleteFirm = () => {
        deleteFirmware().then((res: any) => {
            if (res.status == "success") {
                openAlert({
                    title: "성공",
                    body: "펌웨어 삭제 성공.",
                    type: "primary",
                })
                queryClient.invalidateQueries({
                    queryKey: [FW_QUERY_KEY.GET_HISTORY, "bike", firmwareType?.toLocaleLowerCase()],
                })

                setModalOpen(prev => ({
                    ...prev,
                    isOpen: false,
                }))
            } else {
                openAlert({
                    title: "실패",
                    body: "삭제 실패.",
                    type: "warning",
                })
            }
        })
    }

    const upsertFirm = () => {
        upsertFirmware().then(res => {
            if (res.status == "success") {
                openAlert({
                    title: "성공",
                    body: "펌웨어 업데이트 성공.",
                    type: "primary",
                })
                queryClient.invalidateQueries({
                    queryKey: [FW_QUERY_KEY.GET_HISTORY, "bike", firmwareType?.toLocaleLowerCase()],
                })
                clearParam()
                setIsChecked(false)
                setModalOpen(prev => ({
                    ...prev,
                    isOpen: false,
                }))
            } else {
                openAlert({
                    title: "실패",
                    body: "업데이트 실패.",
                    type: "warning",
                })
            }
        })
    }
    let data = firmwareHistoryData?.map((item: any) => {
        return {
            ...item,
            firmwareType: item?.firmwareType,
            version: item?.version,
            developer: item?.developer,
            description:
                item?.description?.length > 15 ? (
                    <Tooltip
                        placement={"top"}
                        content={
                            <ul style={{ padding: 0 }}>
                                {item?.description.length > 20
                                    ? item?.description.match(/.{1,20}/g).map((text: any) => {
                                          return <li>{text}</li>
                                      })
                                    : item?.description}
                            </ul>
                        }
                    >
                        <div style={{ cursor: "pointer" }}> {item?.description.substr(0, 15) + "..."}</div>
                    </Tooltip>
                ) : (
                    item?.description
                ),
            releaseDt: item?.releaseDt?.replace(/\.\d+$/, ""),
            deviceType: changeTypeName(item?.deviceType),
            search: (
                <Button
                    size="small"
                    type="secondary"
                    onClick={() => {
                        setModalOpen(prev => ({
                            ...prev,
                            isOpen: true,
                            width: 700,
                            title: "바이크 조회",
                            contents: (
                                <FirmBikeSearchModal
                                    firmwareId={item?.firmwareId}
                                    deviceType={changeTypeName(item?.deviceType)}
                                    version={item?.version}
                                    firmwareType={item?.firmwareType}
                                />
                            ),
                        }))
                    }}
                >
                    바이크 조회
                </Button>
            ),
            update: (
                <FlexDiv justifyContent="center">
                    <Button
                        size="small"
                        compact
                        type="primary"
                        onClick={() => {
                            setModalOpen(prev => ({
                                ...prev,
                                isOpen: true,
                                width: 600,
                                title: "펌웨어 버전 수정",
                                contents: (
                                    <FirmVersionUpdateModal
                                        deviceType={changeTypeName(item?.deviceType)}
                                        firmwareType={item?.firmwareType}
                                        firmwareInfo={item}
                                        setFirmwareUpdateParam={setFirmwareUpdateParam}
                                    />
                                ),
                                cb: checkedValidation,
                            }))
                        }}
                    >
                        <Icon.Edit
                            width={14}
                            height={14}
                        />
                    </Button>
                </FlexDiv>
            ),
            delete: (
                <FlexDiv justifyContent="center">
                    <Button
                        size="small"
                        compact
                        disabled={item.isBikeExist}
                        type="danger"
                        onClick={() => {
                            setFirmwareId(item?.firmwareId)
                            setModalOpen(prev => ({
                                ...prev,
                                isOpen: true,
                                width: 600,
                                title: "펌웨어 버전 삭제",
                                contents: <>펌웨어 버전을 삭제하시겠습니까?</>,
                                cb: deleteFirm,
                            }))
                        }}
                    >
                        <Icon.Delete
                            width={14}
                            height={14}
                        />
                    </Button>
                </FlexDiv>
            ),
        }
    })

    useEffect(() => {
        if (ischecked) {
            let allPropertiesFilled = true
            for (const key in firmwareUpdateParam) {
                if (key !== "description" && firmwareUpdateParam[key] === "") {
                    openAlert({
                        title: "확인",
                        body: "필수 입력값을 작성해 주세요.",
                        type: "warning",
                    })
                    setIsChecked(false)
                    allPropertiesFilled = false
                    break
                }
            }
            if (allPropertiesFilled) {
                upsertFirm()
            }
        }
    }, [ischecked])
    return (
        <div>
            <PageHeader
                title="자산관리"
                subTitle="바이크 상세 > 펌웨어 패치 이력"
            />

            <Text
                as={"h2"}
                fontWeight={700}
            >
                {firmwareType}
            </Text>
            {isFetching ? (
                <Loading open={isFetching} />
            ) : (
                <DataTable
                    headerRight={
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                setModalOpen(prev => ({
                                    ...prev,
                                    isOpen: true,
                                    width: 600,
                                    title: "펌웨어 버전 추가",
                                    contents: (
                                        <FirmVersionUpdateModal
                                            deviceType={changeTypeName(deviceType as string)}
                                            firmwareType={firmwareType}
                                            setFirmwareUpdateParam={setFirmwareUpdateParam}
                                        />
                                    ),
                                    cb: checkedValidation,
                                }))
                            }}
                        >
                            버전 추가
                        </Button>
                    }
                    pagination
                    columns={ASSETS_FIRM.firmHistoryColumn}
                    dataList={data}
                />
            )}
            <CommonModal modalOpen={modalOpen} />
        </div>
    )
}

export default FirmwareDetail
