import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"

import { Col, Icon, PageHeader, Row } from "p-ui"

import { FlexColDiv, FlexDiv, GridDiv, ImgDiv, Text } from "@styles"

import useAlert from "@context/alert/useAlert"

import { useGetBikeOne, useGetBikeMemo, useUpdateFavoriteBike, useDeleteFavoriteBike } from "src/queries/assets"

import BikeInfoForm from "@pages/Assets/Components/BikeInfoForm"

import Memo from "@pages/Assets/Components/Memo"
import TabMenu from "@pages/Lab/bike/components/TabMenu"
import { IconWrapper } from "../style"
import { getManagerId } from "@common/util"

const AssetsBikeDetail = () => {
    const location = useLocation()
    const serialNo = new URLSearchParams(location.search).get("serialNo")
    const navigate = useNavigate()
    const managerId = getManagerId()

    const { openAlert } = useAlert()
    const [isConcern, setIsConcern] = useState(false)
    const [favoriteParma, setFavoriteParam] = useState({
        deviceType: "bike",
        favoriteId: 0,
        managerId: 0,
        serialNo: "",
    })

    /**
     * 바이크 단건 조회
     * @param serialNo
     */
    const { data, isFetching, isError, error } = useGetBikeOne(serialNo as string, managerId as unknown as number)
    const { refetch } = useUpdateFavoriteBike(favoriteParma)
    const { refetch: deleteFavoriteBike } = useDeleteFavoriteBike(data?.favoriteId)
    useGetBikeMemo(serialNo as string)

    /**
     * 바이크 상태 업데이트
     * @param changeData
     */

    const updateFavoriteBike = () => {
        if (!isConcern) {
            refetch().then(res => {
                if (res.status == "success") {
                    openAlert({ title: "성공", body: "즐겨찾는 바이크에 저장되었습니다.", type: "primary" })
                } else {
                    openAlert({ title: "실패", body: "저장 실패.", type: "warning" })
                }
            })
        } else {
            deleteFavoriteBike().then(res => {
                if (res.status == "success") {
                    openAlert({ title: "성공", body: "즐겨찾는 바이크가 해제되었습니다.", type: "primary" })
                } else {
                    openAlert({ title: "실패", body: "수정 실패.", type: "warning" })
                }
            })
        }
    }

    useEffect(() => {
        if (!isFetching && !data) {
            openAlert({ title: "실패", body: "차량정보가 없어 목록페이지로 돌아갑니다.", type: "warning" })
            setTimeout(() => {
                navigate("/assets/bike")
            }, 1300)
        }
        if (!isFetching && !isError) {
            setIsConcern(data?.favoriteId ? true : false)
            setFavoriteParam({
                ...favoriteParma,
                favoriteId: data?.favoriteId,
                managerId: managerId as unknown as number,
                serialNo: serialNo as string,
            })
        }
    }, [isFetching, isError, error])

    return (
        <FlexColDiv flex="auto">
            <PageHeader
                title="자산관리"
                subTitle="바이크 상세"
            />
            <FlexColDiv maxWidth="1100px">
                <Row gap={30}>
                    <Col md={12}>
                        <FlexDiv
                            gap="10px"
                            alignItems="center"
                        >
                            <Text
                                bold
                                as={"h3"}
                            >
                                차량 정보
                            </Text>
                            <IconWrapper
                                onClick={() => {
                                    setIsConcern(!isConcern)
                                    updateFavoriteBike()
                                }}
                            >
                                {isConcern ? (
                                    <Icon
                                        icon={"StarFill"}
                                        fill="#F9DE51"
                                    />
                                ) : (
                                    <Icon
                                        icon={"Star"}
                                        fill="#7C7B7B"
                                    />
                                )}
                            </IconWrapper>
                        </FlexDiv>
                        <GridDiv
                            gap="20px"
                            gridCol="2fr 9fr"
                        >
                            <FlexColDiv justifyContent="center">
                                <ImgDiv
                                    size="200px"
                                    backGroundColor="#f8f8f8"
                                    borderRadius="4px"
                                    backGroundImg={data?.bikeOne.s3Url}
                                ></ImgDiv>
                            </FlexColDiv>
                            <Memo />
                        </GridDiv>
                    </Col>
                    <Col md={12}>
                        <BikeInfoForm />
                    </Col>
                    <Col md={12}>
                        <TabMenu />
                    </Col>
                </Row>
            </FlexColDiv>
        </FlexColDiv>
    )
}

export default AssetsBikeDetail
