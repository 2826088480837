import CustomDatePicker from "@components/widgets/customDatePicker"
import { DataPickerInput, FlexColDiv, Text } from "@styles"
import { ko } from "date-fns/locale"
import { Input, LabelForm, Select, Type } from "p-ui"
import React, { useEffect, useState } from "react"
import ReactDatePicker from "react-datepicker"

import ZENTAL from "src/apis/zental"
import { depositOptions, discountOptions } from "./ZentalConstant"
import useAlert from "@context/alert/useAlert"
import { useRecoilState } from "recoil"
import { extendState } from "src/store/zental"
import { format } from "date-fns"

interface extandFormProps {
    bikeData: any
}

const ExtandForm = (props: extandFormProps) => {
    const { openAlert } = useAlert()

    const { bikeData } = props

    const [selectedYM, setSelectedYM] = useState<Date>(new Date(bikeData.serviceEndDt))
    const [zentalPlan, setZentalPlan] = useState<Type.OptionType[]>([{ label: "선택해주세요", value: -1 }])
    const [extendRecoilState, setExtendState] = useRecoilState(extendState)

    // * 플랜 list 가져오기
    const getZentalServiceModel = async () => {
        await ZENTAL.getServiceModel().then(res => {
            if (res.status === "SUCCESS") {
                let newArr = res.resultData.map((item: any) => ({
                    label: `${item.modelName} (${item.price.toLocaleString()})`,
                    value: item.modelId,
                }))
                newArr.unshift({label: "선택해주세요", value: -1})
                setZentalPlan(newArr)
            } else {
                openAlert({ title: "ERROR", body: res.resultMessage, type: "warning" })
            }
        })
    }

    useEffect(() => {
        setExtendState(prev => ({ ...prev, ["startDt"]: format(selectedYM, "yyyy-MM-dd") }))
    }, [selectedYM])

    useEffect(() => {
        getZentalServiceModel()
        setExtendState(prev => ({ ...prev, ["zentalId"]: bikeData.zentalId }))
    }, [])

    return (
        <FlexColDiv gap="5px">
            <LabelForm
                label="계약자"
                required
            >
                <Input
                    defaultValue={bikeData.riderNm}
                    disabled
                />
            </LabelForm>
            <LabelForm
                label="연장플랜"
                required
            >
                <Select
                    options={zentalPlan}
                    defaultValue={extendRecoilState.modelId}
                    onChange={(label, value) => {
                        setExtendState(prev => ({ ...prev, ["modelId"]: value }))
                    }}
                />
            </LabelForm>
            <LabelForm
                label="할인액"
                required
            >
                <Select
                    options={discountOptions}
                    searchable
                    defaultValue={-1}
                    onChange={(label, value) => {
                        setExtendState(prev => ({ ...prev, ["discount"]: value }))
                    }}
                />
            </LabelForm>
            <LabelForm
                label="보증금"
                required
            >
                <Select
                    options={depositOptions}
                    defaultValue={depositOptions[0].value}
                    onChange={(label, value) => {
                        setExtendState(prev => ({ ...prev, ["deposit"]: value }))
                    }}
                />
            </LabelForm>
            <LabelForm
                label="시작일자"
                required
            >
                <CustomDatePicker
                    width="100%"
                    height={"50px"}
                >
                    <ReactDatePicker
                        selected={selectedYM}
                        locale={ko} // 한글로 변경
                        onChange={date => setSelectedYM(date || selectedYM)}
                        dateFormat="yyyy-MM-dd"
                        minDate={new Date(bikeData.serviceEndDt)}
                        startDate={new Date(bikeData.serviceEndDt)}
                        customInput={<DataPickerInput height={"50px"} />}
                    />
                </CustomDatePicker>
            </LabelForm>
            <Text
                color="red"
                bold
                fontSize="15px"
                margin="10px 0 0 0 "
            >
                * 연장 시 누적 실패 횟수는 초기화 됩니다.
            </Text>
        </FlexColDiv>
    )
}

export default ExtandForm
