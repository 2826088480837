import React from "react"

import styled from "styled-components"

import { TableForm, TableFormLabel, TableFormValue, Button } from "p-ui"
import Utils from "@utils"

import { MembershipBikeInfo, MembershipInfo } from "@type/membershipType"

interface BikeInfoProps {
    membershipInfo: MembershipInfo | undefined
    setOpenExchangeModal: (e: boolean) => void
    membershipBikeInfo?: MembershipBikeInfo
}

const BikeInfo = (props: BikeInfoProps) => {
    const { membershipBikeInfo, setOpenExchangeModal } = props

    return (
        <>
            <h3>차량정보</h3>
            <TableForm>
                <TableFormLabel sm={3}>차대 번호</TableFormLabel>
                <TableFormValue sm={9}>{membershipBikeInfo?.biVin}</TableFormValue>
                <TableFormLabel sm={3}>차량 번호</TableFormLabel>
                <TableFormValue sm={9}>{membershipBikeInfo?.vinNo}</TableFormValue>
                <TableFormLabel sm={3}>시리얼 번호</TableFormLabel>
                <TableFormValue sm={3}>{membershipBikeInfo?.serialNo}</TableFormValue>
                <TableFormLabel sm={3}>교환 상태</TableFormLabel>
                <TableFormValue sm={3}>
                    <div className="flex-between">
                        <Text authPermitAt={membershipBikeInfo?.authPermitAt?.toLocaleLowerCase()}>
                            {membershipBikeInfo?.authPermitAt?.toLocaleLowerCase() === "y" ? "가능" : "불가능"}
                        </Text>
                        <Button
                            onClick={() => {
                                setOpenExchangeModal(true)
                            }}
                            type="default"
                            size="small"
                        >
                            변경
                        </Button>
                    </div>
                </TableFormValue>

                <TableFormLabel sm={3}>통신상태</TableFormLabel>
                <TableFormValue sm={3}>
                    <Text communicationStatus={membershipBikeInfo?.communicationStatus}>
                        {membershipBikeInfo?.communicationStatus}
                    </Text>
                </TableFormValue>
                <TableFormLabel sm={3}>마지막 통신 시간</TableFormLabel>
                <TableFormValue sm={3}>{membershipBikeInfo?.baseDt}</TableFormValue>
                <TableFormLabel sm={3}>전체 주행거리</TableFormLabel>
                <TableFormValue sm={9}>{Number(membershipBikeInfo?.totalMile).toLocaleString()}km</TableFormValue>

                <TableFormLabel sm={3}>구매자</TableFormLabel>
                <TableFormValue sm={3}>{membershipBikeInfo?.purchaserNm}</TableFormValue>
                <TableFormLabel sm={3}>닉네임</TableFormLabel>
                <TableFormValue sm={3}>{membershipBikeInfo?.goodsAliasNm}</TableFormValue>
                <TableFormLabel sm={3}>휴대폰 번호</TableFormLabel>
                <TableFormValue sm={9}>{Utils.formatPhone(membershipBikeInfo?.telNo as string)}</TableFormValue>
            </TableForm>
        </>
    )
}

export default BikeInfo

export const Text = styled.span<{ authPermitAt?: string; communicationStatus?: string }>`
    color: ${props => (props.authPermitAt === "n" || props.communicationStatus === "비정상") && "red"};
`
