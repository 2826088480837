import React, { useState, useEffect } from "react"

import { Col, Row } from "react-grid-system"
import { Button, Input, Modal, Select, TextArea, LabelForm } from "p-ui"
import { Type } from "p-ui"
import { FloatRightDiv } from "../Push/styles"
import { CommonDiv, FlexColDiv } from "@styles"

import apis from "@apis"

import useAlert from "@context/alert/useAlert"

import { PushTemplateType } from "@type/push"
import { onChangeParam } from "@type/common"
interface PushTemplateProps {
    pushTemplateOptionList: Type.OptionType[]
    pushTemplateList: PushTemplateType[]
    clickEvent: () => void
}

const PushTemplateModal = (props: PushTemplateProps) => {
    const { pushTemplateOptionList, pushTemplateList, clickEvent } = props
    const [modalPushTemplateOptionList, setModalPushTemplateOptionList] = useState<Type.OptionGroupType[]>([])
    const [selectedPushTemplate, setSelectedPushTemplate] = useState<number>(-1)
    const [sendType, setSendType] = useState<string>("noti")
    const [title, setTitle] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [buttonFormType, setButtonFormType] = useState<string>("add")
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    const { openAlert } = useAlert()

    function setButton(value: number) {
        if (value == -1) return setButtonFormType("add")
        else if (isEdit) return setButtonFormType("edit")
        else return setButtonFormType("action")
    }

    async function insertPushTemplate() {
        if (!title) return openAlert({ body: "제목을 작성해주세요", type: "warning" })

        if (!content) return openAlert({ body: "내용을 작성해주세요", type: "warning" })
        let params: PushTemplateType = {
            templateId: 0,
            title: title,
            type: sendType,
            content: content,
        }

        await apis.Push.insertPushTemplate(params).then(res => {
            openAlert({ body: "저장 성공", type: "primary" })
            clickEvent()
        })
    }

    async function updatePushTemplate() {
        if (!title) return openAlert({ body: "제목을 작성해주세요", type: "warning" })
        if (!content) return { body: "내용을 작성해주세요", type: "warning" }

        let params: PushTemplateType = {
            templateId: selectedPushTemplate,
            title: title,
            type: sendType,
            content: content,
        }
        await apis.Push.updatePushTemplate(params).then(res => {
            openAlert({ body: "수정 성공", type: "primary" })
            clickEvent()
        })
    }

    async function deletePushTemplate() {
        await apis.Push.deletePushTemplate(selectedPushTemplate).then(res => {
            openAlert({ body: "삭제 성공", type: "primary" })
            clickEvent()
            setSelectedPushTemplate(-1)
        })
    }

    function setTemplate(value: number) {
        setSelectedPushTemplate(value)
        const isDisalbed = value != -1
        setIsDisabled(isDisalbed)
        setIsEdit(false)

        const pushTemplate = pushTemplateList?.find((item: { templateId: number }) => {
            return item.templateId == value
        })

        if (pushTemplate) {
            setSendType(pushTemplate.type)
            setTitle(pushTemplate.title)
            setContent(pushTemplate.content)
        }

        setButton(value)
    }

    function closeAction(closeType: string) {
        setIsModalOpen(false)
    }

    const addButton = (
        <Button
            type="primary"
            onClick={() => {
                insertPushTemplate()
            }}
        >
            추가
        </Button>
    )

    const editButton = (
        <>
            <Button
                type="default"
                onClick={() => {
                    updatePushTemplate()
                }}
            >
                저장
            </Button>
            <Button
                type="warning"
                onClick={() => {
                    setIsEdit(false)
                    setTemplate(selectedPushTemplate)
                }}
            >
                취소
            </Button>
        </>
    )

    const saveButton = (
        <>
            <Button
                type="warning"
                onClick={() => {
                    setIsModalOpen(true)
                }}
            >
                삭제
            </Button>
            <Button
                type="default"
                onClick={() => setIsEdit(true)}
            >
                수정
            </Button>
        </>
    )

    useEffect(() => {
        setButton(selectedPushTemplate)
    }, [isEdit])

    useEffect(() => {
        if (content.length > 300) {
            alert("300자 이상 입력할 수 없습니다.")
            setContent(content.substring(0, 300))
        }
        const splitContent = content.split("\n")
        if (splitContent.length > 25) {
            alert("25줄 이상 입력할 수 없습니다.")
            setContent(splitContent.slice(0, 25).join("\n"))
        }
    }, [content])

    useEffect(() => {
        let updatePushTemplate = JSON.parse(JSON.stringify(pushTemplateOptionList))
        if (updatePushTemplate.length != 0) {
            updatePushTemplate[0].label = "템플릿 추가하기"
        }
        setModalPushTemplateOptionList(updatePushTemplate)
    }, [pushTemplateOptionList])

    return (
        <div>
            <Modal
                open={isModalOpen}
                title="삭제 확인"
                type="confirm"
                onConfirm={deletePushTemplate}
                onClose={() => closeAction("delete")}
            >
                <div>템플릿을 삭제하시겠습니까?</div>
            </Modal>

            <FlexColDiv gap="10px">
                <CommonDiv width="100%">
                    <LabelForm
                        label={"템플릿 선택"}
                        textAlign="center"
                    >
                        {modalPushTemplateOptionList.length > 0 && (
                            <Select
                                options={modalPushTemplateOptionList}
                                onChange={(label: string, value: any) => setTemplate(value)}
                                defaultValue={selectedPushTemplate}
                            />
                        )}
                    </LabelForm>
                </CommonDiv>
                <CommonDiv width="100%">
                    <LabelForm
                        label={"공지 유형"}
                        textAlign="center"
                    >
                        <Select
                            options={[
                                { label: "공지 (앱 설치된 전체 고객)", value: "noti" },
                                { label: "광고 (Push 알림 동의된 고객)", value: "ad" },
                            ]}
                            onChange={(label: string, value: any) => {
                                setSendType(value)
                            }}
                            defaultValue={sendType}
                            readonly={isDisabled && !isEdit}
                        />
                    </LabelForm>
                </CommonDiv>
                <CommonDiv width="100%">
                    <LabelForm
                        label={"제목"}
                        textAlign="center"
                    >
                        <Input
                            value={title}
                            name="title"
                            onChange={(e: onChangeParam) => {
                                setTitle(e.value.toString())
                            }}
                            readonly={isDisabled && !isEdit}
                        ></Input>
                    </LabelForm>
                </CommonDiv>
                <CommonDiv width="100%">
                    <LabelForm
                        label={"내용"}
                        textAlign="center"
                    >
                        <TextArea
                            value={content}
                            name="content"
                            rows={25}
                            onChange={(e: any) => {
                                setContent(e.target.value)
                            }}
                            readonly={isDisabled && !isEdit}
                        ></TextArea>
                    </LabelForm>
                </CommonDiv>
                <Row>
                    <Col sm={4}>
                        <div>{content.length + "/300"}</div>
                    </Col>
                    <Col sm={8}>
                        <FloatRightDiv>
                            {buttonFormType == "add" && addButton}
                            {buttonFormType == "edit" && editButton}
                            {buttonFormType == "action" && saveButton}
                        </FloatRightDiv>
                    </Col>
                </Row>
            </FlexColDiv>
        </div>
    )
}

export default PushTemplateModal
