export const depositOptions = [
    { label: "보증금을 선택해주세요", value: -1 },
    { label: "0", value: 0 },
    { label: "100,000", value: 100000 },
    { label: "200,000", value: 200000 },
    { label: "300,000", value: 300000 },
    { label: "400,000", value: 400000 },
    { label: "500,000", value: 500000 },
    { label: "600,000", value: 600000 },
]
export const discountOptions = [
    { label: "할인액을 선택해주세요", value: -1 },
    { label: "0", value: "0" },
    { label: "-500", value: 3500 },
    { label: "-1,000", value: 7000 },
    { label: "-1,500", value: 10500 },
    { label: "-2,000", value: 14000 },
    { label: "-2,500", value: 17500 },
    { label: "-3,000", value: 21000 },
    { label: "-3,500", value: 24500 },
    { label: "-4,000", value: 28000 },
    { label: "-4,500", value: 31500 },
    { label: "-5,000", value: 35000 },
]

export const b2bRiderColumn = [
    {
        title: "계정",
        dataIndex: "email",
    },
    {
        title: "이름",
        dataIndex: "riderNm",
    },
    {
        title: "차량번호",
        dataIndex: "bikeNo",
    },
    {
        title: "멤버십",
        dataIndex: "modelName",
    },
    {
        title: "연결 된 시리얼",
        dataIndex: "cnncBikeSn",
    },
    {
        title: "바이크 시리얼",
        dataIndex: "serialNo",
    },
]
export const StatusOptions = [
    { label: "전체", value: "-1" },
    { label: "미계약", value: "wait" },
    { label: "점검", value: "inspection" },
    { label: "결제 실패", value: "fail" },
    { label: "종료", value: "0" },
    { label: "정상", value: "1" },
    { label: "해지", value: "2" },
    { label: "해지 예정", value: "3" },
    { label: "이륜차 반납", value: "11" },
    { label: "점검 요청", value: "21" },
]
