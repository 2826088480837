import axios from "./config"
import { GOODS } from "./urls"
export default {
    /**
     * 바이크 authPermit 업데이트
     * @param bikeInfo {authPermitAt: "y or n", serialNo:""}
     * @returns
     */
    async udateBikeAuth(bikeInfo: any) {
        try {
            return await axios.host.post(GOODS.UPDATE_AUTH, bikeInfo)
        } catch (e: any) {
            return e.message
        }
    },
}
