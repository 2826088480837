import React, { useEffect, useState } from "react"

import { Col, DataTable, Modal, PageHeader, Row, Select } from "p-ui"
import { MembershipDetailPageWrap } from "../styles"
import { membershipDetailColumn } from "../constants"
import BikeInfo from "../components/BikeInfo"
import MembershipInfo from "../components/MembershipInfo"
import CardInfo from "../components/CardInfo"
import { useLocation, useMatch } from "react-router-dom"
import apis from "@apis"
import { MembershipTable, MembershipListAll } from "@type/membershipType"
import DetailContentWrap from "@pages/Rental/Components/DetailContentWrap"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"

const MembershipDetailPage = (): JSX.Element => {
    const location = useLocation()
    const bikeSerial = useMatch("/membership/detail/:serial")?.params.serial

    return (
        <MembershipDetailPageWrap>
            <PageHeader
                title="멤버십 관리"
                subTitle="상세페이지"
            />
            <Row>
                <Col md={6}>{bikeSerial}</Col>
            </Row>
        </MembershipDetailPageWrap>
    )
}

export default MembershipDetailPage

const membership = [
    {
        label: "스탠다드 플랜",
        value: "스탠다드 플랜",
    },
    {
        label: "언리미티드 플랜",
        value: "언리미티드 플랜",
    },
]
