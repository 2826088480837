import styled from "styled-components"

export const StatusBox = styled.div`
    border: solid 1px ${props => props.color ?? "var(--secondary-light)"};
    height: 80px;
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    background-color: white !important;
`
export const StatusBoxTitle = styled.div`
    font-weight: bold;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const StatusBoxContent = styled.div`
    margin-top: 15px;
    font-weight: bold;
    font-size: 0.8rem;
`
export const Circle = styled.div`
    border-radius: 50%;
    background: ${props => props.color};
    width: 15px;
    height: 15px;
    margin-right: 10px;
`
