import moment from "moment-timezone"

export const TIMEZONE = "Asia/Seoul"
export const FORMAT_DATE = "YYYY-MM-DD"
export const FORMAT_DATETIME = "YYYY-MM-DD HH:mm:ss"
export const FORMAT_SHORT_DATETIME = "YYYY-MM-DD HH:mm"

const DEFAULT_FORMAT = FORMAT_DATE

type TIME_UNIT = "s" | "m" | "h" | "d" | "w" | "M" | "y"

declare global {
    interface Date {
        format(formatString: string): string
        plus(amount: number, unit: TIME_UNIT): Date
        minus(amount: number, unit: TIME_UNIT): Date
    }
}

Date.prototype.format = function (format?: string) {
    return dateToString(this, format)
}

Date.prototype.plus = function (amount: number, unit: TIME_UNIT): Date {
    return moment(this).tz(TIMEZONE).add(amount, unit).toDate()
}

Date.prototype.minus = function (amount: number, unit: TIME_UNIT): Date {
    return moment(this).tz(TIMEZONE).subtract(amount, unit).toDate()
}

export const TODAY = moment().tz(TIMEZONE).toDate()

const dateToString = (date: Date, farmat?: string) => {
    return moment(date.getTime())
        .tz(TIMEZONE)
        .format(farmat || DEFAULT_FORMAT)
}

export const stringToDate = (date: string, farmat?: string): Date => {
    return moment(date, farmat || DEFAULT_FORMAT).toDate()
}

export const oneWeekAgo = (date?: Date | string) => {
    if (typeof date === "string") {
        date = stringToDate(date)
    }

    return moment(date).tz(TIMEZONE).subtract(1, "w").toDate()
}

export const oneMonthAgo = (date?: Date | string) => {
    if (typeof date === "string") {
        date = stringToDate(date)
    }

    return moment(date).tz(TIMEZONE).subtract(1, "M").toDate()
}

export const extractTimeFromDateString = (dateString: string | any) => {
    const stringToDate = new Date(dateString)
    const hours = stringToDate.getHours()
    const minutes = stringToDate.getMinutes()

    return { hours, minutes }
}

export function areDatesEqual(date1: Date, date2: Date) {
    const day1 = date1.getDate()
    const month1 = date1.getMonth()
    const day2 = date2.getDate()
    const month2 = date2.getMonth()

    return day1 === day2 && month1 === month2
}
