import { Button, CheckBox, Col, DataTable, Icon, Input, PageHeader, Row, Tag } from "p-ui"
import notice from "src/apis/notice"
import { useEffect, useMemo, useRef, useState } from "react"
import { Notice } from "@common/constatns"
import { FlexDiv, Label } from "@styles"
import { onChangeFilter } from "src/utils/filter"
import { updateActivePage, observPage } from "@common/util"
import { useLocation, useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import { PageRow } from "p-ui/dist/esm/types"

const NoticeList = () => {
    const tableRef = useRef<HTMLTableElement | null>(null)

    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchTextParam = searchParams?.get("searchText")
    const displayParam = searchParams?.get("display")
    const pinParam = searchParams?.get("pin")
    const noticeTypeParam = searchParams.get("noticeType")
    const pageParam = searchParams.get("page")
    const limitParam = searchParams.get("limit")

    const column: dataInfo[] = Notice

    const [noticeListData, setNoticeListData] = useState([] as any)
    const [noticeType, setNoticeType] = useState<noticeTypeData[]>([])
    const [filterData, setFilterData] = useState([] as any)
    const [pageRows, setPageRows] = useState<PageRow>(15)

    const [searchQuery, setSearchQuery] = useState("")
    const [clickedDisplayFilter, setClickedDisplayFilter] = useState<number[]>(
        displayParam ? displayParam.split(",").map(Number) : []
    )
    const [clickedPinFilter, setClickedPinFilter] = useState<number[]>(pinParam ? pinParam.split(",").map(Number) : [])
    const [clickedTypeFilter, setClickedTypeFilter] = useState<number[]>(
        noticeTypeParam ? noticeTypeParam.split(",").map(Number) : []
    )

    const [nowPage, setNowPage] = useState<any>(pageParam !== null ? pageParam : 1)

    /**
     * 공지사항 목록 조회
     */
    const getNoticeList = () => {
        notice
            .getNoticeList()
            .then(res => {
                if (res.status === "SUCCESS") {
                    setNoticeListData(res.resultData)
                }
            })
            .catch(error => console.log(error))
    }

    /**
     * 공지사항 카테고리(구분) 조회
     */
    const getType = (isSearchEmpty: boolean) => {
        setNowPage(Number(pageParam))
        notice
            .getType()
            .then(res => {
                if (res.status === "SUCCESS") {
                    setNoticeType(prev => {
                        const updatedNoticeType = [...prev]
                        res.resultData.forEach(item => {
                            const isDuplicate = updatedNoticeType.some(
                                existingItem => existingItem.value === item.codeId
                            )
                            if (!isDuplicate) {
                                updatedNoticeType.push({ label: item.codeLabel, value: item.codeId })
                            }
                        })
                        return updatedNoticeType
                    })
                    const values = res.resultData.map(item => item.codeId)
                    setClickedTypeFilter(isSearchEmpty ? values : splitParam(noticeTypeParam).map(Number))
                }
            })
            .catch(error => console.log(error))
    }

    const commonMappingFunction = (item: any) => ({
        ...item,
        title: <FlexDiv width="600px">{item.title}</FlexDiv>,
        pinStatus: item.pinStatus === 1 && (
            <Button
                type="black"
                size="small"
                compact
            >
                <Icon.Pin
                    width={16}
                    height={16}
                />
            </Button>
        ),
        displayFg: (
            <Tag
                value={item.displayFg === 0 ? "숨김" : "게시중"}
                type={item.displayFg === 0 ? "danger" : "primary"}
            />
        ),
        detail: (
            <Button
                size="small"
                type="secondary"
                onClick={() => {
                    navigate("" + item.noticeId, { state: { id: item.noticeId } })
                }}
            >
                상세보기
            </Button>
        ),
    })

    const searchList = useMemo(() => {
        if (searchQuery === "") {
            return noticeListData.filter((item: any) => {
                return (
                    clickedPinFilter.includes(item.pinStatus) &&
                    clickedTypeFilter.includes(item.noticeType) &&
                    clickedDisplayFilter.includes(item.displayFg)
                )
            })
        }

        if (clickedDisplayFilter.length === 0) {
            return []
        }
        const jsonData = noticeListData.map((item: any) => JSON.stringify(item))
        const data = noticeListData.filter((item: any, index: number) => {
            return (
                clickedPinFilter.includes(item.pinStatus) &&
                clickedTypeFilter.includes(item.noticeType) &&
                clickedDisplayFilter.includes(item.displayFg) &&
                jsonData[index].includes(searchQuery)
            )
        })
        return data
    }, [noticeListData, clickedDisplayFilter, clickedTypeFilter, clickedPinFilter, searchQuery])

    const filter = () => {
        if (location.search.length === 0) {
            setSearchParams({
                searchText: searchQuery,
                page: nowPage,
                limit: pageRows.toString(),
            })
            setClickedDisplayFilter(displayOptions.map(item => item.value))
            setClickedPinFilter(pinOptions.map(item => item.value))
        } else {
            setSearchParams({
                searchText: searchQuery,
                display: clickedDisplayFilter.toString(),
                noticeType: clickedTypeFilter.toString(),
                pin: clickedPinFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        }
        const searchedList = [...searchList]
        const filteredList = searchedList.map(commonMappingFunction)

        setFilterData(filteredList)
    }

    const handleEnterPress = (event: any) => {
        if (event.key === "Enter") {
            setNowPage(1)
            filter()
        }
    }

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)

            if (newActivePage === 0) {
                return setNowPage(Number(pageParam))
            }

            setNowPage(newActivePage)
        }
        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    const splitParam = (param: any) => {
        if (!param) {
            return []
        }
        return param.split(",")
    }

    useEffect(() => {
        getNoticeList()
        getType(location.search.length === 0)
        if (searchTextParam) {
            setSearchQuery(searchTextParam)
        }
        if (limitParam) {
            setPageRows(Number(limitParam) as unknown as PageRow)
        }
    }, [])

    useEffect(() => {
        filter()
    }, [noticeListData, clickedTypeFilter, clickedPinFilter, clickedDisplayFilter, nowPage, pageRows])

    return (
        <div>
            <PageHeader
                title={"공지사항 관리"}
                subTitle="공지사항 목록"
            />
            <Row gap={"20px"}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <Input
                        value={searchQuery}
                        placeholder="검색어를 입력하세요."
                        width="300px"
                        size="small"
                        onChange={(e: any) => setSearchQuery(e.value)}
                        onSearch={() => {
                            setNowPage(1)
                            filter()
                        }}
                        onKeyUp={handleEnterPress}
                    />
                </Col>
                <Col sm={1}>
                    <Label>게시 상태</Label>
                </Col>
                <Col md={11}>
                    <FlexDiv
                        alignItems="center"
                        gap="15px"
                    >
                        <CheckBox.Group
                            value={clickedDisplayFilter}
                            options={displayOptions}
                            onChange={(value: any) => {
                                const param = {
                                    clickedFilter: clickedDisplayFilter,
                                    checkList: value as [],
                                    defaultFilter: displayOptions.map((item: any) => item.value),
                                    setClickedFilter: setClickedDisplayFilter,
                                    allValue: 2,
                                }

                                onChangeFilter(param)
                                setNowPage(1)
                                filter()
                            }}
                        />
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>구분</Label>
                </Col>
                <Col md={11}>
                    <CheckBox.Group
                        value={clickedTypeFilter}
                        options={noticeType}
                        onChange={(value: any) => {
                            const param = {
                                clickedFilter: clickedTypeFilter,
                                checkList: value as [],
                                defaultFilter: noticeType.map((item: any) => item.value),
                                setClickedFilter: setClickedTypeFilter,
                                allValue: 0,
                            }
                            onChangeFilter(param)
                            setNowPage(1)
                            filter()
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Label>글고정</Label>
                </Col>
                <Col md={11}>
                    <CheckBox.Group
                        value={clickedPinFilter}
                        options={pinOptions}
                        onChange={(value: any) => {
                            const param = {
                                clickedFilter: clickedPinFilter,
                                checkList: value as [],
                                defaultFilter: pinOptions.map((item: any) => item.value),
                                setClickedFilter: setClickedPinFilter,
                                allValue: 2,
                            }
                            onChangeFilter(param)
                            setNowPage(1)
                            filter()
                        }}
                    />
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    columns={column}
                    dataList={filterData}
                    pagination
                    nowPage={nowPage}
                    pageRow={pageRows}
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(limitParam) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                    headerRight={
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => navigate("insert")}
                        >
                            등록하기
                        </Button>
                    }
                />
            </div>
        </div>
    )
}

export default NoticeList

export interface dataInfo {
    title: string
    dataIndex: string
    render?: (params: any) => void
}

const pinOptions = [
    { label: "해제", value: 0 },
    { label: "고정", value: 1 },
]

const displayOptions = [
    { label: "숨김", value: 0 },
    { label: "게시중", value: 1 },
]

export interface noticeTypeData {
    label: string
    value: number
}
