import React, { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useRecoilState, useRecoilValue } from "recoil"
import { ZentalUserParamState, ZentalUserFileState } from "src/store/zental"

import { Button, Icon, LabelForm, Type } from "p-ui"
import { Col, DataTable, Modal, PageHeader, Row, Select } from "p-ui"
import UserForm from "@pages/Rental/Components/UserForm"
import UserFormHeader from "@pages/Rental/Components/UserFormHeader"
import { Rental, zentalStatus } from "@pages/Rental/constants"
import Divider from "@components/widgets/Divider"
import { FlexDiv, Text, TextAlignDiv } from "@styles"

import ZENTAL from "src/apis/zental"

import useAlert from "@context/alert/useAlert"

import { paymentStatus } from "@common/constatns"

import { b2bRiderColumn } from "@pages/Rental/Components/ZentalConstant"
import { StringArrObjectType, UserOneDataStateType } from "@type/common"
import { UserHistoryType } from "@type/responseType"
import styled from "styled-components"

const UserDetailModal = {
    paymentHistory: false,
}

function UserDetailPage(props: any) {
    const location = useLocation()
    const navigate = useNavigate()
    const id = new URLSearchParams(location.search).get("id")

    const [zentalUserParam, setZentalUserParam] = useRecoilState(ZentalUserParamState)
    const zentalUserFile = useRecoilValue(ZentalUserFileState)

    const { openAlert } = useAlert()

    const [mode, setMode] = useState<string>("detail")
    const [zentalUserOneData, setZentalUserOneData] = useState<UserOneDataStateType>()
    const [userDeleteModalState, setUserDeleteModalState] = useState<boolean>(false)
    const [fileDeleteModalData, setFileDeleteModalData] = useState({ isOpen: false, fileId: 0, fileName: "" })
    const [bikeListOption, setBikeListOption] = useState<Type.OptionType[]>([{ label: "전체", value: "-1" }])
    const [selectedBike, setSelectedBike] = useState<string>("")
    const [paymentHistoryColumn, setPaymentHistoryColumn] = useState(Rental.paymentHistory)
    const [serviceHistoryColumn, setServiceHistoryColumn] = useState(Rental.userHistory2)
    const [userHistory, setUserHistory] = useState<UserHistoryType[]>([])
    const [paymentHistory, setPaymentHistory] = useState<StringArrObjectType[]>([])
    const [modalOpen, setModalOpen] = useState(UserDetailModal)

    const getUserData = async (id: number) => {
        await ZENTAL.getZentalUserOne(id).then(res => {
            if (res.status === "SUCCESS") {
                const userData = res.resultData?.user
                setZentalUserOneData(res.resultData)
                setZentalUserParam({
                    ...zentalUserParam,
                    addressDetail: userData.addressDetail,
                    addressRegion: userData.addressRegion,
                    addressRoad: userData.addressRoad,
                    birthDt: userData.birthDt,
                    comment: userData.comment,
                    gender: userData.gender,
                    licenseNo: userData.licenseNo,
                    userEmail: userData.userEmail,
                    userId: userData.userId,
                    userName: userData.userName,
                    userPhone: userData.userPhone,
                    userType: userData.userType,
                    partnerAccount: userData.partnerAccount,
                    zipCode: userData.zipCode,
                })
                getUserHistory(id, userData.userType)
            } else {
                openAlert({ title: "ERROR", body: res.resultMessage, type: "warning" })
                setTimeout(() => {
                    navigate(-1)
                }, 1300)
            }
        })
    }

    /**
     * user 이력 조회
     * @param id
     */
    const getUserHistory = (id: number, userType: number) => {
        ZENTAL.getUserHistory(id)
            .then(res => {
                if (res.status == "SUCCESS") {
                    const newArr = res.resultData.map((item: any) => {
                        return {
                            ...item,
                            paymentHistory: (
                                <IconDiv>
                                    <Icon.Document
                                        fill="var(--primary)"
                                        onClick={() => getZentalPaymentHistory(item.zentalId)}
                                    />
                                </IconDiv>
                            ),
                        }
                    })
                    const keys = Array.from(new Set(newArr.map((el: any) => el.bikeNo)))
                    if (userType == 1) {
                        // * bike list option 생성
                        const selectArr = keys.length > 0 ? keys.map((item: any) => ({ label: item, value: item })) : []
                        selectArr.unshift({ label: "전체", value: "-1" })
                        setBikeListOption(selectArr)
                        setSelectedBike(selectArr[0].value)
                    } else {
                        setSelectedBike("-1")
                    }

                    setUserHistory(newArr)
                } else {
                    openAlert({ title: "ERROR", body: res.resultMessage, type: "warning" })
                }
            })
            .catch(err => {
                openAlert({ title: "ERROR", body: err.message, type: "warning" })
            })
    }

    /**
     * 서비스 별 결제이력 조회
     * @param zentalId
     */
    const getZentalPaymentHistory = (zentalId: number) => {
        ZENTAL.getZentalPaymentHistory(zentalId)
            .then(res => {
                if (res.status == "SUCCESS") {
                    setPaymentHistory(res.resultData)
                    setModalOpen(prev => ({
                        ...prev,
                        paymentHistory: true,
                    }))
                } else {
                    openAlert({ title: "ERROR", body: res.resultMessage, type: "warning" })
                }
            })
            .catch(err => {
                openAlert({ title: "ERROR", body: err.message, type: "warning" })
            })
    }
    /**
     * user 삭제
     */
    const deleteUser = async () => {
        id &&
            (await ZENTAL.deleteZentalUser(Number(id)).then(res => {
                if (res.status === "SUCCESS") {
                    openAlert({ title: "삭제", body: "삭제 되었습니다.", type: "primary" })

                    setTimeout(() => {
                        navigate("/rental/user")
                    }, 1000)
                } else {
                    openAlert({ title: "실패", body: res.resultMessage, type: "warning" })
                }
            }))
    }

    /**
     * user 정보 업데이트
     */
    const updateUser = async () => {
        await ZENTAL.updateZentalUser(zentalUserParam).then(async res => {
            const formData = new FormData()
            if (zentalUserFile.length > 0) {
                zentalUserFile.map((item: any, i: number) => {
                    formData.append("formData", item.object)
                })
                formData.append("linkKey", res.resultData.userId)
                formData.append("linkInfo", "th_zt_user")
                await ZENTAL.uploadFileList("zental", formData).then(res => {
                    if (res.status === "SUCCESS") {
                        openAlert({ title: "등록", body: "등록 되었습니다.", type: "primary" })
                    }
                })
            } else {
                if (res.status === "SUCCESS") {
                    openAlert({ title: "수정", body: "수정 되었습니다.", type: "primary" })

                    getUserData(Number(id))
                }
            }
        })
    }

    /**
     * 유효성 검증
     * @returns
     */
    const onClickFormValidation = () => {
        //set form & message
        const updateForm = zentalUserParam
        const form = updateForm
        const formFields: string[] = ["userName", "userPhone", "addressRoad", "birthDt", "gender", "licenseNo"]

        const message: { [key: string]: string } = {
            userName: "이름을 입력해주세요",
            userPhone: "핸드폰 번호를 입력해주세요",
            addressRoad: "주소를 입력해주세요",
            birthDt: "생년월일을 입력해주세요",
            gender: "성별을 입력해주세요",
            licenseNo: "면허번호를 입력해주세요",
        }

        // check valid
        const field = formFields.find(field => !form[field])
        if (zentalUserParam.userType == 1 && form["partnerAccount"] == null) {
            return alert(message["partnerAccount"])
        }
        if (form["userPhone"].toString().length != 11) {
            return alert("핸드폰 번호 11자리양식이 틀립니다.")
        }
        if (form["birthDt"].toString().length != 8) {
            return alert("생년월일의 양식이 틀립니다.")
        }
        if (zentalUserParam.partnerAccount == "" && zentalUserParam.userType == 1) {
            return alert("master 계정을 연동해주세요")
        }
        if (field) {
            // action
            alert(message[field])
        } else {
            updateUser()
        }
    }

    /**
     * 첨부 파일 삭제
     * @param id fileId
     */
    const deleteAttachFiles = async (id: number) => {
        await ZENTAL.deleteFile("zental", id).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "삭제", body: "삭제 되었습니다.", type: "primary" })

                setZentalUserOneData((prev: any) => ({
                    ...prev,
                    attach: prev.attach.filter((file: any) => file.fileId !== id),
                }))
            }
        })
    }

    const convertPaymentHistoryColumn = () => {
        const newColumn = Rental.paymentHistory.map(item => {
            switch (item.dataIndex) {
                case "paymentStatus":
                    return {
                        ...item,
                        render: (item: any) => {
                            return <span>{paymentStatus[item]}</span>
                        },
                    }
                case "paymentAmount":
                    return {
                        ...item,
                        render: (item: any) => {
                            return <span>{item.toLocaleString("ko-KR")}</span>
                        },
                    }
                default:
                    return { ...item }
            }
        })
        setPaymentHistoryColumn(newColumn)
    }

    const convertColumn = (
        column: {
            title: string
            dataIndex: string
        }[]
    ) => {
        const newColumn = column.map(item => {
            switch (item.dataIndex) {
                case "status":
                    return {
                        ...item,
                        render: (item: any) => {
                            return <span>{zentalStatus[item]}</span>
                        },
                    }
                case "cancelDt":
                case "extendDt":
                    return {
                        ...item,
                        render: (item: any) => {
                            return <span>{item === null ? "-" : item}</span>
                        },
                    }
                default:
                    return {
                        ...item,
                    }
            }
        })
        setServiceHistoryColumn(newColumn)
    }

    const filterdUserHistory = useMemo(() => {
        if (selectedBike == "-1") {
            return userHistory
        } else {
            return userHistory.filter(it => it.bikeNo == selectedBike)
        }
    }, [selectedBike])

    useEffect(() => {
        setMode("detail")
        convertPaymentHistoryColumn()
        convertColumn(serviceHistoryColumn)
        if (id) {
            getUserData(Number(id))
        }
    }, [])

    return (
        <>
            <Modal
                type="confirm"
                open={fileDeleteModalData.isOpen}
                confirmText="삭제"
                onClose={() => {
                    setFileDeleteModalData({ ...fileDeleteModalData, isOpen: false, fileId: -1, fileName: "" })
                }}
                onConfirm={() => deleteAttachFiles(fileDeleteModalData.fileId)}
            >
                <FlexDiv
                    alignItems="center"
                    justifyContent="center"
                >
                    <TextAlignDiv align="center">
                        <h3>{fileDeleteModalData.fileName}</h3>
                        파일을 삭제 하시겠습니까?
                    </TextAlignDiv>
                </FlexDiv>
            </Modal>
            <Modal
                type="confirm"
                open={userDeleteModalState}
                confirmText="삭제"
                onConfirm={() => deleteUser()}
            >
                <FlexDiv
                    alignItems="center"
                    justifyContent="center"
                >
                    {zentalUserOneData?.user.userName}님을 삭제 하시겠습니까?
                </FlexDiv>
            </Modal>
            <div>
                <PageHeader
                    title="렌탈"
                    subTitle="고객 상세"
                />
                <UserFormHeader
                    onClickFormValidation={onClickFormValidation}
                    setMode={setMode}
                    deleteUser={setUserDeleteModalState}
                    mode={mode}
                />
                <UserForm
                    zentalUserOneData={zentalUserOneData}
                    setFileDeleteModalData={setFileDeleteModalData}
                    mode={mode}
                />
                {zentalUserOneData?.user.userType == 1 && (
                    <>
                        <Text
                            as="h3"
                            fontWeight={900}
                            margin="20px 0 20px 0"
                        >
                            B2B Rider
                        </Text>
                        <Divider />
                        <DataTable
                            numbering
                            columns={b2bRiderColumn}
                            dataList={zentalUserOneData.b2bRider}
                            pagination
                        />
                    </>
                )}

                <Text
                    as="h3"
                    fontWeight={900}
                    margin="20px 0 20px 0"
                >
                    이용서비스 내역
                </Text>
                <Divider />
                {zentalUserOneData?.user.userType == 1 ? (
                    <Row marginBottom={20}>
                        <Col sm={4}>
                            <LabelForm label="번호판">
                                <Select
                                    name="bike"
                                    options={bikeListOption}
                                    defaultValue={selectedBike}
                                    onChange={(label: string, value: any) => {
                                        setSelectedBike(value.toString())
                                    }}
                                />
                            </LabelForm>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}

                <DataTable
                    numbering
                    columns={serviceHistoryColumn}
                    dataList={filterdUserHistory}
                    pagination
                />
            </div>

            <Modal
                type="alert"
                title="서비스 별 결제 이력"
                // width="500px"
                open={modalOpen.paymentHistory}
                onClose={() => {
                    setModalOpen(prev => ({
                        ...prev,
                        paymentHistory: false,
                    }))
                }}
            >
                <DataTable
                    numbering
                    columns={paymentHistoryColumn}
                    dataList={paymentHistory}
                    pagination
                />
            </Modal>
        </>
    )
}

export default UserDetailPage

const IconDiv = styled.div`
    cursor: pointer;
`
