import { useEffect, useState } from "react"
import { useNavigate } from "react-router"

import { useRecoilValue, useResetRecoilState } from "recoil"
import { ZentalUserFileState, ZentalUserParamState } from "src/store/zental"

import UserForm from "@pages/Rental/Components/UserForm"
import { PageHeader, Button, Row, Col } from "p-ui"

import ZENTAL from "src/apis/zental"

import useAlert from "@context/alert/useAlert"

const RentalUserInsertPage = () => {
    const navigate = useNavigate()
    const zentalUserParam = useRecoilValue(ZentalUserParamState)
    const zentalUserFile = useRecoilValue(ZentalUserFileState)
    const resetUserParam = useResetRecoilState(ZentalUserParamState)

    const { openAlert } = useAlert()

    const [mode, setMode] = useState<string>("add")

    /**
     * 핸드폰 중복 검사
     * @returns boolean true : 중복 false : 미중복
     */
    const checkPhone = async () => {
        const userPhone = zentalUserParam.userPhone
        return await ZENTAL.duplicateCheckByPhone(userPhone).then(res => {
            if (res.status === "SUCCESS") {
                return res.resultData
            }
        })
    }

    const insertUser = async () => {
        const isDuplicate = await checkPhone()
        if (isDuplicate) {
            openAlert({ title: "중복", body: "이미 등록 된 핸드폰 번호 입니다.", type: "warning" })

            return
        }
        await ZENTAL.insertZentalUser(zentalUserParam).then(async res => {
            const formData = new FormData()
            if (zentalUserFile.length > 0) {
                zentalUserFile.map((item: any, i: number) => {
                    formData.append("formData", item.object)
                })
                formData.append("linkKey", res.resultData.userId)
                formData.append("linkInfo", "th_zt_user")
                await ZENTAL.uploadFileList("zental", formData).then(res => {
                    if (res.status === "SUCCESS") {
                        openAlert({ title: "등록", body: "등록 되었습니다.", type: "primary" })

                        setTimeout(() => {
                            navigate("/rental/user")
                        }, 1000)
                    }
                })
            } else {
                if (res.status === "SUCCESS") {
                    openAlert({ title: "등록", body: "등록 되었습니다.", type: "primary" })
                    setTimeout(() => {
                        navigate("/rental/user")
                    }, 1000)
                }
            }
        })
    }
    const validation = () => {
        // set form & message
        const insertForm = zentalUserParam
        const form = insertForm
        const formFields: string[] = ["userName", "userPhone", "addressRoad", "birthDt", "gender", "licenseNo"]
        const message: { [key: string]: string } = {
            userName: "이름을 입력해주세요",
            userPhone: "핸드폰 번호를 입력해주세요",
            addressRoad: "주소를 입력해주세요",
            birthDt: "생년월일을 입력해주세요",
            gender: "성별을 입력해주세요",
            licenseNo: "면허번호를 입력해주세요",
        }

        // check valid
        const field = formFields.find(field => !form[field])

        if (form["userPhone"].toString().length != 11) {
            return alert("핸드폰 번호 11자리양식이 틀립니다.")
        }
        if (form["birthDt"].toString().length != 8) {
            return alert("생년월일의 양식이 틀립니다.")
        }
        if (zentalUserParam.partnerAccount == "" && zentalUserParam.userType == 1) {
            return alert("master 계정을 연동해주세요")
        }
        if (field) {
            alert(message[field])
        } else {
            insertUser()
        }
    }

    useEffect(() => {
        resetUserParam()
    }, [])

    return (
        <>
            <PageHeader
                title="렌탈"
                subTitle="고객 등록"
            >
                <>
                    <Button
                        onClick={validation}
                        type="primary"
                    >
                        등록
                    </Button>
                </>
            </PageHeader>
            <Row>
                <Col sm={8}>
                    <UserForm mode={mode} />
                </Col>
            </Row>
        </>
    )
}

export default RentalUserInsertPage
