import { CenterTypes, StationTypes } from "@type/stationType"
import { atom, selector } from "recoil"

export const statuState = atom({
    key: "statusState",
    default: "all",
})

export const mapLevelState = atom({
    key: "mapLevelState",
    default: 7,
})

export const mapCenterState = atom({
    key: "mapCenterState",
    default: { lat: 37.5217854, lng: 127.0483083 },
})

export const stationParamState = atom<StationTypes>({
    key: "stationParamState",
    default: {
        stationName: "",
        addressRoad: "",
        addressRegion: "",
        addressDetail: "",
        status: "0",
        zipCode: "",
        stationType: "chrstn",
    },
})

export const centerParamState = atom<CenterTypes>({
    key: "centerParamState",
    default: {
        centerNm: "",
        telNo: "",
        centerType: "0",
        zipCode: "",
        centerId: 0,
        bisNo: "",
    },
})

export const stationImgState = atom({
    key: "stationImgState",
    default: null,
})

export const stationListPageState = atom({
    key: "stationListPageState",
    default: 1,
})

export const stationListPerPageState = atom({
    key: "stationListPerPageState",
    default: 10,
})
