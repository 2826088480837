import apis from "@apis"
import { QUERY_KEYS } from "@common/constatns"
import { shippmentStatusFolderName } from "@pages/Bbs/constants"
import { useMutation, useQuery } from "@tanstack/react-query"

export const useGetShippmentStatus = (linkInfo: String) => {
    return useQuery({
        queryKey: [QUERY_KEYS.SHIPPMENT_STATUS_GET_LIST, linkInfo],
        queryFn: () => apis.File.getFileList(linkInfo),
        select: (data: any) => data.resultData,
    })
}

export const useUploadFile = () => {
    const fn = (formData: FormData) => apis.File.uploadFileList(shippmentStatusFolderName, formData)
    return useMutation({
        mutationFn: fn,
    })
}
