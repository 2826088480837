import { formatYYYYMMDDhhmmss2 } from "@common/util"
import CustomDatePicker from "@components/widgets/customDatePicker"
import { DataPickerInput } from "@styles"
import { ko } from "date-fns/locale"
import { Col, Input, Label, Row, TextArea } from "p-ui"
import React, { useEffect, useState } from "react"
import ReactDatePicker from "react-datepicker"

const FirmVersionUpdateModal = (props: any) => {
    const { firmwareInfo, deviceType, firmwareType, setFirmwareUpdateParam } = props

    const currentDate = new Date()
    const [startDate, setStartDate] = useState(currentDate)

    useEffect(() => {
        if (firmwareInfo) {
            setFirmwareUpdateParam((prev: any) => {
                const { deviceType, firmwareType, ...restFirmwareInfo } = firmwareInfo
                return {
                    ...prev,
                    ...restFirmwareInfo,
                }
            })
            setStartDate(new Date(firmwareInfo.releaseDt))
        } else {
            setFirmwareUpdateParam((prev: any) => ({
                ...prev,
                releaseDt: formatYYYYMMDDhhmmss2(startDate as unknown as string),
            }))
        }
    }, [])
    return (
        <Row gap={10}>
            <Col sm={3}>
                <Label value={"대상"} />
            </Col>
            <Col sm={8}>
                <Input
                    value={deviceType}
                    disabled
                />
            </Col>
            <Col sm={3}>
                <Label value={"펌웨어"} />
            </Col>
            <Col sm={8}>
                <Input
                    value={firmwareType}
                    disabled
                />
            </Col>
            <Col sm={3}>
                <Label value={"*버전"} />
            </Col>
            <Col sm={8}>
                <Input
                    placeholder="버전명을 입력해주세요."
                    value={firmwareInfo && firmwareInfo.version}
                    onChange={e => {
                        setFirmwareUpdateParam((prev: any) => ({ ...prev, version: e.value }))
                    }}
                />
            </Col>

            <Col sm={3}>
                <Label value={"*배포일"} />
            </Col>
            <Col sm={8}>
                <CustomDatePicker
                    width="100%"
                    height="100%"
                >
                    <ReactDatePicker
                        selected={startDate}
                        onChange={(date: any) => {
                            setStartDate(date)
                            setFirmwareUpdateParam((prevInfo: any) => ({
                                ...prevInfo,
                                releaseDt: formatYYYYMMDDhhmmss2(date as unknown as string),
                            }))
                        }}
                        locale={ko} // 한글로 변경
                        selectsStart
                        dateFormat="yyyy.MM.dd (eee)" // 시간 포맷 변경
                        showPopperArrow={false} // 화살표 변경
                        customInput={<DataPickerInput />}
                    />
                </CustomDatePicker>
            </Col>
            <Col sm={3}>
                <Label value={"*개발자"} />
            </Col>
            <Col sm={8}>
                <Input
                    value={firmwareInfo && firmwareInfo?.developer}
                    placeholder="담당자 이름을 입력해주세요."
                    onChange={e => {
                        setFirmwareUpdateParam((prev: any) => ({ ...prev, developer: e.value }))
                    }}
                />
            </Col>
            <Col sm={3}>
                <Label value={"설명"} />
            </Col>
            <Col sm={8}>
                <TextArea
                    value={firmwareInfo && firmwareInfo.description ? firmwareInfo?.description : ""}
                    placeholder="변경점을 입력해주세요."
                    maxLength={200}
                    onChange={e => {
                        setFirmwareUpdateParam((prev: any) => ({ ...prev, description: e.target.value }))
                    }}
                />
            </Col>
        </Row>
    )
}

export default FirmVersionUpdateModal
