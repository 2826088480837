import React, { useEffect, useMemo, useState } from "react"
import { useLocation, useMatch } from "react-router"

import { FlexColDiv } from "@styles"
import { Input, LabelForm, Type, Select } from "p-ui"
import orderManage from "src/apis/orderManage"
import { OrderDetailInfo } from "@type/orderManageType"
import { OrderUpdateParam } from "@type/OrderType"

interface OrderEditForm {
    setOrderInfoParam: (e: any) => void
    orderResultData: OrderDetailInfo
    orderInfoParam: OrderUpdateParam[]
    setSelectedOptions: (e: any) => void
    selectedSubsidyList: number[]
    setSelectedSubsidyList: (e: any) => void
}

const OrderEditForm = (props: OrderEditForm) => {
    const {
        setOrderInfoParam,
        orderResultData,
        orderInfoParam,
        setSelectedOptions,
        selectedSubsidyList,
        setSelectedSubsidyList,
    } = props

    const orderId = useMatch("/order/detail/:orderId")?.params.orderId

    const orderItem = orderResultData.orderItemList[0]
    const optionList = orderItem.optionList

    const [optionsArr, setOptionsArr] = useState<Type.OptionGroupType[]>([])
    const [colorArr, setColorArr] = useState<Type.OptionGroupType[]>([])

    // * 지원 보조금 리스트
    const [subsidyList, setSubsidyList] = useState<any[]>([])

    const extractValues = useMemo(() => {
        const values: number[] = []

        optionsArr?.forEach((itemA: any) => {
            optionList?.forEach((itemB: any) => {
                if (itemA.label === itemB.productName) {
                    values.push(itemA.value)
                }
            })
        })

        return values
    }, [orderResultData, optionsArr])

    const getSelectOptions = () => {
        Promise.all([
            orderManage.getAccessoryList(),
            orderManage.getColorList(orderItem.modelId),
            orderManage.getSubsidyList(),
        ])
            .then(([accessoryData, colorData, subsidyData]) => {
                // * 악세서리 Setting
                if (accessoryData.status === "SUCCESS") {
                    setOptionsArr(accessoryData.resultData)
                } else {
                    console.log("accessoryData :", accessoryData.resultMessage)
                }

                // * 색상 Setting
                if (colorData.status === "SUCCESS") {
                    setColorArr(colorData.resultData)
                } else {
                    console.log("colorData :", colorData.resultMessage)
                }

                // * 보조금 Setting

                if (subsidyData.status === "SUCCESS") {
                    const subsidyOption = subsidyData.resultData.map((item: any) => {
                        return {
                            label: item.label,
                            value: item.modelId,
                        }
                    })

                    subsidyOption.unshift({
                        label: "선택안함",
                        value: 0,
                    })

                    setSubsidyList(subsidyOption)
                } else {
                    console.log("subsidyData :", subsidyData.resultMessage)
                }
            })
            .catch(error => console.log(error))
    }

    const onChangeColor = (optionId: number) => {
        const updatedOrderInfoParam = [...orderInfoParam]
        updatedOrderInfoParam[0] = { ...updatedOrderInfoParam[0], optionId: optionId }
        setOrderInfoParam(updatedOrderInfoParam)
    }

    useEffect(() => {
        getSelectOptions()
        setOrderInfoParam([
            {
                orderId: orderId,
                modelId: orderItem.modelId,
                optionId: orderItem.optionId,
                itemStatus: orderItem.orderStatus,
            },
        ])
    }, [])

    return (
        <FlexColDiv gap="10px">
            <LabelForm
                label="모델명"
                fullHeight
            >
                <Input
                    value={orderItem.productName}
                    readonly
                />
            </LabelForm>
            <LabelForm
                label="컬러"
                fullHeight
            >
                {colorArr.length > 1 && (
                    <Select
                        defaultValue={orderItem.optionId}
                        options={colorArr}
                        onChange={(label, value) => {
                            onChangeColor(value as number)
                        }}
                    />
                )}
            </LabelForm>
            <LabelForm
                label="옵션"
                fullHeight
            >
                {optionsArr.length > 1 && (
                    <Select
                        multiple
                        defaultValue={extractValues}
                        options={optionsArr}
                        onChange={(label, value) => {
                            setSelectedOptions(value)
                        }}
                    />
                )}
            </LabelForm>
            <LabelForm
                label="보조금"
                fullHeight
            >
                {optionsArr.length > 1 && (
                    <Select
                        options={subsidyList}
                        defaultValue={selectedSubsidyList?.[0] || 0}
                        onChange={(label, value) => {
                            // TODO :: 보조금 변경 기능

                            value == 0 ? setSelectedSubsidyList([]) : setSelectedSubsidyList([value])
                        }}
                    />
                )}
            </LabelForm>
        </FlexColDiv>
    )
}

export default OrderEditForm
