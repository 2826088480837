import React, { useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router"

import { Rental } from "@pages/Rental/constants"
import ZENTAL from "src/apis/zental"

import { FlexDiv, Label } from "@styles"

import { Button, Col, DataTable, Input, PageHeader, Row } from "p-ui"

import { downloadExcel, formatPhone, observPage, updateActivePage } from "@common/util"

import { ZtUser } from "@type/responseType"
import { onChangeParam } from "p-ui/dist/esm/types/input"
import { useRecoilState } from "recoil"
import { nowPageState } from "src/store/pageNation"
import { useSearchParams } from "react-router-dom"
import { PageRow } from "p-ui/dist/esm/types"

export interface dataInfo {
    title: string
    dataIndex: string
    render?: (params: any) => void
}

function RentalUserPage(props: any) {
    const tableRef = useRef<HTMLTableElement | null>(null)

    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchTextParam = searchParams?.get("searchText")
    const pageParam = searchParams.get("page")
    const limitParam = searchParams.get("limit")

    const [userData, setUserData] = useState<ZtUser[]>([])
    const [originData, setOriginData] = useState<ZtUser[]>([])
    const [filterList, setFilterList] = useState<ZtUser[]>([])
    const [searchQuery, setSearchQuery] = useState<string>("")

    const [pageRows, setPageRows] = useState<PageRow>(15)

    const [nowPage, setNowPage] = useState<any>(pageParam !== null ? pageParam : 1)

    const navigate = useNavigate()
    /**User list 불러옴 */
    const getUserList = async () => {
        await ZENTAL.getZentalUser().then(res => {
            if (res.status === "SUCCESS") {
                setOriginData(res.resultData)
                setUserData(convertData(res.resultData))
            } else {
                console.log(res.resultMessage)
            }
        })
    }

    const convertData = (data: ZtUser[]) => {
        return data.map((item: any) => {
            return {
                ...item,
                userPhone: formatPhone(item.userPhone),
                detail: (
                    <Button
                        size="small"
                        type="tertiary"
                        onClick={() => {
                            navigate({ pathname: "/rental/userdetail", search: "?id=" + item.userId })
                        }}
                    >
                        상세보기
                    </Button>
                ),
            }
        })
    }

    const filterData = useMemo(() => {
        if (searchQuery !== "") {
            return userData.filter(item => {
                return item.userName?.toLowerCase().includes(searchQuery.toLowerCase())
            })
        }

        return userData
    }, [userData, searchQuery])

    const handleOnSearch = () => {
        setNowPage(1)
        setFilterList(filterData)
        setSearchParams({ searchText: searchQuery, page: "1" })
    }

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)

            if (newActivePage === 0) {
                return setNowPage(Number(pageParam))
            }

            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    useEffect(() => {
        getUserList()
        if (searchTextParam) {
            setSearchQuery(searchTextParam)
        }
        if (limitParam) {
            setPageRows(Number(limitParam) as unknown as PageRow)
        }
        if (location.search.length === 0) {
            setSearchParams({ searchText: searchQuery, page: nowPage, limit: pageRows.toString() })
        }
    }, [])

    useEffect(() => {
        setFilterList(filterData)
        setSearchParams({ searchText: searchQuery, page: nowPage, limit: pageRows.toString() })
    }, [userData, nowPage, pageRows])

    return (
        <>
            <PageHeader
                title="렌탈"
                subTitle="고객 관리"
            />
            <Row gap="20px">
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv gap="5px">
                        <Input
                            value={searchQuery}
                            width="300px"
                            name="name"
                            placeholder="이름 검색"
                            onChange={(e: onChangeParam) => {
                                setSearchQuery(e.value as string)
                            }}
                            onSearch={() => {
                                handleOnSearch()
                            }}
                        />
                    </FlexDiv>
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    header={
                        <FlexDiv
                            alignItems="center"
                            gap="5px"
                        >
                            <Button
                                onClick={() => {
                                    downloadExcel(userData, excelHeader, "렌탈_유저_관리")
                                }}
                                size="small"
                            >
                                엑셀
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate(`/rental/userinsert`)
                                }}
                                type="primary"
                                size="small"
                            >
                                등록
                            </Button>
                        </FlexDiv>
                    }
                    nowPage={nowPage}
                    columns={Rental.rentalUser}
                    dataList={filterList}
                    pagination
                    pageRow={pageRows}
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(limitParam) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                    numbering
                />
            </div>
        </>
    )
}

export default RentalUserPage

const excelHeader = [
    { label: "성명", value: "userName" },
    { label: "전화번호", value: "userPhone" },
    { label: "면허번호", value: "licenseNo" },
    { label: "이메일", value: "userEmail" },
    { label: "메모", value: "comment" },
    { label: "생년월일", value: "birthDt" },
    { label: "성별", value: "gender" },
    { label: "구주소", value: "addressRegion" },
    { label: "도로명주소", value: "addressRoad" },
    { label: "상세주소", value: "addressDetail" },
]
