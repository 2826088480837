export const createVideoContainer = (htmlContent: string) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlContent, "text/html")
    const iframes = doc.querySelectorAll("iframe")

    iframes.forEach(iframe => {
        wrapWithDiv(iframe, "video-container")
    })

    return doc.body.innerHTML
}

const wrapWithDiv = (element: Element, className: string) => {
    const parentNode = element.parentNode
    if (parentNode && parentNode.nodeType === Node.ELEMENT_NODE) {
        const parentElement = parentNode as HTMLElement
        if (!parentElement.classList.contains(className)) {
            const div = document.createElement("div")
            div.className = className

            // <iframe> 앞에 아무것도 없으면 <p> 태그 넣어주기
            if (!element.previousElementSibling) {
                const paragraph = document.createElement("p")
                parentElement.insertBefore(paragraph, element)
            }

            parentElement.insertBefore(div, element)
            div.appendChild(element)
        }
    }
}
