import React, { useEffect, useState } from "react"
import { useLocation, useMatch } from "react-router-dom"

import styled from "styled-components"
import BikeInfo from "../components/BikeInfo"
import MembershipInfo from "../components/MembershipInfo"
import CardInfo from "../components/CardInfo"
import SuspendInfoView from "../components/SuspendInfoView"
import CustomDatePicker from "@components/widgets/customDatePicker"
import DetailContentWrap from "@pages/Rental/Components/DetailContentWrap"
import ReactDatePicker from "react-datepicker"
import { Button, Col, DataTable, Icon, Input, LabelForm, Loading, Modal, PageHeader, Row, Select, TextArea } from "p-ui"
import { DataPickerInput, MembershipDetailPageWrap } from "../styles"

import apis from "@apis"

import { ko } from "date-fns/locale"
import Utils from "@utils"
import { downloadExcel } from "@common/util"
import useAlert from "@context/alert/useAlert"

import {
    membershipAlert,
    membershipDetailColumn,
    mileColumn,
    membershipStatusOption,
    promotionColumn,
} from "../constants"

import { Type } from "p-ui"
import {
    MembershipCardInfo,
    MembershipInfo as MembershipInfoType,
    MembershipBikeInfo,
    MembershipMileInfo,
    MerchantHistoryInfo,
    SuspendInfoType,
    PromotionInfo,
    MembershipModel,
} from "@type/membershipType"
import { FlexDiv, Text } from "@styles"

const MembershipDetailPage = (): JSX.Element => {
    const bikeSerial = useMatch("/membership/detail/:serial")?.params.serial
    const currentDate = new Date()

    const [customerUid, setCustomerUid] = useState<string>("")
    const [purchaserSn, setPurchaserSn] = useState<number>()
    const [membershipId, setMembershipId] = useState<number>()
    const [authPermit, setAuthPermit] = useState<string>("")
    const [promotionId, setPromotionId] = useState<number>()
    const [MembershipGoods, setMembershipGoods] = useState<MembershipModel[]>()
    const [selectMembershipGoods, setSelectMembershipGoods] = useState<MembershipModel[]>([])

    const [openMembershipModal, setOpenMembershipModal] = useState<boolean>(false)
    const [openOfflinePaymentModal, setOpenOfflinePaymentModal] = useState<boolean>(false)
    const [openSuspendHistoryModal, setOpenSusPendHistoryModal] = useState<boolean>(false)
    const [openSuspendHistoryEditModal, setOpenSusPendHistorEdityModal] = useState<boolean>(false)

    const [openRefundPaymentModal, setOpenRefundPaymentModal] = useState<boolean>(false)
    const [openMembershipStatusModal, setOpenMembershipStatusModal] = useState<boolean>(false)
    const [openChangeModal, setOpenChangeModal] = useState<boolean>(false)
    const [openCardModal, setOpenCardModal] = useState<boolean>(false)
    const [openDistanceModal, setOpenDistanceModal] = useState<boolean>(false)
    const [openExchangeModal, setOpenExchangeModal] = useState<boolean>(false)
    const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false)
    const [openPromotionModal, setOpenPromotionModal] = useState<boolean>(false)
    const [openPromotionConfirmModal, setOpenPromotionConfirmModal] = useState<boolean>(false)
    const [openPromotionDeleteModal, setOpenPromotionDeleteModal] = useState<boolean>(false)

    const [dataLoading, setDataLoading] = useState<boolean>(false)

    const [membershipInfo, setMembershipInfo] = useState<MembershipInfoType>()
    const [suspendInfo, setSuspendInfo] = useState<SuspendInfoType>()
    const [membershipBikeInfo, setMembershipBikeInfo] = useState<MembershipBikeInfo>()
    const [cardInfo, setCardInfo] = useState<MembershipCardInfo>()
    const [cardOption, setCardOption] = useState<Type.OptionType[]>([])
    const [promotionOption, setPromotionOption] = useState<Type.OptionType[]>([])
    const [memberhsipOption, setMembershipOption] = useState<Type.OptionType[]>([])
    const [mileData, setMileData] = useState<MembershipMileInfo[]>([])
    const [merchantHistory, setMerchantHistory] = useState<MerchantHistoryInfo[]>([])
    const [promotionInfoList, setPromotionInfoList] = useState<PromotionInfo[]>([])
    const [statusList, setStatusList] = useState(membershipStatusOption)
    const [startDate, setStartDate] = useState<Date>(currentDate)
    const [endDate, setEndDate] = useState<Date>(currentDate)

    const [selectedChangeStatus, setSelectedChangeStatus] = useState<number>()
    const [selectedPromotionModel, setSelectedPromotionModel] = useState<number>(0)
    const [promotionMemo, setPromotionMemo] = useState<string>("")
    const [suspendStartDate, setSuspendStartDate] = useState<Date>(currentDate)
    const [suspendEndDate, setSuspendEndDate] = useState<Date>(currentDate)
    const [suspendLimitDate, setSuspendLimitDate] = useState<Date>(currentDate)
    const [suspendMemo, setSuspendMemo] = useState<string>("")
    const { openAlert } = useAlert()

    const recentCard = cardOption?.filter((item: any) => {
        return item.value === cardInfo?.customerUid
    })

    const getMembershipPurchaser = async () => {
        await apis.Membership.getMembershipOne(bikeSerial).then(res => {
            if (res.status === "SUCCESS") {
                const membershipInfo = res.resultData.membershipInfo
                const membershipBikeInfo = res.resultData.membershipBikeInfo
                const membershipCardInfo = res.resultData.membershipCardInfo
                const suspendInfo = res.resultData.suspendInfo

                setCardInfo(membershipCardInfo)
                setMembershipInfo(membershipInfo)
                setMembershipBikeInfo(membershipBikeInfo)
                setSuspendInfo(suspendInfo)

                setStartDate(new Date(membershipInfo?.startDate))
                setEndDate(new Date(membershipInfo?.endDate))

                const limitDate = new Date(currentDate)
                limitDate.setMonth(limitDate.getMonth() + 6)
                setSuspendEndDate(limitDate)
                setSuspendLimitDate(limitDate)
                if (suspendInfo != null) {
                    setSuspendStartDate(new Date(suspendInfo.startDate))
                    setSuspendEndDate(new Date(suspendInfo.endDate))
                    setSuspendMemo(suspendInfo.memo)
                }

                setMembershipId(membershipInfo?.membershipId as number)
                setPurchaserSn(membershipCardInfo?.userId as number)
                setAuthPermit(membershipBikeInfo?.authPermitAt?.toLocaleLowerCase())
                setStatusoption(membershipInfo, suspendInfo)
            }
        })
    }

    const getMembershipPromotionList = async () => {
        await apis.Membership.getMembershipPromotionList(bikeSerial).then(res => {
            if (res.status === "SUCCESS") {
                setPromotionInfoList([])
                setPromotionInfoList(
                    res.resultData.map((item: any) => {
                        return {
                            ...item,
                            promotionStatus:
                                item.promotionStatus === 0
                                    ? "적용대기"
                                    : item.promotionStatus === 1
                                    ? "주행거리적용대기"
                                    : "만료",
                            delete: (
                                <FlexDiv justifyContent="center">
                                    <Button
                                        compact
                                        size="small"
                                        onClick={() => checkpromotionDelete(item.promotionId)}
                                    >
                                        <Icon.Minus
                                            width={"10px"}
                                            height={"10px"}
                                        />
                                    </Button>
                                </FlexDiv>
                            ),
                        }
                    })
                )
            }
        })
    }

    const deleteMembershipPromotion = async (promotionId: number) => {
        await apis.Membership.deleteMembershipGoods(promotionId).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "삭제완료", body: membershipAlert.deleteSuccess, type: "primary" })
                getMembershipPromotionList()
                getMembershipPurchaser()
            } else {
                openAlert({ title: "삭제실패", body: membershipAlert.fail, type: "warning" })
                getMembershipPurchaser()
            }
        })
    }
    const getMerchantList = async () => {
        await apis.Membership.getMerchantList(bikeSerial).then(res => {
            if (res.status === "SUCCESS") {
                setMerchantHistory(
                    res.resultData.map((item: MerchantHistoryInfo) => {
                        return {
                            ...item,
                            amount: `${item.amount.toLocaleString()} 원`,
                            cardNumber: item?.cardNumber?.substring(
                                item?.cardNumber?.lastIndexOf("*") + 1,
                                item?.cardNumber?.lastIndexOf("*") + 1 + 4
                            ),
                            modelName: item.modelName.includes("환불") ? (
                                <Text color="red">{item.modelName}</Text>
                            ) : (
                                item.modelName
                            ),
                        }
                    })
                )
            }
        })
    }

    const getCardList = async () => {
        await apis.Membership.getCardList(purchaserSn).then(res => {
            if (res.status === "SUCCESS") {
                let cardList: Type.OptionType[] = [{ label: "선택", value: "selecet", disabled: true }]
                res.resultData.map((item: any) => {
                    cardList.push({
                        label: `${item.cardName}(${
                            item?.cardNumber?.substring(
                                item?.cardNumber?.lastIndexOf("*") + 1,
                                item?.cardNumber?.lastIndexOf("*") + 1 + 4
                            ) || "-"
                        })`,
                        value: item.customerUid,
                    })
                })
                setCardOption(cardList)
            }
        })
    }

    const getMile = async () => {
        setDataLoading(true)

        await apis.Membership.getDailyMile(
            bikeSerial,
            startDate.format(Utils.FORMAT_DATE),
            endDate.format(Utils.FORMAT_DATE)
        ).then(res => {
            if (res.status === "SUCCESS") {
                setDataLoading(false)
                setMileData(
                    res.resultData.map((item: any) => {
                        return {
                            ...item,
                            dailyMileK: `${item.dailyMile.toLocaleString()} km`,
                            totalMileK: `${item.totalMile.toLocaleString()} km`,
                        }
                    })
                )
            } else {
                return
            }
        })
    }

    const getGoodsList = async () => {
        await apis.Membership.getMembershipGoodsList(bikeSerial).then(res => {
            if (res.status === "SUCCESS") {
                let optionList: Type.OptionType[] = [{ label: "선택", value: 0, disabled: true }]
                res.resultData.map((item: any) => {
                    optionList.push({
                        label: item.modelName,
                        value: item.modelId,
                    })
                })
                setMembershipOption(optionList)
                setMembershipGoods(res.resultData)
            }
        })
    }
    const getPromotionList = async () => {
        await apis.Membership.getPromotionList().then(res => {
            if (res.status === "SUCCESS") {
                let optionList: Type.OptionType[] = [{ label: "선택", value: 0, disabled: true }]
                res.resultData.map((item: any) => {
                    optionList.push({
                        label: item.label,
                        value: item.modelId,
                    })
                })
                setPromotionOption(optionList)
            }
        })
    }

    const paymentMembership = async () => {
        setDataLoading(true)
        await apis.Membership.paymentMembership(bikeSerial).then(res => {
            if (res.status === "SUCCESS") {
                setDataLoading(false)
                openAlert({ title: "저장", body: membershipAlert.successPayment, type: "primary" })
                getMembershipPurchaser()
                getMerchantList()
            } else {
                setDataLoading(false)
                openAlert({ title: "실패", body: membershipAlert.failPayment, type: "warning" })
            }
        })
    }

    const updateMembership = async () => {
        await apis.Membership.updateMembership({
            membershipId: membershipId,
            nextGoodsId: selectMembershipGoods[0]?.modelId,
        }).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "저장",
                    body: membershipAlert.successChangeMembership,
                    type: "primary",
                })
                getMembershipPurchaser()
            } else {
                openAlert({
                    title: "실패",
                    body: membershipAlert.failChangeMembership,
                    type: "warning",
                })
            }
        })
    }

    const offlinePayment = async () => {
        await apis.Membership.offlinePayment(bikeSerial).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "저장", body: membershipAlert.successOfflinePayment, type: "primary" })
                getMembershipPurchaser()
                getMerchantList()
                getMembershipPromotionList()
            } else {
                openAlert({ title: "실패", body: membershipAlert.failOfflinePayment, type: "warning" })
            }
        })
    }

    const refundMembership = async () => {
        await apis.Membership.refundMembership(membershipId).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "저장", body: membershipAlert.successRefundPayment, type: "primary" })
                getMembershipPurchaser()
                getMerchantList()
            } else {
                openAlert({ title: "실패", body: res.resultMessage, type: "warning" })
            }
        })
    }

    const updateMembershipAuthasync = async () => {
        await apis.Membership.updateAuth({
            membershipId: membershipId,
            status: selectedChangeStatus,
            startDate: suspendStartDate.format(Utils.FORMAT_DATE),
            endDate: suspendEndDate.format(Utils.FORMAT_DATE),
            memo: suspendMemo,
        }).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "저장", body: membershipAlert.sussessChangeMembershipStatus, type: "primary" })
                getMembershipPurchaser()
            } else {
                openAlert({ title: "실패", body: membershipAlert.failChangeMembershipStatus, type: "warning" })
            }
        })
    }

    const updateBikeAuth = async () => {
        await apis.Membership.updateBikeAuth({
            serialNo: bikeSerial,
            authPermitAt: authPermit === "y" ? "n" : "y",
        }).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "저장",
                    body: membershipAlert.successChangeAuthBike,
                    type: "primary",
                })
                getMembershipPurchaser()
            } else {
                openAlert({ title: "실패", body: membershipAlert.failChangeAuthBike, type: "warning" })
            }
        })
    }

    const updateCard = async () => {
        await apis.Membership.updateCard({
            membershipId: membershipId,
            customerUid: customerUid,
        }).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "저장", body: membershipAlert.successChangeCard, type: "primary" })
                getMembershipPurchaser()
            } else {
                openAlert({ title: "실패", body: membershipAlert.failChangeCard, type: "warning" })
            }
        })
    }

    const membershipSuspend = async () => {
        await apis.Membership.membershipSuspend({
            serviceId: membershipId,
            suspendId: suspendInfo?.suspendId,
            startDate: suspendStartDate.format(Utils.FORMAT_DATE),
            endDate: suspendEndDate.format(Utils.FORMAT_DATE),
            memo: suspendMemo,
        }).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "저장", body: membershipAlert.successMembershipSuspend, type: "primary" })
                getMembershipPurchaser()
            } else {
                openAlert({ title: "실패", body: res.resultMessage, type: "warning" })
                getMembershipPurchaser()
            }
        })
    }

    const insertPromotionInfo = async () => {
        await apis.Membership.insertPromotionInfo({
            biSerialNo: bikeSerial,
            modelId: selectedPromotionModel,
            comment: promotionMemo,
        }).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "저장",
                    body: membershipAlert.save,
                    type: "primary",
                })
                getMembershipPurchaser()
                getMembershipPromotionList()
                setPromotionMemo("")
            } else {
                openAlert({ title: "실패", body: membershipAlert.fail, type: "warning" })
            }
        })
    }

    const setStatusoption = (membershipInfo: MembershipInfoType, suspendInfo: SuspendInfoType) => {
        let filtered

        switch (membershipInfo?.statusId) {
            case 1:
                filtered = suspendInfo
                    ? membershipStatusOption.filter((element: any) => element.value !== 1 && element.value !== 3)
                    : membershipStatusOption.filter((element: any) => element.value !== 1)
                setStatusList(filtered)
                break
            case 2:
                filtered = suspendInfo
                    ? membershipStatusOption.filter((element: any) => element.value < 2 || element.value === "select")
                    : membershipStatusOption.filter((element: any) => element.value < 2)
                setStatusList(filtered)
                break
            case 3:
                filtered = suspendInfo
                    ? membershipStatusOption.filter((element: any) => element.value == 1)
                    : membershipStatusOption
                setStatusList(filtered)
                break
            case 0:
                filtered = membershipStatusOption.filter((element: any) => element.value == 1)
                setStatusList(filtered)
                break
            default:
                filtered = suspendInfo
                    ? membershipStatusOption.filter((element: any) => element.value !== 3)
                    : membershipStatusOption
                setStatusList(filtered)
                break
        }
    }
    /**------------------ 내부함수 ------------------- */

    const checkCard = () => {
        if (customerUid === recentCard[0]?.value) {
            openAlert({ title: "실패", body: membershipAlert.checkCard, type: "warning" })

            return
        } else {
            updateCard()
        }
    }

    const checkMembership = () => {
        if (membershipInfo?.nextModelId === selectMembershipGoods[0]?.modelId) {
            openAlert({ title: "실패", body: membershipAlert.checkMembership, type: "warning" })

            return
        } else {
            updateMembership()
        }
    }

    const changingSuspendDate = (inputDate: Date) => {
        const limitDate = new Date(inputDate)
        setSuspendStartDate(inputDate)
        limitDate.setMonth(limitDate.getMonth() + 6)
        setSuspendLimitDate(limitDate)
        // if (suspendEndDate.getTime() > limitDate.getTime()) {
        setSuspendEndDate(limitDate)
        // }
        return
    }

    const checkpromotionDelete = (promotionId: number) => {
        setPromotionId(promotionId)
        setOpenPromotionDeleteModal(true)
    }

    const checkRemainTime = () => {
        if (membershipInfo) {
            // suspendStartDate는 시간을 00:00:00으로 설정
            let suspendDate = new Date(suspendStartDate)
            suspendDate.setHours(0, 0, 0, 0)

            // 초기 endDate를 구합니다.
            let endDate = new Date(membershipInfo.endDate)
            endDate.setHours(0, 0, 0, 0)

            // suspendDate를 기준으로 timeDifference 계산 (밀리초 단위)
            let timeDifferenceInMilliseconds: number = endDate.getTime() - suspendDate.getTime()

            // suspendStartDate가 바뀌면서 timeDifference가 변하면서 endDate를 조정
            endDate = new Date(suspendDate.getTime() + timeDifferenceInMilliseconds)

            // 밀리초를 일로 변환
            let daysRemaining: number = Math.ceil(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24))

            return daysRemaining + 1
        }
    }

    const checkMembershipMaxDate = () => {
        if (membershipInfo) {
            return new Date(membershipInfo.endDate)
        }
    }
    const resetStatusModal = () => {
        changingSuspendDate(currentDate)
        setSuspendMemo(suspendInfo != null ? suspendInfo.memo : "")
    }
    const resetPromotionModal = () => {
        setPromotionMemo("")
    }

    /**------------------ 내부함수 ------------------- */

    useEffect(() => {
        getMembershipPurchaser()
        getMerchantList()
        getGoodsList()
        getPromotionList()
        getMembershipPromotionList()
    }, [])

    useEffect(() => {
        if (promotionMemo.toString().length >= 30) {
            openAlert({ title: "경고", body: "30자 까지 입력가능합니다.", type: "warning" })
            setPromotionMemo(promotionMemo.substring(0, 30) as string)
        }
    }, [promotionMemo])

    return (
        <MembershipDetailPageWrap>
            <PageHeader
                title="멤버십 관리"
                subTitle="상세페이지"
            />
            <div className="flex-div">
                <div className="info-div">
                    <BikeInfo
                        membershipInfo={membershipInfo}
                        membershipBikeInfo={membershipBikeInfo}
                        setOpenExchangeModal={setOpenExchangeModal}
                    />
                </div>
                <div className="info-div">
                    {membershipInfo == null ? (
                        <>
                            <h3>현 멤버십 정보</h3>
                            <ContentButtonWrap>
                                <Button
                                    type="tertiary"
                                    disabled
                                >
                                    {membershipBikeInfo?.bikeType === "normal" ? "멤버십 미 구독" : "충전형 기체"}
                                </Button>
                            </ContentButtonWrap>
                            <h3>카드 정보</h3>
                            <ContentButtonWrap>
                                <Button
                                    type="tertiary"
                                    disabled
                                >
                                    {membershipBikeInfo?.bikeType === "normal" ? "멤버십 미 구독" : "충전형 기체"}
                                </Button>
                            </ContentButtonWrap>
                        </>
                    ) : (
                        <>
                            <MembershipInfo
                                getMile={getMile}
                                membershipInfo={membershipInfo}
                                setOpenMembershipModal={setOpenMembershipModal}
                                setOpenChangeModal={setOpenChangeModal}
                                setOpenDistanceModal={setOpenDistanceModal}
                                setOpenPaymentModal={setOpenPaymentModal}
                                setOpenOfflinePaymentModal={setOpenOfflinePaymentModal}
                                setOpenRefundPaymentModal={setOpenRefundPaymentModal}
                                setOpenMembershipStatusModal={setOpenMembershipStatusModal}
                                setOpenPromotionModal={setOpenPromotionModal}
                                setOpenSusPendHistoryModal={setOpenSusPendHistoryModal}
                                isShowSuspendButton={suspendInfo && suspendInfo.status == 0}
                            />
                            <CardInfo
                                getCardList={getCardList}
                                setOpenCardModal={setOpenCardModal}
                                cardInfo={cardInfo}
                            />
                        </>
                    )}
                </div>
            </div>
            {suspendInfo?.status == 1 && (
                <div className="flex-div">
                    <div className="info-div">
                        <SuspendInfoView
                            suspendInfo={suspendInfo}
                            setOpenSusPendHistorEdityModal={setOpenSusPendHistorEdityModal}
                        />
                    </div>
                </div>
            )}

            <h3>결제 히스토리</h3>
            <DetailContentWrap>
                <DataTable
                    pageRow={5}
                    pagination
                    columns={membershipDetailColumn}
                    dataList={merchantHistory}
                />
            </DetailContentWrap>

            {/* Modal */}
            <Modal
                onClose={() => setOpenDistanceModal(false)}
                title="주행거리"
                scrollable
                open={openDistanceModal}
                type="alert"
            >
                <Row gap={10}>
                    <Col sm={12}>
                        <LabelForm label="시작일">
                            <CustomDatePicker width="100%">
                                <ReactDatePicker
                                    selected={startDate}
                                    onChange={(date: Date) => setStartDate(date)}
                                    locale={ko} // 한글로 변경
                                    selectsStart
                                    dateFormat="yyyy.MM.dd"
                                    showPopperArrow={false} // 화살표 변경
                                    customInput={<DataPickerInput />}
                                />
                            </CustomDatePicker>
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <LabelForm label="종료일">
                            <CustomDatePicker width="100%">
                                <ReactDatePicker
                                    selected={endDate}
                                    onChange={(date: Date) => setEndDate(date)}
                                    locale={ko}
                                    dateFormat="yyyy.MM.dd"
                                    showPopperArrow={false}
                                    selectsEnd
                                    minDate={startDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    customInput={<DataPickerInput />}
                                />
                            </CustomDatePicker>
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                size="small"
                                type="primary"
                                onClick={() => getMile()}
                            >
                                조회
                            </Button>
                        </div>
                    </Col>
                </Row>
                <DataTable
                    pagination
                    pageRow={5}
                    headerRight={
                        <Button
                            type="default"
                            size="small"
                            onClick={() => downloadExcel(mileData, excelHeader, `주행거리_${bikeSerial}`)}
                        >
                            엑셀
                        </Button>
                    }
                    columns={mileColumn}
                    dataList={mileData}
                />
            </Modal>

            <Modal
                onClose={() => setOpenChangeModal(false)}
                title="멤버십 변경"
                open={openChangeModal}
                type="confirm"
                onConfirm={() => {
                    checkMembership()
                }}
            >
                <div>
                    <span>현재</span>
                    <Select
                        defaultValue={membershipInfo?.currentModelId as number}
                        options={memberhsipOption}
                        readonly
                    />
                </div>
                <h2 style={{ textAlign: "center" }}>
                    <Icon.BottomArrow
                        width={20}
                        height={20}
                    />
                </h2>
                <div>
                    <span>변경</span>
                    <Select
                        options={memberhsipOption}
                        defaultValue={memberhsipOption[0]?.value}
                        onChange={(label: string, value: any) => {
                            const selectArr = MembershipGoods?.filter((item: any) => {
                                return item.modelId === value
                            })
                            setSelectMembershipGoods(selectArr as [])
                        }}
                    />
                </div>
                {selectMembershipGoods.length > 0 && (
                    <p style={{ textAlign: "center" }}>
                        멤버십이
                        <br />
                        {selectMembershipGoods[0]?.modelName}
                        으로 변경됩니다.
                        <br />
                        멤버십을 변경 하시겠습니까?
                    </p>
                )}
            </Modal>

            <Modal
                onClose={() => setOpenMembershipModal(false)}
                title={(membershipInfo?.statusId as number) > 1 ? "멤버십 구독 " : "멤버십 해지 예약"}
                open={openMembershipModal}
                type="warning"
                onConfirm={() => {
                    updateMembershipAuthasync()
                }}
            >
                {(membershipInfo?.statusId as number) > 1 ? (
                    <p style={{ textAlign: "center" }}>
                        다음 결제일 : <b>{membershipInfo?.paidDt}</b> <br />
                        <br />
                        멤버십 구독을 재 시작 하시겠습니까?
                    </p>
                ) : (
                    <p style={{ textAlign: "center" }}>
                        다음 결제일 : <b>{membershipInfo?.paidDt}</b> <br />
                        <br />
                        멤버십 구독 해지를 예약합니다. <br />
                        다음 결제일부터 결제되지 않습니다. <br /> <br />
                        멤버십 구독을 해지 예약 하시겠습니까?
                    </p>
                )}
            </Modal>

            <Modal
                onClose={() => setOpenCardModal(false)}
                title="카드 변경"
                open={openCardModal}
                onConfirm={() => {
                    checkCard()
                }}
                type="confirm"
            >
                <div>
                    <span>현재</span>
                    <Select
                        options={cardOption}
                        defaultValue={recentCard[0]?.value}
                        readonly
                    />
                </div>
                <h2 style={{ textAlign: "center" }}>
                    <Icon.BottomArrow
                        width={20}
                        height={20}
                    />
                </h2>
                <div>
                    <span>카드 변경</span>
                    <Select
                        options={cardOption}
                        defaultValue={cardOption[0]?.value}
                        onChange={(label: string, value: any) => {
                            setCustomerUid(value as string)
                        }}
                    />
                </div>
                <p style={{ textAlign: "center" }}>선택하신 카드로 변경 하시겠습니까?</p>
            </Modal>

            <Modal
                onClose={() => setOpenExchangeModal(false)}
                title="교환상태 변경"
                open={openExchangeModal}
                type="warning"
                onConfirm={() => {
                    updateBikeAuth()
                }}
            >
                <h3>
                    {membershipBikeInfo?.authPermitAt?.toLocaleLowerCase() === "y" ? (
                        <div>
                            멤버십 여부 상관없이 <span style={{ color: "red" }}>교환이 불가능 합니다.</span>
                            <br /> <br /> 교환상태를 변경 하시겠습니까?
                        </div>
                    ) : (
                        <div>
                            멤버십 여부 상관없이 <span style={{ color: "red" }}>교환이 가능 합니다.</span>
                            <br /> <br /> 교환상태를 변경 하시겠습니까?
                        </div>
                    )}
                </h3>
            </Modal>
            <Modal
                onClose={() => setOpenPaymentModal(false)}
                title="결제 시도"
                open={openPaymentModal}
                type="warning"
                onConfirm={() => {
                    paymentMembership()
                }}
            >
                <h3>
                    결제 금액 : {membershipInfo?.amount.toLocaleString()}원 <br />
                </h3>
                <h3 style={{ color: "red" }}>결제 시 취소가 불가합니다. 정말 결제하시겠습니까?</h3>
            </Modal>
            <Modal
                width="500px"
                onClose={() => {
                    setOpenMembershipStatusModal(false)
                    resetStatusModal()
                }}
                title="상태변경"
                open={openMembershipStatusModal}
                type="warning"
                onConfirm={() => {
                    updateMembershipAuthasync()
                    resetStatusModal()
                }}
            >
                <Row gap={10}>
                    <Col sm={12}>
                        <LabelForm label="멤버십 상태">
                            <Select
                                width="100%"
                                options={statusList}
                                defaultValue={statusList[0].value}
                                onChange={(label: string, value: any) => {
                                    setSelectedChangeStatus(value)
                                }}
                            />
                        </LabelForm>
                    </Col>
                    {selectedChangeStatus == 3 && (
                        <>
                            <Col sm={12}>
                                <LabelForm label="시작일">
                                    <CustomDatePicker width="100%">
                                        <ReactDatePicker
                                            selected={suspendStartDate}
                                            onChange={(date: Date) => changingSuspendDate(date)}
                                            locale={ko} // 한글로 변경
                                            selectsStart
                                            dateFormat="yyyy.MM.dd"
                                            showPopperArrow={false} // 화살표 변경
                                            minDate={startDate}
                                            maxDate={currentDate}
                                            customInput={<DataPickerInput />}
                                        />
                                    </CustomDatePicker>
                                </LabelForm>
                            </Col>
                            <Col sm={12}>
                                <LabelForm label="종료일">
                                    <CustomDatePicker width="100%">
                                        <ReactDatePicker
                                            selected={suspendEndDate}
                                            onChange={(date: Date) => setSuspendEndDate(date)}
                                            locale={ko}
                                            dateFormat="yyyy.MM.dd"
                                            showPopperArrow={false}
                                            selectsEnd
                                            minDate={currentDate > suspendStartDate ? currentDate : suspendStartDate}
                                            maxDate={suspendLimitDate}
                                            customInput={<DataPickerInput />}
                                        />
                                    </CustomDatePicker>
                                </LabelForm>
                            </Col>
                            <Col sm={12}>
                                <LabelForm label="메모">
                                    <TextArea
                                        value={suspendMemo}
                                        name="suspendMemo"
                                        height={100}
                                        maxLength={50}
                                        onChange={(e: any) => {
                                            setSuspendMemo(e.target.value)
                                        }}
                                    ></TextArea>
                                </LabelForm>
                            </Col>
                            <Col sm={12}>
                                <LabelForm label="잔여일">
                                    <Input
                                        readonly
                                        value={`${checkRemainTime()} 일`}
                                    />
                                </LabelForm>
                                <Text> * 잔여일: 다음 결제일까지 남은 일수</Text>
                            </Col>
                        </>
                    )}
                    <Text
                        fontSize="15px"
                        color="red"
                    >
                        * 일시정지가 한번이라도 있었던 경우 일시정지 상태로 변경 불가능합니다.
                    </Text>
                </Row>
            </Modal>
            <Modal
                title="일시정지 수정"
                width="500px"
                onClose={() => {
                    setOpenSusPendHistorEdityModal(false)
                    resetStatusModal()
                }}
                open={openSuspendHistoryEditModal}
                type="confirm"
                onConfirm={() => {
                    membershipSuspend()
                    resetStatusModal()
                }}
            >
                <Row gap={10}>
                    <Col sm={12}>
                        <LabelForm label="시작일">
                            <CustomDatePicker width="100%">
                                <ReactDatePicker
                                    selected={suspendStartDate}
                                    onChange={(date: Date) => changingSuspendDate(date)}
                                    locale={ko} // 한글로 변경
                                    selectsStart
                                    dateFormat="yyyy.MM.dd"
                                    showPopperArrow={false} // 화살표 변경
                                    minDate={startDate}
                                    maxDate={currentDate}
                                    customInput={<DataPickerInput />}
                                />
                            </CustomDatePicker>
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <LabelForm label="종료일">
                            <CustomDatePicker width="100%">
                                <ReactDatePicker
                                    selected={suspendEndDate}
                                    onChange={(date: Date) => setSuspendEndDate(date)}
                                    locale={ko}
                                    dateFormat="yyyy.MM.dd"
                                    showPopperArrow={false}
                                    selectsEnd
                                    minDate={currentDate > suspendStartDate ? currentDate : suspendStartDate}
                                    maxDate={suspendLimitDate}
                                    customInput={<DataPickerInput />}
                                />
                            </CustomDatePicker>
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <LabelForm label="메모">
                            <TextArea
                                value={suspendMemo}
                                name="suspendMemo"
                                height={100}
                                maxLength={50}
                                onChange={(e: any) => {
                                    setSuspendMemo(e.target.value)
                                }}
                            ></TextArea>
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <LabelForm label="잔여일">
                            <Input
                                readonly
                                value={`${checkRemainTime()} 일`}
                            />
                        </LabelForm>
                        <Text> * 잔여일: 다음 결제일까지 남은 일수</Text>
                    </Col>
                </Row>
            </Modal>
            <Modal
                onClose={() => setOpenRefundPaymentModal(false)}
                title="환불"
                open={openRefundPaymentModal}
                type="warning"
                onConfirm={() => {
                    refundMembership()
                }}
            >
                <Text
                    as={"div"}
                    fontSize="1.2rem"
                    textAlign="center"
                    margin="0 0 20px 0"
                >
                    환불 처리하시겠습니까?
                </Text>
                <Text
                    fontSize="0.95rem"
                    color="red"
                >
                    현장 결제 처리 건은 데이터만 처리 됩니다.
                    <br />
                    환불 진행 시 이전 결제 상태로 돌아가니 카드 정보, 변경된 멤버십, 교환 가능 여부 확인 부탁드립니다.
                </Text>
            </Modal>
            <Modal
                onClose={() => setOpenOfflinePaymentModal(false)}
                title="현장결제"
                open={openOfflinePaymentModal}
                type="warning"
                onConfirm={() => {
                    offlinePayment()
                }}
            >
                <Text
                    as={"div"}
                    fontSize="1.2rem"
                    textAlign="center"
                    margin="0 0 20px 0"
                >
                    {membershipInfo?.amount.toLocaleString()}원 현장 결제 처리하시겠습니까?
                </Text>
                <Text
                    fontSize="0.95rem"
                    color="red"
                >
                    * 현장 결제 처리 시 결제 예상 금액에 대한 비용은 따로 받아야 합니다.
                </Text>
            </Modal>
            <Modal
                width="800px"
                onClose={() => {
                    setOpenPromotionModal(false)
                    resetPromotionModal()
                }}
                title="프로모션 등록"
                open={openPromotionModal}
                type="alert"
                scrollable
            >
                <Row gap={10}>
                    <Col sm={12}>
                        <LabelForm label="프로모션 명">
                            <Select
                                width="100%"
                                options={promotionOption}
                                defaultValue={promotionOption[0]?.value}
                                onChange={(label: string, value: any) => {
                                    setSelectedPromotionModel(value)
                                }}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <LabelForm label="메모">
                            <Input
                                value={promotionMemo}
                                name="addr"
                                size="small"
                                placeholder="30자 까지 입력 가능"
                                onChange={e => {
                                    setPromotionMemo(e.value as string)
                                }}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={12}>
                        <Text
                            fontSize="15px"
                            color="red"
                        >
                            멤버십 무료 프로모션 적용 시 스탠다드 요금제일 경우
                            <br />
                            결제 이후 주행거리 요금 무료 프로모션이 자동 등록됩니다.
                        </Text>
                    </Col>
                    <Col sm={12}>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                size="small"
                                type="primary"
                                onClick={() => {
                                    if (selectedPromotionModel == 0) {
                                        openAlert({ title: "경고", body: "프로모션을 선택해주세요", type: "warning" })
                                    } else {
                                        setOpenPromotionConfirmModal(true)
                                    }
                                }}
                            >
                                추가
                            </Button>
                        </div>
                    </Col>
                    <Col sm={12}>
                        {promotionInfoList.length > 0 && (
                            <DataTable
                                pageRow={5}
                                pagination
                                columns={promotionColumn}
                                dataList={promotionInfoList}
                            />
                        )}
                    </Col>
                </Row>
            </Modal>
            <Modal
                width="600px"
                onClose={() => setOpenSusPendHistoryModal(false)}
                title="일시정지 히스토리"
                open={openSuspendHistoryModal}
                type="confirm"
            >
                <SuspendInfoView
                    isHistory={true}
                    suspendInfo={suspendInfo}
                />
            </Modal>
            <Modal
                onClose={() => setOpenPromotionConfirmModal(false)}
                title="프로모션 추가"
                open={openPromotionConfirmModal}
                onConfirm={() => {
                    insertPromotionInfo()
                    setOpenPromotionConfirmModal(false)
                }}
                type="confirm"
            >
                <Text
                    fontSize="15px"
                    textAlign="center"
                >
                    프로모션을 추가 등록하시겠습니까?
                </Text>
            </Modal>
            <Modal
                onClose={() => setOpenPromotionDeleteModal(false)}
                title="프로모션 삭제"
                open={openPromotionDeleteModal}
                type="confirm"
                onConfirm={() => {
                    deleteMembershipPromotion(promotionId as number)
                    setOpenPromotionDeleteModal(false)
                }}
            >
                <Text
                    fontSize="15px"
                    textAlign="center"
                >
                    프로모션을 삭제 하시겠습니까?
                </Text>
            </Modal>
            {dataLoading && <Loading open />}
        </MembershipDetailPageWrap>
    )
}

export default MembershipDetailPage

export const ContentWrap = styled.div`
    border: 1px solid #dddd;
    padding: 2rem;
    border-radius: 5px;
    white-space: nowrap;
    overflow-x: scroll;
`
export const ContentButtonWrap = styled.div`
    background: #f8f8f8;
    border: 1px solid #f8f8f8;
    border-radius: 8px;
    height: 95%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const excelHeader = [
    {
        label: "기준시간",
        value: "baseDt",
    },
    {
        label: "전체 주행거리",
        value: "totalMile",
    },
    {
        label: "하루 주행거리",
        value: "dailyMile",
    },
    {
        label: "하루 교환횟수",
        value: "dailySwapCount",
    },
]
