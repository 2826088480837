import { faqValidate, getManagerId } from "@common/util"
import useAlert from "@context/alert/useAlert"
import BbsDragAndDropFile from "@pages/Bbs/Components/BbsDragAndDrop"
import BbsEditor from "@pages/Bbs/Components/BbsEditor"
import { createVideoContainer } from "@pages/Bbs/Components/createVideoContainer"
import { faqFolderName, faqLinkInfo } from "@pages/Bbs/constants"
import { FlexColDiv, FlexDiv, Text } from "@styles"
import { Editor } from "@toast-ui/react-editor"
import { Faq } from "@type/bbsType"
import { Code } from "@type/responseType"
import { Button, Input, LabelForm, PageHeader, Radio, Select } from "p-ui"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
import faq from "src/apis/faq"
import file from "src/apis/file"

const InsertFaq = () => {
    const navigate = useNavigate()

    const [faqData, setFaqData] = useState<Faq>(defaultFaqData)
    const [faqType, setFaqType] = useState<Code[]>(defaultCodeData)
    const [contents, setContents] = useState<string | any>("")
    const [uploadFiles, setUploadFiles] = useState([])

    const editorRef = useRef<Editor>(null)

    const managerId = getManagerId()
    const { openAlert } = useAlert()

    const setForm = (name: any, value: any) => {
        setFaqData((item: any) => ({ ...item, [name]: value }))
    }

    /**
     * FAQ 카테고리 조회
     */
    const getFaqType = () => {
        faq.getFaqType().then(res => {
            if (res.status === "SUCCESS") {
                setFaqType(res.resultData)
                setForm("regManager", Number(managerId))
            }
        })
    }

    /**
     * 유효성 체크
     * @returns
     */
    const checkValidate = () => {
        const { fieldList, notValidMessage } = faqValidate()

        if (faqData !== undefined) {
            const data = faqData
            const isNotValidField = fieldList.find(field => !data[field as keyof Faq])

            if (isNotValidField) {
                return openAlert({ title: "실패", body: notValidMessage[isNotValidField].toString(), type: "warning" })
            }
            if (data.contents === "<p><br></p>") {
                return openAlert({ title: "실패", body: "faq 내용을 입력해주세요.", type: "warning" })
            }
            insertFaq(data)
        }
    }

    /**
     * Faq 등록
     * @param body Faq
     */
    const insertFaq = (body: Faq) => {
        faq.insertFaq(body).then(res => {
            if (res.status === "SUCCESS") {
                uploadFiles.length > 0 ? uploadFile(res.resultData.faqId) : insertSuccessAction()
            }
        })
    }

    /**
     * 파일 업로드
     * @param fileId fileId
     */
    const uploadFile = (fileId: any) => {
        const formData = new FormData()
        uploadFiles.map((item: any) => {
            formData.append("formData", item.object)
        })
        formData.append("linkKey", fileId)
        formData.append("linkInfo", faqLinkInfo)

        file.uploadFileList(faqFolderName, formData)
            .then(res => {
                if (res.status === "SUCCESS") {
                    insertSuccessAction()
                } else {
                    openAlert({
                        title: "실패",
                        body: "FAQ 첨부파일 등록 실패했습니다. 첨부파일만 다시 등록해주시길 바랍니다.",
                        type: "warning",
                    })
                    navigate("/bbs/faq")
                }
            })
            .catch(err => console.log(err))
    }

    /**
     * insert 성공했을 때 실행되는 함수
     */
    const insertSuccessAction = () => {
        openAlert({ title: "성공", body: "FAQ가 등록되었습니다.", type: "primary" })
        navigate("/bbs/faq")
    }

    useEffect(() => {
        getFaqType()
    }, [])

    useEffect(() => {
        setForm("contents", createVideoContainer(contents))
    }, [contents])

    return (
        <>
            <FlexDiv alignItems="center">
                <PageHeader
                    title="FAQ 관리"
                    subTitle="FAQ 등록"
                />
                <Button
                    type="primary"
                    onClick={() => checkValidate()}
                >
                    등록하기
                </Button>
            </FlexDiv>
            <FlexColDiv gap="36px">
                <FlexColDiv
                    width="1200px"
                    gap="8px"
                >
                    <LabelForm
                        label={"제목"}
                        labelCol={2}
                        required
                    >
                        <Input
                            name="title"
                            onChange={e => {
                                setForm(e.name, e.value)
                            }}
                        />
                    </LabelForm>
                    <LabelForm
                        label="구분"
                        labelCol={2}
                        required
                    >
                        <Select
                            name="faqType"
                            options={faqType.map((item: any) => item.codeLabel)}
                            onChange={(label: string, value: any) => {
                                setForm(label, faqType.find((item: any) => item.codeLabel === value)?.codeId)
                            }}
                        />
                    </LabelForm>
                    <LabelForm
                        label="게시 상태"
                        labelCol={2}
                        required
                    >
                        <FlexDiv height="50px">
                            <Radio.Group
                                defaultValue={"게시"}
                                onChange={e => {
                                    setForm("displayFg", e === "게시" ? 1 : 0)
                                }}
                                options={["게시", "숨김"]}
                            ></Radio.Group>
                        </FlexDiv>
                    </LabelForm>
                    <LabelForm
                        label={"첨부파일"}
                        labelCol={2}
                    >
                        <FlexColDiv>
                            <Text
                                as={"p"}
                                bold
                            >
                                첨부파일 추가 - 파일을 드래그 혹은 클릭해서 첨부 해 주세요
                            </Text>
                            <BbsDragAndDropFile
                                key={"bbsDragAndDropFile"}
                                files={uploadFiles}
                                setFiles={setUploadFiles}
                                defaultData={undefined}
                            />
                        </FlexColDiv>
                    </LabelForm>
                </FlexColDiv>
                <BbsEditor
                    ref={editorRef}
                    placeholder="FAQ 답변을 입력해주세요."
                    onChange={() => setContents(editorRef?.current?.getInstance().getHTML())}
                />
            </FlexColDiv>
        </>
    )
}

export default InsertFaq

const defaultFaqData = {
    title: "",
    contents: "",
    faqType: null,
    displayFg: 1,
    regManager: 0,
}

const defaultCodeData = [
    {
        codeId: 0,
        parentCode: "",
        code: "",
        codeLabel: "",
        numberValue: null,
        stringValue: null,
    },
]
