import styled from "styled-components"
export const Span = styled.span<{ width?: string }>`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 40px;
    width: ${props => props.width};
    background-color: #ededed;
    font-size: 15px;
    color: #6e6e6e;
`
export const DatePickerWrap = styled.div<{ margin?: string; height?: string; width?: string }>`
    width: 220px;
    height: 40px;
    height: ${props => props.height};
    width: ${props => props.width};
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    margin: ${props => props.margin};
`
