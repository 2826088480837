import React, { useEffect, useState } from "react"
import { TableForm, TableFormLabel, TableFormValue, Button, Modal, Select, LabelForm } from "p-ui"
import { FlexColDiv } from "@styles"
import { ConfirmModalType } from "../types"
import { AssetGoodsBike } from "@type/responseType"
import useAlert from "@context/alert/useAlert"
import { useLocation } from "react-router"
import { useUpdateBikeInfo } from "src/queries/assets"
import { useQueryClient } from "@tanstack/react-query"
import { getManagerId } from "@common/util"
import { ASSETS_QUERY_KEY } from "@common/queryKey"
import CommonModal from "./CommonModal"

const BikeInfoForm = (props: any) => {
    const managerId = getManagerId()
    const location = useLocation()
    const queryClient = useQueryClient()

    const { openAlert } = useAlert()
    const serialNo = new URLSearchParams(location.search).get("serialNo")

    /**기존 상위컴포넌트에서 불러온 데이터 가져옴 */
    const cacheData: any = queryClient.getQueryData([ASSETS_QUERY_KEY.GET_BIKE_ONE, serialNo, managerId])
    const bikeData = cacheData ? cacheData.resultData?.bikeOne : []

    const [changeData, setChangeData] = useState<AssetGoodsBike>({ ...bikeData })

    const [modalOpen, setModalOpen] = useState<ConfirmModalType>({
        isOpen: false,
        title: "confirm",
        contents: `<div>test</div>`,
        cb: () => {},
        onClose: () => {
            setChangeData({ ...bikeData })
            setModalOpen(prev => ({
                ...prev,
                isOpen: false,
                title: "confirm modal",
                contents: "confirm modal contents",
                cb: () => {},
            }))
        },
    })

    const { mutate } = useUpdateBikeInfo()

    const updateBikeType = (changeData: AssetGoodsBike) => {
        if (bikeData.goodsType == changeData.goodsType) {
            openAlert({ title: "실패", body: "동일한 타입으로 변경할 수 업습니다.", type: "warning" })
            return
        }
        mutate(changeData, {
            onSuccess(data) {
                if (data.status === "SUCCESS") {
                    openAlert({ title: "성공", body: "타입 변경 완료", type: "primary" })
                    queryClient.invalidateQueries({ queryKey: [ASSETS_QUERY_KEY.GET_BIKE_ONE, serialNo] })
                } else {
                    openAlert({ title: "실패", body: data.resultMessage, type: "warning" })
                }
            },
        })

        setModalOpen(prev => ({
            ...prev,
            isOpen: false,
            title: "confirm modal",
            contents: "confirm modal contents",
            cb: () => {},
        }))
    }

    useEffect(() => {
        if (bikeData) {
            setChangeData({ ...bikeData })
        }
    }, [bikeData])

    return (
        <FlexColDiv
            gap="15px"
            flex="auto"
        >
            <TableForm>
                <TableFormLabel md={2}>차량 번호</TableFormLabel>
                <TableFormValue md={4}>{bikeData?.vinNo}</TableFormValue>
                <TableFormLabel md={2}>차대 번호</TableFormLabel>
                <TableFormValue md={4}>{bikeData?.biVin}</TableFormValue>
                <TableFormLabel md={2}>시리얼 번호</TableFormLabel>
                <TableFormValue md={4}>{bikeData?.serialNo}</TableFormValue>
                <TableFormLabel md={2}>모델명</TableFormLabel>
                <TableFormValue md={4}>{bikeData?.goodsModelNm}</TableFormValue>
                <TableFormLabel md={2}>색상</TableFormLabel>
                <TableFormValue md={4}>{bikeData?.bikeColor}</TableFormValue>
                <TableFormLabel md={2}>타입</TableFormLabel>
                <TableFormValue md={4}>
                    <div className="flex-between">
                        {bikeData?.goodsType}
                        <Button
                            type="secondary"
                            size="small"
                            onClick={() =>
                                setModalOpen(prev => ({
                                    ...prev,
                                    isOpen: true,
                                    title: "타입 변경",
                                    contents: (
                                        <LabelForm label="타입">
                                            <Select
                                                options={typeOptions}
                                                defaultValue={bikeData?.goodsType}
                                                onChange={(label, value) => {
                                                    setChangeData(prev => ({
                                                        ...prev,
                                                        goodsType: value.toString(),
                                                    }))
                                                }}
                                            />
                                        </LabelForm>
                                    ),
                                    cb: updateBikeType,
                                }))
                            }
                        >
                            변경
                        </Button>
                    </div>
                </TableFormValue>
                <TableFormLabel md={2}>고객</TableFormLabel>
                <TableFormValue md={4}>{bikeData?.purchaserNm}</TableFormValue>
            </TableForm>
            <CommonModal
                modalOpen={modalOpen}
                cbParam={changeData}
            />
        </FlexColDiv>
    )
}

export default BikeInfoForm

const typeOptions = [
    { label: "일반", value: "일반" },
    { label: "렌탈", value: "렌탈" },
    { label: "대차", value: "대차" },
]
