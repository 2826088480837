import React, { useState } from "react"
import { Button, TableForm, TableFormLabel, TableFormValue } from "p-ui"
import { MembershipCardInfo } from "@type/membershipType"
interface CardInfoProps {
    setOpenCardModal: (item: boolean) => void
    cardInfo?: MembershipCardInfo
    getCardList: () => void
}

const CardInfo = (props: CardInfoProps) => {
    const { setOpenCardModal, cardInfo, getCardList } = props
    const [memoEdit, setMemoEdit] = useState<boolean>(false)

    const button = () => {
        return memoEdit ? (
            <Button
                size="small"
                type="primary"
                onClick={() => {
                    setMemoEdit(false)
                }}
            >
                메모 저장
            </Button>
        ) : (
            <Button
                size="small"
                type="primary"
                onClick={() => {
                    setMemoEdit(true)
                }}
            >
                메모 수정
            </Button>
        )
    }
    return (
        <div>
            {/* <div>
                <div className="flex-between">
                    <h3>메모</h3>
                    {button()}
                </div>
                <Row>
                    <Col sm={12}>
                        <TextArea
                            readonly={!memoEdit}
                            height={"300px"}
                        />
                    </Col>
                </Row>
            </div> */}

            <h3>카드 정보</h3>
            <TableForm>
                <TableFormLabel sm={3}>카드 번호 (끝 4자리)</TableFormLabel>
                <TableFormValue sm={9}>
                    <div className="flex-between">
                        <span>
                            {cardInfo?.cardNumber?.substring(
                                cardInfo?.cardNumber?.lastIndexOf("*") + 1,
                                cardInfo?.cardNumber?.lastIndexOf("*") + 1 + 4
                            )}
                        </span>
                        <Button
                            disabled={cardInfo?.cardName === null}
                            onClick={() => {
                                setOpenCardModal(true)
                                getCardList()
                            }}
                            size="small"
                            type="default"
                        >
                            카드 변경
                        </Button>
                    </div>
                </TableFormValue>
                <TableFormLabel sm={3}>카드사</TableFormLabel>
                <TableFormValue sm={3}>{cardInfo?.cardName}</TableFormValue>
                <TableFormLabel sm={3}>이름</TableFormLabel>
                <TableFormValue sm={3}>{cardInfo?.purchaserNm}</TableFormValue>
            </TableForm>
        </div>
    )
}

export default CardInfo
