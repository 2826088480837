import { Result } from "@type/responseType"
import axios from "./config"
import { MEMBERSHIP } from "./urls"
import {
    MembershipAuthParam,
    MembershipCardInfo,
    MembershipCardParam,
    MembershipModelParam,
    MembershipOne,
    MembershipBikeAuthParam,
    MembershipTable,
    MembershipGoods,
    MembershipSuspendParam,
    PromotionInfo,
    PromotionDTO,
    PromotionInsertParam,
} from "@type/membershipType"

export default {
    /**구매자별 멤버십 사용 현황 조회  (MainList) */
    async getMembership(startDt?: string | null, endDt?: string | null): Promise<Result<MembershipTable[]> | any> {
        try {
            return await axios.host.get(MEMBERSHIP.GET_MEMBERSHIP, { params: { startDt, endDt } })
        } catch (e: any) {
            return e.message
        }
    },
    /**멤버십 굿즈리스트 조회 */
    async getMembershipGoodsList(serialNo: string | undefined): Promise<Result<MembershipGoods[]> | any> {
        try {
            return await axios.host.get(MEMBERSHIP.GET_GOODS_LIST + serialNo)
        } catch (e: any) {
            return e.message
        }
    },
    async deleteMembershipGoods(promotionId: number | undefined): Promise<Result<string | any>> {
        try {
            return await axios.host.delete(MEMBERSHIP.DELETE_MEMBERSHIP_PROMOTION + promotionId)
        } catch (e: any) {
            return e.message
        }
    },
    /**멤버십 단건 조회 */
    async getMembershipOne(serialNo: string | undefined): Promise<Result<MembershipOne[]> | any> {
        try {
            return await axios.host.get(MEMBERSHIP.GET_MEMBERSHIP_ONE + serialNo)
        } catch (e: any) {
            return e.message
        }
    },
    /**멤버십 굿즈리스트 조회 */
    async getPromotionList(): Promise<Result<PromotionInfo[]> | any> {
        try {
            return await axios.host.get(MEMBERSHIP.GET_PROMOTION_LIST)
        } catch (e: any) {
            return e.message
        }
    },
    /**바이크 프로모션 리스트 조회 */
    async getMembershipPromotionList(serialNo: string | undefined): Promise<Result<PromotionDTO[]> | any> {
        try {
            return await axios.host.get(MEMBERSHIP.GET_MEMBERSHIP_PROMOTION_LIST + serialNo)
        } catch (e: any) {
            return e.message
        }
    },

    /**멤버십 변경 */
    async updateMembership(modelParam: MembershipModelParam): Promise<Result<string> | any> {
        try {
            return await axios.host.post(MEMBERSHIP.UPDATE_MEMBERSIP, modelParam)
        } catch (e: any) {
            return e.message
        }
    },
    /**멤버십 카드 전체 조회 */
    async getCardList(purchaserSn: number | undefined): Promise<Result<MembershipCardInfo[]> | any> {
        try {
            return await axios.host.get(MEMBERSHIP.GET_CARD_LIST + purchaserSn)
        } catch (e: any) {
            return e.message
        }
    },
    async updateCard(cardParm: MembershipCardParam): Promise<Result<string> | any> {
        try {
            return await axios.host.post(MEMBERSHIP.UPDATE_CARD, cardParm)
        } catch (e: any) {
            return e.message
        }
    },
    /**결제 이력 조회 */
    async getMerchantList(serialNo: string | undefined): Promise<Result<MembershipCardInfo[]> | any> {
        try {
            return await axios.host.get(MEMBERSHIP.GET_MERCHANT + serialNo)
        } catch (e: any) {
            return e.message
        }
    },
    /**멤버십 상태 변경 */
    async updateAuth(statusParam: MembershipAuthParam): Promise<Result<string> | any> {
        try {
            return await axios.host.post(MEMBERSHIP.UPDATE_AUTH, statusParam)
        } catch (e: any) {
            return e.message
        }
    },

    /**바이크 교환 상태 변경 */
    async updateBikeAuth(statusParam: MembershipBikeAuthParam): Promise<Result<string> | any> {
        try {
            return await axios.host.post(MEMBERSHIP.UPDATE_BIKE_AUTH, statusParam)
        } catch (e: any) {
            return e.message
        }
    },
    /**바이크 교환 상태 변경 */
    async insertPromotionInfo(promotionUpdateParam: PromotionInsertParam): Promise<Result<string> | any> {
        try {
            return await axios.host.post(MEMBERSHIP.INSERT_PROMOTION, promotionUpdateParam)
        } catch (e: any) {
            return e.message
        }
    },
    /**결제 시도 */
    async paymentMembership(serialNo: undefined | string): Promise<Result<string> | any> {
        try {
            return await axios.host.post(MEMBERSHIP.PAYMENT_MEMBERSHIP + serialNo)
        } catch (e: any) {
            return e.message
        }
    },
    async offlinePayment(serialNo: undefined | string): Promise<Result<string> | any> {
        try {
            return await axios.host.post(MEMBERSHIP.OFFLINE_PAYMENT + serialNo)
        } catch (e: any) {
            return e.message
        }
    },

    async refundMembership(membershipId: undefined | number): Promise<Result<string> | any> {
        try {
            return await axios.host.post(MEMBERSHIP.REFUND_PAYMENT + membershipId)
        } catch (e: any) {
            return e.message
        }
    },

    async membershipSuspend(membershipSuspend: MembershipSuspendParam): Promise<Result<string> | any> {
        try {
            return await axios.host.post(MEMBERSHIP.SUSPEND_MEMBERSHIP, membershipSuspend)
        } catch (e: any) {
            return e.message
        }
    },

    /**주행거리 조회 */
    async getDailyMile(
        serialNo: string | undefined,
        startDt?: string,
        endDt?: string
    ): Promise<Result<MembershipCardInfo[]> | any> {
        try {
            if (startDt && endDt) {
                return await axios.host.get(
                    MEMBERSHIP.GET_DAILY_MILE + serialNo + `?endDate=${endDt}&startDate=${startDt}`
                )
            } else {
                return await axios.host.get(MEMBERSHIP.GET_DAILY_MILE + serialNo)
            }
        } catch (e: any) {
            return e.message
        }
    },
}
