import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"
import { Button, Icon } from "p-ui"
import { DropZone, FileDiv, ScrollDiv } from "./styles"

function DragAndDropFile(props: any) {
    const { files, setFiles, defaultFiles, setFileDeleteModalData } = props
    const imgInputRef = useRef<HTMLInputElement>(null)
    const [isDragging, setIsDragging] = useState<boolean>(false)

    const fileId = useRef<number>(0)
    const dragRef = useRef<HTMLDivElement | null>(null)

    const onImgInputBtnClick = (e: any) => {
        e.preventDefault()
        if (e.target.className !== "deleteBtn") {
            imgInputRef.current?.click()
        }
    }

    const onChangeFiles = useCallback(
        (e: ChangeEvent<HTMLInputElement> | any): void => {
            let selectFiles: File[] = []
            let tempFiles: IFileTypes[] = files
            if (e.type === "drop") {
                selectFiles = e.dataTransfer.files
            } else {
                selectFiles = e.target.files
            }

            for (const file of selectFiles) {
                tempFiles = [
                    ...tempFiles,
                    {
                        id: fileId.current++,
                        object: file,
                    },
                ]
            }
            setFiles(tempFiles)
        },
        [files]
    )
    const handleDragIn = useCallback((e: DragEvent): void => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const handleDragOut = useCallback((e: DragEvent): void => {
        e.preventDefault()
        e.stopPropagation()

        setIsDragging(false)
    }, [])

    const handleDragOver = useCallback((e: DragEvent): void => {
        e.preventDefault()
        e.stopPropagation()

        if (e.dataTransfer!.files) {
            setIsDragging(true)
        }
    }, [])

    const handleDrop = useCallback(
        (e: DragEvent): void => {
            e.preventDefault()
            e.stopPropagation()

            onChangeFiles(e)
            setIsDragging(false)
        },
        [onChangeFiles]
    )

    const initDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.addEventListener("dragenter", handleDragIn)
            dragRef.current.addEventListener("dragleave", handleDragOut)
            dragRef.current.addEventListener("dragover", handleDragOver)
            dragRef.current.addEventListener("drop", handleDrop)
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

    const resetDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.removeEventListener("dragenter", handleDragIn)
            dragRef.current.removeEventListener("dragleave", handleDragOut)
            dragRef.current.removeEventListener("dragover", handleDragOver)
            dragRef.current.removeEventListener("drop", handleDrop)
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop])

    const deleteFiles = useCallback(
        (id: number): void => {
            setFiles(files.filter((file: any) => file.id !== id))
        },
        [files]
    )

    useEffect(() => {
        initDragEvents()
        return () => resetDragEvents()
    }, [initDragEvents, resetDragEvents])

    useEffect(() => {
        return () => {
            setFiles([])
        }
    }, [])

    return (
        <>
            <div style={{ display: "flex", gap: "30px", flexDirection: "column" }}>
                <div>
                    <DropZone
                        isDragging={isDragging}
                        ref={dragRef}
                        onClick={onImgInputBtnClick}
                    >
                        <span>
                            <Icon.Upload fill="var(--primary)" />
                        </span>
                        <p>Drag file to zone on click upload</p>
                    </DropZone>
                    <input
                        ref={imgInputRef}
                        placeholder="입력"
                        type="file"
                        style={{ display: "none" }}
                        onChange={onChangeFiles}
                        multiple={true}
                    />
                </div>
                {(files?.length > 0 || defaultFiles?.length > 0) && (
                    <ScrollDiv>
                        <div>
                            {defaultFiles?.length > 0 &&
                                defaultFiles?.map((item: any) => {
                                    const { fileId, fileName } = item
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                                marginBottom: "8px",
                                            }}
                                        >
                                            <FileDiv key={fileId}>
                                                <span>
                                                    <Icon.Attach
                                                        width={16}
                                                        height={16}
                                                        fill="#ff7433"
                                                    />
                                                </span>
                                                <p>{fileName}</p>
                                            </FileDiv>
                                            <Button
                                                type={"danger"}
                                                size="small"
                                                height="32px"
                                                onClick={() =>
                                                    setFileDeleteModalData((prev: any) => ({
                                                        ...prev,
                                                        isOpen: true,
                                                        fileId: fileId,
                                                        fileName: fileName,
                                                    }))
                                                }
                                            >
                                                <Icon.Delete
                                                    fill="white"
                                                    width={16}
                                                    height={16}
                                                />
                                            </Button>
                                        </div>
                                    )
                                })}
                            {files?.length > 0 &&
                                files?.map((file: IFileTypes) => {
                                    const {
                                        id,
                                        object: { name },
                                    } = file
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                                marginBottom: "8px",
                                            }}
                                        >
                                            <FileDiv key={id}>
                                                <span>
                                                    <Icon.Attach
                                                        width={16}
                                                        height={16}
                                                        fill="#ff7433"
                                                    />
                                                </span>
                                                <p>{name}</p>
                                            </FileDiv>
                                            <Button
                                                type={"danger"}
                                                size="small"
                                                height="32px"
                                                onClick={() => deleteFiles(id)}
                                            >
                                                <Icon.Delete
                                                    fill="white"
                                                    width={16}
                                                    height={16}
                                                />
                                            </Button>
                                        </div>
                                    )
                                })}
                        </div>
                    </ScrollDiv>
                )}
            </div>
        </>
    )
}

export default DragAndDropFile

interface IFileTypes {
    id: number // 파일들의 고유값 id
    object: File
}
