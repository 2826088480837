import React, { useState } from "react"
import { Button, Icon, TableForm, TableFormLabel, TableFormValue, Tag } from "p-ui"
import { SuspendInfoType } from "@type/membershipType"

interface SuspendInfoProps {
    suspendInfo?: SuspendInfoType
    isHistory?: boolean
    setOpenSusPendHistorEdityModal?: (item: boolean) => void
}
interface SuspandTagProps {
    status: number
}
const SuspendInfoView = (props: SuspendInfoProps) => {
    const { suspendInfo, isHistory, setOpenSusPendHistorEdityModal } = props

    const SuspandTag: React.FC<SuspandTagProps> = props => {
        const { status } = props
        switch (status) {
            case 0:
                return (
                    <Tag
                        value="만료"
                        type="warning"
                    />
                )
            case 1:
                return (
                    <Tag
                        value="적용중"
                        type="primary"
                    />
                )
            default:
                return (
                    <Tag
                        value="적용중"
                        type="primary"
                    />
                )
        }
    }
    return (
        <div>
            <div className="flex-between">
                <h3>일시정지 히스토리</h3>
                <div className="button-wrap">
                    <SuspandTag status={suspendInfo?.status || 0} />
                    {!isHistory && (
                        <Button
                            compact
                            size="small"
                            type="primary"
                            onClick={() => {
                                setOpenSusPendHistorEdityModal && setOpenSusPendHistorEdityModal(true)
                            }}
                        >
                            <Icon.Edit
                                width={"15px"}
                                height={"15px"}
                            />
                        </Button>
                    )}
                </div>
            </div>
            <TableForm>
                <TableFormLabel sm={3}>시작일</TableFormLabel>
                <TableFormValue sm={3}>{suspendInfo?.startDate.split(" ")[0]}</TableFormValue>
                <TableFormLabel sm={3}>종료일</TableFormLabel>
                <TableFormValue sm={3}>{suspendInfo?.endDate.split(" ")[0]}</TableFormValue>
                <TableFormLabel sm={3}>메모</TableFormLabel>
                <TableFormValue sm={3}>{suspendInfo?.memo}</TableFormValue>
            </TableForm>
        </div>
    )
}

export default SuspendInfoView
