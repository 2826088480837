import React, { useEffect, useRef, useState } from "react"

import { useRecoilState, useSetRecoilState } from "recoil"
import { stationImgState, stationParamState } from "src/store/staiton"

import { Button, Input, LabelForm, Radio } from "p-ui"
import { FlexColDiv, FlexDiv, ImgDiv } from "@styles"

import { useDaumPostcodePopup } from "react-daum-postcode"

import { StationManage } from "@common/constatns"

import { StationTypes } from "@type/stationType"

const StationForm = (props: stationFormProps) => {
    const { edit, id, stationData } = props

    const [previewImageSrc, setPreviewImageSrc] = useState<any>()
    const [address, setAddress] = useState<string>("")
    const [stationParam, setStationParam] = useRecoilState<StationTypes>(stationParamState)
    const setImgData = useSetRecoilState(stationImgState)

    const imgInputRef = useRef<HTMLInputElement>(null)
    const open = useDaumPostcodePopup()

    const changeInput = (e: any) => {
        setStationParam({ ...stationParam, [e.name]: e.value })
    }
    const encodeFileToBase64 = (fileBlob: any) => {
        const reader = new FileReader()
        reader.readAsDataURL(fileBlob)
        return new Promise((resolve: any) => {
            reader.onload = () => {
                setPreviewImageSrc(reader.result)
                resolve()
            }
        })
    }
    const onChangeImg = (e: any) => {
        e.preventDefault()
        if (!e.target.files) {
            return
        }
        setImgData(e.target.files[0])
        encodeFileToBase64(e.target.files[0])
    }

    const onImgInputBtnClick = (e: any) => {
        e.preventDefault()
        imgInputRef.current?.click()
    }

    const handleImageError = (e: any) => {
        e.target.src = `${process.env.PUBLIC_URL}/images/none.png`
    }

    const handleComplete = (data: any) => {
        setAddress(data.roadAddress || data.autoRoadAddress)
        setStationParam({
            ...stationParam,
            addressRoad: data.roadAddress || data.autoRoadAddress,
            addressRegion: data.jibunAddress || data.autoJibunAddress,
            zipCode: data.zonecode,
        })
    }

    const handleClick = () => {
        open({ onComplete: handleComplete })
    }

    useEffect(() => {
        if (id) {
            setStationParam(prev => ({
                ...stationParam,
                useFg: "Y",
                stationId: id,
                stationName: stationData?.stationName,
                addressRoad: stationData?.addressRoad,
                addressRegion: stationData?.addressRegion,
                addressDetail: stationData?.addressDetail,
                status: stationData?.status.toString(),
                zipCode: stationData?.zipCode,
                stationType: "chrstn",
            }))
        }
    }, [stationData])

    return (
        <FlexColDiv gap="10px">
            <FlexDiv
                alignItems="center"
                gap="5px"
            >
                <h4>스테이션 정보</h4>
                <span>* 은 필수 입력 항목입니다.</span>
            </FlexDiv>
            <LabelForm
                required
                label="스테이션 명"
            >
                <Input
                    onChange={e => {
                        changeInput(e)
                    }}
                    name="stationName"
                    readonly={!edit && id ? true : false}
                    value={stationData && stationData.stationName}
                />
            </LabelForm>
            <LabelForm
                required
                label="주소"
            >
                <FlexDiv
                    alignItems="center"
                    gap="5px"
                >
                    <Input
                        width={"100%"}
                        onClick={handleClick}
                        readonly
                        value={address !== "" ? address : stationData ? stationData.addressRoad : ""}
                    />
                    <Button
                        onClick={handleClick}
                        type="default"
                        disabled={!edit && id ? true : false}
                    >
                        주소 찾기
                    </Button>
                </FlexDiv>
            </LabelForm>
            <LabelForm label="상세주소">
                <Input
                    value={stationData && stationData.addressDetail}
                    onChange={e => {
                        changeInput(e)
                    }}
                    readonly={!edit && id ? true : false}
                    name="addressDetail"
                />
            </LabelForm>
            <LabelForm label="이미지 등록">
                <FlexDiv
                    gap="5px"
                    alignItems="flex-end"
                    justifyContent="flex-start"
                >
                    <ImgDiv
                        as="img"
                        size="120px"
                        borderRadius="8px"
                        border="1px solid #ddd"
                        src={
                            previewImageSrc
                                ? previewImageSrc
                                : stationData
                                ? `${stationData.s3Url}`
                                : `${process.env.PUBLIC_URL}/images/none.png`
                        }
                        alt=""
                        onError={handleImageError}
                    />

                    <Button
                        disabled={!edit && id ? true : false}
                        onClick={onImgInputBtnClick}
                    >
                        이미지 등록
                    </Button>
                    <input
                        ref={imgInputRef}
                        placeholder="입력"
                        type="file"
                        accept="image/*"
                        onChange={onChangeImg}
                        style={{ display: "none" }}
                    />
                </FlexDiv>
            </LabelForm>
            <div>
                <LabelForm
                    fullHeight
                    required
                    label="상태"
                >
                    <FlexDiv height="100%">
                        <Radio.Group
                            defaultValue={"0"}
                            value={stationData && stationData.status?.toString()}
                            disabled={!edit && id ? true : false}
                            onChange={e => {
                                setStationParam(prev => ({ ...prev, status: e.toString() }))
                            }}
                            options={StationManage.stationStatus}
                        ></Radio.Group>
                    </FlexDiv>
                </LabelForm>
            </div>
        </FlexColDiv>
    )
}

export default StationForm

interface stationFormProps {
    stationData: any
    edit: boolean
    id?: string
}
