import React, { useState, useEffect } from "react"

import { useDaumPostcodePopup } from "react-daum-postcode"
import { FlexDiv, FlexColDiv } from "@styles"
import { Input, LabelForm, Radio, Button } from "p-ui"
import orderManage from "src/apis/orderManage"
import { CustomerParam, DetailOrderInfo } from "@type/orderManageType"

interface OrderPurchaserForm {
    detailData: DetailOrderInfo
    orderId: string
    param: CustomerParam
    setParam: (e: any) => void
}

const OrderPurchaserForm = (props: OrderPurchaserForm) => {
    const { detailData, orderId, param, setParam } = props

    const open = useDaumPostcodePopup()
    const [address, setAddress] = useState(detailData.addressRoad)
    const [orderType, setOrderType] = useState<string>(detailData.orderType)

    const handleComplete = (data: any) => {
        setParam({
            ...param,
            addressRoad: data.roadAddress || data.autoRoadAddress,
            addressRegion: data.jibunAddress || data.autoJibunAddress,
            zipCode: data.zonecode,
        })
        setAddress(data.roadAddress || data.autoRoadAddress)
    }

    const handleClick = () => {
        open({ onComplete: handleComplete })
    }

    const getCustomorInfo = () => {
        orderManage
            .getCustomorOne(orderId)
            .then(res => {
                if (res.status === "SUCCESS") {
                    const result = res.resultData
                    result.userId = detailData.userId
                    setParam(result)
                } else {
                    console.log(res.resultMessage)
                }
            })
            .catch(error => console.log(error))
    }

    const onChange = (e: any) => {
        setParam({ ...param, [e.name]: e.value })
    }

    const setPersonalNumber = () => {
        detailData.orderType !== orderType
            ? setParam({ ...param, ["personalNumber"]: detailData.personalNumber })
            : setParam({ ...param, ["personalNumber"]: null })
    }
    useEffect(() => {
        getCustomorInfo()
    }, [])

    return (
        <FlexColDiv gap="10px">
            <LabelForm
                label="구분"
                fullHeight
                required
            >
                <FlexDiv height="100%">
                    <Radio.Group
                        defaultValue={detailData.orderType}
                        options={purchaserType}
                        onChange={e => {
                            setOrderType(e as string)
                            setPersonalNumber()
                        }}
                    ></Radio.Group>
                </FlexDiv>
            </LabelForm>
            <LabelForm
                label="이름"
                fullHeight
                required
            >
                <Input
                    name="userName"
                    onChange={e => {
                        onChange(e)
                    }}
                    defaultValue={detailData.userName}
                />
            </LabelForm>
            <LabelForm
                label="휴대폰"
                fullHeight
                required
            >
                <Input
                    type="number"
                    name="userPhone"
                    onChange={e => {
                        onChange(e)
                    }}
                    defaultValue={detailData.userPhone}
                />
            </LabelForm>
            <LabelForm
                label="이메일"
                fullHeight
                required
            >
                <Input
                    name="userEmail"
                    onChange={e => {
                        onChange(e)
                    }}
                    defaultValue={detailData.userEmail}
                />
            </LabelForm>
            <LabelForm
                required
                label={orderType === "개인" ? "생년월일" : "사업자번호"}
            >
                <Input
                    name="personalNumber"
                    type="number"
                    onChange={e => {
                        onChange(e)
                    }}
                    value={detailData.orderType !== orderType ? "" : detailData.personalNumber}
                />
            </LabelForm>
            <LabelForm
                required
                label="주소"
            >
                <FlexDiv
                    alignItems="center"
                    gap="5px"
                >
                    <Input
                        width={"100%"}
                        readonly
                        value={address}
                    />
                    <Button
                        onClick={handleClick}
                        type="default"
                    >
                        주소 찾기
                    </Button>
                </FlexDiv>
            </LabelForm>
            <LabelForm label="상세주소">
                <Input
                    name="addressDetail"
                    width={"100%"}
                    onChange={e => {
                        onChange(e)
                    }}
                    defaultValue={detailData.addressDetail}
                />
            </LabelForm>
        </FlexColDiv>
    )
}

export default OrderPurchaserForm

const purchaserType = ["개인", "법인"]
