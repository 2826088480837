import { de } from "date-fns/locale"
import axios from "./config"

import { Result, BikeLatLng } from "@type/responseType"
import { OPEN_API_BIKE } from "./urls"

export default {
    /**
     * 이슈 리스트 조회
     * @param params : any
     * @returns httpResponse
     */
    async getBikeLatLng(params?: any): Promise<Result<BikeLatLng[]>> {
        try {
            return await axios.openApi.get("/bike/gps", { params })
        } catch (e: any) {
            console.log(e)
            return e.message
        }
    },
    async getBikeData(startTime: string, endTime: string): Promise<Result<any[]>> {
        try {
            return await axios.openApi.get(
                OPEN_API_BIKE.GET_DATA + `?endTime=${endTime}&startTime=${startTime}&serial=M2226B0024`
            )
        } catch (e: any) {
            console.log(e)
            return e.message
        }
    },
}
