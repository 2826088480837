import apis from "@apis"
import { MANAGER_QUERY_KEY } from "@common/queryKey"
import { useQuery, useMutation } from "@tanstack/react-query"

export const useGetManagerList = () => {
    return useQuery({
        queryKey: [MANAGER_QUERY_KEY.GET_LIST],
        queryFn: apis.RequestTask.getManagerIdList,
        select: (data: any) => data.resultData,
        retry: false,
    })
}
