import { Result, Station, StationResult } from "@type/responseType"
import { getStationType, updateStationType } from "@type/stationType"
import axios from "./config"
import { CENTER, STATION } from "./urls"

export default {
    /**
     * Request album list
     * @param {Object} params : Search conditions
     * @returns {Array}
     */
    async getStationData(params: getStationType): Promise<Result<StationResult<Station[]>>> {
        try {
            return await axios.host.get(STATION.GET, { params })
        } catch (e: any) {
            return e.message
        }
    },
    async getOneStation(stationId: string): Promise<Result<StationResult<Station>>> {
        try {
            return await axios.host.get(STATION.GET_ONE + stationId)
        } catch (e: any) {
            return e.message
        }
    },
    async updateStationData(params: updateStationType) {
        try {
            return await axios.host.post(STATION.POST, params)
        } catch (e: any) {
            return e.message
        }
    },
    async deleteStationData(params: number) {
        try {
            return await axios.host.delete(STATION.DELETE + params)
        } catch (e: any) {
            return e.message
        }
    },
    async deleteCenterData(params: number) {
        try {
            return await axios.host.delete(CENTER.DELETE + params)
        } catch (e: any) {
            return e.message
        }
    },
}
