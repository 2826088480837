import React, { useEffect, useState } from "react"

import { useRecoilState, useResetRecoilState } from "recoil"
import AppState from "@store"

import { MainDashBoardBox } from "../styles"
import Divider from "../../../components/widgets/Divider"
import ToastEditor from "./ToastEditor"
import RegForm from "./RegForm"

const IssueDetailForm = ({ issueData }: any) => {
    const [issueDesData, setIssueDesData] = useRecoilState(AppState.reg.paramIssueDesState)
    const [htmlStr, setHtmlStr] = useState<string>("")
    const resetIssueDesc = useResetRecoilState(AppState.reg.paramIssueDesState)
    useEffect(() => {
        setHtmlStr(issueDesData.contents)
    }, [issueDesData])

    useEffect(() => {
        return () => {
            resetIssueDesc()
        }
    }, [])

    return (
        <>
            <MainDashBoardBox>
                <RegForm
                    isInsert={false}
                    isDisabled={true}
                />
            </MainDashBoardBox>
            <Divider />
            <MainDashBoardBox>
                <ToastEditor
                    htmlStr={htmlStr}
                    setHtmlStr={setHtmlStr}
                    isViewer={true}
                />
            </MainDashBoardBox>
        </>
    )
}

export default IssueDetailForm
