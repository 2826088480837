import React, { useEffect, useState } from "react"

import { useRecoilState } from "recoil"
import { ZentalUserParamState, ZentalUserFileState } from "src/store/zental"

import styled from "styled-components"
import { Col, Row, Type, Button, Input, Select, TextArea, LabelForm } from "p-ui"
import { InsertValueDiv } from "../styles"
import DragAndDropFile from "@components/widgets/dragAndDrop"
import { CommonDiv, FlexColDiv, FlexDiv, GridDiv } from "@styles"

import ZENTAL from "src/apis/zental"

import { useDaumPostcodePopup } from "react-daum-postcode"
import useAlert from "@context/alert/useAlert"

import { onChangeParam } from "@type/common"

const genderOption: Type.OptionType[] = [
    {
        label: "남성",
        value: "M",
    },
    {
        label: "여성",
        value: "F",
    },
]

const userTypeOption: Type.OptionType[] = [
    {
        label: "일반",
        value: 0,
    },
    {
        label: "B2B",
        value: 1,
    },
]

function UserForm(props: any) {
    const open = useDaumPostcodePopup()
    const userData = props.zentalUserOneData
    const deleteAttachFiles = props.deleteAttachFiles
    const setFileDeleteModalData = props.setFileDeleteModalData
    const mode = props.mode

    const { openAlert } = useAlert()

    const [address, setAddress] = useState<string>("")
    const [riderOptions, setRiderOptions] = useState<{ label: any; value: any }[]>([])
    const [isB2B, setIsB2B] = useState<boolean>(false)
    const [masterAccount, setMasterAccount] = useState<string>("")

    const [zentalUserParam, setZentalUserParam] = useRecoilState(ZentalUserParamState)
    const [zentalUserFile, setZentalUserFile] = useRecoilState(ZentalUserFileState)

    const handleComplete = (data: any) => {
        setAddress(data.roadAddress || data.autoRoadAddress)
        setZentalUserParam(prev => ({
            ...prev,
            addressRoad: data.roadAddress || data.autoRoadAddress /**도로명 저장 */,
            addressRegion: data.jibunAddress || data.autoJibunAddress /**구주소 저장 */,
            zipCode: data.zonecode,
        }))
    }
    /**Post popup 여는 함수 */
    const handleClick = () => {
        open({ onComplete: handleComplete })
    }

    /**
     * input change 감지 함수 - recoil 데이터 변경
     * @param e event
     * @param name labelname
     */
    const changeInput = (e: any, name?: string) => {
        if (e.name) {
            setZentalUserParam({ ...zentalUserParam, [e.name]: e.value })
        } else {
            switch (name) {
                case "comment":
                    setZentalUserParam({ ...zentalUserParam, comment: e })
                    break
                case "gender":
                    setZentalUserParam({ ...zentalUserParam, gender: e })
                    break
                case "userType":
                    setZentalUserParam({ ...zentalUserParam, userType: e })
                    break
                case "partnerAccount":
                    setZentalUserParam({ ...zentalUserParam, partnerAccount: e })
                    setMasterAccount(e)
                    break
            }
        }
    }

    const downloadFile = (url: string) => {
        const link = document.createElement("a")
        link.href = url
        link.download = url.substring(url.lastIndexOf("/") + 1)
        link.target = "_blank"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    /**연결 안된 젠탈 라이더 */
    const getZentalRider = async () => {
        await ZENTAL.getZentalRider().then(res => {
            if (res.status === "SUCCESS") {
                if (res.resultData.length === 0) {
                    setRiderOptions([])
                } else {
                    let newRiderArr: { label: any; value: any }[] = []
                    res.resultData.map((item: any) => {
                        newRiderArr.push({ label: item.email, value: item.email })
                    })
                    if (userData?.user?.partnerAccount) {
                        newRiderArr.unshift({
                            label: userData.user?.partnerAccount,
                            value: userData.user?.partnerAccount,
                        })
                        setMasterAccount(userData.user?.partnerAccount)
                    }
                    setRiderOptions(newRiderArr)
                }
            }
        })
    }

    /** 라이더 계정 추가 */
    const insertRider = async () => {
        await ZENTAL.insertZentalRider().then(res => {
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "계정 생성 성공",
                    body: res.resultData + "계정 생성 성공",
                    type: "primary",
                })

                getZentalRider()
            } else {
                openAlert({
                    title: "계정 생성 실패",
                    body: "계정 생성 실패하였습니다. 관리자에게 문의하세요",
                    type: "warning",
                })
            }
        })
    }

    const resetPassAction = async () => {
        await ZENTAL.resetPassword(masterAccount).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({
                    title: "변경",
                    body: "Password가 변경 되었습니다.",
                    type: "primary",
                })
            } else {
                openAlert({
                    title: "변경",
                    body: "Password 변경 실패 하였습니다.",
                    type: "warning",
                })
            }
        })
    }

    useEffect(() => {
        const isB2bMode = userData ? userData.user.userType == 1 : zentalUserParam.userType == 1
        if (isB2bMode) {
            getZentalRider()
        }
        setIsB2B(isB2bMode)
    }, [zentalUserParam.userType, userData?.user.userType])

    return (
        <FlexColDiv>
            <Row>
                <Col sm={mode === "add" ? 12 : 8}>
                    <Row gap="10px">
                        <Col sm={6}>
                            <LabelForm
                                required
                                label="이름"
                            >
                                <Input
                                    name="userName"
                                    readonly={mode === "detail"}
                                    placeholder="홍길동"
                                    value={userData && userData.user?.userName}
                                    onChange={(e: onChangeParam) => {
                                        changeInput(e)
                                    }}
                                />
                            </LabelForm>
                        </Col>
                        <Col sm={6}>
                            <LabelForm
                                required
                                label="전화번호"
                            >
                                <Input
                                    name="userPhone"
                                    readonly={mode === "detail"}
                                    placeholder="01012345678 (- 없이)"
                                    value={userData && userData.user?.userPhone}
                                    onChange={(e: onChangeParam) => {
                                        changeInput(e)
                                    }}
                                />
                            </LabelForm>
                        </Col>
                        <Col sm={6}>
                            <LabelForm
                                required
                                label="생년월일"
                            >
                                <Input
                                    name="birthDt"
                                    readonly={mode === "detail"}
                                    placeholder="20010101 (- 없이)"
                                    value={userData && userData.user?.birthDt}
                                    onChange={(e: onChangeParam) => {
                                        changeInput(e)
                                    }}
                                />
                            </LabelForm>
                        </Col>
                        <Col sm={6}>
                            <LabelForm
                                required
                                label="성별"
                            >
                                <Select
                                    name="gender"
                                    readonly={mode === "detail"}
                                    options={genderOption}
                                    defaultValue={userData ? userData.user?.gender : "M"}
                                    onChange={(label: string, value: any) => {
                                        changeInput(value, "gender")
                                    }}
                                />
                            </LabelForm>
                        </Col>

                        <Col sm={6}>
                            <LabelForm
                                required
                                label="B2B 여부"
                            >
                                <Select
                                    name="uerType"
                                    readonly={mode === "detail"}
                                    options={userTypeOption}
                                    defaultValue={userData ? userData.user.userType : 0}
                                    onChange={(label: string, value: any) => {
                                        changeInput(value, "userType")
                                    }}
                                />
                            </LabelForm>
                        </Col>
                        <Col sm={6}>
                            <LabelForm
                                required
                                label={isB2B ? "사업자번호" : "면허번호"}
                            >
                                <Input
                                    readonly={mode === "detail"}
                                    name="licenseNo"
                                    placeholder={
                                        zentalUserParam.userType == 1
                                            ? "사업자번호 입력 (-없이)"
                                            : "면허 번호 입력 (- 없이)"
                                    }
                                    value={userData && userData.user?.licenseNo}
                                    onChange={(e: onChangeParam) => {
                                        changeInput(e)
                                    }}
                                />
                            </LabelForm>
                        </Col>
                        {/* <Col sm={4}>
                            <LabelForm label="이메일">
                                <Input
                                    readonly={mode === "detail"}
                                    name="userEmail"
                                    value={userData && userData.user?.userEmail}
                                    onChange={e => {
                                        changeInput(e)
                                    }}
                                />
                            </LabelForm>
                        </Col> */}
                        {isB2B && (
                            <Col sm={12}>
                                <LabelForm
                                    label="Master계정"
                                    required
                                >
                                    <FlexDiv gap="10px">
                                        <InsertValueDiv overflowX="none">
                                            <Select
                                                searchable
                                                readonly={mode === "detail"}
                                                name="partnerAccount"
                                                defaultValue={masterAccount}
                                                onChange={(label: string, value: any) => {
                                                    changeInput(value, "partnerAccount")
                                                }}
                                                options={riderOptions}
                                            />
                                        </InsertValueDiv>
                                        {mode === "add" ? (
                                            <Button
                                                onClick={() => {
                                                    insertRider()
                                                }}
                                                size="small"
                                            >
                                                추가
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={() => {
                                                    resetPassAction()
                                                }}
                                                disabled={mode === "detail"}
                                                size="small"
                                            >
                                                초기화
                                            </Button>
                                        )}
                                    </FlexDiv>
                                </LabelForm>
                            </Col>
                        )}
                        <Col sm={12}>
                            <LabelForm
                                required
                                label="주소"
                            >
                                <FlexDiv gap="10px">
                                    <InsertValueDiv onClick={handleClick}>
                                        <Input
                                            value={mode === "add" ? address : userData && userData.user?.addressRoad}
                                            readonly
                                        />
                                    </InsertValueDiv>
                                    <Button
                                        size="small"
                                        onClick={handleClick}
                                        disabled={mode === "detail"}
                                    >
                                        주소 찾기
                                    </Button>
                                </FlexDiv>
                            </LabelForm>
                        </Col>
                        <Col sm={12}>
                            <LabelForm label="상세주소">
                                <Input
                                    name="addressDetail"
                                    readonly={mode === "detail"}
                                    value={userData && userData.user?.addressDetail}
                                    onChange={(e: onChangeParam) => {
                                        changeInput(e)
                                    }}
                                />
                            </LabelForm>
                        </Col>
                        {mode === "add" && (
                            <Col sm={12}>
                                <LabelForm
                                    label="메모"
                                    fullHeight={true}
                                >
                                    <CommonDiv height="100%">
                                        <TextArea
                                            name="comment"
                                            height="100%"
                                            onChange={(e: any) => {
                                                changeInput(e.target.value, e.target.name)
                                            }}
                                        />
                                    </CommonDiv>
                                </LabelForm>
                            </Col>
                        )}
                        {mode !== "detail" ? (
                            <Col sm={12}>
                                <LabelForm label="첨부파일">
                                    <DragAndDropFile
                                        defaultFiles={userData && userData.attach}
                                        files={zentalUserFile}
                                        setFiles={setZentalUserFile}
                                        setFileDeleteModalData={setFileDeleteModalData}
                                        deleteAttachFiles={deleteAttachFiles}
                                    />
                                </LabelForm>
                            </Col>
                        ) : (
                            <Col sm={12}>
                                <LabelForm label="첨부파일">
                                    <InsertValueDiv>
                                        <GridDiv
                                            gridCol="1fr 1fr"
                                            height="fit-content"
                                            columnGap="52px"
                                            alignItems="center"
                                            minHeihgt="42px"
                                        >
                                            {userData &&
                                                userData.attach?.map((item: any, i: number) => {
                                                    return (
                                                        <ClipDiv onClick={() => downloadFile(item.s3Url)}>
                                                            {item?.fileName}
                                                        </ClipDiv>
                                                    )
                                                })}
                                        </GridDiv>
                                    </InsertValueDiv>
                                </LabelForm>
                            </Col>
                        )}
                    </Row>
                </Col>
                {mode !== "add" && (
                    <Col sm={4}>
                        <LabelForm
                            label="메모"
                            fullHeight={true}
                        >
                            <TextArea
                                readonly={mode === "detail"}
                                name="comment"
                                height="100%"
                                value={userData && userData.user?.comment}
                                onChange={(e: any) => {
                                    changeInput(e.target.value, e.target.name)
                                }}
                            />
                        </LabelForm>
                    </Col>
                )}
            </Row>
        </FlexColDiv>
    )
}

export default UserForm

const ClipDiv = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;
`
