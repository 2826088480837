import { useEffect, useState } from "react"

import ReactDatePicker from "react-datepicker"
import { Input, LabelForm, Select } from "p-ui"
import CustomDatePicker from "@components/widgets/customDatePicker"
import { DataPickerInput, FlexColDiv, FlexDiv } from "@styles"

import { ko } from "date-fns/locale"
import utils from "@utils"
import { formatYYYYMMDD } from "@common/util"

const InsuranceModal = (props: any) => {
    const { bikeData, setInsuranceForm, insuranceForm } = props
    const { startDt, endDt, ageOver, insuranceId, insuranceCompany, insuranceName, comment } = bikeData || {}
    const currentDate = new Date()
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [openInput, setOpenInput] = useState(false)
    const [ageGuiter, setAgeGuiter] = useState<number>()

    const setForm = (name: string, value: any) => {
        setInsuranceForm((item: any) => ({ ...item, [name]: value }))
    }

    const getInitialDate = (date: string | null) => {
        if (date === null) {
            return currentDate.format(utils.FORMAT_SHORT_DATETIME)
        }
        return formatYYYYMMDD(date)
    }

    const setDate = (date: string | null): Date => {
        if (date === null) {
            return currentDate
        }
        return new Date(date)
    }

    const setDefaultForm = () => {
        setInsuranceForm((item: any) => ({
            ...item,
            insuranceId: insuranceId,
            ageOver: ageOver,
            insuranceCompany: insuranceCompany,
            insuranceName: insuranceName,
        }))

        setStartDate(setDate(startDt))
        setEndDate(setDate(endDt))

        setForm("startDt", getInitialDate(startDt))
        setForm("endDt", getInitialDate(endDt))

        if (ageOver !== null) {
            setOpenInput(!ageOverOptions.includes(ageOver))
            const extractedNumber = ageOver === "전연령" ? 0 : parseInt(ageOver?.match(/\d+/)?.[0] || "0", 10)
            setAgeGuiter(extractedNumber)
        }
    }

    useEffect(() => {
        setDefaultForm()
    }, [])

    return (
        <FlexColDiv gap="12px">
            <LabelForm label="보험연령">
                <Select
                    name="ageOver"
                    defaultValue={openInput ? "기타" : insuranceForm.ageOver}
                    options={ageOverOptions}
                    onChange={(label: string, value: any) => {
                        setOpenInput(value === "기타")
                        if (value !== "기타") {
                            setForm(label, value)
                        }
                    }}
                />
                {openInput && (
                    <FlexDiv
                        alignItems="center"
                        gap="8px"
                        margin="4px 0 0"
                    >
                        만
                        <Input
                            defaultValue={ageGuiter?.toString()}
                            name="ageOver"
                            placeholder="숫자만"
                            onChange={(e: any) => setForm(e.name, "만" + e.value + "세")}
                            width={100}
                        />
                        세
                    </FlexDiv>
                )}
            </LabelForm>
            <LabelForm label="보험사">
                <Input
                    name="insuranceCompany"
                    placeholder="보험사를 입력해주세요."
                    defaultValue={insuranceCompany}
                    onChange={(e: any) => setForm(e.name, e.value)}
                />
            </LabelForm>
            <LabelForm label="피보험자">
                <Input
                    name="insuranceName"
                    placeholder="피보험자를 입력해주세요."
                    defaultValue={insuranceName}
                    onChange={(e: any) => setForm(e.name, e.value)}
                />
            </LabelForm>
            <LabelForm label="보험시작">
                <CustomDatePicker
                    width={"100%"}
                    height={"50px"}
                >
                    <ReactDatePicker
                        selected={startDate}
                        onChange={(date: Date) => {
                            setStartDate(date)
                            setForm("startDt", date.format(utils.FORMAT_DATE))
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        customInput={<DataPickerInput height={"50px"} />}
                    />
                </CustomDatePicker>
            </LabelForm>
            <LabelForm label="보험종료">
                <CustomDatePicker
                    width={"100%"}
                    height={"50px"}
                >
                    <ReactDatePicker
                        selected={endDate}
                        onChange={(date: Date) => {
                            setEndDate(date)
                            setForm("endDt", date.format(utils.FORMAT_DATE))
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        customInput={<DataPickerInput height={"50px"} />}
                    />
                </CustomDatePicker>
            </LabelForm>
            <LabelForm label="비고">
                <Input
                    name="comment"
                    placeholder="50자 내외로 입력해주세요"
                    defaultValue={comment}
                    onChange={(e: any) => setForm(e.name, e.value)}
                />
            </LabelForm>
        </FlexColDiv>
    )
}

export default InsuranceModal

const ageOverOptions = ["전연령", "만19세", "만21세", "만26세", "만30세", "기타"]
