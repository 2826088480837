import { Agency } from "@type/responseType"
import { atom, selector } from "recoil"

export const accountDataState = atom<Agency[]>({
    key: "accountDataState",
    default: [
        {
            addr: "",
            agencyId: 0,
            agencyNm: "",
            createdAt: "",
            manageArea: null,
            phone: "",
            purchaserSn: 0,
            updatedAt: "",
            useFg: 1,
        },
    ],
})

export const agencyOneDataState = atom({
    key: "agencyOneDataState",
    default: {
        addr: "",
        account: "",
        agencyId: 0,
        agencyNm: "",
        createdAt: "",
        manageArea: "",
        phone: "",
        purchaserSn: 0,
        updatedAt: "",
        useFg: 1,
    },
})

export const agencyPurchaserState = atom({
    key: "agencyPurchaserState",
    default: [],
})

export const agencyInserFormState = atom({
    key: "agencyInserFormState",
    default: {
        purchaserSn: 0,
        account: "",
        password: "",
        addr: "",
        agencyNm: "",
        phone: "",
    },
})

export const agencyEditFormState = atom({
    key: "agencyEditFormState",
    default: {},
})
