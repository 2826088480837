import { useEffect } from "react"
import { Col } from "react-grid-system"

import styled from "styled-components"
import { Button, Label, TableForm, TableFormLabel, TableFormValue, Row, Icon } from "p-ui"
import { CommonDiv, FlexBetween, FlexColDiv, FlexDiv, SettingDiv, TransparentBtn } from "@styles"

import { formatYYYYMMDD } from "@common/util"

const defaultInsuranceValue = [
    { value: "보험연령", name: "ageOver", default: "" },
    { value: "보험사", name: "insuranceCompany", default: "" },
    { value: "피보험자", name: "insuranceName", default: "" },
    { value: "보험시작", name: "startDt", default: "" },
    { value: "보험종료", name: "endDt", default: "" },
    { value: "비고", name: "insuranceComment", default: "" },
]

function InsuranceForm(props: any) {
    const { setModalOpen, bikeData } = props

    const setInsuranceValueFromBikeData = (bikeData: any) => {
        return defaultInsuranceValue.map(item => ({
            ...item,
            default: bikeData[item.name],
        }))
    }

    useEffect(() => {
        if (bikeData) {
            setInsuranceValueFromBikeData(bikeData)
        }
    }, [bikeData])
    return (
        <>
            <ExtendedCommonDiv>
                <FlexBetween
                    width="100%"
                    alignItems="center"
                >
                    <FlexDiv
                        gap="16px"
                        alignItems={"center"}
                    >
                        <h3>보험 정보</h3>
                        <TransparentBtn
                            onClick={() => {
                                setModalOpen((prev: any) => ({
                                    ...prev,
                                    insuranceModal: true,
                                }))
                            }}
                        >
                            <Icon.Edit />
                        </TransparentBtn>
                    </FlexDiv>
                </FlexBetween>
                {bikeData?.insuranceCompany ? (
                    <TableForm>
                        {defaultInsuranceValue.map(item => (
                            <>
                                <TableFormLabel
                                    md={2}
                                    key={item.name}
                                >
                                    {item.value}
                                </TableFormLabel>
                                <TableFormValue md={4}>
                                    {item.name === "startDt" || item.name === "endDt"
                                        ? formatYYYYMMDD(bikeData?.[item.name])
                                        : bikeData?.[item.name]}
                                </TableFormValue>
                            </>
                        ))}
                    </TableForm>
                ) : (
                    /**데이터 없을때 보여줄거*/
                    <Row>
                        <Col sm={3}>
                            <FlexColDiv gap="10px">
                                {defaultInsuranceValue.map(item => {
                                    return (
                                        <Label
                                            key={item.value}
                                            value={item.value}
                                        />
                                    )
                                })}
                            </FlexColDiv>
                        </Col>
                        <Col sm={9}>
                            <SettingDiv>
                                <Button
                                    type="tertiary"
                                    onClick={() =>
                                        setModalOpen((prev: any) => ({
                                            ...prev,
                                            insuranceModal: true,
                                        }))
                                    }
                                >
                                    보험 연결
                                </Button>
                            </SettingDiv>
                        </Col>
                    </Row>
                )}
            </ExtendedCommonDiv>
        </>
    )
}

export default InsuranceForm

const ExtendedCommonDiv = styled(CommonDiv)`
    flex: 1;
    @media screen and (max-width: 1800px) {
        padding: 0 3rem 0 0;
    }
`
