import { FlexDivProps, CommonDivProps, GridDivProps } from "@styles"
import styled from "styled-components"

/**
 * @props
 *   margin?: string
    padding?: string
    height?: string
    width?: string
    minHeihgt?: string
    maxHeight?: string
    minWidth?: string
    maxWidth?: string
    display?: string
 */
export const CommonDiv = styled.div<CommonDivProps>`
    height: ${props => props.height};
    width: ${props => props.width};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    min-height: ${props => props.minHeihgt};
    max-height: ${props => props.maxHeight};
    min-width: ${props => props.minWidth};
    max-width: ${props => props.maxWidth};
    display: ${props => props.display};
    overflow: ${props => props.overflow};
    overflow-x: ${props => props.overflowX};
    overflow-y: ${props => props.overflowY};
    position: ${props => props.position};
`

/**
 * @props
 * @extends CommonDivType
 * alignItems?: string
    justifyContent?: string
    flexDirection?: string
    gap?: string
    flex?: string
 */

export const FlexDiv = styled(CommonDiv)<FlexDivProps>`
    display: flex;
    align-items: ${props => props.alignItems};
    justify-content: ${props => props.justifyContent};
    flex-direction: ${props => props.flexDirection || "row"};
    gap: ${props => props.gap};
    flex: ${props => props.flex};
`

/**
 * @props
 * @extends CommonDivType
 * alignItems?: string
    justifyContent?: string
    flexDirection?: string
    gap?: string
    flex?: string
 */

export const FlexBetween = styled(CommonDiv)<FlexDivProps>`
    display: flex;
    align-items: ${props => props.alignItems};
    justify-content: ${props => props.justifyContent || "space-between"};
    flex-direction: ${props => props.flexDirection || "row"};
    gap: ${props => props.gap};
    flex: ${props => props.flex};
`

/**
 * @props
 * @extends CommonDivType
 * alignItems?: string
    justifyContent?: string
    flexDirection?: string
    gap?: string
    flex?: string
 */
export const FlexColDiv = styled(CommonDiv)<FlexDivProps>`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.alignItems};
    justify-content: ${props => props.justifyContent};
    gap: ${props => props.gap};
    flex: ${props => props.flex};
`

/**
 * @props
 * @extends CommonDivType
 *     gridCol?: string
    gridRow?: string
    columnGap?: string
    alignItems?: string
    gap?: string
 */
export const GridDiv = styled(CommonDiv)<GridDivProps>`
    display: grid;
    grid-template-columns: ${props => props.gridCol};
    grid-template-rows: ${props => props.gridRow};
    column-gap: ${props => props.columnGap};
    align-items: ${props => props.alignItems};
    gap: ${props => props.gap};
    justify-content: ${props => props.justifyContent};
    justify-items: ${props => props.justifyItems};
`

// datePicker
export const DataPickerInput = styled.input`
    border: none;
    height: ${props => props.height || "40px"};
    width: 100%;
    background-color: #f8f8f8;
    outline: none;
    padding-left: 0.5rem;
    color: #6e6e6e;
`

export const DatePickerSpan = styled.span`
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 1.5;
    font-size: 22px;
`

export const ViewerContainer = styled.div`
    border: 1px solid #dadde6;
    box-sizing: border-box;
    height: 100%;
    border-radius: 5px;
    height: 600px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 10px; /* 스크롤바의 너비 */
    }

    ::-webkit-scrollbar-thumb {
        height: 30%; /* 스크롤바의 길이 */
        background: #868686; /* 스크롤바의 색상 */

        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background: rgba(83, 83, 83, 0.1); /*스크롤바 뒷 배경 색상*/
    }
    .viewer-header {
        border-bottom: 1px solid #ebedf2;
        border-top-left-radius: 5px;
        height: 45px;
        background-color: #f7f9fc;
    }
    .viewer-content {
        padding: 25px;
        .html-block.ProseMirror-selectednode {
            outline: none;
        }
    }
    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
    }
    .video-container iframe,
    .video-container object,
    .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
`

export const BbsViewerContainer = styled(ViewerContainer)<{ $width?: string }>`
    width: ${props => props.$width || "429px"};

    & .toastui-editor-contents {
        & p {
            font-size: 16px;
        }
    }
`
