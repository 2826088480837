import { Icon, Type } from "p-ui"

const IconStyle = {
    height: 20,
    width: 20,
    fill: "#F8F8F8",
}

const MenuList: Type.Menu[] = [
    {
        title: "스테이션",
        icon: (
            <Icon.Lightning
                height={IconStyle.height}
                width={IconStyle.width}
                fill={IconStyle.fill}
            />
        ), // 아이콘 표시
        link: "", // 페이지 이동 버튼일 때만
        child: [
            {
                title: "스테이션 현황",
                link: "/station",
                child: [],
            },
            {
                title: "스테이션 관리",
                link: "/station/list",
                child: [],
                linkedPage: ["/station/upsert"],
            },
        ],
    },
    {
        title: "자산관리",
        icon: (
            <Icon.Archive
                height={IconStyle.height}
                width={IconStyle.width}
                fill={IconStyle.fill}
            />
        ), // 아이콘 표시
        link: "",
        child: [
            {
                title: "바이크",
                link: "/assets/bike",
                child: [],
            },
        ],
    },
    {
        title: "차량관리",
        icon: (
            <Icon.Motobike
                width={IconStyle.width}
                height={IconStyle.height}
                fill={IconStyle.fill}
            />
        ), // 아이콘 표시
        link: "",
        child: [
            {
                title: "대차관리",
                link: "/rental_fleet/main",
                child: [],
                linkedPage: ["/rental_fleet/detail"],
            },
        ],
    },
    {
        title: "관제이슈",
        icon: (
            <Icon.Support
                height={IconStyle.height}
                width={IconStyle.width}
                fill={IconStyle.fill}
            />
        ), // 아이콘 표시
        link: "", // 페이지 이동 버튼일 때만
        child: [
            // {
            //     title: "이슈 목록",
            //     link: "/iss/list",
            //     linkedPage: ["/iss/detail", "/iss/upsert"],
            //     child: [],
            //     /**추가된거임 */
            // },
            {
                title: "AS 코드",
                link: "/iss/code",
                linkedPage: [],
                child: [],
            },
        ],
    },
    // {
    //     title: "에이전시",
    //     icon: <FontAwesomeIcon icon={faBuilding}></FontAwesomeIcon>, // 아이콘 표시
    //     link: "", // 페이지 이동 버튼일 때만
    //     child: [
    //         {
    //             title: "에이전시 계정관리",
    //             link: "/agency/account",
    //             child: [],
    //         },
    //     ],
    // },
    {
        title: "고객관리",
        icon: (
            <Icon.Users
                height={IconStyle.height}
                width={IconStyle.width}
                fill={IconStyle.fill}
            />
        ), // 아이콘 표시
        link: "", // 페이지 이동 버튼일 때만
        child: [
            {
                title: "Push 알림",
                link: "/purchaser/push",
                child: [],
            },
            {
                title: "멤버십 현황",
                link: "/membership",
                child: [],
                linkedPage: ["/membership/detail"],
            },
        ],
    },
    {
        title: "렌탈",
        icon: (
            <Icon.Motobike
                width={IconStyle.width}
                height={IconStyle.height}
                fill={IconStyle.fill}
            />
        ), // 아이콘 표시
        link: "", // 페이지 이동 버튼일 때만
        child: [
            {
                title: " 바이크 관리",
                link: "/rental/bike",
                linkedPage: ["/rental/bikedetail"],
                child: [],
            },
            {
                title: " 고객 관리",
                link: "/rental/user",
                linkedPage: ["/rental/userdetail"],
                child: [],
            },
            // {
            //     title: " 결제 내역",
            //     link: "/rental/payment",
            //     child: [],
            // },
        ],
    },
    {
        title: "주문 관리",
        icon: (
            <Icon.Truck
                width={IconStyle.width}
                height={IconStyle.height}
                fill={IconStyle.fill}
            />
        ), // 아이콘 표시
        link: "", // 페이지 이동 버튼일 때만
        child: [
            {
                title: " 전체 주문 관리",
                link: "/order/main",
                linkedPage: ["/order/detail"],
                child: [],
            },
            // {
            //     title: "주문 등록",
            //     link: "/order/insert",
            //     linkedPage: ["/order/detail"],
            //     child: [],
            // },
            // {
            //     title: " 결제 내역",
            //     link: "/rental/payment",
            //     child: [],
            // },
        ],
    },

    {
        title: "업무요청",
        icon: (
            <Icon.Print
                width={IconStyle.width}
                height={IconStyle.height}
                fill={IconStyle.fill}
            />
        ), // 아이콘 표시
        link: "/request/main",
        child: [],
        linkedPage: ["/request/detail", "/request/insert"],
    },
    {
        title: "실험실",
        icon: (
            <Icon.Flask
                width={IconStyle.width}
                height={IconStyle.height}
                fill={IconStyle.fill}
            />
        ), // 아이콘 표시
        link: "",
        child: [
            {
                title: "폴리라인",
                link: "/lab/polyline",
                child: [],
            },
            {
                title: "데이터시각화",
                link: "/lab/datavisual",
                child: [],
            },
            {
                title: "바이크",
                link: "/lab/bike",
                child: [],
                linkedPage: ["/lab/bikedetail"],
            },
            {
                title: "펌웨어",
                link: "/lab/firm",
                child: [],
                linkedPage: ["/lab/firmdetail"],
            },
            {
                title: "하드웨어",
                link: "/lab/hard",
                child: [],
                linkedPage: ["/assets/harddetail"],
            },
        ],
    },
    {
        title: "게시판 관리",
        icon: (
            <Icon.Document
                width={IconStyle.width}
                height={IconStyle.height}
                fill={IconStyle.fill}
            />
        ),
        link: "",
        child: [
            {
                title: "공지사항",
                link: "/bbs/notice",
                child: [],
                linkedPage: ["/bbs/notice"],
            },
            {
                title: "FAQ",
                link: "/bbs/faq",
                child: [],
                linkedPage: ["/bbs/faq"],
            },
            {
                title: "에러코드 안내",
                link: "/bbs/errorcode",
                child: [],
                linkedPage: ["/bbs/errorcode"],
            },
            {
                title: "출고현황",
                link: "/bbs/shippmentstatus",
                child: [],
                linkedPage: ["/bbs/shippmentstatus"],
            },
            {
                title: "이벤트",
                link: "/bbs/event",
                child: [],
                linkedPage: ["/bbs/event"],
            },
        ],
    },

    // {
    //     title: "결제",
    //     icon: <FontAwesomeIcon icon={faCashRegister}></FontAwesomeIcon>,
    //     link: "",
    //     child: [
    //         {
    //             title: " 멤버십 관리",
    //             link: "/payment/membership",
    //             child: [],
    //         },
    //         {
    //             title: " 결제 내역",
    //             link: "/payment/history",
    //             child: [],
    //         },
    //     ],
    // },
]

export default MenuList
