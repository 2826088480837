import styled from "styled-components"

export const RefreshBtn = styled.button`
    border: none;
    outline: none;
    background-color: #1b4af0;
    padding: 5px;
    border-radius: 5px;
    color: white;
    margin-left: 5px;
    cursor: pointer;
    :disabled {
        background-color: #bcbcbc;
        cursor: not-allowed;
    }
`
export const ClipDiv = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;
`
