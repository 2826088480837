import { Input, LabelForm, Select, Col, Row, Radio } from "p-ui"
import { FlexDiv } from "@styles"

import { getManagerName, getOrgName } from "@common/util"

import { priority, req_orgNm, req_type, service_code } from "../constants"

import { ReqTask } from "@type/responseType"
interface inserHeaderProps {
    mode?: string
    setReqType?: (value?: any) => void
    requestData?: ReqTask
    setRequestParam?: any
    requestParam?: any
}

const InsertHeader = (props: inserHeaderProps) => {
    const { mode, setReqType, requestData, setRequestParam, requestParam } = props

    const changeForm = (e: any) => {
        setRequestParam({ ...requestParam, [e.name]: e.value })
    }

    return (
        <>
            <Row
                gap={10}
                marginBottom={10}
            >
                <>
                    <Col sm={12}>
                        <LabelForm
                            required
                            label="제목"
                            labelCol={1.5}
                            formCol={10.5}
                        >
                            <Input
                                name="reqTitle"
                                onChange={e => changeForm(e)}
                                placeholder="제목 입력"
                                readonly={mode === "detail"}
                                value={requestData && requestData.reqTitle}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={6}>
                        <LabelForm
                            required
                            label="개발유형"
                        >
                            <Select
                                name="reqType"
                                onChange={(label: string, value: any) => {
                                    setReqType && setReqType(value)
                                    setRequestParam({ ...requestParam, reqType: value })
                                }}
                                defaultValue={requestData ? requestData.reqType : "select"}
                                readonly={mode === "detail"}
                                options={req_type}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={6}>
                        <LabelForm
                            required
                            label="요청서비스"
                        >
                            <Select
                                name="serviceCode"
                                onChange={(label: string, value: any) => {
                                    setRequestParam({ ...requestParam, serviceCode: value })
                                }}
                                defaultValue={requestData ? requestData.serviceCode : "select"}
                                readonly={mode === "detail"}
                                options={service_code}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={6}>
                        <LabelForm
                            required
                            label="요청부서"
                        >
                            <Select
                                name="reqOrgNm"
                                onChange={(label: string, value: any) => {
                                    setRequestParam({ ...requestParam, reqOrgNm: value })
                                }}
                                readonly={mode === "detail"}
                                options={req_orgNm}
                                defaultValue={requestData ? requestData.reqOrgNm : getOrgName()?.toString()}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={6}>
                        <LabelForm
                            required
                            label="요청자"
                        >
                            <Input
                                name="reqUserNm"
                                onChange={e => changeForm(e)}
                                placeholder="요청자 이름 입력"
                                readonly={mode === "detail"}
                                value={requestData ? requestData.reqUserNm : getManagerName()?.toString()}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={6}>
                        <LabelForm
                            required
                            label="담당부서"
                        >
                            <Select
                                name="taskOrgNm"
                                readonly
                                defaultValue={"시스템개발"}
                                options={[{ label: "시스템개발", value: "시스템개발" }]}
                            />
                        </LabelForm>
                    </Col>
                    <Col sm={6}>
                        <LabelForm
                            required
                            fullHeight
                            label="긴급여부"
                        >
                            <FlexDiv height="100%">
                                <Radio.Group
                                    onChange={e => {
                                        setRequestParam({ ...requestParam, priority: Number(e) })
                                    }}
                                    value={requestData ? requestData.priority : 1}
                                    disabled={mode === "detail"}
                                    options={priority}
                                ></Radio.Group>
                            </FlexDiv>
                        </LabelForm>
                    </Col>
                </>
            </Row>
        </>
    )
}

export default InsertHeader
