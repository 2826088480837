import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import PageTitle from "@components/widgets/PageTitle"
import { DataTables } from "@components/widgets/dataTable"

import { Button, Select, Input, Row, Col } from "p-ui/dist/esm/components/widget"
import { Circle, StatusBox, StatusBoxContent, StatusBoxTitle } from "./styles"
import { MainDashBoardBox } from "../styles"
import { FlexDiv } from "@styles"

import apis from "@apis"

import { Issue } from "@common/constatns"
const IssueList = () => {
    const navigate = useNavigate()
    const [listData, setListData] = useState([] as any)
    const [filterList, setFilterList] = useState([] as any)
    const [keyword, setKeyword] = useState<string>("")
    const [category, setCategory] = useState<string>("all")
    const getIssueList = async () => {
        const issueListData = await apis.Iss.getDataList()
        setListData(issueListData.resultData)
        setFilterList(issueListData.resultData)
    }
    const filterDataList = () => {
        let result = null

        if (category == "all") {
            result = listData?.filter((row: any) => {
                const rowValue = JSON.stringify(row).toLowerCase()
                if (rowValue.includes(keyword.toLowerCase())) {
                    return true
                }
            })
        } else if (category == "btSerial") {
            result = listData?.filter((row: any) => {
                const bt1Value = JSON.stringify(row["btSerialNo1"]).toLowerCase()
                const bt2Value = JSON.stringify(row["btSerialNo2"]).toLowerCase()
                if (bt1Value.includes(keyword.toLowerCase()) || bt2Value.includes(keyword.toLowerCase())) {
                    return true
                }
            })
        } else {
            result = listData?.filter((row: any) => {
                console.log(category)
                console.log(row[category])

                const rowValue = JSON.stringify(row[category]).toLowerCase()
                if (rowValue.includes(keyword.toLowerCase())) {
                    return true
                }
            })
        }

        setFilterList(result)
    }

    useEffect(() => {
        getIssueList()
    }, [])

    const status = [
        { status: "전체", color: "var(--secondary)", count: listData?.length, value: 99 },
        {
            status: "발생",
            color: "var(--danger)",
            count: listData?.filter((item: { status: number }) => item.status == 1).length,
            value: 0,
        },
        {
            status: "확인예정",
            color: "var(--primary)",
            count: listData?.filter((item: { status: number }) => item.status == 2).length,
            value: 1,
        },
        {
            status: "처리중",
            color: "var(--warning)",
            count: listData?.filter((item: { status: number }) => item.status == 3).length,
            value: 2,
        },
        {
            status: "완료",
            color: "var(--success)",
            count: listData?.filter((item: { status: number }) => item.status == 9).length,
            value: 3,
        },
    ]
    return (
        <div>
            {/* PAGE TITLE */}
            <PageTitle
                topMenu="이슈"
                subMenu="목록"
            />
            {/* Status Box */}
            <Row marginBottom="40px">
                {status.map(st => {
                    return (
                        <Col
                            sm={2}
                            key={st.status}
                        >
                            <StatusBox
                                color={st.color}
                                style={{ boxShadow: "rgb(0 0 0 / 10%) 2px 3px 4px 3px" }}
                            >
                                <StatusBoxTitle className="title">
                                    <Circle color={st.color} />
                                    {st.status}
                                </StatusBoxTitle>
                                <StatusBoxContent>이슈 갯수 : {st.count}</StatusBoxContent>
                            </StatusBox>
                        </Col>
                    )
                })}
            </Row>
            {/* Table */}
            <MainDashBoardBox height={150}>
                <Row marginBottom="10px">
                    <Col sm={1}>
                        <Select
                            onChange={(name: string, value: any) => setCategory(value.value)}
                            options={Issue.columns}
                            defaultValue={Issue.columns[0].value}
                            // value={{ label: "제목", value: "title" }}
                        ></Select>
                    </Col>
                    <Col sm={3}>
                        <Input
                            placeholder="Search..."
                            onSearch={() => {
                                filterDataList()
                            }}
                            onKeyUp={(e: any) => {
                                if (e.key == "Enter") {
                                    setKeyword(e.target.value)
                                    filterDataList()
                                }
                            }}
                            onChange={(e: any) => setKeyword(e.target.value)}
                        />
                    </Col>
                    <Col sm={5} />
                    <Col sm={3}>
                        <FlexDiv justifyContent="flex-end">
                            <Button
                                type="default"
                                onClick={() => navigate("/iss/upsert", { replace: true })}
                            >
                                글쓰기
                            </Button>
                        </FlexDiv>
                    </Col>
                </Row>
                <DataTables
                    // bordered
                    isIssue={true}
                    columns={Issue.tableColumn}
                    dataList={filterList}
                    router={{ pathname: "/iss/detail", search: "?id" }}
                    pagination={true}
                />
            </MainDashBoardBox>
        </div>
    )
}

export default IssueList
