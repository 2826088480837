import ReactDatePicker from "react-datepicker"
import { useState, useEffect } from "react"

import { Input, LabelForm, Radio, Select } from "p-ui"
import { DataPickerInput, FlexColDiv, FlexDiv } from "@styles"
import CustomDatePicker from "@components/widgets/customDatePicker"

import { ko } from "date-fns/locale"
import utils from "@utils"

import { RentalDepositStatus, RentalFleetConsentFg } from "@common/constatns"
import { areDatesEqual, extractTimeFromDateString } from "src/utils/date"
import { setHours, setMinutes } from "date-fns"

const RentalFleetEditModal = (props: any) => {
    const { rentalFleetBikeData, setEditForm, lastEndDate } = props
    const { leaseUserNm, leaseReason, leaseType, consentFg, depositStatus, startMgrNm, startDt } =
        rentalFleetBikeData || {}
    const currentDate = new Date()
    const [startDate, setStartDate] = useState(currentDate)
    const [isSetMinTime, setIsSetMinTime] = useState(false)

    useEffect(() => {
        setStartDate(startDt === null ? currentDate : new Date(startDt))
        setIsSetMinTime(areDatesEqual(startDt === null ? currentDate : new Date(startDt), new Date(lastEndDate)))
    }, [])

    return (
        <FlexColDiv gap="16px">
            <LabelForm label="사용자">
                <Input
                    name="leaseUserNm"
                    defaultValue={leaseUserNm}
                    placeholder={"사용자 이름을 적어주세요."}
                    onChange={(e: any) => {
                        setEditForm(e.name, e.value)
                    }}
                />
            </LabelForm>
            <LabelForm label="사유">
                <Input
                    name="leaseReason"
                    defaultValue={leaseReason}
                    placeholder={"사유를 적어주세요."}
                    onChange={(e: any) => {
                        setEditForm(e.name, e.value)
                    }}
                />
            </LabelForm>
            {leaseType !== 0 && leaseType !== 1 && (
                <>
                    <LabelForm label="동의서">
                        <FlexDiv height="50px">
                            <Radio.Group
                                defaultValue={RentalFleetConsentFg.find(item => consentFg === item.value)?.label}
                                options={RentalFleetConsentFg.map(item => item.label)}
                                onChange={e =>
                                    setEditForm("consentFg", RentalFleetConsentFg.find(item => e === item.label)?.value)
                                }
                            />
                        </FlexDiv>
                    </LabelForm>
                    <LabelForm label="보증">
                        <Select
                            name="depositStatus"
                            options={RentalDepositStatus.map(item => item.label)}
                            defaultValue={RentalDepositStatus.find(item => depositStatus === item.value)?.label}
                            onChange={(label: string, value: any) => {
                                setEditForm(label, RentalDepositStatus.find(item => item.label === value)?.value)
                            }}
                        />
                    </LabelForm>
                </>
            )}
            <LabelForm label="시작담당자">
                <Input
                    name="startMgrNm"
                    defaultValue={startMgrNm}
                    placeholder={"시작담당자 이름을 적어주세요."}
                    onChange={(e: any) => {
                        setEditForm(e.name, e.value)
                    }}
                />
            </LabelForm>
            <LabelForm label="시작일">
                <CustomDatePicker
                    width={"100%"}
                    height={"50px"}
                >
                    <ReactDatePicker
                        selected={startDate}
                        onChange={(date: Date) => {
                            setStartDate(date)
                            setEditForm("startDt", date.format(utils.FORMAT_SHORT_DATETIME))
                            setIsSetMinTime(areDatesEqual(date, new Date(lastEndDate)))
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd HH:mm"
                        showTimeSelect
                        timeIntervals={5}
                        minDate={new Date(lastEndDate)}
                        minTime={setHours(
                            setMinutes(new Date(), isSetMinTime ? extractTimeFromDateString(lastEndDate).minutes : 0),
                            isSetMinTime ? extractTimeFromDateString(lastEndDate).hours : 0
                        )}
                        maxTime={setHours(setMinutes(new Date(), 30), 23)}
                        customInput={<DataPickerInput height={"50px"} />}
                        withPortal
                        onKeyDown={e => {
                            e.preventDefault()
                        }}
                    />
                </CustomDatePicker>
            </LabelForm>
        </FlexColDiv>
    )
}

export default RentalFleetEditModal
