import { FlexDiv } from "@styles"
import styled from "styled-components"

export const BbsClipDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    width: fit-content;
    height: 24px;
    margin: 8px 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: var(--primary);
    }
`

export const BbsFlexDiv = styled(FlexDiv)`
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`
