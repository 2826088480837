import styled from "styled-components"
export const PageUl = styled.ul`
    float: left;
    list-style: none;
    text-align: center;
    border-radius: 3px;
    color: #040404;
    padding: 1px;
    background-color: rgba(255, 255, 255, 0.4);
`

export const PageLi = styled.li`
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    padding: 5px;
    border-radius: 5px;
    width: 40px;
    &:hover {
        cursor: pointer;
        color: #002bba;
    }
    &:focus::after {
        color: #002bba;
    }
    .active {
        color: white;
        background-color: #1f2949;
    }
`

export const PageSpan = styled.span`
    padding: 5px;
    display: block;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    &:hover::after,
    &:focus::after {
        color: white;
        background-color: #1f2949;
    }
    .active {
        color: white;
        background-color: #1f2949;
    }
`

export const MoveButton = styled.button`
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    transform: scale(1.5);
    :hover {
        transform: scale(1.7);
    }
`
export const TableHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 16px;
`

export const Label = styled.label`
    font-family: "Spoqa Han Sans Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    margin-right: 0.5rem;
`

export const Table = styled.table`
    border-collapse: collapse;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    border-radius: 5px;
    border-style: hidden;
    /* box-shadow: 0 0 0 1px var(--gray); */
    box-shadow: 0px 1px 1px -0.5px var(--gray);
    overflow: hidden;
    .title-td-panel {
        color: #2d5bff;
        cursor: pointer;
        text-decoration: underline;
    }
`

export const Th = styled.th`
    font-family: "Spoqa Han Sans Neo";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    border-bottom: 1px solid var(--gray);
    padding: 1rem;
    background-color: var(--light-gray);
    position: relative;
    ::after {
        content: "|";
        color: #ddd;
        float: right;
        margin-right: -17px;
    }
    :last-child::after {
        content: "";
    }
`

export const Td = styled.td`
    font-family: "Spoqa Han Sans Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 0.4rem;
    border-bottom: 1px solid var(--gray);
    padding-left: 1rem;
    padding-right: 1rem;
`
export const $TableOptionPanel = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const PaginationDiv = styled.div`
    display: flex;
    justify-content: flex-end;
`
