import React, { useEffect, useRef } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
    position: absolute;
    z-index: 100000;
    bottom: -8px;
    left: -42px;
`

type Props = React.PropsWithChildren<{
    onClose: () => void
}>

export const Popover = React.memo<Props>(props => {
    const { onClose, children } = props

    const settingsWindowRef = useRef<any>(null)

    useEffect(() => {
        const pageClickEvent = (e: any) => {
            if (!settingsWindowRef.current.contains(e.target)) {
                onClose()
            }
        }

        window.addEventListener("click", pageClickEvent, true)

        return () => {
            window.removeEventListener("click", pageClickEvent, true)
        }
    })

    return <Wrapper ref={settingsWindowRef}>{children}</Wrapper>
})
