import { useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router"

import { Rental, ZentalSearchOption, zentalStatus } from "@pages/Rental/constants"
import { CheckBox, Icon } from "p-ui"
import { Button, Col, DataTable, Input, PageHeader, Row, Select, Tag } from "p-ui"
import { DataPickerInput, FlexDiv, Label, Text } from "@styles"
import ReactDatePicker from "react-datepicker"
import CustomDatePicker from "@components/widgets/customDatePicker"

import ZENTAL from "src/apis/zental"

import useAlert from "@context/alert/useAlert"
import { downloadExcel, observPage, updateActivePage } from "@common/util"
import { ko } from "date-fns/esm/locale"
import { endOfMonth, isWithinInterval, parseISO, startOfMonth } from "date-fns"

import { onChangeParam } from "@type/common"
import { ZtBike } from "@type/responseType"
import { StatusOptions } from "@pages/Rental/Components/ZentalConstant"
import { onChangeFilter } from "src/utils/filter"
import { useSearchParams } from "react-router-dom"
import moment from "moment"
import { PageRow } from "p-ui/dist/esm/types"

export interface dataInfo {
    title: string
    dataIndex: string
    render?: (params: any) => void
}

function RentalBikePage() {
    const navigate = useNavigate()
    const { openAlert } = useAlert()

    const tableRef = useRef<HTMLTableElement | null>(null)

    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchTextParam = searchParams?.get("searchText")
    const searchTypeParam = searchParams?.get("searchType")
    const rentalTypeparam = searchParams?.get("rentalType")
    const isPeriodParam = searchParams?.get("isPeriod")
    const periodParam = searchParams?.get("period")
    const pageParam = searchParams.get("page")
    const limitParam = searchParams.get("limit")

    const [column, setColumn] = useState<dataInfo[]>(Rental.bike)
    const [bikeData, setBikeData] = useState<ZtBike[]>([])
    const [filterList, setFilterList] = useState<ZtBike[]>([])
    const [pageRows, setPageRows] = useState<PageRow>(15)

    const [originDataList, setOriginDataList] = useState<ZtBike[]>([])
    const [selectedYM, setSelectedYM] = useState<Date>(periodParam ? new Date(periodParam) : new Date())
    const [searchMonth, setSearchMonth] = useState<boolean>(
        isPeriodParam ? JSON.parse(isPeriodParam.toLowerCase()) : false
    )
    const [searchText, setSearchText] = useState<string>("")
    const [selectedCategory, setSelectedCategory] = useState<string>(searchTypeParam ? searchTypeParam : "ALL")
    const [selectedStatus, setSelectedStatus] = useState<string[]>(
        rentalTypeparam ? rentalTypeparam.split(",") : defaultSelectedStatus.concat("-1")
    )

    const [nowPage, setNowPage] = useState<any>(pageParam !== null ? pageParam : 1)

    const formatPhone = (phoneNumber: string): string => {
        if (!phoneNumber) return ""

        return phoneNumber.replace(/\D/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
    }

    /**
     * 젠탈 바이크 목록 조회
     */
    const getBikeList = async () => {
        await ZENTAL.getZentalBikeList().then(res => {
            if (res.status === "SUCCESS") {
                setBikeData(
                    res.resultData.map((item: any) => {
                        return {
                            ...item,
                            phone: formatPhone(item.phone),
                            statusTag: zentalStatus[item.status],
                        }
                    })
                )
                setOriginDataList(res.resultData)
            }
        })
    }

    /**
     * datatable action 설정
     * @param ztBikeList
     * @returns ZtBike[]
     */
    const filterDataListAction = (ztBikeList: ZtBike[]) => {
        let result = null
        if (selectedCategory == "ALL") {
            result = ztBikeList?.filter((row: any) => {
                return JSON.stringify(row).toLowerCase().includes(searchText.toLowerCase())
            })
        } else {
            result = ztBikeList?.filter((row: any) => {
                return row[selectedCategory] && row[selectedCategory].toLowerCase().includes(searchText.toLowerCase())
            })
        }
        return result
    }

    /**
     * 바이크 동기화
     */
    const syncBike = async () => {
        console.log("동기화?")
        await ZENTAL.syncBike().then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "동기화", body: "동기화 되었습니다", type: "primary" })
                getBikeList()
            } else {
                openAlert({ title: "동기화", body: "동기화 실패", type: "warning" })
            }
        })
    }

    /**
     * 운행 기간이 선택한 년/월 내에 포함 되는지 확인
     */
    const filterDrivingMonth = (list: ZtBike[]) => {
        /**
         * 젠탈 계약이 기간 내에 걸려 있는 지 확인
         * @param startDt string 계약 시작일
         * @param endDt string 계약 종료일
         * @returns
         */
        const check = (startDt: string, endDt: string) => {
            const firstDayOfMonth = startOfMonth(selectedYM)
            const lastDayOfMonth = endOfMonth(selectedYM)
            return (
                isWithinInterval(firstDayOfMonth, {
                    start: parseISO(startDt),
                    end: parseISO(endDt),
                }) ||
                isWithinInterval(lastDayOfMonth, {
                    start: parseISO(startDt),
                    end: parseISO(endDt),
                })
            )
        }

        const result = list.filter(
            // * service 계약이 되어 있는 것만 진행 (serviceStartDt 가 null 이 아닌 것)
            item => item.serviceStartDt && check(item.serviceStartDt.toString(), item.serviceEndDt.toString())
        )

        return result
    }

    /**
     * zental bike 컬럼 세팅
     */
    const setNewColumn = () => {
        /**
         * tag 색상 설정
         * @param status
         * @returns
         */
        const setTagType = (status: string) => {
            switch (status) {
                case "wait":
                case "0":
                case "2":
                case "3":
                case "fail":
                    return "danger"
                case "inspection":
                case "11":
                case "21":
                    return "warning"
                default:
                    return "success"
            }
        }
        const newColumn = column.map(item => {
            switch (item.dataIndex) {
                case "bikeNo":
                    return {
                        ...item,
                        render: (item: any) => {
                            return item ?? <span>등록 필요</span>
                        },
                    }
                case "status":
                    return {
                        ...item,
                        render: (item: string) => {
                            return (
                                <Tag
                                    type={setTagType(item)}
                                    value={zentalStatus[item]}
                                />
                            )
                        },
                    }
                case "bikeId":
                    return {
                        ...item,
                        render: (id: any) => {
                            return (
                                <Button
                                    onClick={() => {
                                        navigate({ pathname: "/rental/bikedetail", search: "?id=" + id })
                                    }}
                                    size="small"
                                    type="tertiary"
                                >
                                    상세보기
                                </Button>
                            )
                        },
                    }

                default:
                    return {
                        ...item,
                        render: (item: string) => {
                            return <span>{item === null ? "-" : item}</span>
                        },
                    }
            }
        })
        setColumn(newColumn)
    }

    const filterData = useMemo(() => {
        let list = [...originDataList]
        if (searchMonth && selectedYM) {
            list = filterDrivingMonth(list)
        }

        if (selectedStatus.length === 0) {
            return []
        }
        if (!selectedStatus.includes("-1") && searchText !== "") {
            return filterDataListAction(list.filter(item => selectedStatus.includes(item.status.toString())))
        }
        if (searchText !== "") {
            return filterDataListAction(list)
        }
        if (selectedStatus.includes("-1") && searchText === "") {
            return [...list]
        }
        // * 기본
        return list.filter(item => selectedStatus.includes(item.status.toString()))
    }, [originDataList, selectedStatus, searchText, searchMonth, selectedYM])

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)

            if (newActivePage === 0) {
                return setNowPage(Number(pageParam))
            }

            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    useEffect(() => {
        getBikeList()
        setNewColumn()
        if (limitParam) {
            setPageRows(Number(limitParam) as unknown as PageRow)
        }
        if (searchTextParam) {
            setSearchText(searchTextParam)
        }
    }, [])

    const filter = () => {
        if (location.search.length === 0) {
            setSearchParams({
                searchText: searchText,
                searchType: selectedCategory,
                page: nowPage,
                limit: pageRows.toString(),
            })
        } else {
            setSearchParams({
                searchText: searchText,
                searchType: selectedCategory,
                rentalType: selectedStatus.toString(),
                isPeriod: searchMonth.toString(),
                period: moment(selectedYM).format("YYYY-MM").toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        }
        setFilterList(filterData)
    }

    useEffect(() => {
        filter()
    }, [bikeData, selectedStatus, searchMonth, selectedYM, nowPage, pageRows])
    return (
        <div>
            <PageHeader
                title="렌탈"
                subTitle="바이크 관리"
            />
            <Row gap="20px">
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv
                        gap="10px"
                        alignItems="center"
                    >
                        <Select
                            width="100px"
                            size="small"
                            options={ZentalSearchOption}
                            defaultValue={selectedCategory}
                            onChange={(label: string, value: any) => setSelectedCategory(value)}
                        />
                        <Input
                            value={searchText}
                            size="small"
                            width="300px"
                            onChange={(e: onChangeParam) => {
                                setSearchText(e.value.toString())
                            }}
                            onSearch={() => {
                                setSearchText(searchText)
                                setNowPage(1)
                                filter()
                            }}
                        />
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>상태</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv
                        alignItems="center"
                        gap="15px"
                    >
                        <CheckBox.Group
                            value={selectedStatus}
                            options={StatusOptions}
                            onChange={(value: any) => {
                                const param = {
                                    clickedFilter: selectedStatus,
                                    checkList: value as [],
                                    defaultFilter: defaultSelectedStatus,
                                    setClickedFilter: setSelectedStatus,
                                    allValue: "-1",
                                }
                                onChangeFilter(param)
                                setNowPage(1)
                                filter()
                            }}
                        />
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>운행기간</Label>
                </Col>
                <Col sm={10}>
                    <FlexDiv
                        gap="10px"
                        alignItems="center"
                    >
                        <CheckBox
                            checked={searchMonth}
                            onChange={() => setSearchMonth(!searchMonth)}
                        >
                            검색
                        </CheckBox>
                        <CustomDatePicker>
                            <ReactDatePicker
                                selected={selectedYM}
                                locale={ko} // 한글로 변경
                                onChange={date => setSelectedYM(date || selectedYM)}
                                showMonthYearPicker
                                dateFormat="yyyy-MM"
                                customInput={<DataPickerInput />}
                            />
                        </CustomDatePicker>
                    </FlexDiv>
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    header={
                        <FlexDiv
                            alignItems="center"
                            gap="5px"
                        >
                            <Button
                                size="small"
                                onClick={() => {
                                    downloadExcel(bikeData, excelHeader, "렌탈_바이크_관리_")
                                }}
                            >
                                엑셀
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => syncBike()}
                            >
                                바이크동기화
                            </Button>
                        </FlexDiv>
                    }
                    pageRow={pageRows}
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(limitParam) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                    columns={column}
                    dataList={filterList}
                    nowPage={nowPage}
                    pagination
                    numbering
                />
            </div>
        </div>
    )
}

export default RentalBikePage

const excelHeader = [
    { label: "바이크 sn", value: "serialNo" },
    { label: "차대번호", value: "vinNo" },
    { label: "차량번호", value: "bikeNo" },
    { label: "전화번호", value: "phone" },
    { label: "생년월일", value: "birthDt" },
    { label: "보증금", value: "deposit" },
    { label: "실패횟수", value: "failCnt" },
    { label: "상태", value: "statusTag" },
    { label: "계약상품", value: "modelName" },
    { label: "계약시작", value: "serviceStartDt" },
    { label: "계약종료", value: "serviceEndDt" },
    { label: "취소일자", value: "cancelDt" },
    // { label: "피보험자", value: "insuranceName" },
    // { label: "보험사", value: "insuranceCompany" },
    // { label: "보험연령", value: "ageOver" },
    { label: "메모", value: "comment" },
    { label: "라이더이름", value: "riderNm" },
    { label: "라이더메일", value: "riderEmail" },
    // { label: "보험시작", value: "insuranceStartDt" },
    // { label: "보험종료", value: "insuranceEndDt" },
]

const defaultSelectedStatus = ["wait", "inspection", "fail", "0", "1", "2", "3", "11", "21"]
