import { Col, Input, LabelForm, Row, Select } from "p-ui"
import { Text } from "@styles"
import { depositOptions } from "./ZentalConstant"

const AssignmentModal = (props: any) => {
    const { bikeData, setTransfereeId, options, setTransfereeDeposit } = props

    return (
        <Row gap="10px">
            <Col sm={12}>
                <LabelForm label="양도자">
                    <Input
                        width="100%"
                        readonly
                        defaultValue={bikeData.riderNm}
                    />
                </LabelForm>
            </Col>
            <Col sm={12}>
                <LabelForm label="양수자">
                    <Select
                        width="100%"
                        onChange={(label, value) => {
                            setTransfereeId(value)
                        }}
                        options={options}
                    />
                </LabelForm>
            </Col>
            <Col sm={12}>
                <LabelForm label="보증금">
                    <Select
                        onChange={(label, value) => {
                            setTransfereeDeposit(value)
                        }}
                        name="deposit"
                        size="small"
                        options={depositOptions}
                        defaultValue={depositOptions[0].value}
                    />
                </LabelForm>
                {/* <LabelForm label="보증금">
                    <Input
                        name="deposit"
                        placeholder="숫자만 입력해주세요"
                        onChange={(e: any) => setTransfereeDeposit(e.value)}
                    />
                </LabelForm> */}
                <Text
                    color="var(--danger)"
                    fontSize="0.8rem"
                >
                    * 양수자의 보증금
                </Text>
            </Col>
        </Row>
    )
}

export default AssignmentModal
