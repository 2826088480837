import { Faq } from "@common/constatns"
import { updateActivePage, observPage } from "@common/util"
import { FlexDiv, Label } from "@styles"
import { Button, CheckBox, Col, DataTable, Input, PageHeader, Row, Tag } from "p-ui"
import { PageRow } from "p-ui/dist/esm/types"
import { useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import faq from "src/apis/faq"
import { onChangeFilter } from "src/utils/filter"

const FaqList = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchTextParam = searchParams?.get("searchText")
    const displayParam = searchParams?.get("display")
    const faqTypeParam = searchParams.get("faqType")
    const pageParam = searchParams.get("page")
    const limitParam = searchParams.get("limit")

    const column: dataInfo[] = Faq
    const tableRef = useRef<HTMLTableElement | null>(null)

    const [faqList, setFaqList] = useState([] as any)
    const [filterList, setFilterList] = useState([] as any)
    const [faqType, setFaqType] = useState<faqTypeData[]>([])
    const [pageRows, setPageRows] = useState<PageRow>(15)

    const [searchQuery, setSearchQuery] = useState("")
    const [clickedDisplayFilter, setClickedDisplayFilter] = useState<number[]>(
        displayParam ? displayParam.split(",").map(Number) : []
    )
    const [clickedTypeFilter, setClickedTypeFilter] = useState<number[]>(
        faqTypeParam ? faqTypeParam.split(",").map(Number) : []
    )
    const [nowPage, setNowPage] = useState<any>(pageParam !== null ? pageParam : 1)

    /**
     * FAQ 목록 조회
     */
    const getFaqList = () => {
        faq.getFaqList().then(res => {
            if (res.status === "SUCCESS") {
                setFaqList(res.resultData)
            }
        })
    }

    /**
     * FAQ 구분 조회
     */
    const getType = (isSearchEmpty: boolean) => {
        faq.getFaqType().then(res => {
            if (res.status === "SUCCESS") {
                setFaqType(prev => {
                    const updatedFaqType = [...prev]
                    res.resultData.forEach(item => {
                        const isDuplicate = updatedFaqType.some(existingItem => existingItem.value === item.codeId)
                        if (!isDuplicate) {
                            updatedFaqType.push({ label: item.codeLabel, value: item.codeId })
                        }
                    })
                    return updatedFaqType
                })
                const values = res.resultData.map(item => item.codeId)
                setClickedTypeFilter(isSearchEmpty ? values : splitParam(faqTypeParam).map(Number))
            }
        })
    }

    const commonMapping = (item: any) => ({
        ...item,
        title: <FlexDiv width="600px">{item.title}</FlexDiv>,
        displayFg: (
            <Tag
                value={item.displayFg === 0 ? "숨김" : "게시중"}
                type={item.displayFg === 0 ? "danger" : "primary"}
            />
        ),
        detail: (
            <Button
                size="small"
                type="secondary"
                onClick={() => {
                    navigate("" + item.faqId, { state: { id: item.faqId } })
                }}
            >
                상세보기
            </Button>
        ),
    })

    const searchList = useMemo(() => {
        if (searchQuery === "") {
            return faqList.filter((item: any) => {
                return clickedTypeFilter.includes(item.faqType) && clickedDisplayFilter.includes(item.displayFg)
            })
        }

        if (clickedDisplayFilter.length === 0) {
            return []
        }
        const jsonData = faqList.map((item: any) => JSON.stringify(item))
        const data = faqList.filter((item: any, index: number) => {
            return (
                clickedTypeFilter.includes(item.faqType) &&
                clickedDisplayFilter.includes(item.displayFg) &&
                jsonData[index].includes(searchQuery)
            )
        })
        return data
    }, [faqList, clickedDisplayFilter, clickedTypeFilter, searchQuery])

    const filter = () => {
        if (location.search.length === 0) {
            setSearchParams({
                searchText: searchQuery,
                page: nowPage,
                limit: pageRows.toString(),
            })

            setClickedDisplayFilter(displayOptions.map(item => item.value))
        } else {
            setSearchParams({
                searchText: searchQuery,
                display: clickedDisplayFilter.toString(),
                faqType: clickedTypeFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        }
        const searchedList = [...searchList]
        const filteredList = searchedList.map(commonMapping)
        setFilterList(filteredList)
    }

    const handleEnterPress = (event: any) => {
        if (event.key === "Enter") {
            setNowPage(1)
            filter()
        }
    }

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)

            if (newActivePage === 0) {
                return setNowPage(Number(pageParam))
            }

            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    const splitParam = (param: any) => {
        if (!param) {
            return []
        }
        return param.split(",")
    }

    useEffect(() => {
        getFaqList()
        getType(location.search.length === 0)
        if (limitParam) {
            setPageRows(Number(limitParam) as unknown as PageRow)
        }
        if (searchTextParam) {
            setSearchQuery(searchTextParam)
        }
    }, [])

    useEffect(() => {
        filter()
    }, [faqList, clickedTypeFilter, clickedDisplayFilter, nowPage, pageRows])

    return (
        <>
            <PageHeader
                title={"FAQ 관리"}
                subTitle="FAQ 목록"
            />
            <Row gap={"20px"}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <Input
                        value={searchQuery}
                        placeholder="검색어를 입력하세요."
                        width="300px"
                        size="small"
                        onChange={(e: any) => setSearchQuery(e.value)}
                        onSearch={() => {
                            setNowPage(1)
                            filter()
                        }}
                        onKeyUp={handleEnterPress}
                    />
                </Col>
                <Col sm={1}>
                    <Label>게시 상태</Label>
                </Col>
                <Col md={11}>
                    <FlexDiv
                        alignItems="center"
                        gap="15px"
                    >
                        <CheckBox.Group
                            value={clickedDisplayFilter}
                            options={displayOptions}
                            onChange={(value: any) => {
                                const param = {
                                    clickedFilter: clickedDisplayFilter,
                                    checkList: value as [],
                                    defaultFilter: displayOptions.map((item: any) => item.value),
                                    setClickedFilter: setClickedDisplayFilter,
                                    allValue: 2,
                                }
                                onChangeFilter(param)
                                setNowPage(1)
                                filter()
                            }}
                        />
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>구분</Label>
                </Col>
                <Col md={11}>
                    <CheckBox.Group
                        value={clickedTypeFilter}
                        options={faqType}
                        onChange={(value: any) => {
                            const param = {
                                clickedFilter: clickedTypeFilter,
                                checkList: value as [],
                                defaultFilter: faqType.map((item: any) => item.value),
                                setClickedFilter: setClickedTypeFilter,
                                allValue: 0,
                            }
                            onChangeFilter(param)
                            setNowPage(1)
                            filter()
                        }}
                    />
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    columns={column}
                    dataList={filterList}
                    pagination
                    nowPage={nowPage}
                    pageRow={pageRows}
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(limitParam) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                    headerRight={
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => navigate("insert")}
                        >
                            등록하기
                        </Button>
                    }
                />
            </div>
        </>
    )
}

export default FaqList

interface dataInfo {
    title: string
    dataIndex: string
    render?: (params: any) => void
}

export interface faqTypeData {
    label: string
    value: number
}

const displayOptions = [
    { label: "숨김", value: 0 },
    { label: "게시중", value: 1 },
]
