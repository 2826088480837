import { ASSETS_CODE } from "@pages/Assets/constants"
import { ExcelData, ObjectType } from "@type/common"
import { Type } from "p-ui"

import { utils, writeFile } from "xlsx"

export const deleteAlert = (callback?: any, params?: any) => {
    if (window.confirm("정말 삭제 하시겠습니까?")) {
        // alert("삭제되었습니다.");
        callback(params)
        console.log("삭제")
    } else {
        alert("취소합니다.")
        console.log("취소")
    }
}
export const saveAlert = (callback?: any) => {
    if (window.confirm("저장 하시겠습니까?")) {
        alert("저장 되었습니다.")
        callback()
    } else {
        alert("취소합니다.")
    }
}

// TODO CHECK:: 시간
export const dateChange = (value: any) => {
    const dayToString = ["일", "월", "화", "수", "목", "금", "토"]
    let selectDay = value
    let year = selectDay.getFullYear()
    let month = ("0" + (selectDay.getMonth() + 1)).slice(-2)
    let day = ("0" + selectDay.getDate()).slice(-2)
    let dow = dayToString[selectDay.getDay()]
    // let hour = "0" + selectDay.getHours()
    // let min = selectDay.getMinutes()

    let dateString = `${year}-${month}-${day} (${dow})`

    return dateString
}

export const validateInput = (value: string, message: string, type: Type.AlertType = "warning"): Type.AlertProps => {
    if (!value) return { open: true, type: type, body: message }
    else return { open: false, type: type, body: message }
}

export const getOrgName = () => {
    return sessionStorage.getItem("ORGNAME")
}

export const getAccount = () => {
    return sessionStorage.getItem("ACCOUNT")
}

export const getConfirmManager = () => {
    return sessionStorage.getItem("CONFIRMMANAGER")
}

export const getManagerName = () => {
    return sessionStorage.getItem("MANAGERNAME")
}

export const getPositionName = () => {
    return sessionStorage.getItem("POSITIONNAME")
}

export const getManagerId = () => {
    return sessionStorage.getItem("MANAGERID")
}

export const downloadExcel = (data: any[], header: any[], title: string) => {
    const downLoadData = JSON.parse(JSON.stringify(data))
    const key = Object?.keys(downLoadData[0])

    const deletedColumn = key.filter(item => {
        return !header.some(obj => obj.value === item)
    })

    const wb = utils.book_new()
    // 열 순서를 sheet화
    const ws = utils.aoa_to_sheet([]) // 빈 시트로 생성
    const keysSet = new Set<keyof ExcelData>()
    header.forEach((item, index) => {
        ws[utils.encode_cell({ r: 0, c: index })] = {
            t: "",
            v: item.label,
        }
    })
    // ws에 excelTableHead를 추가
    // any가 아니면 키 삭제가 안됨
    const excelDataList = downLoadData.map((item: any) => {
        deletedColumn.forEach(column => {
            delete item[column]
        })
        const keys = Object.keys(item)
        keys.forEach(key => keysSet.add(key))
        return item
    })

    const colWidths = new Array(header.length).fill(0)

    excelDataList.forEach((item: any) => {
        header.forEach((headerItem, index) => {
            let cellWidth = 0
            const content = item[headerItem.value]?.toString() || ""

            const contentLength = content?.length
            if (!isNaN(parseFloat(content))) {
                cellWidth = contentLength * 1.3
            } else {
                const isKorean = content.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/)
                cellWidth = isKorean ? (contentLength + 1) * 1.2 + 8 : contentLength + 3
            }
            colWidths[index] = Math.max(colWidths[index], cellWidth > 0 ? cellWidth : 3)

            item[headerItem.value] = content
        })
    })

    utils.sheet_add_json(ws, excelDataList, {
        header: header.map(item => item.value),
        skipHeader: true, // 헤더 행은 이미 추가했으므로 건너뛰도록 설정
        origin: "A2", // 데이터 추가할 시작 셀을 A2로 설정
    })

    ws["!cols"] = colWidths.map(width => ({ wch: width }))

    utils.book_append_sheet(wb, ws, "Report")
    // Set Time
    const date = new Date()
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const currentDate = `${year}-${month}-${day}`
    // Set File Name
    writeFile(wb, `${title}${currentDate}.xlsx`)

    return "TEST"
}

export const formatPhone = (phoneNumber: string): string => {
    if (!phoneNumber) return ""

    return phoneNumber.replace(/\D/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
}

/**
 * insurance validation
 * @returns
 */
export const insuranceValidate = () => {
    const fieldList: string[] = ["ageOver", "insuranceCompany", "insuranceName", "startDt", "endDt"]
    const notValidMessage: ObjectType = {
        ageOver: "보험연령을 입력해주세요",
        insuranceCompany: "보험사를 입력해주세요",
        insuranceName: "피보험자를 입력해주세요",
        startDt: "보험 시작 일자를 입력해주세요",
        endDt: "보험 종료 일자를 입력해 주세요",
    }

    return { fieldList, notValidMessage }
}

/**
 * 대차 등록 모달 Validate
 * @returns
 */
export const startModalValidate = () => {
    const fieldList: string[] = ["leaseUserNm", "leaseReason", "startMgrNm"]
    const notValidMessage: ObjectType = {
        leaseUserNm: "사용자 이름을 입력해주세요",
        leaseReason: "사유를 입력해주세요",
        startMgrNm: "시작 담당자 이름을 입력해주세요",
    }

    return { fieldList, notValidMessage }
}

/**
 * 대차 종료 모달 Validate
 * @returns
 */
export const endModalValidate = () => {
    const fieldList: string[] = ["endMgrNm"]
    const notValidMessage: ObjectType = {
        endMgrNm: "종료담당자 이름을 입력해주세요",
    }

    return { fieldList, notValidMessage }
}

/**
 * 대차 점검 필요 모달 Validate
 * @returns
 */
export const unusabledValidate = () => {
    const fieldList: string[] = ["leaseReason", "startMgrNm"]
    const notValidMessage: ObjectType = {
        leaseReason: "사유를 입력해주세요",
        startMgrNm: "시작 담당자 이름을 입력해주세요",
    }

    return { fieldList, notValidMessage }
}

/**
 * 대차 사용가능 모달 Validate
 * @returns
 */
export const usabledValidate = () => {
    const fieldList: string[] = ["endMgrNm"]
    const notValidMessage: ObjectType = {
        endMgrNm: "종료담당자 이름을 입력해주세요",
    }

    return { fieldList, notValidMessage }
}

/**
 * 공지사항 Validate
 * @returns {fieldList, notValidMessage}
 */
export const noticeValidate = () => {
    const fieldList: string[] = ["title", "postingDate", "noticeType", "contents"]
    const notValidMessage: ObjectType = {
        title: "제목을 입력해주세요.",
        postingDate: "작성일을 입력해주세요.",
        noticeType: "구분을 선택해주세요.",
        contents: "공지사항 내용을 입력해주세요.",
    }

    return { fieldList, notValidMessage }
}
/**
 * FAQ Validate
 * @returns {fieldList, notValidMessage}
 */
export const faqValidate = () => {
    const fieldList: string[] = ["title", "faqType", "contents"]
    const notValidMessage: ObjectType = {
        title: "제목을 입력해주세요.",
        faqType: "구분을 선택해주세요.",
        contents: "faq 내용을 입력해주세요.",
    }

    return { fieldList, notValidMessage }
}

/**
 * BikeErrorCode Validate
 * @returns {fieldList, notValidMessage}
 */
export const bikeErrorCodeValidate = () => {
    const fieldList: string[] = ["errorCode", "codeType", "errorDesc"]
    const notValidMessage: ObjectType = {
        errorCode: "에러코드명을 입력해주세요.",
        codeType: "구분을 선택해주세요.",
        errorDesc: "에러코드 설명을 입력해주세요.",
    }

    return { fieldList, notValidMessage }
}

export const firmwareUpdateValidate = () => {
    const fieldList: string[] = ["bikeSerial", "edcuVersion", "icsVersion", "mcuVersion", "managerId", "applyDt"]
    const notValidMessage: ObjectType = {
        edcuVersion: "업데이트하는 펌웨어를 선택해주세요.",
        icsVersion: "업데이트하는 펌웨어를 선택해주세요.",
        mcuVersion: "업데이트하는 펌웨어를 선택해주세요.",
        managerId: "담당자를 선택해 주세요",
        applyDt: "업데이트일을 선택해주세요",
    }
    return { fieldList, notValidMessage }
}
/**
 * string 타입 시간을 YYYY-MM-DD hh:mm로 변환
 * @param dateString
 * @returns string
 */
export const formatYYYYMMDDhhmm = (dateString: string): string => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")

    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`
    return formattedDate
}
export const formatYYYYMMDDhhmmss2 = (dateString: string): string => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")

    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const seconds = String(date.getSeconds()).padStart(2, "0")

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    return formattedDate
}
export const formatYYYYMMDDhhmmss = (dateString: string): string => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")

    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const seconds = String(date.getSeconds()).padStart(2, "0")

    const formattedDate = `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분 ${seconds}초`
    return formattedDate
}
/**
 * string 타입인 시간을 YYYY-MM-DD로 변환
 * @param dateString
 * @returns string
 */
export const formatYYYYMMDD = (dateString: string): string => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")

    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
}

export const observPage = (observePage: MutationCallback, ref: any) => {
    const observer = new MutationObserver(observePage)

    if (ref.current) {
        observer.observe(ref.current, { childList: true, subtree: true })
    }

    return () => {
        observer.disconnect()
    }
}

export const updateActivePage = (ref: HTMLTableElement | null) => {
    if (ref) {
        const activeNumber = ref.querySelector<HTMLAnchorElement>(".pui-active a")?.innerText
        const newActivePage = Number(activeNumber) || 0
        return newActivePage
    } else {
        // 처리할 내용이나 오류 처리
        return 1 // 기본값 또는 오류 처리에 맞게 반환값 지정
    }
}

export function getFwHwCode(deviceType: string, firmwareType: string) {
    let deviceTypeCode, firmwareTypeCode
    switch (deviceType) {
        case "BIKE_FW":
            deviceTypeCode = ASSETS_CODE.BIKE_FW
            break
        case "BIKE_HW":
            deviceTypeCode = ASSETS_CODE.BIKE_HW
            break
        case "STATION_FW":
            deviceTypeCode = ASSETS_CODE.STATION_FW
            break
        case "BATTERY_FW":
            deviceTypeCode = ASSETS_CODE.BATTERY_FW
            break
    }

    switch (firmwareType) {
        case "ICS":
            firmwareTypeCode = ASSETS_CODE.ICS_FW
            break
        case "MCU":
            firmwareTypeCode = ASSETS_CODE.MCU_FW
            break
        case "EDCU":
            deviceTypeCode == ASSETS_CODE.BIKE_HW
                ? (firmwareTypeCode = ASSETS_CODE.EDCU_HW)
                : (firmwareTypeCode = ASSETS_CODE.EDCU_FW)
            break
    }

    return { deviceTypeCode, firmwareTypeCode }
}
