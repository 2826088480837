import React from "react"
import { Button } from "p-ui"
import { Icon } from "p-ui"
import { useNavigate } from "react-router"
import { FlexColDiv } from "@styles"
import { useSetRecoilState } from "recoil"
import { axiosClearAuthHeader } from "@apis"
import AppState from "@store"

const NotFound = () => {
    const setLoginState = useSetRecoilState<boolean>(AppState.auth.loginState)
    const setToken = useSetRecoilState<string>(AppState.auth.tokenState)
    const setUserState = useSetRecoilState<string>(AppState.auth.userState)
    const navigate = useNavigate()
    const redirectAction = () => {
        if (sessionStorage.getItem("MENU_LIST")) {
            navigate("/station")
        } else {
            setToken("")
            setLoginState(false)
            setUserState("")
            axiosClearAuthHeader()
            sessionStorage.clear()
            navigate("/")
        }
    }
    return (
        <FlexColDiv
            alignItems="center"
            justifyContent="center"
            height="100%"
        >
            <Icon.Sad
                fill="red"
                width={"50"}
                height={"50"}
            />
            <h1>페이지 접근 권한이 없습니다.</h1>
            <Button
                type="secondary"
                size="small"
                onClick={() => redirectAction()}
            >
                돌아가기
            </Button>
        </FlexColDiv>
    )
}

export default NotFound
