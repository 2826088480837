import React, { useState } from "react"
import { MoveButton, PageLi, PageSpan, PageUl } from "./styles"

const Pagination = ({ postsPerPage, totalPosts, paginate, page }: any) => {
    const pageNumbers = []
    let numPages = Math.ceil(totalPosts / postsPerPage)
    const [select, setSelect] = useState<number>(1)
    const [currPage, setCurrPage] = useState(page)

    // let firstNum = currPage - (currPage % numPages) + 1;
    let firstNum = 1
    let lastNum = currPage - (currPage % numPages) + numPages

    if (numPages > 5) {
        firstNum = currPage - (currPage % 5) + 1
        lastNum = currPage - (currPage % 5) + 5
    }
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage) - 1; i++) {
        pageNumbers.push(i)
    }

    return (
        <>
            {numPages > 5 ? (
                <div>
                    <div>
                        <PageUl className="pagination">
                            <MoveButton
                                disabled={page === 1}
                                onClick={() => {
                                    paginate(page - 1)
                                    setCurrPage(page - 2)
                                    setSelect(page - 1)
                                }}
                            >
                                &lt;
                            </MoveButton>
                            <PageLi
                                onClick={() => {
                                    paginate(firstNum)
                                    setSelect(firstNum)
                                }}
                            >
                                <PageSpan className={select == firstNum ? "active" : ""}>{firstNum}</PageSpan>
                            </PageLi>
                            {Array(4)
                                .fill(1)
                                .map((_, i) => {
                                    if (i <= 2) {
                                        return (
                                            <PageLi
                                                key={i + 1}
                                                onClick={() => {
                                                    paginate(firstNum + 1 + i)
                                                    setSelect(firstNum + 1 + i)
                                                }}
                                            >
                                                <PageSpan className={select == firstNum + i + 1 ? "active" : ""}>
                                                    {firstNum + 1 + i}
                                                </PageSpan>
                                            </PageLi>
                                        )
                                    } else if (i >= 3) {
                                        return (
                                            <PageLi
                                                key={i + 1}
                                                onClick={() => {
                                                    paginate(lastNum)
                                                    setSelect(lastNum)
                                                }}
                                            >
                                                <PageSpan className={select == lastNum ? "active" : ""}>
                                                    {lastNum}
                                                </PageSpan>
                                            </PageLi>
                                        )
                                    }
                                })}
                            <MoveButton
                                disabled={page === numPages}
                                onClick={() => {
                                    paginate(page + 1)
                                    setCurrPage(page)
                                    setSelect(page + 1)
                                }}
                            >
                                &gt;
                            </MoveButton>
                        </PageUl>
                    </div>
                </div>
            ) : (
                <div>
                    <div>
                        <PageUl className="pagination">
                            <MoveButton
                                disabled={page === 1}
                                onClick={() => {
                                    paginate(page - 1)
                                    setCurrPage(page - 2)
                                    setSelect(page - 1)
                                }}
                            >
                                &lt;
                            </MoveButton>
                            <PageLi
                                onClick={() => {
                                    paginate(firstNum)
                                    setSelect(firstNum)
                                }}
                            >
                                <PageSpan className={select == firstNum ? "active" : ""}>{firstNum}</PageSpan>
                            </PageLi>
                            {pageNumbers.map((_, i) => {
                                if (i <= numPages - 3) {
                                    return (
                                        <PageLi
                                            key={i + 1}
                                            onClick={() => {
                                                paginate(firstNum + 1 + i)
                                                setSelect(firstNum + 1 + i)
                                            }}
                                        >
                                            <PageSpan className={select == firstNum + i + 1 ? "active" : ""}>
                                                {firstNum + 1 + i}
                                            </PageSpan>
                                        </PageLi>
                                    )
                                } else if (i >= numPages - 2) {
                                    return (
                                        <PageLi
                                            key={i + 1}
                                            onClick={() => {
                                                paginate(lastNum)
                                                setSelect(lastNum)
                                            }}
                                        >
                                            <PageSpan className={select == lastNum ? "active" : ""}>{lastNum}</PageSpan>
                                        </PageLi>
                                    )
                                }
                            })}
                            <MoveButton
                                disabled={page === numPages}
                                onClick={() => {
                                    paginate(page + 1)
                                    setCurrPage(page)
                                    setSelect(page + 1)
                                }}
                            >
                                &gt;
                            </MoveButton>
                        </PageUl>
                    </div>
                </div>
            )}
        </>
    )
}

export default Pagination
