import React from "react"
import { Button, Icon } from "p-ui"
import { FlexDiv } from "@styles"

function UserFormHeader(props: any) {
    const { deleteUser, onClickFormValidation, setMode, mode } = props

    return (
        <FlexDiv
            justifyContent="flex-end"
            gap="5px"
            margin="0 0 15px 0"
        >
            <Button
                type={mode === "edit" ? "default" : "primary"}
                size="small"
                onClick={() => {
                    setMode(mode == "detail" ? "edit" : "detail")
                }}
            >
                {mode === "edit" ? (
                    <Icon.Block
                        width={15}
                        height={15}
                    />
                ) : (
                    <Icon.Edit
                        width={15}
                        height={15}
                    />
                )}
            </Button>
            {mode == "edit" ? (
                <Button
                    onClick={() => onClickFormValidation()}
                    type="primary"
                    size="small"
                >
                    저장
                </Button>
            ) : (
                <Button
                    onClick={() => deleteUser(true)}
                    type="danger"
                    size="small"
                >
                    삭제
                </Button>
            )}
        </FlexDiv>
    )
}

export default UserFormHeader
