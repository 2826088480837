import { FlexDiv } from "@styles"
import { useEffect, useState } from "react"

const Pagination = (props: paginationProps) => {
    const { pageList, count, page, setPage } = props
    const [firstPage, setFirstPage] = useState(1)

    useEffect(() => {
        if (page - 5 >= firstPage) {
            setFirstPage(firstPage + 5)
        } else if (page < firstPage) {
            setFirstPage(firstPage - 5)
        }
    }, [page])

    return (
        <FlexDiv justifyContent="center">
            <div className="pui-patient-pagination">
                <nav>
                    <ul className="pui-pagination">
                        {firstPage > count && (
                            <li>
                                <a
                                    onClick={() => {
                                        if (page - count >= 1) {
                                            setPage(firstPage - count)
                                            setFirstPage(firstPage - count)
                                        }
                                    }}
                                >
                                    &lt;&lt;
                                </a>
                            </li>
                        )}
                        {page !== 1 && (
                            <li>
                                <a
                                    onClick={() => {
                                        if (page > 1) {
                                            setPage(page - 1)
                                        }
                                    }}
                                >
                                    &lt;
                                </a>
                            </li>
                        )}
                        {pageList
                            ?.filter(v => {
                                return v < firstPage + count && v >= firstPage
                            })
                            .map(v => {
                                return (
                                    <li
                                        className={page === v ? "pui-active" : ""}
                                        onClick={() => setPage(v)}
                                    >
                                        <a>{v}</a>
                                    </li>
                                )
                            })}
                        {pageList && pageList.length !== page && (
                            <li>
                                <a
                                    onClick={() => {
                                        if (pageList) {
                                            if (page < pageList.length) {
                                                setPage(page + 1)
                                            }
                                        }
                                    }}
                                >
                                    &gt;
                                </a>
                            </li>
                        )}
                        {pageList && firstPage + 5 < pageList.length && (
                            <li>
                                <a
                                    onClick={() => {
                                        if (pageList) {
                                            setPage(firstPage + count)
                                            setFirstPage(firstPage + count)
                                        }
                                    }}
                                >
                                    &gt; &gt;
                                </a>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
        </FlexDiv>
    )
}

export default Pagination

interface paginationProps {
    pageList: number[]
    count: number
    page: number
    setPage: any
}
