export const membershipColumn = [
    {
        title: "시리얼 번호",
        dataIndex: "bikeSerial",
    },
    {
        title: "차대 번호",
        dataIndex: "biVin",
    },
    {
        title: "구매자",
        dataIndex: "purchaserNm",
    },
    {
        title: "휴대폰번호",
        dataIndex: "purchaserPhone",
    },
    {
        title: "구독중인 멤버십",
        dataIndex: "membershipName",
    },
    {
        title: "상태",
        dataIndex: "status",
    },
    {
        title: "시작일",
        dataIndex: "startDate",
    },
    {
        title: "종료일",
        dataIndex: "endDate",
    },
    {
        title: "다음 결제일",
        dataIndex: "paymentDate",
    },
    {
        title: "상세 보기",
        dataIndex: "detail",
        isFooterFixed: true,
    },
]

export const membershipDetailColumn = [
    {
        title: "멤버십명",
        dataIndex: "modelName",
    },

    {
        title: "결제 금액",
        dataIndex: "amount",
    },
    {
        title: "시작일",
        dataIndex: "startDate",
    },
    {
        title: "종료일",
        dataIndex: "endDate",
    },
    {
        title: "결제자이름",
        dataIndex: "purchaserNm",
    },
    {
        title: "결제일",
        dataIndex: "paidAt",
    },
    {
        title: "환불일",
        dataIndex: "refundDate",
    },
    {
        title: "카드이름",
        dataIndex: "cardName",
    },
    {
        title: "카드 번호(끝 4자리)",
        dataIndex: "cardNumber",
    },
    {
        title: "실패횟수",
        dataIndex: "failCount",
    },
]

export const mileColumn = [
    {
        title: "기준시간",
        dataIndex: "baseDt",
    },
    {
        title: "전체 주행거리",
        dataIndex: "totalMileK",
    },
    {
        title: "하루 주행거리",
        dataIndex: "dailyMileK",
    },
    {
        title: "하루 교환횟수",
        dataIndex: "dailySwapCount",
    },
]

export const membershipStatus: { [key: string | number]: number | string } = {
    cancel: 0,
    subscribe: 1,
    cancelWait: 2,
    wait: 3,
    0: "해지",
    1: "구독중",
    2: "해지예정",
    3: "일시정지",
}

export const membershipStatusOption = [
    {
        label: "선택",
        value: "select",
        disabled: true,
    },
    {
        label: "구독중",
        value: 1,
    },
    {
        label: "해지예정",
        value: 2,
    },
    {
        label: "일시정지",
        value: 3,
    },
    {
        label: "해지",
        value: 0,
    },
]

export const promotionColumn = [
    {
        title: "프로모션 명",
        dataIndex: "label",
    },

    {
        title: "생성일",
        dataIndex: "createdAt",
    },
    {
        title: "반영일",
        dataIndex: "updatedAt",
    },
    {
        title: "메모",
        dataIndex: "comment",
    },
    {
        title: "상태",
        dataIndex: "promotionStatus",
    },
    {
        title: "삭제",
        dataIndex: "delete",
    },
]

export const membershipAlert: { [key: string]: string } = {
    successChangeMembership: "멤버십 변경이 완료되었습니다.",
    failChangeMembership: "멤버십 변경이 실패되었습니다. 다시 시도 해주세요.",
    successCancelWait: "멤버십 해지예약이 완료되었습니다.",
    failCancelWait: "멤버십 해지예약이 실패되었습니다. 다시 시도 해주세요.",
    successSubscribe: "멤버십 구독상태로 변경되었습니다.",
    failSubscribe: "멤버십 구독상태로 변경 실패되었습니다. 다시 시도 해주세요.",
    successChangeAuthBike: "교환상태 변경이 완료되었습니다.",
    failChangeAuthBike: "교환상태 변경이 실패되었습니다. 다시 시도 해주세요.",
    successChangeCard: "카드 변경이 완료되었습니다.",
    failChangeCard: "카드 변경이 실패되었습니다. 다시 시도 해주세요.",
    checkCard: "현재 카드와 같은 카드를 선택했습니다. 다시 시도 해 주세요",
    checkMembership: "다음 결제 멤버십과 같은 멤버십을 선택했습니다. 다시 시도 해 주세요.",
    successPayment: "결제요청이 완료되었습니다.",
    failPayment: "결제요청이 실패되었습니다. 다시 시도 해 주세요.",
    successOfflinePayment: "현장 결제 처리하였습니다.",
    failOfflinePayment: "현장 결제가 실패하였습니다. 관리자에게 문의하세요.",
    successRefundPayment: "환불 처리 되었습니다.",
    failRefundPayment: "환불 처리가 실패되었습니다. 다시 시도 해 주세요.",

    successMembershipSuspend: "멤버십 일시정지 정보가 수정되었습니다.",
    failMembershipSuspend: "요청이 실패되었습니다. 다시 시도 해 주세요",
    sussessChangeMembershipStatus: "멤버십 상태 완료되었습니다.",
    failChangeMembershipStatus: "요청이 실패되었습니다. 다시 시도 해 주세요",
    deleteSuccess: "삭제 되었습니다.",
    fail: "실패 되었습니다.",
    save: "저장 되었습니다.",
}
