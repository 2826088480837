import axios from "./config"
import { FILE, STATION } from "./urls"

export default {
    /**
     * s3 file upload
     * @param folder string : s3 folder name
     * @param params any : form data
     * @returns
     */
    async uploadeImg(folder: string, params: any) {
        try {
            return await axios.host.post(`${STATION.FILE_UPLOAD}/${folder}`, params, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        } catch (e) {
            return []
        }
    },

    /**
     * 첨부파일 업로드
     * @param folderName s3폴더 이름
     * @param formData formData
     * @returns
     */
    async uploadFileList(folderName: string, formData: any) {
        try {
            return await axios.host.post(FILE.UPLOAD_FILE_LIST + folderName, formData)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * 파일 단건 삭제
     * @param folderName s3폴더 이름
     * @param fileId 파일 id
     * @returns
     */
    async deleteFile(folderName: String, fileId: number) {
        try {
            return await axios.host.delete(FILE.DELETE_FILE + folderName, { params: { fileId } })
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * 파일 리스트 조회
     * @param linkInfo linkInfo
     * @returns
     */
    async getFileList(linkInfo: String) {
        try {
            return await axios.host.get(FILE.GET_FILE_LIST, { params: { linkInfo } })
        } catch (e: any) {
            return e.message
        }
    },
}
