import { OrderUpdateParam } from "./../types/OrderType"
import { OrderCustomerParamType } from "@type/OrderType"
import axios from "./config"
import { ORDER_MANAGE } from "./urls"
import { OrderInfo, OrderDetailInfo, OrderCommentParams, OrderCancelParams } from "@type/orderManageType"
import { Result } from "@type/responseType"

export default {
    async getOrderList(): Promise<Result<OrderInfo> | any> {
        try {
            return await axios.host.get(ORDER_MANAGE.GET_LIST)
        } catch (e: any) {
            return e.message
        }
    },
    async getOrderDetail(orderId: string): Promise<Result<OrderDetailInfo> | any> {
        try {
            return await axios.host.get(ORDER_MANAGE.GET_DETAIL + orderId)
        } catch (e: any) {
            return e.message
        }
    },
    async upsertComment(params: OrderCommentParams): Promise<Result<string> | any> {
        try {
            return await axios.host.post(ORDER_MANAGE.UPDATE_COMMENT, params)
        } catch (e: any) {
            return e.message
        }
    },
    async deleteOrder(orderId: string, cancelParam: OrderCancelParams): Promise<Result<string> | any> {
        try {
            return await axios.host.put(ORDER_MANAGE.DELETE_ORDER + orderId, cancelParam)
        } catch (e: any) {
            return e.message
        }
    },
    async upadteOrderStatus(orderId: string, status: number): Promise<Result<string> | any> {
        try {
            return await axios.host.put(ORDER_MANAGE.UPDATE_ORDER_STATE + "?id=" + orderId + "&status=" + status)
        } catch (e: any) {
            return e.message
        }
    },

    async updateDeliveryDt(orderId: string, status: number, deliveryDt: string) {
        try {
            return await axios.host.put(
                ORDER_MANAGE.UPDATE_ORDER_STATE + "?id=" + orderId + "&status=" + status + "&deliveryDt=" + deliveryDt
            )
        } catch (e: any) {
            return e.message
        }
    },
    async checkPayment(orderId: string): Promise<Result<string> | any> {
        try {
            return await axios.host.put(ORDER_MANAGE.CHECK_PAYMENT + orderId)
        } catch (e: any) {
            return e.message
        }
    },
    async uploadFileList(folderName: string, formData: any) {
        try {
            return await axios.host.post(ORDER_MANAGE.UPLOAD_FILE_LIST + folderName, formData)
        } catch (e: any) {
            return e.message
        }
    },
    async deleteFile(folderName: String, fileId: number) {
        try {
            return await axios.host.delete(ORDER_MANAGE.DELETE_FILE + folderName, { params: { fileId } })
        } catch (e: any) {
            return e.message
        }
    },
    async rejectCancel(orderId: string): Promise<Result<string> | any> {
        try {
            return await axios.host.put(ORDER_MANAGE.REJECT_CANCEL + "?id=" + orderId)
        } catch (e: any) {
            return e.message
        }
    },
    async getPromotion(orderId: string): Promise<Result<string> | any> {
        try {
            return await axios.host.get(ORDER_MANAGE.GET_PROMOTION + orderId)
        } catch (e: any) {
            return e.message
        }
    },
    async getCustomorOne(orderId: string): Promise<Result<string> | any> {
        try {
            return await axios.host.get(ORDER_MANAGE.GET_CUSTOMER_ONE + orderId)
        } catch (e: any) {
            return e.message
        }
    },
    async updateCustomer(param: OrderCustomerParamType): Promise<Result<string> | any> {
        try {
            return await axios.host.put(ORDER_MANAGE.UPDATE_CUSTOMER, param)
        } catch (e: any) {
            return e.message
        }
    },
    async getAccessoryList(): Promise<Result<string> | any> {
        try {
            return await axios.host.get(ORDER_MANAGE.GET_ACCESSORY)
        } catch (e: any) {
            return e.message
        }
    },
    async getColorList(orderId: string): Promise<Result<string> | any> {
        try {
            return await axios.host.get(ORDER_MANAGE.GET_COLOR + orderId)
        } catch (e: any) {
            return e.message
        }
    },
    async updateOrderOption(orderId: string, param: OrderUpdateParam): Promise<Result<string> | any> {
        try {
            return await axios.host.post(ORDER_MANAGE.UPDATE_ORDER + orderId, param)
        } catch (e: any) {
            return e.message
        }
    },
    async updatePromotion(orderId: string, promotionId: number): Promise<Result<string> | any> {
        try {
            return await axios.host.post(ORDER_MANAGE.UPDATE_PROMOTION + orderId, {
                promotionModelId: promotionId as number,
            })
        } catch (e: any) {
            return e.message
        }
    },
    async getRefund(orderId: string): Promise<Result<string> | any> {
        try {
            return await axios.host.get(ORDER_MANAGE.GET_REFUND + orderId)
        } catch (e: any) {
            return e.message
        }
    },
    async getTotalAmount(orderId: string): Promise<Result<string> | any> {
        try {
            return await axios.host.get(ORDER_MANAGE.GET_TOTAL_AMOUNT + orderId)
        } catch (e: any) {
            return e.message
        }
    },
    async offlinePayment(orderId: string): Promise<Result<string> | any> {
        try {
            return await axios.host.post(ORDER_MANAGE.OFFLINE_PAYMENT + orderId)
        } catch (e: any) {
            return e.message
        }
    },
    async getSubsidyList(): Promise<Result<string> | any> {
        try {
            return await axios.host.get(ORDER_MANAGE.GET_SUBSIDY)
        } catch (e: any) {
            return e.message
        }
    },
}
