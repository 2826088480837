import colorPlugin from "@toast-ui/editor-plugin-color-syntax"
import { Editor } from "@toast-ui/react-editor"
import tableMergedCellPlugin from "@toast-ui/editor-plugin-table-merged-cell"
import React from "react"
import ReactDOM from "react-dom"
import { CommonDiv, Text } from "@styles"
import { Input } from "p-ui"

interface EditorProps {
    initialValue?: string | undefined
    height?: string
    placeholder?: string
    onChange?: any
}

const BbsEditor = React.forwardRef<Editor, EditorProps>((props, ref) => {
    // 유튜브 삽입을 위한 커스텀 툴바 아이템 생성
    const youtubeElement = document.createElement("div")
    youtubeElement.style.cursor = "pointer"
    youtubeElement.style.display = "flex"

    const icon = document.createElement("img")
    icon.setAttribute("src", `${process.env.PUBLIC_URL}/images/youtube_icon.png`)
    icon.setAttribute("width", "32")
    youtubeElement.appendChild(icon)

    // 팝업 바디 생성
    const container = document.createElement("div")
    ReactDOM.render(<InsertVideoComponents editorRef={ref} />, container)

    return (
        <Editor
            ref={ref}
            initialValue={props.initialValue}
            initialEditType="wysiwyg"
            previewStyle="vertical"
            height={props.height ? props.height : "600px"}
            placeholder={props.placeholder}
            useCommandShortcut={true}
            language="ko"
            customHTMLRenderer={{
                htmlBlock: {
                    iframe(node: any) {
                        return [
                            {
                                type: "openTag",
                                tagName: "iframe",
                                outerNewLine: true,
                                attributes: node.attrs,
                                classNames: ["youtube-box"],
                            },
                            { type: "html", content: node.childrenHTML ?? "" },
                            { type: "closeTag", tagName: "iframe", outerNewLine: true },
                        ]
                    },
                },
            }}
            toolbarItems={[
                ["heading", "bold", "italic", "strike"],
                ["hr", "quote"],
                ["ul", "ol", "task"],
                [
                    "table",
                    "image",
                    {
                        name: "Youtube",
                        tooltip: "Youtube",
                        el: youtubeElement,
                        popup: {
                            body: container,
                            style: { width: "auto" },
                        },
                    },
                    "link",
                ],
                ["code", "codeblock"],
            ]}
            plugins={[colorPlugin, tableMergedCellPlugin]}
            onChange={props.onChange}
        />
    )
})

const InsertVideoComponents = ({ editorRef }: { editorRef: any }) => {
    const handleKeyUp = (event: any) => {
        if (event.key === "Enter") {
            let url = event.target.value

            if (!url) {
                return alert("url을 입력해주세요.")
            }
            if (extractYouTubeVideoId(url)) {
                url = extractYouTubeVideoId(url)

                const videoTag =
                    `<iframe src="https://www.youtube.com/embed/` +
                    url +
                    `" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`

                editorRef.current.getInstance().changeMode("markdown")
                editorRef.current.getInstance().insertText(videoTag)
                editorRef.current.getInstance().eventEmitter.emit("closePopup")
                editorRef.current.getInstance().changeMode("wysiwyg")
                event.target.value = ""
            } else {
                return alert("유튜브 url을 입력해주세요!")
            }
        }
    }

    const extractYouTubeVideoId = (url: String) => {
        const regex =
            /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
        const match = url.match(regex)
        return match ? match[1] : null
    }

    return (
        <CommonDiv>
            <Text>유튜브 링크(url)를 넣고 엔터를 눌러주세요!</Text>
            <Input
                width={"500px"}
                defaultValue=""
                placeholder="유튜브 링크(url)을 삽입해주세요."
                onKeyUp={e => handleKeyUp(e)}
            />
        </CommonDiv>
    )
}

export default BbsEditor
