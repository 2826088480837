import { Result } from "@type/responseType"
import axios from "./config"
import { PUSH } from "./urls"


import { PushTemplateType, PushType } from "@type/push"

const Push = {
    /**
     * SEND PUSH MSG
     * @returns {string}
     */
    async sendPushMsg(params: PushType): Promise<Result<string>> {
        try {
            return await axios.host.post(PUSH.SEND_ALL, params)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * GET Push Template
     * @returns {PushTemplateType[]}
     */
    async getPushTemplate(): Promise<Result<PushTemplateType[]>> {
        try {
            return await axios.host.get(PUSH.GET_TEMPLATE)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * Insert Push Template
     * @returns {PushTemplateType[]}
     */
    async insertPushTemplate(params: PushTemplateType): Promise<Result<string>> {
        try {
            return await axios.host.post(PUSH.POST_TEMPLATE, params)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * Update Push Template
     * @returns {PushTemplateType[]}
     */
    async updatePushTemplate(params: PushTemplateType): Promise<Result<string>> {
        try {
            return await axios.host.post(PUSH.PUT_TEMPLATE, params)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * Delete Push Template
     * @returns {PushTemplateType[]}
     */
    async deletePushTemplate(id: number): Promise<Result<string>> {
        try {
            return await axios.host.delete(PUSH.DELETE_TEMPLATE + id)
        } catch (e: any) {
            return e.message
        }
    },
}

export default Push
