import CustomDatePicker from "@components/widgets/customDatePicker"
import { FlexDiv, DataPickerInput, DatePickerSpan, Label, GridDiv, Text } from "@styles"
import { ko } from "date-fns/locale"
import ECharts from "echarts-for-react"
import { Button, CheckBox, Col, Icon, Loading, Row } from "p-ui"
import React, { useEffect, useMemo, useRef, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useGetAssetBikeStatus } from "src/queries/assets"
import { DateBtn, GraphDiv } from "../../Lab/bike/style"
import { useLocation } from "react-router"
import useAlert from "@context/alert/useAlert"
import { ASSET_BIKE } from "../constants"
import styled from "styled-components"

const BatteryInfoGraph = () => {
    const currentDate = new Date()
    const location = useLocation()
    const { openAlert } = useAlert()
    const serialNo = new URLSearchParams(location.search).get("serialNo")
    const [startDate, setStartDate] = useState(currentDate)
    const [endDate, setEndDate] = useState<Date>(currentDate)
    const [isFullScreen, setIsFullScreen] = useState(false)
    const graphEl = useRef<any>()
    const [selectedFilter, setSelectedFilter] = useState(["Soc", "Temp"])
    const [selectedDate, setSelectedDate] = useState(-1)
    const { data, isFetching, isError, error, refetch } = useGetAssetBikeStatus(
        startDate.getTime().toString(),
        endDate.getTime().toString(),
        serialNo as string
    )

    const options = useMemo(() => {
        if (selectedFilter.length == 0 || isError) {
            return {}
        }
        const yAxisData = selectedFilter?.map(data => ({
            type: "value",
            name: data,
            max: data === "Soc" ? 100 : data === "Temp" ? 60 : data === "Current" ? 50 : 20,
            nameTextStyle: {
                fontWeight: "bold",
                verticalAlign: "top",
                padding: [20, 8, 8, 8],
            },
            axisLabel: {
                formatter: function (value: number) {
                    if (data === "Soc") {
                        return value + "%"
                    } else if (data === "Temp") {
                        return value + "°C"
                    } else if (data === "Current") {
                        return value + "A"
                    } else {
                        return value + "V"
                    }
                },
            },

            nameLocation: "start",
        }))

        const uniqueArr = data?.reduce((acc: any[], current: any) => {
            const isDuplicate = acc.some(item => item.bt1Serial === current.bt1Serial)
            if (!isDuplicate) {
                acc.push(current)
            }
            return acc
        }, [])

        const filterMap: { [key: string]: string[] } = {
            Soc: ["bt1Soc", "bt2Soc"],
            Temp: ["bt1TempAvg", "bt2TempAvg"],
            Current: ["bt1Current", "bt2Current"],
            CellAvg: ["bt1CellAvg", "bt2CellAvg"],
        }

        const markAreaData = uniqueArr
            ?.map((item: any, i: any) => {
                if (item.bt1Serial !== "") {
                    return [
                        {
                            name: item.bt1Serial,
                            xAxis: item.dataTime, // 시작값
                            itemStyle: {
                                color: ASSET_BIKE.graphColor[i],
                            },
                        },
                        {
                            xAxis: uniqueArr[i + 1]?.dataTime, // 종료값
                        },
                    ]
                } else {
                    return [
                        {
                            name: item.bt1Serial,
                            xAxis: item.dataTime, // 시작값
                            itemStyle: {
                                color: ASSET_BIKE.graphColor[i - 1],
                            },
                        },
                        {
                            xAxis: uniqueArr[i + 1]?.dataTime, // 종료값
                        },
                    ]
                }
            })
            .filter((element: any) => element !== undefined)

        const seriesData = selectedFilter?.flatMap((filter: any) => {
            const keys = filterMap[filter]

            if (!keys) return []

            return keys.map((key: any, i: number) => ({
                name: key,
                type: "line",
                data: data?.map((item: any) => item[key]),
                yAxisIndex: yAxisData.findIndex(yAxis => yAxis.name === filter),
                sampling: "lttb",
                itemStyle: {
                    emphasis: {
                        label: {
                            show: true,
                            formatter: function (params: any) {
                                const currentBtSerial =
                                    data[params.dataIndex][
                                        "bt" + (params.seriesName.includes("bt1") ? "1" : "2") + "Serial"
                                    ]
                                return `${currentBtSerial}`
                            },
                            position: "top",
                        },
                    },
                },
            }))
        })

        return {
            animationEasing: "elasticOut",
            xAxis: [
                {
                    type: "category",
                    data: data?.map((item: any) => item.dataTime),
                },
            ],

            yAxis: yAxisData,
            legend: {
                data: seriesData?.map(series => series.name),
            },
            tooltip: {
                trigger: "axis",
            },
            series: seriesData.map(item => {
                return {
                    ...item,
                    markArea: { data: markAreaData },
                }
            }),
            dataZoom: [
                {
                    type: "inside",
                    realtime: true,
                    start: 0,
                    end: 100,
                },
            ],
        }
    }, [selectedFilter, data])

    useEffect(() => {
        if (!isFetching && isError) {
            openAlert({ title: "조회실패", body: "30일 이상 데이터는 조회할 수 없습니다.", type: "warning" })
        }
    }, [isFetching, isError])
    const handleFullscreenChange = () => {
        if (!document.fullscreenElement) {
            setIsFullScreen(false)
        } else if (document.fullscreenElement === graphEl.current) {
            setIsFullScreen(true)
        }
    }

    useEffect(() => {
        document.addEventListener("fullscreenchange", handleFullscreenChange)
        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange)
        }
    }, [])

    return (
        <GraphDiv>
            <Row gap={20}>
                <Col sm={12}>
                    <Text as={"h3"}>배터리 정보 이력 그래프</Text>
                </Col>
                <Col sm={1.2}>
                    <Label>조회 기간</Label>
                </Col>
                <Col md={10}>
                    <FlexDiv gap="5px">
                        <CustomDatePicker>
                            <ReactDatePicker
                                selected={startDate}
                                onChange={(date: any) => {
                                    setStartDate(date)
                                    setSelectedDate(-1)
                                }}
                                locale={ko} // 한글로 변경
                                selectsStart
                                showTimeSelect
                                dateFormat="yyyy.MM.dd (eee) HH:mm"
                                showPopperArrow={false} // 화살표 변경
                                customInput={<DataPickerInput />}
                            />
                        </CustomDatePicker>
                        <DatePickerSpan> ~ </DatePickerSpan>
                        <CustomDatePicker>
                            <ReactDatePicker
                                selected={endDate}
                                onChange={(date: any) => {
                                    setEndDate(date)
                                    setSelectedDate(-1)
                                }}
                                locale={ko}
                                showTimeSelect
                                dateFormat="yyyy.MM.dd (eee) HH:mm"
                                showPopperArrow={false}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                customInput={<DataPickerInput />}
                            />
                        </CustomDatePicker>
                        <Button
                            size="small"
                            onClick={() => {
                                refetch()
                            }}
                        >
                            검색
                        </Button>
                        <DateBtn
                            className={selectedDate == 0 ? "focused" : ""}
                            onClick={() => {
                                setSelectedDate(0)
                                const today = new Date()
                                today.setHours(0, 0, 0, 0)
                                setStartDate(today)
                                setEndDate(currentDate)
                            }}
                        >
                            오늘
                        </DateBtn>
                        <DateBtn
                            onClick={() => {
                                setSelectedDate(1)
                                const threeDaysAgo = new Date(currentDate.getTime() - 3 * 24 * 60 * 60 * 1000)
                                threeDaysAgo.setHours(0, 0, 0, 0)
                                setStartDate(threeDaysAgo)
                                setEndDate(currentDate)
                            }}
                            className={selectedDate == 1 ? "focused" : ""}
                        >
                            3일
                        </DateBtn>
                    </FlexDiv>
                </Col>
                <Col md={11}>
                    <FlexDiv
                        gap="15px"
                        alignItems="flex-end"
                    >
                        <CheckBox.Group
                            value={selectedFilter}
                            onChange={(value: any) => {
                                if (value.length > 2) {
                                    setSelectedFilter(value.slice(1))
                                } else {
                                    setSelectedFilter(value)
                                }
                            }}
                        >
                            <GridDiv
                                gridCol="repeat(8, 1fr);"
                                gap="10px"
                            >
                                {["Soc", "Temp", "Current", "CellAvg"].map(item => {
                                    return (
                                        <CheckBox
                                            value={item}
                                            label={item}
                                        />
                                    )
                                })}
                            </GridDiv>
                        </CheckBox.Group>
                    </FlexDiv>
                </Col>
            </Row>
            {isFetching ? (
                <Loading open={isFetching} />
            ) : data?.length > 0 ? (
                <div
                    ref={graphEl}
                    style={{ width: "100%", height: "100%", position: "relative", backgroundColor: "white" }}
                >
                    <FullScreenBtn
                        onClick={() => {
                            if (document.fullscreenElement) {
                                document.exitFullscreen()
                                setIsFullScreen(false)
                            } else {
                                graphEl.current?.requestFullscreen()
                                setIsFullScreen(true)
                            }
                        }}
                    >
                        {isFullScreen ? (
                            <Icon.ExitFullScreen
                                width={40}
                                height={40}
                            />
                        ) : (
                            <Icon.FullScreen
                                width={30}
                                height={30}
                            />
                        )}
                    </FullScreenBtn>
                    <ECharts
                        style={{ marginTop: isFullScreen ? "200px" : 0 }}
                        option={options}
                        notMerge={true}
                        opts={{ renderer: "canvas", height: isFullScreen ? 600 : "auto", width: "auto" }}
                    />
                </div>
            ) : (
                <FlexDiv
                    alignItems="center"
                    justifyContent="center"
                    minHeihgt="300px"
                >
                    <div>데이터가 없습니다.</div>
                </FlexDiv>
            )}
        </GraphDiv>
    )
}

export default BatteryInfoGraph
const FullScreenBtn = styled.div`
    position: absolute;
    z-index: 99;
    right: 5px;
    top: 5px;
    width: 40px;
    height: 40px;
    background-color: rgb(255 255 255 / 65%);
    cursor: pointer;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
`
