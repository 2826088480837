import styled from "styled-components"

export const ChartsDiv = styled.div`
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 300px;
`

export const ListTab = styled.div`
    display: flex;
    gap: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .menu {
        font-size: 16px;
        cursor: pointer;
        height: 30px;
        text-align: center;
        font-weight: bold;
        color: #5c5c5c;
    }
    .menu.focused {
        border-bottom: 3px solid;
        color: #0c0c0c;
    }
`

export const Popper = styled.div`
    position: fixed;
    height: max-content;
    min-width: 205px;
    max-width: 300px;
    width: max-content;
    white-space: normal;
    word-wrap: break-word;
    min-height: 40px;
    background: #e9e9e9;
    color: black;
    border-radius: 5px;
    padding: 12px 12.8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    :after {
        border-top: 0px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #e9e9e9;
        content: "";
        position: absolute;
        top: -8px;
        left: 160px;
    }
`

export const IconWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const GraphDiv = styled.div`
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    margin: 40px 0;
    min-height: 600px;
`

export const DateBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #ededed;
    color: #5c5c5c;
    font-weight: 700;
    min-width: 74px;
    max-width: 74px;
    max-height: 34px;
    border-radius: 8px;
    padding: 8px 24px;
    cursor: pointer;
    :hover {
        transform: scale(1.02);
    }
    &.focused {
        border-color: #002ed0;
        color: #002ed0;
    }
`

export const Tab = styled.ul`
    color: #5c5c5c;
    font-weight: bold;
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 30 0 0 0;
    margin-bottom: 0;
    cursor: pointer;
    .submenu {
        display: flex;
        width: 110px;
        padding: 10px;
        font-size: 15px;
        transition: 0.5s;
        border-radius: 8px 8px 0 0;
        align-items: center;
        justify-content: center;
        border: 1px solid #dddddd8d;
    }

    .focused {
        background-color: #2d5bff;
        color: white;
    }
`

export const Contents = styled.div<{ minHeigth?: string }>`
    flex: 1;
    transition: 0.5s;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px;
    border-radius: 0 0 8px 8px;
    min-height: ${props => (props.minHeigth ? props.minHeigth : "545px")};
`
