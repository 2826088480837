import { Alert, Type } from "p-ui"
import React, { useEffect } from "react"

interface DefaultAlertProps {
    duration?: number
    infinite?: boolean
    title?: string
    type: Type.AlertType
    body?: string
    onClose?: any
}

const DefaultAlert = (props: DefaultAlertProps) => {
    const handleClickClose = () => {
        props.onClose()
    }
    useEffect(() => {
        if (props.infinite) {
            return
        } else {
            setTimeout(
                () => {
                    handleClickClose()
                },
                props.duration ? props.duration : 1500
            )
        }
    }, [])
    return (
        <Alert
            open={true}
            onClose={handleClickClose}
            title={props.title}
            body={props.body}
            type={props.type}
        />
    )
}

export default DefaultAlert
