import { ErrorCode } from "@common/constatns"
import { updateActivePage, observPage } from "@common/util"
import { FlexDiv, Label } from "@styles"
import { Viewer } from "@toast-ui/react-editor"
import { Button, CheckBox, Col, DataTable, Input, PageHeader, Row } from "p-ui"
import { PageRow } from "p-ui/dist/esm/types"
import { useEffect, useState, useRef, useMemo } from "react"
import { useLocation, useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import bikeErrorCode from "src/apis/bikeErrorCode"
import { onChangeFilter } from "src/utils/filter"

const BikeErrorCodeList = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const tableRef = useRef<HTMLTableElement | null>(null)

    const [searchParams, setSearchParams] = useSearchParams()
    const column: dataInfo[] = ErrorCode

    const LIST_URL = "/bbs/errorcode"

    const codeTypeParam = searchParams.get("codeType")
    const searchTextParam = searchParams?.get("searchText")
    const pageParam = searchParams.get("page")
    const limitParam = searchParams.get("limit")

    const [bikeErrorCodeList, setBikeErrorCodeList] = useState([] as any)
    const [filterList, setFilterList] = useState([] as any)
    const [codeType, setCodeType] = useState<codeTypeData[]>([])
    const [defaultType, setDefaultType] = useState<number[]>([])
    const [pageRows, setPageRows] = useState<PageRow>(15)

    const [clickedTypeFilter, setClickedTypeFilter] = useState<number[]>(
        codeTypeParam ? codeTypeParam.split(",").map(Number) : []
    )
    const [searchText, setSearchText] = useState("")
    const [nowPage, setNowPage] = useState<any>(pageParam !== null ? pageParam : 1)

    /**
     * Bike Error Code 목록 조회
     */
    const getBikeErrorCodeList = (codeTypeParam: any) => {
        bikeErrorCode
            .getBikeErrorCodeList(codeTypeParam, searchTextParam)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setBikeErrorCodeList(res.resultData)
                }
            })
            .catch(e => console.log(e))
    }

    /**
     * Bike Error Code 구분 조회
     */
    const getType = (isSearchEmpty: boolean) => {
        bikeErrorCode.getBikeErrorCodeType().then(res => {
            if (res.status === "SUCCESS") {
                setCodeType(prev => {
                    const updatedCodeType = [...prev]
                    res.resultData.forEach(item => {
                        const isDuplicate = updatedCodeType.some(existingItem => existingItem.value === item.codeId)
                        if (!isDuplicate) {
                            updatedCodeType.push({ label: item.codeLabel, value: item.codeId })
                        }
                    })
                    return updatedCodeType
                })

                const updateDefaultType = (prevDefaultType: any) => {
                    const newDefaultType = [...prevDefaultType]
                    res.resultData.forEach(item => {
                        newDefaultType.push(item.codeId)
                    })
                    return newDefaultType
                }

                setDefaultType(updateDefaultType)
                const values = res.resultData.map(item => item.codeId)
                setClickedTypeFilter(isSearchEmpty ? values : splitParam(codeTypeParam).map(Number))
            }
        })
    }

    const splitParam = (param: any) => {
        if (!param) {
            return []
        }
        return param.split(",")
    }

    useEffect(() => {
        getBikeErrorCodeList(null)
        getType(location.search.length === 0)
        if (limitParam) {
            setPageRows(Number(limitParam) as unknown as PageRow)
        }
        if (searchTextParam) {
            setSearchText(searchTextParam)
        }
    }, [])

    const replaceErrorDesc = (desc: string) => {
        return desc.replace(/<p>/g, "<span>").replace(/<\/p>/g, "</span>")
    }

    const commonMapping = (item: any) => ({
        ...item,
        errorDesc: (
            <Viewer
                initialValue={replaceErrorDesc(item.errorDesc)}
                key={item.codeId}
            />
        ),
        detail: (
            <Button
                size="small"
                type="secondary"
                onClick={() => {
                    goToDetail(item.codeId)
                }}
            >
                상세보기
            </Button>
        ),
    })

    const filterData = useMemo(() => {
        if (searchText === "") {
            return bikeErrorCodeList.filter((item: any) => {
                return clickedTypeFilter.includes(item.codeType)
            })
        }
        if (clickedTypeFilter.length === 0) {
            return []
        }

        const data = bikeErrorCodeList.filter(
            (item: any) =>
                clickedTypeFilter.includes(item.codeType) &&
                (item.errorDesc.includes(searchText) || item.errorCode.includes(searchText))
        )
        return data
    }, [clickedTypeFilter, bikeErrorCodeList, searchText])

    const filter = () => {
        if (location.search.length === 0) {
            setSearchParams({
                searchText: searchText,
                page: nowPage,
                limit: pageRows.toString(),
            })
        } else {
            setSearchParams({
                searchText: searchText,
                codeType: clickedTypeFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        }
        const filteredList = filterData.map(commonMapping)
        setFilterList(filteredList)
    }

    useEffect(() => {
        filter()
    }, [bikeErrorCodeList, clickedTypeFilter, nowPage, pageRows])

    /**
     * 엔터키 눌렀을 때 함수
     * @param event
     */
    const handleEnterPress = (event: any) => {
        if (event.key === "Enter") {
            setNowPage(1)
            filter()
        }
    }

    /**
     * 상세보기 페이지로 이동
     * @param id codeId
     */
    const goToDetail = (id: number) => {
        navigate(LIST_URL + `/${id}`, { state: { id: id } })
    }

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)
            if (newActivePage === 0) {
                setNowPage(Number(pageParam))
                return
            }
            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    return (
        <>
            <PageHeader
                title={"에러코드 안내 관리"}
                subTitle="에러코드 목록"
            />
            <Row gap={"20px"}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <Input
                        value={searchText}
                        placeholder="검색어를 입력하세요."
                        width="300px"
                        size="small"
                        onChange={(e: any) => setSearchText(e.value)}
                        onSearch={() => {
                            setNowPage(1)
                            filter()
                        }}
                        onKeyUp={e => {
                            handleEnterPress(e)
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <Label>구분</Label>
                </Col>
                <Col md={11}>
                    <FlexDiv gap="10px">
                        {defaultType.length > 1 && (
                            <CheckBox.Group
                                value={clickedTypeFilter}
                                options={codeType}
                                onChange={(value: any) => {
                                    const param = {
                                        clickedFilter: clickedTypeFilter,
                                        checkList: value as [],
                                        defaultFilter: defaultType,
                                        setClickedFilter: setClickedTypeFilter,
                                        allValue: 0,
                                    }
                                    onChangeFilter(param)
                                    setNowPage(1)
                                    filter()
                                }}
                            />
                        )}
                    </FlexDiv>
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    columns={column}
                    dataList={filterList}
                    pagination
                    nowPage={nowPage}
                    pageRow={pageRows}
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(limitParam) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                    headerRight={
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => navigate("insert")}
                        >
                            등록하기
                        </Button>
                    }
                />
            </div>
        </>
    )
}

export default BikeErrorCodeList

interface dataInfo {
    title: string
    dataIndex: string
    render?: (params: any) => void
}

export interface codeTypeData {
    label: string
    value: number
}
