import { LoginFormData, TokenData } from "@type/formType"
import { Auth, Result } from "@type/responseType"
import axios from "./config"
import { AUTH } from "./urls"

export default {
    /**
     * 로그인
     * @param params
     * @returns
     */
    async login(params: LoginFormData): Promise<Result<Auth> | any> {
        try {
            return await axios.host.post(AUTH.SIGNIN, params)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 토큰 재발급
     * @param params
     * @returns
     */
    async refreshToken(params: TokenData): Promise<Result<string> | any> {
        try {
            return await axios.host.post(AUTH.REFRESH, params)
        } catch (e: any) {
            return e.message
        }
    },
}
