import React, { useContext } from "react"
import { AlertStateContext, AlertDispatchContext } from "./AlertContext"
import DefaultAlert from "@components/widgets/DefaultAlert"

export const alerts = {
    defaultAlert: DefaultAlert,
}

const Alerts = () => {
    const opendAlerts = useContext(AlertStateContext)
    const { close } = useContext(AlertDispatchContext)

    return (
        <div>
            {opendAlerts.map((alert: any, index: any) => {
                const { Component, props } = alert
                const onClose = () => {
                    close(Component)
                }
                return (
                    <Component
                        key={index}
                        onClose={onClose}
                        {...props}
                    />
                )
            })}
        </div>
    )
}

export default Alerts
