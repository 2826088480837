import { Text } from "@styles"
import { Button, Col, DataTable, Input, Row } from "p-ui"
import { FlexDiv, Label } from "@styles"

import React, { useEffect, useMemo, useRef, useState } from "react"
import { ASSETS_FIRM } from "../constants"
import { useGetApplyFirmBike } from "src/queries/firmhard"
import { useNavigate } from "react-router"
import { onChangeParam } from "p-ui/dist/esm/types/input"
import { PageRow } from "p-ui/dist/esm/types"
import { observPage, updateActivePage } from "@common/util"

const FirmBikeSearchModal = (props: any) => {
    const navigate = useNavigate()
    const tableRef = useRef<HTMLTableElement | null>(null)

    const { deviceType, version, firmwareType, firmwareId } = props
    const { data: applyData, isFetching, isError, error } = useGetApplyFirmBike(firmwareId)
    const [bikeList, setBikeList] = useState<any[]>([])
    const [searchText, setSearchText] = useState<string>("")
    const [filterText, setFilterText] = useState<string>("")
    const [nowPage, setNowPage] = useState<any>(1)
    const filterData = useMemo(() => {
        let filteredResults = bikeList
        // 검색 필터
        if (filterText !== "") {
            filteredResults = filteredResults?.filter(item =>
                JSON.stringify(item).toLowerCase().includes(filterText.toLowerCase().split(" ").join(""))
            )
        }
        return filteredResults
    }, [bikeList, filterText])

    const convertList = (data: any[]) => {
        return data?.map((item: any) => {
            let version = ""
            switch (firmwareType) {
                case "EDCU":
                    version = item.edcuVersion
                    break
                case "MCU":
                    version = item.mcuVersion
                    break
                case "ICS":
                    version = item.icsVersion
                    break
                default:
                    version = "Unknown"
                    break
            }
            return {
                ...item,
                version: version,
                detail: (
                    <Button
                        size="small"
                        type="tertiary"
                        onClick={() => {
                            navigate({ pathname: "/lab/bikedetail", search: "?serialNo=" + item.serialNo })
                        }}
                    >
                        상세보기
                    </Button>
                ),
            }
        })
    }
    const handleFetchResult = (
        fetching: boolean,
        isError: boolean,
        error: any,
        dataList: any[],
        setter: (data: any[]) => void
    ) => {
        /**실패시 */
        if (!fetching && isError) {
            console.log(error)
        }
        /**성공시 */
        if (!fetching && !isError) {
            setter(convertList(dataList as any[]))
        }
    }

    useEffect(() => {
        handleFetchResult(isFetching, isError, error, applyData, setBikeList)
    }, [isFetching, isError, error, applyData])
    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)

            if (newActivePage === 0) {
                return setNowPage(1)
            }

            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    return (
        <div>
            <Row gap={20}>
                <Col sm={12}>
                    <Text>
                        {deviceType}/{firmwareType}/{version} 바이크 목록
                    </Text>
                </Col>
                <Col sm={2}>
                    <Label>검색</Label>
                </Col>
                <Col sm={10}>
                    <Input
                        size="small"
                        onChange={(e: onChangeParam) => {
                            setSearchText(e.value.toString())
                        }}
                        onSearch={() => {
                            setFilterText(searchText)
                            setNowPage(1)
                        }}
                    />
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    numbering
                    pagination
                    pageRow={5}
                    columns={ASSETS_FIRM.firmBikeSearchColumn}
                    dataList={filterData}
                    nowPage={nowPage}
                />
            </div>
        </div>
    )
}

export default FirmBikeSearchModal
