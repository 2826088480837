import React, { useMemo } from "react"

import { Button, Icon, TableForm, TableFormLabel, TableFormValue, Tag } from "p-ui"
import { CommonDiv, FlexBetween, FlexColDiv, FlexDiv, ImgDiv, Text } from "@styles"
import { DetailOrderInfo, PaymentInfo, PromotionInfo } from "@type/orderManageType"

interface OrderDetailInfoParam {
    detailData: DetailOrderInfo
    bikeColor: Record<string, number>
    promotionInfo: PromotionInfo
    orderStatus: number
    setModalOpen: any
    modalOpen: any
    paymentInfo: PaymentInfo[]
}

function OrderDetailInfoForm(props: OrderDetailInfoParam) {
    const { detailData, bikeColor, promotionInfo, orderStatus, setModalOpen, modalOpen, paymentInfo } = props

    const disabledBtn = orderStatus === 0
    const isCancle = orderStatus === 0 || orderStatus === 1
    const isEditBtnDisabled = orderStatus >= 9 || orderStatus <= 1
    const dipositDisabled =
        paymentInfo.filter(item => item.name.includes("예약금")).some(item => item.statusLabel.includes("환불")) ||
        orderStatus === 1
    const offlineBtnDisabled =
        paymentInfo.some(item => item.name.includes("잔금")) ||
        paymentInfo.some(item => item.statusLabel.includes("대기")) ||
        orderStatus < 4

    const tagType = useMemo(() => {
        return detailData?.statusLabel?.includes("취소") ? "danger" : "primary"
    }, [detailData])

    const bikeImgColor = useMemo(() => {
        let bikeImg = ""
        switch (Object.keys(bikeColor)[0]) {
            case "매트블랙":
                bikeImg = Object.values(bikeColor)[0] === 36 ? "MatteBlack_2024" : "MattBlack"
                break
            case "화이트":
                bikeImg = Object.values(bikeColor)[0] === 36 ? "White_2024" : "White"
                break
            case "티타늄":
                bikeImg = "Titanium"
                break
            case "그레이시 블루":
                bikeImg = "DarkGrayishBlue"
                break
            case "그레이쉬블루":
                bikeImg = "DarkGrayishBlue"
                break
            case "옐로우":
                bikeImg = "Yellow_2024"
                break
            case "옐로우블랙":
                bikeImg = "YellowBlack"
                break
            case "락그레이":
                bikeImg = "RockGray_2024"
                break
            case "캔디블루":
                bikeImg = "CandyBlue_2024"
                break
            default:
                break
        }
        return bikeImg
    }, [bikeColor])

    return (
        <CommonDiv width="45%">
            <FlexBetween margin="0 0 10px 0">
                <Text
                    bold
                    fontSize="16px"
                >
                    주문 정보
                </Text>
                <FlexDiv gap="10px">
                    {isCancle ? (
                        <Button
                            size="small"
                            type="danger"
                            disabled={dipositDisabled}
                            onClick={() => {
                                setModalOpen({ ...modalOpen, isDepositOpen: true })
                            }}
                        >
                            예약금 환불
                        </Button>
                    ) : (
                        <Button
                            size="small"
                            type="secondary"
                            disabled={offlineBtnDisabled}
                            onClick={() => {
                                setModalOpen({ ...modalOpen, isPaymentOpen: true })
                            }}
                        >
                            현장 결제
                        </Button>
                    )}
                    <Button
                        size="small"
                        disabled={disabledBtn}
                        onClick={() => {
                            setModalOpen({ ...modalOpen, isCancleOpen: true })
                        }}
                        type="danger"
                    >
                        주문 취소
                    </Button>
                </FlexDiv>
            </FlexBetween>

            <FlexDiv
                gap="10px"
                alignItems="center"
            >
                <ImgDiv
                    size="250px"
                    borderRadius="8px"
                    backGroundImg={`${process.env.PUBLIC_URL}/images/zentropy_${bikeImgColor}.png`}
                />

                <CommonDiv width="70%">
                    <TableForm>
                        <TableFormLabel sm={4}>주문번호</TableFormLabel>
                        <TableFormValue sm={8}>{detailData?.orderNo}</TableFormValue>
                        <TableFormLabel sm={4}>주문일</TableFormLabel>
                        <TableFormValue sm={8}>{detailData?.createdAt}</TableFormValue>
                        <TableFormLabel sm={4}>인도일</TableFormLabel>
                        <TableFormValue sm={8}>
                            <FlexBetween alignItems="center">
                                <Text>{detailData?.deliveryDt ? detailData.deliveryDt : "-"}</Text>
                                <Button
                                    size="small"
                                    type="primary"
                                    disabled={isCancle}
                                    compact
                                    onClick={() => {
                                        setModalOpen({ ...modalOpen, deliveryDtEditOpen: true })
                                    }}
                                >
                                    <Icon.Edit
                                        width={15}
                                        height={15}
                                    />
                                </Button>
                            </FlexBetween>
                        </TableFormValue>
                        <TableFormLabel sm={4}>주문상태</TableFormLabel>
                        <TableFormValue sm={8}>
                            <Tag
                                size="small"
                                value={detailData?.statusLabel}
                                type={tagType}
                            />
                        </TableFormValue>
                        <TableFormLabel sm={4}>센터명</TableFormLabel>
                        <TableFormValue sm={8}>{detailData?.centerNm}</TableFormValue>
                        <TableFormLabel sm={4}>구매경로</TableFormLabel>
                        <TableFormValue
                            isDefaultWrap={false}
                            sm={8}
                        >
                            <Text lineHeigt="1">{detailData?.orderRoute ? detailData.orderRoute : "-"}</Text>
                        </TableFormValue>
                        <TableFormLabel sm={4}>프로모션 명</TableFormLabel>
                        <TableFormValue
                            isDefaultWrap={promotionInfo?.promotionName ? false : true}
                            sm={8}
                        >
                            {promotionInfo?.promotionName ? (
                                <FlexColDiv gap="10px">
                                    <Text lineHeigt="2">
                                        {promotionInfo?.promotionName} - {promotionInfo?.promotionDesc}
                                    </Text>
                                    <Button
                                        size="small"
                                        type="primary"
                                        compact
                                        disabled={isEditBtnDisabled}
                                        onClick={() => {
                                            setModalOpen({ ...modalOpen, isPromotionEditOpen: true })
                                        }}
                                    >
                                        <Icon.Edit
                                            width={15}
                                            height={15}
                                        />
                                    </Button>
                                </FlexColDiv>
                            ) : (
                                <FlexBetween alignItems="center">
                                    <Text>-</Text>
                                    <Button
                                        size="small"
                                        type="primary"
                                        compact
                                        disabled={isEditBtnDisabled}
                                        onClick={() => {
                                            setModalOpen({ ...modalOpen, isPromotionEditOpen: true })
                                        }}
                                    >
                                        <Icon.Edit
                                            width={15}
                                            height={15}
                                        />
                                    </Button>
                                </FlexBetween>
                            )}
                        </TableFormValue>
                    </TableForm>
                </CommonDiv>
            </FlexDiv>
        </CommonDiv>
    )
}

export default OrderDetailInfoForm
