import styled from "styled-components"

export const DataPickerInput = styled.input`
    border: none;
    height: 50px;
    width: 100%;
    background-color: #f8f8f8;
    outline: none;
    padding-left: 1rem;
    // color: #6e6e6e;
    border-radius: 8px;
`
