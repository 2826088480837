import React, { useState, useEffect } from "react"

import { useRecoilState, useRecoilValue } from "recoil"
import { centerParamState, stationParamState } from "src/store/staiton"

import { FlexColDiv, FlexDiv, SettingDiv } from "@styles"
import { Button, Input, LabelForm, Radio } from "p-ui"

import apis from "@apis"

import useAlert from "@context/alert/useAlert"

import { StationManage } from "@common/constatns"

import { CenterTypes } from "@type/stationType"

const CenterForm = (props: CenterFormProps) => {
    const { stationData, getStationData, isCenter, setIsCenter, edit, id } = props

    const [cntType, setCntType] = useState<string>("1")

    const [centerParam, setCenterParam] = useRecoilState<CenterTypes>(centerParamState)
    const stationParam = useRecoilValue(stationParamState)
    const { openAlert } = useAlert()

    const changeInputCenter = (e: any) => {
        setCenterParam({ ...centerParam, [e.name]: e.value })
    }
    const deleteCenter = async () => {
        await apis.Station.deleteCenterData(stationData.centerId as number).then(res => {
            if (res.status == "SUCCESS") {
                openAlert({ title: "삭제", body: "센터가 삭제되었습니다.", type: "warning" })

                getStationData(id as string)
                setIsCenter(false)
            } else {
                openAlert({ title: "실패", body: "저장 실패.", type: "warning" })
            }
        })
    }

    useEffect(() => {
        if (stationData) {
            stationData.centerId && setIsCenter(true)
        }
        if (id) {
            setCntType(stationData?.centerType)
            setCenterParam({
                centerNm: stationData?.centerNm,
                telNo: stationData?.telNo,
                centerType: stationData?.centerType,
                zipCode: stationData?.zipCode,
                centerId: stationData?.centerId ? stationData?.centerId : 0,
                bisNo: stationData?.bisNo,
            })
        }
    }, [stationData])

    useEffect(() => {
        /**radio 버튼 onChange안돼서 cntType따로 설정해줌, station에서 zipcode등록되면 zipcode등록해줌 */
        setCenterParam({ ...centerParam, centerType: cntType, zipCode: stationParam.zipCode })
    }, [cntType, stationParam])

    return (
        <FlexColDiv
            gap="10px"
            style={{ height: "100%" }}
        >
            <FlexDiv
                alignItems="center"
                gap="5px"
            >
                <h4>센터 설정</h4>
                <span>* 은 필수 입력 항목입니다.</span>
            </FlexDiv>
            {isCenter ? (
                <>
                    <LabelForm
                        required
                        label="지점명"
                    >
                        <Input
                            value={stationData && stationData.centerNm}
                            onChange={e => {
                                changeInputCenter(e)
                            }}
                            placeholder="지점명을 입력해주세요 (ex. 강남 청담 대리점)"
                            name="centerNm"
                            disabled={!isCenter}
                            readonly={!edit && id ? true : false}
                        />
                    </LabelForm>
                    <LabelForm
                        required
                        label="전화번호"
                    >
                        <Input
                            value={stationData && stationData.telNo}
                            onChange={e => {
                                changeInputCenter(e)
                            }}
                            name="telNo"
                            placeholder="- 포함하여 입력"
                            disabled={!isCenter}
                            readonly={!edit && id ? true : false}
                        />
                    </LabelForm>
                    <LabelForm
                        required
                        label="사업자 번호"
                    >
                        <Input
                            value={stationData && stationData.bisNo}
                            onChange={e => {
                                changeInputCenter(e)
                            }}
                            name="bisNo"
                            placeholder="- 포함하여 입력"
                            disabled={!isCenter}
                            readonly={!edit && id ? true : false}
                        />
                    </LabelForm>
                    <LabelForm label="주소">
                        <Input
                            value={stationData?.centerId && stationData.addressRoad}
                            readonly
                            disabled={!isCenter}
                            defaultValue="주소는 스테이션의 주소와 동일하게 저장됩니다"
                        />
                    </LabelForm>
                    <div>
                        <LabelForm
                            fullHeight
                            required
                            label="센터유형"
                        >
                            {stationData && stationData.centerType?.toString() == "0" ? (
                                <Input value="본사" />
                            ) : (
                                <FlexDiv height="100%">
                                    <Radio.Group
                                        defaultValue={"1"}
                                        value={stationData && stationData.centerType?.toString()}
                                        onChange={option => setCntType(option.toString())}
                                        disabled={!isCenter || (!edit && id) ? true : false}
                                        options={StationManage.centerOption}
                                    />
                                </FlexDiv>
                            )}
                        </LabelForm>
                    </div>
                    <Button
                        disabled={!id || !stationData.centerId}
                        type="danger"
                        onClick={deleteCenter}
                    >
                        센터 삭제
                    </Button>
                </>
            ) : (
                <SettingDiv>
                    <Button
                        type="tertiary"
                        disabled={!edit && id ? true : false}
                        onClick={() => setIsCenter(true)}
                    >
                        센터 설정
                    </Button>
                </SettingDiv>
            )}
        </FlexColDiv>
    )
}

export default CenterForm

interface CenterFormProps {
    stationData: any
    getStationData: (id: string) => void
    setIsCenter: (data: boolean) => void
    isCenter: boolean
    edit: boolean
    id?: string
}
