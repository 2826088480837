import { Codes, ParentCodes, StationForASCode } from "@type/codeType"
import { Result } from "@type/responseType"
import axios from "./config"
import { ASCODE } from "./urls"

const Code = {
    /**
     * GET AS Parent Code List
     * @returns {Array}
     */
    async getParentCodeList(): Promise<Result<ParentCodes[]>> {
        try {
            return await axios.host.get(ASCODE.GET_AS_PARENT)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * GET AS Code List
     * @returns {Array}
     */
    async getASCodeList(): Promise<Result<Codes[]>> {
        try {
            return await axios.host.get(ASCODE.GET_AS)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * GET Station List for AS Code
     * @returns {Array}
     */
    async getStationListForASCode(): Promise<Result<StationForASCode[]>> {
        try {
            return await axios.host.get(ASCODE.GET_STATION_LIST_FOR_AS)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * AS Code 삭제
     * @param codeId number
     * @returns httpResponse
     */
    async deleteASCode(codeId: number): Promise<Result<String>> {
        try {
            return await axios.host.delete(ASCODE.DELETE + codeId)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * AS Code 수정
     * @param code Codes
     * @returns httpResponse
     */
    async updateASCode(code: Codes): Promise<Result<String>> {
        try {
            return await axios.host.put(ASCODE.PUT, code)
        } catch (e: any) {
            return e.message
        }
    },

    /**
     * AS Code 등록
     * @param code any
     * @returns httpResponse
     */
    async insertASCode(code: Codes): Promise<Result<Codes>> {
        try {
            return await axios.host.post(ASCODE.POST, code)
        } catch (e: any) {
            return e.message
        }
    },
}

export default Code
