import { useState, useEffect } from "react"

import { Alert, Input, LabelForm, Select } from "p-ui"
import CustomDatePicker from "@components/widgets/customDatePicker"
import ReactDatePicker from "react-datepicker"
import { DataPickerInput, FlexColDiv } from "@styles"

import { ko } from "date-fns/locale"
import utils from "@utils"

import { RentalDepositStatus } from "@common/constatns"
import { setHours, setMinutes } from "date-fns"
import { areDatesEqual, extractTimeFromDateString } from "src/utils/date"

interface openAlert {
    isOpen: boolean
    msg: string
}

const RentalFleetEndModal = (props: any) => {
    const { rentalFleetBikeData, setEditForm } = props
    const { depositStatus, startDt } = rentalFleetBikeData || {}
    const [isVisible, setIsVisible] = useState<boolean>(true)
    const [enterDeposit, setEnterDeposit] = useState<string>()
    const currentDate = new Date()
    const [startDate, setStartDate] = useState(currentDate)
    const [minDate, setMinDate] = useState(currentDate)
    const [isSetMinTime, setIsSetMinTime] = useState(true)
    const [openAlert, setOpenAlert] = useState<openAlert>({ isOpen: false, msg: "" })

    const handleInputChange = (e: any) => {
        const inputValue = e.value
        const sanitizedValue = inputValue.replace(/,/g, "") // 콤마 제거

        if (isNaN(sanitizedValue)) {
            setOpenAlert({ isOpen: true, msg: "숫자만 입력해주세요." })
            setEnterDeposit("0")
            setEditForm(e.name, null)
        } else if (sanitizedValue.length > 8) {
            setOpenAlert({ isOpen: true, msg: "8자리 이하의 숫자만 입력해주세요." })
            setEnterDeposit("0")
            setEditForm(e.name, null)
        } else {
            const removedCommaValue: number = Number(sanitizedValue)
            setEnterDeposit(removedCommaValue.toLocaleString())
            setEditForm(e.name, removedCommaValue)
        }
    }
    const closeAlert = () => {
        setOpenAlert({ ...openAlert, isOpen: false })
    }

    const closeAlertAfterDelay = () => {
        setTimeout(() => {
            closeAlert()
        }, 2000)
    }

    useEffect(() => {
        if (openAlert.isOpen) {
            closeAlertAfterDelay()
        }
    }, [openAlert.isOpen])

    useEffect(() => {
        setStartDate(new Date(startDt))
        setEditForm("endDt", startDt)
        setMinDate(new Date(startDt))
    }, [])

    return (
        <>
            <FlexColDiv gap="16px">
                <LabelForm label="보증">
                    <Select
                        name="depositStatus"
                        options={RentalDepositStatus.map(item => item.label)}
                        defaultValue={RentalDepositStatus.find(item => depositStatus === item.value)?.label}
                        onChange={(label: string, value: any) => {
                            setEditForm(label, RentalDepositStatus.find(item => item.label === value)?.value)
                            setEditForm("deposit", null) //보증 선택 바뀔때마다 이용금액 초기화
                            setIsVisible(value === RentalDepositStatus[1].label)
                            setEnterDeposit(value === RentalDepositStatus[1].label ? enterDeposit : "")
                        }}
                    />
                </LabelForm>
                <LabelForm label="이용금액 (원)">
                    <Input
                        name="deposit"
                        disabled={!isVisible}
                        placeholder={isVisible ? "이용금액을 입력하세요." : ""}
                        value={enterDeposit}
                        onChange={(e: any) => {
                            handleInputChange(e)
                        }}
                    />
                </LabelForm>
                <LabelForm label="종료담당자">
                    <Input
                        name="endMgrNm"
                        placeholder={"종료담당자 이름을 입력하세요."}
                        onChange={(e: any) => {
                            setEditForm(e.name, e.value)
                        }}
                    />
                </LabelForm>
                <LabelForm label="종료일">
                    <CustomDatePicker
                        width={"100%"}
                        height={"50px"}
                    >
                        <ReactDatePicker
                            selected={startDate}
                            onChange={(date: Date) => {
                                setStartDate(date)
                                setEditForm("endDt", date.format(utils.FORMAT_SHORT_DATETIME))
                                setIsSetMinTime(areDatesEqual(date, new Date(startDt)))
                            }}
                            locale={ko}
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeSelect
                            timeIntervals={5}
                            customInput={<DataPickerInput height={"50px"} />}
                            minDate={minDate}
                            minTime={setHours(
                                setMinutes(new Date(), isSetMinTime ? extractTimeFromDateString(startDt).minutes : 0),
                                isSetMinTime ? extractTimeFromDateString(startDt).hours : 0
                            )}
                            maxTime={setHours(setMinutes(new Date(), 55), 23)}
                            withPortal
                            showDisabledMonthNavigation
                        />
                    </CustomDatePicker>
                </LabelForm>
            </FlexColDiv>
            <Alert
                open={openAlert.isOpen}
                type="warning"
                body={openAlert.msg}
                title="실패"
                onClose={() => setOpenAlert({ ...openAlert, isOpen: false })}
            />
        </>
    )
}

export default RentalFleetEndModal
