import { FlexDiv, Text } from "@styles"
import { Station } from "@type/responseType"

function StationFootData(props: StationFootDataProps) {
    return (
        <div>
            <FlexDiv
                alignItems="flex-end"
                height="100%"
                gap="5px"
                justifyContent="flex-end"
                margin="10px 0 0 0 "
            >
                <Text
                    fontWeight={900}
                    fontSize="1rem"
                    margin="0 1rem 0 0"
                    color="var(--primary)"
                >
                    • Total : {props.dataList.length}
                </Text>
                <Text
                    fontWeight={900}
                    fontSize="1rem"
                    margin="0 1rem 0 0"
                    color="var(--success)"
                >
                    • 스테이션 : {props.dataList.filter((item: any) => item.status == "1" && !item.centerNm).length}
                </Text>
                <Text
                    fontWeight={900}
                    fontSize="1rem"
                    margin="0 1rem 0 0"
                    color="var(--zen-orange)"
                >
                    • 대리/협력점 : {props.dataList.filter((item: any) => item.centerNm && item.status == "1").length}
                </Text>
                <Text
                    fontWeight={900}
                    fontSize="1rem"
                    margin="0 1rem 0 0"
                    color="var(--zen-orange)"
                >
                    • 전기시공 : {props.dataList.filter((item: any) => item.status == "2").length}
                </Text>
                <Text
                    fontWeight={900}
                    fontSize="1rem"
                    margin="0 1rem 0 0"
                    color="var(--danger)"
                >
                    • 설치예정 : {props.dataList.filter((item: any) => item.status == "0").length}
                </Text>
                <Text
                    fontWeight={900}
                    fontSize="1rem"
                    margin="0 1rem 0 0"
                    color="#6284fd"
                >
                    • 문의 : {props.dataList.filter((item: any) => ["3", "4"].includes(item.status)).length}
                </Text>
            </FlexDiv>
        </div>
    )
}

export default StationFootData
interface StationFootDataProps {
    dataList: Station[]
}
