import { useContext } from "react"

import { AlertDispatchContext } from "@context/alert/AlertContext"
import DefaultAlert from "@components/widgets/DefaultAlert"

export default function useAlert() {
    const { open, close } = useContext(AlertDispatchContext)

    const openAlert = (props: any) => {
        open(DefaultAlert, props)
    }
    const closeAlert = (Component: any) => {
        close(DefaultAlert)
    }

    return {
        openAlert,
        closeAlert,
    }
}
