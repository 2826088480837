import { FlexDiv, Text } from "@styles"
import { InfoWrap } from "../styles"

function StationInfo() {
    return (
        <InfoWrap>
            {info.map((info, i) => {
                return (
                    <FlexDiv
                        alignItems="flex-end"
                        gap="5px"
                        justifyContent="flex-end"
                        key={info.name}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}${info.src}`}
                            alt=""
                            className={info.key}
                        />
                        <Text fontSize="14px">{info.name}</Text>
                    </FlexDiv>
                )
            })}
        </InfoWrap>
    )
}

export default StationInfo

const info = [
    {
        key: "main",
        name: "청담본사",
        src: "/images/zentropyMainIconSmall.png",
    },
    {
        key: "installed",
        name: "설치완료",
        src: "/images/stationIcon.png",
    },
    {
        key: "center",
        name: "대리/협력점",
        src: "/images/center.svg",
    },
    {
        key: "plan",
        name: "설치예정",
        src: "/images/stationIcon2.png",
    },
    {
        key: "electric",
        name: "전기시공",
        src: "/images/evIconSmall.png",
    },
]
