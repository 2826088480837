import React, { useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router"

import InsertHeader from "../component/insertHeader"
import { Editor } from "@toast-ui/react-editor"
import { Button, Col, LabelForm, PageHeader, Row } from "p-ui"
import { FlexDiv, TextAlignDiv } from "@styles"

import colorSyntax from "@toast-ui/editor-plugin-color-syntax"
import "@toast-ui/editor/dist/i18n/ko-kr"
import "@toast-ui/editor/dist/toastui-editor.css"
import "tui-color-picker/dist/tui-color-picker.css"
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css"

import REQUEST_TASK from "src/apis/reqTask"

import { getManagerId, getManagerName, getOrgName } from "@common/util"
import useAlert from "@context/alert/useAlert"

import { editorPlaceHolder, issuePlaceHolder } from "../constants"

import { RequestParamType } from "@type/formType"

const RequestInsertPage = () => {
    const navigate = useNavigate()

    const { openAlert } = useAlert()

    const [reqType, setReqType] = useState(1)
    const [reqIssue, setReqIssue] = useState<string | any>("")
    const [reqDesc, setReqDesc] = useState<string | any>("")

    const requsetEditorRef = useRef<Editor>(null)
    const isuueEditorRef = useRef<Editor>(null)

    const [requestParam, setRequestParam] = useState<RequestParamType>({
        reqTitle: "",
        reqOrgNm: getOrgName() as string,
        reqUserNm: getManagerName() as string,
        taskOrgNm: "시스템개발",
        serviceCode: 0,
        priority: 1,
        reqType: 0,
        reqDesc: "",
        reqIssue: "",
        reqManager: Number(getManagerId()),
    })

    const validation = () => {
        const form = requestParam
        const formFields: string[] = [
            "reqTitle",
            "reqType",
            "serviceCode",
            "priority",
            "reqOrgNm",
            "reqUserNm",
            "reqDesc",
        ]

        const message: { [key: string]: string } = {
            reqTitle: "제목을 입력 해 주세요",
            reqDesc: "요청사항을 입력 해 주세요",
            reqOrgNm: "요청 부서를 선택 해 주세요",
            reqUserNm: "요청자를 입력 해 주세요",
            priority: "긴급여부를 선택 해 주세요",
            reqType: "개발 유형을 선택 해 주세요",
            serviceCode: "요청 서비스를 선택 해 주세요",
        }

        // check valid
        const field = formFields.find(field => !form[field])

        if (field) {
            // action
            alert(message[field])
            return
        } else {
            insertReqTask()
        }
    }
    /**업무요청 등록 함수 */
    const insertReqTask = async () => {
        await REQUEST_TASK.insertReqTask(requestParam).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "저장", body: "업무요청이 저장되었습니다.", type: "primary" })

                setTimeout(() => {
                    navigate("/request/main")
                }, 2000)
            }
        })
    }

    /**문제사항 저장 함수*/
    useEffect(() => {
        if (reqIssue === "<p><br></p>") {
            setRequestParam(prev => ({ ...prev, reqIssue: "" }))
        } else {
            setRequestParam(prev => ({ ...prev, reqIssue: reqIssue }))
        }
    }, [reqIssue])

    /**요청사항 저장 함수*/
    useEffect(() => {
        if (reqDesc === "<p><br></p>") {
            setRequestParam(prev => ({ ...prev, reqDesc: "" }))
        } else {
            setRequestParam(prev => ({ ...prev, reqDesc: reqDesc }))
        }
    }, [reqDesc])

    return (
        <>
            <PageHeader
                title="업무요청"
                subTitle="업무요청 등록"
            />
            <InsertHeader
                mode={"insert"}
                setReqType={setReqType}
                setRequestParam={setRequestParam}
                requestParam={requestParam}
            />
            <Row
                gap={10}
                marginBottom={10}
            >
                <Col sm={12}>
                    <LabelForm
                        required
                        label="요청사항"
                        labelCol={1.5}
                        formCol={10.5}
                    >
                        <TextAlignDiv align="left">
                            <Editor
                                placeholder={editorPlaceHolder}
                                ref={requsetEditorRef}
                                initialValue={reqDesc}
                                plugins={[colorSyntax]}
                                previewStyle="vertical"
                                height="400px"
                                initialEditType="wysiwyg"
                                useCommandShortcut={true}
                                language="ko-KR"
                                onChange={() => setReqDesc(requsetEditorRef.current?.getInstance().getHTML())}
                            />
                        </TextAlignDiv>
                    </LabelForm>
                </Col>
                {reqType !== 3 && (
                    <Col sm={12}>
                        <LabelForm
                            label="문제사항"
                            labelCol={1.5}
                            formCol={10.5}
                        >
                            <TextAlignDiv align="left">
                                <Editor
                                    placeholder={issuePlaceHolder}
                                    ref={isuueEditorRef}
                                    initialValue={reqIssue}
                                    plugins={[colorSyntax]}
                                    previewStyle="vertical"
                                    height="400px"
                                    initialEditType="wysiwyg"
                                    useCommandShortcut={true}
                                    language="ko-KR"
                                    onChange={() => setReqIssue(isuueEditorRef.current?.getInstance().getHTML())}
                                />
                            </TextAlignDiv>
                        </LabelForm>
                    </Col>
                )}
            </Row>
            <FlexDiv justifyContent="flex-end">
                <Button
                    onClick={validation}
                    type="primary"
                >
                    업무요청 등록
                </Button>
            </FlexDiv>
        </>
    )
}

export default RequestInsertPage
