import React, { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import { Button, CheckBox, Col, DataTable, Input, PageHeader, Row, Tag } from "p-ui"
import { CommonDiv, DataPickerInput, DatePickerSpan, FlexDiv, GridDiv, Label, Text } from "@styles"

import { downloadExcel, formatPhone, observPage, updateActivePage } from "@common/util"
import orderManage from "src/apis/orderManage"

import { onChangeFilter } from "src/utils/filter"

import { Order } from "../constants"

import { onChangeParam } from "@type/common"
import { OrderInfo } from "@type/orderManageType"
import CustomDatePicker from "@components/widgets/customDatePicker"
import { ko } from "date-fns/locale"
import ReactDatePicker from "react-datepicker"
import { OptionType, PageRow } from "p-ui/dist/esm/types"
import { useSearchParams } from "react-router-dom"
import { isWithinInterval, parseISO, startOfDay, endOfDay } from "date-fns"

function OrderPage() {
    const currentDate = new Date()
    const navigate = useNavigate()
    const location = useLocation()
    const tableRef = useRef<HTMLTableElement | null>(null)

    const [searchParams, setSearchParams] = useSearchParams()
    const pageParam = searchParams.get("page")
    const searchTextParam = searchParams?.get("searchText")
    const statusParam = searchParams.get("status")
    const startDateParam = searchParams.get("startDate")
    const endDateParam = searchParams.get("endDate")
    const limitParam = searchParams.get("limit")

    const [startDate, setStartDate] = useState(startDateParam ? new Date(startDateParam) : currentDate)
    const [endDate, setEndDate] = useState<Date>(endDateParam ? new Date(endDateParam) : currentDate)

    const [orderData, setOrderData] = useState<OrderInfo[]>([])

    const [clickedFilter, setClickedFilter] = useState<number[]>(statusParam ? statusParam.split(",").map(Number) : [])

    const [searchText, setSearchText] = useState<string>("")
    const [filterText, setFilterText] = useState<string>("")

    const [checkDate, setCheckDate] = useState<boolean>(false)
    const [searchDate, setSearchDate] = useState<boolean>(false)
    const [pageRows, setPageRows] = useState<PageRow>(15)

    const [filterOptions, setFilterOptions] = useState<OptionType[]>(Order.orderStep)
    const [nowPage, setNowPage] = useState<any>(pageParam !== null ? pageParam : 1)

    const filterData = useMemo(() => {
        return orderData.filter(item => {
            const createdAtValid =
                !checkDate ||
                isWithinInterval(parseISO(item.createdAt), {
                    start: parseISO(startOfDay(startDate).toISOString()),
                    end: parseISO(endOfDay(endDate).toISOString()),
                })

            const orderStatusValid = clickedFilter.includes(99) || clickedFilter.includes(Number(item.orderStatus))

            const filterTextValid = filterText === "" || item.userName.includes(filterText)

            return createdAtValid && orderStatusValid && filterTextValid
        })
    }, [clickedFilter, orderData, filterText, searchDate])

    const resetFilter = () => {
        setClickedFilter(defaultFilter.concat(99))
        setSearchText("")
        setFilterText("")
        setStartDate(currentDate)
        setEndDate(currentDate)
        setCheckDate(false)
        setSearchDate(false)
        setNowPage(1)
    }

    const getOrderList = () => {
        orderManage
            .getOrderList()
            .then(res => {
                if (res.status === "SUCCESS") {
                    const mappedData = res.resultData.map((item: OrderInfo) => {
                        return {
                            ...item,
                            codeTag: (
                                <Tag
                                    type={
                                        item.orderStatus === 0 || item.orderStatus === 1
                                            ? "danger"
                                            : item.codeLabel.includes("완료")
                                            ? "primary"
                                            : "success"
                                    }
                                    value={item.codeLabel}
                                />
                            ),
                            detail: (
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        navigate({ pathname: "/order/detail/" + item.orderId })
                                    }}
                                >
                                    상세보기 →
                                </Button>
                            ),
                            userPhone: formatPhone(item.userPhone),
                            deliveryDt: item.createdAt ? <span>{item.createdAt}</span> : <span>-</span>,
                        }
                    })

                    setOrderData(mappedData)
                }
            })
            .catch(error => console.log(error))
    }

    const filter = () => {
        if (location.search.length === 0) {
            setClickedFilter([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 99])

            setSearchParams({
                searchText: searchText,
                startDate: startDate.toString(),
                endDate: endDate.toString(),
                status: clickedFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        } else {
            setSearchParams({
                searchText: searchText,
                startDate: startDate.toString(),
                endDate: endDate.toString(),
                status: clickedFilter.toString(),
                page: nowPage,
                limit: pageRows.toString(),
            })
        }
    }
    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)
            if (newActivePage === 0) {
                setNowPage(Number(pageParam))
                return
            }
            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    useEffect(() => {
        getOrderList()
        const newArr = [...Order.orderStep]
        if (searchTextParam) {
            setFilterText(searchTextParam)
            setSearchText(searchTextParam)
        }
        if (limitParam) {
            setPageRows(Number(limitParam) as unknown as PageRow)
        }
        newArr.splice(0, 0, { label: "전체", value: 99 })
        setFilterOptions(newArr)
    }, [])

    useEffect(() => {
        if (startDateParam && endDateParam) {
            if (startDate.getDate() == currentDate.getDate() && endDate.getDate() == currentDate.getDate()) {
                setCheckDate(false)
                filter()
                return
            } else {
                setCheckDate(true)
                filter()
                return
            }
        } else {
            filter()
        }
    }, [orderData, clickedFilter, nowPage, checkDate, searchDate, pageRows])
    return (
        <>
            <PageHeader
                title="주문 관리"
                subTitle="전체 주문 관리"
            />
            <Row gap={"20px"}>
                <Col sm={1}>
                    <Label>검색</Label>
                </Col>
                <Col sm={11}>
                    <FlexDiv gap="10px">
                        <Input
                            size="small"
                            width="300px"
                            value={searchTextParam ? searchTextParam : ""}
                            placeholder="이름 검색"
                            onChange={(e: onChangeParam) => {
                                setSearchText(e.value as string)
                            }}
                            onSearch={() => {
                                setNowPage(1)
                                setFilterText(searchText)
                                filter()
                            }}
                        />
                        <Button
                            size="small"
                            type="default"
                            onClick={() => {
                                resetFilter()
                            }}
                        >
                            초기화
                        </Button>
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>검색 기간</Label>
                </Col>
                <Col md={11}>
                    <FlexDiv gap="5px">
                        <CustomDatePicker>
                            <ReactDatePicker
                                selected={startDate}
                                onChange={(date: any) => setStartDate(date)}
                                locale={ko} // 한글로 변경
                                selectsStart
                                dateFormat="yyyy.MM.dd (eee)" // 시간 포맷 변경
                                showPopperArrow={false} // 화살표 변경
                                customInput={<DataPickerInput />}
                            />
                        </CustomDatePicker>
                        <DatePickerSpan> ~ </DatePickerSpan>
                        <CustomDatePicker>
                            <ReactDatePicker
                                selected={endDate}
                                onChange={(date: any) => setEndDate(date)}
                                locale={ko}
                                dateFormat="yyyy.MM.dd (eee)"
                                showPopperArrow={false}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                customInput={<DataPickerInput />}
                            />
                        </CustomDatePicker>
                        <Button
                            onClick={() => {
                                setCheckDate(true)
                                setSearchDate(!searchDate)
                            }}
                        >
                            검색
                        </Button>
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Label>주문 상태</Label>
                </Col>
                <Col md={11}>
                    <FlexDiv
                        gap="15px"
                        alignItems="flex-end"
                    >
                        <CheckBox.Group
                            value={clickedFilter}
                            onChange={(value: any) => {
                                const param = {
                                    clickedFilter: clickedFilter,
                                    checkList: value as [],
                                    defaultFilter: defaultFilter,
                                    setClickedFilter: setClickedFilter,
                                    allValue: 99,
                                }
                                onChangeFilter(param)
                                setNowPage(1)
                            }}
                        >
                            <GridDiv
                                gridCol="repeat(8, 1fr);"
                                gap="10px"
                            >
                                {filterOptions.map(item => {
                                    return (
                                        <CheckBox
                                            width={16}
                                            height={16}
                                            value={item.value}
                                            label={item.label}
                                        />
                                    )
                                })}
                            </GridDiv>
                        </CheckBox.Group>
                    </FlexDiv>
                </Col>
            </Row>
            <div ref={tableRef}>
                <DataTable
                    columns={Order.tableColumn}
                    dataList={filterData}
                    pagination={true}
                    pageRow={pageRows}
                    getPageRow={row => {
                        if (row === 0) {
                            setPageRows(Number(limitParam) as unknown as PageRow)
                            return
                        } else {
                            setPageRows(Number(row) as PageRow)
                        }
                    }}
                    nowPage={Number(pageParam)}
                    header={
                        <FlexDiv
                            alignItems="center"
                            gap="5px"
                        >
                            <CommonDiv
                                display="inline-block"
                                margin="0 0.5rem 0 0"
                            >
                                전체
                                <Text
                                    bold
                                    color="#2d5bff"
                                >
                                    {orderData.length}
                                </Text>
                                개 /
                            </CommonDiv>
                            <CommonDiv
                                display="inline-block"
                                margin="0 0.5rem 0 0"
                            >
                                취소
                                <Text
                                    bold
                                    color="#ff3030"
                                >
                                    {orderData.filter(item => item.orderStatus === 0).length}
                                </Text>
                                개
                            </CommonDiv>
                            <Button
                                onClick={() => {
                                    downloadExcel(orderData, excelHeader, "전체_주문_관리_")
                                }}
                                size="small"
                            >
                                엑셀
                            </Button>
                        </FlexDiv>
                    }
                />
            </div>
        </>
    )
}

export default OrderPage

const defaultFilter = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

const excelHeader = [
    { label: "주문상태", value: "codeLabel" },
    { label: "성명", value: "userName" },
    { label: "전화번호", value: "userPhone" },
    { label: "이메일", value: "userEmail" },
    { label: "주문명", value: "productName" },
    { label: "주문일", value: "createdAt" },
]
