import { Result } from "@type/responseType"
import axios from "./config"
import { LAB } from "./urls"

export default {
    /**
     * Request album list
     * @param {Object} params : Search conditions
     * @returns {Array}
     */
    async getBikeList(): Promise<Result<any>> {
        try {
            return await axios.host.get(LAB.GET_BIKE_LIST)
        } catch (e: any) {
            return e.message
        }
    },
    async getBikeGps(param: {
        serial: string
        startTime: string | number
        endTime: string | number
        interval: string | number
    }): Promise<Result<any>> {
        try {
            return await axios.host.get(LAB.GET_BIKE_GPS, {
                params: param,
            })
        } catch (e: any) {
            return e.message
        }
    },
    async getBikeTotal(param: {
        serial: string
        startTime: string | number
        endTime: string | number
        interval: string | number
    }): Promise<Result<any>> {
        try {
            return await axios.host.get(LAB.GET_BIKE_TOTAL, {
                params: param,
            })
        } catch (e: any) {
            return e.message
        }
    },
}
