import React, { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"

import AppState from "@store"

import { Select, Input, Row, Col } from "p-ui"
import { RegFormDatePicker } from "../styles"
import { FlexDiv } from "@styles"

import moment from "moment/moment"
import "moment/locale/ko"
import { Issue } from "@common/constatns"

import { onChangeParam } from "@type/common"
import { Category } from "@type/issue"

moment.locale("ko")

const RegForm = ({ isInsert, isDisabled = false }: any) => {
    const [formData, setFormData] = useRecoilState(AppState.reg.paramIssueStateSelector)
    const writeData = useRecoilValue(AppState.reg.writeDataState)
    const [goodsSerialList, setGoodsSerialList] = useState([] as any)
    const [issueCategoryDetailList, setIssueCategoryDetailList] = useState([] as any)

    const [selectedIssueCategory, setSelectedIssueCategory] = useState({ label: "유형 선택", value: "" })
    const [selectedIssueCategoryDetail, setSelectedIssueCategoryDetail] = useState({ label: "상세유형선택", value: "" })
    const [selectedSerial, setSelectedSerial] = useState({ label: "", value: "" })
    const [isBattery, setIsBattery] = useState(false)

    const issueCategoryList = writeData?.category
        .filter((item: { categoryDepth: number }) => item.categoryDepth == 1)
        .map((item: Category) => {
            return { label: item.categoryNm, value: item.categoryId }
        })

    const onChangeDatalistHandler = (name: string, serial: string) => {
        const bt2 = serial.slice(0, -1) + "S"
        setFormData({ ...formData, ["btSerialNo1"]: serial, ["btSerialNo2"]: bt2 })
    }
    const selectIssue = (name: string, value: string) => {
        value == "30000" ? setIsBattery(true) : setIsBattery(false)
        console.log(writeData)
        console.log("value==>")
        console.log(value)

        const serialList =
            value == "10000"
                ? writeData?.station
                : value == "20000"
                ? writeData?.bike
                : value == "30000"
                ? writeData?.battery
                : []
        console.log(serialList)

        setFormData({ ...formData, ["categoryNm"]: "" })
        setFormData({ ...formData, ["goodsSerialNo"]: "" })
        setSelectedIssueCategoryDetail({ label: "상세유형선택", value: "" })
        setSelectedSerial({ label: "", value: "" })
        setGoodsSerialList(serialList)
        if (value == "30000") {
            setIsBattery(true)
        }
        setFormData({ ...formData, [name]: value })
        setIssueCategoryDetailList(
            writeData?.category
                .filter((item: any) => {
                    return Number(value) == item.parentId && item.categoryDepth == 2
                })
                .map((item: any) => {
                    return { label: item.categoryNm, value: item.categoryId }
                })
        )
    }

    const dateChange = (value: any) => {
        let selectDay = value
        let year = selectDay.getFullYear()
        let month = ("0" + (selectDay.getMonth() + 1)).slice(-2)
        let day = ("0" + selectDay.getDate()).slice(-2)
        let hour = ("0" + selectDay.getHours()).slice(-2)
        let min = selectDay.getMinutes()

        let dateString = year + "-" + month + "-" + day + " " + hour + ":" + min

        setFormData({ ...formData, ["issueDt"]: dateString })
    }

    useEffect(() => {
        if (formData.parentId == 30000) {
            setIsBattery(true)
        }
        console.log("Use Effect Issue", formData.issueId)
        if (formData.issueId != 0) {
            setSelectedSerial({ label: formData.goodsSerialNo, value: formData.goodsSerialNo })
            setSelectedIssueCategory({ label: formData.parentCategoryNm, value: formData.parentId.toString() })
            setSelectedIssueCategoryDetail({ label: formData.categoryNm, value: formData.issueType })
        }
    }, [formData.issueId])

    useEffect(() => {
        const serialList =
            formData.parentId == 10000
                ? writeData?.station
                : formData.parentId == 20000
                ? writeData?.bike
                : formData.parentId == 30000
                ? writeData?.battery
                : []

        setGoodsSerialList(serialList)
        setIssueCategoryDetailList(
            writeData?.category
                .filter((item: any) => {
                    return formData.parentId == item.parentId && item.categoryDepth == 2
                })
                .map((item: any) => {
                    return { label: item.categoryNm, value: item.categoryId }
                })
        )
    }, [writeData])

    return (
        <>
            <Row marginBottom={8}>
                <Col sm={1}>
                    <FlexDiv
                        justifyContent="flex-end"
                        alignItems="center"
                        height="100%"
                    >
                        진행 상태
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Select
                        height={35}
                        options={Issue.issueStatus}
                        defaultValue={"3"}
                    ></Select>
                </Col>
            </Row>
            <Row marginBottom={8}>
                <Col sm={1}>
                    <FlexDiv
                        justifyContent="flex-end"
                        alignItems="center"
                        height="100%"
                    >
                        제목
                    </FlexDiv>
                </Col>
                <Col sm={11}>
                    <Input
                        height={35}
                        disabled={isDisabled}
                        value={formData.title}
                        onChange={(param: onChangeParam) => {
                            setFormData({ ...formData, [param.name]: param.value })
                        }}
                    ></Input>
                </Col>
            </Row>
            <Row marginBottom={8}>
                <Col sm={1}>
                    <FlexDiv
                        justifyContent="flex-end"
                        alignItems="center"
                        height="100%"
                    >
                        이슈유형
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Select
                        height={35}
                        options={issueCategoryList}
                        defaultValue={selectedIssueCategory.value}
                        name="parentId"
                        onChange={(name: string, value: any) => selectIssue(name, value)}
                        disabled={isDisabled}
                    ></Select>
                </Col>

                <Col sm={1}>
                    <Select
                        height={35}
                        options={issueCategoryDetailList}
                        defaultValue={selectedIssueCategoryDetail.value}
                        onChange={(name: string, value: any) => {
                            setFormData({ ...formData, [name]: value.value })
                        }}
                        disabled={isDisabled}
                    ></Select>
                </Col>
                <Col sm={1}>
                    <FlexDiv
                        justifyContent="flex-end"
                        alignItems="center"
                        height="100%"
                    >
                        접수유형
                    </FlexDiv>
                </Col>
                <Col sm={1}>
                    <Select
                        height={35}
                        options={Issue.selectedReportType}
                        defaultValue={"IB"}
                        disabled={isDisabled}
                    ></Select>
                </Col>
                <Col sm={1}>
                    <FlexDiv
                        justifyContent="flex-end"
                        alignItems="center"
                        height="100%"
                    >
                        발생일시
                    </FlexDiv>
                </Col>
                <Col sm={3}>
                    <RegFormDatePicker
                        // locale={ko}
                        onChange={date => dateChange(date)}
                        name="issueDt"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        showTimeSelect
                        dateFormat={"yyyy-MM-dd HH:mm"}
                        selected={formData.issueDt ? new Date(Date.parse(formData.issueDt)) : new Date()}
                        disabled={isDisabled}
                    />
                </Col>
            </Row>
            <Row marginBottom={8}>
                {!isBattery && (
                    <>
                        <Col sm={1}>
                            <FlexDiv
                                justifyContent="flex-end"
                                alignItems="center"
                                height="100%"
                            >
                                제품S/N
                            </FlexDiv>
                        </Col>
                        <Col sm={2}>
                            <Select
                                height={35}
                                searchable
                                options={goodsSerialList}
                                defaultValue={selectedSerial.value}
                                disabled={isDisabled}
                                name="goodsSerialNo"
                                onChange={(name: string, value: any) => {
                                    setFormData({ ...formData, [name]: value })
                                }}
                            ></Select>
                        </Col>
                    </>
                )}
                <Col sm={1}>
                    <FlexDiv
                        justifyContent="flex-end"
                        alignItems="center"
                        height="100%"
                    >
                        배터리 S/N
                    </FlexDiv>
                </Col>
                <Col sm={2}>
                    <Select
                        height={35}
                        searchable
                        options={writeData.battery}
                        defaultValue={formData.btSerialNo1}
                        disabled={isDisabled}
                        onChange={(name: string, value: any) => {
                            onChangeDatalistHandler(name, value)
                        }}
                    ></Select>
                </Col>
            </Row>
            <Row marginBottom={8}>
                <Col sm={1}>
                    <FlexDiv
                        justifyContent="flex-end"
                        alignItems="center"
                        height="100%"
                    >
                        보고자
                    </FlexDiv>
                </Col>
                <Col sm={2}>
                    <Input
                        height={35}
                        defaultValue="김나현"
                        value={formData.reporterId}
                        disabled={isDisabled}
                        name="reporterId"
                        onChange={(param: onChangeParam) => {
                            setFormData({ ...formData, [param.name]: param.value })
                        }}
                    ></Input>
                </Col>
                <Col sm={1}>
                    <FlexDiv
                        justifyContent="flex-end"
                        alignItems="center"
                        height="100%"
                    >
                        출동 인원
                    </FlexDiv>
                </Col>
                <Col sm={2}>
                    <Input
                        height={35}
                        value={formData.handlerId}
                        disabled={isDisabled}
                        name="handlerId"
                        onChange={(param: onChangeParam) => {
                            setFormData({ ...formData, [param.name]: param.value })
                        }}
                    ></Input>
                </Col>
            </Row>
            <Row marginBottom={8}>
                <Col sm={1}>
                    <FlexDiv
                        justifyContent="flex-end"
                        alignItems="center"
                        height="100%"
                    >
                        발생위치
                    </FlexDiv>
                </Col>
                <Col sm={11}>
                    <Input
                        height={35}
                        value={formData.product}
                        disabled={isDisabled}
                        name="product"
                        onChange={(param: onChangeParam) => {
                            setFormData({ ...formData, [param.name]: param.value })
                        }}
                    ></Input>
                </Col>
            </Row>
        </>
    )
}

export default RegForm
