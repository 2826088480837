import { Result, RentalFleetInfo, RentalFleetInfoDetail, RentalFleetDetail } from "@type/responseType"
import axios from "./config"
import { RENTAL_FLEET } from "./urls"

export default {
    /**
     * 전체 대차 리스트 조회
     * @returns {Array}
     */
    async getRentalFleetList(): Promise<Result<RentalFleetInfo>> {
        try {
            return await axios.host.get(RENTAL_FLEET.GET_LIST)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * 대차 단건 조회
     * @param leaseId
     * @returns {Result}
     */
    async getRentalFleetDetail(leaseId: number): Promise<Result<RentalFleetDetail>> {
        try {
            return await axios.host.get(RENTAL_FLEET.GET_DETAIL + leaseId)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * 대차 정보 수정
     * @param param 대차 정보 param
     * @returns
     */
    async updateRentalFleetDetail(body: RentalFleetInfo) {
        try {
            return await axios.host.put(RENTAL_FLEET.PUT, body)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * 대차 정보 등록
     * @param body 대차 정보 body
     * @returns {results}
     */
    async insertRentalFleetDetail(body: RentalFleetInfoDetail) {
        try {
            return await axios.host.post(RENTAL_FLEET.POST, body)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * 비고 수정
     * @param bikeSerial 바이크 시리얼
     * @param comment 비고 내용
     * @returns {results}
     */
    async updateRentalFleetMemo(bikeSerial: string, comment: string) {
        try {
            return await axios.host.put(RENTAL_FLEET.PUT_MEMO + `${bikeSerial}/comment`, comment)
        } catch (err: any) {
            return err.message
        }
    },

    async getRentalFleetHistory(bikeSerial: string | undefined) {
        try {
            return await axios.host.get(RENTAL_FLEET.GET_HISTORY + bikeSerial)
        } catch (err: any) {
            return err.message
        }
    },
}
