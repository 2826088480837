import CustomDatePicker from "@components/widgets/customDatePicker"
import { DataPickerInput, FlexColDiv, FlexDiv, Text } from "@styles"
import { Editor } from "@toast-ui/react-editor"
import { Button, Input, LabelForm, PageHeader, Radio, Select } from "p-ui"
import ReactDatePicker from "react-datepicker"
import { useState, useEffect, useRef } from "react"
import { Notice } from "@type/bbsType"
import notice from "src/apis/notice"
import utils from "@utils"
import { ko } from "date-fns/locale"
import useAlert from "@context/alert/useAlert"
import { Code } from "@type/responseType"
import { useNavigate } from "react-router"
import { getManagerId, noticeValidate } from "@common/util"
import BbsEditor from "@pages/Bbs/Components/BbsEditor"
import BbsDragAndDropFile from "@pages/Bbs/Components/BbsDragAndDrop"
import file from "src/apis/file"
import { noticeFolderName, noticeLinkInfo } from "@pages/Bbs/constants"
import { createVideoContainer } from "@pages/Bbs/Components/createVideoContainer"

const InsertNotice = () => {
    const navigate = useNavigate()
    const currentDate = new Date()

    const [postingDt, setPostingDt] = useState<Date>(currentDate)
    const [noticeData, setNoticeData] = useState<Notice>(defaultNoticeData)
    const [noticeType, setNoticeType] = useState<Code[]>(defaultCodeData)
    const [contents, setContents] = useState<string | any>("")
    const [uploadFiles, setUploadFiles] = useState([])

    const editorRef = useRef<Editor>(null)

    const managerId = getManagerId()
    const { openAlert } = useAlert()

    /**
     * 공지사항 카테고리(구분) 조회
     */
    const getType = () => {
        notice.getType().then(res => {
            if (res.status === "SUCCESS") {
                setNoticeType(res.resultData)
                setForm("postingDate", currentDate.format(utils.FORMAT_DATETIME))
                setForm("regManager", Number(managerId))
            }
        })
    }

    const setForm = (name: any, value: any) => {
        setNoticeData((item: any) => ({ ...item, [name]: value }))
    }

    const checkValidate = () => {
        const { fieldList, notValidMessage } = noticeValidate()

        if (noticeData !== undefined) {
            const data = noticeData
            const isNotValidField = fieldList.find(field => !data[field as keyof Notice])

            if (isNotValidField) {
                return openAlert({ title: "실패", body: notValidMessage[isNotValidField].toString(), type: "warning" })
            }
            if (data.contents === "<p><br></p>") {
                return openAlert({ title: "실패", body: "공지사항 내용을 입력해주세요.", type: "warning" })
            }
            insertNotice(data)
        }
    }

    /**
     * 공지사항 등록
     * @param {Notice} body 공지사항 정보
     */
    const insertNotice = (body: Notice) => {
        notice.insertNotice(body).then(res => {
            if (res.status === "SUCCESS") {
                uploadFiles.length > 0 ? uploadFile(res.resultData.noticeId) : insertSuccessAction()
            } else {
                openAlert({ title: "실패", body: "공지사항 정보 등록 실패", type: "warning" })
            }
        })
    }

    /**
     * 파일 업로드
     * @param fileId fileId
     */
    const uploadFile = (fileId: any) => {
        const formData = new FormData()
        uploadFiles.map((item: any) => {
            formData.append("formData", item.object)
        })

        formData.append("linkKey", fileId)
        formData.append("linkInfo", noticeLinkInfo)

        file.uploadFileList(noticeFolderName, formData)
            .then(res => {
                if (res.status === "SUCCESS") {
                    insertSuccessAction()
                } else {
                    openAlert({
                        title: "실패",
                        body: "공지사항 첨부파일 등록 실패했습니다. 첨부파일만 다시 등록해주세요.",
                        type: "warning",
                    })
                    navigate("/bbs/notice")
                }
            })
            .catch(err => console.log(err))
    }

    const insertSuccessAction = () => {
        openAlert({ title: "성공", body: "공지사항이 등록되었습니다.", type: "primary" })
        navigate("/bbs/notice")
    }

    useEffect(() => {
        getType()
    }, [])

    useEffect(() => {
        setForm("contents", createVideoContainer(contents))
    }, [contents])

    return (
        <>
            <FlexDiv alignItems="center">
                <PageHeader
                    title={"공지사항 관리"}
                    subTitle={"공지사항 등록"}
                />
                <Button
                    type="primary"
                    onClick={() => checkValidate()}
                >
                    등록하기
                </Button>
            </FlexDiv>
            <FlexColDiv gap="36px">
                <FlexColDiv
                    width="1200px"
                    gap="8px"
                >
                    <LabelForm
                        label={"제목"}
                        labelCol={2}
                        required
                    >
                        <Input
                            name="title"
                            onChange={e => {
                                setForm(e.name, e.value)
                            }}
                        />
                    </LabelForm>
                    <LabelForm
                        label={"작성일"}
                        labelCol={2}
                        required
                    >
                        <CustomDatePicker
                            width="100%"
                            height="50px"
                        >
                            <ReactDatePicker
                                selected={postingDt}
                                onChange={(date: Date) => {
                                    setPostingDt(date)
                                    setForm("postingDate", date.format(utils.FORMAT_DATETIME))
                                }}
                                locale={ko}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                showTimeInput
                                customInput={<DataPickerInput height={"50px"} />}
                                withPortal
                            />
                        </CustomDatePicker>
                    </LabelForm>
                    <LabelForm
                        label="구분"
                        labelCol={2}
                        required
                    >
                        <Select
                            name="noticeType"
                            options={noticeType.map((item: any) => item.codeLabel)}
                            onChange={(label: string, value: any) => {
                                setForm(label, noticeType.find((item: any) => item.codeLabel === value)?.codeId)
                            }}
                        />
                    </LabelForm>
                    <LabelForm
                        label="글 고정"
                        labelCol={2}
                        required
                    >
                        <FlexDiv height="50px">
                            <Radio.Group
                                defaultValue={"해제"}
                                onChange={e => {
                                    setForm("pinStatus", e === "고정" ? 1 : 0)
                                }}
                                options={["고정", "해제"]}
                            ></Radio.Group>
                        </FlexDiv>
                    </LabelForm>
                    <LabelForm
                        label="게시 상태"
                        labelCol={2}
                        required
                    >
                        <FlexDiv height="50px">
                            <Radio.Group
                                defaultValue={"게시"}
                                onChange={e => {
                                    setForm("displayFg", e === "게시" ? 1 : 0)
                                }}
                                options={["게시", "숨김"]}
                            ></Radio.Group>
                        </FlexDiv>
                    </LabelForm>
                    <LabelForm
                        label={"첨부파일"}
                        labelCol={2}
                    >
                        <FlexColDiv>
                            <Text
                                as={"p"}
                                bold
                            >
                                첨부파일 추가 - 파일을 드래그 혹은 클릭해서 첨부 해 주세요
                            </Text>
                            <BbsDragAndDropFile
                                key={"bbsDragAndDropFile"}
                                files={uploadFiles}
                                setFiles={setUploadFiles}
                                defaultData={undefined}
                            />
                        </FlexColDiv>
                    </LabelForm>
                </FlexColDiv>
                <BbsEditor
                    ref={editorRef}
                    placeholder="공지사항 내용을 입력해주세요."
                    onChange={() => setContents(editorRef?.current?.getInstance().getHTML())}
                />
            </FlexColDiv>
        </>
    )
}

export default InsertNotice

const defaultNoticeData = {
    title: "",
    contents: "",
    postingDate: "",
    noticeType: null,
    pinStatus: 0,
    displayFg: 1,
    regManager: 0,
}

const defaultCodeData = [
    {
        codeId: 0,
        parentCode: "",
        code: "",
        codeLabel: "",
        numberValue: null,
        stringValue: null,
    },
]
