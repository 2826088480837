import { useState, useEffect } from "react"

import CustomDatePicker from "@components/widgets/customDatePicker"
import ReactDatePicker from "react-datepicker"
import { Input, LabelForm } from "p-ui"
import { DataPickerInput, FlexColDiv } from "@styles"

import { ko } from "date-fns/locale"
import utils from "@utils"
import { setHours, setMinutes } from "date-fns"
import { areDatesEqual, extractTimeFromDateString } from "src/utils/date"
const UnusableModal = (props: any) => {
    const { setResetData, rentalFleetData } = props
    const { startDt } = rentalFleetData || {}
    const currentDate = new Date()
    const [selectedDt, setSelectedDt] = useState(currentDate)
    const [isSetMinTime, setIsSetMinTime] = useState(false)

    useEffect(() => {
        setSelectedDt(new Date(startDt))
        setResetData((prev: any) => ({ ...prev, startDt: startDt }))
        setIsSetMinTime(areDatesEqual(currentDate, new Date(startDt)))
    }, [])
    return (
        <FlexColDiv gap="16px">
            <LabelForm label="사유">
                <Input
                    name="leaseReason"
                    placeholder={"사유를 입력하세요."}
                    onChange={(e: any) => {
                        setResetData((prev: any) => ({ ...prev, [e.name]: e.value }))
                    }}
                />
            </LabelForm>
            <LabelForm label="시작담당자">
                <Input
                    name="startMgrNm"
                    placeholder={"시작담당자 이름을 입력하세요."}
                    onChange={(e: any) => {
                        setResetData((prev: any) => ({ ...prev, [e.name]: e.value }))
                    }}
                />
            </LabelForm>
            <LabelForm label="시작일">
                <CustomDatePicker
                    width={"100%"}
                    height={"50px"}
                >
                    <ReactDatePicker
                        selected={selectedDt}
                        onChange={(date: Date) => {
                            setSelectedDt(date)
                            setResetData((prev: any) => ({
                                ...prev,
                                startDt: date.format(utils.FORMAT_SHORT_DATETIME),
                            }))
                            setIsSetMinTime(areDatesEqual(date, new Date(startDt)))
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd HH:mm"
                        showTimeSelect
                        timeIntervals={5}
                        minDate={new Date(startDt)}
                        minTime={setHours(
                            setMinutes(new Date(), isSetMinTime ? extractTimeFromDateString(startDt).minutes : 0),
                            isSetMinTime ? extractTimeFromDateString(startDt).hours : 0
                        )}
                        maxTime={setHours(setMinutes(new Date(), 30), 23)}
                        customInput={<DataPickerInput height={"50px"} />}
                        onKeyDown={e => {
                            e.preventDefault()
                        }}
                    />
                </CustomDatePicker>
            </LabelForm>
        </FlexColDiv>
    )
}

export default UnusableModal
