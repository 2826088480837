import { LabelForm, Select } from "p-ui"
import { RentalFleetLocation } from "@common/constatns"

const LocationEditModal = (props: any) => {
    const { rentalFleetBikeData, setEditForm } = props
    const { bikeLocation } = rentalFleetBikeData || {}
    return (
        <LabelForm label="위치">
            <Select
                name="bikeLocation"
                options={RentalFleetLocation.map(item => item.label)}
                defaultValue={RentalFleetLocation.find(item => item.value === bikeLocation)?.label}
                onChange={(label: string, value: any) => {
                    setEditForm(label, RentalFleetLocation.find(item => value === item.label)?.value)
                }}
            />
        </LabelForm>
    )
}

export default LocationEditModal
