import React from "react"

const Loader = () => {
    return (
        <div className="loader-wrapper">
            <span className="loader" />
        </div>
    )
}

export default Loader
