import React, { useState, useEffect } from "react"

import { Button, Input, Modal, PageHeader, Select, TextArea, LabelForm, Row, Col } from "p-ui"
import { PushContent, PushModalContent, PushUi } from "./styles"
import PushTemplateModal from "../Components/PushTemplateModal"
import { PushModalMessage } from "@common/constatns"
import { CommonDiv, FlexColDiv, FlexDiv, Text } from "@styles"

import apis from "@apis"

import useAlert from "@context/alert/useAlert"
import moment from "moment/moment"

import { Type } from "p-ui"
import { PushTemplateType } from "@type/push"
import { onChangeParam } from "@type/common"

const PushPage = () => {
    const [date, setDate] = useState(() => moment().format("a hh:mm"))
    const [sendType, setSendType] = useState<string>("noti")
    const [title, setTitle] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [pushTemplateOptionList, setPushTemplateOptionList] = useState<Type.OptionType[]>([])
    const [pushTemplateList, setPushTemplateList] = useState<PushTemplateType[]>([])
    const [selectedPushTemplate, setSelectedPushTemplate] = useState<number>(-1)

    const { openAlert } = useAlert()

    const tick = () => {
        setDate(moment().format("a hh:mm"))
    }

    function clickEvent() {
        getPushTemplate()
    }

    async function sendPushMsg() {
        if (!title) return openAlert({ body: "제목을 작성해 주세요", type: "warning" })
        if (!content) return openAlert({ body: "내용을 작성해 주세요", type: "warning" })

        const params = {
            alarmTitle: title,
            alarmMsg: content,
            sendType: sendType,
        }

        await apis.Push.sendPushMsg(params).then(res => {
            if (res.status == "SUCCESS") {
                openAlert({ body: "PUSH 발송 성공", type: "primary" })
                getPushTemplate()
            } else {
                openAlert({ body: "PUSH 발송 실패", type: "warning" })
            }
        })
    }

    async function getPushTemplate() {
        setPushTemplateOptionList([])
        await apis.Push.getPushTemplate().then(res => {
            if (res.status == "SUCCESS") {
                let templateOptionList: Type.OptionType[] = []
                res.resultData.unshift({
                    templateId: -1,
                    type: "noti",
                    title: "",
                    content: "",
                })
                setPushTemplateList(res.resultData)
                templateOptionList = res.resultData.map(item => {
                    if (item.templateId == -1) return { label: "선택 되지 않음", value: item.templateId }
                    else return { label: item.title, value: item.templateId }
                })

                setPushTemplateOptionList(templateOptionList)
                setTemplate(-1)
            }
        })
    }

    function setTemplate(value: number) {
        const pushTemplate = pushTemplateList?.find(item => {
            return item.templateId == value
        })

        if (pushTemplate) {
            setSendType(pushTemplate.type)
            setTitle(pushTemplate.title)
            setContent(pushTemplate.content)
        }
    }

    useEffect(() => {
        const timeId = setInterval(() => tick(), 1000)

        getPushTemplate()
        return () => {
            clearInterval(timeId)
        }
    }, [])

    useEffect(() => {
        if (content.length > 300) {
            alert("300자 이상 입력할 수 없습니다.")
            setContent(content.substring(0, 300))
        }
        const splitContent = content.split("\n")
        if (splitContent.length > 25) {
            alert("25줄 이상 입력할 수 없습니다.")
            setContent(splitContent.slice(0, 25).join("\n"))
        }
    }, [content])

    return (
        <div>
            <Modal
                title="템플릿 관리"
                open={openModal}
                onClose={() => setOpenModal(false)}
                scrollable
                type={"alert"}
                width={700}
            >
                <PushTemplateModal
                    pushTemplateOptionList={pushTemplateOptionList}
                    pushTemplateList={pushTemplateList}
                    clickEvent={() => clickEvent()}
                />
            </Modal>

            <div>
                <Modal
                    open={isModalOpen}
                    title="PUSH 전송"
                    type={"confirm"}
                    onConfirm={sendPushMsg}
                    onClose={() => setIsModalOpen(false)}
                >
                    <PushModalContent>
                        {sendType == "noti" ? PushModalMessage.noti : PushModalMessage.ad}
                    </PushModalContent>
                </Modal>

                <PageHeader
                    title="고객관리"
                    subTitle="고객관리 > Push 알림"
                />
                <Row>
                    <Col sm={6}>
                        <FlexColDiv gap="10px">
                            <CommonDiv width="100%">
                                <LabelForm
                                    label={"템플릿 선택"}
                                    textAlign="center"
                                >
                                    {pushTemplateOptionList.length > 0 && (
                                        <Select
                                            options={pushTemplateOptionList}
                                            onChange={(label: string, value: any) => setTemplate(value)}
                                            defaultValue={selectedPushTemplate}
                                        />
                                    )}
                                </LabelForm>
                            </CommonDiv>
                            <CommonDiv width="100%">
                                <LabelForm
                                    label={"공지 유형"}
                                    textAlign="center"
                                >
                                    <Select
                                        options={[
                                            { label: "공지 (앱 설치된 전체 고객)", value: "noti" },
                                            { label: "광고 (Push 알림 동의된 고객)", value: "ad" },
                                        ]}
                                        onChange={(label: string, value: any) => {
                                            setSendType(value)
                                        }}
                                        defaultValue={sendType}
                                    />
                                </LabelForm>
                            </CommonDiv>
                            <CommonDiv width="100%">
                                <LabelForm
                                    label={"제목"}
                                    textAlign="center"
                                >
                                    <Input
                                        value={title}
                                        name="title"
                                        onChange={(e: onChangeParam) => {
                                            setTitle(e.value.toString())
                                        }}
                                    ></Input>
                                </LabelForm>
                            </CommonDiv>
                            <CommonDiv width="100%">
                                <LabelForm
                                    label={"내용"}
                                    textAlign="center"
                                >
                                    <TextArea
                                        value={content}
                                        name="content"
                                        height={500}
                                        onChange={(e: any) => {
                                            setContent(e.target.value)
                                        }}
                                    ></TextArea>
                                </LabelForm>
                            </CommonDiv>
                            <Row>
                                <Col sm={2}>
                                    <div>{content.length + "/300"}</div>
                                </Col>
                                <Col sm={10}>
                                    <FlexDiv
                                        justifyContent="flex-end"
                                        gap="5px"
                                    >
                                        <Button
                                            type="secondary"
                                            onClick={() => setOpenModal(true)}
                                        >
                                            템플릿 추가
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            발송
                                        </Button>
                                    </FlexDiv>
                                </Col>
                            </Row>
                        </FlexColDiv>
                    </Col>
                    <Col sm={6}>
                        <FlexDiv justifyContent="center">
                            <PushUi
                                top={70}
                                className="tooltip"
                            >
                                <span className="tooltiptext tooltip-right">
                                    Push 알림 <br />
                                    펼치기 전 상태
                                </span>
                                <FlexColDiv gap="10px">
                                    <Row>
                                        <Col sm={1}>
                                            <img
                                                style={{ width: "18px" }}
                                                src={`${process.env.PUBLIC_URL}/images/zentropyLogo.png`}
                                            />
                                        </Col>
                                        <Col sm={10}>
                                            <FlexDiv
                                                gap="4px"
                                                alignItems="center"
                                            >
                                                <b>{title ? title : "제목을 입력해주세요"}</b>
                                                <Text fontSize="0.7rem">{date}</Text>
                                            </FlexDiv>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <PushContent isNowrap={true}>
                                                {content ? content : "내용을 입력해 주세요."}
                                            </PushContent>
                                        </Col>
                                    </Row>
                                </FlexColDiv>
                            </PushUi>
                            <PushUi
                                top={150}
                                className="tooltip"
                            >
                                <span className="tooltiptext tooltip-right">
                                    Push 알림
                                    <br />
                                    펼친 후 상태
                                </span>
                                <FlexColDiv gap="10px">
                                    <Row>
                                        <Col sm={1}>
                                            <img
                                                style={{ width: "18px" }}
                                                src={`${process.env.PUBLIC_URL}/images/zentropyLogo.png`}
                                            />
                                        </Col>
                                        <Col sm={10}>
                                            <FlexDiv
                                                gap="4px"
                                                alignItems="center"
                                            >
                                                <div>젠트로피</div>
                                                <Text fontSize="0.7rem">{date}</Text>
                                            </FlexDiv>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <b>{title ? title : "제목을 입력해주세요"}</b>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <PushContent isNowrap={false}>
                                                {content ? content : "내용을 입력해 주세요."}
                                            </PushContent>
                                        </Col>
                                    </Row>
                                </FlexColDiv>
                            </PushUi>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/phone.png`}
                                alt=""
                            />
                        </FlexDiv>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default PushPage
