import { FlexColDiv, Text } from "@styles"
import { Tag } from "p-ui"

import { RentalFleetStatus } from "@common/constatns"

const RentalFleetBikeHeader = (props: any) => {
    const { rentalFleetBikeData, bikeNo } = props
    const { leaseType, bikeSerial } = rentalFleetBikeData || {}

    const setTagType = (type: number) => {
        switch (type) {
            case 2:
            case 3:
                return "success"
            case 4:
                return "warning"
            case 0:
                return "danger"
            default:
                return "primary"
        }
    }
    return (
        <FlexColDiv
            alignItems="flex-start"
            gap="8px"
        >
            {rentalFleetBikeData && (
                <>
                    <Tag
                        value={RentalFleetStatus.find((item: any) => item.value === leaseType)?.label}
                        type={setTagType(leaseType)}
                        size="small"
                    />
                    <Text
                        bold
                        fontSize="18px"
                    >
                        {bikeNo}
                    </Text>
                    <Text
                        fontSize="14px"
                        color={"var(--black)"}
                    >
                        {bikeSerial}
                    </Text>
                </>
            )}
        </FlexColDiv>
    )
}

export default RentalFleetBikeHeader
