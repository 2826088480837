import styled from "styled-components"

function Divider() {
    return <$Divider />
}

export default Divider

const $Divider = styled.div`
    display: flex;
    clear: both;
    width: 100%;
    margin: 14px 0;
    border-top: 1px solid #ddd;
`
