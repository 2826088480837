import { useEffect, useRef, useState } from "react"

import {
    Button,
    Icon,
    Input,
    LabelForm,
    Modal,
    PageHeader,
    Radio,
    Select,
    TableForm,
    TableFormLabel,
    TableFormValue,
} from "p-ui"
import notice from "src/apis/notice"
import { NoticeInfo } from "@type/bbsType"
import { useLocation, useNavigate, useParams } from "react-router"
import { Editor, Viewer } from "@toast-ui/react-editor"
import { BbsViewerContainer, CommonDiv, DataPickerInput, FlexColDiv, FlexDiv, Text } from "@styles"
import CustomDatePicker from "@components/widgets/customDatePicker"
import ReactDatePicker from "react-datepicker"
import { ko } from "date-fns/locale"
import utils from "@utils"
import useAlert from "@context/alert/useAlert"
import { noticeValidate } from "@common/util"
import BbsEditor from "@pages/Bbs/Components/BbsEditor"
import BbsDragAndDropFile from "@pages/Bbs/Components/BbsDragAndDrop"
import file from "src/apis/file"
import { BbsClipDiv } from "@pages/Bbs/styles"
import { noticeFolderName, noticeLinkInfo } from "@pages/Bbs/constants"
import BbsViewer from "@pages/Bbs/Components/BbsViewer"
import { createVideoContainer } from "@pages/Bbs/Components/createVideoContainer"

const NoticeDetail = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { id } = useParams()

    const currentDate = new Date()

    const { openAlert } = useAlert()

    const [noticeData, setNoticeData] = useState<NoticeInfo>()
    const [noticeType, setNoticeType] = useState([] as any)
    const [editData, setEditData] = useState<NoticeInfo>()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [postingDt, setPostingDt] = useState<Date>(currentDate)
    const [contents, setContents] = useState<string | any>("")
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [uploadFiles, setUploadFiles] = useState([])
    const [attatchFiles, setAttatchFiles] = useState([])

    const editorRef = useRef<Editor>(null)

    /**
     * 공지사항 단건 조회
     * @param id noticeId
     */
    const getNotice = (id: string | number) => {
        notice
            .getNotice(id)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setNoticeData(res.resultData.notice)
                    setEditData(res.resultData.notice)
                    setPostingDt(new Date(res.resultData.notice.postingDate))
                    setContents(res.resultData.notice.contents)
                    setAttatchFiles(res.resultData.attach)
                }
            })
            .catch(err => console.log(err))
    }

    /**
     * 공지사항 카테고리(구분) 조회
     */
    const getType = () => {
        notice.getType().then(res => {
            if (res.status === "SUCCESS") {
                setNoticeType(res.resultData)
            }
        })
    }

    const setEditForm = (name: any, value: any) => {
        setEditData((item: any) => ({ ...item, [name]: value }))
    }

    /**
     * 공지사항 수정
     * @param id noticeId
     * @param body NoticeList
     */
    const updateNotice = (id: number, body: NoticeInfo | undefined) => {
        if (body !== undefined) {
            notice
                .updateNotice(id, body)
                .then(res => {
                    if (res.status === "SUCCESS") {
                        uploadFiles.length > 0 ? uploadFile(id) : editSuccessAction(id)
                    } else {
                        openAlert({ title: "실패", body: "공지사항 정보 수정 실패", type: "warning" })
                    }
                })
                .catch(e => console.log(e))
        }
    }

    /**
     * 공지사항 삭제
     * @param id noticeId
     */
    const deleteNotice = (id: number) => {
        notice
            .deleteNotice(id)
            .then(res => {
                if (res.status === "SUCCESS") {
                    openAlert({ title: "삭제", body: "삭제 완료되었습니다.", type: "primary" })
                    navigate("/bbs/notice")
                } else {
                    openAlert({ title: "실패", body: "삭제 실패", type: "warning" })
                }
            })
            .catch(e => console.log(e))
    }

    /**
     * 파일 업로드
     * @param fileId fileId
     */
    const uploadFile = (fileId: any) => {
        const formData = new FormData()
        uploadFiles.map((item: any) => {
            formData.append("formData", item.object)
        })
        formData.append("linkKey", fileId)
        formData.append("linkInfo", noticeLinkInfo)

        file.uploadFileList(noticeFolderName, formData)
            .then(res => {
                if (res.status === "SUCCESS") {
                    if (id) {
                        editSuccessAction(id)
                    }
                } else {
                    openAlert({ title: "실패", body: "공지사항 첨부파일 수정 실패", type: "warning" })
                }
            })
            .catch(err => console.log(err))
    }

    const editSuccessAction = (id: number | string) => {
        setEditMode(false)
        getNotice(id)
        openAlert({ title: "성공", body: "공지사항 수정이 완료되었습니다.", type: "primary" })
    }

    /**
     * 첨부파일 단건 삭제
     * @param id noticeId
     */
    const deleteAttachFiles = (id: number) => {
        file.deleteFile(noticeFolderName, id)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setAttatchFiles(attatchFiles.filter((file: any) => file.fileId !== id))
                    openAlert({ title: "삭제", body: "첨부파일 삭제되었습니다.", type: "primary" })
                } else {
                    openAlert({ title: "실패", body: "삭제 실패", type: "warning" })
                }
            })
            .catch(e => console.log(e))
    }

    const checkValidate = () => {
        const { fieldList, notValidMessage } = noticeValidate()

        if (editData !== undefined) {
            const data = editData
            const isNotValidField = fieldList.find(field => !data[field as keyof NoticeInfo])

            if (isNotValidField) {
                return openAlert({ title: "실패", body: notValidMessage[isNotValidField].toString(), type: "warning" })
            }
            if (data.contents === "<p><br></p>") {
                return openAlert({ title: "실패", body: "공지사항 내용을 입력해주세요.", type: "warning" })
            }
            updateNotice(location.state.id, editData)
        }
    }

    const downloadFile = (url: string) => {
        const link = document.createElement("a")
        link.href = url
        link.download = url.substring(url.lastIndexOf("/") + 1)
        link.target = "_blank"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    useEffect(() => {
        if (id) {
            getNotice(id)
        }
        getType()
    }, [])

    useEffect(() => {
        setEditForm("contents", createVideoContainer(contents))
    }, [contents])

    return (
        <div>
            <FlexDiv alignItems="center">
                <PageHeader
                    title={"공지사항 관리"}
                    subTitle="공지사항 상세 내역"
                />
                <FlexDiv
                    gap="16px"
                    justifyContent="flex-end"
                    height="40px"
                >
                    {editMode ? (
                        <>
                            <Button
                                type="secondary"
                                onClick={() => {
                                    setEditMode(false)
                                    setEditData(noticeData)
                                    if (noticeData) {
                                        setPostingDt(new Date(noticeData.postingDate))
                                        setContents(noticeData.contents)
                                    }
                                }}
                            >
                                취소하기
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => checkValidate()}
                            >
                                저장하기
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                type="secondary"
                                onClick={() => setEditMode(true)}
                            >
                                수정하기
                            </Button>
                            <Button
                                type="danger"
                                onClick={() => {
                                    setOpenModal(true)
                                }}
                            >
                                삭제하기
                            </Button>
                        </>
                    )}
                </FlexDiv>
            </FlexDiv>

            {editMode ? (
                <FlexColDiv gap="36px">
                    <FlexColDiv
                        width="1200px"
                        gap="8px"
                    >
                        <LabelForm
                            label={"제목"}
                            labelCol={2}
                            required
                        >
                            <Input
                                name="title"
                                defaultValue={noticeData?.title}
                                onChange={e => {
                                    setEditForm(e.name, e.value)
                                }}
                            />
                        </LabelForm>
                        <LabelForm
                            label={"작성일"}
                            labelCol={2}
                            required
                        >
                            <CustomDatePicker
                                width="100%"
                                height="50px"
                            >
                                <ReactDatePicker
                                    selected={postingDt}
                                    onChange={(date: Date) => {
                                        setPostingDt(date)
                                        setEditForm("postingDate", date.format(utils.FORMAT_DATETIME))
                                    }}
                                    locale={ko}
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    showTimeInput
                                    customInput={<DataPickerInput height={"50px"} />}
                                    withPortal
                                />
                            </CustomDatePicker>
                        </LabelForm>
                        <LabelForm
                            label="구분"
                            labelCol={2}
                            required
                        >
                            <Select
                                name="noticeType"
                                defaultValue={noticeData?.codeLabel}
                                options={noticeType.map((item: any) => item.codeLabel)}
                                onChange={(label: string, value: any) => {
                                    setEditForm(label, noticeType.find((item: any) => item.codeLabel === value).codeId)
                                }}
                            />
                        </LabelForm>
                        <LabelForm
                            label="글 고정"
                            labelCol={2}
                            required
                        >
                            <FlexDiv height="50px">
                                <Radio.Group
                                    defaultValue={noticeData?.pinStatus === 1 ? "고정" : "해제"}
                                    onChange={e => {
                                        setEditForm("pinStatus", e === "고정" ? 1 : 0)
                                    }}
                                    options={["고정", "해제"]}
                                ></Radio.Group>
                            </FlexDiv>
                        </LabelForm>
                        <LabelForm
                            label="게시 상태"
                            labelCol={2}
                            required
                        >
                            <FlexDiv height="50px">
                                <Radio.Group
                                    defaultValue={noticeData?.displayFg === 1 ? "게시" : "숨김"}
                                    onChange={e => {
                                        setEditForm("displayFg", e === "게시" ? 1 : 0)
                                    }}
                                    options={["게시", "숨김"]}
                                ></Radio.Group>
                            </FlexDiv>
                        </LabelForm>
                        <LabelForm
                            label={"첨부파일"}
                            labelCol={2}
                        >
                            <FlexColDiv>
                                <Text
                                    as={"p"}
                                    bold
                                >
                                    첨부파일 추가 - 파일을 드래그 혹은 클릭해서 첨부 해 주세요
                                </Text>
                                <BbsDragAndDropFile
                                    key={"bbsDragAndDropFile"}
                                    files={uploadFiles}
                                    setFiles={setUploadFiles}
                                    defaultData={attatchFiles}
                                    deleteAttachFiles={deleteAttachFiles}
                                />
                            </FlexColDiv>
                        </LabelForm>
                    </FlexColDiv>
                    <BbsEditor
                        ref={editorRef}
                        initialValue={noticeData?.contents}
                        placeholder="공지사항 내용을 입력해주세요."
                        onChange={() => setContents(editorRef?.current?.getInstance().getHTML())}
                    />
                </FlexColDiv>
            ) : (
                <FlexColDiv gap="36px">
                    <TableForm>
                        <TableFormLabel md={3}>제목</TableFormLabel>
                        <TableFormValue md={9}>{noticeData?.title}</TableFormValue>
                        <TableFormLabel md={3}>작성일</TableFormLabel>
                        <TableFormValue md={9}>{noticeData?.postingDate}</TableFormValue>
                        <TableFormLabel md={3}>구분</TableFormLabel>
                        <TableFormValue md={9}>{noticeData?.codeLabel}</TableFormValue>
                        <TableFormLabel md={2}>고정</TableFormLabel>
                        <TableFormValue md={4}>{noticeData?.pinStatus === 1 ? "고정" : "-"}</TableFormValue>
                        <TableFormLabel md={2}>게시 상태</TableFormLabel>
                        <TableFormValue md={4}>{noticeData?.displayFg === 1 ? "게시중" : "숨김"}</TableFormValue>
                        <TableFormLabel md={3}>첨부파일</TableFormLabel>
                        <TableFormValue md={8}>
                            {attatchFiles.length > 0
                                ? attatchFiles.map((item: any, idx: number) => {
                                      return (
                                          <BbsClipDiv
                                              key={idx}
                                              onClick={() => downloadFile(item.s3Url)}
                                          >
                                              {item?.fileName}
                                              <Icon.Download width={16} />
                                          </BbsClipDiv>
                                      )
                                  })
                                : "첨부된 파일이 없습니다."}
                        </TableFormValue>
                    </TableForm>
                    <FlexDiv gap="8px">
                        {noticeData && (
                            <CommonDiv margin="-24px 0">
                                <Text
                                    margin="-24px 0"
                                    fontSize="12px"
                                    color="grey"
                                >
                                    pc 버전 미리보기 (1200px) - 아무것도 안보일 경우 새로고침해주세요.
                                </Text>
                                <BbsViewerContainer $width="1200px">
                                    <BbsViewer contents={contents} />
                                </BbsViewerContainer>
                            </CommonDiv>
                        )}
                        {noticeData && (
                            <CommonDiv margin="-24px 0">
                                <Text
                                    fontSize="12px"
                                    color="grey"
                                >
                                    mobile 버전 미리보기 (393px - iphone 14 pro)
                                </Text>
                                <BbsViewerContainer $width="393px">
                                    <BbsViewer contents={contents} />
                                </BbsViewerContainer>
                            </CommonDiv>
                        )}
                    </FlexDiv>
                </FlexColDiv>
            )}
            <Modal
                type="confirm"
                title="공지사항 삭제"
                open={openModal}
                onConfirm={() => {
                    setOpenModal(false)
                    deleteNotice(location.state.id)
                }}
                onClose={() => {
                    setOpenModal(false)
                }}
            >
                정말로 삭제하시겠습니까?
            </Modal>
        </div>
    )
}

export default NoticeDetail
