import { ConfirmModalType } from "@pages/Assets/types"
import { ObjectListType, ObjectNullType, ObjectType } from "@type/common"
import { InsuranceFormType } from "@type/formType"
import { atom } from "recoil"

export const zentalDataState = atom({
    key: "zentalDataState",
    default: {
        userState: [],
        serviceModelState: [],
        userPaymentHistoryState: [],
    },
})

interface MotalTypes {
    serviceHistoryState: boolean
    paymentHistoryState: boolean
    connectModalState: boolean
    insuranceModalState: boolean
    assignmentModalState: boolean
    bikeChangeModalState: boolean
    cancelContractModalState: { isOpen: false; type: "" }
    confirmModalState: ConfirmModalType
    cardMoalState: boolean
    extendMoalState: boolean
}

export const zentalModalState = atom<MotalTypes>({
    key: "zentalModalState",
    default: {
        serviceHistoryState: false,
        paymentHistoryState: false,
        connectModalState: false,
        insuranceModalState: false,
        assignmentModalState: false,
        bikeChangeModalState: false,
        cancelContractModalState: { isOpen: false, type: "" },
        confirmModalState: { isOpen: false, title: "confirm", contents: `<div>test</div>`, cb: () => {} },
        cardMoalState: false,
        extendMoalState: false,
    },
})

/**젠탈 파람 다시 정리 */

export const ZentalUserFileState = atom({
    key: "ZentalUserFileState",
    default: [],
})

export const ZentalUserParamState = atom<znetalUserParamInterface>({
    key: "ZentalUserParamState",
    default: {
        userName: "",
        userPhone: "",
        userEmail: null,
        comment: null,
        addressDetail: "",
        addressRegion: "",
        addressRoad: "",
        birthDt: "",
        gender: "M",
        licenseNo: "",
        userType: 0,
        partnerAccount: null,
        zipCode: null,
    },
})

export const insuranceFormState = atom<InsuranceFormType>({
    key: "insuranceFormState",
    default: {
        insuranceId: 0,
        ageOver: "만30세",
        insuranceCompany: "현대",
        insuranceName: "재상전자주식회사",
        startDt: "",
        endDt: "",
        comment: "",
        bikeId: "0",
    },
})

export const connectParamState = atom<ObjectType>({
    key: "connectParamState",
    default: {
        modelId: 0,
        codeId: 431,
        startDt: "",
        status: 1,
        useFg: 1,
        discount: 0,
    },
})

export const serviceUserConnectParamState = atom<ObjectType>({
    key: "serviceUserConnectParamState",
    default: {
        bikeId: 0,
        startDt: "",
        status: "",
        useFg: 1,
        userId: 0,
        deposit: "0",
    },
})

export const extendState = atom<ObjectListType>({
    key: "extendState",
    default: {
        zentalId: -1,
        modelId: -1,
        startDt: "",
        discount: -1,
        deposit: "",
    },
})

export interface znetalUserParamInterface extends ObjectNullType {
    userName: string
    userPhone: string
    userEmail: string | null
    comment: string | null
    addressDetail: string
    addressRegion: string
    addressRoad: string
    birthDt: string
    gender: string
    licenseNo: string
    userType: number
    partnerAccount: string | null
    zipCode: string | null
}
