import { faqValidate } from "@common/util"
import useAlert from "@context/alert/useAlert"
import BbsDragAndDropFile from "@pages/Bbs/Components/BbsDragAndDrop"
import BbsEditor from "@pages/Bbs/Components/BbsEditor"
import BbsViewer from "@pages/Bbs/Components/BbsViewer"
import { createVideoContainer } from "@pages/Bbs/Components/createVideoContainer"
import { faqFolderName, faqLinkInfo } from "@pages/Bbs/constants"
import { BbsClipDiv, BbsFlexDiv } from "@pages/Bbs/styles"
import { BbsViewerContainer, CommonDiv, FlexColDiv, FlexDiv, Text } from "@styles"
import { Editor, Viewer } from "@toast-ui/react-editor"
import { FaqInfo } from "@type/bbsType"
import {
    Button,
    Icon,
    Input,
    LabelForm,
    Modal,
    PageHeader,
    Radio,
    Select,
    TableForm,
    TableFormLabel,
    TableFormValue,
} from "p-ui"
import { useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router"
import faq from "src/apis/faq"
import file from "src/apis/file"

const FaqDetail = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { id } = useParams()

    const { openAlert } = useAlert()

    const [faqData, setFaqData] = useState<FaqInfo>()
    const [faqType, setFaqType] = useState([] as any)
    const [editData, setEditData] = useState<FaqInfo>()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [contents, setContents] = useState<string | any>("")
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [uploadFiles, setUploadFiles] = useState([])
    const [attatchFiles, setAttatchFiles] = useState([])

    const editorRef = useRef<Editor>(null)

    /**
     * FAQ 단건 조회
     * @param id faqId
     */
    const getFaq = (id: number | string) => {
        faq.getFaqDetail(id)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setFaqData(res.resultData.faq)
                    setEditData(res.resultData.faq)
                    setContents(res.resultData.faq.contents)
                    setAttatchFiles(res.resultData.attach)
                }
            })
            .catch(err => console.log(err))
    }

    /**
     * FAQ 카테고리(구분) 조회
     */
    const getType = () => {
        faq.getFaqType().then(res => {
            if (res.status === "SUCCESS") {
                setFaqType(res.resultData)
            }
        })
    }

    const setEditForm = (name: any, value: any) => {
        setEditData((item: any) => ({ ...item, [name]: value }))
    }

    /**
     * FAQ 수정
     * @param id faqId
     * @param body FaqList
     */
    const updateFaq = (body: FaqInfo | undefined) => {
        if (body !== undefined) {
            faq.updateFaq(body).then(res => {
                if (res.status === "SUCCESS") {
                    if (id) {
                        uploadFiles.length > 0 ? uploadFile(id) : editSuccessAction(id)
                    }
                } else {
                    openAlert({ title: "실패", body: "FAQ 수정 실패", type: "warning" })
                }
            })
        }
    }

    /**
     * 파일 업로드
     * @param fileId fileId
     */
    const uploadFile = (fileId: any) => {
        const formData = new FormData()
        uploadFiles.map((item: any) => {
            formData.append("formData", item.object)
        })
        formData.append("linkKey", fileId)
        formData.append("linkInfo", faqLinkInfo)

        file.uploadFileList(faqFolderName, formData)
            .then(res => {
                if (res.status === "SUCCESS") {
                    if (id) {
                        editSuccessAction(id)
                    }
                } else {
                    openAlert({ title: "실패", body: "Faq 첨부파일 수정 실패", type: "warning" })
                }
            })
            .catch(err => console.log(err))
    }

    const editSuccessAction = (id: number | string) => {
        setEditMode(false)
        getFaq(id)
        openAlert({ title: "성공", body: "FAQ 수정이 완료되었습니다.", type: "primary" })
    }

    /**
     * Faq 삭제
     * @param id faqId
     */
    const deleteFaq = (id: number) => {
        faq.deleteFaq(id)
            .then(res => {
                if (res.status === "SUCCESS") {
                    openAlert({ title: "삭제", body: "삭제 완료되었습니다.", type: "primary" })
                    navigate("/bbs/faq")
                } else {
                    openAlert({ title: "실패", body: "삭제 실패", type: "warning" })
                }
            })
            .catch(e => console.log(e))
    }

    /**
     * 첨부파일 단건 삭제
     * @param id faqId
     */
    const deleteAttachFiles = (id: number) => {
        file.deleteFile(faqFolderName, id)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setAttatchFiles(attatchFiles.filter((file: any) => file.fileId !== id))
                    openAlert({ title: "삭제", body: "첨부파일 삭제되었습니다.", type: "primary" })
                } else {
                    openAlert({ title: "실패", body: "삭제 실패", type: "warning" })
                }
            })
            .catch(e => console.log(e))
    }

    const checkValidate = () => {
        const { fieldList, notValidMessage } = faqValidate()

        if (editData !== undefined) {
            const data = editData
            const isNotValidField = fieldList.find(field => !data[field as keyof FaqInfo])

            if (isNotValidField) {
                return openAlert({ title: "실패", body: notValidMessage[isNotValidField].toString(), type: "warning" })
            }
            if (data.contents === "<p><br></p>") {
                return openAlert({ title: "실패", body: "faq 내용을 입력해주세요.", type: "warning" })
            }
            updateFaq(data)
        }
    }

    const displayStatus = useMemo(() => {
        return faqData?.displayFg === 1 ? "게시" : "숨김"
    }, [faqData?.displayFg])

    const downloadFile = (url: string) => {
        const link = document.createElement("a")
        link.href = url
        link.download = url.substring(url.lastIndexOf("/") + 1)
        link.target = "_blank"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    useEffect(() => {
        if (id) {
            getFaq(id)
        }
        getType()
    }, [])

    useEffect(() => {
        setEditForm("contents", createVideoContainer(contents))
    }, [contents])

    return (
        <div>
            <BbsFlexDiv alignItems="center">
                <PageHeader
                    title={"FAQ 관리"}
                    subTitle="FAQ 상세 내역"
                />
                <FlexDiv
                    gap="16px"
                    justifyContent="flex-end"
                    height="40px"
                >
                    {editMode ? (
                        <>
                            <Button
                                type="secondary"
                                onClick={() => {
                                    setEditMode(false)
                                    setEditData(faqData)
                                    setContents(faqData?.contents)
                                }}
                            >
                                취소하기
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => checkValidate()}
                            >
                                저장하기
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                type="secondary"
                                onClick={() => setEditMode(true)}
                            >
                                수정하기
                            </Button>
                            <Button
                                type="danger"
                                onClick={() => {
                                    setOpenModal(true)
                                }}
                            >
                                삭제하기
                            </Button>
                        </>
                    )}
                </FlexDiv>
            </BbsFlexDiv>

            {editMode ? (
                <FlexColDiv gap="36px">
                    <FlexColDiv
                        width="1200px"
                        gap="8px"
                    >
                        <LabelForm
                            label={"제목"}
                            labelCol={2}
                            required
                        >
                            <Input
                                name="title"
                                defaultValue={faqData?.title}
                                onChange={e => {
                                    setEditForm(e.name, e.value)
                                }}
                            />
                        </LabelForm>

                        <LabelForm
                            label="구분"
                            labelCol={2}
                            required
                        >
                            <Select
                                name="faqType"
                                defaultValue={faqData?.codeLabel}
                                options={faqType.map((item: any) => item.codeLabel)}
                                onChange={(label: string, value: any) => {
                                    setEditForm(label, faqType.find((item: any) => item.codeLabel === value).codeId)
                                }}
                            />
                        </LabelForm>

                        <LabelForm
                            label="게시 상태"
                            labelCol={2}
                            required
                        >
                            <FlexDiv height="50px">
                                <Radio.Group
                                    defaultValue={displayStatus}
                                    onChange={e => {
                                        setEditForm("displayFg", e === "게시" ? 1 : 0)
                                    }}
                                    options={["게시", "숨김"]}
                                ></Radio.Group>
                            </FlexDiv>
                        </LabelForm>
                        <LabelForm
                            label={"첨부파일"}
                            labelCol={2}
                        >
                            <FlexColDiv>
                                <Text
                                    as={"p"}
                                    bold
                                >
                                    첨부파일 추가 - 파일을 드래그 혹은 클릭해서 첨부 해 주세요
                                </Text>
                                <BbsDragAndDropFile
                                    key={"bbsDragAndDropFile"}
                                    files={uploadFiles}
                                    setFiles={setUploadFiles}
                                    defaultData={attatchFiles}
                                    deleteAttachFiles={deleteAttachFiles}
                                />
                            </FlexColDiv>
                        </LabelForm>
                    </FlexColDiv>
                    <BbsEditor
                        ref={editorRef}
                        initialValue={faqData?.contents}
                        placeholder="FAQ 답변을 입력해주세요."
                        onChange={() => setContents(editorRef?.current?.getInstance().getHTML())}
                    />
                </FlexColDiv>
            ) : (
                <FlexColDiv gap="36px">
                    <TableForm>
                        <TableFormLabel md={3}>제목</TableFormLabel>
                        <TableFormValue md={9}>{faqData?.title}</TableFormValue>
                        <TableFormLabel md={3}>구분</TableFormLabel>
                        <TableFormValue md={9}>{faqData?.codeLabel}</TableFormValue>
                        <TableFormLabel md={3}>게시 상태</TableFormLabel>
                        <TableFormValue md={9}>{displayStatus}</TableFormValue>
                        <TableFormLabel md={3}>첨부파일</TableFormLabel>
                        <TableFormValue md={9}>
                            {attatchFiles.length > 0
                                ? attatchFiles.map((item: any, idx: number) => {
                                      return (
                                          <BbsClipDiv
                                              key={idx}
                                              onClick={() => downloadFile(item.s3Url)}
                                          >
                                              {item?.fileName}
                                              <Icon.Download width={16} />
                                          </BbsClipDiv>
                                      )
                                  })
                                : "첨부된 파일이 없습니다."}
                        </TableFormValue>
                    </TableForm>
                    <BbsFlexDiv gap="8px">
                        {faqData && (
                            <CommonDiv margin="-24px 0">
                                <Text
                                    margin="-24px 0"
                                    fontSize="12px"
                                    color="grey"
                                >
                                    pc 버전 미리보기 (1200px) - 아무것도 안보일 경우 새로고침해주세요.
                                </Text>
                                <BbsViewerContainer $width="1200px">
                                    <BbsViewer contents={contents} />
                                </BbsViewerContainer>
                            </CommonDiv>
                        )}
                        {faqData && (
                            <CommonDiv margin="-24px 0">
                                <Text
                                    fontSize="12px"
                                    color="grey"
                                >
                                    mobile 버전 미리보기 (393px - iphone 14 pro)
                                </Text>
                                <BbsViewerContainer $width="393px">
                                    <BbsViewer contents={contents} />
                                </BbsViewerContainer>
                            </CommonDiv>
                        )}
                    </BbsFlexDiv>
                </FlexColDiv>
            )}
            <Modal
                type="confirm"
                title="FAQ 삭제"
                open={openModal}
                onConfirm={() => {
                    setOpenModal(false)
                    deleteFaq(location.state.id)
                }}
                onCancel={() => {
                    setOpenModal(false)
                }}
                onClose={() => {
                    setOpenModal(false)
                }}
            >
                정말로 삭제하시겠습니까?
            </Modal>
        </div>
    )
}

export default FaqDetail
