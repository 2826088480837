import React, { useEffect, useMemo, useRef, useState } from "react"

import { Button, CheckBox, Input, LabelForm, Select } from "p-ui"
import { CommonDiv, FlexColDiv, FlexDiv } from "@styles"
import { Type } from "p-ui"
import { FlexCol } from "../styles"

import apis from "@apis"

import { CopyToClipboard } from "react-copy-to-clipboard"
import useAlert from "src/context/alert/useAlert"

import { Codes } from "@type/codeType"
import { onChangeParam } from "@type/common"

interface codeItem {
    key: string
    code: string
}

const CodeGenerator = (props: any) => {
    const emptyData = useMemo(() => {
        return [
            { key: "FUNNEL", code: "" },
            { key: "SYMPTOM", code: "" },
            { key: "CHECK", code: "" },
            { key: "CAUSE", code: "" },
            { key: "PROCESS", code: "" },
            { key: "OPTION", code: "" },
        ]
    }, [])
    const binaryCode = useMemo(() => {
        return "0000000000000"
    }, [])
    const { category, codeList } = props
    const [code, setCode] = useState<codeItem[]>(JSON.parse(JSON.stringify(emptyData)))
    const [selectedStation, setSelectedStation] = useState<string>("SE00T00000")
    const [resultCode, setResultCode] = useState<string>("#0000/SE00T00000")
    const [stationList, setStationList] = useState<Type.OptionGroupType[]>([
        { label: "관련 스테이션 없음 : SE00T00000", value: "SE00T00000" },
    ])
    const [copied, setCopied] = useState<boolean>(false)
    const [selectedCheckboxList, setSelectedCheckboxList] = useState<Array<string | number>>([])
    const result = useRef<HTMLInputElement>(null)
    const { openAlert } = useAlert()

    /**
     * parent code 별로 하위 code filter 하는 함수
     * @param parentCodeKey string
     * @returns filterList Codes[]
     */
    const filterCodeByParentCode = (parentCodeKey: string) => {
        let newList: Type.OptionGroupType[] = []
        codeList.map((item: Codes) => {
            if (item.parentCode.includes(parentCodeKey)) {
                newList.push({ value: item.code, label: item.label })
            }
        })
        return newList
    }

    const filterOptionCode = () => {
        let newList: Type.OptionType[] = []
        codeList.map((item: Codes) => {
            if (item.parentCode.includes("AS_OPTION")) {
                newList.push({ value: item.code, label: item.label })
            }
        })
        newList.sort(function (a: Type.OptionType, b: Type.OptionType) {
            return Number(a.value) - Number(b.value)
        })

        return newList
    }

    const onChangeGroup = (checkedValue: Array<string | number>) => {
        let s = binaryCode

        checkedValue.forEach((item: string | number) => {
            s = s.substring(0, Number(item) - 1) + "1" + s.substring(Number(item))
        })
        let resultData = "0000" + parseInt(s, 2)
        code[5].code = "#" + resultData.slice(-4)
        setCode([...code])
    }

    /**
     * 사용자가 선택에 따른 as code 생성 함수
     * @returns as code string
     */
    const generateCode = () => {
        let result: string[] = []
        category.map((item: any) => {
            code.map((el: codeItem) => {
                if (el.key == item.value) {
                    if (el.key == "OPTION" && el.code == "") {
                        result.push("#0000")
                    } else {
                        result.push(el.code)
                    }
                }
            })
        })
        return `${result.join("")}${selectedStation ? "/" + selectedStation : selectedStation}`
    }

    /**
     * code 선택 시 중복을 제거하고 code 할당하는 함수
     * @param key string parent code value
     * @param value string code value
     */
    const selectCodeItem = (key: string, value: string) => {
        code.forEach((el, idx) => {
            if (el.key == key) {
                code[idx].code = value
                return
            } else {
                return
            }
        })

        setCode([...code])
        selectDefaultValue(key)
    }

    /**
     * as code 생성을 위한 station list 가져오는 함수
     */
    const getStationListForASCode = async () => {
        await apis.Code.getStationListForASCode().then(res => {
            if (res.status == "SUCCESS") {
                let stationOptionList: Type.OptionGroupType[] = []
                res.resultData.map(item => {
                    stationOptionList.push({ label: item.label, value: item.serialNo })
                })
                stationOptionList.unshift({ label: "관련 스테이션 없음 : SE00T00000", value: "SE00T00000" })
                setStationList(stationOptionList)
            }
        })
    }

    /**
     * select의 default value (혹은 선택한 값)을 화면에 표기 하기 위한 함수
     * @param key string value의 key값
     * @returns filer value :string
     */
    const selectDefaultValue = (key: string) => {
        const result = code.filter(el => {
            return el.key == key
        })
        return result[0]?.code
    }

    /**
     * 사용자가 입력한 코드의 유효성 검증
     * @param e any
     */
    const revertCode = (e: any) => {
        // TODO :: 정규식
        const inputString = e.value
        const progressCode = inputString.split("/")[0]
        convertCode(progressCode)
        if (inputString.indexOf("/") != -1) {
            if (inputString.split("/")[1].length == 10) {
                setSelectedStation(inputString.split("/")[1])
            }
        } else {
            setSelectedStation("")
        }
    }

    /**
     * 사용자가 입력한 코드를 select 창이 표기할 수 있게 code 변수를 변경하는 함수
     * @param resultCode string 사용자가 입력한 input
     */
    const convertCode = (resultCode: string) => {
        const items = [
            resultCode.length > 0 ? resultCode.substring(0, 1).toUpperCase() : "",
            resultCode.length > 3 ? resultCode.substring(1, 4) : "",
            resultCode.length > 5 ? resultCode.substring(4, 6) : "",
            resultCode.length > 7 ? resultCode.substring(6, 8) : "",
            resultCode.length > 9 ? resultCode.substring(8, 10) : "",
            resultCode.length > 14 ? resultCode.substring(10, 15) : "",
        ]
        if (resultCode.length > 14) {
            const optionCode = ("0000000000000" + parseInt(resultCode.substring(11, 15)).toString(2))
                .slice(-13)
                .split("")
            let resultBinaryCode: Array<string | number> = []
            optionCode.forEach((item, idx) => {
                if (item == "1") {
                    resultBinaryCode.push((idx + 1).toString())
                }
            })
            setSelectedCheckboxList(resultBinaryCode)
        } else {
            setSelectedCheckboxList([])
        }

        items.forEach((el, idx) => {
            code[idx].code = el
        })

        setCode([...code])
    }

    useEffect(() => {
        const genCode = generateCode()
        setResultCode(genCode)
        console.log("TEST")
    }, [code, selectedStation])

    useEffect(() => {
        if (copied) {
            openAlert({ body: "복사 완료!", type: "primary" })
            setCopied(false)
        }
    }, [copied])

    useEffect(() => {
        getStationListForASCode()
    }, [])

    return (
        <div>
            <FlexColDiv gap="10px">
                {category.map((item: any, idx: number) => {
                    return (
                        idx > 0 && (
                            <LabelForm label={item.label}>
                                {idx != 6 ? (
                                    <Select
                                        options={filterCodeByParentCode(item.value)}
                                        onChange={(label: string, value: any) => selectCodeItem(item.value, value)}
                                        searchable
                                        defaultValue={selectDefaultValue(item.value)}
                                    />
                                ) : (
                                    <CheckBox.Group
                                        onChange={onChangeGroup}
                                        value={selectedCheckboxList}
                                    >
                                        <FlexCol flexWrap={"wrap"}>
                                            {filterOptionCode().map((item: any) => {
                                                return (
                                                    <CheckBox
                                                        key={item.value}
                                                        label={item.label}
                                                        value={item.value}
                                                    />
                                                )
                                            })}
                                        </FlexCol>
                                    </CheckBox.Group>
                                )}
                            </LabelForm>
                        )
                    )
                })}
            </FlexColDiv>
            <CommonDiv margin="10px 0 0 0">
                <LabelForm label="스테이션">
                    <Select
                        options={stationList}
                        onChange={(label: string, value: any) => {
                            setSelectedStation(value)
                        }}
                        searchable
                        defaultValue={selectedStation}
                    />
                </LabelForm>
            </CommonDiv>
            <CommonDiv margin="10px 0 0 0">
                <LabelForm label="결과코드">
                    <FlexDiv
                        gap="5px"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Input
                            width={"100%"}
                            onChange={(e: onChangeParam) => {
                                revertCode(e)
                            }}
                            value={resultCode}
                            ref={result}
                        />
                        <CopyToClipboard text={resultCode}>
                            <Button
                                type="primary"
                                onClick={() => setCopied(true)}
                            >
                                복사
                            </Button>
                        </CopyToClipboard>
                    </FlexDiv>
                </LabelForm>
            </CommonDiv>
            <CommonDiv margin="10px 0 0 0">
                <Button
                    type="warning"
                    onClick={() => {
                        setCode(JSON.parse(JSON.stringify(emptyData)))
                        setSelectedCheckboxList([])
                        setSelectedStation("SE00T00000")
                    }}
                >
                    초기화
                </Button>
            </CommonDiv>
        </div>
    )
}

export default CodeGenerator
