import React from "react"
import { Button, Icon } from "p-ui"
import { axiosClearAuthHeader } from "@apis"
import { useSetRecoilState } from "recoil"
import AppState from "@store"
import { useNavigate } from "react-router"
import { Sidebar } from "p-ui/dist/esm/utils"
import { Type } from "p-ui"
import { getManagerName, getOrgName, getPositionName } from "@common/util"
import styled from "styled-components"

interface SidebarProps {
    menuList: Type.Menu[]
}
const HeaderLayout = (props: SidebarProps) => {
    const setLoginState = useSetRecoilState<boolean>(AppState.auth.loginState)
    const setToken = useSetRecoilState<string>(AppState.auth.tokenState)
    const setUserState = useSetRecoilState<string>(AppState.auth.userState)
    const navigate = useNavigate()
    const orgName = getOrgName()
    const managerName = getManagerName()
    const positionName = getPositionName()

    const logout = () => {
        setToken("")
        setLoginState(false)
        setUserState("")
        axiosClearAuthHeader()
        sessionStorage.clear()

        // TODO CHANGE : 권한 별 인덱스 설정
        navigate("/")
    }
    return (
        <header className="layout-header">
            <Button
                size="small"
                onClick={() => {
                    console.log(props.menuList)
                    Sidebar.toggleSideBar(props.menuList)
                }}
            >
                <Icon.Menu
                    height={10}
                    width={10}
                />
            </Button>
            <div className="flex-between">
                <div>
                    <span>{orgName} / </span>
                    <span>{positionName} / </span>
                    <span>{managerName} </span>
                </div>
                <LogOutDiv onClick={() => logout()}>
                    <Icon.Turn
                        fill="white"
                        width={20}
                        height={20}
                    />
                </LogOutDiv>
            </div>
        </header>
    )
}

export default HeaderLayout

const LogOutDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
`
