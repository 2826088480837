import { bikeErrorCodeValidate, getManagerId } from "@common/util"
import useAlert from "@context/alert/useAlert"
import { FlexColDiv, FlexDiv } from "@styles"
import { Editor } from "@toast-ui/react-editor"
import colorPlugin from "@toast-ui/editor-plugin-color-syntax"
import { BikeErrorCode } from "@type/bbsType"
import { Code } from "@type/responseType"
import { Button, Input, LabelForm, PageHeader, Select, TextArea } from "p-ui"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
import bikeErrorCode from "src/apis/bikeErrorCode"

const InsertBikeErrorCode = () => {
    const navigate = useNavigate()

    const [bikeErrorCodeData, setBikeErrorCodeData] = useState<BikeErrorCode>(defaultBikeErrorCodeData)
    const [codeType, setCodeType] = useState<Code[]>(defaultCodeData)
    const [contents, setContents] = useState<string | any>("")

    const editorRef = useRef<Editor>(null)
    const managerId = getManagerId()
    const { openAlert } = useAlert()

    const regex = /^[a-zA-Z0-9]+$/ //영어+숫자만

    const setForm = (name: any, value: any) => {
        setBikeErrorCodeData((item: any) => ({ ...item, [name]: value }))
    }

    const getCodeType = () => {
        bikeErrorCode.getBikeErrorCodeType().then(res => {
            if (res.status === "SUCCESS") {
                setCodeType(res.resultData)
                setForm("regManager", Number(managerId))
            }
        })
    }

    const checkValidate = () => {
        const { fieldList, notValidMessage } = bikeErrorCodeValidate()

        if (bikeErrorCodeData !== undefined) {
            const data = bikeErrorCodeData
            const isNotValidField = fieldList.find(field => !data[field as keyof BikeErrorCode])

            if (isNotValidField) {
                return openAlert({ title: "실패", body: notValidMessage[isNotValidField].toString(), type: "warning" })
            }
            if (data.errorDesc === "<p><br></p>") {
                return openAlert({ title: "실패", body: "에러코드 설명을 입력해주세요.", type: "warning" })
            }
            if (!regex.test(data.errorCode)) {
                return openAlert({ title: "실패", body: "에러코드명은 영어, 숫자만 입력해주세요", type: "warning" })
            }

            const containsImageTag = /<img\s[^>]*>/i.test(data.errorDesc)

            if (containsImageTag) {
                return openAlert({ title: "실패", body: "이미지는 삽입할 수 없습니다.", type: "warning" })
            }

            insertBikeErrorCode(data)
        }
    }

    const insertBikeErrorCode = (body: BikeErrorCode) => {
        bikeErrorCode.insertBikeErrorCode(body).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "성공", body: "에러코드가 등록되었습니다.", type: "primary" })
                navigate("/bbs/errorcode")
            } else {
                openAlert({ title: "실패", body: res.resultMessage, type: "warning" })
            }
        })
    }

    const handleErrorCodeInput = (e: any) => {
        setForm(e.name, e.value)
        if (!regex.test(e.value)) {
            return openAlert({ title: "실패", body: "에러코드명은 영어, 숫자만 입력해주세요", type: "warning" })
        }
    }

    useEffect(() => {
        getCodeType()
        if (editorRef.current) {
            editorRef.current.getInstance().removeHook("addImageBlobHook")
        }
    }, [])

    useEffect(() => {
        setForm("errorDesc", contents)
    }, [contents])

    return (
        <>
            <FlexDiv alignItems="center">
                <PageHeader
                    title="에러코드 관리"
                    subTitle="에러코드 등록"
                />
                <Button
                    type="primary"
                    onClick={() => checkValidate()}
                >
                    등록하기
                </Button>
            </FlexDiv>
            <FlexColDiv gap="36px">
                <FlexColDiv
                    width="1200px"
                    gap="8px"
                >
                    <LabelForm
                        label={"에러코드명"}
                        labelCol={2}
                        required
                    >
                        <Input
                            name="errorCode"
                            placeholder="에러코드명은 영어, 숫자만 입력해주세요."
                            onChange={e => {
                                handleErrorCodeInput(e)
                            }}
                        />
                    </LabelForm>
                    <LabelForm
                        label="구분"
                        labelCol={2}
                        required
                    >
                        <Select
                            name="codeType"
                            options={codeType.map((item: any) => item.codeLabel)}
                            onChange={(label: string, value: any) => {
                                setForm(label, codeType.find((item: any) => item.codeLabel === value)?.codeId)
                            }}
                        />
                    </LabelForm>
                </FlexColDiv>
                <Editor
                    ref={editorRef}
                    initialEditType="wysiwyg"
                    previewStyle="vertical"
                    height="600px"
                    placeholder="에러코드 설명을 입력해주세요."
                    useCommandShortcut={true}
                    plugins={[colorPlugin]}
                    toolbarItems={[["bold", "italic", "strike"]]}
                    onChange={() => setContents(editorRef?.current?.getInstance().getHTML())}
                />
            </FlexColDiv>
        </>
    )
}

export default InsertBikeErrorCode

const defaultBikeErrorCodeData = {
    codeType: null,
    errorCode: "",
    errorDesc: "",
    regManager: "",
}

const defaultCodeData = [
    {
        codeId: 0,
        parentCode: "",
        code: "",
        codeLabel: "",
        numberValue: null,
        stringValue: null,
    },
]
