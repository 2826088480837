import { useEffect, useState } from "react"
import { useLocation } from "react-router"

import BikeHistory from "../Components/BikeHistory"
import LocationEditModal from "../Components/LocationEditModal"
import MemoComponents from "../Components/MemoComponents"
import RentalFleetBikeHeader from "../Components/RentalFleetDetailBikeHeader"
import RentalFleetStartModal from "../Components/RentalFleetStartModal"
import RentalFleetEndModal from "../Components/RentalFleetEndModal"
import InsuranceForm from "../Components/InsuranceForm"
import InsuranceModal from "../Components/InsuranceModal"
import UnusableModal from "../Components/UnusabledModal"
import UsabledModal from "../Components/UsabledModal"
import RentalFleetEditModal from "../Components/RentalFleetEditModal"
import BikeInfo from "../Components/BikeInfo"
import { FlexDiv } from "@styles"
import { Button, Modal, PageHeader } from "p-ui"

import rentalFleet from "src/apis/rentalFleet"
import zental from "src/apis/zental"

import useAlert from "@context/alert/useAlert"
import utils from "@utils"
import {
    endModalValidate,
    insuranceValidate,
    startModalValidate,
    unusabledValidate,
    usabledValidate,
    getManagerId,
    getManagerName,
} from "@common/util"

import { RentalFleetLocation } from "@common/constatns"

import { Type } from "p-ui"
import { ObjectType } from "@type/common"

import {
    RentalFleetInfo,
    RentalFleetInfoDetail,
    RentalFleetInsurance,
    RentalFleetInsuranceUpsert,
} from "@type/responseType"

const RentalFleetDetail = (props: any) => {
    const location = useLocation()
    const { openAlert } = useAlert()
    const [rentalFleetData, setRentalFleetData] = useState<RentalFleetInfoDetail>()
    const [insuranceData, setInsuranceData] = useState<RentalFleetInsurance>()
    const [historyData, setHistoryData] = useState<RentalFleetInfo[]>([])
    const [editData, setEditData] = useState<RentalFleetInfoDetail>()
    const [resetData, setResetData] = useState<RentalFleetInfoDetail>(ResetRentalFleetInfo)
    const [insuranceForm, setInsuranceForm] = useState<RentalFleetInsuranceUpsert>(InsuranceDefault)
    const [bikeNo, setBikeNo] = useState<string>("")
    const [modalOpen, setModalOpen] = useState({
        startModal: false,
        endModal: false,
        useModal: false,
        editModal: false,
        locationEdit: false,
        insuranceModal: false,
    })
    const [memo, setMemo] = useState<string>("")
    const [lastEndDate, setLastEndDate] = useState()

    const managerName = getManagerName()
    const managerId = getManagerId()
    const currentDate = new Date()
    const formatDt = currentDate.format(utils.FORMAT_SHORT_DATETIME)

    const setEditForm = (name: any, value: any) => {
        setEditData((item: any) => ({ ...item, [name]: value }))
    }

    /**
     * 대차 정보 조회
     */
    const getRentalFleetDetail = (id: number) => {
        rentalFleet
            .getRentalFleetDetail(id)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setRentalFleetData(res.resultData.leaseData)
                    setEditData(res.resultData.leaseData)
                    setInsuranceData(res.resultData.bikeData)
                    const bikeData = res.resultData.bikeData
                    setBikeNo(bikeData.bikeNo)
                    setInsuranceForm((prev: any) => ({ ...prev, bikeId: bikeData.bikeId }))
                } else {
                    if (res.resultCode === "ERROR_INFO") {
                        return openAlert({ title: "실패", body: res.resultMessage, type: "warning", infinite: true })
                    }
                    openAlert({
                        title: "실패",
                        body: "문제가 발생하였습니다. 관리자에게 문의하세요",
                        type: "warning",
                        infinite: true,
                    })
                }
            })
            .catch(error => console.log(error))
    }

    const checkInsurance = () => {
        const { fieldList, notValidMessage } = insuranceValidate()

        const isNotValidField = fieldList.find(field => !insuranceForm[field as keyof RentalFleetInsuranceUpsert])

        if (isNotValidField) {
            return openAlert({ title: "실패", body: notValidMessage[isNotValidField].toString(), type: "warning" })
        }
        connectInsurance()
    }

    type ValidateFunction = () => { fieldList: string[]; notValidMessage: ObjectType }

    const handleModalCheck = (validateFunction: ValidateFunction) => {
        const { fieldList, notValidMessage } = validateFunction()

        if (editData !== undefined) {
            const data = validateFunction === unusabledValidate ? resetData : editData
            const isNotValidField = fieldList.find(field => !data[field as keyof RentalFleetInfoDetail])
            if (isNotValidField) {
                return openAlert({ title: "실패", body: notValidMessage[isNotValidField].toString(), type: "warning" })
            } else {
                if (validateFunction === startModalValidate) {
                    setModalOpen(prev => ({
                        ...prev,
                        startModal: false,
                    }))
                } else if (validateFunction === endModalValidate) {
                    if (editData.depositStatus === 1 && (editData.deposit === null || 0)) {
                        return openAlert({ title: "실패", body: "이용금액을 입력해주세요.", type: "warning" })
                    }
                    setModalOpen(prev => ({
                        ...prev,
                        endModal: false,
                    }))
                } else {
                    setModalOpen(prev => ({
                        ...prev,
                        useModal: false,
                    }))
                }
            }
        }

        if (validateFunction === startModalValidate) {
            updateRentalFleetDetail(editData, null) // 시작할 때 사용가능 -> 대차, 업무로 바꿀 때
        } else if (validateFunction === endModalValidate) {
            endAndInsertData(editData)
        } else {
            autoEndAndInsertData(
                editData,
                useModalSetting.find(item => rentalFleetData?.leaseType === item.leaseType)?.toLeaseType
            )
        }
    }

    const endAndInsertData = (data: any) => {
        const bikeSerial = data?.bikeSerial
        const newResetData = {
            ...resetData,
            bikeSerial: bikeSerial,
            regManager: Number(managerId),
            startDt: data ? data.endDt : formatDt,
            comment: memo,
        }
        updateRentalFleetDetail(data, newResetData) // 대차 사용 종료할 때, 대차,업무 -> 사용가능으로 바꿀 때
    }

    const autoEndAndInsertData = (data: any, toLeaseType: any) => {
        //자동으로 종료일 입력 후 업데이트 한다음 insert함.
        //사용불가 -> 대기는 leaseType 1로
        //대기 -> 사용불가는 leaseType 0으로 바꿔야함.
        /**
         * 대치 정보 업데이트
         */
        const copyData = data //업데이트할 정보
        if (toLeaseType === 0) {
            //현재 사용가능 데이터를 업데이트한다.
            copyData.endDt = resetData.startDt //점검필요의 시작일
            copyData.endMgrNm = managerName
            historyData.length !== 0 && (copyData.startDt = historyData[0].endDt) // 시작일은 이전 종료일로
        }
        setEditData(copyData)

        /**
         * 대차 정보 등록 (단, 대기->사용불가는 leaseType 0으로)
         */
        const bikeSerial = data?.bikeSerial
        //대차 새로 등록할 정보
        const newResetData = {
            ...resetData,
            leaseType: toLeaseType,
            bikeLocation: RentalFleetLocation[2].value,
            bikeSerial: bikeSerial,
            leaseReason: toLeaseType === 1 ? "" : resetData.leaseReason,
            regManager: Number(managerId),
            startDt: toLeaseType === 1 ? copyData.endDt : resetData.startDt, //사용가능 시작일은 점검필요 종료일로 설정.
            comment: memo,
        }
        updateRentalFleetDetail(editData, newResetData) // 점검필요 -> 사용가능으로 바꿀 때
    }

    /**
     * 대차 정보 수정
     * @param data RentalFleetInfo
     */
    const updateRentalFleetDetail = (data: any, insertData: RentalFleetInfoDetail | null) => {
        rentalFleet
            .updateRentalFleetDetail(data)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setRentalFleetData(res.resultData)
                    setEditData(res.resultData)
                    if (insertData !== null) {
                        insertRentalFleetDetail(insertData)
                    }
                    openAlert({ title: "성공", body: "대차 정보가 수정되었습니다.", type: "primary" })
                } else {
                    if (res.resultCode === "ERROR_STARTDT") {
                        return openAlert({ title: "실패", body: res.resultMessage, type: "warning", infinite: true })
                    }
                    openAlert({ title: "실패", body: "문제가 발생하였습니다. 관리자에게 문의하세요", type: "warning" })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    /**
     * 대차 정보 생성 (대기상태로)
     * @param data RentalFleetInfoDetail
     */
    const insertRentalFleetDetail = (data: RentalFleetInfoDetail) => {
        rentalFleet
            .insertRentalFleetDetail(data)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setRentalFleetData(res.resultData)
                    getRentalFleetDetail(res.resultData.bikeSerial)
                    setResetData(ResetRentalFleetInfo)
                } else {
                    openAlert({ title: "실패", body: "문제가 발생하였습니다. 관리자에게 문의하세요", type: "warning" })
                }
            })
            .catch(err => console.log(err))
    }

    /** 보험 연결 */
    const connectInsurance = async () => {
        await zental.insertInsurance(insuranceForm).then(res => {
            if (res.status === "SUCCESS") {
                openAlert({ title: "저장", body: "저장되었습니다.", type: "primary" })
                setModalOpen(prev => ({
                    ...prev,
                    insuranceModal: false,
                }))
                getRentalFleetDetail(location.state.bikeSerial)
            } else {
                openAlert({ title: "실패", body: "문제가 발생하였습니다. 관리자에게 문의하세요", type: "warning" })
            }
        })
    }

    useEffect(() => {
        if (location.state) {
            getRentalFleetDetail(location.state.bikeSerial)
        }
    }, [])

    return (
        <>
            <PageHeader
                title={"대차"}
                subTitle={"대차 상세"}
            />
            <RentalFleetBikeHeader
                rentalFleetBikeData={rentalFleetData}
                bikeNo={bikeNo}
            />
            <FlexDiv
                gap="72px"
                alignItems="center"
            >
                <BikeInfo
                    rentalFleetBikeData={rentalFleetData}
                    setModalOpen={setModalOpen}
                />
                <InsuranceForm
                    bikeData={insuranceData}
                    setModalOpen={setModalOpen}
                />
            </FlexDiv>
            <MemoComponents
                rentalFleetBikeData={rentalFleetData}
                setEditData={setEditData}
                memo={memo}
                setMemo={setMemo}
            />
            <BikeHistory
                rentalFleetBikeData={rentalFleetData}
                historyData={historyData}
                setHistoryData={setHistoryData}
                setLastEndDate={setLastEndDate}
            />
            {/* 🔽모달🔽 */}
            <Modal
                type="confirm"
                title="대차 등록"
                open={modalOpen.startModal}
                onClose={() => {
                    setModalOpen(prev => ({
                        ...prev,
                        startModal: false,
                    }))
                    setEditData(rentalFleetData)
                }}
                confirmText="등록"
                width={550}
                scrollable={true}
                footer={
                    <>
                        <Button
                            type="secondary"
                            onClick={() => {
                                setModalOpen(prev => ({
                                    ...prev,
                                    startModal: false,
                                }))
                                setEditData(rentalFleetData)
                            }}
                        >
                            취소
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => handleModalCheck(startModalValidate)}
                        >
                            등록
                        </Button>
                    </>
                }
            >
                <RentalFleetStartModal
                    rentalFleetBikeData={rentalFleetData}
                    setEditForm={setEditForm}
                    lastEndDate={lastEndDate}
                />
            </Modal>
            <Modal
                type="confirm"
                title="대차 사용 종료"
                open={modalOpen.endModal}
                onClose={() => {
                    setModalOpen({ ...modalOpen, endModal: false })
                    setEditData(rentalFleetData)
                }}
                width={550}
                scrollable={true}
                footer={
                    <>
                        <Button
                            type="secondary"
                            onClick={() => {
                                setModalOpen(prev => ({
                                    ...prev,
                                    endModal: false,
                                }))
                                setEditData(rentalFleetData)
                            }}
                        >
                            취소
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => handleModalCheck(endModalValidate)}
                        >
                            종료
                        </Button>
                    </>
                }
            >
                <RentalFleetEndModal
                    rentalFleetBikeData={rentalFleetData}
                    setEditForm={setEditForm}
                />
            </Modal>
            <Modal
                type="confirm"
                title="대차 정보 수정"
                open={modalOpen.editModal}
                onClose={() => {
                    setModalOpen({ ...modalOpen, editModal: false })
                    setEditData(rentalFleetData)
                }}
                width={550}
                scrollable={true}
                footer={
                    <>
                        <Button
                            type="secondary"
                            onClick={() => {
                                setModalOpen(prev => ({
                                    ...prev,
                                    editModal: false,
                                }))
                                setEditData(rentalFleetData)
                            }}
                        >
                            취소
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                setModalOpen(prev => ({
                                    ...prev,
                                    editModal: false,
                                }))
                                updateRentalFleetDetail(editData, null) // 대차정보수정할때
                            }}
                        >
                            수정
                        </Button>
                    </>
                }
            >
                <RentalFleetEditModal
                    rentalFleetBikeData={rentalFleetData}
                    setEditForm={setEditForm}
                    lastEndDate={lastEndDate}
                />
            </Modal>
            <Modal
                type="confirm"
                title={useModalSetting.find(item => rentalFleetData?.leaseType === item.leaseType)?.title}
                width={550}
                open={modalOpen.useModal}
                onClose={() => {
                    setModalOpen(prev => ({
                        ...prev,
                        useModal: false,
                    }))
                    rentalFleetData?.leaseType === 1 ? setResetData(ResetRentalFleetInfo) : setEditData(rentalFleetData)
                }}
                footer={
                    <>
                        <Button
                            type="secondary"
                            onClick={() => {
                                setModalOpen(prev => ({
                                    ...prev,
                                    useModal: false,
                                }))
                                rentalFleetData?.leaseType === 1
                                    ? setResetData(ResetRentalFleetInfo)
                                    : setEditData(rentalFleetData)
                            }}
                        >
                            취소
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                handleModalCheck(rentalFleetData?.leaseType === 1 ? unusabledValidate : usabledValidate)
                            }}
                        >
                            등록
                        </Button>
                    </>
                }
            >
                <h3>{useModalSetting.find(item => rentalFleetData?.leaseType === item.leaseType)?.children}</h3>
                {rentalFleetData?.leaseType === 1 ? (
                    <UnusableModal
                        setResetData={setResetData}
                        rentalFleetData={rentalFleetData}
                    />
                ) : (
                    <UsabledModal
                        setEditForm={setEditForm}
                        rentalFleetData={rentalFleetData}
                    />
                )}
            </Modal>
            <Modal
                type="confirm"
                title="모터사이클 위치 정보 수정"
                open={modalOpen.locationEdit}
                onClose={() => {
                    setModalOpen({ ...modalOpen, locationEdit: false })
                    setEditData(rentalFleetData)
                }}
                onConfirm={() => {
                    setModalOpen({ ...modalOpen, locationEdit: false })
                    updateRentalFleetDetail(editData, null) // 위치정보 수정할 때
                }}
            >
                <LocationEditModal
                    rentalFleetBikeData={rentalFleetData}
                    setEditForm={setEditForm}
                />
            </Modal>
            <Modal
                type="confirm"
                title="보험 정보 등록 / 수정"
                open={modalOpen.insuranceModal}
                onClose={() => {
                    setModalOpen(prev => ({
                        ...prev,
                        insuranceModal: false,
                    }))
                }}
                width={500}
                footer={
                    <>
                        <Button
                            type="secondary"
                            onClick={() =>
                                setModalOpen((prev: any) => ({
                                    ...prev,
                                    insuranceModal: false,
                                }))
                            }
                        >
                            취소
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => checkInsurance()}
                        >
                            저장
                        </Button>
                    </>
                }
            >
                <InsuranceModal
                    bikeData={insuranceData}
                    insuranceForm={insuranceForm}
                    setInsuranceForm={setInsuranceForm}
                />
            </Modal>
        </>
    )
}

export default RentalFleetDetail

const ResetRentalFleetInfo = {
    leaseId: 0,
    bikeSerial: "",
    leaseType: 1,
    leaseUserNm: "",
    leaseReason: "",
    consentFg: 0,
    depositStatus: 0,
    deposit: null,
    bikeLocation: 1,
    startMgrNm: "",
    endMgrNm: "",
    startDt: "",
    endDt: "",
    regManager: null,
    comment: "",
}

const InsuranceDefault = {
    bikeId: 0,
    insuranceId: null,
    insuranceName: null,
    insuranceCompany: null,
    ageOver: null,
    startDt: null,
    endDt: null,
    insuranceComment: null,
}
const useModalSetting = [
    {
        leaseType: 1,
        toLeaseType: 0,
        title: "점검 필요로 변경",
        children: (
            <>
                점검 필요한 차량은 대차, 업무에 사용할 수 없습니다.
                <br />
                아래 사용 불가 사유를 적고 확인 버튼을 누르면 사용 불가 차량으로 전환됩니다.
            </>
        ),
    },
    {
        leaseType: 0,
        toLeaseType: 1,
        title: "대기 상태로 변경",
        children: (
            <>
                사용 가능 상태로 변경시 대기 상태로 변경되며,
                <br /> 대차, 업무에 사용할 수 있습니다.
            </>
        ),
    },
]
