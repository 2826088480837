import { FirmwareInfo } from "@type/firmHardType"
import axios from "./config"
import { FirmwareList, FirmwareListALL, Result } from "@type/responseType"
import { FW } from "./urls"
export default {
    async getAllFirmList(): Promise<Result<FirmwareListALL[]>> {
        try {
            return await axios.host.get(FW.GET_FIRM_LIST_ALL)
        } catch (e: any) {
            return e.message
        }
    },
    async getFirmList(): Promise<Result<FirmwareList[]>> {
        try {
            return await axios.host.get(FW.GET_FIRM_LIST)
        } catch (e: any) {
            return e.message
        }
    },
    async getFirmHistory(deviceType: string, firmwareType: string): Promise<Result<FirmwareList[]>> {
        try {
            return await axios.host.get(
                FW.GET_FIRM_HISTORY + "?deviceType=" + deviceType + "&firmwareType=" + firmwareType
            )
        } catch (e: any) {
            return e.message
        }
    },
    async upsertFirm(firmwareInfo: FirmwareInfo): Promise<Result<any[]>> {
        try {
            return await axios.host.post(FW.UPDATE_FIRM, firmwareInfo)
        } catch (e: any) {
            return e.message
        }
    },
    async deleteFirm(firmwareId: number): Promise<Result<any[]>> {
        try {
            return await axios.host.delete(FW.DELETE_FIRM, { params: { firmwareId: firmwareId } })
        } catch (e: any) {
            return e.message
        }
    },
}
