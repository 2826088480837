import * as reg from "./reg"
import * as station from "./staiton"
import * as modal from "./modal"
import * as auth from "./auth"
import * as agency from "./agency"
import * as zental from "./zental"
const AppState = {
    reg,
    station,
    modal,
    auth,
    agency,
    zental,
}

export default AppState
