import { Faq, FaqInfo, FaqOneData } from "@type/bbsType"
import { Code, Result } from "@type/responseType"
import axios from "./config"
import { FAQ } from "./urls"

export default {
    /**
     * FAQ 목록 조회
     * @returns {Array}
     */
    async getFaqList(): Promise<Result<FaqInfo>> {
        try {
            return await axios.host.get(FAQ.GET_LIST)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * FAQ 구분 조회
     * @returns {Array}
     */
    async getFaqType(): Promise<Result<Code[]>> {
        try {
            return await axios.host.get(FAQ.GET_TYPE)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * faq 단건 조회
     * @param id faqId
     * @returns {Array}
     */
    async getFaqDetail(id: number | string): Promise<Result<FaqOneData>> {
        try {
            return await axios.host.get(FAQ.GET_DETAIL + id)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * faq 등록
     * @param body faq info
     * @returns {Array}
     */
    async insertFaq(body: Faq): Promise<Result<FaqInfo>> {
        try {
            return await axios.host.post(FAQ.POST, body)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * faq 수정
     * @param body faq info
     * @returns {Array}
     */
    async updateFaq(body: FaqInfo): Promise<Result<FaqInfo>> {
        try {
            return await axios.host.put(FAQ.PUT, body)
        } catch (err: any) {
            return err.message
        }
    },

    /**
     * faq 삭제 (use_fg = 0처리)
     * @param id faqId
     * @returns {Array}
     */
    async deleteFaq(id: number): Promise<Result<FaqInfo>> {
        try {
            return await axios.host.delete(FAQ.DELETE + id)
        } catch (err: any) {
            return err.message
        }
    },
}
