import { Accordion, Button, CheckBox, Col, Loading, PageHeader, Row } from "p-ui"
import React, { useEffect, useMemo, useState } from "react"
import openApi from "src/apis/openApi"

import { DataPickerInput, DatePickerSpan, FlexColDiv, FlexDiv, GridDiv, Label } from "@styles"
import CustomDatePicker from "@components/widgets/customDatePicker"
import { ko } from "date-fns/locale"
import ReactDatePicker from "react-datepicker"
import ECharts, { EChartsReactProps } from "echarts-for-react"
import * as echarts from "echarts"
import "./echarts-theme.js"
import { downloadExcel } from "@common/util"

const DataVisualPage = () => {
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0))

    const [isResize, setIsResize] = useState(false)
    const [startDate, setStartDate] = useState(currentDate)
    const [endDate, setEndDate] = useState<Date>(currentDate)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [bikeData, setBikeData] = useState<any[]>([])
    const [selectedCellValue, setSelectedCellValue] = useState([])
    const [selectedMilesValue, setSelectedMilesValue] = useState([])
    const [selectedSocValue, setSelectedSocValue] = useState([])
    const [selectedTempValue, setSelectedTempValue] = useState([])
    const [resultArray, setResultArray] = useState<{ value: any; type: string }[]>([])
    const handleStartDateChange = (date: any) => {
        setStartDate(date)
        setEndDate(date)
    }

    const handleEndDateChange = (date: any) => {
        setEndDate(date)
    }
    const getBikeData = async () => {
        let startdate = new Date(startDate)
        let enddate = new Date(endDate)

        let epochStartTime = startdate.getTime().toString()
        let epochEndTime = enddate.getTime().toString()

        await openApi.getBikeData(epochStartTime, epochEndTime).then(res => {
            if (res.status == "SUCCESS") {
                setIsLoading(false)

                const bt1Soc = res.resultData.map(item => {
                    return {
                        ...item,
                        bt1Soc: item.bt1Soc / 10,
                        bt1Soh: item.bt1Soh / 10,
                        bt2Soc: item.bt2Soc / 10,
                        bt2Soh: item.bt2Soh / 10,
                        bt1TempMax: item.bt1TempMax / 10,
                        bt1TempMin: item.bt1TempMin / 10,
                        bt2TempMax: item.bt2TempMax / 10,
                        bt2TempMin: item.bt2TempMin / 10,
                        bt1TempAvg: item.bt1TempAvg / 10,
                        bt2TempAvg: item.bt2TempAvg / 10,
                        dataTime: `${String(new Date(item.time).getHours()).padStart(2, "0")} : ${String(
                            new Date(item.time).getMinutes()
                        ).padStart(2, "0")}`,
                    }
                })
                bt1Soc.sort((a, b) => a.time - b.time)
                setBikeData(bt1Soc)
            } else {
                setIsLoading(false)
            }
        })
    }
    const handleCheckboxChange = (value: any, type: string) => {
        const updatedResultArray = [...resultArray]

        const existingIndex = updatedResultArray.findIndex(item => item.type === type)

        if (existingIndex !== -1) {
            if (Array.isArray(value) && value.length === 0) {
                updatedResultArray.splice(existingIndex, 1)
            } else {
                updatedResultArray[existingIndex].value = Array.isArray(value) ? value : []
            }
        } else {
            if (!(Array.isArray(value) && value.length === 0)) {
                updatedResultArray.push({ value: Array.isArray(value) ? value : [], type })
            }
        }

        setResultArray(updatedResultArray)
    }

    const settingSelectedValue = useMemo(() => {
        if (resultArray.length > 2) {
            const [newArr, ...rest] = resultArray
            setResultArray(rest)

            switch (newArr?.type) {
                case "Km":
                    setSelectedMilesValue([])
                    break
                case "%":
                    setSelectedSocValue([])
                    break
                case "mV":
                    setSelectedCellValue([])
                    break
                case "°C":
                    setSelectedTempValue([])
                    break
            }
        }

        if (resultArray.length === 0) {
            return []
        }

        const finalResult = resultArray

        return finalResult
    }, [
        resultArray,
        setSelectedCellValue,
        setSelectedMilesValue,
        setSelectedSocValue,
        setSelectedTempValue,
    ]) as unknown as SelectedValueType

    const options = useMemo(() => {
        if (settingSelectedValue.length === 0) {
            return {
                animationEasing: "elasticOut",
                yAxis: [
                    {
                        type: "value",
                        axisLabel: {
                            formatter: "{value}",
                        },
                    },
                ],
                xAxis: {
                    type: "category",
                    data: [],
                },
                dataZoom: [
                    {
                        type: "inside",
                        zoomOnMouseWheel: true,
                    },
                    {
                        dataZoomSlider: true,
                        type: "slider",
                    },
                ],
                legend: {
                    icon: "rect",
                },
                tooltip: {
                    trigger: "axis",
                },
                responsive: true,
                series: [
                    {
                        name: "Default",
                        type: "line",
                        data: [],
                        sampling: "lttb",
                        markPoint: {
                            data: [
                                { type: "max", name: "최대값" },
                                { type: "min", name: "최소값" },
                            ],
                        },
                    },
                ],
            }
        }

        const yAxisData = settingSelectedValue.map(data => ({
            type: "value",
            name: data.type,
            nameTextStyle: {
                fontWeight: "bold",
            },
            axisLabel: {
                formatter: `{value} ${data.type}`,
            },
        }))

        const seriesData = settingSelectedValue.flatMap(data =>
            data.value.map(value => ({
                name: `${value} (${data.type})`,
                type: "line",
                yAxisIndex: yAxisData.findIndex(yAxis => yAxis.name === data.type),
                data: bikeData.map(item => item[value]),
                sampling: "lttb",
                markPoint: {
                    data: [
                        { type: "max", name: "최대값" },
                        { type: "min", name: "최소값" },
                    ],
                },
            }))
        )

        return {
            animationEasing: "elasticOut",
            yAxis: yAxisData,
            xAxis: {
                type: "category",
                xAxis: {
                    type: "category",
                    data: bikeData.map(item => item.dataTime),
                },
            },
            dataZoom: [
                {
                    type: "inside",
                    zoomOnMouseWheel: true,
                },
                {
                    dataZoomSlider: true,
                    type: "slider",
                },
            ],
            legend: {
                icon: "rect",
            },
            toolbox: {
                feature: {
                    dataView: { show: true, readOnly: false },
                    saveAsImage: { show: true },
                    magicType: {
                        type: ["line", "bar"],
                    },
                },
            },
            tooltip: {
                trigger: "axis",
            },
            responsive: true,
            series: seriesData,
        }
    }, [bikeData, settingSelectedValue])

    useEffect(() => {
        const fetchData = async () => {
            await getBikeData()
        }
        fetchData()
    }, [])

    useEffect(() => {
        setIsResize(true)
    }, [])
    return (
        <>
            <Loading open={isLoading} />
            <FlexColDiv height="100%">
                <PageHeader
                    title="실험실"
                    subTitle="데이터 시각화"
                />
                <Row
                    marginBottom={"10px"}
                    gap="10px"
                >
                    <Col sm={1}>
                        <Label>검색 기간</Label>
                    </Col>
                    <Col md={11}>
                        <FlexDiv
                            height="100%"
                            justifyContent="space-between"
                        >
                            <FlexDiv
                                height="100%"
                                gap="5px"
                            >
                                <CustomDatePicker height={"100%"}>
                                    <ReactDatePicker
                                        selected={startDate}
                                        showTimeSelect
                                        onChange={(date: any) => handleStartDateChange(date)}
                                        locale={ko} // 한글로 변경
                                        selectsStart
                                        dateFormat="yyyy.MM.dd (eee) HH:mm" // 시간 포맷 변경
                                        showPopperArrow={false} // 화살표 변경
                                        customInput={<DataPickerInput height={"50px"} />}
                                    />
                                </CustomDatePicker>
                                <DatePickerSpan> ~ </DatePickerSpan>
                                <CustomDatePicker height={"100%"}>
                                    <ReactDatePicker
                                        selected={endDate}
                                        showTimeSelect
                                        onChange={(date: any) => handleEndDateChange(date)}
                                        locale={ko}
                                        dateFormat="yyyy.MM.dd (eee) HH:mm"
                                        showPopperArrow={false}
                                        selectsEnd
                                        startDate={startDate}
                                        maxDate={new Date(startDate.getTime() + 3 * 60 * 60 * 1000)}
                                        endDate={endDate}
                                        minDate={startDate}
                                        customInput={<DataPickerInput height={"50px"} />}
                                    />
                                </CustomDatePicker>
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setIsLoading(true)
                                        getBikeData()
                                    }}
                                >
                                    검색
                                </Button>
                            </FlexDiv>
                            <Button
                                size="small"
                                onClick={() => downloadExcel(bikeData, excelHeader, "dataVisual_")}
                            >
                                엑셀
                            </Button>
                        </FlexDiv>
                    </Col>
                    <Col sm={1}>
                        <Label>검색 필터</Label>
                    </Col>
                    <Col sm={11}>
                        <Accordion>
                            <Accordion.Header>검색 필터</Accordion.Header>
                            <Accordion.Contents>
                                <Row gap="10px">
                                    <Col sm={1.2}>
                                        <Label>온도 (°C)</Label>
                                    </Col>
                                    <Col md={10.8}>
                                        <FlexDiv
                                            gap="15px"
                                            alignItems="flex-end"
                                        >
                                            <CheckBox.Group
                                                value={selectedTempValue}
                                                onChange={(value: any) => {
                                                    setSelectedTempValue(value)
                                                    handleCheckboxChange(value, "°C")
                                                }}
                                            >
                                                <GridDiv
                                                    gridCol="repeat(10, 1fr);"
                                                    gap="10px"
                                                >
                                                    {tempValues.map(item => {
                                                        return (
                                                            <CheckBox
                                                                width={16}
                                                                height={16}
                                                                value={item}
                                                                label={item}
                                                            />
                                                        )
                                                    })}
                                                </GridDiv>
                                            </CheckBox.Group>
                                        </FlexDiv>
                                    </Col>
                                    <Col sm={1.2}>
                                        <Label>셀 전압 (mV)</Label>
                                    </Col>
                                    <Col md={10.8}>
                                        <FlexDiv
                                            gap="15px"
                                            alignItems="flex-end"
                                        >
                                            <CheckBox.Group
                                                value={selectedCellValue}
                                                onChange={(value: any) => {
                                                    setSelectedCellValue(value)
                                                    handleCheckboxChange(value, "mV")
                                                }}
                                            >
                                                <GridDiv
                                                    gridCol="repeat(10, 1fr);"
                                                    gap="10px"
                                                >
                                                    {cellValues.map(item => {
                                                        return (
                                                            <CheckBox
                                                                width={16}
                                                                height={16}
                                                                value={item}
                                                                label={item}
                                                            />
                                                        )
                                                    })}
                                                </GridDiv>
                                            </CheckBox.Group>
                                        </FlexDiv>
                                    </Col>
                                    <Col sm={1.2}>
                                        <Label>SOC/SOH (%)</Label>
                                    </Col>
                                    <Col md={10.8}>
                                        <FlexDiv
                                            gap="15px"
                                            alignItems="flex-end"
                                        >
                                            <CheckBox.Group
                                                value={selectedSocValue}
                                                onChange={(value: any) => {
                                                    setSelectedSocValue(value)
                                                    handleCheckboxChange(value, "%")
                                                }}
                                            >
                                                <GridDiv
                                                    gridCol="repeat(10, 1fr);"
                                                    gap="10px"
                                                >
                                                    {socSohValues.map(item => {
                                                        return (
                                                            <CheckBox
                                                                width={16}
                                                                height={16}
                                                                value={item}
                                                                label={item}
                                                            />
                                                        )
                                                    })}
                                                </GridDiv>
                                            </CheckBox.Group>
                                        </FlexDiv>
                                    </Col>
                                    <Col sm={1.2}>
                                        <Label>주행거리 (Km)</Label>
                                    </Col>
                                    <Col md={10.8}>
                                        <FlexDiv
                                            gap="15px"
                                            alignItems="flex-end"
                                        >
                                            <CheckBox.Group
                                                value={selectedMilesValue}
                                                onChange={(value: any) => {
                                                    setSelectedMilesValue(value)
                                                    handleCheckboxChange(value, "Km")
                                                }}
                                            >
                                                <GridDiv
                                                    gridCol="repeat(10, 1fr);"
                                                    gap="10px"
                                                >
                                                    {milesValues.map(item => {
                                                        return (
                                                            <CheckBox
                                                                width={16}
                                                                height={16}
                                                                value={item}
                                                                label={item}
                                                            />
                                                        )
                                                    })}
                                                </GridDiv>
                                            </CheckBox.Group>
                                        </FlexDiv>
                                    </Col>
                                </Row>
                            </Accordion.Contents>
                        </Accordion>
                    </Col>
                </Row>

                <div style={{ flex: "1" }}>
                    {isResize && (
                        <ECharts
                            option={options}
                            notMerge={true}
                            style={{ width: "100%", minHeight: "600px", height: "100%" }}
                            opts={{ renderer: "canvas", height: "auto" }}
                            theme="dark"
                        />
                    )}
                </div>
            </FlexColDiv>
        </>
    )
}

export default DataVisualPage

const cellValues = [
    "bt1CellAvg",
    "bt1CellMax",
    "bt1CellMin",
    "bt2CellAvg",
    "bt2CellMax",
    "bt2CellMin",
    "bt1Voltage",
    "bt2Voltage",
]
const milesValues = ["totalMile", "tripMile"]
const socSohValues = ["bt1Soc", "bt1Soh", "bt2Soc", "bt2Soh"]
const tempValues = [
    "bt1TempMin",
    "bt1TempMax",
    "bt1TempAvg",
    "bt2TempMin",
    "bt2TempMax",
    "bt2TempAvg",
    "edcuTemp",
    "mcuTemp",
    "motorTemp",
]

const excelHeader = [
    { label: "dataTime", value: "dataTime" },
    { label: "bt1Soc", value: "bt1Soc" },
    { label: "bt1Soh", value: "bt1Soh" },
    { label: "bt2Soc", value: "bt2Soc" },
    { label: "bt2Soh", value: "bt2Soh" },
    { label: "bt1CellAvg", value: "bt1CellAvg" },
    { label: "bt1CellMax", value: "bt1CellMax" },
    { label: "bt1CellMin", value: "bt1CellMin" },
    { label: "bt1CellMin", value: "bt1CellMin" },
    { label: "bt1Voltage", value: "bt1Voltage" },

    { label: "bt2CellAvg", value: "bt2CellAvg" },
    { label: "bt2CellMax", value: "bt2CellMax" },
    { label: "bt2CellMin", value: "bt2CellMin" },
    { label: "bt1TempMin", value: "bt1TempMin" },
    { label: "bt1TempMax", value: "bt1TempMax" },
    { label: "bt2TempMin", value: "bt2TempMin" },
    { label: "bt2TempMax", value: "bt2TempMax" },
    { label: "bt2TempAvg", value: "bt2TempAvg" },
    { label: "bt2Voltage", value: "bt2Voltage" },

    { label: "edcuTemp", value: "edcuTemp" },
    { label: "mcuTemp", value: "mcuTemp" },
    { label: "motorTemp", value: "motorTemp" },
    { label: "totalMile", value: "totalMile" },
    { label: "tripMile", value: "tripMile" },
]
type SelectedValueType = {
    value: string[]
    type: string
}[]
