import { useEffect, useState } from "react"
import { useMatch } from "react-router-dom"

import OrderDetailInfoForm from "@pages/Order/Components/OrderDetailInfoForm"
import OrderDetailOrdererForm from "@pages/Order/Components/OrderDetailOrdererForm"
import orderManage from "src/apis/orderManage"
import { RefreshBtn, ClipDiv } from "./styles"
import { DataPickerInput, FlexBetween, FlexColDiv, FlexDiv, Text } from "@styles"
import { Button, DataTable, Icon, Input, LabelForm, Modal, PageHeader, Select } from "p-ui"
import OrderDragAndDropFile from "../Components/OrderDragAndDrop"
import { InsertValueDiv } from "@pages/Rental/styles"
import OrderCancelModal from "../Components/OrderCancelModal"

import moment from "moment/moment"
import useAlert from "@context/alert/useAlert"

import { Order } from "../constants"

import {
    CustomerParam,
    DetailOrderInfo,
    OrderAttach,
    OrderCancel,
    OrderCancelParams,
    OrderCommentParams,
    OrderDetailInfo,
    OrderItemList,
    PaymentInfo,
    PromotionInfo,
} from "@type/orderManageType"

import OrderPurchaserForm from "../Components/OrderPurchaserForm"
import OrderEditForm from "../Components/OrderEditForm"
import OrderEditStatusForm from "../Components/OrderEditStatusForm"
import { OrderCustomerParamType, OrderUpdateParam } from "@type/OrderType"
import { validationField } from "../constants"
import OrderPaymentModal from "../Components/OrderPaymentModal"
import CustomDatePicker from "@components/widgets/customDatePicker"
import { ko } from "date-fns/locale"
import ReactDatePicker from "react-datepicker"
import { formatYYYYMMDD } from "@common/util"

function OrderDetailPage() {
    const { openAlert } = useAlert()
    const currentDate = new Date()

    const orderId = useMatch("/order/detail/:orderId")?.params.orderId

    const [orderResultData, setOrderResultData] = useState<OrderDetailInfo>()
    /**주문자 정보 */
    const [orderInfo, setOrderInfo] = useState<DetailOrderInfo>()
    /**주문 모델 정보 */
    const [orderItemList, setOrderItemList] = useState<OrderItemList[]>([])

    /**결제 정보 */
    const [paymentInfo, setPaymentInfo] = useState<PaymentInfo[]>([])
    /**프로모션 정보 */
    const [promotionInfo, setPromotionInfo] = useState<PromotionInfo>({})
    /**주문취소 정보 */
    const [orderCancel, setOrderCancel] = useState<OrderCancel | null>()
    /**메모  */
    const [orderComment, setOrderComment] = useState<OrderCommentParams>({})

    const [orderStatus, setOrderStatus] = useState<number>()
    const [bikeColor, setBikeColor] = useState<Record<string, number>>({})

    /**월별 프로모션 리스트 */
    const [monthlyPromotion, setMonthlyPromotion] = useState<any[]>([])

    // * 선택된 보조금
    const [selectedSubsidyList, setSelectedSubsidyList] = useState<number[]>([])

    const [modalOpen, setModalOpen] = useState({
        fileEditMode: false,
        isCancleOpen: false,
        isDepositOpen: false,
        isPaymentOpen: false,
        isStatusEditOpen: false,
        isEditOpen: false,
        isOrderEditOpen: false,
        isPromotionEditOpen: false,
        deliveryDtEditOpen: false,
    })

    const [startDate, setStartDate] = useState(currentDate)

    const [isVbank, setIsVbank] = useState<boolean>(false)

    const [uploadFiles, setUploadFiles] = useState([])
    const [attatchFiles, setAttatchFiles] = useState<OrderAttach[] | []>([])

    const [orderInfoParam, setOrderInfoParam] = useState<OrderUpdateParam[]>([])
    const [selectedOptions, setSelectedOptions] = useState<number[]>([])
    const [selectedPromotion, setSelectedPromotion] = useState<number>()
    const [customerParam, setCustomerParam] = useState<CustomerParam>()
    const [cancelParam, setCancelParam] = useState<OrderCancelParams>({
        comment: "",
        type: "ALL",
        refundId: null, // 환불 정보 아이디
    })

    const [previousStatus, setPreviousStatus] = useState<number>()

    const isCancle = orderStatus === 0 || orderStatus === 1
    const isPreviousButtonDisabled =
        /**주문취소  */ orderStatus === 0 || /**주문취소 요청 */ orderStatus === 1 || /**예약대기 */ orderStatus === 2

    const isNextButtonDisabled =
        /**주문취소  */ orderStatus === 0 ||
        /**주문취소 요청 */ orderStatus === 1 ||
        /**최종완료*/ orderStatus === 13 ||
        /**이거 결제상태가 성공일때만 넘어가게 */
        (orderStatus === 7 &&
            paymentInfo.some(item => item.name.includes("잔금")) &&
            paymentInfo.some(item => item.orderStatus !== 2)) ||
        (orderStatus === 8 &&
            paymentInfo.some(item => item.name.includes("잔금")) &&
            paymentInfo.some(item => item.orderStatus !== 2))

    const validation = (validationParam: any, type: string) => {
        const validationFormFields = Object.keys(validationField[type])

        //set form & message
        const updateForm = validationParam
        // check valid
        const missingField = validationFormFields.find(field => !updateForm[field])

        if (missingField) {
            return {
                isNotValid: true,
                msg: validationField[type][missingField],
            }
        } else {
            return {
                isNotValid: false,
                msg: undefined,
            }
        }
    }

    const handleNextButtonClick = () => {
        if (!isNextButtonDisabled) {
            let newOrderStatus = (orderStatus as number) + 1

            // 만약 주문 상태가 7이고 결제 방법이 "카드"인 경우
            if (orderStatus === 7 && paymentInfo.some(item => item.payMethod === "card" || "point")) {
                newOrderStatus += 1
            }

            changeOrderStatus(newOrderStatus)
        }
    }

    const resetSubsidyList = () => {
        orderResultData && setSelectedSubsidyList([orderResultData.subsidyList[0]?.modelId as number])
    }
    const calculateTotalAmount = (items: any) => {
        return items?.reduce((total: any, item: any) => total + item.itemAmount, 0)
    }
    /** get OrderDetail 후 주문 모델 정보 테이블, 결제정보 테이블 데이터 셋팅하는함수 */
    const processOrderDetail = (data: OrderDetailInfo) => {
        /**ACCESSORY 인 항목 담는 배열 */
        let accessoryList: any[] = []
        /**ORDER_BIKE 인 인덱스 저장 */
        let bikeIndex: number | null = null

        data.orderItemList.forEach((item: any, i: number) => {
            if (item.code === "BIKE" || item.code === "ORDER_BIKE") {
                bikeIndex = i
            } else if (item.code === "ACCESSORY") {
                accessoryList.push(item)
            }
        })

        if (bikeIndex !== null && accessoryList.length > 0) {
            const bikeItem = data.orderItemList[bikeIndex]
            bikeItem.optionList = accessoryList

            data.orderItemList = data.orderItemList.filter((item: any) => item.code !== "ACCESSORY")
        }

        /** 보조금 정보 */
        const subsidyList = data.subsidyList
        const subsidyAccount = subsidyList.reduce((acc: number, item: any) => acc + item.discountAccount, 0)

        if (subsidyList.length > 0) {
            setSelectedSubsidyList(subsidyList.map((item: any) => item.modelId))
        }

        /**주문 모델 정보 테이블 데이터 셋팅해주는 함수 */
        const newOrderItemList = data.orderItemList.map((item: any) => {
            const isEditBtnDisabled = data.orderInfo.orderStatus >= 9 || data.orderInfo.orderStatus <= 1

            return {
                ...item,
                itemAmount: <span>{item.itemAmount?.toLocaleString()} 원</span>,
                total: (
                    <span>
                        {item.optionList
                            ? (
                                  calculateTotalAmount(item.optionList) +
                                  item.itemAmount -
                                  subsidyAccount
                              )?.toLocaleString()
                            : item.itemAmount?.toLocaleString()}
                        원
                    </span>
                ),
                optionList: item.optionList ? (
                    <FlexDiv justifyContent="center">
                        <ul style={{ textAlign: "left", padding: 0 }}>
                            {item.optionList.map((optionItem: any) => (
                                <li
                                    style={{ margin: "0 0 5px 0" }}
                                    key={optionItem.productName}
                                >
                                    {optionItem.productName}({optionItem.itemAmount?.toLocaleString()}원) *
                                    {optionItem.itemQuantity} 개
                                </li>
                            ))}
                        </ul>
                    </FlexDiv>
                ) : (
                    <span>-</span>
                ),
                subsidyList: (
                    <FlexDiv
                        justifyContent="center"
                        color="red"
                    >
                        <ul style={{ textAlign: "left", padding: 0 }}>
                            {subsidyList.map((subsidyItem: any) => (
                                <li
                                    style={{ margin: "0 0 5px 0" }}
                                    key={subsidyItem.modelId}
                                >
                                    {subsidyItem.label}(<span style={{ color: "red" }}>{subsidyItem.subsidyDesc}</span>)
                                </li>
                            ))}
                        </ul>
                    </FlexDiv>
                ),
                edit: (
                    <FlexDiv justifyContent="center">
                        <Button
                            size="small"
                            type="primary"
                            compact
                            disabled={isEditBtnDisabled}
                            onClick={() => {
                                setModalOpen({ ...modalDefault, isOrderEditOpen: true })
                            }}
                        >
                            <Icon.Edit
                                width={15}
                                height={15}
                            />
                        </Button>
                    </FlexDiv>
                ),
            }
        })

        /** 결제 정보테이블 데이터 셋팅해주는 함수 */
        const newPaymentInfo = data.paymentInfo.map((item: any) => {
            let statusLabel = ""
            let payMethodLabel = ""
            switch (item.payMethod.toLowerCase()) {
                case "card":
                    payMethodLabel = "카드"
                    break
                case "vacnt":
                    payMethodLabel = "가상계좌"
                    break
                case "point":
                    payMethodLabel = "포인트"
                    break
                case "offline":
                    payMethodLabel = "현장결제"
                    break
                default:
                    payMethodLabel = ""
                    break
            }
            switch (item.status) {
                case 0:
                    statusLabel = "실패"
                    break
                case 1:
                    statusLabel = "대기"
                    break
                case 2 || 9:
                    statusLabel = "성공"
                    break
                case 3:
                    statusLabel = "환불"
                    break
                case 4:
                    statusLabel = "만료"
                    break
                default:
                    statusLabel = ""
                    break
            }
            return {
                ...item,
                paidAt: moment(item.paidAt).format("YYYY-MM-DD hh:mm:ss"),
                payMethod: payMethodLabel,
                totalAmount: <span>{item.amount?.toLocaleString()} 원</span>,
                statusLabel: statusLabel,
                orderStatus: item.status,
                status: (
                    <>
                        <span>{statusLabel}</span>
                        {item.payMethod.toLowerCase() === "vacnt" && item.status == 1 && (
                            <RefreshBtn
                                disabled={item.status === 2}
                                onClick={checkPayments}
                            >
                                <Icon.Swap
                                    width={16}
                                    height={16}
                                    fill="white"
                                />
                            </RefreshBtn>
                        )}
                    </>
                ),
            }
        })
        setIsVbank(newPaymentInfo.some((item: any) => item.payMethod === "가상계좌"))
        setOrderItemList(newOrderItemList)
        setOrderInfo(data.orderInfo)
        setPaymentInfo(newPaymentInfo)
        setPromotionInfo(data.promotionInfo)
        setOrderCancel(data.orderCancel)
        setBikeColor({ [data.orderItemList[0]?.optionName as string]: data.orderItemList[0]?.modelId })
        setOrderComment(data.orderComment)
        setOrderStatus(data.orderInfo.orderStatus)
        setAttatchFiles(data.attach)
    }

    const getOrderDetail = () => {
        orderManage
            .getOrderDetail(orderId as string)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setOrderResultData(res.resultData)
                    processOrderDetail(res.resultData)
                    console.log("res.resultData: ", res.resultData)
                } else {
                    console.log(res.resultMessage)
                    console.log("dd")
                }
            })
            .catch(error => console.log(error))
    }

    const getPromotion = () => {
        orderManage
            .getPromotion(orderId as string)
            .then(res => {
                if (res.status === "SUCCESS") {
                    let newArr: any[] = []
                    res.resultData.map((item: any) => {
                        newArr.push({
                            label: item.promotionDesc,
                            value: item.modelId,
                            promotionName: item.promotionName,
                        })
                    })
                    setMonthlyPromotion(newArr)
                } else {
                    console.log(res.resultMessage)
                }
            })
            .catch(error => console.log(error))
    }

    const cancelOrder = () => {
        const cancelType = isVbank ? "vBankCancel" : "cardCancel"
        const validInfo = validation(cancelParam, cancelType)

        if (validInfo.isNotValid) {
            openAlert({
                body: validInfo.msg,
                type: "warning",
            })
            return
        }

        orderManage
            .deleteOrder(orderId as string, cancelParam)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setModalOpen({ ...modalDefault, isCancleOpen: false })
                    openAlert({
                        title: "취소 완료",
                        body: "주문이 정상적으로 취소되었습니다.",
                        type: "primary",
                    })
                    getOrderDetail()
                } else {
                    openAlert({ title: "취소 실패", body: res.resultMessage, type: "warning", duration: 5000 })
                }
            })
            .catch(error => console.log(error))
    }

    /**인도일 수정 */
    const changeDeliveryDt = () => {
        orderManage
            .updateDeliveryDt(orderId as string, 11, formatYYYYMMDD(startDate as unknown as string))
            .then(res => {
                if (res.status === "SUCCESS") {
                    openAlert({
                        title: "변경",
                        body: "저장되었습니다",
                        type: "primary",
                    })
                    getOrderDetail()
                } else {
                    openAlert({
                        title: "변경",
                        body: "변경 실패.",
                        type: "warning",
                    })
                }
            })
            .catch(error => console.log(error))
    }

    const changeOrderStatus = (status: any) => {
        if (!status) {
            openAlert({
                body: "변경 상태를 선택 해 주세요.",
                type: "warning",
            })

            return true
        }
        if (paymentInfo.some(item => item.orderStatus === 1)) {
            /**결제상태에 대기 상태가 있으면 다음 단계로 변경 불가능 */
            openAlert({
                body: "결제 대기상태가 존재합니다.",
                type: "warning",
            })
            return true
        } else {
            const nextStatus = Order.orderStep[status]?.label

            if (window.confirm(`"${nextStatus}" 상태로 주문상태를 변경하시겠습니까?`)) {
                if (status === 11) {
                    setModalOpen({ ...modalOpen, deliveryDtEditOpen: true })
                } else {
                    orderManage
                        .upadteOrderStatus(orderId as string, status)
                        .then(res => {
                            if (res.status === "SUCCESS") {
                                openAlert({
                                    title: "변경",
                                    body: "저장되었습니다",
                                    type: "primary",
                                })
                                getOrderDetail()
                            } else {
                                openAlert({
                                    title: "변경",
                                    body: "변경 실패.",
                                    type: "warning",
                                })
                            }
                        })
                        .catch(error => console.log(error))
                }
            } else {
                openAlert({
                    body: "취소합니다",
                    type: "warning",
                })
            }
        }
    }

    /**주문자 정보 수정 */
    const updateCustomer = () => {
        const validInfo = validation(customerParam, "purchaserEdit")

        if (validInfo.isNotValid) {
            openAlert({
                body: validInfo.msg,
                type: "warning",
            })
            return
        }

        orderManage
            .updateCustomer(customerParam as OrderCustomerParamType)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setModalOpen({ ...modalDefault, isEditOpen: false })

                    openAlert({
                        title: "변경",
                        body: "저장되었습니다",
                        type: "primary",
                    })

                    getOrderDetail()
                } else {
                    setModalOpen({ ...modalDefault, isEditOpen: false })

                    openAlert({
                        title: "변경",
                        body: "변경 실패.",
                        type: "warning",
                    })
                }
            })
            .catch(error => console.log(error))
    }

    /**주문 옵션 변경 */
    const updateOrderOptions = () => {
        const orderItemParams = [...orderInfoParam]

        selectedOptions &&
            selectedOptions?.map(item => {
                orderItemParams.push({
                    orderId: orderId as string,
                    modelId: item,
                    optionId: 0,
                    itemStatus: orderItemList[0].orderStatus,
                })
            })

        const param = {
            orderItemList: orderItemParams,
            subsidyIdList: selectedSubsidyList,
        }

        orderManage
            .updateOrderOption(orderId as string, param as unknown as OrderUpdateParam)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setModalOpen({ ...modalDefault, isOrderEditOpen: false })
                    openAlert({
                        title: "변경",
                        body: "저장되었습니다",
                        type: "primary",
                    })
                    getOrderDetail()
                } else {
                    setModalOpen({ ...modalDefault, isOrderEditOpen: false })
                    openAlert({
                        title: "변경",
                        body: "수정 실패.",
                        type: "warning",
                    })
                }
            })
            .catch(error => console.log(error))
    }

    /**프로모션 변경 */
    const updatePromotion = () => {
        orderManage
            .updatePromotion(orderId as string, selectedPromotion as number)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setModalOpen({ ...modalDefault, isPromotionEditOpen: false })
                    openAlert({
                        title: "변경",
                        body: "저장되었습니다",
                        type: "primary",
                    })

                    getOrderDetail()
                } else {
                    setModalOpen({ ...modalDefault, isPromotionEditOpen: false })
                    openAlert({
                        title: "변경",
                        body: "수정 실패.",
                        type: "warning",
                    })
                }
            })
            .catch(error => console.log(error))
    }
    /**가상계좌일경우 결제상태 체크 */
    const checkPayments = () => {
        orderManage
            .checkPayment(orderId as string)
            .then(res => {
                if (res.status === "SUCCESS") {
                    getOrderDetail()
                    openAlert({
                        title: "입금 확인",
                        body: "상태 업데이트 완료",
                        type: "primary",
                    })
                } else {
                    openAlert({
                        title: "입금 확인",
                        body: "상태 업데이트 실패",
                        type: "warning",
                    })
                }
            })
            .catch(error => console.log(error))
    }

    /**현장 결제 처리  */
    const offlinePayment = async () => {
        try {
            const paymentResponse = await orderManage.offlinePayment(orderId as string)
            if (paymentResponse.status === "SUCCESS") {
                const updateResponse = await orderManage.upadteOrderStatus(orderId as string, 9)

                if (updateResponse.status === "SUCCESS") {
                    openAlert({
                        title: "현장 결제",
                        body: "현장 결제 완료",
                        type: "primary",
                    })
                    getOrderDetail()
                } else {
                    openAlert({
                        title: "현장 결제",
                        body: "현장 결제 실패",
                        type: "warning",
                    })
                }
            } else {
                openAlert({
                    title: "현장 결제",
                    body: "현장 결제 실패",
                    type: "warning",
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    const uploadFile = () => {
        const formData = new FormData()
        uploadFiles.map((item: any) => {
            formData.append("formData", item.object)
        })
        formData.append("linkKey", orderId as string)
        formData.append("linkInfo", "th_order")
        orderManage
            .uploadFileList("order", formData)
            .then(res => {
                if (res.status === "SUCCESS") {
                    openAlert({
                        title: "첨부파일",
                        body: "저장되었습니다",
                        type: "primary",
                    })
                    getOrderDetail()
                } else {
                    openAlert({
                        title: "실패",
                        body: "저장 실패.",
                        type: "warning",
                    })
                }
            })
            .catch(error => console.log(error))
    }

    const downloadFile = (url: string) => {
        const link = document.createElement("a")
        link.href = url
        link.download = url.substring(url.lastIndexOf("/") + 1)
        link.target = "_blank"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const deleteAttachFiles = (id: number) => {
        orderManage
            .deleteFile("order", id)
            .then(res => {
                if (res.status === "SUCCESS") {
                    setAttatchFiles(attatchFiles.filter((file: any) => file.fileId !== id))
                    openAlert({
                        title: "첨부파일",
                        body: "삭제되었습니다",
                        type: "primary",
                    })
                } else {
                    openAlert({
                        title: "실패",
                        body: "삭제 실패.",
                        type: "warning",
                    })
                }
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getOrderDetail()
        getPromotion()
    }, [])

    return (
        <>
            <FlexBetween
                alignItems="center"
                gap="5px"
            >
                <PageHeader
                    title="주문 관리"
                    subTitle="상세 내역"
                />
                <FlexBetween
                    alignItems="center"
                    gap="5px"
                >
                    <Button
                        disabled={isPreviousButtonDisabled}
                        size="small"
                        type="primary"
                        onClick={() => setModalOpen({ ...modalDefault, isStatusEditOpen: true })}
                    >
                        ← 이전 상태
                    </Button>
                    <Button
                        disabled={isNextButtonDisabled}
                        size="small"
                        type="primary"
                        onClick={handleNextButtonClick}
                    >
                        다음 상태 →
                    </Button>
                </FlexBetween>
            </FlexBetween>

            <FlexBetween>
                {/**주문 정보 */}
                <OrderDetailInfoForm
                    detailData={orderInfo as DetailOrderInfo}
                    setModalOpen={setModalOpen}
                    modalOpen={modalOpen}
                    bikeColor={bikeColor}
                    promotionInfo={promotionInfo}
                    paymentInfo={paymentInfo}
                    orderStatus={orderStatus as number}
                />
                {/**주문자 정보 */}
                <OrderDetailOrdererForm
                    detailData={orderInfo as DetailOrderInfo}
                    orderComment={orderComment}
                    getOrderDetail={getOrderDetail}
                    setModalOpen={setModalOpen}
                    modalOpen={modalOpen}
                    orderStatus={orderStatus as number}
                    orderCancel={orderCancel as OrderCancel}
                />
            </FlexBetween>
            {/** 주문 모델 정보 테이블 */}
            <Text
                as="p"
                fontSize="16px"
                margin="96px 1rem 0 0 "
                bold
            >
                주문 모델 정보
            </Text>

            <DataTable
                columns={Order.detailTableColumn}
                dataList={orderItemList}
            />
            {/** 결제 정보 테이블 */}
            <Text
                as="p"
                fontSize="16px"
                margin="96px 1rem 0 0 "
                bold
            >
                결제 정보
            </Text>
            <DataTable
                columns={Order.purchaseInfo}
                dataList={paymentInfo}
            />
            {/** 첨부파일 */}
            <FlexDiv
                alignItems="center"
                margin="30px 0"
            >
                <Text
                    display="inline-block"
                    fontSize="16px"
                    bold
                >
                    첨부 파일
                </Text>
                <RefreshBtn
                    disabled={isCancle}
                    onClick={() => {
                        setModalOpen({ ...modalDefault, fileEditMode: true })
                    }}
                >
                    {modalOpen.fileEditMode ? (
                        <Icon.Save
                            fill="white"
                            width={16}
                            height={16}
                        />
                    ) : (
                        <Icon.Edit
                            fill="white"
                            width={16}
                            height={16}
                        />
                    )}
                </RefreshBtn>
            </FlexDiv>

            <InsertValueDiv>
                <FlexColDiv>
                    {attatchFiles.length > 0
                        ? attatchFiles?.map((item: any) => {
                              return <ClipDiv onClick={() => downloadFile(item.s3Url)}>{item?.fileName}</ClipDiv>
                          })
                        : "첨부된 파일이 없습니다."}
                </FlexColDiv>
            </InsertValueDiv>
            {/**인도완료모달 */}

            <Modal
                type="confirm"
                title="인도 완료"
                width={"600px"}
                open={modalOpen.deliveryDtEditOpen}
                onClose={() => {
                    setModalOpen({ ...modalDefault, deliveryDtEditOpen: false })
                }}
                onConfirm={() => {
                    changeDeliveryDt()
                }}
            >
                <LabelForm label="인도일">
                    <CustomDatePicker width="100%">
                        <ReactDatePicker
                            selected={startDate}
                            onChange={(date: any) => setStartDate(date)}
                            locale={ko} // 한글로 변경
                            selectsStart
                            dateFormat="yyyy.MM.dd (eee)" // 시간 포맷 변경
                            showPopperArrow={false} // 화살표 변경
                            customInput={<DataPickerInput />}
                        />
                    </CustomDatePicker>
                </LabelForm>
            </Modal>
            {/**현장 결제  모달 */}
            <Modal
                type="confirm"
                title="현장 결제"
                width={"600px"}
                open={modalOpen.isPaymentOpen}
                onClose={() => {
                    setModalOpen({ ...modalDefault, isPaymentOpen: false })
                }}
                onConfirm={() => {
                    offlinePayment()
                }}
            >
                <OrderPaymentModal />
            </Modal>
            {/**예약금 단건 환불  모달 */}
            <Modal
                type="confirm"
                title="예약금 단건 환불"
                width={"600px"}
                open={modalOpen.isDepositOpen}
                onClose={() => {
                    setModalOpen({ ...modalDefault, isDepositOpen: false })
                }}
                onConfirm={() => {
                    cancelOrder()
                }}
            >
                <OrderCancelModal
                    orderId={orderId as string}
                    setCancelParam={setCancelParam}
                    cancelParam={cancelParam}
                    paymentInfo={paymentInfo}
                    isVbank={isVbank}
                    setIsVbank={setIsVbank}
                    isDeposit={true}
                />
            </Modal>
            {/**첨부파일 모달 */}
            <Modal
                type="confirm"
                title="첨부파일"
                open={modalOpen.fileEditMode}
                width={"600px"}
                onClose={() => {
                    setModalOpen({ ...modalDefault, fileEditMode: false })
                }}
                onConfirm={() => {
                    uploadFiles.length > 0 && uploadFile()
                }}
            >
                <>
                    <Text
                        as={"p"}
                        bold
                    >
                        첨부파일 추가 - 파일을 드래그 혹은 클릭해서 첨부 해 주세요
                    </Text>
                    <OrderDragAndDropFile
                        files={uploadFiles}
                        setFiles={setUploadFiles}
                        defaultData={attatchFiles}
                        deleteAttachFiles={deleteAttachFiles}
                    />
                </>
            </Modal>

            {/**주문 취소  모달 */}
            <Modal
                type="confirm"
                title="주문 취소"
                open={modalOpen.isCancleOpen}
                width={"600px"}
                onClose={() => {
                    setModalOpen({ ...modalDefault, isCancleOpen: false })
                }}
                footer={
                    <>
                        <Button
                            type="secondary"
                            onClick={() => {
                                setModalOpen({ ...modalDefault, isCancleOpen: false })
                            }}
                        >
                            취소
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => cancelOrder()}
                        >
                            확인
                        </Button>
                    </>
                }
            >
                <OrderCancelModal
                    orderId={orderId as string}
                    setCancelParam={setCancelParam}
                    cancelParam={cancelParam}
                    paymentInfo={paymentInfo}
                    isVbank={isVbank}
                    setIsVbank={setIsVbank}
                />
            </Modal>

            {/**이전상태 수정  모달 */}
            <Modal
                type="confirm"
                title="상태수정"
                open={modalOpen.isStatusEditOpen}
                onConfirm={() => changeOrderStatus(previousStatus)}
                width={"600px"}
                onClose={() => {
                    setModalOpen({ ...modalDefault, isStatusEditOpen: false })
                }}
            >
                <OrderEditStatusForm
                    paymentInfo={paymentInfo}
                    setPreviousStatus={setPreviousStatus}
                    orderStatus={orderStatus as number}
                />
            </Modal>

            {/**주문자 정보 수정  모달 */}
            <Modal
                type="confirm"
                title="주문자 정보 수정"
                open={modalOpen.isEditOpen}
                width={"600px"}
                onClose={() => {
                    setModalOpen({ ...modalDefault, isEditOpen: false })
                }}
                footer={
                    <>
                        <Button
                            type="secondary"
                            onClick={() => setModalOpen({ ...modalDefault, isEditOpen: false })}
                        >
                            취소
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                updateCustomer()
                            }}
                        >
                            확인
                        </Button>
                    </>
                }
            >
                <OrderPurchaserForm
                    orderId={orderId as string}
                    detailData={orderInfo as DetailOrderInfo}
                    param={customerParam as CustomerParam}
                    setParam={setCustomerParam}
                />
            </Modal>

            {/**주문모델 정보  모달 */}
            <Modal
                type="confirm"
                title="주문모델 정보 수정"
                open={modalOpen.isOrderEditOpen}
                width={"500px"}
                onClose={() => {
                    setModalOpen({ ...modalDefault, isOrderEditOpen: false })
                    resetSubsidyList()
                }}
                footer={
                    <>
                        <Button
                            type="secondary"
                            onClick={() => {
                                setModalOpen({ ...modalDefault, isOrderEditOpen: false })
                                resetSubsidyList()
                            }}
                        >
                            취소
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                updateOrderOptions()
                            }}
                        >
                            확인
                        </Button>
                    </>
                }
            >
                <OrderEditForm
                    orderInfoParam={orderInfoParam}
                    setSelectedOptions={setSelectedOptions}
                    orderResultData={orderResultData as unknown as OrderDetailInfo}
                    setOrderInfoParam={setOrderInfoParam}
                    selectedSubsidyList={selectedSubsidyList}
                    setSelectedSubsidyList={setSelectedSubsidyList}
                />
            </Modal>

            {/**프로모션 수정 모달 */}
            <Modal
                type="confirm"
                width={600}
                title="프로모션  수정"
                open={modalOpen.isPromotionEditOpen}
                onClose={() => {
                    setModalOpen({ ...modalDefault, isPromotionEditOpen: false })
                }}
                footer={
                    <>
                        <Button
                            type="secondary"
                            onClick={() => setModalOpen({ ...modalDefault, isPromotionEditOpen: false })}
                        >
                            취소
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                updatePromotion()
                            }}
                        >
                            확인
                        </Button>
                    </>
                }
            >
                <FlexColDiv gap="10px">
                    <LabelForm label="프로모션 이름">
                        <Input
                            readonly
                            defaultValue={monthlyPromotion[0]?.promotionName}
                        />
                    </LabelForm>
                    <LabelForm label="혜택">
                        <Select
                            defaultValue={promotionInfo?.modelId}
                            options={monthlyPromotion}
                            onChange={(label, value) => {
                                setSelectedPromotion(value as number)
                            }}
                        />
                    </LabelForm>
                </FlexColDiv>
            </Modal>
        </>
    )
}

export default OrderDetailPage

const modalDefault = {
    fileEditMode: false,
    isCancleOpen: false,
    isDepositOpen: false,
    isPaymentOpen: false,
    isStatusEditOpen: false,
    isEditOpen: false,
    isOrderEditOpen: false,
    isPromotionEditOpen: false,
    deliveryDtEditOpen: false,
}
