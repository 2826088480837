import React, { useState, useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router"

import { useRecoilState, useResetRecoilState } from "recoil"
import { requestParamState, reviewParamState } from "src/store/workRequest"

import InsertHeader from "../component/insertHeader"
import DetailReviewForm from "../component/detailReviewForm"
import Divider from "@components/widgets/Divider"
import { Button, Col, LabelForm, PageHeader, Row } from "p-ui"
import { Editor, Viewer } from "@toast-ui/react-editor"
import { FlexBetween, Text, TextAlignDiv, ViewerContainer } from "@styles"
import colorSyntax from "@toast-ui/editor-plugin-color-syntax"
import "@toast-ui/editor/dist/toastui-editor-viewer.css"
import "@toast-ui/editor/dist/i18n/ko-kr"
import "@toast-ui/editor/dist/toastui-editor.css"
import "tui-color-picker/dist/tui-color-picker.css"
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css"

import REQUEST_TASK from "src/apis/reqTask"

import { getManagerId, getManagerName, getOrgName } from "@common/util"
import useAlert from "@context/alert/useAlert"

import { ReqTask } from "@type/responseType"
import { RequestParamType } from "@type/formType"
import { Type } from "p-ui"

const RequestDetailPage = () => {
    const location = useLocation()
    const managerId = getManagerId()
    const orgName = getOrgName()
    const navigate = useNavigate()

    const { openAlert } = useAlert()

    const id = new URLSearchParams(location.search).get("id") as string
    const [manager, setManager] = useState(false)
    const [isIssue, setIsIssue] = useState(true)
    const [managerIdList, setManagerIdList] = useState<Type.OptionGroupType[]>([])
    const [requestMode, setRequestMode] = useState("detail")
    const [reviewMode, setReviewMode] = useState("detail")
    const [requestData, setRequestData] = useState<ReqTask>()
    const [reqIssue, setReqIssue] = useState<string | any>("")
    const [reqDesc, setReqDesc] = useState<string | any>("")
    const [requestParam, setRequestParam] = useState<RequestParamType>({
        reqTitle: "",
        reqOrgNm: getOrgName() as string,
        reqUserNm: getManagerName() as string,
        taskOrgNm: "시스템개발",
        serviceCode: 0,
        priority: 1,
        reqType: 0,
        reqDesc: "",
        reqIssue: "",
        reqManager: Number(getManagerId()),
    })
    const [reviewParam, setReviewParam] = useRecoilState(reviewParamState)

    const resetReviewParam = useResetRecoilState(reviewParamState)
    const resetRequsetParam = useResetRecoilState(requestParamState)

    const requsetEditorRef = useRef<Editor>(null)
    const isuueEditorRef = useRef<Editor>(null)

    const validation = (type: string) => {
        const reviewForm = reviewParam
        const requestForm = requestParam
        const form = type == "review" ? reviewForm : requestForm
        const RequestFormFields: string[] = [
            "reqTitle",
            "reqType",
            "serviceCode",
            "reqUserNm",
            "reqOrgNm",
            "priority",
            "reqDesc",
        ]
        const ReviewFormFields: string[] = ["completionDt", "taskOrgNm", "taskStatus", "taskDesc"]

        const formFields = type == "review" ? ReviewFormFields : RequestFormFields
        const RequestMessage: { [key: string]: string } = {
            priority: "긴급여부를 선택 해 주세요",
            reqDesc: "요청사항을 입력 해 주세요",
            reqUserNm: "요청자를 입력 해 주세요",
            reqOrgNm: "요청 부서를 선택 해 주세요",
            gender: "성별을 입력해주세요",
            reqTitle: "제목을 입력 해 주세요",
            reqType: "개발 유형을 선택 해 주세요",
            serviceCode: "요청 서비스를 선택 해 주세요",
        }

        const ReviewMessage: { [key: string]: string } = {
            completionDt: "예상 완료 일자를 선택 해주세요",
            taskDesc: "검토의견을 입력 해 주세요",
            taskOrgNm: "담당부서를 선택 해 주세요",
            taskStatus: "업무 상태를 선택 해 주세요",
        }
        const message = type == "review" ? ReviewMessage : RequestMessage
        // check valid
        const field = formFields.find(field => !form[field])
        if (field) {
            // action
            alert(message[field])
            return
        } else {
            updateReqTask(type)
        }
    }

    /**업무요청 단건 조회 함수 */
    const getOneReqTask = async () => {
        await REQUEST_TASK.getOneReqTask(id).then(res => {
            if (res.status === "SUCCESS") {
                setRequestData(res.resultData)
                setReqIssue(res.resultData.reqIssue)
                setReqDesc(res.resultData.reqDesc)
                res.resultData.taskManager && setManager(true)
                !res.resultData.reqIssue && setIsIssue(false)
            }
        })
    }

    const getManagerIdList = async () => {
        await REQUEST_TASK.getManagerIdList().then(res => {
            if (res.status === "SUCCESS") {
                let managerList: Type.OptionGroupType[] = []
                res.resultData.map((item: any) => {
                    managerList.push({ label: `${item.orgNm} / ${item.managerNm}`, value: item.managerId })
                })
                setManagerIdList(managerList)
            }
        })
    }
    /**업무요청 수정 함수 */
    const updateReqTask = async (type: string) => {
        let updateTaskparam = {
            ...requestParam,
            reqId: requestData?.reqId,
        }
        let updateReviewParam = {
            ...requestData,
            ...reviewParam,
        }
        let param = type === "request" ? updateTaskparam : updateReviewParam

        await REQUEST_TASK.updateReqTask(param).then(res => {
            if (res.status === "SUCCESS") {
                setReviewMode("detail")
                setRequestMode("detail")
                openAlert({ title: "저장", body: "업무요청이 저장되었습니다.", type: "primary" })

                getOneReqTask()
            }
        })
    }

    /**업무요청 수정 버튼 클릭 함수 */
    const requestEdit = () => {
        if (requestData) {
            if (Number(managerId) !== requestData.reqManager) {
                return openAlert({ title: "실패", body: "권한이 없습니다.", type: "warning" })
            } else {
                setRequestMode("edit")
            }
        }
    }

    /**업무요청 수정  저장 함수 */
    const requestSave = () => {
        validation("request")
    }

    /**업무요청 검토 수정 버튼 클릭 함수 */
    const reviewEdit = () => {
        if (orgName !== "시스템개발") {
            return openAlert({ title: "실패", body: "권한이 없습니다.", type: "warning" })
        } else {
            setReviewMode("edit")
        }
    }

    /**업무요청 검토 저장 함수 */
    const reviewSave = () => {
        validation("review")
        /**이거 등록인지 수정인지 구분하는거 필요함 */
    }

    useEffect(() => {
        requestMode === "edit" && setRequestParam({ ...(requestData as unknown as RequestParamType) })
    }, [requestMode])

    useEffect(() => {
        setRequestParam({ ...requestParam, reqIssue: reqIssue })
    }, [reqIssue])
    useEffect(() => {
        setRequestParam({ ...requestParam, reqDesc: reqDesc })
    }, [reqDesc])

    useEffect(() => {
        getOneReqTask()
        getManagerIdList()
        resetReviewParam()
        resetRequsetParam()
    }, [])
    return (
        <>
            <PageHeader
                title="업무요청"
                subTitle="업무요청 상세"
            />
            <FlexBetween margin="0 0 10px 0">
                <Text
                    bold={true}
                    fontSize="18px"
                >
                    업무 요청서
                </Text>
                {requestMode === "detail" ? (
                    <Button
                        onClick={requestEdit}
                        type="primary"
                        size="small"
                    >
                        수정
                    </Button>
                ) : (
                    <Button
                        onClick={requestSave}
                        type="primary"
                        size="small"
                    >
                        저장
                    </Button>
                )}
            </FlexBetween>
            <InsertHeader
                mode={requestMode}
                requestData={requestData}
                requestParam={requestParam}
                setRequestParam={setRequestParam}
            />
            <Row
                gap={10}
                marginBottom={30}
            >
                {reqIssue && (
                    <>
                        <Col sm={6}>
                            <LabelForm
                                required
                                label="요청사항"
                                labelCol={1.5}
                                formCol={10.5}
                            >
                                <TextAlignDiv align="left">
                                    {requestMode === "detail" ? (
                                        <ViewerContainer>
                                            <div className="viewer-content">
                                                <Viewer initialValue={reqDesc ? reqDesc?.toString() : ""}></Viewer>
                                            </div>
                                        </ViewerContainer>
                                    ) : (
                                        <Editor
                                            ref={requsetEditorRef}
                                            initialValue={reqDesc}
                                            plugins={[colorSyntax]}
                                            previewStyle="vertical"
                                            height="600px"
                                            initialEditType="wysiwyg"
                                            useCommandShortcut={true}
                                            language="ko-KR"
                                            onChange={() =>
                                                setReqDesc(requsetEditorRef?.current?.getInstance().getHTML())
                                            }
                                        />
                                    )}
                                </TextAlignDiv>
                            </LabelForm>
                        </Col>
                        <Col sm={6}>
                            <LabelForm
                                label="문제사항"
                                labelCol={1.5}
                                formCol={10.5}
                            >
                                <TextAlignDiv align="left">
                                    {requestMode === "detail" ? (
                                        <ViewerContainer>
                                            <div className="viewer-content">
                                                <Viewer initialValue={reqIssue ? reqIssue?.toString() : ""}></Viewer>
                                            </div>
                                        </ViewerContainer>
                                    ) : (
                                        <Editor
                                            ref={isuueEditorRef}
                                            initialValue={reqIssue}
                                            plugins={[colorSyntax]}
                                            previewStyle="vertical"
                                            height="600px"
                                            initialEditType="wysiwyg"
                                            useCommandShortcut={true}
                                            language="ko-KR"
                                            onChange={() =>
                                                setReqIssue(isuueEditorRef?.current?.getInstance().getHTML())
                                            }
                                        />
                                    )}
                                </TextAlignDiv>
                            </LabelForm>
                        </Col>
                    </>
                )}
                {!isIssue && (
                    <Col sm={12}>
                        <LabelForm
                            label="요청사항"
                            labelCol={1.5}
                            formCol={10.5}
                        >
                            <TextAlignDiv align="left">
                                {requestMode === "detail" ? (
                                    <ViewerContainer>
                                        <div className="viewer-content">
                                            <Viewer initialValue={reqDesc ? reqDesc.toString() : ""}></Viewer>
                                        </div>
                                    </ViewerContainer>
                                ) : (
                                    <Editor
                                        ref={requsetEditorRef}
                                        initialValue={reqDesc}
                                        plugins={[colorSyntax]}
                                        previewStyle="vertical"
                                        height="300px"
                                        initialEditType="wysiwyg"
                                        useCommandShortcut={true}
                                        language="ko-KR"
                                        onChange={() => setReqDesc(requsetEditorRef.current?.getInstance().getHTML())}
                                    />
                                )}
                            </TextAlignDiv>
                        </LabelForm>
                    </Col>
                )}
            </Row>
            <Divider />
            <FlexBetween margin="0 0 10px 0">
                <Text
                    bold={true}
                    fontSize="18px"
                >
                    업무 검토서
                </Text>
                {manager &&
                    (reviewMode == "detail" ? (
                        <Button
                            onClick={reviewEdit}
                            type="primary"
                            size="small"
                        >
                            수정
                        </Button>
                    ) : (
                        <Button
                            onClick={reviewSave}
                            type="primary"
                            size="small"
                        >
                            저장
                        </Button>
                    ))}
            </FlexBetween>
            <DetailReviewForm
                mode={reviewMode}
                requestData={requestData}
                manager={manager}
                setManager={setManager}
                setMode={setReviewMode}
                getOneReqTask={getOneReqTask}
                managerIdList={managerIdList}
            />
        </>
    )
}

export default RequestDetailPage
