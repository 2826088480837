import React, { useEffect, useMemo, useRef, useState } from "react"
import { CommonDiv, FlexColDiv, FlexDiv, Label, Text } from "@styles"
import { Button, CheckBox, Col, DataTable, Icon, Input, PageHeader, Row, Select, Tag, Tooltip } from "p-ui"
import useAlert from "@context/alert/useAlert"
import { AssetAllBike, FirmwareListALL } from "@type/responseType"
import { useNavigate } from "react-router"
import { onChangeFilter } from "src/utils/filter"
import { onChangeParam } from "p-ui/dist/esm/types/input"
import { getManagerId, observPage, updateActivePage } from "@common/util"
import { useSearchParams } from "react-router-dom"
import { PageRow } from "p-ui/dist/esm/types"
import { useDeleteFavoriteBike, useGetAllBikeList, useGetFavoriteBike } from "src/queries/assets"
import { ASSET_BIKE } from "@pages/Assets/constants"
import { IconWrapper, ListTab } from "../style"
import { useGetAllFirmList, useGetAllHardList } from "src/queries/firmhard"
import { QueryParams, SearchParamState } from "@pages/Assets/types"

const AssetManage = () => {
    const { openAlert } = useAlert()
    const navigate = useNavigate()
    const managerId = getManagerId()
    const tableRef = useRef<HTMLTableElement | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const queryParams: QueryParams = {
        page: searchParams?.get("page"),
        limit: searchParams?.get("limit"),
        searchText: searchParams?.get("searchText"),
        searchType: searchParams?.get("searchType"),
        firmware: {
            searchText: searchParams?.get("firmwareSearchText"),
            type: searchParams?.get("firmwareType"),
        },
        hardware: {
            searchText: searchParams?.get("hardwareSearchText"),
            type: searchParams?.get("hardwareType"),
        },
    }

    const [bikeList, setBikeList] = useState<AssetAllBike[]>([])
    const [favoriteBikeList, setfavoriteBikeList] = useState<AssetAllBike[]>([])

    const [pageRows, setPageRows] = useState<PageRow>(15)
    const [nowPage, setNowPage] = useState<any>(queryParams.page !== null ? queryParams.page : 1)

    const [isFirmwareSearch, setIsFirmwareSearch] = useState<boolean>(false)
    const [isHardwareSearch, setIsHardwareSearch] = useState<boolean>(false)

    const [favoriteId, setFavoriteId] = useState<number | any>()
    const [currentTab, clickTab] = useState(0)
    const [selectedType, setSelectedType] = useState<string[]>(
        queryParams.searchType ? queryParams.searchType.split(",") : defaultSelectedType.concat("-1")
    )
    const [searchParamsState, setSearchParamsState] = useState<SearchParamState>({
        searchText: "",
        filterText: "",
        firmware: {
            type: "",
            searchText: "",
            filterText: "",
        },
        hardware: {
            type: "",
            searchText: "",
            filterText: "",
        },
    })

    /**전체 바이크 리스트 조회 */
    const { data: bikeListData, isFetching, isError, error } = useGetAllBikeList(managerId as unknown as number)
    const { refetch: deleteFavoriteBike } = useDeleteFavoriteBike(favoriteId)
    /**전체 펌웨어 리스트  조회 */
    const { data: firmwareListData } = useGetAllFirmList()
    /**전체 하드웨어 리스트  조회 */
    const { data: hardwareListData } = useGetAllHardList()
    const {
        data: favolistListData,
        refetch: refetchFavoriteData,
        isFetching: fvFetching,
        isError: fvIsError,
        error: fvError,
    } = useGetFavoriteBike(managerId as unknown as number)

    const selectMenuHandler = (value: number) => {
        clickTab(value)
    }

    const firmSearchOptionList = useMemo(() => {
        return firmwareListData
            ? firmwareListData
                  ?.filter((item: any) => searchParamsState.firmware.type.includes(item.firmwareType?.toLowerCase()))
                  .map((item: FirmwareListALL) => {
                      return { label: item.version, value: item.version }
                  })
            : []
    }, [searchParamsState, firmwareListData])

    const hardSearchOptionList = useMemo(() => {
        return hardwareListData
            ? hardwareListData
                  ?.filter((item: any) => searchParamsState.hardware.type.includes(item.hardwareType?.toLowerCase()))
                  .map((item: FirmwareListALL) => {
                      return { label: item.version, value: item.version }
                  })
            : []
    }, [searchParamsState, hardwareListData])

    const convertList = (data: AssetAllBike[]) => {
        return data?.map(item => {
            return {
                ...item,
                favorite: (
                    <FlexDiv
                        onClick={() => {
                            setFavoriteId(item.favoriteId)
                        }}
                        justifyContent="center"
                    >
                        <IconWrapper>
                            <Icon
                                fill="#F9DE51"
                                icon={"StarFill"}
                            />
                        </IconWrapper>
                    </FlexDiv>
                ),
                hwVersion: item.hwEdcuVersion,
                hwEdcuVersion: (
                    <FlexDiv
                        width="100%"
                        gap="10px"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Tag
                            size="small"
                            type="primary"
                            value={"EDCU"}
                        />
                        <CommonDiv minWidth="100px"> {item.hwEdcuVersion ? item.hwEdcuVersion : "-"}</CommonDiv>
                    </FlexDiv>
                ),
                firmWareVirson: (
                    <FlexColDiv
                        alignItems="center"
                        justifyContent="center"
                        gap="5px"
                    >
                        <FlexDiv
                            width="100%"
                            gap="10px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Tag
                                size="small"
                                type="primary"
                                value={"EDCU"}
                            />
                            <CommonDiv minWidth="100px"> {item.edcuVersion}</CommonDiv>
                        </FlexDiv>
                        <FlexDiv
                            width="100%"
                            gap="10px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Tag
                                size="small"
                                type="success"
                                value={"MCU"}
                            />
                            <CommonDiv minWidth="100px">{item.mcuVersion}</CommonDiv>
                        </FlexDiv>
                        <FlexDiv
                            width="100%"
                            gap="10px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Tag
                                size="small"
                                type="danger"
                                value={"ICS"}
                            />
                            <CommonDiv minWidth="100px">{item.icsVersion}</CommonDiv>
                        </FlexDiv>
                    </FlexColDiv>
                ),
                detail: (
                    <Button
                        size="small"
                        type="tertiary"
                        onClick={() => {
                            navigate({ pathname: "/lab/bikedetail", search: "?serialNo=" + item.serialNo })
                        }}
                    >
                        상세보기
                    </Button>
                ),
            }
        })
    }

    const filterClear = () => {
        setSearchParamsState({
            searchText: "",
            filterText: "",
            firmware: {
                type: "edcuVersion",
                searchText: "",
                filterText: "",
            },
            hardware: {
                type: "edcuVersion",
                searchText: "",
                filterText: "",
            },
        })
        setSelectedType(defaultSelectedType.concat("-1"))
        setNowPage(1)
    }

    /**
     * queryString setting 함수
     */
    const filterByQueryString = () => {
        setSearchParams({
            filterText: searchParamsState.filterText,
            firmwareFilterText: searchParamsState.firmware.filterText,
            firmwareSearchText: searchParamsState.firmware.searchText,
            firmwareType: searchParamsState.firmware.type,
            hardwareFilterText: searchParamsState.hardware.filterText,
            hardwareSearchText: searchParamsState.hardware.searchText,
            hardwareType: searchParamsState.hardware.type,
            searchType: selectedType.toString(),
            searchText: searchParamsState.searchText,
            page: nowPage,
            limit: pageRows.toString(),
        })
    }

    /**
     * 검색 및 상태값에 따른 List Filter
     * @returns filterBikeList
     */
    const filterData = useMemo(() => {
        let filteredResults = bikeList

        if (selectedType.length === 0) {
            return []
        }

        if (searchParamsState.firmware.filterText !== "") {
            filteredResults = filteredResults?.filter(item => {
                return searchParamsState.firmware.type
                    ? item[searchParamsState.firmware.type]
                          ?.toLowerCase()
                          ?.includes(searchParamsState.firmware.filterText?.toLowerCase()?.split(" ").join(""))
                    : true
            })
        }
        if (searchParamsState.hardware.filterText !== "") {
            filteredResults = filteredResults?.filter(item => {
                return searchParamsState.hardware.type
                    ? item["hwVersion"]
                          ?.toLowerCase()
                          ?.includes(searchParamsState.hardware.filterText?.toLowerCase()?.split(" ").join(""))
                    : true
            })
        }
        // 검색 필터
        if (searchParamsState.filterText !== "") {
            filteredResults = filteredResults?.filter(item =>
                JSON.stringify(item)
                    ?.toLowerCase()
                    ?.includes(searchParamsState.filterText?.toLowerCase()?.split(" ").join(""))
            )
        }

        // 다른 필터
        if (!selectedType.includes(-1 as unknown as string)) {
            filteredResults = filteredResults?.filter(item => selectedType.includes(item.goodsType))
        }

        return filteredResults
    }, [selectedType, bikeList, searchParamsState, isFirmwareSearch, isHardwareSearch])

    const handleFetchResult = (
        fetching: boolean,
        isError: boolean,
        error: any,
        dataList: any[],
        setter: (data: any[]) => void
    ) => {
        /**실패시 */
        if (!fetching && isError) {
            openAlert({ title: "실패", body: error.message, type: "warning" })
        }
        /**성공시 */
        if (!fetching && !isError) {
            setter(convertList(dataList as AssetAllBike[]))
        }
    }

    useEffect(() => {
        handleFetchResult(isFetching, isError, error, bikeListData, setBikeList)
    }, [isFetching, isError, error, bikeListData])

    useEffect(() => {
        handleFetchResult(fvFetching, fvIsError, fvError, favolistListData, setfavoriteBikeList)
    }, [fvFetching, fvIsError, fvError, favolistListData])

    useEffect(() => {
        favoriteId &&
            deleteFavoriteBike().then(res => {
                if (res.status == "success") {
                    openAlert({
                        title: "성공",
                        body: "즐겨찾는 바이크가 해제되었습니다.",
                        type: "primary",
                    })
                    refetchFavoriteData()
                } else {
                    openAlert({ title: "실패", body: "수정 실패.", type: "warning" })
                }
            })
    }, [favoriteId])

    useEffect(() => {
        const observPageCallback = () => {
            const newActivePage = updateActivePage(tableRef.current)

            if (newActivePage === 0) {
                return setNowPage(Number(queryParams.page))
            }

            setNowPage(newActivePage)
        }

        return observPage(observPageCallback, tableRef)
    }, [tableRef, nowPage])

    useEffect(() => {
        filterByQueryString()
    }, [bikeList, selectedType, nowPage, pageRows, searchParamsState])

    useEffect(() => {
        if (queryParams.limit) {
            setPageRows(Number(queryParams.limit) as unknown as PageRow)
        }
        if (queryParams.searchText) {
            setSearchParamsState(prevState => ({
                ...prevState,
                searchText: queryParams.searchText as string,
                filterText: queryParams.searchText as string,
            }))
        }
        if (queryParams.firmware.searchText) {
            setSearchParamsState(prevState => ({
                ...prevState,
                firmware: {
                    ...prevState.firmware,
                    searchText: queryParams.firmware.searchText as string,
                    filterText: queryParams.firmware.searchText as string,
                },
            }))
        }
        if (queryParams.firmware.type) {
            setSearchParamsState(prevState => ({
                ...prevState,
                firmware: {
                    ...prevState.firmware,
                    type: queryParams.firmware.type as string,
                },
            }))
        } else {
            setSearchParamsState(prevState => ({
                ...prevState,
                firmware: {
                    ...prevState.firmware,
                    type: "edcuVersion",
                },
            }))
        }

        if (queryParams.hardware.searchText) {
            setSearchParamsState(prevState => ({
                ...prevState,
                hardware: {
                    ...prevState.hardware,
                    searchText: queryParams.hardware.searchText as string,
                    filterText: queryParams.hardware.searchText as string,
                },
            }))
        }
        if (queryParams.hardware.type) {
            setSearchParamsState(prevState => ({
                ...prevState,
                hardware: {
                    ...prevState.hardware,
                    type: queryParams.hardware.type as string,
                },
            }))
        } else {
            setSearchParamsState(prevState => ({
                ...prevState,
                hardware: {
                    ...prevState.hardware,
                    type: "edcuVersion",
                },
            }))
        }
    }, [])

    /**
     * 상세보기 추가해주는 함수
     * @param data
     * @returns
     */

    return (
        <div>
            <PageHeader
                title="자산관리"
                subTitle="전체 이륜차 목록"
            />
            <ListTab>
                <span
                    className={0 === currentTab ? "menu focused" : "menu"}
                    onClick={() => selectMenuHandler(0)}
                >
                    즐겨찾기
                </span>
                <span
                    className={1 === currentTab ? "menu focused" : "menu"}
                    onClick={() => selectMenuHandler(1)}
                >
                    바이크 목록
                </span>
            </ListTab>
            <Text
                as={"h2"}
                fontSize="24px"
                fontWeight={700}
                margin="36px 0"
            >
                {currentTab == 0 ? "즐겨찾기 목록" : "바이크 목록"}
            </Text>
            {currentTab == 0 ? (
                <div>
                    {favoriteBikeList?.length > 0 ? (
                        <DataTable
                            columns={ASSET_BIKE.favoriteBikeCoulmn}
                            dataList={favoriteBikeList}
                        />
                    ) : (
                        <FlexColDiv
                            margin="60px 0"
                            alignItems="center"
                            justifyContent="center"
                            gap="36px"
                        >
                            <Text fontSize="32px">
                                <Icon
                                    width={32}
                                    height={32}
                                    icon={"Star"}
                                    fill="#7c7b7b"
                                />
                            </Text>
                            <Text
                                fontSize="16px"
                                textAlign="center"
                                lineHeigt="1.5"
                            >
                                즐겨찾는 바이크가 없습니다.
                                <br />
                                {`바이크 목록 > 상세보기 > 별표를 눌러 즐켜찾는 바이크를 추가해 보세요`}
                            </Text>
                        </FlexColDiv>
                    )}
                </div>
            ) : (
                <div>
                    <Row gap={20}>
                        <Col sm={1}>
                            <Label>검색</Label>
                        </Col>
                        <Col sm={11}>
                            <FlexDiv
                                gap="10px"
                                alignItems="center"
                            >
                                <Input
                                    size="small"
                                    width={300}
                                    value={searchParamsState.searchText}
                                    onChange={(e: onChangeParam) => {
                                        setSearchParamsState(prevState => ({
                                            ...prevState,
                                            searchText: e.value.toString(),
                                        }))
                                    }}
                                    onSearch={() => {
                                        setNowPage(1)
                                        setSearchParamsState(prevState => ({
                                            ...prevState,
                                            filterText: searchParamsState.searchText,
                                        }))
                                    }}
                                />
                            </FlexDiv>
                        </Col>
                        <Col sm={1}>
                            <Label>펌웨어</Label>
                        </Col>
                        <Col sm={11}>
                            <FlexDiv
                                gap="10px"
                                alignItems="center"
                            >
                                <Select
                                    width={90}
                                    size="small"
                                    onChange={(label, value) =>
                                        setSearchParamsState(prevState => ({
                                            ...prevState,
                                            firmware: {
                                                ...prevState.firmware,
                                                type: value.toString(),
                                                filterText: "",
                                            },
                                        }))
                                    }
                                    defaultValue={searchParamsState.firmware.type}
                                    options={ASSET_BIKE.firmType}
                                />
                                <Select
                                    size="small"
                                    options={firmSearchOptionList}
                                    defaultValue={searchParamsState.firmware.filterText}
                                    onChange={(label, value) => {
                                        setNowPage(1)
                                        setSearchParamsState(prevState => ({
                                            ...prevState,
                                            firmware: {
                                                ...prevState.firmware,
                                                searchText: value.toString(),
                                                filterText: value.toString(),
                                            },
                                        }))
                                        setIsFirmwareSearch(!isFirmwareSearch)
                                    }}
                                />
                            </FlexDiv>
                        </Col>
                        <Col sm={1}>
                            <Label>하드웨어</Label>
                        </Col>
                        <Col sm={11}>
                            <FlexDiv
                                gap="10px"
                                alignItems="center"
                            >
                                <Select
                                    width={90}
                                    size="small"
                                    onChange={(label, value) =>
                                        setSearchParamsState(prevState => ({
                                            ...prevState,
                                            hardware: {
                                                ...prevState.hardware,
                                                type: value.toString(),
                                                filterText: "",
                                            },
                                        }))
                                    }
                                    defaultValue={searchParamsState.hardware.type}
                                    options={ASSET_BIKE.hardType}
                                />
                                <Select
                                    size="small"
                                    options={hardSearchOptionList}
                                    defaultValue={searchParamsState.hardware.filterText}
                                    onChange={(label, value) => {
                                        setNowPage(1)
                                        setIsHardwareSearch(!isHardwareSearch)
                                        setSearchParamsState(prevState => ({
                                            ...prevState,
                                            hardware: {
                                                ...prevState.hardware,
                                                searchText: value.toString(),
                                                filterText: value.toString(),
                                            },
                                        }))
                                    }}
                                />
                            </FlexDiv>
                        </Col>
                        <Col sm={1}>
                            <Label>분류</Label>
                        </Col>
                        <Col sm={11}>
                            <FlexDiv
                                alignItems="center"
                                gap="15px"
                            >
                                <CheckBox.Group
                                    options={GoodsTypeOptions}
                                    value={selectedType}
                                    onChange={(value: any) => {
                                        const param = {
                                            clickedFilter: selectedType,
                                            checkList: value as [],
                                            defaultFilter: defaultSelectedType,
                                            setClickedFilter: setSelectedType,
                                            allValue: "-1",
                                        }
                                        onChangeFilter(param)
                                        setNowPage(1)
                                    }}
                                />
                                <Button
                                    size="small"
                                    type="default"
                                    onClick={() => filterClear()}
                                >
                                    초기화
                                </Button>
                            </FlexDiv>
                        </Col>
                    </Row>

                    <div ref={tableRef}>
                        <DataTable
                            pagination
                            numbering
                            headerLeft={
                                <FlexDiv gap="5px">
                                    <Tooltip
                                        content="검색 결과 수 / 전체 바이크 수"
                                        placement="top"
                                    >
                                        <Text
                                            lineHeigt="2"
                                            color="var(--success)"
                                            fontWeight={"bold"}
                                        >
                                            {filterData?.length}
                                        </Text>

                                        <Text
                                            lineHeigt="2"
                                            color="var(--primary)"
                                            fontWeight={"bold"}
                                        >
                                            / {bikeList?.length}
                                        </Text>
                                    </Tooltip>
                                </FlexDiv>
                            }
                            columns={ASSET_BIKE.bikeTableCoulum}
                            dataList={filterData}
                            pageRow={pageRows}
                            getPageRow={row => {
                                if (row === 0) {
                                    setPageRows(Number(queryParams.limit) as unknown as PageRow)
                                    return
                                } else {
                                    setPageRows(Number(row) as PageRow)
                                }
                            }}
                            nowPage={nowPage}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default AssetManage

const GoodsTypeOptions = [
    { label: "전체", value: "-1" },
    { label: "일반", value: "일반" },
    { label: "렌탈", value: "렌탈" },
    { label: "대차", value: "대차" },
]

const defaultSelectedType = ["일반", "렌탈", "대차"]
