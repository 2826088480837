import React, { useEffect, useState } from "react"

import styled from "styled-components"
import { TableForm, TableFormLabel, TableFormValue, Button, Icon, Tooltip as PuiTooltip } from "p-ui"

import { membershipStatus } from "../constants"

import { MembershipInfo as MembershipInfoType } from "@type/membershipType"
import { ContentButtonWrap } from "../detail"

interface MembershipInfoProps {
    setOpenMembershipModal: (item: boolean) => void
    setOpenChangeModal: (item: boolean) => void
    setOpenDistanceModal: (item: boolean) => void
    setOpenPaymentModal: (item: boolean) => void
    setOpenOfflinePaymentModal: (item: boolean) => void
    setOpenRefundPaymentModal: (item: boolean) => void
    setOpenMembershipStatusModal: (item: boolean) => void
    setOpenPromotionModal: (item: boolean) => void
    setOpenSusPendHistoryModal: (item: boolean) => void
    membershipInfo: MembershipInfoType | undefined
    isShowSuspendButton: boolean | undefined
    getMile: () => void
}

const MembershipInfo = (props: MembershipInfoProps) => {
    const {
        setOpenChangeModal,
        setOpenSusPendHistoryModal,
        setOpenDistanceModal,
        setOpenPaymentModal,
        setOpenOfflinePaymentModal,
        setOpenRefundPaymentModal,
        setOpenMembershipStatusModal,
        setOpenPromotionModal,
        membershipInfo,
        isShowSuspendButton,
        getMile,
    } = props
    const [checkPaymentTime, setCheckPaymentTime] = useState<boolean>(false)
    const [statusId, setStatusId] = useState<number>(membershipInfo?.statusId as number)
    const [failCount, setFailCount] = useState<number>(0)
    const [toolTip, setToolTip] = useState<boolean>(false)
    let color = ""

    switch (statusId) {
        case 0:
            color = "--danger"
            break
        case 1:
            color = "--success"
            break
        case 2:
            color = "--zen-orange"
            break
        case 3:
            color = "--warning"
            break
    }

    useEffect(() => {
        setStatusId(membershipInfo?.statusId as number)
        setFailCount(membershipInfo?.failCount as number)

        if (membershipInfo && membershipInfo.paidDt) {
            const paidDt = new Date(membershipInfo.paidDt)
            const currentDate = new Date()
            paidDt.setHours(10, 0, 0, 0)

            const isAfter10AM = currentDate.getTime() >= paidDt.getTime()
            if (isAfter10AM) {
                setCheckPaymentTime(true)
            }
        }
    }, [membershipInfo])

    return (
        <div>
            <div className="flex-between">
                <div className="button-wrap">
                    <h3>현 멤버십 정보 </h3>
                    {isShowSuspendButton && (
                        <PuiTooltip
                            content="일시정지 히스토리 이력입니다."
                            placement="top"
                        >
                            <Button
                                compact
                                size="small"
                                onClick={() => {
                                    setOpenSusPendHistoryModal(true)
                                }}
                            >
                                <Icon.Document
                                    width={"15px"}
                                    height={"15px"}
                                />
                            </Button>
                        </PuiTooltip>
                    )}
                </div>
                <div className="button-wrap">
                    <Button
                        // disabled={(membershipInfo?.statusId as number) !== 1}
                        type="primary"
                        size="small"
                        onClick={() => {
                            setOpenMembershipStatusModal(true)
                        }}
                    >
                        상태변경
                    </Button>
                    {statusId == 1 && (
                        <>
                            <Button
                                disabled={(membershipInfo?.statusId as number) !== 1 || membershipInfo?.merCnt == 1}
                                type="warning"
                                size="small"
                                onClick={() => {
                                    setOpenRefundPaymentModal(true)
                                }}
                            >
                                환불
                            </Button>
                            <Button
                                disabled={(membershipInfo?.statusId as number) !== 1}
                                type="accent"
                                size="small"
                                onClick={() => {
                                    setOpenOfflinePaymentModal(true)
                                }}
                            >
                                현장결제
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <TableForm>
                <TableFormLabel sm={2}>멤버십명</TableFormLabel>
                <TableFormValue sm={10}>
                    <div className="flex-between">
                        <span>{membershipInfo?.currentModelName}</span>
                        <div className="button-wrap">
                            <Button
                                disabled={(membershipInfo?.statusId as number) !== 1}
                                onClick={() => {
                                    setOpenChangeModal(true)
                                }}
                                type="primary"
                                size="small"
                            >
                                변경
                            </Button>
                            {checkPaymentTime &&
                                ((membershipInfo?.statusId as number) == 1 ||
                                    (membershipInfo?.statusId as number) == 2) && (
                                    <Button
                                        onClick={() => {
                                            setOpenPaymentModal(true)
                                        }}
                                        type="danger"
                                        size="small"
                                    >
                                        결제시도
                                    </Button>
                                )}
                        </div>
                    </div>
                </TableFormValue>
                <TableFormLabel sm={2}>구독 상태</TableFormLabel>
                <TableFormValue sm={4}>
                    <Text color={`var(${color})`}>{membershipStatus[`${statusId}`]}</Text>
                    {failCount > 0 && (
                        <>
                            <Text color="black"> - </Text>
                            <Text color="red">{`    결제실패(${failCount})`}</Text>
                        </>
                    )}
                </TableFormValue>
                <TableFormLabel sm={2}>다음 결제일</TableFormLabel>
                <TableFormValue sm={4}>
                    {statusId !== 0 ? `${membershipInfo?.paidDt}  (${membershipInfo?.nextModelName})` : "-"}
                </TableFormValue>
                <TableFormLabel sm={2}>예상 요금</TableFormLabel>
                <TableFormValue sm={4}>
                    {statusId !== 0 ? `${membershipInfo?.amount.toLocaleString()} 원` : "-"}
                </TableFormValue>
                <TableFormLabel sm={2}>총 결제 횟수</TableFormLabel>
                <TableFormValue sm={4}>{statusId !== 0 ? `${membershipInfo?.merCnt} / 24` : "-"}</TableFormValue>
                <TableFormLabel sm={2}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span> 주행거리</span>
                        <ToolTip
                            onMouseOut={() => {
                                setToolTip(false)
                            }}
                            onMouseOver={() => {
                                setToolTip(true)
                            }}
                        >
                            <Icon.Info width={"1rem"} />
                            {toolTip && <span>멤버십 이용기간 내 </span>}
                        </ToolTip>
                    </div>
                </TableFormLabel>
                <TableFormValue sm={10}>
                    <div className="flex-between">
                        <span>{Number(membershipInfo?.totalMile).toLocaleString()}km</span>
                        <Button
                            onClick={() => {
                                setOpenDistanceModal(true)
                                getMile()
                            }}
                            type="default"
                            size="small"
                        >
                            주행거리
                        </Button>
                    </div>
                </TableFormValue>
                <TableFormLabel sm={2}>프로모션 명</TableFormLabel>
                <TableFormValue sm={10}>
                    <div className="flex-between">
                        <span>{membershipInfo?.promotionLabel}</span>
                        <Button
                            disabled={(membershipInfo?.statusId as number) !== 1}
                            type="primary"
                            size="small"
                            onClick={() => {
                                setOpenPromotionModal(true)
                            }}
                        >
                            등록/수정
                        </Button>
                    </div>
                </TableFormValue>
            </TableForm>
        </div>
    )
}

export default MembershipInfo

const Text = styled.span<{ color: string }>`
    color: ${props => props.color};
    font-weight: bold;
`

const ToolTip = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    span {
        position: absolute;
        width: 110px;
        top: 30px;
        height: 35px;
        background-color: #000000d4;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        line-height: 35px;
        padding: 0 5px;

        z-index: 1;
        :after {
            content: "";
            position: absolute;
            top: 6px;
            left: 50%;
            width: 0;
            height: 0;
            border: 15px solid transparent;
            border-bottom-color: #000000d4;
            border-top: 0;
            border-left: 0;
            margin-left: -40px;
            margin-top: -20px;
        }
    }
`
