import React from "react"
import { Button, Modal } from "p-ui"
import { ConfirmModalType } from "../types"

interface ModalProps {
    modalOpen: ConfirmModalType
    cbParam?: any
}

const CommonModal = (props: ModalProps) => {
    const { modalOpen, cbParam } = props
    return (
        <Modal
            type={modalOpen.type ? modalOpen.type : "confirm"}
            title={modalOpen.title}
            open={modalOpen.isOpen}
            width={modalOpen.width}
            onClose={() => {
                modalOpen.onClose()
            }}
            footer={
                <>
                    <Button
                        type="secondary"
                        onClick={() => {
                            modalOpen.onClose()
                        }}
                    >
                        취소
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => modalOpen.cb(cbParam)}
                    >
                        확인
                    </Button>
                </>
            }
        >
            {modalOpen.contents}
        </Modal>
    )
}

export default CommonModal
