import React, { useEffect, useMemo, useState } from "react"

import { useSetRecoilState } from "recoil"
import { zentalModalState } from "src/store/zental"

import { Select, Tag, Button, Icon, Input, Tooltip } from "p-ui"
import { FlexBetween, FlexColDiv, FlexDiv, Text, TransparentBtn } from "@styles"

import { Type } from "p-ui"
import { BikeServiceInfoType } from "@type/responseType"
import { ZENTAL } from "src/apis/urls"
import apis from "@apis"
import useAlert from "@context/alert/useAlert"

const statusOption = [
    { label: "대기", value: "wait" },
    { label: "점검", value: "inspection" },
]

interface headerProps {
    bikeData: BikeServiceInfoType
    changeBikeInfo: () => void
    getUncontractUserList: () => void
    getUncontractBikeList: () => void
    setBikeData: any
    updateZentalStatus: (status: number) => void
    cancelContract: (type: string) => void
}
const BikeDetailHeader = (props: headerProps) => {
    const isDevelopment = process.env.NODE_ENV === "development"

    const { openAlert } = useAlert()

    const {
        bikeData,
        setBikeData,
        changeBikeInfo,
        getUncontractUserList,
        getUncontractBikeList,
        updateZentalStatus,
        cancelContract,
    } = props

    const setZentalModal = useSetRecoilState(zentalModalState)
    const [bikeStatusEdit, setBikeStatusEdit] = useState<boolean>(false)
    const [status, setStatus] = useState<string>("")
    const [statusType, setStatusType] = useState<Type.TagType>("success")
    const [isConn, setIsConn] = useState<boolean>(false)
    const [isAllFinish, setIsAllFinish] = useState<boolean>(false)

    const setDatas = (status: string, color: Type.TagType, connect: boolean) => {
        setStatus(status)
        setStatusType(color)
        setIsConn(connect)
    }

    const createPayment = () => {
        apis.Zental.createPayment(bikeData.zentalId).then(res => {
            if (res.status == "SUCCESS") {
                openAlert({ title: "성공", body: "생성 완료", type: "primary" })
            } else {
                openAlert({ title: "실패", body: res.resultMessage, type: "warning" })
            }
        })
    }

    const btnName = useMemo(() => {
        switch (bikeData.status) {
            case "11":
                return { label: "점검 요청", value: 21 }
            case "21":
                return { label: "점검 완료", value: 22 }
            default:
                return { label: "이륜차 반납", value: 11 }
        }
    }, [bikeData.status])

    const isFixable = useMemo(() => {
        return status == "미계약" || status == "점검"
    }, [status])

    useEffect(() => {
        switch (bikeData.status) {
            case "fail":
                setDatas("결제 실패", "danger", true)
                break
            case "wait":
                setDatas("미계약", "danger", false)
                break
            case "inspection":
                setDatas("점검", "warning", false)
                break
            case "0":
                setDatas("종료", "danger", false)
                break
            case "2":
                setDatas("해지", "danger", false)
                break
            case "3":
                setDatas("해지 예정", "danger", true)
                break
            case "11":
                setDatas("이륜차 반납", "warning", false)
                break
            case "21":
                setDatas("점검 요청", "warning", false)
                break
            case "22":
                setDatas("점검 완료", "warning", false)
                break
            default:
                setDatas("정상", "success", true)
                break
        }
    }, [bikeData])

    useEffect(() => {
        setIsAllFinish(!isConn && Number(bikeData.status) >= 0)
    }, [status])

    return (
        <FlexBetween alignItems="center">
            <FlexColDiv justifyContent="center">
                <FlexDiv
                    as="h4"
                    gap="5px"
                    alignItems="center"
                >
                    {bikeStatusEdit && isFixable ? (
                        bikeData.riderNm === null && (
                            <Select
                                size="small"
                                width={"100px"}
                                options={statusOption}
                                defaultValue={bikeData?.status}
                                onChange={(label: string, value: any) => {
                                    setBikeData({ ...bikeData, status: value })
                                }}
                            />
                        )
                    ) : (
                        <Tag
                            size="small"
                            value={status}
                            type={statusType}
                        />
                    )}

                    {bikeStatusEdit ? (
                        <Input
                            defaultValue={bikeData?.bikeNo ? bikeData?.bikeNo : "차량번호 없음"}
                            onChange={e => setBikeData({ ...bikeData, bikeNo: e.value.toString() })}
                        />
                    ) : (
                        <Text
                            bold
                            fontSize="1.3rem"
                        >
                            {bikeData?.bikeNo ? bikeData?.bikeNo : "차량번호 없음"}
                        </Text>
                    )}
                    <Tooltip content="차량 번호 / 상태 변경" placement="top">
                    <TransparentBtn onClick={() => setBikeStatusEdit(!bikeStatusEdit)}>
                        {bikeStatusEdit ? (
                            
                            <Icon.Check
                                fill="#1b4af0"
                                onClick={() => {
                                    changeBikeInfo()
                                }}
                            />
                        ) : (
                            <Icon.Edit width={15} />
                        )}
                    </TransparentBtn>
                    
                    </Tooltip>
                </FlexDiv>
            </FlexColDiv>
            {isConn && (
                <FlexDiv gap="8px">
                    {isDevelopment && (
                        <Button
                            onClick={() => createPayment()}
                            size="small"
                            type="secondary"
                        >
                            결제이력 생성
                        </Button>
                    )}
                    <Button
                        onClick={() => {
                            getUncontractUserList()
                            setZentalModal(res => ({ ...res, assignmentModalState: true }))
                        }}
                        size="small"
                        type="secondary"
                        disabled={bikeData?.riderNm === null || bikeData?.riderNm.startsWith("zen")}
                    >
                        양도
                    </Button>
                    <Button
                        onClick={() => {
                            getUncontractBikeList()
                            setZentalModal(res => ({ ...res, bikeChangeModalState: true }))
                        }}
                        size="small"
                        type="primary"
                        disabled={bikeData?.riderNm === null || bikeData?.riderNm.startsWith("zen")}
                    >
                        바이크변경
                    </Button>

                    <Button
                        size="small"
                        type="danger"
                        disabled={bikeData?.riderNm === null || bikeData?.riderNm.startsWith("zen")}
                        onClick={() => {
                            setZentalModal(res => ({
                                ...res,
                                confirmModalState: {
                                    ...res.confirmModalState,
                                    isOpen: true,
                                    title: "계약 종료",
                                    contents: "계약을 종료 하시겠습니까?",
                                    cb: () => cancelContract("finish"),
                                },
                            }))
                        }}
                    >
                        종료
                    </Button>

                    <Button
                        size="small"
                        disabled={bikeData?.riderNm === null || bikeData?.riderNm.startsWith("zen")}
                        onClick={() => {
                            setZentalModal(res => ({
                                ...res,
                                confirmModalState: {
                                    ...res.confirmModalState,
                                    isOpen: true,
                                    title: "계약 해지",
                                    contents: "계약을 해지 하시겠습니까?",
                                    cb: () => cancelContract("cancel"),
                                },
                            }))
                        }}
                    >
                        해지
                    </Button>
                </FlexDiv>
            )}
            {isAllFinish && (
                <FlexDiv>
                    <Button
                        size="small"
                        type="secondary"
                        onClick={() => updateZentalStatus(btnName.value)}
                    >
                        {btnName.label}
                    </Button>
                </FlexDiv>
            )}
        </FlexBetween>
    )
}

export default BikeDetailHeader
