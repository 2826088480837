import { HardwareParam, FirmwareParam } from "@type/assetsTypes"

import {
    AssetAllBike,
    AssetBIkeInfo,
    AssetBikeMemo,
    AssetBikeStatus,
    AssetGoodsBike,
    FavoriteBike,
    Result,
} from "@type/responseType"
import axios from "./config"
import { ASSETS } from "./urls"

export default {
    /**
     * 젠트로피 전체 바이크 조회 (goods)
     * @returns []
     */
    async getAllBikeList(managerId: number): Promise<Result<AssetAllBike[]>> {
        return await axios.host.get(ASSETS.GET_LIST + "?managerId=" + managerId)
    },

    /**
     * 자산관리 바이크 단건 조회
     * @param serialNo
     * @returns
     */
    async getBikeOne(serialNo: string, managerId: number): Promise<Result<AssetGoodsBike>> {
        return await axios.host.get(ASSETS.GET_ONE + serialNo + "?managerId=" + managerId)
    },
    /**
     * 자산관리 바이크 상태 조회
     * @param serialNo
     * @returns
     */
    async getBikeStatus(serialNo: string): Promise<Result<AssetBikeStatus>> {
        try {
            return await axios.host.get(ASSETS.GET_STATUS + serialNo)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 자산관리 펌웨어 버전 조회
     * @param serialNo
     * @returns
     */
    async getBikeFirmWareVersion(serialNo: string): Promise<Result<AssetBikeStatus>> {
        try {
            return await axios.host.get(ASSETS.GET_FIRMARE + serialNo)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 자산관리 펌웨어 버전이력 조회
     * @param serialNo
     * @returns
     */
    async getBikeFirmWareVersionHistory(serialNo: string): Promise<Result<AssetBikeStatus>> {
        try {
            return await axios.host.get(ASSETS.GET_FIRMARE_LIST + serialNo)
        } catch (e: any) {
            return e.message
        }
    },
    async getBikeFirmWareApplyVersionList(firmwareId: number): Promise<Result<AssetBikeStatus>> {
        try {
            return await axios.host.get(ASSETS.GET_APPLY_FIRMARE_LIST + "?firmwareId=" + firmwareId)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 자산관리 하드웨어 버전 조회
     * @param serialNo
     * @returns
     */
    async getBikeHardWareVersion(serialNo: string): Promise<Result<AssetBikeStatus>> {
        try {
            return await axios.host.get(ASSETS.GET_HARDWARE + serialNo)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 자산관리 하드웨어 버전이력 조회
     * @param serialNo
     * @returns
     */
    async getBikeHardWareVersionHistory(serialNo: string): Promise<Result<AssetBikeStatus>> {
        try {
            return await axios.host.get(ASSETS.GET_HARDWARE_LIST + serialNo)
        } catch (e: any) {
            return e.message
        }
    },
    async getBikeHardWareApplyVersionList(hardwareId: number): Promise<Result<AssetBikeStatus>> {
        try {
            return await axios.host.get(ASSETS.GET_APPLY_HARDWARE_LIST + "?hardwareId=" + hardwareId)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 자산관리 하드웨어 버전업데이트
     * @returns
     */
    async updateHardWareVersion(hardwareParam: HardwareParam): Promise<Result<AssetBikeStatus>> {
        try {
            return await axios.host.post(ASSETS.UPDATE_HARDWARE, hardwareParam)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 자산관리 펌웨어 버전업데이트
     * @returns
     */
    async updateFirmWareVersion(firmwareParam: FirmwareParam): Promise<Result<AssetBikeStatus>> {
        try {
            return await axios.host.post(ASSETS.UPDATE_FIRMWARE, firmwareParam)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 자산관리 배터리 정보그래프 조회
     * @returns
     */
    async getAssetsBikeStatus(bikeInfo: AssetBIkeInfo): Promise<Result<any[]>> {
        try {
            return await axios.host.get(ASSETS.GET_ASSETS_BIKESTATUS, { params: bikeInfo })
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 자산관리 메모 조회
     * @param serialNo
     * @returns
     */
    async getBikeMemo(serialNo: string): Promise<Result<AssetBikeMemo>> {
        try {
            return await axios.host.get(ASSETS.GET_MEMO + serialNo)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 자산관리 바이크 메모 업데이트
     * @returns
     */
    async updateBikeMemo(bikememo: AssetBikeMemo): Promise<Result<AssetBikeMemo>> {
        try {
            return await axios.host.post(ASSETS.UPDATE_MEMO, bikememo)
        } catch (e: any) {
            return e.message
        }
    },
    /**
     * 자산관리 바이크 정보 업데이트
     * @param serialNo
     * @returns
     */
    async updateBikeInfo(serialNo: string, goodsBike: AssetGoodsBike): Promise<Result<AssetGoodsBike>> {
        try {
            return await axios.host.put(ASSETS.UPDATE_ASSETS + serialNo, goodsBike)
        } catch (e: any) {
            return e.message
        }
    },

    /**즐겨찾는 기체리스트 조회 */
    async getFavoriteBikeList(managerId: number): Promise<Result<any>> {
        try {
            return await axios.host.get(ASSETS.GET_FAVORITE_LIST + "?managerId=" + managerId)
        } catch (e: any) {
            return e.message
        }
    },
    /**즐겨찾는 기체 조회 */
    async getFavoriteBike(deviceType: string, managerId: number): Promise<Result<FavoriteBike>> {
        try {
            return await axios.host.get(ASSETS.GET_FAVORITE + "deviceType" + deviceType + "managerId" + managerId)
        } catch (e: any) {
            return e.message
        }
    },
    /**즐겨찾는 기체 업데이트 */

    async updateFavoriteBike(favoriteBike: FavoriteBike): Promise<Result<any>> {
        try {
            return await axios.host.post(ASSETS.POST_FAVORITE, favoriteBike)
        } catch (e: any) {
            return e.message
        }
    },
    /**즐겨찾는 기체 삭제 */

    async deleteFavoriteBike(favoriteBikeId: number): Promise<Result<any>> {
        try {
            return await axios.host.delete(ASSETS.DELETE_FAVORITE, {
                params: { favoriteId: favoriteBikeId },
            })
        } catch (e: any) {
            return e.message
        }
    },
}
