import styled from "styled-components"

export const CustomOverlay = styled.div`
    position: relative;
    h2 {
        margin: 0;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        font-size: 14px;
        color: white;
        &.start {
            background-color: green;
        }
        &.end {
            background-color: red;
        }
    }
    .info-window {
        top: -25px;
        position: absolute;
        background-color: #131313b7;
        color: white;
        font-weight: bold;
        padding: 0.2rem;
    }
`

export const InfoWrap = styled.div`
    display: flex;
    /* align-items: flex-end; */
    box-sizing: border-box;
    gap: 5px;
    img {
        width: 20%;
        &.center {
            width: 17%;
        }
    }
    @media screen and (max-width: 844px) {
        display: none !important;
    }
`

export const OpacityBtnWrap = styled.div`
    position: absolute;
    z-index: 2;
    right: 10px;
    bottom: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    span {
        display: block;
        text-align: center;
        background-color: #13131392;
        color: white;
        font-weight: bold;
        padding: 0.3rem;
        width: 100%;
    }
`

export const OpacitySwitchWrap = styled.div`
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`
export const FormWrap = styled.div`
    height: 450px;
    overflow-y: scroll;
    padding: 0 1rem;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
`

export const PopupWrap = styled.div`
    display: flex;
    border-radius: 10px;
    width: 500px;
    height: 170px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 18px;
    background: #ffff;
    text-align: center;
    box-shadow: 0px 8px 20px rgba(43, 49, 53, 0.05);
    z-index: 100;
    bottom: 0px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    box-sizing: border-box;
    z-index: 100;
    padding: 1rem;
    @media screen and (max-width: 844px) {
        /* 모바일 */
        width: 100%;
        height: 140px;
        gap: 3px;
        left: 0;
        padding: 5px;
    }
`

export const PopupButton = styled.button`
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 5px;
    border: none;
    font-size: 20px;
    font-family: "Spoqa Han Sans Neo";
    font-style: normal;
    font-weight: 500;
    color: #999;
    cursor: pointer;
`
export const PopupName = styled.div`
    display: block;
    width: 100%;
    font-family: "Spoqa Han Sans Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.03em;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    box-sizing: border-box;
    padding-bottom: 10px;
    @media screen and (max-width: 844px) {
        font-size: 13px;
        padding-bottom: 5px;
    }
`

export const MapWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 16px;
    z-index: 1;
`

export const FullScreenBtn = styled.div`
    position: absolute;
    z-index: 2;
    left: 5px;
    top: 5px;
    width: 40px;
    height: 40px;
    background-color: rgb(255 255 255 / 65%);
`
export const TagUI = styled.div`
    border-radius: 12px;
    background: white;
    border: 1px solid black;
    color: black;
    padding: 2px 8px;
    font-size: 0.6rem;
`

export const TotalMileColorBar = styled.div<{ color: string }>`
    width: 50px;
    height: 4px;
    background-color: ${props => props.color};
`
