import { Col, Input, LabelForm, Row, Select } from "p-ui"

const BikeChangeModal = (props: any) => {
    const statusOption = [
        { label: "대기", value: "wait" },
        { label: "점검", value: "inspection" },
    ]
    const { bikeData, options, setNewBikeId, setBikeStatus } = props

    const setData = (name: string | any, value: string | any) => {
        setNewBikeId(value)
    }

    return (
        <Row gap="10px">
            <Col sm={12}>
                <LabelForm label="기존 바이크">
                    <Input
                        width="100%"
                        readonly
                        defaultValue={`${bikeData.bikeNo} / ${bikeData.serialNo}`}
                    />
                </LabelForm>
            </Col>
            <Col sm={12}>
                <LabelForm label="바이크 상태">
                    <Select
                        width="100%"
                        onChange={(label, value) => {
                            setBikeStatus(value)
                        }}
                        options={statusOption}
                    />
                </LabelForm>
            </Col>
            <Col sm={12}>
                <LabelForm label="변경 바이크">
                    <Select
                        width="100%"
                        onChange={(label, value) => {
                            setData(label, value)
                        }}
                        searchable
                        options={options}
                    />
                </LabelForm>
            </Col>
        </Row>
    )
}

export default BikeChangeModal
