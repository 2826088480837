import { useEffect, useState } from "react"
import { RouteObject, useLocation, useNavigate, useRoutes } from "react-router-dom"
import { LoginPage } from "@pages/Auth"
import { IssueDetailPage, IssueListPage, IssueUpsertPage, AsCodePage } from "@pages/Issue"
import { OrderDetailPage, OrderListPage } from "@pages/Order"
import { StationListPage, StationMapPage, StationUpsertPage } from "@pages/Station"

import RentalFleetList from "@pages/RentalFleet/RentalFleetList"
import RentalFleetDetail from "@pages/RentalFleet/RentalFleetDetail"

import {
    RentalBikeDetailPage,
    RentalBikePage,
    RentalUserDetailPage,
    RentalUserInsertPage,
    RentalUserPage,
} from "@pages/Rental"

import { PushPage } from "@pages/Purchaser"
import DefaultLayout from "@components/layouts/defaultLayout"
import { MembershipMainPage } from "@pages/Membership"
import MembershipDetailPage from "@pages/Membership/detail"
import MembershipDetailPage2 from "@pages/Membership/detail/index_copy"
import { RequestDetailPage, RequestInsertPage, RequestMainPage } from "@pages/RequestWork"

import NotFound from "@pages/NotFound"

// ! TODO CHECK
import BikePolyline from "@pages/Lab/polyline"

import { ServiceMenu } from "@type/common"
import { FaqDetail, FaqList, InsertFaq } from "@pages/Bbs/FAQ"
import { InsertNotice, NoticeDetail, NoticeList } from "@pages/Bbs/Notice"
import { BikeErrorCodeDetail, BikeErrorCodeList, InsertBikeErrorCode } from "@pages/Bbs/BikeErrorCode"

import DataVisualPage from "@pages/Lab/dataVisual"

import AssetManage from "@pages/Lab/bike/BikeList"
import AssetsBikeDetail from "@pages/Lab/bike/BikeDetail"
import FirmWareList from "@pages/Assets/Frimware/FirmwareList"
import FirmwareDetail from "@pages/Assets/Frimware/FirmwareDetail"
import HardwareList from "@pages/Assets/Hardware/HardwareList"
import HardwareDetail from "@pages/Assets/Hardware/HardwareDetail"
import BikeList from "@pages/Assets/Bike/BikeList"
import ShippmentStatus from "@pages/Bbs/ShippmentStatus"
import EventPage from "@pages/Bbs/Event"
import EventUpsertPage from "@pages/Bbs/Event/EventUpsert"

const Routes = () => {
    const navigate = useNavigate()
    const { pathname, search } = useLocation()
    // 기본 라우터 설정
    const loginRoute: RouteObject = {
        path: "/",
        element: <LoginPage />,
    }
    const notFoundRoute: RouteObject = {
        path: "*",
        element: <NotFound />,
    }

    const [filterRouteList, setFilterRouteList] = useState<RouteObject[]>([loginRoute])

    useEffect(() => {
        if (!sessionStorage.getItem("TOKEN")) {
            navigate("/", { state: { pathname: pathname, search: search } })
        }
    }, [sessionStorage.getItem("TOKEN")])

    // 메뉴 세팅
    useEffect(() => {
        const resultRouteList: RouteObject[] = []
        // 세션에 권한 메뉴 리스트 존재하는지 확인
        if (sessionStorage.getItem("MENU_LIST")) {
            const userMenuList: ServiceMenu[] = JSON.parse(sessionStorage.getItem("MENU_LIST")!!)
            // 세션에 있는 권한 id와 라우터에 설정되있는 ID값 비교하여 필터
            const filterUserRouteList = routeList.filter(userMenu =>
                userMenuList.some(routeObject => routeObject.menuId === userMenu.id)
            )
            resultRouteList.push(...filterUserRouteList)
        }
        // 기본 라우터 세팅 (로그인, notFound Page)
        resultRouteList.push(loginRoute)
        resultRouteList.push(notFoundRoute)
        // 라우터 세팅
        setFilterRouteList(resultRouteList)
    }, [sessionStorage.getItem("MENU_LIST")])

    const routeList: RouteObject[] = [
        {
            id: "2",
            path: "/station",
            element: <DefaultLayout />,
            children: [{ index: true, element: <StationMapPage /> }],
        },
        {
            id: "3",
            path: "/station",
            element: <DefaultLayout />,
            children: [
                { path: "list", element: <StationListPage /> },
                { path: "upsert", element: <StationUpsertPage /> },
            ],
        },
        {
            id: "5",
            path: "/rental_fleet",
            element: <DefaultLayout />,
            children: [
                { path: "main", element: <RentalFleetList /> },
                { path: "detail", element: <RentalFleetDetail /> },
            ],
        },
        {
            id: "7",
            path: "/iss",
            element: <DefaultLayout />,
            children: [
                { path: "list", element: <IssueListPage /> },
                { path: "detail", element: <IssueDetailPage /> },
                { path: "upsert", element: <IssueUpsertPage /> },
                { path: "code", element: <AsCodePage /> },
            ],
        },
        {
            id: "9",
            path: "/purchaser",
            element: <DefaultLayout />,
            children: [{ path: "push", element: <PushPage /> }],
        },
        {
            id: "10",
            path: "/membership",
            element: <DefaultLayout />,
            children: [
                { index: true, element: <MembershipMainPage /> },
                { path: "detail/:serial", element: <MembershipDetailPage /> },
                { path: "detail-copy/:serial", element: <MembershipDetailPage2 /> },
            ],
        },
        {
            id: "12",
            path: "/rental",
            element: <DefaultLayout />,
            children: [
                { path: "user", element: <RentalUserPage /> },
                { path: "userdetail", element: <RentalUserDetailPage /> },
                { path: "userinsert", element: <RentalUserInsertPage /> },
            ],
        },
        {
            id: "13",
            path: "/rental",
            element: <DefaultLayout />,
            children: [
                { path: "bike", element: <RentalBikePage /> },
                { path: "bikedetail", element: <RentalBikeDetailPage /> },
            ],
        },
        {
            id: "15",
            path: "/order",
            element: <DefaultLayout />,
            children: [
                { path: "main", element: <OrderListPage /> },
                { path: "detail/:orderId", element: <OrderDetailPage /> },
            ],
        },
        {
            id: "16",
            path: "/request",
            element: <DefaultLayout />,
            children: [
                { path: "main", element: <RequestMainPage /> },
                { path: "detail", element: <RequestDetailPage /> },
                { path: "insert", element: <RequestInsertPage /> },
            ],
        },
        {
            id: "22",
            path: "/lab",
            element: <DefaultLayout />,
            children: [{ path: "polyline", element: <BikePolyline /> }],
        },
        {
            id: "25",
            path: "/lab",
            element: <DefaultLayout />,
            children: [{ path: "datavisual", element: <DataVisualPage /> }],
        },
        {
            id: "26",
            path: "/lab",
            element: <DefaultLayout />,
            children: [
                { path: "firm", element: <FirmWareList /> },
                { path: "firmdetail", element: <FirmwareDetail /> },
            ],
        },
        {
            id: "27",
            path: "/lab",
            element: <DefaultLayout />,
            children: [
                { path: "hard", element: <HardwareList /> },
                { path: "hardDetail", element: <HardwareDetail /> },
            ],
        },
        {
            id: "29",
            path: "/lab",
            element: <DefaultLayout />,
            children: [
                { path: "bike", element: <AssetManage /> },
                { path: "bikedetail", element: <AssetsBikeDetail /> },
            ],
        },
        {
            id: "18",
            path: "/bbs",
            element: <DefaultLayout />,
            children: [
                { path: "notice", element: <NoticeList /> },
                { path: "notice/:id", element: <NoticeDetail /> },
                { path: "notice/insert", element: <InsertNotice /> },
            ],
        },
        {
            id: "19",
            path: "/bbs",
            element: <DefaultLayout />,
            children: [
                { path: "faq", element: <FaqList /> },
                { path: "faq/:id", element: <FaqDetail /> },
                { path: "faq/insert", element: <InsertFaq /> },
            ],
        },
        {
            id: "20",
            path: "/bbs",
            element: <DefaultLayout />,
            children: [
                { path: "errorcode", element: <BikeErrorCodeList /> },
                { path: "errorcode/:id", element: <BikeErrorCodeDetail /> },
                { path: "errorcode/insert", element: <InsertBikeErrorCode /> },
            ],
        },
        {
            id: "28",
            path: "/bbs",
            element: <DefaultLayout />,
            children: [{ path: "shippmentstatus", element: <ShippmentStatus /> }],
        },
        {
            id: "30",
            path: "/bbs",
            element: <DefaultLayout />,
            children: [
                { path: "event", element: <EventPage /> },
                { path: "event/:id", element: <EventUpsertPage /> },
            ],
        },
        {
            id: "24",
            path: "/assets",
            element: <DefaultLayout />,
            children: [{ path: "bike", element: <BikeList /> }],
        },
        { path: "/login", element: <LoginPage /> },
        {
            path: "*",
            element: <NotFound />,
        },
    ]

    return useRoutes(filterRouteList)
}

export default Routes
