import { EventItem, Result } from "@type/responseType"
import axios from "./config"
import { EVENT } from "./urls"

export default {
    async getEventList(): Promise<Result<EventItem[]>> {
        try {
            return await axios.host.get(EVENT.GET_POPUP_LIST)
        } catch (err: any) {
            return err.message
        }
    },
    async getEventOne(id: number | string): Promise<Result<EventItem>> {
        try {
            return await axios.host.get(EVENT.GET_POPUP_ONE + id)
        } catch (err: any) {
            return err.message
        }
    },
    async deleteEvent(id: number | any): Promise<Result<any>> {
        try {
            return await axios.host.delete(EVENT.DELETE_POPUP_ONE + id)
        } catch (err: any) {
            return err.message
        }
    },
    async insertEvent(body: any): Promise<Result<any>> {
        try {
            return await axios.host.post(EVENT.INSERT_POPUP, body)
        } catch (err: any) {
            return err.message
        }
    },

    async updateEvent(body: any): Promise<Result<any>> {
        try {
            return await axios.host.put(EVENT.UPDATE_POPUP, body)
        } catch (err: any) {
            return err.message
        }
    },
}
