import React from "react"
import { useNavigate } from "react-router"

import { FlexCol } from "../styles"
import { Button } from "p-ui"
import { Row } from "react-grid-system"

import apis from "@apis"
import { deleteAlert } from "@common/util"

const ChartTitle = (props: any) => {
    const navigate = useNavigate()
    const deleteIssue = async () => {
        const axiosResult = await apis.Iss.deleteIssue(Number(props.id))
        if (axiosResult.status == "SUCCESS") {
            alert("삭제 완료")
            navigate("/iss/list")
        } else {
            alert("삭제 실패")
        }
    }

    return (
        <Row>
            <FlexCol sm={12}>
                <ChartTitle marginBottom={props.marginBottom}>{props.title}</ChartTitle>
                {props.detail && (
                    <FlexCol style={{ padding: "0px" }}>
                        <Button
                            type="primary"
                            onClick={() => navigate({ pathname: "/iss/upsert", search: `?id={props.id}` })}
                        >
                            수정
                        </Button>
                        <Button
                            type="danger"
                            onClick={(e: any) => {
                                e.stopPropagation()
                                deleteAlert(deleteIssue)
                            }}
                            // onClick={() => {
                            //     setIsOpen({
                            //         ...modal,
                            //         open: true,
                            //         header: "이슈 삭제",
                            //         text: "이슈를 삭제하시겠습니까?",
                            //         type: "1",
                            //         cb: deleteIssue,
                            //     })
                            // }}
                        >
                            삭제
                        </Button>
                    </FlexCol>
                )}
            </FlexCol>
        </Row>
    )
}

export default ChartTitle
