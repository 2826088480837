export const noticeFolderName = "notice"
export const noticeLinkInfo = "th_notice"

export const faqFolderName = "faq"
export const faqLinkInfo = "th_faq"

export const shippmentStatusFolderName = "shippmentstatus"
export const shippmentStatusLinkInfo = "shippment_status"

export const eventTableColumn = [
    {
        title: "순서",
        dataIndex: "popupSeq",
    },
    {
        title: "상태",
        dataIndex: "currentStatus",
    },
    {
        title: "팝업제목",
        dataIndex: "popupTitle",
    },
    {
        title: "시작일",
        dataIndex: "startDate",
    },
    {
        title: "종료일",
        dataIndex: "endDate",
    },
    {
        title: "상세보기",
        dataIndex: "detail",
    },
]
